import api from "!../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!./jquery.ui.resizable.css";

var options = {};

options.insert = function insertAtTop(element) {
									const parent = document.querySelector('head');
									// eslint-disable-next-line no-underscore-dangle
									const lastInsertedElement =
										window._lastElementInsertedByStyleLoader;

									if (!lastInsertedElement) {
										parent.insertBefore(element, parent.firstChild);
									} else if (lastInsertedElement.nextSibling) {
										parent.insertBefore(
											element,
											lastInsertedElement.nextSibling
										);
									} else {
										parent.appendChild(element);
									}

									// eslint-disable-next-line no-underscore-dangle
									window._lastElementInsertedByStyleLoader = element;
								};
options.singleton = false;

var update = api(content, options);



export default content.locals || {};