import _ from 'underscore';
import ajaxService from 'AjaxService';
import Promise from 'bluebird';
import constants from 'Constants';
import log from 'Log';
import ruleDependencyValue from 'RuleDependencyValue2';
import ruleFunction from 'RuleFunction';
import errors from 'Errors';
import connection from 'Connection';
import global from 'Global';

function ClientSideRule(component, valueConverter) {
	this._func = ruleFunction(component.func);
	this.parameters = component.parameters || [];
	this._setterFunc = ruleFunction(component.setterFunc);
	this._setterParameters = component.setterParameters || [];
	this._valueConverter = valueConverter;
	this._cacheKeyFunc = ruleFunction(component.cacheKeyFunc);
	this.hasCacheKeyFunc = !!this._cacheKeyFunc;
	this.dependencies = component.dependencies || [];
}

ClientSideRule.prototype.loadCacheKeyFuncAsync = function () {
	return Promise.try(() => {
		return this._cacheKeyFunc && this._cacheKeyFunc.loadAsync();
	});
};

ClientSideRule.prototype.getCacheKey = function (entity) {
	if (this._cacheKeyFunc) {
		const args = ruleDependencyValue.getValues(entity, this.parameters);
		if (args.state === constants.States.Available) {
			return this._cacheKeyFunc.apply(null, args.values);
		}
	}
};

ClientSideRule.prototype.processAsync = function (rule, entity, context) {
	const startTime = performance.now();
	let loadingDependencies;
	if (this.dependencies.length) {
		const dependencies = this.dependencies.map((path) => {
			return { value: `<${path}>` };
		});
		loadingDependencies = ruleDependencyValue.getValuesAsync(entity, dependencies);
	}

	const self = this;
	return Promise.wait(loadingDependencies, () => {
		if (global.featureFlags.logRuleInvocationTimings) {
			log.info(`[Rule] Dependencies loaded for ${rule.ruleId} in ${performance.now() - startTime}ms.`);
		}

		return ruleDependencyValue.getValuesAsync(entity, self.parameters).then(({ state, values }) => {
			if (state !== constants.States.Available) {
				throw new errors.UnavailableArgumentsOrSecurityError();
			}

			return processCore(self, rule, entity, context, values, finalize);

			function finalize(value) {
				return Promise.resolve(value)
					.catch((error) => {
						if (error instanceof connection.OfflineError || errors.isCritical(error)) {
							throw error;
						}
						assertIsNotForbidden(error);
						throw new errors.RuleInvocationException(
							getInterfaceName(entity),
							rule.ruleId,
							rule.property,
							error
						);
					})
					.then((value) => {
						return {
							isSuccess: true,
							value: convertValue(self, value, values, rule.returnType),
						};
					});
			}
		});
	});

};

ClientSideRule.prototype.tryProcessSync = function (rule, entity, context) {
	if (this.dependencies.length > 0) {
		const dependencies = this.dependencies.map((path) => {
			return { value: `<${path}>` };
		});

		if (ruleDependencyValue.getValues(entity, dependencies).state === constants.States.NotLoaded) {
			return { state: constants.States.NotLoaded };
		}
	}

	if (!this._func.isLoaded()) {
		return { state: constants.States.NotLoaded };
	}

	const { state, values } = ruleDependencyValue.getValues(entity, this.parameters);

	if (state === constants.States.NotAvailable) {
		throw new errors.UnavailableArgumentsOrSecurityError();
	}

	if (state === constants.States.Available) {
		const self = this;
		return processCore(this, rule, entity, context, values, (value) => {
			if (value instanceof Promise) {
				throw new Error('tryProcessSync should not be used for async rule: ' + rule.ruleId);
			}

			return {
				state: constants.States.Available,
				value: convertValue(self, value, values, rule.returnType),
			};
		});
	} else {
		return { state };
	}
};

ClientSideRule.prototype.hasSetter = function () {
	return !!this._setterFunc;
};

ClientSideRule.prototype.invokeSetterAsync = function (entity, value) {
	const self = this;
	return ruleDependencyValue.getValuesAsync(entity, this._setterParameters).then((result) => {
		if (result.state === constants.States.Available) {
			const values = result.values;
			values.unshift(entity, value);
			return Promise.wait(self._setterFunc.apply(self, values), () => {
				return true;
			}, (error) => {
				assertIsNotForbidden(error);
				throw error;
			});
		} else if (result.state === constants.States.NotAvailable) {
			throw new errors.UnavailableArgumentsOrSecurityError();
		} else {
			return false;
		}
	});
};

function processCore(self, rule, entity, context, values, finalize) {
	const defaultContext = {
		entity,
		entityManager: entity && entity.entityAspect ? entity.entityAspect.entityManager : null
	};
	const extendedContext = _.extend(defaultContext, context);
	try {
		const value = self._func.apply(extendedContext, values);
		return finalize(value);
	}
	catch (error) {
		if (errors.isCritical(error)) {
			throw error;
		}
		throw new errors.RuleInvocationException(getInterfaceName(entity), rule.ruleId, rule.property, error);
	}
}

function convertValue(self, value, values, returnType) {
	const converter = self._valueConverter;
	if (converter) {
		value = converter(value, values, returnType);
	}

	return value;
}

function getInterfaceName(entity) {
	/*! SuppressStringValidation no entity type indicator */
	return entity && entity.entityType ? entity.entityType.interfaceName : 'N/A';
}

function assertIsNotForbidden(error) {
	const networkError = errors.findError(error, errors.DataServiceRequestError) || errors.findError(error, ajaxService.AjaxError);
	if (networkError && networkError.status === 403) {
		throw new errors.UnavailableArgumentsOrSecurityError(error);
	}
}

export default ClientSideRule;
