import type { Item, Strategy } from "./Strategy.ts";

export class RamStrategy implements Strategy {
  private readonly internalStorage: Map<string, unknown>;

  constructor() {
    this.internalStorage = new Map();
  }

  async clearAsync(): Promise<void> {
    this.internalStorage.clear();
    await Promise.resolve();
  }

  async getAsync<T>(key: string): Promise<T | undefined> {
    const result = this.internalStorage.get(key);
    await Promise.resolve();
    return result as T | undefined;
  }

  async deleteAsync(key: string): Promise<void> {
    this.internalStorage.delete(key);
    await Promise.resolve();
  }

  async deleteAllAsync(predicate: (item: Item) => boolean): Promise<void> {
    const elementsToDelete: string[] = [];
    this.internalStorage.forEach((value, key) => {
      if (predicate({ key, value })) {
        elementsToDelete.push(key);
      }
    });
    elementsToDelete.forEach((key) => this.internalStorage.delete(key));
    await Promise.resolve();
  }

  async getAllValuesAsync<T>(): Promise<T[]> {
    const result: T[] = [];
    this.internalStorage.forEach((value) => {
      result.push(value as T);
    });
    await Promise.resolve();
    return result;
  }

  async getAllItemsAsync<T>(): Promise<Item<T>[]> {
    const result: Item<T>[] = [];
    this.internalStorage.forEach((value, key) => {
      result.push({ key, value: value as T });
    });
    await Promise.resolve();
    return result;
  }

  async replaceAllAsync<T>(items: Item<T>[]): Promise<void> {
    this.internalStorage.clear();
    if (items) {
      items.forEach(({ key, value }) => {
        this.internalStorage.set(key, value);
      });
    }
    await Promise.resolve();
  }

  async setAsync<T>(key: string, value: T): Promise<void> {
    this.internalStorage.set(key, value);
    await Promise.resolve();
  }
}
