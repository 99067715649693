import { getTypeNameFromFullyQualifiedName } from "DataTypes";
import { getLanguageCode } from "LanguageService";

interface Range {
  max: number;
  min: number;
}

interface NumberFormat {
  decimalSeparator?: string;
  decimalDigits?: number;
  groupSeparator?: string;
  digitGrouping?: number;
}

interface ParsedValue {
  isAdjusted: boolean;
  value: number;
}

export function roundToDecimal(value: number, precision: number): number {
  const power = Math.pow(10, precision);
  return Math.round(value * power) / power;
}

const roundDownToNearestTenth = (value: number): number => Math.floor(10 * value) / 10;

const abbreviationRanges = [
  { upperLimit: 1e3 },
  { upperLimit: 1e6, scale: (value: number): number => value / 1e3, suffix: "K" },
  { upperLimit: 1e9, scale: (value: number): number => value / 1e6, suffix: "M" },
  { upperLimit: 1e12, scale: (value: number): number => value / 1e9, suffix: "B" },
  { upperLimit: 1e15, scale: (value: number): number => value / 1e12, suffix: "T" },
];

export function abbreviate(value: number): string {
  const multiplier = value < 0 ? -1 : 1;
  const absValue = Math.abs(value);

  for (let i = 0; i < abbreviationRanges.length; i++) {
    const range = abbreviationRanges[i];
    if (absValue < range.upperLimit) {
      if (!range.scale) {
        return value.toString();
      }

      const scaled = range.scale(absValue);

      return multiplier * (scaled >= 100 ? Math.floor(scaled) : roundDownToNearestTenth(scaled)) + range.suffix;
    }
  }

  return value < 0 ? "<-1Q" : ">1Q";
}

function getConverter(dataType: string): Converter | undefined {
  const typeName = getTypeNameFromFullyQualifiedName(dataType);
  if (!typeName) {
    return undefined;
  }

  return propertyTypeConverters[typeName];
}

export function getMinMaxValueByType(
  dataType: string,
  allowedNegatives: boolean,
  precision?: number,
  scale?: number
): Range | null {
  const converter = getConverter(dataType);
  if (!converter) {
    return null;
  }

  let result: Range = {
    min: allowedNegatives ? converter.numberTypeInfo.min : 0,
    max: converter.numberTypeInfo.max,
  };

  if (isNumber(precision) && isNumber(scale)) {
    scale = converter.numberTypeInfo.supportsDecimal ? scale : 0;
    const maxMinFromPrecision = getMinMaxValue(precision, scale);
    result = {
      min: result.min > maxMinFromPrecision.min ? result.min : maxMinFromPrecision.min,
      max: result.max > maxMinFromPrecision.max ? maxMinFromPrecision.max : result.max,
    };
  }

  return result;
}

export function getParsedValueByType(dataType: string, value: string, emptyValue?: number): ParsedValue {
  const converter = getConverter(dataType);
  let isAdjusted = true;
  let parsedValue = converter?.parser(value);

  if (isNumber(parsedValue)) {
    const minMax = getMinMaxValueByType(dataType, true);
    if (minMax) {
      if (parsedValue < minMax.min) {
        parsedValue = minMax.min;
      } else if (parsedValue > minMax.max) {
        parsedValue = minMax.max;
      } else {
        isAdjusted = false;
      }
    }
  } else {
    parsedValue = emptyValue === undefined ? 0 : emptyValue;
  }

  return { isAdjusted, value: parsedValue };
}

export function getNumberFormat(): NumberFormat {
  const format: NumberFormat = {};
  const numberString = (111111.111111111).toLocaleString(getLanguageCode());

  let decimalSeparator = "";
  let decimalDigits = 0;

  for (let i = numberString.length - 1; i >= 0; i--) {
    const char = numberString.charAt(i);
    if (char !== "1") {
      decimalSeparator = char;
      decimalDigits = numberString.length - i - 1;
      break;
    }
  }
  format.decimalSeparator = decimalSeparator;
  format.decimalDigits = decimalDigits;

  // Digit grouping separator
  let groupSeparator = undefined;
  for (let i = 0; i < numberString.length; i++) {
    const char = numberString.charAt(i);
    if (char !== "1" && char !== decimalSeparator) {
      groupSeparator = char;
      break;
    }
  }

  // Digit Groups
  let digitGrouping;
  const groupingLengths =
    groupSeparator !== undefined
      ? numberString
          .substring(0, numberString.length - decimalDigits - 1)
          .split(groupSeparator)
          .map((g) => g.split(decimalSeparator)[0].length)
      : [numberString.substring(0, numberString.length - decimalDigits - 1).length];
  if (groupingLengths.some((groupLength) => groupLength === 2)) {
    digitGrouping = 2;
  } else {
    digitGrouping = Math.max(...groupingLengths);
  }

  format.groupSeparator = groupSeparator || undefined;
  format.digitGrouping = digitGrouping;

  return format;
}

export function intToString(value: string): string {
  return parseInt(value).toLocaleString(getLanguageCode());
}

export function decimalToString(value: string, decimalPlaces: number): string {
  if (value == null) {
    return "";
  }
  const languageCode = getLanguageCode();
  return parseFloat(value).toLocaleString(languageCode, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
}

export function getSuffixText(suffix: string, entity: Record<string, unknown>): string {
  let suffixText = suffix;
  if (suffixText && suffixText.length > 2 && /^<\w+>$/g.test(suffixText)) {
    const propertyName = suffixText.substring(1, suffixText.length - 1);
    const property = entity && entity[propertyName];
    suffixText = property && typeof property === "function" ? property.call(entity) : "";
  }

  suffixText = replaceNumberCharactersWithSpecialCharacters(suffixText);

  return suffixText || "";
}

// autoNumeric does not allow any numerical characters, below numbers use special unicode code so could not trigger the number regex validation
function replaceNumberCharactersWithSpecialCharacters(suffixText: string): string {
  const arr = ["𝟢", "𝟣", "𝟤", "𝟥", "𝟦", "𝟧", "𝟨", "𝟩", "𝟪", "𝟫"];
  return suffixText && /\d+/g.test(suffixText) ? [...suffixText].map((x) => (+x ? arr[+x] : x)).join("") : suffixText;
}

function getMinMaxValue(precision: number, scale: number): Range {
  const maxValue = Math.pow(10, precision - scale) - Math.pow(10, -scale);
  return { max: maxValue, min: -maxValue };
}

export function isNumber(value: unknown): value is number {
  return typeof value === "number" && !isNaN(value) && isFinite(value);
}

function parseInt10(value: string): number {
  return parseInt(value, 10);
}

interface Converter {
  parser: (string: string) => number;
  numberTypeInfo: {
    min: number;
    max: number;
    supportsDecimal: boolean;
  };
}

/*! StartNoStringValidationRegion (type names) */
const propertyTypeConverters: Record<string, Converter> = {
  Decimal: {
    parser: parseFloat,
    numberTypeInfo: {
      // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
      min: -99999999999999999999,
      // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
      max: 99999999999999999999,
      supportsDecimal: true,
    },
  },

  Double: {
    parser: parseFloat,
    numberTypeInfo: {
      min: -999999999999999,
      max: 999999999999999,
      supportsDecimal: true,
    },
  },

  Single: {
    parser: parseFloat,
    numberTypeInfo: {
      min: -9999999,
      max: 9999999,
      supportsDecimal: true,
    },
  },

  Int16: {
    parser: parseInt10,
    numberTypeInfo: {
      min: -32768,
      max: 32767,
      supportsDecimal: false,
    },
  },

  Int32: {
    parser: parseInt10,
    numberTypeInfo: {
      min: -2147483648,
      max: 2147483647,
      supportsDecimal: false,
    },
  },

  Int64: {
    parser: parseInt10,
    numberTypeInfo: {
      min: Number.MIN_SAFE_INTEGER,
      max: Number.MAX_SAFE_INTEGER,
      supportsDecimal: false,
    },
  },

  Byte: {
    parser: parseInt10,
    numberTypeInfo: {
      min: 0,
      max: 255,
      supportsDecimal: false,
    },
  },
};
/*! EndNoStringValidationRegion */
