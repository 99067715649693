// TODO: Code can be simplified to truthy/falsy checks once entire codebase is converted to TypeScript and we can enforce argument types.

export type NullableString = string | null | undefined;

const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
const rawGuidRegex = /^([0-9a-f]{8})([0-9a-f]{4})([0-9a-f]{4})([0-9a-f]{4})([0-9a-f]{12})$/i;
let canvas: HTMLCanvasElement | undefined;

export const emptyGuid = "00000000-0000-0000-0000-000000000000";
export const restrictedPlaceholder = "\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF";

export function capitalizeWords(value: string): string {
  return isString(value) ? value.replace(/(?:^|\s)\S/g, (s) => s.toUpperCase()) : "";
}

export function commaSeparate(...values: NullableString[]): string {
  return extractNonEmptyStrings(values).join(", ");
}

export function commaSeparateHumanReadable(...values: NullableString[]): string {
  const nonNullValues = extractNonEmptyStrings(values);

  if (nonNullValues.length > 0) {
    return nonNullValues.length === 1
      ? nonNullValues[0]
      : `${nonNullValues.slice(0, -1).join(", ")} and ${nonNullValues.slice(-1)}`;
  }

  return "";
}

/** @deprecated Use the native string function endsWith */
export function endsWith(str: NullableString, value: NullableString): boolean {
  return isString(str) && isString(value) && str.endsWith(value);
}

/** @deprecated Use the native string function includes */
export function contains(str: NullableString, value: NullableString): boolean {
  return isString(str) && isString(value) && str.includes(value);
}

export function equalsIgnoreCase(value1: NullableString, value2: NullableString): boolean {
  const value1IsEmpty = !isNonEmptyString(value1);
  const value2IsEmpty = !isNonEmptyString(value2);

  if (value1IsEmpty) {
    return value2IsEmpty;
  } else if (value2IsEmpty) {
    return false;
  } else {
    return value1.toUpperCase() === value2.toUpperCase();
  }
}

export function format(value: NullableString, ...args: unknown[]): string {
  if (isNonEmptyString(value)) {
    return value.replace(/\{(\d+)\}/g, (match, number) => {
      const arg: unknown = args[number];
      return arg === undefined ? "" : String(arg);
    });
  }

  return "";
}

export function htmlEscape(value: NullableString): string {
  if (isNonEmptyString(value)) {
    /*! StartNoStringValidationRegion HTML characters */
    return value
      .replace(/&/g, "&amp;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#39;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    /*! EndNoStringValidationRegion */
  }
  return "";
}

export function isBoolean(value: NullableString): boolean {
  return value === "true" || value === "false";
}

export function tryGetGuidOrValue(value: string): string {
  if (isNonEmptyString(value)) {
    return value.replace(rawGuidRegex, "$1-$2-$3-$4-$5");
  }

  return "";
}

export function isEmptyGuid(value: string): boolean {
  return isNullOrWhitespace(value) || value === emptyGuid;
}

export function isGuid(value: NullableString): boolean {
  return isString(value) && guidRegex.test(value);
}

export function isGuidArray(value: unknown): boolean {
  return value != null && value.toString().split(",").every(isGuid);
}

export function isNonEmptyGuid(value: NullableString): boolean {
  return isGuid(value) && value !== emptyGuid;
}

export function isNonEmptyString(value: unknown): value is string {
  return isString(value) && value.length > 0;
}

/** @deprecated Use isNonEmptyString instead that also supports type checking */
export function isNullOrEmpty(value: unknown): boolean {
  return !isString(value) || !value.length;
}

export function isNullOrWhitespace(value: unknown): boolean {
  return !isString(value) || !value.trim().length;
}

// From http://stackoverflow.com/a/2901298
export function numberWithCommas(value: number): string {
  const parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

/** @deprecated Use the native string function padStart */
export function padLeft(value: string, length: number, char?: string): string {
  return value.padStart(length, char);
}

export function regexpEscape(value: NullableString): string {
  if (isNonEmptyString(value)) {
    return value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  return "";
}

/** @deprecated Use the native string function startsWith */
export function startsWith(str: NullableString, value: NullableString): boolean {
  return isString(str) && isString(value) && str.startsWith(value);
}

export function startsWithIgnoreCase(str: NullableString, value: NullableString): boolean {
  return isString(str) && isString(value) && str.toUpperCase().startsWith(value.toUpperCase());
}

/** @deprecated Use the native string function trim */
export function trim(value: NullableString): string {
  return isString(value) ? value.trim() : "";
}

export function xmlEscape(value: NullableString): string {
  if (isNonEmptyString(value)) {
    /*! StartNoStringValidationRegion XML characters */
    return value
      .replace(/&/g, "&amp;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&apos;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    /*! EndNoStringValidationRegion */
  }

  return "";
}

export function toBoolean(value: unknown): boolean {
  if (!isString(value)) {
    return !!value;
  }

  return value === "true";
}

export function getRenderedTextWidth(textToMeasure: NullableString, font: string): number {
  if (isNullOrWhitespace(font)) {
    throw new Error("Font argument was not given, unable to measure.");
  }
  if (!isNonEmptyString(textToMeasure)) {
    return 0;
  }
  if (!canvas) {
    canvas = document.createElement("canvas");
  }
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Cannot create canvas context, unable to measure.");
  }
  context.font = font;
  const metrics = context.measureText(textToMeasure);
  return metrics.width;
}

/** Only to be used in tests to clear the cached canvas */
export function clearCachedCanvas(): void {
  canvas = undefined;
}

export function kebabToCamelCase(value: string): string {
  return value.toLowerCase().replace(/-[a-z]/g, (g) => g[1].toUpperCase());
}

export function kebabToPascalCase(value: string): string {
  return kebabToCamelCase(value).replace(/^[a-z]/g, (g) => g[0].toUpperCase());
}

export function toArraySafe<T>(value: NullableString): T[] {
  const array = tryParseJson<T[]>(value);
  return Array.isArray(array) ? array : [];
}

export function tryParseJson<T>(value: NullableString): T | undefined {
  try {
    return isNonEmptyString(value) ? (JSON.parse(value) as T) : undefined;
  } catch (e) {
    // Don't care.
    return undefined;
  }
}

export function tryParseJsonWithStatus<T>(value: string): {
  parsed: boolean;
  json?: T;
  message?: string | undefined;
} {
  try {
    return { parsed: true, json: JSON.parse(value) };
  } catch (e) {
    return { parsed: false, message: e instanceof Error ? e.message : "" };
  }
}

function isString(value: unknown): value is string {
  return typeof value === "string";
}

function extractNonEmptyStrings(values: NullableString[]): string[] {
  return values.reduce<string[]>((a, s) => {
    if (isNonEmptyString(s)) {
      a.push(s);
    }
    return a;
  }, []);
}
