import ajaxService from 'AjaxService';
import errorReportingHelper from 'ErrorReportingHelper';
import { getModuleValue } from 'ModuleLoader';
import global from 'Global';
import log from 'Log';
import Storage from 'Storage';
import userSession from 'UserSession';

const errorStorageKey = 'PendingErrors';

class ErrorReportingService {
	constructor() {
		this._worker = null;
	}

	reset() {
		disposeWorker(this);
	}

	sendErrorReport(error, userErrorDescription, errorID) {
		const payload = {
			errorDescription: userErrorDescription,
			errors: errorReportingHelper.getReportableErrors(error),
			uri: window.location.href,
			userName: userSession.sessionData().userName,
			userEmailAddress: userSession.sessionData().userEmailAddress,
			errorID,
		};

		/*! SuppressStringValidation No captions here */
		getWorker(this).postMessage({ action: 'process', payload });
	}

	async sendPendingErrorReportsAsync() {
		const errors = getPendingErrors();

		if (errors && errors.length > 0) {
			const isUploaded = await uploadErrorsAsync(errors);
			if (isUploaded) {
				const publicStorage = Storage.public();
				let currentErrors = getPendingErrors();
				if (currentErrors) {
					if (currentErrors.length === errors.length) {
						publicStorage.remove(errorStorageKey);
					}
					if (currentErrors.length > errors.length) {
						currentErrors = currentErrors.slice(errors.length);
						publicStorage.set(errorStorageKey, currentErrors);
					}
				}
			}
		}
	}

	getErrorID() {
		return getErrorID();
	}
}

function disposeWorker(self) {
	const worker = self._worker;
	if (worker) {
		worker.terminate();
		self._worker = null;
	}
}

function getWorker(self) {
	let worker = self._worker;
	if (!worker) {
		const ErrorReportingWebWorker = getModuleValue(require('ErrorReportingWebWorker'));
		worker = new ErrorReportingWebWorker();
		worker.addEventListener('message', onErrorReportGeneratedAsync);
		self._worker = worker;
	}

	return worker;
}

function getPendingErrors() {
	return Storage.public().get(errorStorageKey);
}

function enqueueErrorReport(errorReport) {
	const publicStorage = Storage.public();
	let errors = publicStorage.get(errorStorageKey);
	if (!errors) {
		errors = [];
	}
	errors.push(errorReport);
	publicStorage.set(errorStorageKey, errors);
}

async function onErrorReportGeneratedAsync(e) {
	const errorReport = e.data;
	if (userSession.isLoggedOn()) {
		const isUploaded = await uploadErrorsAsync([errorReport]);
		if (!isUploaded) {
			enqueueErrorReport(errorReport);
		}
	}
	else {
		enqueueErrorReport(errorReport);
	}
}

async function uploadErrorsAsync(errorReports) {
	errorReports = errorReports.filter((e) => e && e.error);
	if (!errorReports.length) {
		return Promise.resolve(true);
	}

	errorReports.forEach((errorReport) => {
		cleanUpDataItemValues(errorReport.error);
	});

	/*! StartNoStringValidationRegion Suppressed in initial refactor */
	const request = {
		url: global.rootPath + 'errors/reportErrors',
		type: 'post',
		data: JSON.stringify(errorReports),
		contentType: 'application/json'
	};
	/*! EndNoStringValidationRegion */

	try {
		await ajaxService.ajaxAsync(request);
		return true;
	}
	catch (error) {
		log.error(`Failed to upload error report. Reason: ${error ? error.message : undefined}`);
		return false;
	}
}

function getErrorID() {
	let errorID = 'R';
	for (let i = 0; i < 18; i++) {
		const random = Math.floor(Math.random() * 10);
		errorID += random;
	}
	return errorID;
}

function cleanUpDataItemValues(error) {

	for (let i = 0; error.data && i < error.data.length; i++) {
		if (typeof error.data[i].value !== 'string') {
			error.data[i].value = JSON.stringify(error.data[i].value);
		}
	}

	if (error.innerError) {
		cleanUpDataItemValues(error.innerError);
	}
}

export default new ErrorReportingService();
