export interface AppConfig {
  audioRetryDelayMinutes: number;
  connectionPollingIntervalSeconds: number;
  contentContainer: string;
  dependencyVisitorCacheLimit: number;
  initialPage: string;
  locationTrackingMaxSearchDetailsCount: number;
  locationTrackingRefreshIntervalSeconds: number;
  locationTrackingRoadDataPointsOverlap: number;
  locationTrackingRoadMaxSnapPoints: number;
  locationTrackingRouteThresholdDataPoints: number;
  lookupCacheLimit: number;
  messageTimeout: number;
  storageChangedTimeout: number;
}

/*! StartNoStringValidationRegion No captions here. */
const appConfig: AppConfig = {
  audioRetryDelayMinutes: 10,
  connectionPollingIntervalSeconds: 30,
  contentContainer: "#g-content",
  dependencyVisitorCacheLimit: 2000,
  initialPage: "index",
  locationTrackingMaxSearchDetailsCount: 100,
  locationTrackingRefreshIntervalSeconds: 60,
  locationTrackingRoadDataPointsOverlap: 10,
  locationTrackingRoadMaxSnapPoints: 100,
  locationTrackingRouteThresholdDataPoints: 2880,
  lookupCacheLimit: 100,
  messageTimeout: 4000,
  storageChangedTimeout: 250,
};
/*! EndNoStringValidationRegion */

export default appConfig;
