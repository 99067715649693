import captionService from 'CaptionService';
import widgetFactory from 'WidgetFactory';
import widgetUtils from 'WidgetUtils';
import $ from 'jquery';

function CaptionPresentationService() {
}

CaptionPresentationService.prototype.getCaptionThatFits = ($elementToTestWith, captionsToTest, maxWidth) => {
	return getCaptionThatFits($elementToTestWith, captionsToTest, () => maxWidth);
};

CaptionPresentationService.prototype.resizeCaptionForWidget = ($label, caption) => {
	const availableWidth = () => $label.parent().width();
	const captionsToTest = [caption.short, caption.medium, caption.long];
	const captionThatFits = getCaptionThatFits($label, captionsToTest, availableWidth);
	setCaption($label, captionThatFits);
};

CaptionPresentationService.prototype.resizeCaptions = function ($context) {
	const self = this;
	widgetFactory.select($context, /*includeSelf:*/ true).each((_, element) => {
		self.resizeCaption(widgetUtils.getLabelForElement($(element)));
	});
};

CaptionPresentationService.prototype.resizeCaption = function ($label) {
	if ($label && $label.length) {
		let caption = $label.data('captionDetail');

		if (caption) {
			if (caption.ResKey) {
				caption = captionService.getCaptionFromKey(caption.ResKey, caption.Fallback);
			}

			this.resizeCaptionForWidget($label, caption);
		}
	}
};

function getCaptionThatFits($elementToTestWith, captionsToTest, maxWidthGetter) {
	const filteredCaptionsToTest = captionsToTest.filter(Boolean);
	if (filteredCaptionsToTest.length <= 1) {
		return filteredCaptionsToTest[0] || '';
	}

	let captionThatFits = filteredCaptionsToTest[0];
	const maxWidth = maxWidthGetter();
	$elementToTestWith.addClass('g-label-tester');
	for (let i = 1; i < filteredCaptionsToTest.length; i++) {
		const captionToTest = filteredCaptionsToTest[i];
		setCaption($elementToTestWith, captionToTest);
		const textWidth = $elementToTestWith.outerWidth();
		if (textWidth <= maxWidth) {
			captionThatFits = captionToTest;
		}
		if (textWidth >= maxWidth) {
			break;
		}
	}
	$elementToTestWith.removeClass('g-label-tester');

	return captionThatFits;
}

function setCaption($label, caption) {
	$label.html(caption);
}

export default new CaptionPresentationService();
