import ko from 'knockout';
import ajaxService from 'AjaxService';
import LRUCache from 'lru-cache';
import global from 'Global';
import connection from 'Connection';

const notRequiredResult = { isRequired: false };

class UserAgreementManager {
	constructor() {
		this._cache = new LRUCache({ max: 20 });
	}

	checkModuleAsync() {
		const portalCode = global.moduleName.toUpperCase();

		const cachedResult = this._cache.get(portalCode);
		if (cachedResult && !cachedResult.isRequired) {
			return Promise.resolve(cachedResult);
		}

		return ajaxService.getAsync(`${global.serviceUri}api/portalAgreements/get`, { portalCode })
			.then((result) => {
				if (!result || !result.isRequired) {
					this._cache.set(portalCode, notRequiredResult);

					return notRequiredResult;
				}

				const viewModel = new UserAgreementViewModel(portalCode, result);

				return { isRequired: true, viewModel };
			})
			.catch((error) => {
				if (error instanceof connection.OfflineError) {
					this._cache.set(portalCode, notRequiredResult);

					return notRequiredResult;
				}
				else {
					throw error;
				}
			});
	}
}

class UserAgreementViewModel {
	constructor(portalCode, agreement) {
		this._portalCode = portalCode;
		this.identifier = agreement.identifier;
		this.title = agreement.title;
		this.content = agreement.content;
		this.canSendEmail = agreement.canSendEmail;
		this.shouldSendAgreementCopy = ko.observable(false);
	}

	acknowledgeAgreementAsync() {
		const model = {
			portalCode: this._portalCode,
			identifier: this.identifier,
			shouldSendAgreementCopy: this.shouldSendAgreementCopy(),
		};

		return ajaxService.postAsync(`${global.serviceUri}api/portalAgreements/acknowledge`, model)
			.then(() => {
				global.windowLocation.reload();
			})
			.catch((error) => {
				if (error instanceof connection.OfflineError) {
					global.windowLocation.reload();
				}
				else {
					throw error;
				}
			});
	}
}

export default new UserAgreementManager();
