import type { Disposable } from "Disposable";
import { excludeReadOnlyProperty } from "EntityMetadataExtensions";
import userSession from "UserSession";
import breeze, { type Entity, type EntityManager } from "breeze-client";

class GlowMacroProvider {
  private readonly subscription: Disposable;
  private entityManager: EntityManager | undefined;
  private glowMacro: Entity | undefined;

  constructor() {
    this.entityManager = undefined;
    this.glowMacro = undefined;
    this.subscription = userSession.onIsLoggedOnChanged((newValue) => {
      if (!newValue) {
        this.glowMacro = undefined;
      }
    });
  }

  getGlowMacroEntity(): Entity {
    if (!this.entityManager) {
      /*! SuppressStringValidation Property name for key of an entity */
      const dataKeyPropertyName = "key";

      const glowMacroEntityType = new breeze.EntityType({
        shortName: "GlowMacro",
        autoGeneratedKeyType: breeze.AutoGeneratedKeyType.Identity,
        dataProperties: [
          new breeze.DataProperty({
            name: dataKeyPropertyName,
            dataType: breeze.DataType.Guid,
            isPartOfKey: true,
          }),
        ],
      });
      excludeReadOnlyProperty(glowMacroEntityType, true);

      this.entityManager = new breeze.EntityManager("/Glow/MacroService");
      this.entityManager.metadataStore.addEntityType(glowMacroEntityType);
    }

    if (!this.glowMacro) {
      this.glowMacro = this.entityManager.createEntity("IGlowMacro");
      enableOneTimeNotificationForEntity(this.glowMacro);
    }

    return this.glowMacro;
  }

  dispose(): void {
    this.subscription.dispose();
  }

  reset(): void {
    this.glowMacro = undefined;
  }
}

function enableOneTimeNotificationForEntity(entity: Entity): void {
  for (const name in entity) {
    const property = (entity as unknown as Record<string, MaybeCalculatedProperty | undefined>)[name];
    property?.disableSubscriptionsOnNextNotification?.();
  }
}

interface MaybeCalculatedProperty {
  disableSubscriptionsOnNextNotification?: () => void;
}

export default new GlowMacroProvider();
