import ko from "knockout";
import userSession from "UserSession";
import global from "Global";

export function getLanguageCode(): string | undefined {
  return ko.ignoreDependencies(() => {
    if (userSession.isLoggedOn()) {
      return userSession.sessionData().languageCode;
    }

    return global.languageCode;
  });
}

/** @deprecated use named import instead -- import { getLanguageCode } from 'LanguageService'; */
export default { getLanguageCode };
