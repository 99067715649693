import captionService from 'CaptionService';

export const headers = {
    categoryBoolean: () => captionService.getString('078b7d1b-f2da-413f-a555-e2c41dfc87c9', 'Flags'),
    categoryDate: () => captionService.getString('f6221e0a-27a4-4475-9a03-c206ac5dfa38', 'Dates'),
    categoryNumber: () => captionService.getString('eaea21c9-185e-49d9-b5e7-86a0861cf1ac', 'Numeric'),
    categoryText: () => captionService.getString('943e5423-6d95-4fea-82a5-d117c856fb68', 'Text'),
    addresses: () => captionService.getString('477948c5-c1f2-42c2-9c5d-c24a431fcaf3', 'Addresses'),
    measurement: () => captionService.getString('f5b1598e-58fc-4da0-9045-82f89c7b6b74', 'Measurement'),
    categoryGeo: () => captionService.getString('21d91a77-e44f-4df9-8046-2b04d4b6de2e', 'Geo Locations'),
    categoryCollection: () => captionService.getString('d8063450-7518-4212-8bae-3ff7674fa891', 'Related Records'),
};