import $ from 'jquery';
import ko from 'knockout';
import userSession from 'UserSession';
import settingService from 'SettingService';
import encodingUtils from 'EncodingUtils';
import constants from 'Constants';
import global from 'Global';

ko.bindingHandlers.gwHeaderLinksDisplayOption = {
	init(element) {
		setupDisplayOption({
			displaySetting: constants.DisplayModeSetting.Header,
			displayOptionIDs: {
				[constants.LinksDisplayMode.ButtonsAndText]: '#headerLinksDisplayOptionRadioButtonsAndText',
				[constants.LinksDisplayMode.ButtonsOnly]: '#headerLinksDisplayOptionRadioButtonsOnly',
				[constants.LinksDisplayMode.TextOnly]: '#headerLinksDisplayOptionRadioTextOnly'
			},
			defaultValue: constants.LinksDisplayMode.ButtonsAndText,
			$inputs: $(element).find('input[name=headerLinksDisplayOptionRadio]:radio'),
			popoverResizeRequired: true
		});
	}
};

ko.bindingHandlers.gwApplyHeaderLinksDisplayOption = {
	update(element) {
		applyLinksDisplayModeOption(element, constants.DisplayModeSetting.Header);
	}
};

ko.bindingHandlers.gwGridLinksDisplayOption = {
	init(element) {
		setupDisplayOption({
			displaySetting: constants.DisplayModeSetting.Grid,
			displayOptionIDs: {
				[constants.LinksDisplayMode.ButtonsAndText]: '#gridLinksDisplayOptionRadioButtonsAndText',
				[constants.LinksDisplayMode.ButtonsOnly]: '#gridLinksDisplayOptionRadioButtonsOnly',
				[constants.LinksDisplayMode.TextOnly]: '#gridLinksDisplayOptionRadioTextOnly'
			},
			defaultValue: constants.LinksDisplayMode.ButtonsAndText,
			$inputs: $(element).find('input[name=gridLinksDisplayOptionRadio]:radio')
		});
	}
};

ko.bindingHandlers.gwApplyGridLinksDisplayOption = {
	update(element) {
		applyLinksDisplayModeOption(element, constants.DisplayModeSetting.Grid);
	}
};

ko.bindingHandlers.gwFilterLinksDisplayOption = {
	init(element) {
		setupDisplayOption({
			displaySetting: constants.DisplayModeSetting.Filter,
			displayOptionIDs: {
				[constants.LinksDisplayMode.ButtonsAndText]: '#filterLinksDisplayOptionRadioButtonsAndText',
				[constants.LinksDisplayMode.ButtonsOnly]: '#filterLinksDisplayOptionRadioButtonsOnly',
				[constants.LinksDisplayMode.TextOnly]: '#filterLinksDisplayOptionRadioTextOnly'
			},
			defaultValue: constants.LinksDisplayMode.ButtonsAndText,
			$inputs: $(element).find('input[name=filterLinksDisplayOptionRadio]:radio')
		});
	}
};

ko.bindingHandlers.gwApplyFilterLinksDisplayOption = {
	update(element) {
		applyLinksDisplayModeOption(element, constants.DisplayModeSetting.Filter);
	}
};

ko.bindingHandlers.gwAlertDisplayTimeOption = {
	init(element) {
		setupDisplayOption({
			displaySetting: constants.DisplayModeSetting.AlertDisplayTime,
			displayOptionIDs: {
				[constants.AlertDisplayTime.Short]: '#alertDisplayTimeOptionRadioShort',
				[constants.AlertDisplayTime.Long]: '#alertDisplayTimeOptionRadioLong',
				[constants.AlertDisplayTime.UntilDismissed]: '#alertDisplayTimeOptionRadioUntilDismissed'
			},
			defaultValue: constants.AlertDisplayTime.Short,
			$inputs: $(element).find('input[name=alertDisplayTimeOptionRadio]:radio')
		});
	}
};

function setupDisplayOption({ displaySetting, displayOptionIDs, defaultValue, $inputs, popoverResizeRequired }) {
	settingService.getSettingAsync(displaySetting).then((value) => {
		value = encodingUtils.decodeData(value, defaultValue);
		$(displayOptionIDs[value]).prop('checked', true);
	});

	$inputs.on('change', (event) => {
		const value = $(event?.currentTarget).val();
		$inputs.attr('disabled', true);
		settingService.saveSettingAsync(displaySetting, encodingUtils.encodeData(value)).finally(() => {
			$inputs.attr('disabled', false);
		});

		if (popoverResizeRequired) {
			$('.popover').trigger('resize');
		}
	});
}

function applyLinksDisplayModeOption(element, targetDisplaySetting) {
	if (userSession.isLoggedOn()) {
		const observable = settingService.getObservableSetting(targetDisplaySetting);
		let value = observable();
		const $element = $(element);
		$element.removeClass('text-only buttons-only');
		value = encodingUtils.decodeData(value, constants.LinksDisplayMode.ButtonsAndText);

		if (global.isPortable()) {
			$element.addClass('buttons-only');
		} else {
			switch (value) {
				case constants.LinksDisplayMode.ButtonsOnly:
					$element.addClass('buttons-only');
					break;
				case constants.LinksDisplayMode.TextOnly:
					$element.addClass('text-only');
					break;
			}
		}
	}
}
