export default class ProposedValue {
  private isNoResult: boolean;
  readonly Value: unknown;

  constructor(value: unknown) {
    this.Value = value;
    this.isNoResult = false;
  }

  get IsNoResult(): boolean {
    return this.isNoResult;
  }

  static readonly NoResult = ProposedValue.createNoResult();

  private static createNoResult(): ProposedValue {
    const result = new ProposedValue(null);
    result.isNoResult = true;
    return result;
  }
}
