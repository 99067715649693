import ajaxService from 'AjaxService';
import constants from 'Constants';
import errors from 'Errors';
import global from 'Global';
import ruleDependencyValue from 'RuleDependencyValue2';
import { joinUri } from 'UriUtils';
import Promise from 'bluebird';

function ServerSideRule(component, valueConverter, returnType) {
	this.uri = component.uri;
	this._valueConverter = valueConverter;
	this.parameters = component.parameters || [];
	this._returnType = returnType;
	this.hasCacheKeyFunc = false;
	this.dependencies = component.dependencies || [];
}

ServerSideRule.prototype.processAsync = function (rule, entity) {
	let loadingDependencies;
	if (this.dependencies.length) {
		const dependencies = this.dependencies.map((path) => {
			return { value: `<${path}>` };
		});
		loadingDependencies = ruleDependencyValue.getValuesAsync(entity, dependencies);
	}

	const self = this;
	return Promise.wait(loadingDependencies, () => {
		return ruleDependencyValue
			.getSerializableValuesByNameAsync(entity, this.parameters)
			.then((result) => {
				if (result.state === constants.States.Available) {
					return processCoreAsync(self, result.values, rule, entity);
				}
				else {
					throw new errors.UnavailableArgumentsOrSecurityError();
				}
			});
	});
};

ServerSideRule.prototype.tryProcessSync = () => {
	return { state: constants.States.NotLoaded };
};

ServerSideRule.prototype.hasSetter = () => {
	return false;
};

ServerSideRule.prototype.invokeSetterAsync = () => {
	return Promise.resolve(false);
};

function processCoreAsync(serverSideRule, valuesByName, rule, entity) {
	const uri = joinUri(global.serviceUri, serverSideRule.uri);
	const query = {};

	for (const name in valuesByName) {
		const value = valuesByName[name];
		if (typeof value !== 'undefined' && value !== null) {
			query[name] = value;
		}
	}

	return ajaxService.getAsync(uri, query).catch((error) => {
		if (error instanceof ajaxService.AjaxError) {
			/*! SuppressStringValidation no entity type indicator */
			const entityInterfaceName = entity && entity.entityType ? entity.entityType.interfaceName : 'N/A';
			throw new errors.RuleInvocationException(entityInterfaceName, rule.ruleId, rule.property, error);
		}
		throw error;
	}).then((value) => {
		return { isSuccess: true, value: convertValue(serverSideRule, value) };
	});
}

function convertValue(serverSideRule, value) {
	const converter = serverSideRule._valueConverter;
	return converter ? converter(value, serverSideRule._returnType) : value;
}

export default ServerSideRule;
