import Promise from 'bluebird';
import _ from 'underscore';
import dialogService from 'DialogService';
import { loadTemplateAsync } from 'ModuleLoader';
import captionService from 'CaptionService';
import notificationType from 'NotificationType';

class SelectUserViewModel {
	constructor(contexts, onSelected) {
		this.contexts = _.sortBy(contexts, (item) => item.description ? item.description.toLowerCase() : '');
		this.onSelected = onSelected;
	}
}

async function selectUserAsync(contexts) {
	if (!contexts || !contexts.length) {
		return dialogService.alertAsync(
			notificationType.Error,
			captionService.getString('ef7fa04f-de73-44dc-ae7a-54d66ddc40b5', 'You do not have permission to log in to this portal. Contact your system administrator to be granted appropriate permissions.'),
			captionService.getString('7a76a8fe-56fd-4f45-bdc9-87e7f71d4061', 'Unable to login'))
			.return(null);
	}

	let selectedContext;

	const uniqUsers = _.uniq(contexts, (x) => x.userKey);
	if (uniqUsers.length === 1) {
		selectedContext = uniqUsers[0];

		return Promise.resolve(selectedContext);
	}

	const onSelected = (value) => {
		selectedContext = value;
		dialogService.hide(dialogInfo);
	};
	const viewModel = new SelectUserViewModel(uniqUsers, onSelected);
	const bodyDfd = loadTemplateAsync('SelectUserDialog.html');
	const buttonOptions = [{
		caption: dialogService.buttonTypes.Cancel().text,
		result: dialogService.buttonTypes.Cancel().value,
		isDismiss: true,
	}];
	const resultDfd = Promise.deferred();

	/*! StartNoStringValidationRegion dialogCss is not a caption */
	const dialogInfo = await dialogService.showDialogAsync({
		title: captionService.getString('ad4d20ac-ba34-4f5e-9a6b-2b819bfff69c', 'Account Selection'),
		viewModel,
		bodyAllowHtml: true,
		bodyDeferred: bodyDfd,
		dialogCss: 'g-context-change-dialog',
		buttonOptions,
		preHideCallback: () => {
			resultDfd.resolve(selectedContext);
		}
	});
	/*! EndNoStringValidationRegion */

	return resultDfd.promise();
}

export default {
	selectUserAsync,
};
