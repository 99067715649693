import captionService from 'CaptionService';
import constants from 'Constants';
import ko from 'knockout';
import LabelViewModel from 'LabelViewModel';
import template from 'WidgetTemplates/gwLabel.ejs';
import * as stringUtils from 'StringUtils';

function createViewModel(params) {
	params.isEntityBoundLabel = params.controlType === constants.ControlTypes.Label;

	return new LabelViewModel(params);
}

/** @this { elementObsoleteDoNotUse: componentInfo.$elementObsoleteDoNotUse, container: componentInfo.$container } */
function getTitle() {
	const viewModel = ko.dataFor(this.elementObsoleteDoNotUse[0]);
	if (viewModel.isEntityBoundLabel) {
		const dataItem = viewModel.dataItem();
		const propertyName = viewModel.propertyName;
		const labelNotificationType = viewModel.labelNotificationType;
		const propertyCaption = dataItem && dataItem.entityType ? captionService.getCaptionFromField({ entityName: dataItem.entityType.interfaceName, propertyName }).caption : propertyName;

		return stringUtils.format('{0} ({1})', propertyCaption, labelNotificationType);
	}

	return viewModel.labelText();
}

/** @this { elementObsoleteDoNotUse: componentInfo.$elementObsoleteDoNotUse, container: componentInfo.$container } */
function getWidget() {
	return this.container.find(componentConfig.widgetSelector);
}

const componentConfig = {
	template,
	widgetSelector: '.gwLabel',
	viewModel: { createViewModel },
	methods: {
		getTitle,
		getWidget,
	}
};

export default componentConfig;
