import ko from 'knockout';
import $ from 'jquery';
import buttonHelper from 'ButtonHelper';
import { getPageExtensions } from 'PageExtensions';

ko.bindingHandlers.gwCommand = {
	init(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		$(element).on('click', (e) => {
			e.stopPropagation();

			// Needed to let the form submit, and save the autocomplete data, before the page is redirected
			setTimeout(() => {
				const settings = valueAccessor();
				if (settings.isProcessing === undefined || !settings.isProcessing()) {
					const pageExtensions = getPageExtensions(bindingContext);
					settings.onClick({ messageBus: pageExtensions.messageBus });
				}
			}, 0);
		});
	},
	update(element, valueAccessor) {
		const settings = valueAccessor();
		if (settings.isProcessing !== undefined) {
			buttonHelper.setLoadingState($(element), !!ko.unwrap(settings.isProcessing));
		}
	}
};
