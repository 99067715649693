import { deleteDB, openDB } from "idb";
import type { AggregateStrategy } from "./AggregateStrategy.ts";
import { dbPrefix, storeName } from "./Constants.ts";
import type { PersistentStore } from "./PersistentStore.ts";
import { StorageError } from "./StorageError.ts";

export class IdbAggregateStrategy implements AggregateStrategy {
  private readonly dbNames: string[];
  private readonly dbNamesStore: () => PersistentStore;

  private constructor(dbNames: string[], dbNamesStore: () => PersistentStore) {
    this.dbNames = dbNames;
    this.dbNamesStore = dbNamesStore;
  }

  static async createAsync(dbNamesStore: () => PersistentStore): Promise<IdbAggregateStrategy> {
    const dbNames = await getDBNamesAsync(dbNamesStore);
    return new IdbAggregateStrategy(dbNames, dbNamesStore);
  }

  async clearStorageAsync(
    storeCache: Map<string, PersistentStore>,
    dbNameSuffix: string
  ): Promise<void> {
    try {
      const dbs = this.dbNames.filter((name) => name.startsWith(`${dbPrefix}${dbNameSuffix}`));
      await Promise.all(
        dbs.map(async (name) => {
          const isDBInUse = storeCache.has(name.substring(dbPrefix.length));
          if (isDBInUse) {
            const db = await openDB(name, 1);
            try {
              await db.clear(storeName);
            } finally {
              db.close();
            }
          } else {
            await deleteDB(name);
            return this.dbNamesStore().deleteAsync(name);
          }
        })
      );
    } catch (error) {
      throw new StorageError(
        `Failed to clear aggregate storage for suffix '${dbNameSuffix}'.`,
        error
      );
    }
  }
}

async function getDBNamesAsync(dbNamesStore: () => PersistentStore): Promise<string[]> {
  try {
    if (indexedDB.databases) {
      const dbs = await indexedDB.databases();
      return dbs.map(({ name }) => name).filter(Boolean) as string[];
    }

    return await dbNamesStore().getAllValuesAsync();
  } catch (error) {
    throw new StorageError("Failed to get DB names for aggregate store.", error);
  }
}
