import ko from 'knockout';
import * as stringUtils from 'StringUtils';

function RuleCommandResult(errorMessage) {
	this.errorMessage = errorMessage;
	this.isSuccess = ko.pureComputed(() => {
		return stringUtils.isNullOrEmpty(this.errorMessage);
	});
}

RuleCommandResult.Success = new RuleCommandResult();

RuleCommandResult.Error = (errorMessage) => {
	return new RuleCommandResult(errorMessage);
};

export default RuleCommandResult;
