import $ from 'jquery';
import ko from 'knockout';
import visibilityEvaluator from 'VisibilityEvaluator';

ko.bindingHandlers.gwComponentVisibility = {
	update(element, valueAccessor) {
		const params = ko.unwrap(valueAccessor());
		const isVisible = visibilityEvaluator.isVisible(ko.unwrap(params.rootDataItem), ko.unwrap(params.dataItem), params.condition);

		$(element).closest('.g-container').toggle(isVisible);
	}
};
