import $ from 'jquery';
import { restrictedPlaceholder } from 'StringUtils';

export default function ($container, params) {
	if (params.isReadRestrictedAsync) {
		return params.isReadRestrictedAsync().then((isRestricted) => {
			if (isRestricted) {
				const $inputAndtextAreaElement = $container.find('input:not(.gw-img-input,.gwCheckBox__input),textarea');
				if ($inputAndtextAreaElement.length === 0) {
					setToolTip($container);
					$container.addClass('g-read-restricted');
					$container.prepend($('<i class="icon-eye-slash"></i>'));
				}
				else {
					$.each($inputAndtextAreaElement, (i, element) => setMaskingRestriction($(element)));
				}
			}
		});
	}
}

function setToolTip($element) {
	const currentDataBind = $element.attr('data-bind');
	const tooltipBottomDataBind = "gwTooltip: { tooltip: $caption('6a0def31-0b95-4402-b421-03ad1edb0ef1', 'Access denied due to read restrictions'), placement: 'top' }";
	const dataBindValue = currentDataBind ? currentDataBind + ', ' + tooltipBottomDataBind : tooltipBottomDataBind;
	$element.attr('data-bind', dataBindValue);
}

function setMaskingRestriction($element) {
	setToolTip($element);
	$element.addClass('g-read-restricted')
		.attr('placeholder', restrictedPlaceholder);
}
