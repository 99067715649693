import { commaSeparate } from 'StringUtils';
import widgetUtils from 'WidgetUtils';
import captionService from 'CaptionService';
import captionPresentationService from 'CaptionPresentationService';
import bindingEvaluator from 'BindingEvaluator';

function KOCaptionComponentDecorator() {
}

KOCaptionComponentDecorator.prototype.decorate = (component) => {
	const $component = component.$elementObsoleteDoNotUse;
	const params = component.params;

	const caption = params.caption;
	if (!captionService.isEmptyCaption(caption)) {
		applyCaption($component, caption);
	} else if (!captionService.isEmptyCaption(params.captionOverride)) {
		applyCaption($component, params.captionOverride);
	} else {
		applyFallbackCaption(component);
	}
};

function applyCaption($component, caption) {
	const $caption = widgetUtils.getLabelForElement($component);
	if ($caption.length > 0) {
		const resKey = caption.key || caption.ResKey;
		if (resKey) {
			const fallback = caption.text || caption.Fallback;
			const bind = commaSeparate($caption.attr('data-bind'), "gwCaption: '" + resKey + "'");
			$caption.attr('data-bind', bind).text(fallback);
		} else if (typeof caption === 'string') {
			$caption.text(caption);
		} else {
			captionPresentationService.resizeCaptionForWidget($caption, caption);
		}
	}
}

function applyFallbackCaption(component) {
	const $component = component.$elementObsoleteDoNotUse;
	const instance = component.instance;
	const params = component.params;

	let caption;
	if (!params.skipCaptionFallback) {
		const bindingPath = params.bindingPath;
		if (bindingPath) {
			if (params.dataItemEntityType) {
				const bindingInformation = bindingEvaluator.getEntityBindingInfo(params.dataItemEntityType, bindingEvaluator.getPropertyName(bindingPath));
				caption = captionService.getCaptionFromField(bindingInformation);

				if (instance.applyCaption) {
					instance.applyCaption.call(component, caption);
					return;
				}
			}
		}
	}

	applyCaption($component, caption || '');
}

export default new KOCaptionComponentDecorator();
