import widgetUtils from 'WidgetUtils';

function InitialFocusExtension($view) {
	this._$view = $view;
}

InitialFocusExtension.prototype.applyAsync = function () {
	const $view = this._$view;
	return widgetUtils.waitInitAllWidgetsAsync($view).then(() => {
		widgetUtils.setInitialFocus($view);
	});
};

export default InitialFocusExtension;
