export function joinUri(...values: string[]): string {
  let result = values[0] || "";

  for (let i = 1; i < values.length; i++) {
    const right = values[i];
    if (right) {
      const leftHasSlash = result.endsWith("/");
      const rightHasSlash = right.startsWith("/");

      if (leftHasSlash && rightHasSlash) {
        result += right.substring(1);
      } else if (leftHasSlash || rightHasSlash) {
        result += right;
      } else {
        result += "/" + right;
      }
    }
  }

  return result;
}

export function isAbsoluteUri(uri: string): boolean {
  const r = /^(?:[a-z]+:)?\/\//i;
  return r.test(uri);
}

export function getQueryStringValue(url: string, paramName: string): string | null {
  if (!url) {
    return null;
  }

  let validUrl;
  try {
    validUrl = new URL(url);
  } catch (error) {
    validUrl = undefined;
  }

  let result = validUrl?.searchParams.get(paramName) || null;
  if (!result) {
    const partOfUrl = validUrl ? validUrl.hash : url;
    const index = partOfUrl.indexOf("?");
    if (index > -1) {
      const searchParams = new URLSearchParams(partOfUrl.substring(index + 1));
      result = searchParams.get(paramName);
    }
  }

  return result;
}

export function getRouteName(serviceUri: string): string {
  const match = serviceUri.match(/([^/]+)(\/)*$/);
  const routeName = match && match[1];
  if (!routeName) {
    throw new Error('Could not determine route for service "' + serviceUri + '".');
  }

  return routeName;
}

type UriPathAndParams = {
  path: string;
  params: Record<string, string>;
};

export function getUriPathAndParams(uri: string, base: string): UriPathAndParams {
  const url = new URL(uri, base);
  const params: Record<string, string> = {};
  const queryInHashIndex = url.hash ? url.hash.indexOf("?") : -1;
  let hash = "";
  if (queryInHashIndex > -1) {
    const searchParams = new URLSearchParams(url.hash.substring(queryInHashIndex + 1));
    searchParams.forEach((value, key) => (params[key] = value));
    hash = url.hash.substring(0, queryInHashIndex);
  } else {
    hash = url.hash;
    url.searchParams.forEach((value, key) => (params[key] = value));
  }

  return { path: url.origin + url.pathname + hash, params };
}
