import { PromiseCacheItem } from "PromiseCacheItem";

export class AsyncDictionary<T> {
  private readonly items: Map<string, PromiseCacheItem<T>>;

  constructor() {
    this.items = new Map();
  }

  clear(): void {
    this.items.clear();
  }

  getAsync(key: string): Promise<T | undefined> {
    const item = this.items.get(key);
    if (item) {
      const promise = item.accessCache();
      if (promise) {
        return promise;
      }
    }
    return Promise.resolve(undefined);
  }

  getOrAddAsync(key: string, factory: (key: string) => Promise<T>): Promise<T> {
    let item = this.items.get(key);
    if (item) {
      const promise = item.accessCache();
      if (promise) {
        return promise;
      }
    }

    item = new PromiseCacheItem();
    const promise = getCacheItemPromiseAsync(item, factory(key));
    item.cachePromise(promise);
    this.items.set(key, item);
    return promise;
  }

  remove(key: string): void {
    this.items.delete(key);
  }

  removeWhere(callback: (key: string) => boolean): void {
    this.items.forEach((_, key) => {
      if (callback(key) === true) {
        this.remove(key);
      }
    });
  }
}

async function getCacheItemPromiseAsync<T>(item: PromiseCacheItem<T>, promise: Promise<T>): Promise<T> {
  try {
    const value = await promise;
    item.setResolved(value);
    return value;
  } finally {
    item.clearCachedPromise();
  }
}
