import ConfigureMenuViewModel from 'ConfigureMenuViewModel';
import FavoritesViewModel from 'FavoritesViewModel';
import global from 'Global';
import helpPresenter from 'HelpPresenter';
import menuItemsProvider from 'MenuItemsProvider';
import navigationService from 'NavigationService';
import profilePhotoService from 'ProfilePhotoService';
import { getLoadingCaption, observeTitle } from 'TitleService';
import userSession from 'UserSession';
import $ from 'jquery';
import ko from 'knockout';
import _ from 'underscore';

export default class PageViewModel {
	constructor(options) {
		options = $.extend({}, options);

		this._rawTitle = options.title || global.portalInfo.title;
		this.titleArgs = options.titleArgs;
		this.contentViewModel = options.contentViewModel;
		this.isAccessDenied = options.isAccessDenied === true;
		this.isHomePage = options.isHomePage === true;
		this.hideHomeButton = options.isLogonPage || global.portalInfo.hideHomeButton;
		this.isLocationTracking = this._rawTitle === 'Location Tracking';
		this.isStaffProfileForm = this._rawTitle === 'StaffProfileForm';
		this.isLoggedOn = userSession.isLoggedOn();

		const userData = userSession.sessionData();
		this.isAllowedToConfigureHelp = isStaff(userData);
		/*! SuppressStringValidation title is not a caption */
		this.isDashboardPage =
			this._rawTitle ===
			(isStaff(userData)
				? 'Global Staff Portal Dashboard'
				: 'Global Contact Portal Dashboard');
		this.profileImage = getProfileImage(userData);
		this.showMyProfileMenuItem = isStaff(userData) && !this.isStaffProfileForm;
		this.userData = userData;
		this.pageExtensions = options.pageExtensions;

		this.postNavigateByHref = postNavigateByHref;
		this.favoritesViewModel = new FavoritesViewModel();
		this._configureMenuViewModel = new ConfigureMenuViewModel(
			options.isInConfigurationMode,
			options.configurationParentID,
			options.additionalMenuOptions && options.additionalMenuOptions.isTaskPage,
			options.configurationEntityPK
		);

		this.moreMenuItems = ko.pureComputed(
			getMoreMenuItems.bind(
				null,
				this,
				_.pick(
					options,
					'workflowPK',
					'workflowName',
					'isInConfigurationMode',
					'additionalMenuOptions'
				)
			)
		);
		this.helpMenuItems = ko.pureComputed(this._getHelpMenuItems, this);
		this.others = options.additionalMenuOptions && options.additionalMenuOptions.others;
		this.jumpMenuItems = ko.pureComputed(menuItemsProvider.getJumpMenuItems);
		this.workflowMenuItems = ko.pureComputed(
			menuItemsProvider.getWorkflowMenuItems.bind(null, options.additionalMenuOptions)
		);
		this.pinnableMenuItems = ko.pureComputed(this._getPinnableMenuItems, this);
		this.customPinnableMenuItems = ko.observableArray().extend({ deferred: true });

		const formTitle = observeTitle(
			this.contentViewModel?.entity,
			this._rawTitle,
			this.titleArgs,
		);

		// Title information can come from the BPMForm caption or from caption overrides in the contentViewModel (from the showForm activity).
		// We will feed the BPMForm caption to the function in contentViewModel to guarantee that overrides are used when available.
		this.title = this.contentViewModel?.getTitleAsync
			? ko.asyncComputed(
				() => ({
					syncValue: getLoadingCaption(),
					asyncPromise: this.contentViewModel.getTitleAsync(formTitle())
				}),
				() => formTitle() // Updates observable when this changes, otherwise we'll just get stuck with the initial `syncValue`.
			)
			: formTitle;
	}

	showAbout() {
		navigationService.post('#/support/showAbout');
	}

	showGPSDisclaimer() {
		navigationService.post('#/support/showGPSDisclaimer');
	}

	logOff() {
		navigationService.post('#/logOff');
	}

	_getHelpMenuItems() {
		return menuItemsProvider.getHelpMenuItems({
			showHelpPage: helpPresenter.showHelpPage,
			showGPSDisclaimer: this.isLocationTracking ? this.showGPSDisclaimer : null,
			postNavigateByHref: this.postNavigateByHref,
			openWiseLearning,
		});
	}

	_getPinnableMenuItems() {
		const pinnedFavorites = this.favoritesViewModel.pinnedFavorites();
		const moreMenuItems = this.moreMenuItems().filter(pinFilter);
		const helpMenuItems = this.helpMenuItems().filter(pinFilter);
		const jumpMenuItems = [
			this.favoritesViewModel.favoritesMenuItem,
			this.favoritesViewModel.recentsMenuItem,
		];

		return [].concat(pinnedFavorites, moreMenuItems, helpMenuItems, jumpMenuItems);
	}
}

function getMoreMenuItems(viewModel, options) {
	const defaultOptions = {
		isTaskPage: false,
		profileImage: viewModel.profileImage,
		accessIsDenied: viewModel.isAccessDenied,
		isHomePage: viewModel.isHomePage,
		isDashboardPage: viewModel.isDashboardPage,
		isStaff: isStaff(viewModel.userData),
		postNavigateByHref: viewModel.postNavigateByHref,
		showHelpPage: helpPresenter.showHelpPage,
		favoritesViewModel: viewModel.favoritesViewModel,
		configureMenuViewModel: viewModel._configureMenuViewModel,
		isInConfigurationMode: options.isInConfigurationMode,
		workflowPK: options.workflowPK,
		workflowName: options.workflowName,
	};
	const menuItemOptions = $.extend(defaultOptions, options.additionalMenuOptions);
	return menuItemsProvider.getMoreMenuItems(menuItemOptions, viewModel.customPinnableMenuItems());
}

function openWiseLearning() {
	navigationService.post('#/support/openMyAccountUrl', {
		myAccountUrl: 'https://myaccount.cargowise.com/Home/CargoWiseOneWiseLearning.aspx',
	});
}

function pinFilter(item) {
	return item.isPinnable;
}

function postNavigateByHref(data, event) {
	const url = $(event.currentTarget).attr('href');
	if (typeof url === 'string' && url !== '') {
		navigationService.post(url, { element: event.currentTarget });
	}
}

function getProfileImage(userData) {
	if (isStaff(userData)) {
		return profilePhotoService.getProfileImage(userData.userPK, false);
	} else {
		return {
			fallback: profilePhotoService.getDefaultImage(),
		};
	}
}

function isStaff(userData) {
	return userData.userType === userSession.UserType.Staff;
}
