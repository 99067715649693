// This must be a CommonJS module because bluebird is aliased to this.
const { DeferredPromise } = require('DeferredPromise');
const Promise = getModuleValue(require('bluebird.base'));

function getModuleValue(exports) {
	return exports.default ?? exports;
}

Promise.prototype.setObservables = function (values) {
	if (!values) {
		return this;
	}

	return this.tap((result) => {
		for (const name in values) {
			if (Object.prototype.hasOwnProperty.call(values, name)) {
				const value = values[name];
				result[name](value);
			}
		}
	});
};

Promise.cache = (callbackOrPromise, context) => {
	const item = {};
	return () => {
		let promise = accessCacheItem(item);
		if (!promise) {
			const hasCallback = typeof callbackOrPromise === 'function';
			const innerPromise = hasCallback ? callbackOrPromise.call(context) : callbackOrPromise;
			const clearContext = () => {
				callbackOrPromise = null;
				context = null;
			};

			item.promise = promise = getCacheItemPromise(item, innerPromise, !hasCallback)
				.tap(clearContext)
				.tapCatch(() => {
					if (!hasCallback) {
						clearContext();
					}
				});
		}

		return promise;
	};
};

function accessCacheItem(item) {
	if ('value' in item) {
		return Promise.resolve(item.value);
	}

	if ('error' in item) {
		return Promise.reject(item.error);
	}

	return item.promise;
}

function getCacheItemPromise(item, promise, cacheError) {
	return promise
		.tap((value) => {
			item.value = value;
		})
		.tapCatch((error) => {
			if (cacheError) {
				item.error = error;
			}
		})
		.finally(() => {
			item.promise = null;
		});
}

Promise.dictionary = () => {
	return new AsyncDictionary();
};

function AsyncDictionary() {
	this._items = {};
}

AsyncDictionary.prototype.clear = function () {
	this._items = {};
};

AsyncDictionary.prototype.getAsync = function (key) {
	let promise;
	const item = this._items[key];
	if (item) {
		promise = accessCacheItem(item);
		if (promise) {
			return promise;
		}
	}
	return Promise.resolve();
};

AsyncDictionary.prototype.getOrAddAsync = function (key, factory) {
	let promise;
	let item = this._items[key];
	if (item) {
		promise = accessCacheItem(item);
		if (promise) {
			return promise;
		}
	}

	item = {};
	item.promise = promise = getCacheItemPromise(item, factory(key));
	this._items[key] = item;
	return promise;
};

AsyncDictionary.prototype.remove = function (key) {
	delete this._items[key];
};

AsyncDictionary.prototype.removeWhere = function (callback) {
	Object.keys(this._items).forEach((key) => {
		if (callback(key) === true) {
			this.remove(key);
		}
	});
};

Promise.deferred = () => {
	const _deferred = new DeferredPromise();

	const deferred = {
		promise() {
			return _deferred.promise;
		},
		resolve(value) {
			_deferred.resolve(value);
		},
		reject(error) {
			_deferred.reject(error);
		}
	};

	return deferred;
};

Promise.wait = (promiseOrValue, callback, errorCallback) => {
	if (promiseOrValue instanceof Promise) {
		if (callback) {
			promiseOrValue = promiseOrValue.then(callback);
		}
		if (errorCallback) {
			promiseOrValue = promiseOrValue.catch(errorCallback);
		}
		return promiseOrValue;
	}

	if (callback) {
		return callback(promiseOrValue);
	}

	return promiseOrValue;
};

Promise.while = (predicate, promiseOrAction) => {
	const loop = () => {
		if (!predicate()) {
			return Promise.resolve();
		}
		const promise = promiseOrAction instanceof Promise ? promiseOrAction : Promise.resolve(promiseOrAction());
		return promise.then(loop);
	};
	return loop();
};

Promise.first = (collection, predicateAsync) => {
	const deferred = Promise.deferred();
	find(deferred, collection, 0, predicateAsync);
	return deferred.promise();
};

function find(deferred, collection, index, predicateAsync) {
	if (index >= collection.length) {
		deferred.resolve(null);
	}
	else {
		const item = collection[index];
		predicateAsync(item)
			.then((value) => {
				if (value) {
					deferred.resolve(item);
				}
				else {
					find(deferred, collection, index + 1, predicateAsync);
				}
			})
			.catch(deferred.reject);
	}
}

module.exports = Promise;
