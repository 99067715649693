import type { EntityManager } from "breeze-client";

const storage = new WeakMap<EntityManager, Record<string, unknown>>();

export function getSessionVariables(entityManager: EntityManager): Record<string, unknown> | undefined {
  return storage.get(entityManager);
}

export function setSessionVariables(entityManager: EntityManager, value: Record<string, unknown>): void {
  storage.set(entityManager, value);
}
