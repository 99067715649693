import { addODataUriGuidKey, getODataRoute } from 'ODataUtils';
import versionedContent from 'VersionedContentProvider';

const defaultImage = versionedContent('Images/DefaultProfile.png');

export default {
	getProfileImage(userPK, useCachedImage) {
		/*! SuppressStringValidation route name */
		const entitySet = getODataRoute('Global') + '/GlbStaffPhotos';
		const src = addODataUriGuidKey(entitySet, userPK) + '/GS_ProfilePhoto';

		return {
			src,
			fallback: defaultImage,
			useCachedImage,
		};
	},

	getDefaultImage() {
		return defaultImage;
	},
};
