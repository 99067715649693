import global from "Global";
import { store, type StoreType } from "Store2Extensions";
import type { StoredSessionData } from "UserSession";

/*! StartNoStringValidationRegion Local storage namespaces*/
const userKey = "Glow";
const publicKey = "GlowPublic";
const previewSuffix = "Preview";
const delimiter = ":";
/*! EndNoStringValidationRegion */

class Storage {
  private readonly _store: StoreType;
  constructor(namespace: string) {
    this._store = createNamespace(store, namespace || userKey + getNamespaceSuffix());
  }

  public(): Storage {
    return new Storage(publicKey + getNamespaceSuffix());
  }

  get(key: string): StoredSessionData {
    return this._store.get(key);
  }

  set(key: string, value: unknown): unknown {
    return this._store.set(key, value);
  }

  has(key: string): boolean {
    return this._store.has(key);
  }

  remove(key: string): unknown {
    return this._store.remove(key);
  }

  clearAll(): StoreType {
    return this._store.clearAll() as StoreType;
  }

  on(key: string, callback: () => void): Storage {
    this._store.on(key, callback);
    return this;
  }

  off(key: string, callback: () => void): Storage {
    this._store.off(key, callback);
    return this;
  }

  namespace(namespace: string): Storage | string {
    if (!namespace) {
      return this._store.namespace();
    }

    const completeNamespace = createNamespace(this._store, namespace).namespace();
    return new Storage(completeNamespace);
  }
}

function createNamespace(parentStore: StoreType, namespace: string): StoreType {
  return parentStore.namespace(namespace, true, delimiter);
}

export function clearDeprecatedStorage(): void {
  createNamespace(store, userKey).clearAll();
  createNamespace(store, publicKey).clearAll();
  createNamespace(store, userKey + previewSuffix).clearAll();
  createNamespace(store, publicKey + previewSuffix).clearAll();

  if (global.baseServiceUri !== global.servicePath) {
    createNamespace(store, userKey + "-" + global.baseServiceUri).clearAll();
    createNamespace(store, publicKey + "-" + global.baseServiceUri).clearAll();
    createNamespace(store, userKey + previewSuffix + "-" + global.baseServiceUri).clearAll();
    createNamespace(store, publicKey + previewSuffix + "-" + global.baseServiceUri).clearAll();
  }
}

function getNamespaceSuffix(isPreviewMode: boolean = global.isPreviewMode()): string {
  return (isPreviewMode ? previewSuffix : "") + "-" + global.servicePath;
}

export function clearOppositeModeStorage(): void {
  const oppositeMode = !global.isPreviewMode();
  createNamespace(store, `${userKey}${getNamespaceSuffix(oppositeMode)}`).clearAll();
  createNamespace(store, `${publicKey}${getNamespaceSuffix(oppositeMode)}`).clearAll();
}

export default new Storage("");
