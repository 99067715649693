import Promise from 'bluebird';
import breeze from 'breeze-client';
import moment from 'moment';
import _ from 'underscore';
import Dependency from 'Dependency';
import bindingEvaluator from 'BindingEvaluator';
import * as stringUtils from 'StringUtils';
import { loadFilterFactoryAsync } from 'ModuleLoader';
import filterSerializer from 'Filters/FilterSerializer';
import ruleService from 'RuleService';
import constants from 'Constants';

let cachedEvalType;

function FilterEvaluator() {
}

// convert:			[ImplicitFilterGroup1, ImplicitFilterGroup2, ExplicitFilterGroup1, ExplicitFilterGroup2]
// to predicate:	(ImplicitPredicateGroup1 OR ImplicitPredicateGroup2) AND (ExplicitFilterGroup1 OR ExplicitFilterGroup2)
FilterEvaluator.prototype.getPredicateAsync = (filterGroups, entity, itemType) => {
	return getDependencyValuesAsync(filterGroups, entity)
		.then((dependencyValues) => {
			const implicitExplicitGroups = _.values(_.groupBy(filterGroups, (g) => g.IsImplicit));
			return Promise.map(implicitExplicitGroups, (group) => getFilterGroupsPredicateAsync(dependencyValues, itemType, group, entity));
		})
		.then((implicitExplicitPredicates) => {
			return implicitExplicitPredicates.filter((predicate) => predicate != null);
		})
		.then((implicitExplicitPredicates) => {
			if (implicitExplicitPredicates.length === 0) {
				return null;
			}

			return breeze.Predicate.and(implicitExplicitPredicates);
		});
};

// filterGroups are either [ImplicitFilterGroup1, ImplicitFilterGroup2...] or [ExplicitFilterGroup1, ExplicitFilterGroup2...]
// returns (Predicate1 AND Predicate2) OR (Predicate3 AND Predicate4)
function getFilterGroupsPredicateAsync(dependencyValues, itemType, filterGroups, entity) {
	return Promise.map(filterGroups, (filterGroup) => {
		return Promise.map(filterGroup.Filters, (filter) => getFilterPredicateAsync(dependencyValues, itemType, filter, entity));
	})
	.then((predicateGroups) => {
		return predicateGroups.filter((predicateGroup) => {
			return predicateGroup.every(_.identity);
		});
	})
	.then((orGroup) => {
		if (orGroup.length === 0) {
			return null;
		}

		// filters in each filter group are AND-ed
		const andPredicates = orGroup.map((andGroup) => {
			return breeze.Predicate.and(andGroup);
		});

		// all implicit/explicit groups are OR-ed
		return breeze.Predicate.or(andPredicates);
	});
}

function getDependencyValuesAsync(filterGroups, entity) {
	const result = {};
	const promises = [];

	getDependencyValuesCore(filterGroups, entity, result, promises);

	return Promise.all(promises).return(result);
}

function getDependencyValuesCore(filterGroups, entity, result, promises) {
	filterGroups.forEach((filterGroup) => {
		filterGroup.Filters.forEach((filterData) => {
			const values = filterData.Values;
			if (values) {
				values.forEach((value) => {
					updateDependencyValueInResult(value, result, entity, promises);
				});
			}

			const subFilters = filterData.FilterGroups;
			if (subFilters) {
				getDependencyValuesCore(subFilters, entity, result, promises);
			}

			const propertyPath = filterData.PropertyPath;
			updateDependencyValueInResult(propertyPath, result, entity, promises);
		});
	});
}

function updateDependencyValueInResult(resultPath, result, entity, promises) {
	if (isDependencyPath(resultPath) && !(resultPath in result)) {
		result[resultPath] = null;
		const path = resultPath.substring(1, resultPath.length - 1);
		const promise = Dependency.loadValueAsync(entity, path).then((dependencyValue) => {
			result[resultPath] = dependencyValue;
		});

		promises.push(promise);
	}
}

function getFilterPredicateAsync(dependencyValues, rootItemType, filterData, entity) {
	const values = getValues(dependencyValues, filterData);
	if (!values) {
		return null;
	}

	if (isDependencyPath(filterData.PropertyPath)) {
		return isFilterApplicableAsync(filterData, dependencyValues, values, rootItemType, entity).then((isApplicable) => {
			return isApplicable ? breeze.Predicate.booleanPredicate(true) : null;
		});
	}

	const collectionSegments = splitIntoCollectionSegments(filterData.PropertyPath.split('.'), rootItemType);
	const fieldName = collectionSegments.join('/');
	const bindingInfo = bindingEvaluator.getEntityBindingInfo(rootItemType, fieldName);

	const searchField = {
		FieldName: fieldName,
		entityType: rootItemType.metadataStore.getEntityType(bindingInfo.entityName),
		filterType: filterData.FilterType,
		dbMappingOverrideMetadata: ruleService.get(bindingInfo.entityName).dbMappingOverride(bindingInfo.propertyName)
	};

	return loadFilterFactoryAsync(constants.FilterFactories.Entity)
		.then((entityFilterFactory) => {
			const filter = entityFilterFactory.create(searchField);
			return filterSerializer.populateFromDataAsync(filter, filterData.Operation, values.value, filterData.FilterGroups, rootItemType, entityFilterFactory, entity, getValues.bind(null, dependencyValues))
				.then(() => {
					return filter.getPredicateAsync();
				})
				.then((predicate) => {
					return predicate || breeze.Predicate.booleanPredicate(true);
				});
		});
}

function isFilterApplicableAsync(filterData, dependencyValues, values, rootItemType, entity) {
	/*! SuppressStringValidation (Not a caption) */
	const searchField = { filterType: filterData.FilterType, entityType: rootItemType, FieldName: 'value' };
	return loadFilterFactoryAsync(constants.FilterFactories.Entity)
		.then((entityFilterFactory) => {
			const filter = entityFilterFactory.create(searchField);
			return filterSerializer.populateFromDataAsync(filter, filterData.Operation, values.value, filterData.filterGroups, rootItemType, entityFilterFactory, entity)
				.then(() => {
					return filter.getPredicateAsync();
				})
				.then((predicate) => {
					const entity = {
						getProperty() {
							return dependencyValues[filterData.PropertyPath];
						}
					};
					const evaluationFunction = predicate.toFunction(getEvalType());
					return evaluationFunction(entity);
				});
		});
}

function getValues(dependencyValues, filterData) {
	let values = filterData.Values;
	if (values) {
		values = values.slice(0);
		for (let i = 0; i < values.length; i++) {
			const value = values[i];
			if (isDependencyPath(value)) {
				const dependencyValue = dependencyValues[value];
				if (isUnavailableValue(dependencyValue)) {
					return null;
				}

				values[i] = dependencyValue;
			}
		}
	}

	return { value: values };
}

function getEvalType() {
	let evalType = cachedEvalType;
	if (!evalType) {
		/*! StartNoStringValidationRegion (Not a caption) */
		evalType = new breeze.EntityType({
			shortName: 'Fake',
			defaultResourceName: 'Fake',
			autoGeneratedKeyType: breeze.AutoGeneratedKeyType.Identity,
			dataProperties: [
				new breeze.DataProperty({ name: 'key', dataType: breeze.DataType.Guid, isPartOfKey: true }),
				new breeze.DataProperty({ name: 'value', dataType: breeze.DataType.String, isPartOfKey: false })
			]
		});
		evalType.isComplexType = true;
		evalType.isAnonymous = true;
		/*! EndNoStringValidationRegion */

		evalType.metadataStore = new breeze.MetadataStore();
		evalType.metadataStore.localQueryComparisonOptions = [];
		cachedEvalType = evalType;
	}

	return evalType;
}

function isDependencyPath(value) {
	return typeof value === 'string' && value[0] === '<' && value[value.length - 1] === '>';
}

function isUnavailableValue(value) {
	return value === null ||
		value === stringUtils.emptyGuid ||
		value === '' ||
		(moment.isDate(value) && moment.minValue.isSame(value));
}

function splitIntoCollectionSegments(remainingPropertyNames, itemType, propertyNameAccumulator, result) {
	propertyNameAccumulator = propertyNameAccumulator || [];
	result = result || [];

	const propertyName = remainingPropertyNames.shift();
	propertyNameAccumulator.push(propertyName);

	if (remainingPropertyNames.length === 0) {
		result.push(propertyNameAccumulator.join('.'));
		return result;
	}

	const navigationProperty = itemType.getNavigationProperty(propertyName);
	if (navigationProperty) {
		itemType = navigationProperty.entityType;
		if (!navigationProperty.isScalar) {
			result.push(propertyNameAccumulator.join('.'));
			propertyNameAccumulator = [];
		}
	}

	return splitIntoCollectionSegments(remainingPropertyNames, itemType, propertyNameAccumulator, result);
}

export default new FilterEvaluator();
