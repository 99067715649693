import type { DependencyGraph, DependencyVertex } from "DependencyGraph";
import { getInterfaceName, getPrimaryKey } from "EntityExtensions";
import type { Entity } from "breeze-client";

export default class PropertyVertex implements DependencyVertex {
  private readonly entity: Entity;
  private readonly id: string;
  private readonly propertyName: string;

  constructor(entity: Entity, propertyName: string) {
    this.entity = ensureBreezeEntity(entity);
    this.propertyName = ensureNotEmpty(propertyName);
    this.id = getInterfaceName(entity) + getPrimaryKey(entity) + propertyName;
  }

  getId(): string {
    return this.id;
  }

  isValid(): boolean {
    const { entityState } = this.entity.entityAspect;
    return !entityState.isDeleted() && !entityState.isDetached();
  }

  isVertexFor(entity: Entity, propertyName: string): boolean {
    return this.entity === entity && this.propertyName === propertyName;
  }

  reportChangedAsync(graph: DependencyGraph, loadedOnly: boolean): Promise<void> {
    return graph.notifyDependentsAsync(this, loadedOnly);
  }
}

function ensureBreezeEntity(entity: Entity): Entity {
  if (entity && entity.entityAspect) {
    return entity;
  }
  throw new Error("entity must be a breeze entity");
}

function ensureNotEmpty(propertyName: string): string {
  if (propertyName) {
    return propertyName;
  }
  throw new Error(`property name must not be empty but it was '${propertyName}'`);
}

export function isPropertyVertex(
  vertex: DependencyVertex,
  entity: Entity,
  propertyName: string
): boolean {
  return vertex instanceof PropertyVertex && vertex.isVertexFor(entity, propertyName);
}
