import captionService from 'CaptionService';

export const edoc = {
	actions: () => captionService.getString('2c892ba4-86ce-43e6-8036-e188c602cc0e', 'Actions'),
	dateAdded: () => captionService.getString('aff85a39-55a7-4313-91bd-edce6b0d1ba9', 'Added'),
	docTypeCode: () => captionService.getString('00107d9c-1eb1-41b5-bd59-e21b1fc9d804', 'Doc Type Code'),
	description: () => captionService.getString('f0abc27b-8efd-4cea-aa05-9605a14ebd71', 'Description'),
	fileName: () => captionService.getString('cd6d5175-63ab-490b-a952-168102921f95', 'File Name'),
	fileType: () => captionService.getString('542f4d4a-584c-46b9-9dd1-a0b633522f2b', 'File Type'),
	systemGenerated: () => captionService.getString('89bfb0f6-bb62-4fe2-863c-20ae8083e2c5', 'System Generated'),
	published: () => captionService.getString('7c0c6890-738b-4b08-b165-8a4f27e36146', 'Published'),
	deleted: () => captionService.getString('e1a6714e-873a-4f38-8c90-56d5c7278f66', 'Deleted'),
	lastEditedBy: () => captionService.getString('1cea03c4-5531-45ed-a342-b5e234705eb7', 'Last Edited By'),
	edocsSource: () => captionService.getString('20a7a3cb-ea99-4efa-9ebb-cad22a884af4', 'eDocs Source'),
	addEDoc: () => captionService.getString('c3d37498-1dd6-4143-b271-db8c8079e27f', 'Add eDoc'),
	addEDocs: () => captionService.getString('390233d3-1bf6-4c9e-8866-a96b6fbf9437', 'Add eDocs'),
	editEDoc: () => captionService.getString('fd229a4a-cfb1-4953-aecf-13c276066a8b', 'Edit eDoc'),
	refresh: () => captionService.getString('156097ec-c6dd-4055-8677-691de966cf53', 'Refresh'),
	required: () => captionService.getString('5b5ef41c-f762-41c0-a1f0-7fcd0d0a88ec', 'Must be entered'),
	invalidFileName: () => captionService.getString('3ab402ab-8ec3-4067-bd44-79a576c850f3', 'Valid file name must be without special characters'),
	noExtension: () => captionService.getString('b9d59d3a-c589-4060-8d10-1843b557f4f0', 'File name does not have an extension'),
	invalidDocumentType: () => captionService.getString('7d30a0be-e24b-4763-b1ef-b3f19ef172e8', 'Document Type is invalid'),
	tooLargeFile: () => captionService.getString('e87e093b-a949-44de-9873-39b800318dc8', 'The file is too large'),
	showDeleted: () => captionService.getString('1530fd02-7ba6-463d-9d3f-ca3c931047b4', 'Show Deleted'),
	hideDeleted: () => captionService.getString('6761e23a-a84d-4d3b-81a6-5d54d56bc733', 'Hide Deleted'),
	selectFile: () => captionService.getString('ad725e90-491c-45d9-91ab-f6dc91a4a41c', 'Select File'),
	documentType: () => captionService.getString('a962ba49-dd34-43c2-a606-b80eba5c5fc7', 'Document Type'),
	documentDescription: () => captionService.getString('76ae4e96-1d7d-43a6-85eb-ba5ed5c35ba5', 'Document Type Description'),
	isPublished: () => captionService.getString('729caaf8-0e80-49b6-a9fd-12cb4af5b597', 'Publish'),
	progress: () => captionService.getString('14be9b68-ad56-41c6-a7ce-a5b81432b71b', 'Progress'),
	eDocPreview: () => captionService.getString('45fb55a4-2c74-4159-b1e5-41d4dd9b89e1', 'eDoc Preview'),
	eDocPreviewMessage: () => captionService.getString('f0cc8930-c470-4f08-a762-2718823ac1a4', 'Select an eDoc to see the preview if possible'),
	eDocPreviewNotSupported: () => captionService.getString('f3ae2c0c-7a4b-4a77-989c-0d9c53e92746', 'Preview is not supported'),
	eDocPreviewNotSupportedMessage: () => captionService.getString('b851df4c-5d55-4015-9897-8756bc068156', 'The selected eDoc type is not supported for preview. Please download to see the content'),
	edit: () => captionService.getString('2b62a432-23bc-437c-a8b5-4568bc907133', 'Edit'),
	save: () => captionService.getString('1facf05d-6a3e-47e1-aeab-c539fb6e8c48', 'Save'),
	cancel: () => captionService.getString('2b7350c7-d60f-4c51-9ad7-9f6a4ff56abf', 'Cancel'),
	remove: () => captionService.getString('6e90f8e7-cedc-42c0-9ea0-e07b4b875e9d', 'Remove'),
	dropFileArea1: () => captionService.getString('ad5e0a75-4552-4fda-9155-47c69b357608', 'Drop your files here'),
	dropFileArea2: () => captionService.getString('79c7a958-de9b-43ef-8574-4a39868412e1', 'or use the new button'),
	new: () => captionService.getString('3cf8abc9-7683-4c3d-af06-20a0eaa2803e', 'New'),
	clearAll: () => captionService.getString('623d7f8a-bb24-41e8-a6e8-bf8ff5e71f12', 'Clear All'),
	toolbarDetailsButtonTooltip: () => captionService.getString('c679ea47-5f26-40bf-b560-ba93be111d34', 'View Details'),
	dialogTitleSuffix: () => captionService.getString('4d6c3c65-d7e5-44a1-a1c7-3050c2d60a88', 'eDocs'),
	noEDocsMessage: () => captionService.getString('ef342425-7ea5-44ea-9233-bb72f51d2b33', 'Click Add eDocs to upload files'),
	previewPaneCodeLabel: () => captionService.getString('33e67fd2-37e4-489e-9297-4aa2355249dc', 'Code'),
	previewPaneDescriptionLabel: () => captionService.getString('aa0af0ac-3437-47d7-a645-e501d2dc5bb9', 'Description'),
	previewPaneTypeLabel: () => captionService.getString('4ac78e78-8e72-4142-85ee-08c75ff824a1', 'Type'),
	previewPaneIsDeletedLabel: () => captionService.getString('f1fc6a84-85ea-404d-8769-d702ef452fed', 'Deleted'),
	previewPaneIsPublishedLabel: () => captionService.getString('d00ae304-b835-4950-be89-55f2d2499e89', 'Published'),
	previewPaneCreatedLabel: () => captionService.getString('f59df70d-eb9a-45d9-93ca-933c7f65df04', 'Created'),
	previewPaneModifiedLabel: () => captionService.getString('311bdb49-5ce2-4ffb-a5e4-ca9ee813e67f', 'Modified'),
	notAvailableTitle: () => captionService.getString('ba5ca995-bf71-4d36-93f3-dc9d3a8ac279', 'Save Required'),
	notAvailableMessage: () => captionService.getString('086f574e-003d-4d4b-a88a-c253fa851234', 'eDocs cannot be accessed until the changes made to this page are saved.'),
	close: () => captionService.getString('6ae7f800-ba00-4ea7-8365-9fb4fea31fe8', 'Close'),
	properties: () => captionService.getString('fa76c3b2-7d7e-4755-9d04-c879ea23b4ed', 'Properties'),
	itemCountSuffix: () => captionService.getString('52138da7-7f5d-4c1b-b6c0-d8dedaffc7f7', 'eDocs'),
};
