import AsyncLock from 'AsyncLock';
import Promise from 'bluebird';
import constants from 'Constants';
import errors from 'Errors';
import ko from 'knockout';
import validationEngine from 'ValidationEngine';
import DependencyGraphStrategy from './DependencyGraphStrategy';

const fn = {
	isCalculatedProperty: true,
};

ko.utils.setPrototypeOf(fn, Function.prototype);

fn.dispose = function () {
	const context = this._context;
	context.dependencyStrategy.dispose();
	context.isDisposed = true;
};

fn.getRuleDefinition = function () {
	return this._context.rule;
};

fn.getState = function () {
	return this._context.state.peek();
};

fn.resetState = function () {
	const context = this._context;
	if (context.state.peek() === constants.States.Available) {
		context.state(constants.States.NotLoaded);
		context.observable(null);
		context.error = undefined;
		context.initiallyLoaded = false;

		context.dependencyStrategy.notifyDependentsAsync(true);
	}
};

fn.observeState = function () {
	return this._context.state();
};

fn.loadAsync = function () {
	return loadAsync(this).then((x) => x.value);
};

fn.disableSubscriptionsOnNextNotification = function () {
	const context = this._context;
	enableOneTimeNotificationForObservable(context.observable);
	enableOneTimeNotificationForObservable(context.state);
};

function enableOneTimeNotificationForObservable(observable) {
	const subscriptions = {};
	const subscribe = observable.subscribe;
	/*! SuppressStringValidation type */
	const changeEventType = 'change';
	observable.subscribe = function (callback, callbackTarget, eventType) {
		const subscription = subscribe.apply(this, arguments);
		eventType = eventType || changeEventType;
		if (!subscriptions[eventType]) {
			subscriptions[eventType] = [];
		}
		subscriptions[eventType].push(subscription);
		return subscription;
	};
	const notifySubscribers = observable.notifySubscribers;
	observable.notifySubscribers = function (value, eventType) {
		notifySubscribers.apply(this, arguments);
		eventType = eventType || changeEventType;
		observable.subscribe = dummySubscribe;
		const subscriptionsOfThisType = subscriptions[eventType];
		if (subscriptionsOfThisType) {
			const dummySubscription = dummySubscribe();
			subscriptionsOfThisType.forEach((s) => {
				s.dispose();
				s._target = dummySubscription._target;
			});
			delete subscriptions[eventType];
		}
	};
}

function dummySubscribe() {
	const noop = () => { };
	return {
		dispose: noop,
		_target: {
			_notificationIsPending: false,
			hasChanged: noop,
			_deferUpdates: false,
			subscribe: noop,
		},
		_isDisposed: true,
		disposeWhenNodeIsRemoved: noop
	};
}

function load(instance) {
	const context = instance._context;
	if (!context.isDisposed && !context.loader && !context.error) {
		loadCoreAsync(instance);
	}
}

function loadAsync(instance) {
	const context = instance._context;
	if (context.isDisposed) {
		return Promise.resolve({ value: context.observable.peek() });
	}
	if (context.loader) {
		return context.loader;
	}
	if (context.error) {
		return Promise.reject(context.error);
	}

	return loadCoreAsync(instance);
}

function loadCoreAsync(instance) {
	const context = instance._context;

	return ko.ignoreDependencies(() => {
		return Promise.try(() => {
			const rule = context.rule;
			const isNotLoaded = context.state() === constants.States.NotLoaded;
			if (!isNotLoaded && !rule.isCacheDisabled()) {
				return { value: context.observable() };
			}

			const subscriber = context.dependencyStrategy.performInitialLoad();
			if (rule.isCacheDisabled()) {
				const result = tryProcessRuleSync(context);
				if (result.state !== constants.States.NotLoaded) {
					const value = result.state === constants.States.Available ? result.value : null;
					setValue(context, result.state, value, undefined, !isNotLoaded);
					return { value };
				}
			}

			const loader = context.loader = Promise
				.wait(subscriber, () => {
					return processRuleAsync(context);
				})
				.catch((error) => {
					if (loader === context.loader) {
						if (errors.matchError(error, (e) => e instanceof errors.UnavailableArgumentsOrSecurityError || e instanceof errors.PropertyRestrictionError)) {
							return { isSuccess: false };
						}

						if (errors.matchError(error, (e) => errors.isNetworkError(e) || errors.isTransientError(e))) {
							context.loader = undefined;
						}
						else {
							setValue(context, constants.States.NotAvailable, context.observable(), error);
						}
						throw error;
					}
				})
				.then((result) => {
					if (loader === context.loader) {
						const state = result.isSuccess ? constants.States.Available : constants.States.NotAvailable;
						const value = result.isSuccess ? result.value : null;
						const oldValue = context.observable();
						const initiallyLoaded = context.initiallyLoaded;
						context.initiallyLoaded = true;
						setValue(context, state, value);
						if (oldValue === value) {
							context.observable.valueHasMutated();
						}

						return {
							value,
							notificationPromise: context.dependencyStrategy.notifyDependentsAsync(
								!initiallyLoaded
							),
						};
					}

					return loadAsync(instance);
				});

			return context.loader;
		});
	});
}

function tryProcessRuleSync(context) {
	try {
		return context.rule.tryProcessSync(context.entity);
	} catch (error) {
		if (errors.findError(error, errors.UnavailableArgumentsOrSecurityError)) {
			return { state: constants.States.NotAvailable };
		}
		throw error;
	}
}

function processRuleAsync(context) {
	return context.rule.processAsync(context.entity)
		.catch((error) => {
			if (!context.loader || !context.loader.shouldRefresh) {
				throw error;
			}
		})
		.then((result) => {
			if (context.loader && context.loader.shouldRefresh) {
				context.loader.shouldRefresh = false;
				return processRuleAsync(context);
			}

			return result;
		});
}

fn.peek = function () {
	load(this);
	return this._context.observable.peek();
};

fn.subscribe = function () {
	const observable = this._context.observable;
	return observable.subscribe.apply(observable, arguments);
};

fn.valueHasMutated = function () {
	this._context.observable.valueHasMutated();
};

fn.writeAsync = function (value) {
	const context = this._context;
	const entity = context.entity;

	const promise = ko.ignoreDependencies(() => {
		return Promise.try(() => {
			const rule = context.rule;
			ensureSettable(entity, rule);

			if (context.state() === constants.States.Available && context.observable() === value) {
				return;
			}

			setValue(context, constants.States.Available, value);
			const setterState = createSetterState(context);

			return context.setterLock.doAsync(() => {
				return Promise.wait(context.dependencyStrategy.performInitialLoad(), () => {
					if (setterState.isDisposed) {
						return Promise.resolve();
					}
					context.setterState = setterState;

					return rule
						.invokeSetterAsync(entity, value)
						.tap((result) => {
							if (setterState.isDisposed) {
								return;
							}
							if (!result) {
								setValue(context, constants.States.NotAvailable, null);
							}
							return context.dependencyStrategy.notifyDependentsAsync(false);
						})
						.catch((error) => {
							if (setterState.isDisposed) {
								return;
							}
							if (
								errors.findError(error, errors.UnavailableArgumentsOrSecurityError)
							) {
								setValue(context, constants.States.NotAvailable, null);
							} else {
								throw error;
							}
						})
						.then((result) => {
							if (result && !setterState.isDisposed) {
								return validateAsync(context);
							}
						});
				}).finally(() => {
					if (!setterState.isDisposed) {
						context.setterState = undefined;
						if (setterState.shouldRefresh) {
							handleDependenciesChangedAsync(this);
						}
					}
				});
			});
		});
	});

	return addOperationPromise(entity, promise);
};

fn.refreshBinding = function (entity) {
	const context = this._context;
	context.state(constants.States.NotLoaded);
	context.rule.processAsync(entity, context);
};

function createSetterState(context) {
	const setterState = {};
	if (context.setterState) {
		if (context.setterState.next) {
			context.setterState.next.isDisposed = true;
		}
		context.setterState.isDisposed = true;
		context.setterState.next = setterState;
	} else {
		context.setterState = setterState;
	}

	return setterState;
}

// Perhaps instead of this, we should store the state on the EntityManager.
// Then have the ability to import state from a different EntityManager in order to import calculated property values.
fn.forceWriteAsync = function (value) {
	const context = this._context;

	const promise = ko.ignoreDependencies(() => {
		return Promise.try(() => {
			if (context.rule.hasSetter()) {
				return this.writeAsync(value);
			}
			else if (context.state() !== constants.States.Available || context.observable() !== value) {
				return Promise.wait(context.dependencyStrategy.performInitialLoad(), () => {
					setValue(context, constants.States.Available, value);
					return context.dependencyStrategy
						.notifyDependentsAsync(false)
						.then(() => validateAsync(context));
				});
			}
		});
	});

	return addOperationPromise(context.entity, promise);
};

function ensureSettable(entity, rule) {
	if (!rule.hasSetter()) {
		throw new Error('Cannot write to calculated property ' + entity.entityType.interfaceName + '.' + rule.property + ' because it does not have a setter.');
	}
}

function addOperationPromise(entity, promise) {
	if (entity.entityAspect && entity.entityAspect.entityManager) {
		return entity.entityAspect.entityManager.addPromise(promise);
	}
	return promise;
}

function setValue(context, state, value, error, poke) {
	context.state(state);
	context.error = error;
	context.loader = undefined;

	if (context.observable() !== value) {
		if (poke) {
			context.observable.poke(value);
		}
		else {
			context.observable(value);
		}
		context.dependencyStrategy.handleValueChanged(value);
	}
}

Object.defineProperty(fn, 'hasWriteFunction', {
	get() {
		return this._context.rule.hasSetter();
	}
});

fn[ko.observable.protoProperty] = ko.computed;

function calculatedProperty(entity, rule) {
	ensureBreezeEntity(entity);
	const result = createInstance();
	let observable = ko.observable(null);
	if (rule.isCacheDisabled()) {
		observable = observable.withPausing();
	}

	result._context = {
		entity,
		error: undefined,
		isDisposed: false,
		loader: undefined,
		observable,
		rule,
		setterState: undefined,
		state: ko.observable(constants.States.NotLoaded),
		initiallyLoaded: false,
	};

	result._context.dependencyStrategy = new DependencyGraphStrategy(
		new DependencyStrategyContext(result)
	);
	if (rule.hasSetter()) {
		result._context.setterLock = new AsyncLock();
	}

	ko.utils.setPrototypeOfOrExtend(result, fn);
	return result;
}

function createInstance() {
	const instance = function () {
		const context = instance._context;
		if (arguments.length === 0) {
			load(instance);
			return context.observable();
		}
		else {
			ensureSettable(context.entity, context.rule);
			instance.writeAsync(arguments[0]);
		}
	};

	return instance;
}

function ensureBreezeEntity(entity) {
	if (entity && entity.entityAspect) {
		return entity;
	}
	throw new Error('entity must be a breeze entity');
}

async function handleDependenciesChangedAsync(instance) {
	const context = instance._context;
	if (context.setterState) {
		context.setterState.shouldRefresh = true;
		return;
	}

	context.state(constants.States.NotLoaded);
	context.error = undefined;

	if (context.loader) {
		context.loader.shouldRefresh = true;
	} else {
		load(instance);
		if (context.loader) {
			const { notificationPromise } = await context.loader;
			if (notificationPromise) {
				await notificationPromise;
			}
		}
	}
}

function validateAsync(context) {
	return Promise.try(() => {
		const { property } = context.rule;
		if (!property) {
			return;
		}

		const { entity } = context;
		const { entityState } = entity.entityAspect;
		if (entityState.isDeleted() || entityState.isDetached()) {
			return;
		}

		return validationEngine.validatePropertyAndDependentsAsync(entity, property);
	});
}

class DependencyStrategyContext {
	constructor(instance) {
		this._instance = instance;
	}

	get entity() {
		return this._instance._context.entity;
	}

	get rule() {
		return this._instance._context.rule;
	}

	getState() {
		return this._instance.getState();
	}

	handleChanged() {
		return handleDependenciesChangedAsync(this._instance);
	}
}

export default calculatedProperty;
