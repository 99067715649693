import ActivityInvokerHelper from "ActivityInvokerHelper";
import alertDialogService from "AlertDialogService";
import AsyncIndicator from "AsyncIndicator";
import captionService from "CaptionService";
import { TaskMessageBusEvent } from "Constants";
import type ConversationManagerViewModel from "ConversationManagerViewModel";
import { parseDate } from "DateExtensions";
import { DateTimeType } from "DateTimeConstants";
import { DeferredPromise } from "DeferredPromise";
import dialogService, { type ButtonResult, type Dialog } from "DialogService";
import documentsMenuItemsProvider from "DocumentsMenuItemProvider";
import {
  canShowLogs,
  canShowMessages,
  canShowWorkflow,
  canViewDocuments,
  canViewEDocs,
  hideEDocs,
  hideWorkflow,
} from "EntityActionsChecker";
import { getHumanReadableName } from "EntityCalculatedPropertyExtensions";
import { getInterfaceName, getPrimaryKey } from "EntityExtensions";
import { observeAttachAndDetach, waitForEntityManagerOperationsAsync } from "EntityManagerExtensions";
import type { EntitySaveResult } from "EntitySaveService";
import ErrorDialogService from "ErrorDialogService";
import { CancellationError, FormFlowAbortError } from "Errors";
import { FormAction, actionEnabled, type ShowFormActivity } from "FormFlowActivityInvokers/ShowForm.ts";
import { FormFlowError } from "FormFlowError";
import { buildExpressionData, type ExpressionData } from "FormFlowExpressionDataBuilder";
import type { default as FormFlowOthersModel, FormFlowOthersViewModel } from "FormFlowOthersModel";
import { type FormFlowSession, type SaveListener, type StateListener } from "FormFlowSession";
import {
  isButtonTransition,
  isFinderTransition,
  isFinderTransitionEventArgs,
  isFooterTransition,
  isLookupTransition,
  isLookupTransitionEventArgs,
  isTextBoxTransition,
  isTextBoxTransitionEventArgs,
  type ControlTransition,
  type ControlTransitionEventArgs,
  type FooterTransition,
  type ShellDisposeFn,
  type ShellProviderFn,
  type SubFormFlowEventArgs,
  type TaskPresenterComputedOptions,
  type Transition,
  type UIExtensionSettings,
} from "FormFlowTypes";
import global from "Global";
import { trackBusyStateAsync } from "GlobalBusyStateTracker";
import { waitForValueAsync } from "KnockoutExtensions";
import log from "Log";
import type { MenuItemOptions } from "MenuItemsProvider";
import MessageBus, { MessageType } from "MessageBus";
import type ModelProvider from "ModelProvider";
import { loadTemplateAsync } from "ModuleLoader";
import navigationService from "NavigationService";
import notesService from "NotesService";
import NotificationSummary from "NotificationSummary";
import { NotificationType } from "NotificationType";
import { type Alert } from "Notifications";
import { getPageExtensions, type PageExtensions } from "PageExtensions";
import RuleExpressionCondition from "RuleExpressionCondition";
import RuleService from "RuleService";
import RunAsClientMessageBarProvider from "RunAsClientMessageBarProvider";
import TaskViewModel from "TaskViewModel";
import { type ShellOptions } from "UIService";
import validationEngine from "ValidationEngine";
import ValidationRegistrar, { type ValidationRegistrarItem } from "ValidationRegistrar";
import {
  makeEntityCollectionStrategy,
  makeEntityStrategy,
  type FormFlowVariableStrategy,
} from "VariableStrategyFactory";
import { type WorkflowContext } from "VueHooks/WorkflowContextHook.ts";
import widgetUtils from "WidgetUtils";
import workflowMenuItemProvider from "WorkflowMenuItemProvider";
import { EntityState, type Entity } from "breeze-client";
import "gwResizable";
import ko, { type BindingContext, type Observable, type PureComputed } from "knockout";
import { type WtgFrameworkActionMenuItem } from "wtg-material-ui";
import CancelShortcutExtension from "../UIExtensions/CancelShortcut";
import InitialFocusExtension from "../UIExtensions/InitialFocus";
import ScanInputExtension from "../UIExtensions/ScanInput";

export type ConditionContext<T = unknown> = {
  entity: T;
  getConditionById(id: string): RuleExpressionCondition;
};

export interface TaskPageExtensions<T = unknown> extends PageExtensions {
  dataItem: T;
  messageBus: MessageBus;
  workflowContext: WorkflowContext;
  modelProvider?: ModelProvider;
  sessionData?: {
    configurationParentID?: string;
    clientPK?: string;
  };
}

export interface TaskShellOptions extends ShellOptions {
  pageExtensions: TaskPageExtensions;
  viewModel: TaskViewModel;
  transitionResolveHandler?: () => void;
  hideSaveCloseButtons?: boolean;
  disableSaveCancel?: boolean;
  others?: FormFlowOthersModel;
  onCompletedUri?: string;
  configurationParentID?: string;
}

// TODO:
// - Add workflowName/PK for "Secure this form-flow"
// - Add support for configuration mode (e.g. shellOptions needs configurationTmplPK, configurationParentID, configurationEntityPK)
// - Add to recents
// - Support behaviour in gwDataGrid, gwFinder, gwListView that opens a workflow or currently relies on TaskViewModel.
// - Handle or restrict scenario where more than 1 EntityManager in the session has changes.

export default class TaskPresenter2 {
  // per presenter state
  private _isBusy: Observable<boolean>;
  private isAborted: boolean;
  private options: TaskShellOptions;
  // per activity state
  private _activity?: ShowFormActivity;
  private entity?: Observable<Entity | undefined>;
  private notificationSummary?: NotificationSummary;
  private $shell?: JQuery<Element>;
  private footerTransitions?: PureComputed<FooterTransition[]>;
  private formBindingContext?: BindingContext;
  // per show state
  private _session?: FormFlowSession;
  private currentTransitionDeferred?: DeferredPromise<void>;
  private currentShowFormDeferred?: DeferredPromise<void>;
  private messageBarProvider?: RunAsClientMessageBarProvider;
  // track pending actions
  private pendingActionHandlers: Set<ConversationManagerViewModel>;
  // data for footer transition expressions
  private expressionData: Observable<ExpressionData | undefined>;

  constructor(
    private shellProviderFn: ShellProviderFn,
    private disposeFn: ShellDisposeFn,
    options: TaskShellOptions
  ) {
    this.options = options || {};
    this._isBusy = ko.observable(false);
    this.isAborted = false;

    this.messageBarProvider = this.options.clientPK
      ? new RunAsClientMessageBarProvider(this.options.clientPK)
      : undefined;

    this.pendingActionHandlers = new Set();

    this.expressionData = ko.observable();
  }

  get session(): FormFlowSession {
    if (!this._session) {
      throw new Error("This operation can only be performed after session is set");
    }

    return this._session;
  }

  get activity(): ShowFormActivity {
    if (!this._activity) {
      throw new Error("This operation can only be performed after activity is set");
    }
    return this._activity;
  }

  async showFormAsync(session: FormFlowSession, activity: ShowFormActivity): Promise<void> {
    await trackBusyStateAsync(
      (async (): Promise<void> => {
        this.ensureNotAborted();

        if (this._activity === activity) {
          // If showing the same activity, then we have the same formId, form/page, transitions, captionOverride, hideSaveButtons, hideCloseButtons, etc.
          // In this case, we only reset/recreate some state.
          this.cleanUpPerShow();
          this._session = session;

          await this.loadEntityAsync();
        } else {
          // Otherwise, if showing a different activity, or showing for the first time, we need to reset/recreate some additional state.
          this.cleanUpPerActivity();
          this._activity = activity;
          // The entity observable should start undefined, such that loadEntityAsync can change to null.
          this.entity = ko.observable();
          this.notificationSummary = new NotificationSummary(
            this.entity,
            ko.computed({ read: this.getAllEntities, deferEvaluation: true, owner: this })
          );
          this.footerTransitions = ko.pureComputed(this.getFooterTransitions, this);

          this._session = session;
          // We need to load the entity before loading the shell, as UI extensions assume the entity has already loaded.
          try {
            await this.loadEntityAsync();
            await this.loadShellAsync();
          } finally {
            this.cleanUpPerShow();
          }
        }
        this.ensureNotAborted();
        await this.registerEntityUsageAsync();
      })()
    );
    this.ensureNotAborted();
    this.currentShowFormDeferred = new DeferredPromise<void>();
    return this.currentShowFormDeferred.promise;
  }

  dispose(): void {
    this.disposeFn();
    this.cleanUpPerActivity();
    this.cleanUpPerShow();
  }

  abort(): void {
    this.isAborted = true;
    this.resolveCurrentTransitionDeferred();
    this.rejectCurrentShowFormDeferred();
  }

  isBusy(): boolean {
    // busy if it is already running an exclusive action, or if the currentShowFormDeferred is completed (and so no further actions should happen)
    return this._isBusy() || !this.currentShowFormDeferred || !this.currentShowFormDeferred.isPending;
  }

  resolveTransition(): void {
    this.resolveCurrentTransitionDeferred();
  }

  hasFormEntity(): boolean {
    return !!ko.unwrap(this.entity);
  }

  async ensureSavedAsync(shouldRunExclusive?: boolean): Promise<boolean> {
    if (!shouldRunExclusive) {
      this._isBusy(false);
    }
    let isSaved = false;
    const savePromise = new DeferredPromise<boolean>();

    try {
      await this.runExclusiveWithSaveAsync(() => (isSaved = true));
      savePromise.resolve(isSaved);
    } catch (error) {
      savePromise.reject(error);
    }

    return await savePromise.promise;
  }

  // Once aborted, we need to ensure that all promises (session, transition, show form) are rejected, and that we no longer show forms.
  private ensureNotAborted(): void | never {
    if (this.isAborted) {
      throw new FormFlowAbortError();
    }
  }

  private cleanUpPerActivity(): void {
    if (this.notificationSummary) {
      this.notificationSummary.dispose();
      if (ko.isComputed(this.notificationSummary.entities)) {
        this.notificationSummary.entities.dispose();
      }
    }
  }

  private cleanUpPerShow(): void {
    this.resolveCurrentTransitionDeferred();
  }

  private resolveCurrentTransitionDeferred(): void {
    if (this.currentTransitionDeferred) {
      this.currentTransitionDeferred.resolve();
      this.currentTransitionDeferred = undefined;
    }

    if (this.options.transitionResolveHandler) {
      this.options.transitionResolveHandler();
    }
  }

  private rejectCurrentShowFormDeferred(): void {
    if (this.currentShowFormDeferred) {
      this.currentShowFormDeferred.reject(new FormFlowAbortError());
      this.currentShowFormDeferred = undefined;
    }
  }

  private getAllEntities(): Entity[] {
    if (this.entity) {
      const entity = this.entity();
      if (entity) {
        const entityState = entity.entityAspect.entityState;
        if (!entityState.isDeleted() && !entityState.isDetached()) {
          const entityManager = entity.entityAspect.entityManager;
          observeAttachAndDetach(entityManager);
          return entityManager.getEntities();
        }
      }
    }

    return [];
  }

  private getFooterTransitions(): FooterTransition[] {
    let context: ConditionContext;
    const footerTransitions = this.activity.Transitions.filter((transition: Transition) => {
      if (!isFooterTransition(transition)) {
        return false;
      } else if (transition.VisibilityExpression) {
        return new RuleExpressionCondition(transition.VisibilityExpression).evaluate(this.expressionData).value;
      } else if (transition.ConditionId) {
        context = context || this.getConditionContext(this.getDefinedEntity(this.entity)());
        const condition = context.getConditionById(transition.ConditionId);
        return condition.evaluate(context.entity).value;
      } else if (transition.Condition) {
        const entity = this.getDefinedEntity(this.entity)() || {};
        return new RuleExpressionCondition(transition.Condition).evaluate(entity).value;
      } else {
        return true;
      }
    });
    return footerTransitions as FooterTransition[];
  }

  private getConditionContext(entity?: Entity | null): ConditionContext {
    const rules = RuleService.get(entity ? getInterfaceName(entity) : "IGlowMacro");
    return {
      entity: entity || {},
      getConditionById(id: string): RuleExpressionCondition {
        const expression = rules.condition(id, /*okIfNotFound: */ true);
        if (!expression) {
          throw new FormFlowError(`Expected valid condition id, but was ${id}.`);
        }

        return new RuleExpressionCondition(expression);
      },
    };
  }

  private async loadEntityAsync(): Promise<void> {
    /*! SuppressStringValidation log message */
    this.logInfo("loading entity");

    const entityVariableName = this.activity.EntityVariableName;
    let entity;
    if (entityVariableName) {
      entity = (await this.session.getVariableValueAsync(entityVariableName)) as Entity;
      ActivityInvokerHelper.ensureNotNullRecord(entity);
    } else {
      await RuleService.loadAsync("IGlowMacro");
      entity = null;
    }

    if (!this.entity) {
      throw new Error("This operation can only be performed after entity is set");
    }

    if (this.entity() !== entity) {
      await this.loadTransitionDependenciesAsync(entity);
      /*! SuppressStringValidation log message */
      this.logInfo("entity updated");
      if (entity) {
        this.entity(entity);
      }
    } else {
      /*! SuppressStringValidation log message */
      this.logInfo("entity retained");
    }
  }

  private async registerEntityUsageAsync(): Promise<Dialog | void> {
    const entity = this.getDefinedEntity(this.entity)();
    if (
      entity &&
      entity.entityAspect.entityState !== EntityState.Added &&
      RuleService.get(getInterfaceName(entity)).userActivityNotification()
    ) {
      const usages = await this.session.acquireSemaphoreHandleAsync(getPrimaryKey(entity));
      if (usages.length !== 0) {
        const humanReadableName = await getHumanReadableName(entity).loadAsync();
        const header = captionService.getString(
          "f047f7e1-4086-42f5-8b75-b0d611cf4b47",
          "These users are currently modifying {0} - {1}:",
          humanReadableName?.caption,
          humanReadableName?.name
        );
        const usageMessages = usages.map((usage) => {
          return {
            usageMessage: captionService.getString(
              "8eb0fedb-b543-450f-b97b-8e8aa8d6d1b5",
              "{0} in {1} session(s) since {2}",
              usage.UserFullName,
              usage.UsageCount,
              parseDate(usage.CreateDateTimeUTC, DateTimeType.DateTimeUtc).format("D/MM/YYYY h:mm:ss A")
            ),
          };
        });

        return dialogService.showDialogAsync({
          viewModel: { header, usageMessages },
          title: captionService.getString(
            "1b8b2fe1-cb15-451a-9bcb-46622f49c912",
            "Another session is editing the same information"
          ),
          notificationType: NotificationType.Information,
          bodyAllowHtml: true,
          /*! SuppressStringValidation Usages.ejs is a file */
          bodyDeferred: loadTemplateAsync("Usages.ejs"),
        });
      }
    }
  }

  private async loadShellAsync(): Promise<void> {
    const computed: TaskPresenterComputedOptions = {
      canShowDocuments: ko.pureComputed(this.canShowDocuments, this),
      canShowEDocs: ko.pureComputed(this.canShowEDocs, this),
      canShowLogs: ko.pureComputed(this.canShowLogs, this),
      canShowMessages: ko.pureComputed(this.canShowMessages, this),
      canShowNotes: ko.pureComputed(this.canShowNotes, this),
      canShowWorkflow: ko.pureComputed(this.canShowWorkflow, this),
    };
    const viewModel: TaskViewModel = this.createViewModel(computed);
    const workflowContext: WorkflowContext = {
      entity: this.entity,
      notificationSummary: this.notificationSummary,
      version: 3,
    };

    const additionalMenuOptions: MenuItemOptions = {
      canShowDocuments: computed.canShowDocuments,
      canShowEDocs: computed.canShowEDocs,
      canShowMessages: computed.canShowMessages,
      canShowNotes: computed.canShowNotes,
      canShowLogs: computed.canShowLogs,
      canShowWorkflow: computed.canShowWorkflow,
      isTaskPage: true,
      showEDocsAsync: this.showEDocsAsync.bind(this),
      showMessagesAsync: this.showMessagesAsync.bind(this),
      showNotesAsync: this.showNotesAsync.bind(this),
      showLogsAsync: this.showLogsAsync.bind(this),
      workflowContext,
      others: this.getOthersViewModel(),
    };
    const parentID = this.options.parentID;
    const entity = this.activity.EntityVariableName ? this.entity : {};
    const shellOptions: TaskShellOptions = {
      additionalMenuOptions,
      pageExtensions: {
        dataItem: entity,
        messageBus: this.getMessageBus(),
        sessionData: {
          configurationParentID: parentID,
          clientPK: this.options.clientPK,
        },
        validationRegistrar: new ValidationRegistrar(),
        workflowContext,
        modelProvider: this.session.modelProvider,
      },
      viewModel,
      configurationParentID: parentID,
      uiContextOptions: this.options.uiContextOptions,
    };

    const [$shell, shellBindingContext] = await this.shellProviderFn(this.activity.FormId, shellOptions);

    if (!$.contains(document.body, $shell[0])) {
      return;
    }

    this.$shell = $shell;
    this.formBindingContext = shellBindingContext;

    await this.applyUIExtensionsAsync();
  }

  private loadTransitionDependenciesAsync(entity: Entity | null): Promise<void[]> {
    let context: ConditionContext;
    let expressionData: ExpressionData;
    return Promise.all(
      this.activity.Transitions.map(async (transition: Transition) => {
        if (!isFooterTransition(transition)) {
          return;
        } else if (transition.VisibilityExpression) {
          if (!expressionData) {
            expressionData = buildExpressionData(this.session);
            this.expressionData(expressionData);
          }
          await new RuleExpressionCondition(transition.VisibilityExpression).evaluateAsync(expressionData);
        } else if (transition.ConditionId) {
          context = context || this.getConditionContext(entity);
          const condition = context.getConditionById(transition.ConditionId);
          await condition.evaluateAsync(context.entity);
        } else if (transition.Condition) {
          await new RuleExpressionCondition(transition.Condition).evaluateAsync(entity || {});
        } else {
          return;
        }
      })
    );
  }

  private async applyUIExtensionsAsync(): Promise<void> {
    const $shell = this.$shell;
    const extensionSettings: UIExtensionSettings[] = [
      [new CancelShortcutExtension($shell, this.cancelTaskAsync.bind(this)), false],
      [new ScanInputExtension($shell, this.handleScanTransitionAsync.bind(this)), false],
    ];

    if (!global.materialDesign) {
      extensionSettings.push([new InitialFocusExtension($shell), true]);
    }

    for (const [extension, runWhenIdle] of extensionSettings) {
      if (runWhenIdle) {
        waitForBusyStateAndApplyExtensionAsync(this._isBusy, extension);
      } else {
        await extension.applyAsync();
      }
    }

    async function waitForBusyStateAndApplyExtensionAsync(
      isBusy: Observable<boolean>,
      extension: CancelShortcutExtension | ScanInputExtension | InitialFocusExtension
    ): Promise<void> {
      await waitForValueAsync(isBusy, false);
      await extension.applyAsync();
    }
  }

  private createViewModel(computed: TaskPresenterComputedOptions): TaskViewModel {
    return new TaskViewModel(
      this.entity,
      buildExpressionData(this.session),
      this.notificationSummary,
      this.footerTransitions,
      this.cancelTaskAsync.bind(this),
      this.saveChangesAsync.bind(this),
      this.ensureSavedAsync.bind(this, /*shouldRunExclusive*/ true),
      this.invokeTransitionAsync.bind(this),
      this.activity.CaptionOverride,
      this.activity.CaptionOverrideArgs,
      this.activity.HideFooter,
      this.options.hideSaveCloseButtons || this.activity.HideSaveButtons || this.options.disableSaveCancel,
      this.options.hideSaveCloseButtons || this.activity.HideCloseButtons,
      this.messageBarProvider,
      this.options.disableSaveCancel ? this.closeTask.bind(this) : undefined,
      computed.canShowEDocs,
      this.showEDocsAsync.bind(this),
      computed.canShowLogs,
      this.showLogsAsync.bind(this),
      computed.canShowMessages,
      this.showMessagesAsync.bind(this),
      computed.canShowNotes,
      this.showNotesAsync.bind(this),
      computed.canShowWorkflow,
      computed.canShowDocuments,
      this.getDocumentMenuItemsAsync.bind(this)
    );
  }

  private getOthersViewModel(): FormFlowOthersViewModel | null {
    if (!this.activity.HideCloseButtons) {
      const options = this.options;
      if (options) {
        const others = options.others;
        if (others) {
          return others.asViewModel({ onCompletedUri: options.onCompletedUri });
        }
      }
    }

    return null;
  }

  // - Clicking the documents menu causes an auto save, even if save buttons are hidden. Ideally it should come through this same channel here to run exclusively, save if possible, etc.
  private canShowDocuments(): boolean {
    const entity = this.getDefinedEntity(this.entity)();
    return (
      !!entity &&
      actionEnabled(this.activity, FormAction.Documents) &&
      canViewDocuments(getInterfaceName(entity.entityType))
    );
  }

  private canShowEDocs(): boolean {
    const entity = this.getDefinedEntity(this.entity)();
    return (
      !!entity &&
      canViewEDocs(getInterfaceName(entity.entityType)) &&
      actionEnabled(this.activity, FormAction.EDocs) &&
      !hideEDocs(getInterfaceName(entity.entityType))
    );
  }

  private canShowMessages(): boolean {
    const entity = this.getDefinedEntity(this.entity)();
    return !!entity && actionEnabled(this.activity, FormAction.Messages) && canShowMessages(entity.entityType);
  }

  private canShowNotes(): boolean {
    const entity = this.getDefinedEntity(this.entity)();
    return (
      !!entity && actionEnabled(this.activity, FormAction.Notes) && notesService.shouldShowNotes(entity.entityType)
    );
  }

  private canShowLogs(): boolean {
    const entity = this.getDefinedEntity(this.entity)();
    return !!entity && actionEnabled(this.activity, FormAction.Logs) && canShowLogs(entity.entityType);
  }

  private canShowWorkflow(): boolean {
    const entity = this.getDefinedEntity(this.entity)();
    return (
      !!entity &&
      actionEnabled(this.activity, FormAction.Workflows) &&
      canShowWorkflow(entity.entityType) &&
      !hideWorkflow(getInterfaceName(entity.entityType))
    );
  }

  private async showEDocsAsync(): Promise<void> {
    const entity = this.getDefinedEntity(this.entity)();
    if (!entity) {
      return;
    }
    if (entity.entityAspect.entityState.isAdded()) {
      return await this.runExclusiveWithSaveAsync(() => {
        return navigationService.post("#/edocs", { entity });
      });
    } else {
      return await this.runExclusiveAsync(() => {
        return navigationService.post("#/edocs", { entity });
      });
    }
  }

  private async getDocumentMenuItemsAsync(): Promise<WtgFrameworkActionMenuItem[]> {
    const entity = this.getDefinedEntity(this.entity)();
    if (!entity) {
      return [];
    }
    return await documentsMenuItemsProvider.getDocumentMenuItemsAsync(entity);
  }

  private async showMessagesAsync(): Promise<void> {
    const result = await this.runExclusiveAsync(async () => {
      const entity = this.getDefinedEntity(this.entity)();
      if (entity) {
        return await navigationService.post("#/conversation/showMessages", { entity });
      }
    });
    return result;
  }

  private async showNotesAsync(): Promise<void> {
    return await this.runExclusiveWithSaveAsync(() => {
      const entity = this.getDefinedEntity(this.entity)();
      return (
        entity &&
        notesService.showNotesAsync(entity.entityAspect.getKey(), async (hasSaved: boolean) => {
          if (hasSaved) {
            return await this.refreshTaskAsync();
          }
        })
      );
    });
  }

  private async showLogsAsync(): Promise<void> {
    return await this.runExclusiveWithSaveAsync(async () => {
      const entity = this.getDefinedEntity(this.entity)();
      return await workflowMenuItemProvider.showWorkflowAuditLogsAsync(entity);
    });
  }

  private getDefinedEntity(entity: ko.Observable<Entity | undefined> | undefined): Observable<Entity | undefined> {
    if (!entity) {
      throw new Error("This operation can only be performed after entity is set");
    }
    return entity;
  }

  private getMessageBus(): MessageBus {
    const messageBus = new MessageBus();
    const refreshFormMessageType = new MessageType(TaskMessageBusEvent.RefreshForm);
    const cancelTaskMessageType = new MessageType(TaskMessageBusEvent.CancelTask);
    const invokeSubFormFlowMessageType = new MessageType<SubFormFlowEventArgs, Promise<void>>(
      TaskMessageBusEvent.InvokeSubFormFlow
    );
    const invokeControlTransitionMessageType = new MessageType<ControlTransitionEventArgs, Promise<void>>(
      TaskMessageBusEvent.InvokeControlTransition
    );
    const registerPendingActionHandlerMessageType = new MessageType<ConversationManagerViewModel, Promise<void>>(
      TaskMessageBusEvent.RegisterPendingActionHandler
    );
    const unregisterPendingActionHandlerMessageType = new MessageType<ConversationManagerViewModel, Promise<void>>(
      TaskMessageBusEvent.UnregisterPendingActionHandler
    );

    const registerSaveHandlerMessageType = new MessageType<SaveListener>(TaskMessageBusEvent.RegisterSaveHandler);
    const unregisterSaveHandlerMessageType = new MessageType<SaveListener>(TaskMessageBusEvent.UnregisterSaveHandler);
    const registerStateHandlerMessageType = new MessageType<StateListener>(TaskMessageBusEvent.RegisterGetStateHandler);
    const unregisterStateHandlerMessageType = new MessageType<StateListener>(
      TaskMessageBusEvent.UnregisterGetStateHandler
    );

    messageBus.subscribe(refreshFormMessageType, this.refreshTaskAsync.bind(this));
    messageBus.subscribe(cancelTaskMessageType, this.cancelTaskAsync.bind(this));
    messageBus.subscribe(invokeSubFormFlowMessageType, this.handleSubFormFlowAsync.bind(this));
    messageBus.subscribe(invokeControlTransitionMessageType, this.handleControlTransitionAsync.bind(this));
    messageBus.subscribe(registerPendingActionHandlerMessageType, this.registerPendingActionHandlerAsync.bind(this));
    messageBus.subscribe(
      unregisterPendingActionHandlerMessageType,
      this.unregisterPendingActionHandlerAsync.bind(this)
    );
    messageBus.subscribe(registerSaveHandlerMessageType, (callback: SaveListener) => {
      this.session.addSaveListener(callback);
    });
    messageBus.subscribe(unregisterSaveHandlerMessageType, (callback: SaveListener) => {
      this.session.removeSaveListener(callback);
    });
    messageBus.subscribe(registerStateHandlerMessageType, (callback: StateListener) => {
      this.session.addGetStateListener(callback);
    });
    messageBus.subscribe(unregisterStateHandlerMessageType, (callback: StateListener) => {
      this.session.removeGetStateListener(callback);
    });

    return messageBus;
  }

  private async cancelTaskAsync(): Promise<void> {
    return await this.runExclusiveAsync(async () => {
      const isHandled = await this.handlePendingActionsAsync();
      let shouldAbort: ButtonResult;
      if (!isHandled) {
        shouldAbort = false;
      } else if (!this.session.hasChanges()) {
        shouldAbort = true;
      } else {
        shouldAbort = await alertDialogService.warnStayLeaveUnsavedChangesAsync();
      }

      if (shouldAbort) {
        this.abort();
      }
    });
  }

  private closeTask(): void {
    this.currentShowFormDeferred?.resolve();
  }

  private async saveChangesAsync(disableReloadOnSaved: boolean): Promise<EntitySaveResult> {
    return await this.runExclusiveAsync(async () => {
      const shouldSave = await this.handlePendingActionsAsync();
      if (shouldSave) {
        return await this.saveChangesCoreAsync(disableReloadOnSaved);
      }
      return { isSaved: false, error: null };
    });
  }

  private async saveChangesCoreAsync(disableReloadOnSaved?: boolean): Promise<EntitySaveResult> {
    const isValid = await this.validateFormAsync();

    const items = await this.getBoundItemsExcludeDetachedAndDeletedAsync();
    const shouldPreventSave = (alert: Alert): boolean => {
      return (
        isControlError(alert) ||
        (isRuleErrorOrWarning(alert) && global.featureFlags.existingValidationAlertsPreventSave ? true : false)
      );
    };
    if (this.hasClientSideAlert(shouldPreventSave, items) || !isValid) {
      await this.showValidationResultsAsync(shouldPreventSave);
      return { isSaved: false, error: null };
    }
    let result;
    try {
      result = await this.session.saveAsync({ shouldRefresh: !disableReloadOnSaved });
    } catch (error) {
      if (error instanceof FormFlowError) {
        this.ensureNotAborted();
        this.currentShowFormDeferred?.reject(error);
        return { isSaved: false, error: null };
      } else {
        throw error;
      }
    }
    if (!result) {
      result = { isSaved: false, error: null };
    }
    // Always reload, except where the save was successful and reload on save is disabled for this attempt.
    // So, don't reload on a successful save when saving and closing.
    if (!result.isSaved || !disableReloadOnSaved) {
      try {
        await this.loadEntityAsync();
        await this.registerEntityUsageAsync();
      } catch (error) {
        this.ensureNotAborted();
        this.currentShowFormDeferred?.reject(error);
      }
    }

    return result;
  }

  private async refreshTaskAsync(): Promise<void> {
    await this.runExclusiveAsync(async () => {
      try {
        await this.session.discardEntityManagersAsync();
        await this.loadEntityAsync();
      } catch (error) {
        this.currentShowFormDeferred?.reject(error);
      }
    });
  }

  private async handleSubFormFlowAsync(eventArgs: SubFormFlowEventArgs): Promise<void> {
    const argumentStrategies = [];
    if (eventArgs.entityPK && eventArgs.entityName) {
      argumentStrategies.push(makeEntityStrategy(eventArgs.entityName, eventArgs.entityPK));
    } else if (eventArgs.entityPKs && eventArgs.entityName) {
      argumentStrategies.push(makeEntityCollectionStrategy(eventArgs.entityName, eventArgs.entityPKs));
    }
    if (eventArgs.headerEntityPK && eventArgs.headerEntityName) {
      argumentStrategies.push(makeEntityStrategy(eventArgs.headerEntityName, eventArgs.headerEntityPK));
    }

    return await this.handleSubFormFlowAsSubFormFlowAsync(eventArgs, argumentStrategies);
  }

  private async handleSubFormFlowAsSubFormFlowAsync(
    eventArgs: SubFormFlowEventArgs,
    argumentStrategies: FormFlowVariableStrategy[]
  ): Promise<void> {
    return await this.runExclusiveAsync(async () => {
      return await this.session.withTemporaryVariableAsync(async (var1) => {
        return await this.session.withTemporaryVariableAsync(async (var2) => {
          let argumentNames;
          if (argumentStrategies.length === 2) {
            argumentNames = [var1, var2];
            this.session.setVariableStrategy(var1, argumentStrategies[0]);
            this.session.setVariableStrategy(var2, argumentStrategies[1]);
          } else if (argumentStrategies.length === 1) {
            argumentNames = [var1];
            this.session.setVariableStrategy(var1, argumentStrategies[0]);
          }

          const doSubFormFlowActivity = {
            Id: "Temporary-SubFormFlow",
            Kind: "DoSubFormFlow",
            SubFormFlowPK: eventArgs.formFlowPK,
            ArgumentNames: argumentNames,
            OutArgumentNames: null,
            NextActivityId: this.activity.Id,
            CreateNewSession: eventArgs.CreateNewSession,
            ShowInDialog: eventArgs.ShowInDialog,
            ShowMaximizedDialog: eventArgs.ShowMaximizedDialog,
            DisableSaveCurrentSession: eventArgs.DisableSaveCurrentSession,
          };

          return await this.session.withTemporaryActivityAsync(
            doSubFormFlowActivity,
            async (doSubFormFlowActivityId) => {
              await this.invokeTransitionCoreAsync({
                NextActivityId: doSubFormFlowActivityId,
                SuspendValidation: eventArgs.SuspendValidation,
              });
            }
          );
        });
      });
    });
  }

  private async handleControlTransitionAsync(eventArgs: ControlTransitionEventArgs): Promise<void> {
    return await this.runExclusiveAsync(async () => {
      eventArgs.isHandled = true;

      const transition = this.activity.Transitions.find((transition: Transition): transition is ControlTransition => {
        return "ControlId" in transition && transition.ControlId === eventArgs.controlId;
      });

      if (transition) {
        if (isFinderTransition(transition) && isFinderTransitionEventArgs(eventArgs)) {
          if (transition.SearchTermVariableName) {
            this.session.setVariableValue(transition.SearchTermVariableName, eventArgs.searchTerm);
          }
          const queryResult = await eventArgs.strategy.findItemsAsync(
            eventArgs.searchTerm,
            eventArgs.isGS1,
            transition.ExpandPaths
          );

          this.session.setVariableValue(transition.ResultsVariableName, queryResult.results);
          return await this.invokeTransitionCoreAsync(transition);
        } else if (isButtonTransition(transition)) {
          return await this.invokeTransitionCoreAsync(transition);
        } else if (isTextBoxTransition(transition) && isTextBoxTransitionEventArgs(eventArgs)) {
          if (transition.IsGs1VariableName) {
            this.session.setVariableValue(transition.IsGs1VariableName, eventArgs.isGs1);
          }
          this.session.setVariableValue(transition.TextVariableName, eventArgs.text);
          return await this.invokeTransitionCoreAsync(transition);
        } else if (isLookupTransition(transition) && isLookupTransitionEventArgs(eventArgs)) {
          const entityStrategy = makeEntityStrategy(eventArgs.entityName, eventArgs.entityPK);
          this.session.setVariableStrategy(transition.ResultVariableName, entityStrategy);
          return await this.invokeTransitionCoreAsync(transition);
        } else {
          this.currentShowFormDeferred?.reject(new FormFlowError(`Unexpected transition kind ${transition.Kind}.`));
        }
      } else {
        this.currentShowFormDeferred?.reject(
          new FormFlowError(`Expected transition with ControlId ${eventArgs.controlId}, but did not exist.`)
        );
      }
    });
  }

  private async handleScanTransitionAsync(): Promise<void> {
    return await this.runExclusiveAsync(async () => {
      if (!this.footerTransitions) {
        throw new Error("This operation can only be performed after footer transitions are set");
      }
      const transitions = this.footerTransitions();
      if (transitions?.length) {
        return await this.invokeTransitionCoreAsync(transitions[0]);
      }
    });
  }

  private async invokeTransitionAsync(transition: FooterTransition): Promise<void> {
    return await this.runExclusiveAsync(async () => {
      return await this.invokeTransitionCoreAsync(transition);
    });
  }

  private async invokeTransitionCoreAsync(transition: Transition): Promise<void> {
    const isValid = await this.validateFormAsync(transition.SuspendValidation);
    if (!isValid) {
      await this.showValidationResultsAsync(this.shouldPreventTransition);
    }

    let shouldTranstion;
    if (isValid && !this.isAborted) {
      shouldTranstion = await this.handlePendingActionsAsync();

      if (shouldTranstion && !this.isAborted) {
        //This additional layer lets a transition deferred complete early when a form is shown, so that controls on a reused form can stop waiting for the transition to finish.
        const thisShowFormDeferred = this.currentShowFormDeferred;
        const thisTransitionDeferred = (this.currentTransitionDeferred = new DeferredPromise<void>());

        this.invokeActivityAsync(transition, thisShowFormDeferred, thisTransitionDeferred); // do not await
        return await thisTransitionDeferred.promise;
      }
    }
  }

  private async invokeActivityAsync(
    transition: Transition,
    showFormDeferred: DeferredPromise<void> | undefined,
    transitionDeferred: DeferredPromise<void>
  ): Promise<void> {
    try {
      const result = await this.session.invokeActivityAsync(transition.NextActivityId);
      const { remainOpen, refresh } = result ?? {};
      this.logInfo(`transition resolved with { remainOpen: ${remainOpen}, refresh: ${refresh} }`);
      if (!remainOpen) {
        showFormDeferred?.resolve();
      }
      if (refresh) {
        await this.refreshTaskAsync();
      }
      transitionDeferred.resolve();
    } catch (error) {
      showFormDeferred?.reject(error);
      transitionDeferred.resolve();
    }
  }

  private async getBoundItemsExcludeDetachedAndDeletedAsync(): Promise<ValidationRegistrarItem[]> {
    const pageExtensions = getPageExtensions(this.formBindingContext);
    const validationRegistrarItems = await pageExtensions.validationRegistrar?.getBoundItemsAsync();
    if (validationRegistrarItems) {
      const excludeDetachedAndDeletedEntities = (item: ValidationRegistrarItem): boolean =>
        !item.entity.entityAspect.entityState.isDetached() && !item.entity.entityAspect.entityState.isDeleted();
      return validationRegistrarItems.filter(excludeDetachedAndDeletedEntities);
    }
    return [];
  }

  private async validateControlsAsync(): Promise<boolean | undefined> {
    const pageExtensions = getPageExtensions(this.formBindingContext);
    const validateControlsAsyncMessageType = new MessageType<boolean, boolean>(TaskMessageBusEvent.ValidateControls);
    const result = await pageExtensions.messageBus?.publishAsync(validateControlsAsyncMessageType);
    return result?.every((r) => r === true);
  }

  private async validateFormAsync(suspendValidation?: boolean): Promise<boolean> {
    if (suspendValidation) {
      return true;
    }

    if (!(await this.validateControlsAsync())) {
      return false;
    }

    const items = await this.getBoundItemsExcludeDetachedAndDeletedAsync();
    if (this.hasClientSideAlert(isControlError, items)) {
      return false;
    }

    if (!this.session.hasChanges()) {
      return true;
    }

    const results = await Promise.all(
      items.map((i) =>
        validationEngine.validateEntityAsync(i.entity, i.propertyNames, [
          NotificationType.Error,
          NotificationType.Warning,
        ])
      )
    );

    const isValid = results.every(Boolean);
    if (isValid) {
      items.forEach((item) => {
        item.entity.entityAspect.notifications.removeAll(
          (a: Alert) =>
            a.Level === NotificationType.Warning &&
            !a.requiresAcknowledgement() &&
            item.propertyNames.includes(a.propertyName)
        );
      });
    }

    return isValid;
  }

  private hasClientSideAlert(predicate: (alert: Alert) => boolean, boundItems?: ValidationRegistrarItem[]): boolean {
    if (boundItems) {
      return boundItems.some((item) => {
        return item.propertyNames.some((property) => {
          return item.entity.entityAspect.notifications.alerts(property).some((alert: Alert) => {
            return !alert.isServerNotification && predicate(alert);
          });
        });
      });
    }
    return false;
  }

  private async showValidationResultsAsync(filter: (alert: Alert) => boolean | null): Promise<void> {
    const entities = ko.observable(this.getAllEntities.call(this));
    const notificationSummary = new NotificationSummary(null, entities);

    const message = captionService.getString(
      "5062bd45-3f0c-49f8-a9f6-08dd31ffe254",
      "Validation failed, please review"
    );
    const title = captionService.getString("7cd7bac9-11f1-4a09-a4f6-647a18f9c42e", "Validation failed");
    await ErrorDialogService.showValidationResultsAsync(message, title, notificationSummary, filter);
  }

  private shouldPreventTransition(alert: Alert): boolean {
    return isControlError(alert) || isRuleErrorOrWarning(alert);
  }

  private async runExclusiveAsync<T>(callback: () => T): Promise<T> {
    if (this.isBusy()) {
      throw new CancellationError();
    }

    const asyncIndicator = new AsyncIndicator();
    asyncIndicator.show();
    this._isBusy(true);
    try {
      await widgetUtils.waitIdleAllWidgetsAsync(this.$shell);

      const entity = this.getDefinedEntity(this.entity)();
      if (entity) {
        await waitForEntityManagerOperationsAsync(entity.entityAspect.entityManager);
      }
      return await callback();
    } finally {
      await asyncIndicator.hideAsync();
      this._isBusy(false);
    }
  }

  // TODO:
  // - Only allow save if save buttons exist, including an extra flag indicating that save is still available even if the default buttons are hidden
  private async runExclusiveWithSaveAsync<T>(callback: () => T): Promise<Awaited<T> | undefined> {
    return await this.runExclusiveAsync(async () => {
      const isHandled = await this.handlePendingActionsAsync();
      let isSaved;
      if (isHandled) {
        const cannotPerformActionTitle = captionService.getString(
          "43e6e521-f29a-4aa0-88a2-8b029745839a",
          "Cannot Perform Action"
        );

        const entity = this.getDefinedEntity(this.entity)();
        if (!entity) {
          isSaved = false;
        }

        if (!entity?.entityAspect.entityManager.hasChanges()) {
          isSaved = true;
        }
        if (!isSaved) {
          // const canSave = !this.activity.HideSaveButtons || this.activity.AllowSavesAnyway;
          const canSave = true;
          if (canSave) {
            const buttonOptions = [
              {
                caption: captionService.getString("31571928-d120-4d4a-8245-1f1430db50c9", "Cancel"),
                result: false,
                isDismiss: true,
              },
              {
                caption: captionService.getString("453aadd1-908c-4e73-8a4f-b3afa6fd16b4", "Save & Continue"),
                result: true,
                isDefault: true,
                isDismiss: true,
              },
            ];

            const shouldSave = await alertDialogService.confirmAsync(
              captionService.getString(
                "2636df45-0e7f-4ea5-98b8-4bcee598de94",
                "This action cannot be performed right now because there are unsaved changes. Would you like to save?"
              ),
              cannotPerformActionTitle,
              buttonOptions,
              { notificationType: NotificationType.Warning }
            );
            if (shouldSave) {
              const saveResult = await this.saveChangesCoreAsync();
              isSaved = saveResult.isSaved;
            }
          } else {
            await alertDialogService.alertAsync(
              NotificationType.Warning,
              captionService.getString(
                "a7947faa-db4c-4264-8114-3fffac9d2458",
                "This action cannot be performed right now because there are unsaved changes."
              ),
              cannotPerformActionTitle
            );
            isSaved = false;
          }
        }
      }
      if (isSaved) {
        return callback();
      }
      return;
    });
  }

  private async registerPendingActionHandlerAsync(handler: ConversationManagerViewModel): Promise<void> {
    if (handler && typeof handler.handlePendingActionsAsync === "function") {
      await this.pendingActionHandlers.add(handler);
    } else {
      throw new Error("Pending action handler should implement handlePendingActionsAsync function.");
    }
  }

  private async unregisterPendingActionHandlerAsync(handler: ConversationManagerViewModel): Promise<void> {
    if (handler) {
      await this.pendingActionHandlers.delete(handler);
    } else {
      throw new Error("Pending action handler should be defined.");
    }
  }

  private async handlePendingActionsAsync(): Promise<boolean> {
    if (this.pendingActionHandlers.size === 0) {
      return await Promise.resolve(true);
    }

    let result = true;
    for (const handler of this.pendingActionHandlers) {
      const handlerResult = await handler.handlePendingActionsAsync();

      // If any handler returns false, set the result to false and break the loop
      if (!handlerResult) {
        result = false;
        break;
      }
    }

    return result;
  }

  private logInfo(event: string): void {
    log.info(
      `[Form-flow] ${event} for form-flow ${this.options.parentID} - activity ${this.activity.Id} - form ${this.activity.FormId}`
    );
  }
}

function isControlError(alert: Alert): boolean {
  return !alert.ruleId && alert.Level === NotificationType.Error;
}

function isRuleErrorOrWarning(alert: Alert): boolean {
  return (
    !!alert.ruleId &&
    (alert.Level === NotificationType.Error ||
      (alert.Level === NotificationType.Warning && alert.requiresAcknowledgement() && !alert.acknowledged?.()))
  );
}
