import breeze from 'breeze-client';

class EntityActiveStateDecorator {
	decorateQuery(query, entityType) {
		if (entityType && entityType.isActivePropertyName) {
			if (!containsActiveStatePredicate(query.wherePredicate, entityType.isActivePropertyName)) {
				const activeStatePredicate = getActiveStatePredicate(entityType);
				if (activeStatePredicate) {
					query = query.where(activeStatePredicate);
				}
			}
		}

		return query;
	}
}

function containsActiveStatePredicate(predicate, isActivePropertyName) {
	if (predicate) {
		if (predicate.preds) {
			return predicate.preds.some((inner) => containsActiveStatePredicate(inner, isActivePropertyName));
		}

		const expr1Source = predicate.expr1Source;
		return expr1Source && expr1Source === isActivePropertyName;
	}

	return false;
}

function getActiveStatePredicate(entityType) {
	const isActivePropertyName = entityType.isActivePropertyName;
	const isCancellable = entityType.isCancellable;
	let value;

	if (entityType.getProperty(isActivePropertyName).dataType === breeze.DataType.Boolean) {
		value = !isCancellable;
	} else {
		value = isCancellable ? 'N' : 'Y';
	}

	return breeze.Predicate.create(isActivePropertyName, '==', value);
}

export default new EntityActiveStateDecorator();

