import errorReportingService from "ErrorReportingService";

export class RepeatedOccurrencesErrorHandlerFactory {
  private count = 0;
  private reportPeriodEndTime: number | undefined;
  private coolDownEndTime: number | undefined;
  private readonly predicate: (error: Error) => boolean;
  private readonly reportPeriodInSeconds;
  private readonly reportAtCount;
  private readonly coolDownPeriodInSeconds;
  constructor(
    predicate: (error: Error) => boolean,
    reportPeriodInSeconds = 5,
    reportAtCount = 2,
    coolDownPeriodInSeconds = 60
  ) {
    if (reportAtCount <= 0) {
      throw new Error("reportAtCount argument must be greater than 0");
    }
    this.predicate = predicate;
    this.reportPeriodInSeconds = reportPeriodInSeconds;
    this.reportAtCount = reportAtCount;
    this.coolDownPeriodInSeconds = coolDownPeriodInSeconds;
  }

  getHandler(): (error: Error) => boolean {
    return this.handleRepeatedOccurrencesError;
  }

  private handleRepeatedOccurrencesError = (error: Error): boolean => {
    if (!this.predicate(error)) {
      return false;
    }

    const currentTime = Date.now();
    const inCoolDownTime =
      this.reportPeriodEndTime &&
      this.coolDownEndTime &&
      currentTime > this.reportPeriodEndTime &&
      currentTime <= this.coolDownEndTime &&
      this.count >= this.reportAtCount;

    if (!inCoolDownTime) {
      if (
        !this.coolDownEndTime ||
        !this.reportPeriodEndTime ||
        currentTime > this.coolDownEndTime ||
        (currentTime > this.reportPeriodEndTime && this.count < this.reportAtCount)
      ) {
        // start a new cycle of error detection
        this.count = 0;
        this.reportPeriodEndTime = currentTime + this.reportPeriodInSeconds * 1000;
        this.coolDownEndTime = this.reportPeriodEndTime + this.coolDownPeriodInSeconds * 1000;
      }
      this.count++;

      if (currentTime <= this.reportPeriodEndTime && this.count === this.reportAtCount) {
        errorReportingService.sendErrorReport(error);
      }
    }

    return true;
  };
}
