import { createDateTimeOffset, fromLocalDate } from 'DateTimeOffset';
import errors from 'Errors';
import { getAvailableResult, mapData, mapDataOne, operationTypes } from './Utils';

const dateMethodDecorationInfo = Object.freeze({
	operationType: operationTypes.Method,
	operandCount: 1,
});

function trimMilliseconds(value, data, context) {
	return mapDataOne(value, data, context, trimMillisecondsCore, dateMethodDecorationInfo);
}

function trimMillisecondsCore(value) {
	value = cloneValue('TrimMilliseconds', value);
	if (value) {
		value.setMilliseconds(0);
	}

	return getAvailableResult(value);
}

function trimSeconds(value, data, context) {
	return mapDataOne(value, data, context, trimSecondsCore, dateMethodDecorationInfo);
}

function trimSecondsCore(value) {
	value = cloneValue('TrimSeconds', value);
	if (value) {
		value.setSeconds(0);
		value.setMilliseconds(0);
	}

	return getAvailableResult(value);
}

function date(value, data, context) {
	return mapDataOne(value, data, context, dateCore, dateMethodDecorationInfo);
}

function dateCore(value) {
	value = ensureDate('Date', value);
	if (value) {
		const year = value.getFullYear();
		value = new Date(year, value.getMonth(), value.getDate());
		value.setFullYear(year);
	}

	return getAvailableResult(value);
}

function dateTime(value, data, context) {
	return mapDataOne(value, data, context, dateTimeCore, dateMethodDecorationInfo);
}

function dateTimeCore(value) {
	value = ensureDate('DateTime', value);
	if (value) {
		const year = value.getFullYear();
		value = new Date(
			year,
			value.getMonth(),
			value.getDate(),
			value.getHours(),
			value.getMinutes(),
			value.getSeconds(),
			value.getMilliseconds()
		);
		value.setFullYear(year);
	}
	return getAvailableResult(value);
}

function utcDateTime(value, data, context) {
	return mapDataOne(value, data, context, utcDateTimeCore, dateMethodDecorationInfo);
}

function utcDateTimeCore(value) {
	value = ensureDate('UtcDateTime', value);
	if (value) {
		value = fromLocalDate(value, 'Z');
	}
	return getAvailableResult(value);
}

const addDaysDecorationInfo = Object.freeze({
	operationType: operationTypes.Method,
	operandCount: 2,
});

function addDays(value, addDay, data, context) {
	return mapData([value, addDay], data, context, addDaysCore, addDaysDecorationInfo);
}

function addDaysCore([value, addDay]) {
	value = cloneValue('AddDays', value);
	if (value) {
		value.setDate(value.getDate() + addDay);
	}
	return getAvailableResult(value);
}

function ensureDate(description, value) {
	if (value == null) {
		return null;
	}
	if (!(value instanceof Date)) {
		throw new errors.DependencyError(`${description}() can only be used on a date.`);
	}

	return value;
}

function cloneValue(description, value) {
	value = ensureDate(description, value);
	if (value) {
		value = value.hasOffset
			? createDateTimeOffset(value, -value.getTimezoneOffset())
			: new Date(value.getTime());
	}
	return value;
}

export default { trimMilliseconds, trimSeconds, date, dateTime, utcDateTime, addDays };
