import type MessageBus from "MessageBus";
import { inject, provide } from "vue";

export const messageBusInjectionKey = Symbol("messageBus");

export function useMessageBus(): MessageBus | undefined {
  return inject<MessageBus | undefined>(messageBusInjectionKey, undefined);
}

type bindingContextWithMessageBus = { value?: { pageExtensions?: { messageBus?: MessageBus } } };
export function setMessageBus(bindingContext: bindingContextWithMessageBus): void {
  const messageBus =
    (bindingContext &&
      bindingContext.value &&
      bindingContext.value.pageExtensions &&
      bindingContext.value.pageExtensions.messageBus) ||
    undefined;

  provide(messageBusInjectionKey, messageBus);
}
