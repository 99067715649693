/*! StartNoStringValidationRegion Does not contain captions */
import { AjaxError } from "AjaxService";
import captionService from "CaptionService";
import connection from "Connection";
import { DataServiceRequestError } from "DataServiceRequestError";
import type { ErrorWithRequestUri } from "ModuleLoader";
import { NestedError } from "NestedError";
import { isNumber } from "NumericService";
import { isNullOrWhitespace } from "StringUtils";
import type { Entity, EntityQuery, OrderByClauseItem } from "breeze-client";

export enum WorkflowErrorType {
  BadRequest = "BadRequest",
  Forbidden = "Forbidden",
  NotFound = "NotFound",
  EntityNotFound = "EntityNotFound",
  EntityPathNotFound = "EntityPathNotFound",
  EntityTypeNotFound = "EntityTypeNotFound",
  ConditionNotFound = "ConditionNotFound",
  CommandNotFound = "CommandNotFound",
  Other = "Other",
}

/** @deprecated use from DataServiceRequestError */
export { DataServiceRequestError };

export enum ScriptLoadErrorType {
  Error = "error",
  Missing = "missing",
  Timeout = "timeout",
}

export enum RuleInvocationErrorType {
  InvalidArguments = "InvalidArguments",
}

export enum FormFlowErrors {
  SerializationError = "FormFlowSerializationError",
}

export enum DOMExceptionType {
  NotSupportedError = "NotSupportedError",
  InvalidStateError = "InvalidStateError",
  SecurityError = "SecurityError",
  AbortError = "AbortError",
  TimeoutError = "TimeoutError",
}

export type ErrorData = {
  name: string;
  value: unknown;
};
export class SignalRError extends NestedError {
  override readonly name = "SignalRError";
}

export class ResourceStreamError extends NestedError {
  override readonly name = "ResourceStreamError";
  readonly entity: Entity;

  constructor(entity: Entity, innerError?: Error) {
    super(undefined, innerError);

    this.entity = entity;
  }
}

export class AmbiguousModelError extends Error {
  override readonly name = "AmbiguousModelError";
  readonly entityTypeInterface: string;

  constructor(message: string, entityTypeInterface: string) {
    super(message);

    this.entityTypeInterface = entityTypeInterface;
  }
}

export class BindingPathError extends Error {
  override readonly name = "BindingPathError";
}

export class CancellationError extends Error {
  override readonly name = "CancellationError";
}

export class FilterStripError extends Error {
  override readonly name = "FilterStripError";
}

export class QueryTooComplexError extends NestedError {
  override readonly name = "QueryTooComplexError";
}

export class ExpandPathTooDeepError extends Error {
  override readonly name = "ExpandPathTooDeepError";
}

export class StreamQueryError extends NestedError {
  override readonly name = "StreamQueryError";
}

export class BreezeQueryError extends NestedError {
  override readonly name = "BreezeQueryError";
  readonly query?: EntityQuery;
  readonly url?: string;
  readonly status?: number;
  readonly statusText?: string;

  constructor(error: Error | string) {
    const defaultMessage = "An error occurred in Breeze.";

    super(defaultMessage, error instanceof Error ? error : undefined);

    if (typeof error !== "string") {
      this.status = error["status" as keyof typeof error] as number;
      this.query = error["query" as keyof typeof error] as EntityQuery;
      this.url = error["url" as keyof typeof error] as string;
      this.statusText = error["statusText" as keyof typeof error] as string;
    }

    let message = (typeof error === "string" ? error : error.message ?? defaultMessage).trim();
    if (message.endsWith(";")) {
      message = message.substring(0, message.length - 1);
    }
    if (this.url) {
      message += `; URI: ${this.url}`;
    }
    if (isNumber(this.status)) {
      message += `; Status code: ${this.status}`;
    }

    this.message = message;
  }

  getData(): ErrorData[] | null {
    const query = this.query;
    if (!query) {
      return null;
    }

    const result = [{ name: "Resource", value: query.resourceName }];
    if (query.wherePredicate) {
      result.push({ name: "Filter", value: query.wherePredicate.toString() });
    }
    if (query.expandClause && query.expandClause.propertyPaths) {
      result.push({ name: "Expand", value: query.expandClause.propertyPaths.join(", ") });
    }
    if (query.orderByClause && query.orderByClause.items) {
      const formatOrderByClause = (clause: OrderByClauseItem): string => {
        return clause.propertyPath + (clause.isDesc ? " desc" : "");
      };
      const value = query.orderByClause.items.map(formatOrderByClause);
      result.push({ name: "OrderBy", value: value.join(", ") });
    }

    return result;
  }
}

export class BreezeMetadataError extends NestedError {
  override readonly name = "BreezeMetadataError";
  readonly status: number;

  constructor(routeName: string, innerError: AjaxError) {
    super(`Metadata query failed for data service: ${routeName}`, innerError);

    this.status = innerError.status;
  }
}
export class RenewSessionError extends NestedError {
  override readonly name = "RenewSessionError";
  readonly isCriticalError = true;
  readonly authenticationResult: number;

  constructor(message: string, authenticationResult: number, innerError?: Error) {
    super(message, innerError);

    this.authenticationResult = authenticationResult;
  }
}

export class SessionOutdatedError extends Error {
  override readonly name = "SessionOutdatedError";
  readonly isCriticalError = true;
}

export class GoogleMapsAuthError extends Error {
  override readonly name = "GoogleMapsAuthError";
  readonly jqXHR: JQueryXHR;
  readonly status: number;

  constructor(jqXHR: JQueryXHR) {
    super();

    this.jqXHR = jqXHR;
    this.status = jqXHR.status;
  }
}

export class ServiceWorkerInstallationError extends NestedError {
  override readonly name = "ServiceWorkerInstallationError";
  readonly innerError: DOMException;

  constructor(message: string, innerError: DOMException) {
    super(message);

    this.innerError = innerError;
  }

  friendlyMessage(): string {
    switch (this.innerError.name) {
      case DOMExceptionType.SecurityError:
        return captionService.getString(
          "af69b9d6-6fad-486e-a970-9306054a07e9",
          "A secure connection could not be established, application functionality may be limited. Please try reloading the application or contact your Administrator to return to a secure connection."
        );
      case DOMExceptionType.NotSupportedError:
        return captionService.getString(
          "fe20831f-8e2c-4562-81df-132753bd93e7",
          "A service worker failed to install as expected possibly due to cookies being disabled. Some functionality may not work as intended if working in offline mode. Please refresh the page and try again or contact your Administrator if the error persists."
        );
      default:
        return captionService.getString(
          "85af9df0-40b6-4689-8043-fb2fe270d7bc",
          "A service worker failed to install as expected. Some functionality may not work as intended if working in offline mode. Please refresh the page and try again or contact your Administrator if the error persists."
        );
    }
  }
}

export class ServiceWorkerInstallationBadResponseError extends NestedError {
  override readonly name = "ServiceWorkerInstallationBadResponseError";
  readonly swResponseStatus: number;

  constructor(message: string, status: number, innerError?: Error) {
    super(message, innerError);

    this.swResponseStatus = status;
  }
}

export class ServiceWorkerUnknownInstallationError extends NestedError {
  override readonly name = "ServiceWorkerUnknownInstallationError";
}

export class ScriptLoadError extends NestedError {
  override readonly name = "ScriptLoadError";
  readonly errorType: ScriptLoadErrorType;
  readonly status?: number;

  constructor(errorType: ScriptLoadErrorType, uri: string, status?: number, innerError?: Error) {
    let message = `Failed to load script for URI: ${uri}; Reason: ${errorType}`;
    if (isNumber(status)) {
      message += `; Status code: ${status}`;
    }

    super(message, innerError);

    this.errorType = errorType;
    this.status = status;
  }
}

export class FileUploadError extends NestedError {
  override readonly name = "FileUploadError";
  readonly friendlyMessage: string;

  constructor(message: string, friendlyMessage: string, innerError?: Error) {
    super(message, innerError);

    this.friendlyMessage = friendlyMessage;
  }
}

export class FormFlowNotFoundError extends Error {
  override readonly name = "FormFlowNotFoundError";

  constructor(message: string) {
    super(message);
  }
}

export class FormFlowAbortError extends Error {
  override readonly name = "FormFlowAbortError";
}

export class FormExtenderError extends NestedError {
  override readonly name = "FormExtenderError";
}

export class FormTemplateForbiddenError extends NestedError {
  override readonly name = "FormTemplateForbiddenError";
  constructor(innerError?: Error) {
    super("Permission denied", innerError);
  }
}

export class FormTemplateNotFoundError extends NestedError {
  override readonly name = "FormTemplateNotFoundError";
  constructor(innerError?: Error) {
    super("Unable to load form template", innerError);
  }
}

export class WorkflowError extends Error {
  override readonly name = "WorkflowError";
  readonly workflowErrorType: WorkflowErrorType;
  readonly actionName?: string;

  constructor(workflowErrorType: WorkflowErrorType, exceptionMessage?: string, actionName?: string) {
    super(exceptionMessage);

    this.workflowErrorType = workflowErrorType;
    this.actionName = actionName;
  }

  friendlyMessage(): string {
    switch (this.workflowErrorType) {
      case WorkflowErrorType.BadRequest:
        return !isNullOrWhitespace(this.message) ? this.message : getGenericWorkflowErrorMessage();

      case WorkflowErrorType.Forbidden:
        if (isNullOrWhitespace(this.actionName)) {
          return captionService.getString(
            "f74b5231-cdbd-4c3a-aca4-43e26c8a5e43",
            "You do not have permission to perform this action. Contact your system administrator to be granted appropriate permissions."
          );
        }
        return captionService.getString(
          "3708c158-db2b-4306-8d49-86f723ebe970",
          "You do not have permission to perform this action ({0}). Contact your system administrator to be granted appropriate permissions.",
          this.actionName
        );

      case WorkflowErrorType.NotFound:
        return captionService.getString(
          "aa7dc06f-485b-48ba-ac3f-0e9a46ea1998",
          "The action could not be found. This could be due to the form-flow template being removed."
        );

      case WorkflowErrorType.EntityNotFound:
        return captionService.getString(
          "e64cbb34-d83c-419f-8902-902cadcacbef",
          "The record cannot be loaded. This could be due to the record being removed, or security restrictions."
        );

      case WorkflowErrorType.EntityPathNotFound:
        return captionService.getString(
          "e3ff0ad1-6024-4091-9add-48f9dd253c96",
          "The related record path is invalid. This could be due to the property being removed, or security restrictions."
        );

      case WorkflowErrorType.EntityTypeNotFound:
        return captionService.getString(
          "f1d059b3-6512-4907-bb2d-7713f2a7806f",
          "The record type cannot be found. This could be due to a configuration problem on the form-flow template."
        );

      case WorkflowErrorType.ConditionNotFound:
        return captionService.getString(
          "c88d5a05-7111-49ba-9bc4-ecdfc39e8be9",
          "The condition cannot be loaded. This could be due to a configuration problem on the form-flow template."
        );

      case WorkflowErrorType.CommandNotFound:
        return captionService.getString(
          "a3a6c364-9254-44af-af1a-e5efab453a40",
          "The command cannot be loaded. This could be due to a configuration problem on the form-flow template."
        );

      case WorkflowErrorType.Other:
        return getGenericWorkflowErrorMessage();
    }

    function getGenericWorkflowErrorMessage(): string {
      return captionService.getString(
        "7a4099b9-8730-4413-a8b8-09007b9c9f09",
        "An error occurred while processing this request. Please try again or contact your system administrator if the error persists."
      );
    }
  }

  friendlyCaption(): string {
    switch (this.workflowErrorType) {
      case WorkflowErrorType.Forbidden:
        return captionService.getString("8ee78f24-d379-40e9-83e2-d675e455e82a", "Permission denied");

      case WorkflowErrorType.NotFound:
        return captionService.getString("9d73de74-7baf-4f2c-9013-73e95db58cdf", "Action not found");

      case WorkflowErrorType.EntityNotFound:
        return captionService.getString("fc35bd42-8cf3-41de-8ebf-a8c14ca70309", "Record not found");

      case WorkflowErrorType.EntityPathNotFound:
        return captionService.getString("e5d7633a-e56c-4dd0-850f-19a38e74c2f5", "Related property not found");

      case WorkflowErrorType.EntityTypeNotFound:
        return captionService.getString("39d4e6ad-717d-4ad2-accc-e923e927f23c", "Record type not found");

      case WorkflowErrorType.ConditionNotFound:
        return captionService.getString("cbf8ee60-22d2-4775-ab6f-84c39f49fd92", "Condition not found");

      case WorkflowErrorType.CommandNotFound:
        return captionService.getString("5afd61cc-8220-4dff-9016-77ac64fee7e0", "Command not found");

      case WorkflowErrorType.BadRequest:
      case WorkflowErrorType.Other:
        return captionService.getString("a9335208-15a3-469b-928a-241aa22973f5", "Cannot perform action");
    }
  }
}

export class RuleInvocationException extends NestedError {
  override readonly name = "RuleInvocationException";

  constructor(entityType: string, ruleId: string, property: string, innerError: Error | AjaxError) {
    let messageText = "";
    if (innerError instanceof AjaxError) {
      const errorMessage = innerError.getErrorResponse<{ Message: string }>();
      if (errorMessage?.Message === RuleInvocationErrorType.InvalidArguments) {
        messageText = `\n\nHowever, certain cases can be fixed directly on the yaml file.
Example: Expression is XX_YY == @expressionKey
If XX_YY is a nullable guid, then the corresponding argument type in methodTypeArguments list should be 'System.Nullable\`1[[System.Guid]]'.
If XX_YY is a guid, then the corresponding argument type in methodTypeArguments list should be 'System.Guid'.`;
      }
    }

    super(
      captionService.getString(
        "2cf1230f-b157-468c-a337-0beb8a18e6d5",
        "Could not invoke rule with following details\n- Entity type: {0}\n- Property name: {1}\n- Rule ID: {2}\n\nPlease make sure that rule parameters are configured correctly.\n\nTechnical message: {3}{4}",
        entityType,
        property,
        ruleId,
        innerError.message,
        messageText
      ),
      innerError
    );
  }
}

export class UnavailableArgumentsOrSecurityError extends NestedError {
  override readonly name = "UnavailableArgumentsOrSecurityError";

  constructor(innerError?: Error) {
    super(undefined, innerError);
  }
}

export class AddRelatedEntityError extends NestedError {
  override readonly name = "AddRelatedEntityError";

  constructor(propertyType: string, innerError: Error) {
    super(
      captionService.getString(
        "c67a45f6-7b29-497d-97da-f4eca134d3ce",
        "Unable to add a related entity to {0}.\nPlease check your form configuration to make sure you do not allow adding of new records to an abstract collection.\n\nTechnical message: {1}",
        propertyType,
        innerError.message
      ),
      innerError
    );
  }
}

export class InvalidFieldTypesError extends Error {
  override readonly name = "InvalidFieldTypesError";

  constructor(fieldName: string) {
    super(
      captionService.getString(
        "a22381ad-2b8f-4e34-a10e-a1b1646a9286",
        'Invalid Index Field setup."{0}" is bound to fields with different data types. Please check index service config.',
        fieldName
      )
    );
  }
}

export class LoadRulesetException extends NestedError {
  override readonly name = "LoadRulesetException";

  private readonly isRuleScriptGenerationException: boolean;
  private readonly routeName: string;
  private readonly technicalMessage: string;

  constructor(routeName: string, innerError: AjaxError) {
    let responseData: { IsRuleScriptGenerationException: boolean | undefined; Message: string | undefined } | undefined;
    try {
      responseData = JSON.parse(innerError.responseText);
    } catch (e) {
      // Don't care.
    }

    const isRuleScriptGenerationException = responseData?.IsRuleScriptGenerationException ?? false;
    const technicalMessage =
      (isRuleScriptGenerationException ? responseData?.Message : innerError.message) ?? innerError.message;
    const message = isRuleScriptGenerationException
      ? `Error generating ruleset scripts for route ${routeName}.\nPlease check your rule configuration.\n\nTechnical message:\n${technicalMessage}`
      : `Unable to load rule-set for route ${routeName}.\nPlease check your rule configuration.\n\nTechnical message: ${technicalMessage}`;

    super(message, innerError);

    this.isRuleScriptGenerationException = isRuleScriptGenerationException;
    this.routeName = routeName;
    this.technicalMessage = technicalMessage;
  }

  friendlyMessage(): string {
    return this.isRuleScriptGenerationException
      ? captionService.getString(
          "9bef1ea6-4e9a-49ae-b15d-7660975f99e1",
          "Error generating ruleset scripts for route {0}.\nPlease check your rule configuration.\n\nTechnical message:\n{1}",
          this.routeName,
          this.technicalMessage
        )
      : captionService.getString(
          "461d4fc2-2e02-4bda-9264-c888adde1006",
          "Unable to load rule-set for route {0}.\nPlease check your rule configuration.\n\nTechnical message: {1}",
          this.routeName,
          this.technicalMessage
        );
  }
}

export class SetLocationError extends NestedError {
  override readonly name = "SetLocationError";

  constructor(previousURL: string, newURL: string, innerError: Error) {
    super(
      captionService.getString(
        "08FFA0A0-28C5-4CD9-91D4-861BB20D06E2",
        "An error occurred while setting new location, previous URL:{0}, new URL:{1}\n\nTechnical message: {2}",
        previousURL,
        newURL,
        innerError.message
      ),
      innerError
    );
  }
}

export class DependencyError extends Error {
  override readonly name = "DependencyError";
}

export class DependencyOptionsError extends Error {
  override readonly name = "DependencyOptionsError";
}

export class FormFlowSerializationError extends Error {
  override readonly name = "FormFlowSerializationError";
}

export class CadastralServiceError extends Error {
  override readonly name = "CadastralServiceError";
}

export class UnknownModuleError extends NestedError {
  override readonly name = "UnknownModuleError";
}

export class GSSDataDuplicateRowError extends Error {
  override readonly name = "GSSDataDuplicateRowError";
}

export class EdocsMissingDocTypeError extends Error {
  override readonly name = "EdocsMissingDocTypeError";
  readonly docManagerCode: string;

  constructor(docManagerCode: string) {
    super();

    this.docManagerCode = docManagerCode;
  }
}

export class PropertyRestrictionError extends NestedError {
  override readonly name = "PropertyRestrictionError";
}

export class IndexServiceError extends NestedError {
  override readonly name = "IndexServiceError";
}

export class LowDiskSpaceError extends NestedError {
  override readonly name = "LowDiskSpaceError";
}
export class DocumentNotFoundOrNotApplicable extends Error {
  override readonly name = "DocumentNotFoundOrNotApplicable";
  readonly documentId: string;
  readonly formFlowId: string;

  constructor(documentId: string, formFlowId: string) {
    super();

    this.documentId = documentId;
    this.formFlowId = formFlowId;
  }
}

export class BreezeAttachEntityError extends NestedError {
  override readonly name = "BreezeAttachEntityError";
  readonly isCriticalError = true;
  readonly explanation =
    "This may be because an entity already belongs to another entity manager. To resolve this, one of the two entities has to be detached first. A way to approach this could be by setting a default model for this entity.";

  getData(): ErrorData[] {
    return [{ name: "Error Explanation", value: this.explanation }];
  }
}

export class RetryableActivityInvocationError extends NestedError {
  override readonly name = "RetryableActivityInvocationError";

  constructor(innerError?: Error) {
    super(undefined, innerError);
  }
}

export class TooManyOrderExpressionsError extends Error {
  override readonly name = "TooManyOrderExpressionsError";
}

export class UnhandledError extends NestedError {
  override readonly name = "UnhandledError";

  constructor(innerError: Error) {
    super(innerError.message, innerError);
  }
}

export class VisibilityEvaluatorException extends Error {
  override readonly name = "VisibilityEvaluatorException";
}

export class EDocsConfigurationError extends NestedError {
  override readonly name = "EDocsConfigurationError";
}

export class MissingClientLinkError extends Error {
  override readonly name = "MissingClientLinkError";
}

export class ConfigurableModuleNotFoundError extends NestedError {
  override readonly name = "ConfigurableModuleNotFoundError";
}

export function isCritical(error: Error): boolean {
  return isCriticalCore(error as IsCriticalErrorCoreParameter);

  type IsCriticalErrorCoreParameter = {
    isCriticalError?: boolean;
    innerError?: unknown;
  };

  function isCriticalCore(error: IsCriticalErrorCoreParameter): boolean {
    if (error.isCriticalError) {
      return true;
    } else if (error.innerError) {
      return isCriticalCore(error.innerError);
    } else {
      return false;
    }
  }
}

export function isNetworkError(error: Error): boolean {
  return isNetworkErrorCore(error as IsNetworkErrorCoreParameter);

  type IsNetworkErrorCoreParameter = {
    status?: number;
    innerError?: unknown;
  };

  function isNetworkErrorCore(error: IsNetworkErrorCoreParameter): boolean {
    return (
      error instanceof connection.OfflineError ||
      error.status === 0 ||
      !!(error.innerError && isNetworkErrorCore(error.innerError))
    );
  }
}

export function isTransientError(error: Error & { isTransientError?: () => boolean }): boolean {
  return !!error.isTransientError?.();
}

export function isDbUpgradeError(error: Error): boolean {
  const matcher = (e: unknown): boolean => {
    const errorObject = e as unknown as {
      status?: string;
      getResponseHeader?: (key: string) => string | undefined | null;
    };

    return (
      !!errorObject.status && parseInt(errorObject.status) === 503 && !!errorObject.getResponseHeader?.("wtg-upgrade")
    );
  };

  return !!matchError(error, matcher);
}

export function isModuleNotFoundError(error: unknown): error is Error {
  return isModuleNotFoundErrorCore(error as ModuleNotFoundError);

  type ModuleNotFoundError = {
    code?: string;
    message?: string;
  };

  function isModuleNotFoundErrorCore(error: ModuleNotFoundError): boolean {
    return error?.code === "MODULE_NOT_FOUND" && typeof error?.message === "string";
  }
}

export function isErrorWithRequestUri(error: unknown): boolean {
  return isErrorWithRequestUriCore(error as ErrorWithRequestUri);

  function isErrorWithRequestUriCore(error: ErrorWithRequestUri): boolean {
    return error.constructor.name === "Error" && typeof error?.type === "string" && typeof error?.request === "string";
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyErrorConstructor<T extends Error> = { new (...args: any[]): T };

export function findError<T extends Error>(
  rootError: Error & { innerError?: Error },
  expectedErrorType: AnyErrorConstructor<T>
): T | undefined {
  if (rootError instanceof expectedErrorType) {
    return rootError;
  }
  if (rootError.innerError) {
    return findError(rootError.innerError, expectedErrorType);
  }
  return undefined;
}

export function matchError(
  rootError: Error & { innerError?: Error },
  condition: (error: Error) => boolean
): Error | undefined {
  if (condition(rootError)) {
    return rootError;
  }
  if (rootError.innerError) {
    return matchError(rootError.innerError, condition);
  }
  return undefined;
}

// TODO: Refactor & improve error data in WI00542012.
export function addErrorData(error: Error & { getData?: () => ErrorData[] }, data: ErrorData[]): void {
  if (error.getData) {
    const innerGetData = error.getData;
    error.getData = (): ErrorData[] => {
      return innerGetData.call(error).concat(data);
    };
  } else {
    error.getData = (): ErrorData[] => data;
  }
}

/** @deprecated use named import instead */
export default {
  WorkflowErrorType,
  ScriptLoadErrorType,
  RuleInvocationErrorType,
  FormFlowErrors,
  DOMExceptionType,
  SignalRError,
  ResourceStreamError,
  AmbiguousModelError,
  BindingPathError,
  CancellationError,
  FilterStripError,
  QueryTooComplexError,
  ExpandPathTooDeepError,
  StreamQueryError,
  BreezeQueryError,
  BreezeMetadataError,
  RenewSessionError,
  SessionOutdatedError,
  GoogleMapsAuthError,
  ServiceWorkerInstallationError,
  ServiceWorkerInstallationBadResponseError,
  ServiceWorkerUnknownInstallationError,
  ScriptLoadError,
  FileUploadError,
  FormFlowNotFoundError,
  FormFlowAbortError,
  FormExtenderError,
  WorkflowError,
  RuleInvocationException,
  UnavailableArgumentsOrSecurityError,
  AddRelatedEntityError,
  InvalidFieldTypesError,
  LoadRulesetException,
  SetLocationError,
  DependencyError,
  DependencyOptionsError,
  FormFlowSerializationError,
  CadastralServiceError,
  UnknownModuleError,
  EdocsMissingDocTypeError,
  PropertyRestrictionError,
  IndexServiceError,
  LowDiskSpaceError,
  DocumentNotFoundOrNotApplicable,
  BreezeAttachEntityError,
  RetryableActivityInvocationError,
  TooManyOrderExpressionsError,
  UnhandledError,
  VisibilityEvaluatorException,
  EDocsConfigurationError,
  MissingClientLinkError,
  ConfigurableModuleNotFoundError,
  DataServiceRequestError,
  isCritical,
  isNetworkError,
  isTransientError,
  isDbUpgradeError,
  findError,
  matchError,
  addErrorData,
};
/*! EndNoStringValidationRegion */