import $ from 'jquery';
import _ from 'UnderscoreExtensions';
import ResizeObserver from 'resize-observer-polyfill';

const resizeObserver = new ResizeObserver(onElementsResized);
/*! SuppressStringValidation Exception for the developer */
const resizeEventName = 'resize';

function onElementsResized(entries) {
	entries.forEach((entry) => {
		const element = entry.target;
		$(element).triggerHandler(resizeEventName, entry.contentRect);
	});
}

function throwIfInvalidElementType(element) {
	//Ensure that we don't listen to resize on things that don't really have size, like document.
	if (element !== window && !(element instanceof HTMLElement)) {
		/*! SuppressStringValidation Exception for the developer */
		throw new Error('Resize is only supported for instances of HTMLElement or Window.');
	}
}

function getRateLimitedHandler(handleObj, rateLimit) {
	const handler = handleObj.handler;
	const decoratedHandler = function (e) {
		if (!handleObj.isCanceled) {
			return handler.apply(e.currentTarget, arguments);
		}
	};

	return _.debounce(decoratedHandler, rateLimit);
}

$.event.special[resizeEventName] = {
	setup() {
		throwIfInvalidElementType(this);

		//we will attach to the native window.onresize event instead
		if (this === window) {
			return false;
		}
		resizeObserver.observe(this);
	},
	add(handleObj) {
		throwIfInvalidElementType(this);

		//we will attach to the native window.onresize event instead
		if (this === window) {
			return false;
		}
		const rateLimit = $.event.special[resizeEventName].rateLimit;
		if (rateLimit !== 0) {
			handleObj.handler = getRateLimitedHandler(handleObj, rateLimit || 50);
		}
	},
	remove(handleObj) {
		handleObj.isCanceled = true;
	},
	teardown() {
		//we will attach to the native window.onresize event instead
		if (this === window) {
			return false;
		}
		resizeObserver.unobserve(this);
	}
};
