<script>
	import Vue, { unref, watch } from 'vue';
	import { tryGetVueExtender, tryGetVueComponent } from 'ModuleLoader';
	import { useProposedValues } from 'VueHooks/ProposedValuesHook';
	import { useCaption } from 'VueHooks/CaptionHook';
	import { useReadonly } from 'VueHooks/ReadonlyHook';
	import { useValidationState } from 'VueHooks/ValidationStateHook';
	import { useConditionalVisibility } from 'VueHooks/ConditionalVisibilityHook';
	import { useCalculatedProperty } from 'VueHooks/CalculatedPropertyHook';
	import { usePageViewModel } from 'VueHooks/PageViewModelHook.ts';

	export default Vue.extend({
		props: {
			innerComponent: {
				type: String,
				required: true
			},
			extender: {
				type: String,
			},
			visibilityCondition: {
				type: String,
			},
			bindingPath: {
				type: String,
			},
			caption: {
				type: Object,
			},
			captionOverride: {
				type: Object,
			},
			skipCaptionFallback: {
				type: Boolean,
			},
			readonly: {
				type: [Boolean, Object],
			},
			designControlId: {
				type: String,
			},
			anchor: {
				type: String,
			},
			canBeHidden: {
				type: [Boolean, String],
			},
			viewModel: {
				type: String,
			},
			viewModelDataItem: {
				type: String,
			}
		},
		data() {
			return {
				attrs: {},
				props: {},
				on: {},
				extension: null,
				renderComponent: true
			};
		},
		computed: {
			dataObject() {
				const dataObject = {
					attrs: this.attrs,
					props: this.extension && this.extension.props
						? Object.assign({}, this.props, this.extension.props)
						: this.props,
					on: this.extension && this.extension.on
						? Object.assign({}, this.on, this.extension.on)
						: this.on,
					class: this.extension && this.extension.classes
				};

				if (this.extension && this.extension.scopedSlots) {
					dataObject.scopedSlots = {};
					for (const slot of this.extension.scopedSlots) {
						if (slot.name && slot.component) {
							dataObject.scopedSlots[slot.name] = (props) => {
								return this.$createElement(slot.component, { props });
							};
						}
					}
				}
				return dataObject;
			},
			children() {
				return (this.extension && unref(this.extension.children)) || this.$slots.default;
			},
			waitUntilLoadedAsync() {
				return (this.extension && this.extension.waitUntilLoadedAsync) || null;
			}
		},
		created() {
			if (!this.innerComponent) {
				throw new Error('The GlowWrapper must have an innerComponent defined');
			}

			for (const key in this.$attrs) {
				this.attrs[key] = this.$attrs[key];
				Vue.set(this.props, key, this.$attrs[key]);
			}

			if (this.designControlId) {
				this.attrs['data-design-control-id'] = this.designControlId;
			}

			if (this.bindingPath) {
				useProposedValues(this.bindingPath);

				const validationState = useValidationState(this.bindingPath);
				Vue.set(this.props, 'validationState', validationState);
			}

			if (this.visibilityCondition) {
				const visibleRef = useConditionalVisibility(this.bindingPath, this.visibilityCondition);
				watch(() => visibleRef.value, (value) => this.renderComponent = value, { immediate: true });
			}

			const caption = useCaption(this.caption, this.captionOverride, this.bindingPath, this.skipCaptionFallback);
			Vue.set(this.props, 'caption', caption);

			const extender = this.extender && tryGetVueExtender(this.extender);

			if (!extender || !extender.disableDefaultReadOnly) {
				const readOnlyRef = useReadonly(this.bindingPath, this.readonly);
				Vue.set(this.props, 'readonly', readOnlyRef.value);
				watch(() => readOnlyRef.value, (value) => this.props.readonly = value);
			}

			if (extender) {
				const viewModel = usePageViewModel();
				const pageExtensions = viewModel?.pageExtensions;
				const attrs = {
					props: this.props,
					on: this.on,
					bindingPath: this.bindingPath,
					designControlId: this.designControlId,
					configurationMode: !!(pageExtensions && pageExtensions.configurationContext),
					readonly: this.readonly,
					viewModelName: this.viewModel,
					viewModelDataItemPath: this.viewModelDataItem,
					children: this.$children,
					theme: this.$wtgMaterialUi?.theme ?? {},
				};

				this.extension = extender(attrs);
				if (this.extension && this.extension.remove) {
					for (const prop of this.extension.remove) {
						delete this.attrs[prop];
						delete this.props[prop];
					}
				}
			}

			for (const key in this.props) {
				this.props[key] = useCalculatedProperty(this.props[key]);
			}

			this.ComponentConstructor = tryGetVueComponent(this.innerComponent);
		},
		render(createElement) {
			if (this.renderComponent) {
				return createElement(this.ComponentConstructor, { ...this.dataObject }, this.children);
			}
			return null;
		}
	});
</script>

