/**
 * "moment" usage is deprecated. Use DateExtensions instead. Please do not add type definitions for MomentExtensions.
 * This file is only for backward compatibility
 */

// This must be a CommonJS module because moment is aliased to this.

const moment = require('moment.base');
const DateExtensions = require('DateExtensions');

moment.localTimezoneOffset = DateExtensions.localTimezoneOffset;
moment.durationEpoch = DateExtensions.durationEpoch();
moment.maxDuration = DateExtensions.maxDuration();
moment.maxDurationAsDateTime = DateExtensions.maxDurationAsDateTime();
moment.timeEpoch = DateExtensions.timeEpoch();
moment.minValue = DateExtensions.minValue();
moment.zeroDurationString = DateExtensions.zeroDurationString;
moment.invalidOffset = DateExtensions.invalidOffset;
moment.durationFromDate = DateExtensions.durationFromDate;
moment.formatStringForDateTimeType = DateExtensions.formatStringForDateTimeType;
moment.isNonMinDate = DateExtensions.isNonMinDate;
moment.isValidDate = DateExtensions.isValidDate;
moment.format = DateExtensions.formatDate;
moment.formats = DateExtensions.getDateFormats;
moment.parse = DateExtensions.parseDate;
moment.parseExpression = DateExtensions.parseDateExpression;
moment.parseDurationExpression = DateExtensions.parseDurationExpression;
moment.parseDurationExpressionWithDays = DateExtensions.parseDurationExpressionWithDays;
moment.parseNumericShortcut = DateExtensions.parseNumericShortcut;
moment.toDateTimeTypeString = DateExtensions.toDateTimeTypeString;
moment.today = DateExtensions.today;
moment.todayAsDate = DateExtensions.todayAsDate;

moment.fn.applyUtcOffset = function (dateTimeType) {
	return DateExtensions.applyUtcOffset(this, dateTimeType);
};

moment.duration.fn.toString = function () {
	return DateExtensions.durationToString(this);
};

moment.duration.fn.toDate = function () {
	return DateExtensions.durationToDate(this);
};

moment.fn.reverseUtcOffset = function (dateTimeType) {
	return DateExtensions.reverseUtcOffset(this, dateTimeType);
};

moment.fn.setHoursAndMinutes = function (date) {
	return DateExtensions.setHoursAndMinutes(this, date);
};

moment.fn.setYearMonthAndDay = function (date) {
	return DateExtensions.setYearMonthAndDay(this, date);
};

moment.fn.toDateTimeTypeString = function (dateTimeType) {
	return DateExtensions.toDateTimeTypeString(this, dateTimeType);
};

moment.fn.toFilterValueString = function (dateTimeType) {
	return DateExtensions.toFilterValueString(this, dateTimeType);
};

moment.fn.trimSeconds = function () {
	return DateExtensions.trimSeconds(this);
};

module.exports = moment;
