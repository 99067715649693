import { DataServiceRequestError } from "DataServiceRequestError";
import { getLanguageCode } from "LanguageService";
import oData from "OData";
import { addODataETagHeader, getODataHeaders, getODataRoute, MetadataType } from "ODataUtils";
import type { HttpOData } from "ts-odatajs";

export interface DataWithMetadata {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  __metadata: {
    uri: string;
    isNew?: boolean;
  };
  [key: string]: unknown;
}

class DataService {
  async invokeRequestAsync<T>(request: HttpOData.Request): Promise<T> {
    request.requestUri = getODataRoute(request.requestUri);
    request.headers = getODataHeaders(MetadataType.Verbose, request.headers);

    const languageCode = getLanguageCode();
    if (languageCode) {
      /*! SuppressStringValidation Header name*/
      request.headers["Enterprise-Language-Code"] = languageCode;
    }

    return await new Promise((resolve, reject) => {
      oData.request(request, resolve, (error) => {
        if (!(error instanceof Error)) {
          reject(new DataServiceRequestError(request.requestUri, error));
        } else {
          reject(error);
        }
      });
    });
  }

  async getAsync<T>(requestUri: string): Promise<T> {
    /*! StartNoStringValidationRegion Headers */
    const request: HttpOData.Request = {
      headers: { "Cache-Control": "no-cache" }, // Not sure if this actually does anything.
      requestUri,
      method: "GET",
      data: null,
    };
    /*! EndNoStringValidationRegion */

    return await this.invokeRequestAsync<T>(request);
  }

  async saveAsync<T>(data: DataWithMetadata): Promise<T> {
    const { __metadata: metadata, ...dataNoMeta } = data;

    const headers = { "Content-Type": "application/json" };
    addODataETagHeader(headers, dataNoMeta);

    let method;
    if (metadata.isNew) {
      method = "POST";
    } else {
      method = "PATCH";
    }

    const request: HttpOData.Request = {
      headers,
      requestUri: metadata.uri,
      method,
      data: dataNoMeta,
    };

    return await this.invokeRequestAsync<T>(request);
  }

  async deleteAsync(entity: DataWithMetadata): Promise<void> {
    const headers = {};

    addODataETagHeader(headers, entity);

    const request: HttpOData.Request = {
      requestUri: entity.__metadata.uri,
      method: "DELETE",
      headers,
    };

    return await this.invokeRequestAsync(request);
  }

  async invokeActionAsync<T>(uri: string, requestData: unknown): Promise<T> {
    const request: HttpOData.Request = {
      requestUri: uri,
      method: "POST",
      data: requestData,
    };

    return await this.invokeRequestAsync<T>(request);
  }
}

export default new DataService();
