import ajaxService from 'AjaxService';
import Promise from 'bluebird';
import global from 'Global';
import { format } from 'StringUtils';

class LogonFormRetriever {
	constructor() {
		this._entityName = null;
		this._loader = null;
	}

	getEntityNameAsync() {
		if (this._entityName) {
			return Promise.resolve(this._entityName);
		}
		else {
			return this.getFormAsync().then((logonForm) => logonForm.EntityName);
		}
	}

	getMarkupAsync() {
		return this.getFormAsync().then((logonForm) => logonForm.Markup);
	}

	getFormIdAsync(logonType, formFactor) {
		return getFormAsync(logonType, formFactor).then((logonForm) => logonForm.Id);
	}

	getFormAsync() {
		if (!this._loader) {
			this._loader = getFormAsync().then((form) => {
				this._entityName = form.EntityName;
				this._loader = null;
				return form;
			});
		}
		return this._loader;
	}
}

function getFormAsync(logonType, formFactor) {
	return ajaxService.getAsync(format('{0}api/forms/logon/{1}/{2}/{3}',
		global.serviceUri,
		global.formLanguage,
		global.materialDesign ? 'MD' : formFactor || global.formFactor,
		logonType || global.portalInfo.userType
	));
}

export default new LogonFormRetriever();
