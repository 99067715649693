import ajaxService from 'AjaxService';
import { AuthenticationError, AuthenticationResult, session } from 'AuthenticationService';
import captionService from 'CaptionService';
import connection from 'Connection';
import dialogService from 'DialogService';
import log from 'Log';
import nativeBridge from 'NativeBridge';
import notificationType from 'NotificationType';
import moment from 'moment';

export function tryHandleSessionLimitErrorAsync(error) {
	log.withTag('UserSession').info('SessionLimitReachedHandler.tryHandleSessionLimitErrorAsync');

	if (isSessionLimitError(error)) {
		const dialogCaptions = getDialogCaptions(error.claimDetails.conflictingSessions);

		return promptForDestroyAsync(dialogCaptions);
	}
	return Promise.resolve(false);
}

export function isSessionLimitError(error) {
	return (
		error instanceof AuthenticationError &&
		error.authenticationResult === AuthenticationResult.SessionLimitReached
	);
}

async function promptForDestroyAsync(dialogCaptions) {
	const buttonOptions = [
		{
			buttonType: dialogService.buttonTypes.No().value,
			caption: dialogService.buttonTypes.No().text,
			result: dialogService.buttonTypes.No().value,
			isDefault: true,
			isDismiss: true,
		},
		{
			buttonType: dialogService.buttonTypes.Yes().value,
			caption: dialogService.buttonTypes.Yes().text,
			result: dialogService.buttonTypes.Yes().value,
			isDismiss: true,
		},
	];
	const dialogOptions = {
		closeOnDismissOnly: true,
	};

	const dialogResult = await dialogService.showAsync(
		notificationType.Error,
		dialogCaptions.messageBody,
		dialogCaptions.title,
		buttonOptions,
		dialogOptions
	);
	if (dialogResult === dialogService.buttonTypes.No().value) {
		return false;
	}

	try {
		await session.destroyAllAsync(await nativeBridge.getSessionTypeAsync());
		return true;
	} catch (error) {
		if (
			(error instanceof AuthenticationError &&
				error.innerError instanceof ajaxService.AjaxError &&
				(error.innerError.status === 401 || error.innerError.isTransientError())) ||
			error instanceof connection.OfflineError
		) {
			await dialogService
				.alertAsync(
					notificationType.Error,
					dialogCaptions.failedMessageBody,
					dialogCaptions.failedTitle
				);
			return false;
		}
		throw error;
	}
}

function getDialogCaptions(conflictingSessions) {
	const conflictingSessionString = getConflictingSessionString(conflictingSessions);

	const reason = captionService.getString(
		'37111d05-e1aa-4468-b306-56063fc6f11d',
		'Your user account is already logged in from the following sessions:'
	);
	const title = captionService.getString(
		'9adf52b5-b554-4c02-adb3-f4383c576eb2',
		'User already logged in'
	);
	const dialogBody = captionService.getString(
		'0e36e2a5-0e86-41f9-9a5a-78517e9c0330',
		'A user can only be logged in from one session at a time.\n\nDo you want to force the other sessions to exit?\nWARNING: They could lose any unsaved work.'
	);

	const failedTitle = captionService.getString(
		'ff00bca9-9947-4807-a7e4-03fbdbb83491',
		'Something Went Wrong'
	);
	const failedMessageBody = captionService.getString(
		'c739660f-b6ee-4c00-90cd-4a0b566d4f58',
		'We were unable to terminate the other sessions. Please try again.'
	);

	return {
		title,
		messageBody: `${reason}\n${conflictingSessionString}\n\n${dialogBody}`,
		failedTitle,
		failedMessageBody,
	};
}

function getConflictingSessionString(conflictingSessions) {
	/*! SuppressStringValidation no caption here*/
	const hyphen = conflictingSessions.length === 1 ? '' : '- ';
	const sessionStrings = [];
	conflictingSessions.forEach((conflictingSession) => {
		const ipAddress =
			conflictingSession.machineName ||
			captionService.getString('f063c922-56c9-4d4a-a083-286dcedf4b6e', 'unknown');
		const acquiredTime = formatDateTime(conflictingSession.acquiredTime);
		sessionStrings.push(
			hyphen +
				captionService.getString(
					'bd15cade-831c-4c29-88ff-514e577aa9b5',
					'{0} (since {1})',
					ipAddress,
					acquiredTime
				)
		);
	});

	return sessionStrings.join('\n');
}

function formatDateTime(dateTimeString) {
	/*! SuppressStringValidation date & time format */
	return dateTimeString
		? moment(dateTimeString).format(moment.formatStringForDateTimeType())
		: null;
}
