import baseStore, { type StoreType as BaseStoreType } from "store2";
import "store.on";
import "store.overflow";

// We are declaring an extended type since store2 type declaration is missing the namespace() function
// and the extensions such as store.on have no type declarations.

interface StoreType extends BaseStoreType {
  namespace(): string;
  namespace(namespace: string, singleArea?: true, delim?: string): StoreType;
  on(key: string, callback: () => void): void;
  off(key: string, callback: () => void): void;
}

const store = baseStore as StoreType;
export { store, type StoreType };
