import { PromiseCacheItem } from "PromiseCacheItem";

export class DeferredPromise<T> {
  private readonly cache: PromiseCacheItem<T>;
  private innerResolve?: (value: T) => void;
  private innerReject?: (error: unknown) => void;

  constructor() {
    const promise = new Promise<T>((resolve, reject) => {
      this.innerResolve = resolve;
      this.innerReject = reject;
    });

    this.cache = new PromiseCacheItem();
    this.cache.cachePromise(promise);

    // prevent unhandled rejection when the inner promise is rejected
    promise.catch(() => undefined);
  }

  get promise(): Promise<T> {
    const cachedPromise = this.cache.accessCache();
    if (!cachedPromise) {
      // only possible if someone introduces a bug in the resolve / reject functions
      throw new Error("Deferred promise has been reset without resolve or reject");
    }
    return cachedPromise;
  }

  resolve(value: T): void {
    if (this.innerResolve) {
      this.cache.setResolved(value);
      this.innerResolve(value);
      this.clearCachedPromise();
    }
  }

  reject(error: unknown): void {
    if (this.innerReject) {
      this.cache.setRejected(error);
      this.innerReject(error);
      this.clearCachedPromise();
    }
  }

  get isFulfilled(): boolean {
    return this.cache.isFulfilled;
  }

  get isRejected(): boolean {
    return this.cache.isRejected;
  }

  get isPending(): boolean {
    return this.cache.isPending;
  }

  private clearCachedPromise(): void {
    this.innerResolve = undefined;
    this.innerReject = undefined;
    this.cache.clearCachedPromise();
  }
}
