import type { Entity } from "BreezeExtensions";
import { useUltimateDataItem } from "VueHooks/UltimateDataItemHook";
import { onUnmounted, watch, type Ref } from "vue";
import { type MaybeRefOrGetter } from "vue3-polyfill";

type DataItemRef = Ref<Entity | null>;

export function useProposedValues(bindingPath: MaybeRefOrGetter<string>): void {
  const dataItemRef = useUltimateDataItem(bindingPath);
  useProposedValuesWithDataItemRef(dataItemRef);
}

export function useProposedValuesWithDataItemRef(dataItemRef: DataItemRef): void {
  if (!dataItemRef) {
    return;
  }

  watch(
    () => dataItemRef.value,
    (dataItem, oldDataItem = null) => {
      stopProposedValuesListener(oldDataItem);
      startProposedValuesListener(dataItem);
    },
    {
      immediate: true,
    }
  );

  onUnmounted(() => stopProposedValuesListener(dataItemRef?.value));
}

function startProposedValuesListener(dataItem: Entity | null): void {
  dataItem?.entityAspect?.proposedValues.startListeningAsync();
}

function stopProposedValuesListener(dataItem: Entity | null): void {
  dataItem?.entityAspect?.proposedValues.stopListening();
}
