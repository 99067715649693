import ajaxService, { AjaxError, type GlowUrlAjaxSettings } from "AjaxService";
import appConfig from "AppConfig";
import { AsyncDictionary } from "AsyncDictionary";
import errorHandler from "ErrorHandler";
import { isNetworkError } from "Errors";
import global from "Global";
import { NotificationType } from "NotificationType";
import base64 from "base64-js";
import howler, { type Howl } from "howler";

interface SystemAudio {
  data?: Uint8Array;
  isError?: boolean;
  canRetry?: boolean;
  timeOccurred?: Date;
}

class AudioService {
  private cache: AsyncDictionary<SystemAudio>;
  private lastErrorTime?: Date;
  private unloadingState: WeakMap<Howl, boolean> = new WeakMap();

  constructor() {
    this.cache = new AsyncDictionary<SystemAudio>();
  }

  getAudioData(name: string, content: string): string {
    /*! StartNoStringValidationRegion URL. */
    let result = "data:audio/";
    const extension = name.substring(name.lastIndexOf("."), name.length).toLowerCase();
    switch (extension) {
      case ".mp3":
        result += "mp3";
        break;
      case ".wav":
        result += "wav";
        break;
      case ".ogg":
        result += "ogg";
        break;
    }
    result += ";base64," + content;
    /*! EndNoStringValidationRegion */

    return result;
  }

  play(src: string, friendlyName: string): void {
    if (this.retryDelayExpired(this.lastErrorTime)) {
      this.lastErrorTime = undefined;
      const howl = this.createHowl(src, friendlyName);
      howl.load();
      howl.play();
    }
  }

  playNotificationAudio(messageNotificationType: NotificationType): void {
    const audioPK = this.getNotificationAudioPK(messageNotificationType);
    if (audioPK) {
      this.playSystemAudioAsync(audioPK);
    }
  }

  async playSystemAudioAsync(audioKey: string): Promise<void> {
    const item: SystemAudio | undefined = await this.getSystemAudioAsync(audioKey);
    if (item?.data) {
      const dataUri = this.getAudioData(`${audioKey}.mp3`, base64.fromByteArray(item.data));
      this.play(dataUri, `audio with key ${audioKey}`);
    }
  }

  async getSystemAudioAsync(audioKey: string): Promise<SystemAudio | undefined> {
    let item: SystemAudio = await this.cache.getOrAddAsync(audioKey, this.getSystemAudioCoreAsync);
    if (item.isError && item.canRetry) {
      if (this.retryDelayExpired(item.timeOccurred)) {
        this.cache.remove(audioKey);
        item = await this.cache.getOrAddAsync(audioKey, this.getSystemAudioCoreAsync);
      }
    }

    return item;
  }

  async getSystemAudioCoreAsync(audioKey: string): Promise<SystemAudio> {
    /*! StartNoStringValidationRegion URL. */
    const settings: GlowUrlAjaxSettings = {
      url: `${global.serviceUri}api/media/audio/${audioKey}.mp3`,
      data: { v: global.versionNumber },
      dataType: "arraybuffer",
    };
    /*! EndNoStringValidationRegion */

    try {
      const value = await ajaxService.ajaxAsync<number>(settings);
      return { data: new Uint8Array(value) };
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (transientError(error)) {
          return { isError: true, canRetry: true, timeOccurred: new Date() };
        }
      }

      throw error;
    }
  }

  createHowl(src: string, friendlyName: string): Howl {
    const howl = new howler.Howl({ src: [src], preload: false });
    const unload = (): void => this.unloadHowl(howl);
    const audioServiceErrorHandler = (action: string, id?: number, message?: unknown): void => {
      /*! StartNoStringValidationRegion Error message. */
      this.lastErrorTime = new Date();
      const noAudio = howler.Howler.noAudio;
      unload();
      if (
        !noAudio &&
        message !== MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED &&
        message !== "No audio support." &&
        message !== "No codec support for selected audio sources."
      ) {
        try {
          if (typeof message === "number") {
            message = "Error code: " + message;
          }
          throw new Error(`Could not ${action} ${friendlyName || "audio"}. ${message}`);
        } catch (error: unknown) {
          errorHandler.reportError(error);
        }
      }
      /*! EndNoStringValidationRegion */
    };

    /*! StartNoStringValidationRegion Event names. */
    howl.once("end", unload);
    howl.once("loaderror", audioServiceErrorHandler.bind(null, "open"));
    howl.once("playerror", audioServiceErrorHandler.bind(null, "play"));
    /*! EndNoStringValidationRegion */

    return howl;
  }

  unloadHowl(howl: Howl): void {
    this.unloadingState.set(howl, true);
    try {
      howl.unload();
    } catch (error) {
      if (!this.unloadingState.get(howl)) {
        throw error;
      }
    } finally {
      this.unloadingState.set(howl, false);
    }
  }

  retryDelayExpired(originalTime?: Date): boolean {
    const retryDelay = appConfig.audioRetryDelayMinutes;
    /*! SuppressStringValidation Unit of time. */
    return !retryDelay || !originalTime || new Date().getTime() - originalTime.getTime() > retryDelay * 1000 * 60;
  }

  getOverriddenAudioPK(value: string): string | undefined {
    return global.portalInfo.overriddenSounds && global.portalInfo.overriddenSounds[value];
  }

  getNotificationAudioPK(value: NotificationType): string | undefined | null {
    /*! StartNoStringValidationRegion notification type is not translatable */
    switch (value) {
      case NotificationType.Success:
        return this.getOverriddenAudioPK("Success") || "d23bf6d008674f80aa7ee5b80a6639d8";

      case NotificationType.Error:
        return this.getOverriddenAudioPK("Error") || "cb2f203d20ec4260a5a8b891322d149a";

      case NotificationType.MessageError:
        return this.getOverriddenAudioPK("MessageError") || "cbc651d6d7724ee59acdb7f7b790a8fd";

      case NotificationType.Warning:
        return this.getOverriddenAudioPK("Warning") || "d7d4e4d6ca144defa816c49dd6b99328";

      case NotificationType.Information:
        return this.getOverriddenAudioPK("Information") || "08b6de1210034263853b641aaf1fa581";

      case NotificationType.Question:
        return this.getOverriddenAudioPK("Question") || "08b6de1210034263853b641aaf1fa581";

      default:
        return null;
    }
    /*! EndNoStringValidationRegion */
  }
}

function transientError(error: Error): boolean {
  return isNetworkError(error) || (error instanceof AjaxError && error.isTransientError());
}

export default new AudioService();
