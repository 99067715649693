import ajaxService from 'AjaxService';
import entitySetRightsProvider from 'EntitySetRightsProvider';
import errors from 'Errors';

const htmlContentType = 'text/html';

function ResourceStreams(entityManager) {
	this._cache = {};
	this._entityManager = entityManager;
}

ResourceStreams.prototype.getValueAsync = async function (entity, streamName) {
	const cacheItem = getCacheItem(this._cache, entity, streamName);
	if (typeof cacheItem.value !== 'undefined') {
		return cacheItem.value;
	}

	if (entity.entityAspect.entityState.isAdded()) {
		return getDefaultValue(cacheItem.contentType);
	}

	const value = await getValueAsync(entity, streamName, cacheItem.contentType);
	if (!cacheItem.hasChanges) {
		cacheItem.value = value;
	}

	return cacheItem.value;
};

ResourceStreams.prototype.saveChangesAsync = async function () {
	const cache = this._cache;
	const entityManager = this._entityManager;

	for (const cacheKey of Object.keys(cache)) {
		const cacheItem = cache[cacheKey];
		const entity = entityManager.getEntityByKey(cacheItem.entityKey);

		if (entity && !entity.entityAspect.entityState.isDeleted()) {
			if (cacheItem.hasChanges) {
				const settings =
				{
					url: getUri(entity, cacheItem.streamName),
					type: 'PUT',
					contentType: cacheItem.contentType,
					data: cacheItem.value,
					processData: false
				};

				try {
					await ajaxService.ajaxAsync(settings);
				} catch (error) {
					if (error instanceof ajaxService.AjaxError) {
						throw new errors.ResourceStreamError(entity, error);
					}
					throw error;
				}
				cache[cacheKey].hasChanges = false;
			}
		}
		else {
			delete cache[cacheKey];
		}
	}
};

ResourceStreams.prototype.setValue = function (entity, streamName, value) {
	if (typeof value === 'undefined') {
		throw new Error('Cannot set value to undefined.');
	}

	const cacheItem = getCacheItem(this._cache, entity, streamName);
	cacheItem.value = value;
	cacheItem.hasChanges = true;

	if (entity.entityAspect.entityState.isUnchanged()) {
		entity.entityAspect.setModified();
	}
};

function getCacheItem(cache, entity, streamName) {
	const entityKey = entity.entityAspect.getKey();
	const cacheKey = entityKey + '_' + streamName;
	let cacheItem = cache[cacheKey];
	if (!cacheItem) {
		const property = entity.entityType.getStreamProperty(streamName);
		cache[cacheKey] = cacheItem =
		{
			entityKey,
			contentType: getContentType(property.defaultContentType),
			streamName
		};
	}

	return cacheItem;
}

function getContentType(defaultContentType) {
	return defaultContentType === 'application/rtf' ? htmlContentType : defaultContentType;
}

function getDefaultValue(contentType) {
	return contentType === htmlContentType ? '' : null;
}

function getUri(entity, streamName) {
	return entity.entityAspect.getUri() + '/' + streamName;
}

async function getValueAsync(entity, streamName, contentType) {
	const rights = entitySetRightsProvider.get(entity.entityType);
	if (!rights.canReadProperty(entity, streamName)) {
		throw new errors.PropertyRestrictionError(`User does not have read rights for ${entity.entityType.interfaceName}.${streamName}.`);
	}

	const settings = { url: getUri(entity, streamName) };
	const useByteArray = contentType !== 'text/html';
	if (useByteArray) {
		/*! SuppressStringValidation Not a caption. */
		settings.dataType = 'arraybuffer';
	}
	else {
		settings.headers = { Accept: contentType };
	}

	let value;
	try {
		value = await ajaxService.ajaxAsync(settings);
	} catch (error) {
		if (error instanceof ajaxService.AjaxError && error.status === 403) {
			throw new errors.PropertyRestrictionError(
				`Could not fetch resource stream ${entity.entityType.interfaceName}.${streamName}.`,
				error
			);
		}
		throw error;
	}

	return useByteArray ? new Uint8Array(value) : value;
}

export default ResourceStreams;
