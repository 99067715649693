import sharedRoutes from 'Shared/Routes';

export default function (registry) {
	sharedRoutes(registry);

	// Data automation
	registry.get('#/dataAutomation/importPage', { controller: 'DataAutomation', action: 'showImportPageAsync' });
	registry.get('#/dataAutomation/importPage/:contextType', { controller: 'DataAutomation', action: 'showImportPageAsync' });
	registry.get('#/dataAutomation/importPage/:contextType/:fkToParent/:parentPK<guid>', { controller: 'DataAutomation', action: 'showImportPageAsync' });
	registry.get('#/dataAutomation/importMappingPage/:contextType/:mappingPK<guid>', { controller: 'DataAutomation', action: 'showImportMappingPageAsync' });
	registry.get('#/dataAutomation/importMappingPage/:contextType', { controller: 'DataAutomation', action: 'showImportMappingPageAsync' });
}
