import type { Disposable } from "using-statement";

export { using } from "using-statement";
export type { Disposable };

export function createDisposable(dispose: () => void): Disposable {
  let disposed = false;
  return {
    dispose(): void {
      if (!disposed) {
        disposed = true;
        dispose();
      }
    },
  };
}
