import AsyncLock from 'AsyncLock';
import Storage from 'Storage';
import userSession from 'UserSession';
import breeze from 'breeze-client';
import ko from 'knockout';

const EntityName = 'ISettingInfo';

// Trying to use SettingService before login can result in BreezeQueryError (wrapping RenewSessionError) being thrown.
class SettingService {
	constructor() {
		/*! SuppressStringValidation local storage namespace */
		this._cache = Storage.namespace('Settings');
		this._observables = {};
		this._saveLock = new AsyncLock();
	}

	getObservableSetting(key) {
		let observable = this._observables[key];
		if (!observable) {
			observable = ko.observable();
			this._observables[key] = observable;

			const cache = this._cache;
			const cacheChangedHandler = function (args) {
				observable(args.newValue);
			};
			cache.off(key).on(key, cacheChangedHandler);

			if (cache.has(key)) {
				observable(cache.get(key));
			} else {
				this.getSettingAsync(key);
			}
		}

		return observable;
	}

	async getSettingAsync(key) {
		const cache = this._cache;
		if (cache.has(key)) {
			return cache.get(key);
		}

		const entityManager = await breeze.EntityManager.forEntityTypeAsync(EntityName);
		const setting = await getSettingEntityAsync(entityManager, key);
		const result = setting ? setting.Data() : null;
		this._storeValue(key, result);
		return result;
	}

	async saveSettingAsync(key, value) {
		if (this._cache.get(key) === value) {
			return;
		}

		this._storeValue(key, value);

		const entityManager = await breeze.EntityManager.forEntityTypeAsync(EntityName);
		return this._saveLock.doAsync(async () => {
			if (this._cache.get(key) !== value) {
				return;
			}

			try {
				let setting = await getSettingEntityAsync(entityManager, key);
				if (!setting) {
					setting = await entityManager
						.createEntityWithKeyAsync(EntityName)
						.setObservables({
							Key: key,
							OwnerPK: userSession.sessionData().userPK,
						});
				}
				setting.Data(value);
				// saveChanges is called directly instead of using EntitySaveService to avoid circular dependencies
				await entityManager.saveChanges();
			} catch (e) {
				// ignore errors
			}
		});
	}

	_storeValue(key, value) {
		this._cache.set(key, value);
		const observable = this._observables[key];
		if (observable) {
			observable(value);
		}
	}
}

async function getSettingEntityAsync(entityManager, key) {
	/*! StartNoStringValidationRegion No captions here */
	const query = new breeze.EntityQuery('Settings')
		.where('Key', '==', key)
		.where('OwnerPK', '==', userSession.sessionData().userPK);
	/*! EndNoStringValidationRegion */

	const data = await entityManager.executeQuery(query);
	return data.results[0];
}

export default new SettingService();
