import captionService from "CaptionService";
import { getInterfaceName, getPrimaryKey, isComplexType } from "EntityExtensions";
import { fetchEntitiesByKeyAsync } from "EntityManagerExtensions";
import { getEntityTypeNameFromExtendedTypeName } from "EntityTypeUtils";
import { FormFlowError, FormFlowErrorType } from "FormFlowError";
import type FormFlowSession from "FormFlowSession";
import type { FormFlowVariable } from "FormFlowTypes";
import { getRouteNameForMetadataStore } from "MetadataStoreExtensions";
import type { Entity, EntityManager, MetadataStore } from "breeze-client";

export type FormFlowVariableStrategy<T = unknown> = (
  session: FormFlowSession,
  variable?: FormFlowVariable
) => Promise<T>;

export type EntityCollection<T extends Entity> = {
  entityTypeName?: string;
  metadataStore?: MetadataStore;
} & T[];

/**
 * Returning null as a default value for all types
 * This can be made more specific for certain types in the future
 * e.g. empty array, 0 etc...
 *
 * @returns null
 */
export function makeDefaultValueStrategy(): () => Promise<null> {
  return async () => /*session, variable*/ {
    return await null;
  };
}

/**
 * Returns constant strategy for type T
 *
 * @param value constant of type T
 * @returns Formflow strategy of type T
 */
export function makeConstantStrategy<T>(value: T): () => Promise<T> {
  return async () => /*session, variable*/ {
    return await value;
  };
}

/**
 * Returns entity strategy for entity of type T
 *
 * @param entityTypeName entity type name string
 * @param entityKey entity key string
 * @param optionalModelName Optional property model name
 * @returns Formflow strategy of type T or null
 */
export function makeEntityStrategy<T extends Entity>(
  entityTypeName: string,
  entityKey: string,
  optionalModelName?: string
): FormFlowVariableStrategy<T | null> {
  return async (session: FormFlowSession) => {
    const entityManager = await session.getEntityManagerAsync(entityTypeName, optionalModelName);
    if (!optionalModelName) {
      optionalModelName = entityManager?.metadataStore.getRouteName();
    }
    return await fetchEntityAsync(entityTypeName, entityKey, entityManager);
  };
}

/**
 * Returns entity collection strategy for entity of type T
 *
 * @param entityTypeName entity type name string
 * @param entityKeys entity key array of strings
 * @param optionalModelName Optional property model name
 * @returns Formflow strategy of EntityCollection for entitys of type T
 */
export function makeEntityCollectionStrategy<T extends Entity>(
  entityTypeName: string,
  entityKeys: string[],
  optionalModelName?: string
): FormFlowVariableStrategy<EntityCollection<T>> {
  return async (session: FormFlowSession) /*, variable*/ => {
    const entityManager = await session.getEntityManagerAsync(entityTypeName, optionalModelName);
    if (!optionalModelName) {
      optionalModelName = entityManager?.metadataStore.getRouteName();
    }

    const entities = (await Promise.all(
      entityKeys.map((entityKey) => fetchEntityAsync(entityTypeName, entityKey, entityManager))
    )) as EntityCollection<T>;

    const metadataStore = entityManager?.metadataStore;

    entities.entityTypeName = entityTypeName;
    entities.metadataStore = metadataStore;
    return entities;
  };
}

/**
 * Returns strategy for value unknown
 *
 * @param value property unknown
 * @returns Formflow strategy of type T
 */
export function makeStrategy<T>(value: unknown): FormFlowVariableStrategy<T> {
  let entityTypeName = "";
  let entityKey: string;
  if (Array.isArray(value)) {
    const entityKeys: string[] = [];
    value.forEach((x) => {
      if (x && x.entityAspect) {
        if (!entityTypeName) {
          entityTypeName = x.entityType.interfaceName;
        } else if (entityTypeName !== x.entityType.interfaceName) {
          throw new Error("Collection must not contain entities with different data types.");
        }
        entityKey = getPrimaryKey(x);
        entityKeys.push(entityKey);
      }
    });
    if (entityTypeName) {
      return makeEntityCollectionStrategy(entityTypeName, entityKeys) as FormFlowVariableStrategy<T>;
    }

    return makeConstantStrategy(value.slice(0) as T);
  }

  if (isEntity(value)) {
    entityTypeName = getInterfaceName(value);
    if (value.entityAspect.entityState.isDetached()) {
      throw new Error(`Cannot create variable strategy for a detached entity of type ${entityTypeName}.`);
    }
    entityKey = getPrimaryKey(value);
    const optionalModelName = getRouteNameForMetadataStore(value.entityAspect.entityManager.metadataStore);
    return makeEntityStrategy(entityTypeName, entityKey, optionalModelName) as FormFlowVariableStrategy<T>;
  } else if (isUntypedEntity(value)) {
    return makeUntypedEntityStrategy(value.PK) as FormFlowVariableStrategy<T>;
  }

  return makeConstantStrategy(value as T);
}

/**
 * Returns form flow strategy for entity key
 *
 * @param entityKeys entity key array of strings
 * @returns Formflow strategy of type T or null
 */
export function makeUntypedEntityStrategy<T extends Entity>(entityKey: string): FormFlowVariableStrategy<T | null> {
  return async (session: FormFlowSession, variable?: FormFlowVariable) => {
    const entityTypeName = getEntityTypeName(variable);
    const entityManager = await getEntityMangerAsync(session, entityTypeName);
    return fetchEntityAsync(entityTypeName, entityKey, entityManager);
  };
}

/**
 * Returns form flow strategy for entity key
 *
 * @param entityKeys entity key array of strings
 * @returns Formflow strategy of entity collection of type T
 */
export function makeUntypedEntityCollectionStrategy<T extends Entity>(
  entityKeys: string[]
): FormFlowVariableStrategy<EntityCollection<T>> {
  return async (session: FormFlowSession, variable?: FormFlowVariable) => {
    const entityTypeName = getEntityTypeName(variable);
    const entityManager = await getEntityMangerAsync(session, entityTypeName);
    return fetchEntitiesAsync(entityManager, entityTypeName, entityKeys);
  };
}

function getEntityTypeName(variable?: FormFlowVariable): string {
  const entityTypeName = getEntityTypeNameFromExtendedTypeName(variable?.VariableTypeName ?? "");
  if (!entityTypeName) {
    throwFromUntypedStrategy(`Expected valid variable type name, but was ${variable?.VariableTypeName}.`);
  }

  return entityTypeName;
}

async function getEntityMangerAsync(session: FormFlowSession, entityTypeName: string): Promise<EntityManager> {
  const entityManager = await session.getEntityManagerAsync(entityTypeName, "", /*okIfNotFound*/ true);

  if (!entityManager) {
    throwFromUntypedStrategy(`Expected valid entity type name, but was ${entityTypeName}.`);
  }

  return entityManager;
}

function throwFromUntypedStrategy(message: string): never {
  throw new FormFlowError(message, {
    type: FormFlowErrorType.NonReportableRuntimeError,
    friendlyMessage: captionService.getString(
      "c48f6c58-f3ec-4b77-8f97-05ec9c81c8c7",
      "An error occurred while running this form-flow. This may be due to an invalid URI."
    ),
  });
}

async function fetchEntityAsync<T extends Entity>(
  entityTypeName: string,
  entityKey: string,
  entityManager?: EntityManager
): Promise<T | null> {
  if (entityManager) {
    const entityByKeyResult = await entityManager.fetchEntityByKey(
      entityTypeName,
      entityKey,
      /*checkLocalCacheFirst*/ true
    );
    if (entityByKeyResult.entity) {
      return entityByKeyResult.entity as T;
    }
  }
  return null;
}

async function fetchEntitiesAsync<T extends Entity>(
  entityManager: EntityManager,
  entityTypeName: string,
  entityKeys: string[]
): Promise<EntityCollection<T>> {
  const entityType = entityManager.metadataStore.getEntityType(entityTypeName);
  if (isComplexType(entityType)) {
    throw new Error("Cannot fetch entities for a complex type.");
  }
  let entities: EntityCollection<T> = [];
  const notCachedPKs: string[] = [];
  entityKeys.forEach((entityKey: string) => {
    const entity = entityManager.getEntityByKey(entityTypeName, entityKey) as T;
    if (entity) {
      entities.push(entity);
    } else {
      notCachedPKs.push(entityKey);
    }
  });
  if (notCachedPKs.length > 0) {
    const data = (await fetchEntitiesByKeyAsync(entityManager, entityType, notCachedPKs)) as EntityCollection<T>;
    entities = entities.concat(data);
    if (entities.length < entityKeys.length) {
      entities = entities.concat(new Array(entityKeys.length - entities.length).fill(null));
    }
  }
  entities.entityTypeName = entityTypeName;
  return entities;
}

function isEntity(input: unknown): input is Entity {
  return !!input && typeof input === "object" && "entityAspect" in input;
}

function isUntypedEntity(input: unknown): input is Entity & { PK: string } {
  return !!input && typeof input === "object" && "entityType" in input && "PK" in input && !!input.PK;
}

/** @deprecated Use named exports instead */
export default {
  makeDefaultValueStrategy,
  makeConstantStrategy,
  makeEntityStrategy,
  makeEntityCollectionStrategy,
  makeStrategy,
  makeUntypedEntityStrategy,
  makeUntypedEntityCollectionStrategy,
};
