import ko from 'knockout';
import RuleExpressionCondition from 'RuleExpressionCondition';
import constants from 'Constants';
import entitySetRightsProvider from 'EntitySetRightsProvider';

function ReadOnlyEvaluator() {
}

ReadOnlyEvaluator.prototype.isReadOnly = (bindingContext, dataItem, value, propertyName, allowUpdateOnReadonlyParent = false) => {
	dataItem = ko.unwrap(dataItem);
	if (value === true) {
		return true;
	}
	else if (!dataItem) {
		return true;
	}
	else if (dataItem.entityAspect && checkDataItemAndProperty(dataItem, propertyName, allowUpdateOnReadonlyParent)) {
		return true;
	}
	else if (!propertyIsAvailable(dataItem, propertyName)) {
		return true;
	}
	else if (value === false) {
		return false;
	}
	else if (value && (value.Condition || value.ColumnCondition)) {
		if (value.Condition) {
			const condition = new RuleExpressionCondition(value.Condition);
			if (condition.evaluate(dataItem).value !== false)
			{
				return true;
			}
		}

		if (value.ColumnCondition) {
			const columnCondition = new RuleExpressionCondition(value.ColumnCondition);
			const formEntity = ko.unwrap(bindingContext.$contentViewModel?.getEntity() || {});
			if (columnCondition.evaluate(formEntity).value !== false)
			{
				return true;
			}
		}

		return false;
	}
	else {
		return true;
	}
};

ReadOnlyEvaluator.prototype.isReadOnlyAsync = async (bindingContext, dataItem, value, propertyName, allowUpdateOnReadonlyParent = false) => {
	dataItem = ko.unwrap(dataItem);
	if (value === true) {
		return true;
	}
	else if (!dataItem) {
		return true;
	}
	else if (dataItem.entityAspect && await checkDataItemAndPropertyAsync(dataItem, propertyName, allowUpdateOnReadonlyParent)) {
		return true;
	}
	else if (!propertyIsAvailable(dataItem, propertyName)) {
		return true;
	}
	else if (value === false) {
		return false;
	}
	else if (value && (value.Condition || value.ColumnCondition)) {
		if (value.Condition) {
			const condition = new RuleExpressionCondition(value.Condition);
			if (await condition.evaluateAsync(dataItem) !== false)
			{
				return true;
			}
		}

		if (value.ColumnCondition) {
			const columnCondition = new RuleExpressionCondition(value.ColumnCondition);
			const formEntity = ko.unwrap(bindingContext.$contentViewModel?.getEntity() || {});
			if (await columnCondition.evaluateAsync(formEntity) !== false)
			{
				return true;
			}
		}

		return false;
	}
	else {
		return true;
	}
};

function propertyIsAvailable(dataItem, propertyName) {
	const property = propertyName && dataItem[propertyName];
	if (!property) {
		return true;
	}
	else if (property.observeState) {
		return property.observeState() !== constants.States.NotAvailable;
	}
	else if (property.getState) {
		return property.getState() !== constants.States.NotAvailable;
	}
	else {
		return true;
	}
}

function checkDataItemAndProperty(dataItem, propertyName, allowUpdateOnReadonlyParent) {
	if (allowUpdateOnReadonlyParent) {
		return propertyIsSecured(dataItem, propertyName);
	}

	return dataItemIsReadOnly(dataItem) || propertyIsSecured(dataItem, propertyName);
}

async function checkDataItemAndPropertyAsync(dataItem, propertyName, allowUpdateOnReadonlyParent) {
	if (allowUpdateOnReadonlyParent) {
		return propertyIsSecured(dataItem, propertyName);
	}

	return (await dataItemIsReadOnlyAsync(dataItem)) || propertyIsSecured(dataItem, propertyName);
}

function propertyIsSecured(dataItem, propertyName) {
	const rights = entitySetRightsProvider.get(dataItem.entityType, false);
	const result = rights && !rights.canWriteProperty(dataItem, propertyName);
	return result;
}

function dataItemIsReadOnly(dataItem) {
	return ko.unwrap(dataItem.entityAspect.isReadOnly) !== false;
}

async function dataItemIsReadOnlyAsync(dataItem) {
	const result = await dataItem.entityAspect.isReadOnly.loadAsync();
	return result !== false;
}

export default new ReadOnlyEvaluator();
