import 'bootstrapBase';
import $ from 'jquery';

$.fn.modal.Constructor.prototype.enforceFocus = function ()
{
	$(document)
		.off('focusin.bs.modal') // guard against infinite focus loop
		.on('focusin.bs.modal', (e) =>
		{
			if (this.$element[0] !== e.target && !this.$element.has(e.target).length && $(e.target).parentsUntil('*[role="dialog"]').length === 0)
			{
				this.$element.trigger('focus');
			}
		});
};
