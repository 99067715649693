import captionService from 'CaptionService';

export const fileAttachment = {
	add: () => captionService.getString('e83c5f50-3477-46cb-9e63-525cb1cc59e6', 'Add'),
	clearAll: () => captionService.getString('ed6fc10d-0f07-45d6-943d-8e47868adb88', 'Clear All'),
	clearUnsaved: () => captionService.getString('cf60de14-f667-49a9-aeb2-d00b3a092339', 'Clear Unsaved'),
	dropFile: () => captionService.getString('ef9bce2f-fea7-4f3e-ac92-52bdd969b5e7', 'Drop your file here or use the Add button'),
	dropFiles: () => captionService.getString('864595a3-1004-45b1-9ce8-70798632543d', 'Drop your files here or use the Add button'),
	noFiles: () => captionService.getString('774211ac-66b6-4ce6-8804-4fd6e5c9be25', 'No Files'),
	required: () => captionService.getString('4ec27e1f-fae4-416a-8e4c-bd8e1ec16fdb', 'Must be selected'),
	fileName: () => captionService.getString('c507cc96-ea52-4f7c-821b-92a1a9b1d394', 'File Name'),
	description: () => captionService.getString('04cf5374-9e3f-4f3d-bdb8-6f8be0f3e3ad', 'Description'),
};
