const problemDetailsBaseUri = 'https://glow.wisetechglobal.com/';

export const ApplicationProblemType = {
	DatabaseMismatchError: `${problemDetailsBaseUri}proxy/database-mismatch`
};

export const DataImportProblemType = {
	BadData: `${problemDetailsBaseUri}data-import/bad-data`,
	FileIsEmpty: `${problemDetailsBaseUri}data-import/file-is-empty`,
	FileIsEncrypted: `${problemDetailsBaseUri}data-import/file-is-encrypted`,
	FileIsInvalidExcel: `${problemDetailsBaseUri}data-import/file-is-invalid-excel`,
	FileHasInvalidExcelSheet: `${problemDetailsBaseUri}data-import/file-has-invalid-excel-sheet`,
	FileTooOld: `${problemDetailsBaseUri}data-import/file-too-old`,
	FileTypeNotSupported: `${problemDetailsBaseUri}data-import/file-type-not-supported`,
	InvalidMappingTargetColumn: `${problemDetailsBaseUri}data-import/invalid-mapping-target-column`,
	MissingContentError: `${problemDetailsBaseUri}data-import/missing-content-error`,
	MissingSeparatorError: `${problemDetailsBaseUri}data-import/missing-separator-error`,
	StrictOOXMLExcelFileNotSupported: `${problemDetailsBaseUri}data-import/strict-ooxml-excel-file-not-supported`,
};

export const ResetUserPasswordProblemType =
{
	MissingUser: `${problemDetailsBaseUri}password-reset/missing-user`,
};
