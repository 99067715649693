import ajaxService, { AjaxError } from "AjaxService";
import type * as AvailableColumnsStrategyProvider from "AvailableColumnsStrategyProvider";
import { ScriptLoadError, ScriptLoadErrorType, UnknownModuleError, isErrorWithRequestUri, isModuleNotFoundError } from "Errors";
import type { FilterFactory } from "FilterFactory";
import { loadAsync } from "RulesetLoader";
import type { ExtenderFunction } from "VueComponents/GlowComponent";
import type { EntityFilterStrategyFactory } from "entity-filter-strategy/EntityFilterStrategy";
import type { Component } from "vue";
import type { RulesetMetadata } from "./Rules/RulesetMetadata";

export interface ErrorWithRequestUri extends Error {
  request: string;
  type: ScriptLoadErrorType;
}

export function tryGetBusinessRule(resourceName: string): Record<string, unknown> | undefined {
  return tryGetModuleValue(require.cache[require.resolveWeak(`BusinessRules/${resourceName}`)]);
}

export function tryGetVueComponent(componentName: string): Component | undefined {
  // A compiled Vue Component requires us to explicitly look for the default export because of the way the vue-loader creates its modules.
  return tryGetModuleValue(require.cache[require.resolveWeak(`VueComponents/${componentName}.vue`)]);
}

export function tryGetVueExtender(extenderName: string): ExtenderFunction | undefined {
  const context = require.context("VueExtenders", false, /.+Extender\.*$/, "weak");
  return tryGetModuleValue(require.cache[context.resolve(`./${extenderName}`)]);
}

export function tryGetWidget(widgetName: string): unknown | undefined {
  const context = require.context("Widgets", false, /gw.*\.*$/, "weak");
  return tryGetModuleValue(require.cache[context.resolve(`./${widgetName}`)]);
}

export function loadActivityInvokerAsync(kind: string): Promise<unknown> {
  return wrapAsync(() => import(`FormFlowActivityInvokers/${kind}`));
}

export function loadBusinessRuleAsync(resourceName: string): Promise<Record<string, unknown>> {
  return wrapAsync(() => import(`BusinessRules/${resourceName}`));
}

export function loadBusinessViewModelAsync(viewModelName: string): Promise<unknown> {
  return wrapAsync(() => import(`BusinessViewModels/${viewModelName}`));
}

export function loadControllerAsync(controllerName: string): Promise<unknown> {
  return wrapAsync(() => import(`Controllers/${controllerName}Controller`));
}

export function loadEntityCustomFilterStrategyFactoriesAsync(): Promise<EntityFilterStrategyFactory[]> {
  return wrapAsync(() => import("EntityCustomFilterStrategyFactoriesLoader!"));
}

export function loadFilterFactoryAsync(factoryName: string): Promise<FilterFactory> {
  return wrapAsync(() => import(`Filters/${factoryName}FilterFactory`));
}

export function loadMarkerWithLabelAsync(): Promise<unknown> {
  return wrapAsync(() => import("@google/markerwithlabel"));
}

export function loadMomentTimeZonesAsync(): Promise<unknown> {
  return wrapAsync(() => import("moment-timezone"));
}

export function loadNativeBridgeAsync(providerName: string): Promise<unknown> {
  return wrapAsync(() => import(/* webpackInclude: /.+Provider\.[j|t]s$/ */ `Shared/NativeBridge/${providerName}`));
}

export function loadPreviewModeErrorHandlerAsync(): Promise<unknown> {
  return wrapAsync(() => import(/* webpackChunkName: "PreviewModeErrorHandler" */ "PreviewModeErrorHandler"));
}

export function loadRulesetAsync(routeName: string): Promise<RulesetMetadata[]> {
  return wrapAsync(() => loadAsync(ajaxService, routeName));
}

export function loadTemplateAsync(templateName: string): Promise<string> {
  return wrapAsync(() => import(`WidgetTemplates/${templateName}`));
}

export function loadVueComponentAsync(componentName: string): Promise<unknown> {
  return wrapAsync(() => import(`VueComponents/${componentName}.vue`));
}

export function loadVueExtenderAsync(extenderName: string): Promise<unknown> {
  return wrapAsync(() => import(/* webpackInclude: /.+Extender\.[j|t]s$/ */ `VueExtenders/${extenderName}`));
}

export function loadWidgetAsync(widgetName: string): Promise<unknown> {
  return wrapAsync(
    () =>
      import(
        /* webpackInclude: /gw.*\.[j|t]s$/ */
        /* webpackExclude: /__tests__/ */
        `Widgets/${widgetName}`
      ),
  );
}

export function loadWorkflowAuditLogsViewModelAsync(): Promise<unknown> {
  return wrapAsync(() => import(/* webpackChunkName: "WorkflowAuditLogsViewModel" */ "WorkflowAuditLogsViewModel"));
}

export function loadWorkflowEventsViewModelAsync(): Promise<unknown> {
  return wrapAsync(() => import(/* webpackChunkName: "WorkflowEventsViewModel" */ "WorkflowEventsViewModel"));
}

export function loadWorkflowExceptionsViewModelAsync(): Promise<unknown> {
  return wrapAsync(() => import(/* webpackChunkName: "WorkflowExceptionsViewModel" */ "WorkflowExceptionsViewModel"));
}

export function loadWorkflowMilestonesViewModelAsync(): Promise<unknown> {
  return wrapAsync(() => import(/* webpackChunkName: "WorkflowMilestonesViewModel" */ "WorkflowMilestonesViewModel"));
}

export function loadWorkflowTasksViewModelAsync(): Promise<unknown> {
  return wrapAsync(() => import(/* webpackChunkName: "WorkflowTasksViewModel" */ "WorkflowTasksViewModel"));
}

export function loadWorkflowTriggersViewModelAsync(): Promise<unknown> {
  return wrapAsync(() => import(/* webpackChunkName: "WorkflowTriggersViewModel" */ "WorkflowTriggersViewModel"));
}

export function loadAvailableColumnsStrategyProviderAsync(): Promise<typeof AvailableColumnsStrategyProvider> {
  return wrapAsync(() => import(/* */ "AvailableColumnsStrategyProvider"));
}

async function wrapAsync<T>(importer: () => Promise<unknown>, retries: number = 2): Promise<T> {
  try {
    const imported = await importer();
    return getModuleValue(imported);
  } catch (error) {
    if (isModuleNotFoundError(error)) {
      throw new UnknownModuleError(error.message, error);
    }

    if (!isErrorWithRequestUri(error)) {
      throw error;
    }

    const requestError = error as ErrorWithRequestUri;
    const url = requestError.request;
    if (requestError.type !== ScriptLoadErrorType.Error || retries === 0) {
      throw new ScriptLoadError(requestError.type, url, undefined, requestError);
    } else {
      try {
        await ajaxService.ajaxAsync({ url, dataType: "text", headers: { "cache-control": "no-cache" } });
      } catch (internalError) {
        if (internalError instanceof AjaxError) {
          throw new ScriptLoadError(requestError.type, url, internalError.status, requestError);
        }
        throw internalError;
      }
      const result = await wrapAsync(importer, --retries);
      return result as T;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function tryGetModuleValue<T>(module: any): T {
  return module && getModuleValue(module.exports);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getModuleValue<T>(exports: any): T {
  return exports.default || exports;
}
