import { getDependencyValue, getDependencyValueAsync } from "Dependency2";
import type { DependencyResult } from "DependencyResult";

class RuleExpressionCondition {
  private conditionExpression: string;

  constructor(conditionExpression: string) {
    this.conditionExpression = conditionExpression;
  }

  evaluate(entity: unknown): DependencyResult<boolean | null> {
    return getDependencyValue(entity, this.conditionExpression);
  }

  async evaluateAsync(entity: unknown): Promise<boolean | null> {
    const result = await getDependencyValueAsync<boolean | null>(entity, this.conditionExpression);
    return result.value;
  }
}

export default RuleExpressionCondition;
