export type Delayer = (callback: () => void, ms: number) => void;

let delayer: Delayer | undefined;

export function delayAsync(ms: number): Promise<void> {
  return new Promise((resolve) => (delayer ?? setTimeout)(resolve, ms));
}

export function setDelayer(value: Delayer | undefined): void {
  delayer = value;
}
