import configurationService from 'ConfigurationService';
import Constants from 'Constants';
import * as stringUtils from 'StringUtils';
import userSession from 'UserSession';
import Promise from 'bluebird';
import breeze from 'breeze-client';
import _ from 'underscore';

export default class ItemsConfigurator {
	constructor(options) {
		this._configurationKey = options.configurationKey || '';
		this._configurationParentID = options.configurationParentID || '';
		this._isInConfigurationMode = options.isInConfigurationMode || false;
		this._clientPK = options.clientPK || '';
	}

	getEntityPredicateAsync(provider) {
		if (!userSession.isLoggedOn() || !provider) {
			return Promise.resolve(null);
		}

		return Promise.resolve(this.getEntityPredicateFactoryAsync()).then(
			(predicateFactoryAsync) => {
				return Promise.resolve(
					predicateFactoryAsync ? predicateFactoryAsync(provider) : null
				).then((predicate) => {
					return predicate;
				});
			}
		);
	}

	getEntityPredicateFactoryAsync() {
		if (!userSession.isLoggedOn()) {
			return Promise.resolve(null);
		}

		return this._getExcludedValuesAsync.call(this).then((excludedValues) => {
			return (provider) => {
				if (provider && excludedValues && excludedValues.length > 0) {
					return provider.getKeyPropertyNameAsync().then((keyPropertyName) => {
						const predicates = excludedValues.map((excludedValue) => {
							return breeze.Predicate.create(
								keyPropertyName,
								breeze.FilterQueryOp.NotEquals,
								excludedValue
							);
						});

						return breeze.Predicate.and(predicates);
					});
				}

				return Promise.resolve(null);
			};
		});
	}

	getConfigureLookupAsync(items) {
		if (!userSession.isLoggedOn()) {
			return Promise.resolve(items);
		}

		return this._getExcludedValuesAsync.call(this).then((excludedCodes) => {
			if (excludedCodes && excludedCodes.length > 0) {
				return _.filter(items, (item) => {
					return excludedCodes.indexOf(item.code) === -1;
				});
			}

			return items;
		});
	}

	_getExcludedValuesAsync() {
		if (stringUtils.isNullOrEmpty(this._configurationKey)) {
			return Promise.resolve([]);
		}

		let promise;
		if (this._isInConfigurationMode) {
			promise = this._getExcludedValuesCoreAsync.call(this);
		} else {
			if (!this._excludedValuesPromiseCache) {
				this._excludedValuesPromiseCache = Promise.cache(
					this._getExcludedValuesCoreAsync.bind(this)
				);
			}
			promise = this._excludedValuesPromiseCache();
		}
		return promise;
	}

	_getExcludedValuesCoreAsync() {
		return configurationService.getConfigurationItemDataAsync(
			this._configurationKey,
			Constants.ConfigurationItemType.ExcludedListItems,
			this._configurationParentID,
			null,
			this._clientPK
		);
	}
}
