import * as stringUtils from 'StringUtils';
import widgetService from 'WidgetService';
import constants from 'Constants';
import imageService from 'ImageService';

const widgetName = 'gwShellContainer';
widgetService.register(widgetName, {
	'_create'() {
		const backgroundImagePK = this.element.data('backgroundimagepk');
		const backgroundImageOverride = this.element.data('backgroundImageOverride');
		const backgroundSize = this.element.data('backgroundSize') || '';
		const minWidth = this.element.data('minwidth');
		const minHeight = this.element.data('minheight');

		this.element.attr('data-disable-readonly-decorations', 'selfonly');
		this.element.addClass(widgetName).addClass('g-anchor-container');

		if ((minWidth || minHeight) && !this.element.hasClass('g-relaxed')) {
			this.element.css({
				'min-height': minHeight,
				'min-width': minWidth
			});
		}

		if (this.element.parent().closest('div[data-role="gwShellContainer"]').length === 0) {
			if (backgroundImageOverride) {
				setBackgroundImage(this.element, `data:image/jpeg;base64,${backgroundImageOverride}`, backgroundSize);
			}
			else if (stringUtils.isGuid(backgroundImagePK)) {
				setBackgroundImage(this.element, imageService.getImageUri(backgroundImagePK), backgroundSize);
			}

			if (this.element.data('removePadding') === true) {
				this.element.closest(constants.CssClasses.PageShell.Selector).removeClass('g-page-padding');
			}
		}
	}
});

function setBackgroundImage($element, backgroundUrl, backgroundSize) {
	$element.css({
		'backgroundImage': `url("${backgroundUrl}")`,
		'backgroundSize': (backgroundSize || constants.BackgroundImage.Size.Contain).toLowerCase(),
		'backgroundPosition': constants.BackgroundImage.Position.Center,
		'backgroundRepeat': constants.BackgroundImage.Repeat.None
	});
}
