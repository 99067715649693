import bindingEvaluator from 'BindingEvaluator';
import breeze from 'breeze-client';
import captionService from 'CaptionService';
import dependency from 'Dependency2';
import ko from 'knockout';
import RuleService from 'RuleService';
import Promise from 'bluebird';
import { toArraySafe } from 'StringUtils';

class FilterHelpers {
	findSearchField(searchFields, propertyPath) {
		const re = /\//g;
		const pathToFind = propertyPath.replace(re, '.');
		return searchFields && searchFields.find((s) => !s.isSeparator && s.FieldName.length === pathToFind.length && s.FieldName.replace(re, '.') === pathToFind) || null;
	}

	addSelectFilterToSearchFields(searchFields) {
		return [{ isSeparator: false, caption: captionService.getString('c2b27d12-7a8b-4dc0-abe6-3fd0424f1444', '---- Select filter ----'), isFilterReset: true, FieldName: '' }].concat(searchFields);
	}

	parseFilterSafe(filter) {
		return Array.isArray(filter) ? filter : toArraySafe(filter);
	}

	getExplicitAndImplicitGroups(filterGroups) {
		filterGroups = filterGroups || [];
		const explicitFilters = [];
		const implicitFilters = [];
		ko.unwrap(filterGroups).forEach((x) => {
			if (x.filterStrips().length) {
				if (x.isImplicit) {
					implicitFilters.push(x);
				} else {
					explicitFilters.push(x);
				}
			}
		});

		return {
			explicitFilters,
			implicitFilters
		};
	}

	async getPredicateAsync(explicitFilters, implicitFilters) {
		const predicates = await Promise.all([
			getPredicateCoreAsync(implicitFilters),
			getPredicateCoreAsync(explicitFilters),
		]);

		const joinedPredicates = predicates.map((x) => {
			if (x.length) {
				return breeze.Predicate.or(x);
			}
		});

		return breeze.Predicate.and(joinedPredicates);
	}

	getFilterableSearchFieldsAsync(searchFields) {
		return Promise.filter(searchFields, (searchField) => searchField.isFilterable ? dependency.getValueAsync(null, searchField.isFilterable).get('value') : true);
	}

	mapFilterKey(filterKey, rules, entityType) {
		if (!(entityType instanceof breeze.EntityType)) {
			throw new Error('entityType must be a breeze EntityType.');
		}

		let propertyName = filterKey.propertyName;
		let fieldName = filterKey.propertyName;

		const bindingInfo = bindingEvaluator.getEntityBindingInfo(entityType, fieldName, { validateSeparators: false });

		if (!bindingInfo.isValid) {
			return null;
		}

		const isComplexPath = bindingEvaluator.isComplexPath(fieldName);
		const captionInfo = captionService.getCaptionFromField(bindingInfo);

		let lookupMetadata;
		let dbMappingOverrideMetadata;
		if (isComplexPath) {
			entityType = bindingInfo.entityType;
			const ultimateRules = RuleService.get(entityType.interfaceName);
			propertyName = bindingInfo.propertyName;
			lookupMetadata = getLookupMetadata(ultimateRules, propertyName);
			dbMappingOverrideMetadata = ultimateRules.dbMappingOverride(propertyName);
			fieldName = bindingInfo.ultimateDataItemPath + propertyName;
		}
		else {
			lookupMetadata = getLookupMetadata(rules, fieldName);
			dbMappingOverrideMetadata = rules.dbMappingOverride(fieldName);
		}

		const result = Object.assign({}, filterKey, {
			propertyName,
			FieldName: fieldName,
			entityType,
			isSeparator: false,
			caption: captionInfo.caption || captionInfo,
			lookupMetadata,
			isCharBoolean: rules.charBoolean(fieldName),
		});

		const numericSize = rules.numericSize?.(fieldName);

		if (!entityType.getProperty(fieldName) && numericSize) {
			result.numericSize = numericSize;
		}

		if (dbMappingOverrideMetadata) {
			result.dbMappingOverrideMetadata = dbMappingOverrideMetadata;
		}

		return result;
	}
}

const getPredicateCoreAsync = async (filterGroups) => {
	const orPredicates = [];

	const promises = filterGroups.map(async (filterGroup) => {
		const andPredicates = await filterGroup.getFilterPredicatesAsync();
		if (andPredicates.length) {
			orPredicates.push(breeze.Predicate.and(andPredicates));
		}
	});

	await Promise.all(promises);

	return orPredicates;
};

function getLookupMetadata(rules, fieldName) {
	let metadata = rules.lookupMetadata(fieldName);
	if (metadata) {
		metadata = Object.assign({}, metadata);
		// The below are not supported because they do not work when there's no entity.
		delete metadata.ensurePreconditions;
		delete metadata.filter;
		delete metadata.lookupFunc;
	}

	return metadata;
}

export default new FilterHelpers();
