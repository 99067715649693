import { NotificationType, compareNotificationTypes } from "NotificationType";
import { Notifications, type Alert } from "Notifications";
import { type Entity } from "breeze-client";
import ko, { type Computed, type Observable, type PureComputed } from "knockout";

interface PrivateData {
  entitiesDisposable?: Computed<Entity[]>;
}

class NotificationSummary {
  rootEntity: Entity | Observable<Entity | undefined> | null;
  entities: Observable<Entity[]> | Computed<Entity[]>;
  all: Computed<Alert[]>;
  errors: Computed<Alert[]>;
  infos: Computed<Alert[]>;
  warnings: Computed<Alert[]>;
  hasAlerts: Computed<boolean>;
  level: Computed<NotificationType>;
  private privates: PrivateData;

  constructor(
    rootEntity: Entity | Observable<Entity | undefined> | null,
    entities: Observable<Entity[]> | Computed<Entity[]> | PureComputed<Entity[]>,
  ) {
    this.privates = {};
    this.rootEntity = rootEntity;
    this.entities = entities;
    if (!this.entities && rootEntity) {
      const entitiesDisposable = ko.pureComputed(() => {
        return [ko.unwrap(rootEntity)] as Entity[];
      });
      this.entities = entitiesDisposable;
      this.privates.entitiesDisposable = entitiesDisposable;
    }

    this.all = ko.computed({ owner: this, read: this.getAll, deferEvaluation: true });
    this.errors = ko.computed({ owner: this, read: this.getErrors, deferEvaluation: true });
    this.infos = ko.computed({ owner: this, read: this.getInfos, deferEvaluation: true });
    this.warnings = ko.computed({
      owner: this,
      read: this.getWarnings,
      deferEvaluation: true,
    });
    this.hasAlerts = ko.pureComputed(this.getHasAlerts, this);
    this.level = ko.computed({ owner: this, read: this.getLevel, deferEvaluation: true });
  }

  dispose(): void {
    this.all.dispose();
    this.errors.dispose();
    this.infos.dispose();
    this.warnings.dispose();
    this.hasAlerts.dispose();
    this.level.dispose();
    if (ko.isComputed(this.privates.entitiesDisposable)) {
      this.privates.entitiesDisposable.dispose();
    }
  }

  private getAll(): Alert[] {
    let result: Alert[] = [];
    const entities = this.entities() || [];

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      const notifications = Notifications.get(entity);
      result = result.concat(notifications ? notifications.alerts() : new Notifications().alerts());
    }

    return result;
  }

  private getErrors(): Alert[] {
    return filter(this.all(), NotificationType.Error);
  }

  private getHasAlerts(): boolean {
    return this.all().length > 0;
  }

  private getInfos(): Alert[] {
    return filter(this.all(), NotificationType.Information);
  }

  private getLevel(): NotificationType {
    let result = NotificationType.None;
    const alerts = this.all();

    for (let i = 0; i < alerts.length; i++) {
      const alert = alerts[i];
      if (compareNotificationTypes(alert.Level, result) === 1) {
        result = alert.Level;
      }
    }

    return result;
  }

  private getWarnings(): Alert[] {
    return filter(this.all(), NotificationType.Warning);
  }
}

function filter(alerts: Alert[], notificationType: NotificationType): Alert[] {
  const result: Alert[] = [];

  for (let i = 0; i < alerts.length; i++) {
    const alert = alerts[i];
    if (alert.Level === notificationType) {
      result.push(alert);
    }
  }

  return result;
}

export default NotificationSummary;
