import dependency from 'Dependency2';
import errors from 'Errors';
import RuleExpressionCondition from 'RuleExpressionCondition';
import RuleService from 'RuleService';
import * as stringUtils from 'StringUtils';

class VisibilityEvaluator {
	isVisible(rootDataItem, dataItem, value) {
		return stringUtils.isGuid(value)
			? this.isVisibleFromConditionWithFallback(rootDataItem, dataItem, value)
			: this.isVisibleFromExpression(dataItem, value);
	}

	isVisibleFromConditionWithFallback(rootDataItem, dataItem, visibilityConditionPK) {
		let isVisible = isVisibleFromCondition(rootDataItem, visibilityConditionPK);

		if (isVisible === null) {
			isVisible = isVisibleFromCondition(dataItem, visibilityConditionPK);
		}

		if (isVisible === null) {
			throw new errors.VisibilityEvaluatorException(`Could not find visibility condition with id: "${visibilityConditionPK}" on root or bound entity.`);
		}

		return isVisible;
	}

	isVisibleFromExpression(dataItem, expression) {
		if (dataItem) {
			if (expression && dependency.isValid(expression)) {
				return new RuleExpressionCondition(expression).evaluate(dataItem).value;
			}
			else {
				/*! SuppressStringValidation Developer exception message */
				throw new errors.VisibilityEvaluatorException('Invalid visibility expression or dependency.');
			}
		}

		return false;
	}
}

function isVisibleFromCondition(dataItem, visibilityConditionPK) {
	if (dataItem) {
		if (!stringUtils.isEmptyGuid(visibilityConditionPK)) {
			const entityName = dataItem.entityType ? dataItem.entityType.interfaceName : 'IGlowMacro';
			const rules = RuleService.observe(entityName);

			if (rules) {
				const expression = rules.condition(visibilityConditionPK, true);

				if (expression) {
					const condition = new RuleExpressionCondition(expression).evaluate(dataItem);
					return !!condition.value;
				}
				else {
					return null;
				}
			}
		}
		else {
			/*! SuppressStringValidation Developer exception message */
			throw new errors.VisibilityEvaluatorException('Invalid visibility condition supplied, expecting non-empty guid.');
		}
	}

	return false;
}

export default new VisibilityEvaluator();
