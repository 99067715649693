import Promise from 'bluebird';
import lookupRuleEngine from 'LookupRuleEngine';
import RuleService from 'RuleService';
import { isGuid } from 'StringUtils';

function RuleBasedLookupProvider(entityName, ruleId) {
	this._lookupFuncName = isGuid(ruleId) ? 'lookupRuleById' : 'lookupRule';

	this.entityName = entityName;
	this.ruleId = ruleId;
}

RuleBasedLookupProvider.prototype.isDataServiceQuery = false;

RuleBasedLookupProvider.prototype.getLookupRecordsAsync = function (
	dataItem,
	_decorateQuery,
	searchKeyword
) {
	return getLookupRecordsCoreAsync(this, dataItem).then((items) => {
		if (searchKeyword) {
			const searchKeywordUpper = searchKeyword.toUpperCase();
			items = items.filter((item) => {
				return (
					item.code.toUpperCase().startsWith(searchKeywordUpper) ||
					(item.description &&
						item.description.toUpperCase().startsWith(searchKeywordUpper))
				);
			});
		}

		return { inlineCount: items.length, results: items };
	});
};

RuleBasedLookupProvider.prototype.getRecord = function (dataItem, value) {
	const rule = getRule(this);
	const items =
		rule && lookupRuleEngine.observeValueForRule(dataItem, rule, /*emptyDefaultValue:*/ true);
	return getItem(items, value);
};

function getItem(items, value) {
	return (items && items.find((i) => i.code === value)) || null;
}

RuleBasedLookupProvider.prototype.getRecordAsync = function (dataItem, value) {
	return getLookupRecordsCoreAsync(this, dataItem).then((items) => {
		return getItem(items, value);
	});
};

function getLookupRecordsCoreAsync(self, dataItem) {
	const rule = getRule(self);
	return rule ? lookupRuleEngine.getValueAsync(dataItem, rule) : Promise.resolve([]);
}

function getRule(self) {
	const entityRules = RuleService.get(self.entityName);
	return entityRules[self._lookupFuncName](self.ruleId, /*okIfNotFound:*/ true);
}

export default RuleBasedLookupProvider;
