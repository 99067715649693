import bindingEvaluator from 'BindingEvaluator';
import captionService from 'CaptionService';
import notificationType from 'NotificationType';
import Notifications from 'Notifications';
import breeze from 'breeze-client';
import {
	FilterDisplayMode
} from 'Constants';
import ko from 'knockout';
import _ from 'underscore';

export default class Filter {
	constructor(fieldName, entityType) {
		this.fieldName = fieldName;
		this.entityType = entityType;
		this.values = ko.observableArray();
		this.operatorName = ko.observable();
		this.operator = ko.pureComputed(this._getOperator, this);
		this.notifications = new Notifications();
		this.isValid = ko.pureComputed(() => {
			const isWarningOrLess =
				notificationType.compare(this.notifications.level(), notificationType.Warning) < 1;
			return isWarningOrLess;
		});
		this.value = ko.pureComputed(this._getValue(0), this);
		this.value2 = ko.pureComputed(this._getValue(1), this);

		this.FilterDisplayMode = FilterDisplayMode;
	}

	setValue(index, value) {
		if (!this.values()[index]) {
			this.values()[index] = ko.observable();
			this.values.valueHasMutated();
		}
		this.values()[index](value);
	}

	clear() {
		this.values.removeAll();

		this.operatorName(this.operators[0].name);
		this.notifications.removeAll();
	}

	getOperatorName() {
		const operator = this.operator();
		const values = this.values()
			.map((v) => v())
			.join(', ');
		if (!operator) {
			throw new Error(
				`Missing operator for fieldName: ${
					this.fieldName
				}, operatorName: ${this.operatorName()}, entityTypeName: ${
					this.entityType ? this.entityType.interfaceName : 'undefined'
				}, values: [ ${values} ]`
			);
		}
		return operator.name;
	}

	_getValue(index) {
		return {
			read() {
				return ko.unwrap(this.values()[index]);
			},
			write(value) {
				this.setValue(index, value);
			},
			owner: this,
		};
	}

	_getOperator() {
		const operatorName = this.operatorName();
		if (operatorName && this.operators) {
			const operator = _.find(this.operators, (o) => {
				return o.name.toLowerCase() === operatorName.toLowerCase();
			});
			return operator;
		}
	}

	async getPredicateAsync() {
		const operator = this.operator() || this.operators[0];
		if (operator) {
			if (!operator.getPredicateAsync) {
				throw new Error('Cannot generate breeze predicate for operator ' + operator.name);
			}

			const self = this;
			return await getCollectionPredicateAsync(self.fieldName, (lastSegment) => {
				const thisClone = _.extend({}, self);
				thisClone.fieldName = lastSegment;
				return operator.getPredicateAsync(thisClone);
			});
		}
	}

	get propertyName() {
		return bindingEvaluator.getPropertyName(this.fieldName);
	}

	static get types() {
		/*! StartNoStringValidationRegion Filter type names */
		return {
			string: 'StringFilter',
			boolean: 'BoolFilter',
			dateTime: 'DateTimeFilter',
			dateTimeUtc: 'UtcDateTimeFilter',
			dateTimeOffset: 'DateTimeOffsetFilter',
			time: 'TimeFilter',
			duration: 'DurationFilter',
			numericByte: 'ByteFilter',
			numericInt16: 'Int16Filter',
			numericInt32: 'Int32Filter',
			numericInt64: 'Int64Filter',
			numericDouble: 'DoubleFilter',
			numericDecimal: 'DecimalFilter',
			numericSingle: 'SingleFilter',
			stringLookup: 'AdvancedStringLookupFilter',
			guidLookup: 'AdvancedGuidLookupFilter',
			simpleLookup: 'SimpleLookupFilter',
			collection: 'CollectionFilter',
			distance: 'DistanceMeasureFilter',
			temperature: 'TemperatureMeasureFilter',
			volume: 'VolumeMeasureFilter',
			weight: 'WeightMeasureFilter',
			isActive: 'IsActiveFilter',
			geo: 'GeoFilter',
			entityCustom: 'EntityCustomFilter',
		};
		/*! EndNoStringValidationRegion */
	}

	static get templateTypes() {
		/*! StartNoStringValidationRegion String validation suppressed in initial refactor */
		return {
			string: 'string',
			boolean: 'bool',
			dateTime: 'dateTime',
			time: 'time',
			duration: 'duration',
			numeric: 'numeric',
			lookup: 'lookup',
			addressLookup: 'addressLookup',
			simpleLookup: 'simpleLookup',
			collection: 'collection',
			geo: 'geo',
			entityCustom: 'entityCustom',
		};
		/*! EndNoStringValidationRegion */
	}

	static get fieldGroupDataTypes() {
		/*! StartNoStringValidationRegion String validation suppressed in initial refactor */
		return {
			Lookup: {
				Code: 'Lookup',
				Caption: captionService.getString(
					'c1d5df4c-46d9-40cb-ac6a-ed13150f4fed',
					'Related Records'
				),
				types: [
					Filter.types.stringLookup,
					Filter.types.guidLookup,
					Filter.types.collection,
				],
			},
			Text: {
				Code: 'Text',
				Caption: captionService.getString(
					'f300e1dc-5bf5-4332-af80-6c51cd184b07',
					'Text Search'
				),
				types: [Filter.types.string, Filter.types.simpleLookup],
			},
			Number: {
				Code: 'Number',
				Caption: captionService.getString(
					'd0782b7a-c813-427a-8dbd-d4ac55716a59',
					'Numbers and References'
				),
				types: [
					Filter.types.numericByte,
					Filter.types.numericInt16,
					Filter.types.numericInt32,
					Filter.types.numericInt64,
					Filter.types.numericDouble,
					Filter.types.numericDecimal,
					Filter.types.numericSingle,
				],
			},
			Flag: {
				Code: 'Flag',
				Caption: captionService.getString('c07222ce-2a9b-4356-af7e-0d29d98c2ffc', 'Flags'),
				types: [Filter.types.boolean, Filter.types.isActive],
			},
			Date: {
				Code: 'Date',
				Caption: captionService.getString('66fad073-21fc-426b-9e6a-05a3365e318d', 'Dates'),
				types: [
					Filter.types.dateTime,
					Filter.types.dateTimeUtc,
					Filter.types.dateTimeOffset,
				],
			},
			Time: {
				Code: 'Time',
				Caption: captionService.getString('f7244679-9dfe-4ce7-baa2-c0d0fcdb9cef', 'Times'),
				types: [Filter.types.time],
			},
			Duration: {
				Code: 'Duration',
				Caption: captionService.getString(
					'1d7a908a-42d6-42fb-bd10-3c268d9ff9a8',
					'Durations'
				),
				types: [Filter.types.duration],
			},
			Measure: {
				Code: 'Measures',
				Caption: captionService.getString(
					'7380831a-cc15-46cd-8644-6cb7a94806cc',
					'Measurements'
				),
				types: [
					Filter.types.distance,
					Filter.types.temperature,
					Filter.types.volume,
					Filter.types.weight,
				],
			},
			Geography: {
				Code: 'Geography',
				Caption: captionService.getString(
					'c0176aa2-e163-4251-bc5d-9b586d4a6a4e',
					'Geography'
				),
				types: [Filter.types.geo],
			},
			Other: {
				Code: 'Other',
				Caption: captionService.getString('2f43e71f-9a9d-4345-ba78-8343de9fb783', 'Other'),
				types: [],
			},
		};
		/*! EndNoStringValidationRegion */
	}
}

async function getCollectionPredicateAsync(path, getPredicateOriginalAsync) {
	const segments = path.split('/');

	if (segments.length === 1) {
		return getPredicateOriginalAsync(path);
	}

	const collectionPredicate =
		segments.length === 2
			? await getCollectionPredicateAsync(segments[1], getPredicateOriginalAsync)
			: await getCollectionPredicateAsync(
					path.slice(path.indexOf('/') + 1, path.length),
					getPredicateOriginalAsync
			);

	if (collectionPredicate) {
		/*! SuppressStringValidation (Not a caption) */
		return breeze.Predicate.create(segments[0], 'any', collectionPredicate);
	}
}
