import { trackBusyStateAsync } from 'GlobalBusyStateTracker';
import Promise from 'bluebird';
import captionService from 'CaptionService';
import configurationService from 'ConfigurationService';
import Constants from 'Constants';
import dialogService from 'DialogService';
import logonFormRetriever from 'LogonFormRetriever';
import navigationService from 'NavigationService';
import notificationType from 'NotificationType';
import { joinUri } from 'UriUtils';

class ConfigurationHelper {
	showConfigureTheme() {
		navigationService.post('#/configuration/showConfigureTheme');
	}

	showConfigureAnonymousTrackingFields() {
		navigationService.post('#/configuration/showConfigureAnonymousTrackingFields');
	}

	showMasterListPages() {
		navigationService.changePage('#/configuration/masterList/pages');
	}

	showMasterListFormFlows() {
		navigationService.changePage('#/configuration/masterList/formFlows');
	}

	showConfigurationList(parentID, parentTableCode, isClientOptionDisabled, onConfigureCallback) {
		navigationService.post('#/configuration/showConfigurationList', {
			parentID,
			parentTableCode,
			isClientOptionDisabled,
			onConfigureCallback
		});
	}

	startConfiguringAsync(parentID, parentTableCode) {
		const promise = configurationService.getDefaultTemplateAsync(parentID, parentTableCode).tap((configurationTmpl) => {
			this.goToConfiguration(configurationTmpl);
		});

		return trackBusyStateAsync(promise);
	}

	goToConfiguration(configurationTmpl) {
		const parentTableCode = configurationTmpl.VCT_ParentTableCode();
		if (parentTableCode === Constants.ConfigurationTmplParentTableCodes.Form) {
			goToPageConfiguration(configurationTmpl);
		}
		else if (parentTableCode === Constants.ConfigurationTmplParentTableCodes.FormFlow) {
			goToFormFlowConfiguration(configurationTmpl);
		}
	}

	startConfiguringLoginPageAsync(userType, formFactor) {
		const promise = logonFormRetriever.getFormIdAsync(userType, formFactor)
			.then((formPK) => configurationService.getDefaultTemplateAsync(formPK, Constants.ConfigurationTmplParentTableCodes.Form))
			.tap(goToPageConfiguration);

		return trackBusyStateAsync(promise);
	}

	async confirmDeleteResetConfigurationAsync(configurationTmpl, itemsPredicate, dialogOptions, isDelete) {
		let dialogInfo = null;
		let willResolve = false;
		const deferred = Promise.deferred();
		const viewModel = {
			onAsyncClick() {
				willResolve = true;

				return configurationService.deleteConfigurationAsync(configurationTmpl, itemsPredicate, isDelete)
					.then(() => {
						deferred.resolve(true);
					})
					.finally(() => {
						dialogService.hide(dialogInfo);
					});
			}
		};

		const noButtonOptions = {
			isNoButton: true,
			isDismiss: true
		};

		const yesButtonOptions = {
			bindingString: 'asyncClick: onAsyncClick',
			isPrimary: true,
			isDefault: true
		};

		const cancelButtonOptions = {
			caption: dialogService.buttonTypes.Cancel().text,
			isDismiss: true
		};

		dialogOptions = Object.assign({}, dialogOptions, {
			notificationType: notificationType.Warning,
			viewModel,
			preHideCallback() {
				if (!willResolve) {
					deferred.resolve(false);
				}
			}
		});

		if (isDelete) {
			noButtonOptions.caption = captionService.getString('d29fbca0-d29a-4dc7-8e24-9a6fea07a14d', 'Don\'t Delete');
			yesButtonOptions.caption = captionService.getString('7c5211b1-b9f4-48e4-87a8-9c0b72677a4e', 'Delete');
		}
		else {
			noButtonOptions.caption = captionService.getString('8ca3fe56-f863-4aa2-9859-e7d4a510ecfc', 'Don\'t Reset');
			yesButtonOptions.caption = captionService.getString('f5fd4caf-0b77-4471-abc2-0194fb107b17', 'Reset');
		}

		dialogOptions.buttonOptions = [noButtonOptions, cancelButtonOptions, yesButtonOptions];

		dialogInfo = await dialogService.showDialogAsync(dialogOptions);
		return deferred.promise();
	}

	getConfigurationContext(bindingContextOrViewModel) {
		const viewModel = bindingContextOrViewModel.pageExtensions ? bindingContextOrViewModel : bindingContextOrViewModel.$root;
		return viewModel
			&& viewModel.pageExtensions
			&& viewModel.pageExtensions.configurationContext;
	}

	setConfigurationContext(pageExtensions, configurationContext) {
		pageExtensions.configurationContext = configurationContext;
	}
}

function goToFormFlowConfiguration(configurationTmpl) {
	/*! SuppressStringValidation Valid path URI */
	goToConfiguration('formFlow', configurationTmpl);
}

function goToPageConfiguration(configurationTmpl) {
	/*! SuppressStringValidation Valid path URI */
	goToConfiguration('page', configurationTmpl);
}

function goToConfiguration(uriPart, configurationTmpl) {
	/*! SuppressStringValidation Valid path URI */
	const pageUri = joinUri('#/configuration', uriPart, configurationTmpl.VCT_PK());
	navigationService.get(pageUri, {
		onCompletedUri: navigationService.getCurrentLocation()
	});
}

export default new ConfigurationHelper();
