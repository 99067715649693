import favoritesService from 'FavoritesService';
import global from 'Global';
import logonService from 'LogonService';
import navigationService from 'NavigationService';
import _ from 'underscore';
import vueFactory from 'VueFactory';
import $ from 'jQueryExtensions';
import constants from 'Constants';

export default function startupBootstrapper() {
	return logonService
		.tryResumeAsync()
		.catch(_.noop)
		.then(() => {
			if (global.materialDesign) {
				const contentContainer = document.getElementById('g-app');
				if (contentContainer) {
					return vueFactory.createVueInstanceAsync({
						contentContainer,
					}).then((instance) => {
						const $modalsContainer = $(instance.$el).find(constants.CssClasses.ModalsContainer.Selector);
						$modalsContainer.data('is-initialized', true);
					});
				}
			}
		})
		.then(() => {
			navigationService.start();
			favoritesService.attachEventHandlers();
		});
}
