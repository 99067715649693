import captionService from 'CaptionService';
import dialogService from 'DialogService';
import materialDesignDialogService from 'MaterialDesignDialogService';
import NotificationType from 'NotificationType';

class AlertDialogService {
	constructor() {
	}

	confirmAsync(message, title, buttonOptions, options) {
		if (materialDesignDialogService.canShowAlertDialog()) {
			return materialDesignDialogService.showAlertDialogAsync(title, message, buttonOptions, options.notificationType);
		}

		return dialogService.confirmAsync(message, title, buttonOptions, options);
	}

	alertAsync(notificationType, message, title) {
		const buttonOptions = [
			{
				caption: captionService.getString('93f85d2e-d180-49b1-8630-15f4d81dd77b', 'OK'),
				result: true,
				isDismiss: true,
				isDefault: true
			}
		];

		if (materialDesignDialogService.canShowAlertDialog()) {
			return materialDesignDialogService.showAlertDialogAsync(title, message, buttonOptions, notificationType);
		}

		return dialogService.alertAsync(notificationType, message, title);
	}

	warnStayLeaveUnsavedChangesAsync() {
		const message = captionService.getString('81583030-e476-48d9-8f37-e199ba234e5b', 'There are unsaved changes. Are you sure you want to leave?');
		const title = captionService.getString('4c72e25d-c0e3-438f-8bde-a3808a38a5b2', 'Warning - Unsaved Changes');
		const notificationType = NotificationType.Warning;
		const buttonOptions = [{
			caption: captionService.getString('fbe123db-9a25-4c7d-9a67-2984af9096a9', 'Stay'),
			result: false,
			isDismiss: true
		}, {
			caption: captionService.getString('73c6a632-f000-416b-8c0d-7dbdc1934031', 'Leave'),
			result: true,
			isDismiss: true,
			isDefault: true
		}];

		if (materialDesignDialogService.canShowAlertDialog()) {
			return materialDesignDialogService.showAlertDialogAsync(title, message, buttonOptions, notificationType);
		}

		return dialogService.confirmAsync(message, title, buttonOptions, notificationType);
	}

	errorWithOKButtonAsync(title = '', message = '') {
		const buttonOptions = [{
			caption: captionService.getString('3f309868-ce53-45fc-9283-e6eb93f444dd', 'OK'),
			result: true,
			isDismiss: true,
			isDefault: true,
		}];
		const notificationType = NotificationType.Error;

		if (materialDesignDialogService.canShowAlertDialog()) {
			return materialDesignDialogService.showAlertDialogAsync(title, message, buttonOptions, notificationType);
		}

		return dialogService.alertAsync(notificationType, message, title, buttonOptions);
	}

}

export default new AlertDialogService();
