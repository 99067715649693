import captionService from 'CaptionService';
import errorHandler from 'ErrorHandler';
import errors from 'Errors';
import global from 'Global';
import log from 'Log';
import notificationType from 'NotificationType';
import toastService from 'ToastService';

export default function serviceWorkerBootstrapper() {
	/*! StartNoStringValidationRegion Error messages */
	const ServiceWorkerErrors = {
		RegistrationFailed: 'Failed to register a ServiceWorker for scope',
		StorageAccessFailed: 'Failed to access storage',
		UnknownError: 'An unknown error occurred when fetching the script',
		ExecutionTimedOut: 'Service Worker script execution timed out',
		LoadFailed: '.js load failed',
		ErrorDuringInstallation: 'encountered an error during installation.',
		EvaluationFailed: 'ServiceWorker script evaluation failed',
	};
	/*! EndNoStringValidationRegion */

	if (global.featureFlags.serviceWorker && navigator.serviceWorker) {
		const scope = `${global.rootPathForModule}${global.formFactorPath}`;
		const awaiter = navigator.serviceWorker
			.register(`${scope}/sw.js`, { scope })
			.then((registration) => {
				log.info(`Service worker registration succeeded. Scope: ${registration.scope}`);
			})
			.catch((error) => {
				const message = `Error while registering service worker. Scope: ${scope}`;
				const errorName = error.name;

				if (error instanceof TypeError) {
					if (error.message.startsWith(ServiceWorkerErrors.RegistrationFailed)) {
						if (error.message.includes(ServiceWorkerErrors.StorageAccessFailed)) {
							throw new errors.LowDiskSpaceError(message, error);
						}
						if (
							error.message.includes(ServiceWorkerErrors.UnknownError) ||
							error.message.endsWith(ServiceWorkerErrors.EvaluationFailed)
						) {
							throw new errors.ServiceWorkerUnknownInstallationError(message, error);
						}
						const badResponseMatch = error.message.match(
							/A bad HTTP response code \((\d+)\) was received when fetching the script\.$/
						);
						if (badResponseMatch) {
							const status = parseInt(badResponseMatch[1], 10);
							throw new errors.ServiceWorkerInstallationBadResponseError(
								message,
								status,
								error
							);
						}
					}
					if (
						error.message.startsWith(ServiceWorkerErrors.ExecutionTimedOut) ||
						error.message.endsWith(ServiceWorkerErrors.LoadFailed) ||
						error.message.endsWith(ServiceWorkerErrors.ErrorDuringInstallation)
					) {
						throw new errors.ServiceWorkerUnknownInstallationError(message, error);
					}
				} else if (error instanceof DOMException && errors.DOMExceptionType[errorName]) {
					throw new errors.ServiceWorkerInstallationError(message, error);
				}

				toastService.showToastAlertAsync(
					captionService.getString(
						'c3299cda-43d1-46b8-abc3-2505c4c1bd13',
						'An error occurred that will prevent offline functionality from working. An automated report has been sent to WiseTech Global.'
					),
					notificationType.Error,
					/*isSticky*/ false,
					{ timeOut: 0 }
				);
				errorHandler.reportError(error, message);

				log.error(`Service worker registration failed. Error: ${error.message}`);
			});

		return { awaiter };
	}
}
