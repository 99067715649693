import entityMappingService from 'EntityMappingService';
import * as stringUtils from 'StringUtils';

const validationCallbacks =
{
	entityType(typeOrInterfaceName) {
		return entityMappingService.hasInterfaceName(typeOrInterfaceName);
	},
	guid: stringUtils.isGuid,
	bool: stringUtils.isBoolean,
	'guid[]': stringUtils.isGuidArray,
	string: stringUtils.isNonEmptyString,
};

function RouteRegistry(app, routeFactory)
{
	this.app = app;
	this.privates = { routeFactory };
}

RouteRegistry.prototype.around = function (routeInfo)
{
	this.app.around(this.privates.routeFactory(routeInfo));
};

RouteRegistry.prototype.before = function (options, routeInfo)
{
	this.app.before(options, this.privates.routeFactory(routeInfo));
};

RouteRegistry.prototype.get = function (path, routeInfo)
{
	/*! SuppressStringValidation String validation suppressed in initial refactor */
	wrap(this, 'get', path, routeInfo);
};

RouteRegistry.prototype.post = function (path, routeInfo)
{
	/*! SuppressStringValidation String validation suppressed in initial refactor */
	wrap(this, 'post', path, routeInfo);
};

// Give a path like '#/summary/:entityPK<guid>/:entityType<entityType>'
// Then add a guid validation for entityPK and entityType validation for entityType
function wrap(registry, verb, path, routeInfo)
{
	if (typeof path === 'string')
	{
		let hasAddedValidation = false;
		const parts = path.split('/');
		parts.forEach((part, index) => {
			if (stringUtils.startsWith(part, ':') && stringUtils.endsWith(part, '>'))
			{
				const startIndex = part.lastIndexOf('<');
				if (startIndex > -1)
				{
					const parameterName = part.substring(1, startIndex);
					const validationName = part.substring(startIndex + 1, part.length - 1);
					const validationCallback = validationCallbacks[validationName];
					parts[index] = ':' + parameterName;

					if (!routeInfo.validation)
					{
						routeInfo.validation = {};
					}

					routeInfo.validation[parameterName] = validationCallback;
					hasAddedValidation = true;
				}
			}
		});

		if (hasAddedValidation)
		{
			path = parts.join('/');
		}
	}

	registry.app[verb](path, registry.privates.routeFactory(routeInfo));
}

export default RouteRegistry;
