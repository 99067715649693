import type { AggregateStrategy } from "./AggregateStrategy.ts";
import type { PersistentStore } from "./PersistentStore.ts";

export class RamAggregateStrategy implements AggregateStrategy {
  async clearStorageAsync(
    storeCache: Map<string, PersistentStore>,
    dbNamePrefix: string
  ): Promise<void> {
    const storesToClear: PersistentStore[] = [];
    storeCache.forEach((value, key) => {
      if (key.startsWith(dbNamePrefix)) {
        storesToClear.push(value);
      }
    });

    await Promise.all(storesToClear.map((store) => store.clearAsync()));
  }
}
