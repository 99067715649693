import type { BindingInfo } from "BindingEvaluator";
import { getInterfaceName } from "EntityExtensions";
import resStringRepository, { type ResStringRepository, type ResourceString } from "ResStringRepository";
import { format, isEmptyGuid, isNonEmptyString } from "StringUtils";

export interface Caption extends ResourceString {
  isFallback?: boolean;
}

class CaptionService {
  reloadStringsAsync(languageCode: string): Promise<void> {
    resStringRepository.clearCache();
    return resStringRepository.loadStringsAsync(languageCode);
  }

  /**
   * Retrieves a caption based on the information stored in the binding defined.
   * This binding can be obtained using the BindingEvaluator.getEntityBindingInfo call.
   */
  getCaptionFromField(bindingInformation: BindingInfo, addParentCaption = false): Caption {
    return getCaptionFromBindingInformation(bindingInformation, addParentCaption);
  }

  getCaptionFromInterfaceName(interfaceName: string): Caption {
    return getEntityCaptionWithFallback(interfaceName);
  }

  getCaptionForProperty(interfaceName: string, propertyName: string): Caption {
    return getEntityCaptionWithFallback(interfaceName, propertyName);
  }

  getCaptionFromKey(key: string, fallbackText: string): Caption {
    const resString = resStringRepository.getResourceStrings(key);
    if (resString) {
      return asCaption(resString);
    }
    return getFallbackCaption(fallbackText);
  }

  addParentCaption(caption: Caption, parents: ParentInfo[]): Caption {
    return addParentCaptionCore(caption, parents);
  }

  getRepository(): ResStringRepository {
    return resStringRepository;
  }

  /**
   * This function will return the appropriate caption for they key given, or
   * use the fallback text if such key does not exist in the currently loaded string repository.
   *
   * The function is also able to handle formatted strings. So if additional arguments are given,
   * an attempt will be made to substitute them in the text, where the appropriate placeholders
   * are. The format is equivalent to that used in the C# string.Format function.
   *
   * So, both of the following are valid calls:
   *
   * captionService.getString('aKey', 'fallbackText');
   * captionService.getString('bKey', 'fallbackFormat {0}', 'placeholder value');
   * @param key resource string key
   * @param fallbackText text to use if the key does not exist
   * @param args format arguments
   * @returns caption string
   */
  getString(key: string, fallbackText: string, ...args: unknown[]): string {
    const captionString = this.getCaptionFromKey(key, fallbackText).caption;
    return cleanCaptionString(format(captionString, ...args));
  }

  getStringFromInfo(stringResourceInfo: ResourceInfo): string {
    return this.getCaptionFromKey(stringResourceInfo.ResKey, stringResourceInfo.Fallback).caption;
  }

  isEmptyCaption(caption: ResourceInfo | string | undefined): boolean {
    if (isNonEmptyString(caption)) {
      return false;
    }

    return !caption || ("ResKey" in caption && isEmptyGuid(caption.ResKey) && !caption.Fallback);
  }
}

export interface ResourceInfo {
  ResKey: string;
  Fallback: string;
}

export interface ParentInfo {
  entityName: string;
  propertyName: string;
}

interface ConcatCaption extends Caption {
  path?: string;
  longWithNoParent?: string;
}

function concatCaption(caption: Caption, parentCaptions: Caption[]): ConcatCaption {
  const result = { ...caption } as ConcatCaption;
  const prefix = parentCaptions
    .map((parentCaption) => {
      return parentCaption.long;
    })
    .filter((longCaption) => {
      return longCaption;
    })
    .join(" > ");

  if (prefix) {
    result.path = prefix;
    result.longWithNoParent = result.long;
    result.long = (result.long || result.medium || result.short) + " (" + prefix + ")";
  }
  return result;
}

function getCaptionFromBindingInformation(bindingInformation: BindingInfo, addParentCaption: boolean): Caption {
  const caption = getCaptionFromBindingInformationByParents(bindingInformation);
  if (caption) {
    return caption;
  }
  const result = getEntityCaptionWithFallback(bindingInformation.entityName, bindingInformation.propertyName);
  return addParentCaption ? addParentCaptionCore(result, bindingInformation.parents) : result;
}

function getCaptionFromBindingInformationByParents(bindingInformation: BindingInfo): Caption | undefined {
  if (bindingInformation.parents && bindingInformation.parents.length > 0) {
    const interfaceName = getInterfaceName(bindingInformation.parents[0].entityType);
    const propertyName = [
      ...bindingInformation.parents.map((p) => p.propertyName),
      bindingInformation.propertyName,
    ].join(".");

    return getEntityCaption(interfaceName, propertyName);
  }

  return undefined;
}

function addParentCaptionCore(caption: Caption, parents: ReadonlyArray<ParentInfo>): Caption {
  if (caption && parents) {
    const parentCaptions = parents.map((parent) => {
      return getEntityCaptionWithFallback(parent.entityName, parent.propertyName);
    });
    caption = parentCaptions.length ? concatCaption(caption, parentCaptions) : caption;
  }
  return caption;
}

function getEntityCaptionWithFallback(entityName: string, propertyName?: string): Caption {
  const result =
    getEntityCaption(entityName, propertyName) ||
    (propertyName ? getStandardPropertyCaption(propertyName) || getFriendlyCaption(propertyName) : null) ||
    getFriendlyCaption(propertyName || cleanupEntityName(entityName));
  return result;
}

function getEntityCaption(entityName: string, propertyName?: string): Caption | undefined {
  let entityKeys = resStringRepository.getKeysForEntity(entityName);
  if (entityKeys.length === 0) {
    entityKeys = [entityName];
  }

  for (let i = 0; i < entityKeys.length; ++i) {
    const resString = resStringRepository.getResourceStrings(getResourcePath(entityKeys[i], propertyName));
    if (resString) {
      return asCaption(resString);
    }
  }

  return undefined;
}

// Keep the behaviour of getStandardPropertyCaption and getFriendlyPropertyCaption in sync with DotNet CaptionManager counterparts

function getStandardPropertyCaption(propertyName: string): Caption | undefined {
  let key: string | undefined;
  let fallback: string | undefined;
  propertyName = propertyName.toLowerCase();

  /*! StartNoStringValidationRegion (key/fallback pairs will be used to get resource strings) */
  if (propertyName.endsWith("systemcreatetimeutc")) {
    key = "f9822e22-4f1c-4df9-ac5a-1782aefe9ed0";
    fallback = "Created Date/Time";
  } else if (propertyName.endsWith("systemcreateuser")) {
    key = "c9eaf187-ad4e-49fa-9c60-e4d3ae19cb19";
    fallback = "Creating User";
  } else if (propertyName.endsWith("systemlastedittimeutc")) {
    key = "64ad0cd6-fb18-4228-9d9f-d333393248f2";
    fallback = "Last Edited Date/Time";
  } else if (propertyName.endsWith("systemlastedituser")) {
    key = "a41ecb60-c7ed-4490-b15a-e53d7edd6816";
    fallback = "Last Editing User";
  } else if (propertyName.endsWith("isactive")) {
    key = "ec550868-d65d-4fcb-81dd-ec049f3aef1b";
    fallback = "Active Status";
  } else if (propertyName.endsWith("iscancelled")) {
    key = "d640caef-5100-493e-a076-0ea18073dca4";
    fallback = "Canceled Status";
  } else if (propertyName.endsWith("issystem") || propertyName.endsWith("issystemdefined")) {
    key = "9f55bffc-fe5d-4d52-a954-de51afb16f00";
    fallback = "System Defined";
  } else if (propertyName.endsWith("_code")) {
    key = "a89a1738-f94d-4d53-881b-357e1033ce5c";
    fallback = "Code";
  } else if (propertyName.endsWith("_desc") || propertyName.endsWith("_description")) {
    key = "f8a67d70-ca9b-4b0b-a098-7e0fd95a9ac4";
    fallback = "Description";
  }
  /*! EndNoStringValidationRegion */

  if (key && fallback) {
    const resString = resStringRepository.getResourceStrings(key);
    if (resString) {
      return asCaption(resString);
    }
    return getFallbackCaption(fallback);
  }
  return undefined;
}

function getFriendlyCaption(propertyName: string): Caption {
  const arrPropName = propertyName.replace(/(^I)([A-Z0-9])/, "$2").split("_");
  let cleanedPropertyName = arrPropName.length > 0 ? arrPropName[arrPropName.length - 1] : propertyName;

  if (cleanedPropertyName.indexOf("NK") === 0) {
    cleanedPropertyName = cleanedPropertyName.substring(2);
  }

  if (!cleanedPropertyName) {
    return getFallbackCaption(propertyName);
  }

  const friendlyPropertyName = cleanedPropertyName
    .replace(/([A-Z0-9])([A-Z0-9][a-z])/g, "$1 $2")
    .replace(/([a-z])([A-Z0-9])/g, "$1 $2");

  if (friendlyPropertyName) {
    return getFallbackCaption(friendlyPropertyName);
  }

  return getFallbackCaption(cleanedPropertyName);
}

function getFallbackCaption(fallbackText: string): Caption {
  return {
    long: fallbackText,
    description: fallbackText,
    caption: fallbackText,
    longCaption: fallbackText,
    isFallback: true,
  };
}

function getResourcePath(entityName: string, propertyName: string | undefined): string {
  return entityName.toUpperCase() + (propertyName ? "|" + propertyName.toUpperCase() : "");
}

function cleanupEntityName(entityName: string): string {
  // Remove inner type for generics (inline with DotNet type.Name)
  return entityName.replace(/(\[\[.*\]\])/, "");
}

function asCaption(resString: ResourceString): Caption {
  return { ...resString };
}

function cleanCaptionString(captionString: string): string {
  // Remove any occurrences of empty replacement args
  return captionString.replace(/{<>}/g, "");
}

export default new CaptionService();
