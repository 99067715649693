import type { ErrorData } from "Errors";

export enum FormFlowErrorType {
  ConfigurationError = "ConfigurationError",
  NonReportableRuntimeError = "NonReportableRuntimeError",
  ReportableRuntimeError = "ReportableRuntimeError",
}

export interface FormFlowErrorOptions {
  friendlyCaption?: string;
  friendlyMessage?: string;
  innerError?: Error;
  type?: FormFlowErrorType;
}

export class FormFlowError extends Error {
  override readonly name = "FormFlowError";
  readonly friendlyCaption?: string;
  readonly friendlyMessage: string;
  readonly innerError?: Error;
  readonly type: FormFlowErrorType;
  activityStack?: string[];
  getData?: () => ErrorData[];

  constructor(message: string, options?: FormFlowErrorOptions) {
    super(message);

    this.friendlyCaption = options?.friendlyCaption;
    this.friendlyMessage = options?.friendlyMessage || message;
    this.innerError = options?.innerError;
    this.type = options?.type ?? FormFlowErrorType.ConfigurationError;
  }

  get isConfigurationError(): boolean {
    return this.type === FormFlowErrorType.ConfigurationError;
  }

  get isReportable(): boolean {
    return (
      this.type === FormFlowErrorType.ConfigurationError ||
      this.type === FormFlowErrorType.ReportableRuntimeError
    );
  }
}
