import { emptyDuration, minValue, type Duration } from "DateExtensions";
import { emptyGuid, isGuid } from "StringUtils";

interface ParsedValue<T> {
  isValid: boolean;
  value?: T;
}

class DataType<T = unknown> {
  emptyValue: T;
  isNullable: boolean;
  name?: string;
  isBooleanType: boolean;
  isIntegerType: boolean;
  isNumericType: boolean;
  isDateTimeType?: boolean;
  parseString?: (value: string) => ParsedValue<T>;

  constructor(config: {
    emptyValue: T;
    isNullable?: boolean;
    name?: string;
    isBooleanType?: boolean;
    isIntegerType?: boolean;
    isNumericType?: boolean;
    isDateTimeType?: boolean;
    parseString?: (value: string) => ParsedValue<T>;
  }) {
    this.emptyValue = config.emptyValue;
    this.isNullable = config.isNullable ?? false;
    this.isBooleanType = config.isBooleanType ?? false;
    this.isIntegerType = config.isIntegerType ?? false;
    this.isNumericType = config.isNumericType ?? false;
    this.isDateTimeType = config.isDateTimeType ?? false;
    this.parseString = config.parseString;
  }
}

const types: Record<string, DataType<unknown>> = {
  Boolean: new DataType<boolean>({
    emptyValue: false,
    isBooleanType: true,
  }),
  Byte: new DataType<number>({
    emptyValue: 0,
    isIntegerType: true,
    isNumericType: true,
  }),
  DateTime: new DataType<Date>({
    emptyValue: minValue().toDate(),
    isDateTimeType: true,
  }),
  DateTimeOffset: new DataType<Date>({
    emptyValue: minValue().toDate(),
    isDateTimeType: true,
  }),
  Decimal: new DataType<number>({
    emptyValue: 0,
    isNumericType: true,
  }),
  Double: new DataType<number>({
    emptyValue: 0,
    isNumericType: true,
  }),
  Guid: new DataType<string>({
    emptyValue: emptyGuid,
    parseString: (value?: string): ParsedValue<string> =>
      isGuid(value) ? { isValid: true, value } : { isValid: false },
  }),
  Int16: new DataType<number>({
    emptyValue: 0,
    isIntegerType: true,
    isNumericType: true,
  }),
  Int32: new DataType<number>({
    emptyValue: 0,
    isIntegerType: true,
    isNumericType: true,
  }),
  Int64: new DataType<number>({
    emptyValue: 0,
    isIntegerType: true,
    isNumericType: true,
  }),
  Single: new DataType<number>({
    emptyValue: 0,
    isNumericType: true,
  }),
  String: new DataType<string>({
    emptyValue: "",
    parseString: (value?: string): ParsedValue<string> => ({
      isValid: true,
      value: value || "",
    }),
  }),
  TimeSpan: new DataType<Duration>({
    emptyValue: emptyDuration(),
  }),
};

class NullableType<T> extends DataType<T | null> {
  readonly name: string;

  constructor(underlyingType: DataType<T>) {
    super({
      emptyValue: null,
      isNullable: true,
      isBooleanType: underlyingType.isBooleanType,
      isIntegerType: underlyingType.isIntegerType,
      isNumericType: underlyingType.isNumericType,
      isDateTimeType: underlyingType.isDateTimeType,
    });
    this.name = `Nullable[[${underlyingType.name}]]`;

    const { parseString } = underlyingType;
    if (parseString) {
      this.parseString = (value: string): ParsedValue<T | null> => {
        return value == null ? { isValid: true, value: null } : parseString(value);
      };
    }
  }
}

Object.entries(types).forEach(([name, type]) => {
  type.name = name;
  const nullableType = new NullableType(type);
  types[nullableType.name] = nullableType;
});

export function getDataType(dataTypeName: string): DataType | undefined {
  return types[dataTypeName];
}

export function getTypeNameFromFullyQualifiedName(typeName: string): string | undefined {
  if (!typeName) {
    return;
  }

  const match = typeName.match(/^Nullable\[\[(.+)\]\]$/);
  /*! SuppressStringValidation Type name */
  return (match ? match[1] : typeName).replace("System.", "");
}

export function isBooleanType(dataTypeName: string): boolean {
  const type = getDataType(dataTypeName);
  return !!type?.isBooleanType;
}

export function isDateTimeType(dataTypeName: string): boolean {
  const type = getDataType(dataTypeName);
  return !!type?.isDateTimeType;
}

export function isIntegerType(dataTypeName: string): boolean {
  const type = getDataType(dataTypeName);
  return !!type?.isIntegerType;
}

export function isNumericType(dataTypeName: string): boolean {
  const type = getDataType(dataTypeName);
  return !!type?.isNumericType;
}

export function unwrapCollectionType(dataTypeName: string): {
  isCollection: boolean;
  typeName: string;
} {
  const match = dataTypeName.match(/^ICollection\[\[(.+)\]\]$/) ?? dataTypeName.match(/^IEnumerable\[\[(.+)\]\]$/) ?? dataTypeName.match(/^(.+)\[\]$/);
  return match ? { isCollection: true, typeName: match[1] } : { isCollection: false, typeName: dataTypeName };
}

export function unwrapNullableType(dataTypeName: string): {
  isNullable: boolean;
  typeName: string;
} {
  const match = dataTypeName.match(/^Nullable\[\[(.+)\]\]$/);
  return match ? { isNullable: true, typeName: match[1] } : { isNullable: false, typeName: dataTypeName };
}

export default types;
