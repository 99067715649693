import Promise from 'bluebird';
import $ from 'jquery';

function CancelShortcutExtension($view, cancelCallback) {
	this._$view = $view;
	this._cancelCallback = cancelCallback;
}

CancelShortcutExtension.prototype.applyAsync = function () {
	const self = this;

	return Promise.try(() => {
		self._$view.on('keydown', (e) => {
			const escKeyCode = 27;
			if (e.keyCode === escKeyCode) {
				const $target = $(e.target);
				if ($target.is(':focus')) {
					$target.trigger('blur').trigger('focus');
				}
				self._cancelCallback();
			}
		});
	});
};

export default CancelShortcutExtension;
