import {
	areValuesEqual,
	decoratedWaitAvailable,
	getAvailableResult,
	operationTypes,
} from './Utils';

const equalCore = ([a, b]) => getAvailableResult(areValuesEqual(a, b));
const greaterThanCore = ([a, b]) => getAvailableResult(isGreaterThan(a, b));
const greaterThanOrEqualCore = ([a, b]) => getAvailableResult(isGreaterThanOrEqual(a, b));
const lessThanCore = ([a, b]) => getAvailableResult(isLessThan(a, b));
const lessThanOrEqualCore = ([a, b]) => getAvailableResult(isLessThanOrEqual(a, b));
const notEqualCore = ([a, b]) => getAvailableResult(!areValuesEqual(a, b));

const comparisonDecorationInfo = Object.freeze({
	operationType: operationTypes.Comparison,
	operandCount: 2,
	booleanResult: true,
});

const notEqualsDecorationInfo = Object.freeze({
	operationType: operationTypes.NotEquals,
	operandCount: 2,
	booleanResult: true,
});

function equal(left, right, data, context) {
	return decoratedWaitAvailable(
		[left, right],
		data,
		context,
		equalCore,
		comparisonDecorationInfo
	);
}

function greaterThan(left, right, data, context) {
	return decoratedWaitAvailable(
		[left, right],
		data,
		context,
		greaterThanCore,
		comparisonDecorationInfo
	);
}

function greaterThanOrEqual(left, right, data, context) {
	return decoratedWaitAvailable(
		[left, right],
		data,
		context,
		greaterThanOrEqualCore,
		comparisonDecorationInfo
	);
}

function lessThan(left, right, data, context) {
	return decoratedWaitAvailable(
		[left, right],
		data,
		context,
		lessThanCore,
		comparisonDecorationInfo
	);
}

function lessThanOrEqual(left, right, data, context) {
	return decoratedWaitAvailable(
		[left, right],
		data,
		context,
		lessThanOrEqualCore,
		comparisonDecorationInfo
	);
}

function notEqual(left, right, data, context) {
	return decoratedWaitAvailable(
		[left, right],
		data,
		context,
		notEqualCore,
		notEqualsDecorationInfo
	);
}

function isGreaterThan(a, b) {
	return a != null && b != null && a > b;
}

function isGreaterThanOrEqual(a, b) {
	return areValuesEqual(a, b) || isGreaterThan(a, b);
}

function isLessThan(a, b) {
	return a != null && b != null && a < b;
}

function isLessThanOrEqual(a, b) {
	return areValuesEqual(a, b) || isLessThan(a, b);
}

export default {
	equal,
	greaterThan,
	greaterThanOrEqual,
	lessThan,
	lessThanOrEqual,
	notEqual,
};
