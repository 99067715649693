import captionService from 'CaptionService';

export const searchDataTable = {
	deleteRow: () => captionService.getString('f065da71-0306-451e-9fed-401a7004ce5e', 'Delete Row'),
	edit: () => captionService.getString('179fa616-f01b-4fc7-badf-616e793c8b65', 'Edit'),
	new: () => captionService.getString('3a30153c-1bff-4010-9176-d77ba56a6c12', 'New'),
	noItemsFound: () => captionService.getString('0325e1d6-4505-4dab-a88d-15ea9306086d', 'No items found'),
	search: () => captionService.getString('759af1c0-d57f-4850-9977-3f1f28e5a07b', 'Search'),
	selected: (params) => captionService.getString('7df64bf4-11d7-4735-922e-40ab7a4ec871', '{0} selected', ...params),
	dragMoving: (params) => captionService.getString('6c7351af-fd55-44ce-86c6-c2793a462752', 'Moving 1 item', ...params),
	dragMovingMultiple: (params) => captionService.getString('a551e2af-fd62-429f-8f91-a0fdb3e6f92f', 'Moving {0} items', ...params),
	dragMoved: (params) => captionService.getString('178507e7-3018-401e-a71f-108019b9cf92', 'Moved 1 item', ...params),
	dragMovedMultiple: (params) => captionService.getString('b1f53c1c-9982-4300-b4d9-cb36acbbabdf', 'Moved {0} items', ...params)
};
