import ko from 'knockout';

export default class FilterStrip {
	constructor(searchFields, filterFactory, modelProvider) {
		this._filterFactory = filterFactory;

		this.searchFields = ko.observableArray(searchFields);
		this.searchField = ko.observable();
		this.filter = ko.pureComputed(this._getFilter, this);
		this.omitBlankElement = false;
		this.modelProvider = modelProvider;

		this.errorMessage = ko.observable();
		this.isValid = ko.pureComputed(() => {
			const filter = ko.unwrap(this.filter);
			return !this.errorMessage() && (!filter || ko.unwrap(filter.isValid));
		}, this);
	}

	selectFilter(field) {
		if (field.isFilterReset) {
			this.searchField(null);
		} else {
			this.searchField(field);
		}
		this.errorMessage(undefined);
	}

	_getFilter() {
		const field = this.searchField();
		if (field) {
			return ko.ignoreDependencies(() => {
				return this._filterFactory.create(field, this.modelProvider);
			});
		}
	}
}
