import { applyUtcOffset } from "DateExtensions";
import { makeConstructor } from "timezoned-date";
import moment from "../../node_modules/moment/moment.js";

const constructorCache: Record<number, DateConstructor> = {};
const iso8601Regex =
  /^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])?$/;

type DateInput = [number, number, number?, number?, number?, number?, number?];

export function createDateTimeOffset(value: Date | DateInput, offset: number): Date {
  let input: DateInput;
  if (value instanceof Date) {
    input = [
      value.getFullYear(),
      value.getMonth(),
      value.getDate(),
      value.getHours(),
      value.getMinutes(),
      value.getSeconds(),
      value.getMilliseconds(),
    ];
  } else {
    input = value;
  }

  //eslint-disable-next-line @typescript-eslint/naming-convention -- variable name Constructor should be camelCase
  const Constructor = getConstructor(offset);
  const result = new Constructor(...input);
  if (input[0] < 100) {
    result.setFullYear(input[0]);
  }

  return result;
}

export function fromLocalDate(value: Date, timezone: string): Date {
  if (!timezone) {
    throw new Error("Timezone must be specified.");
  }

  const offset = getOffset(timezone);
  //eslint-disable-next-line @typescript-eslint/naming-convention -- variable name Constructor should be camelCase
  const Constructor = getConstructor(offset);
  return new Constructor(value);
}

export function fromISOString(value: string): Date {
  const match = iso8601Regex.exec(value);
  const timezone = match && match[8];
  if (!timezone) {
    return new Date(value);
  }

  const offset = getOffset(timezone);
  //eslint-disable-next-line @typescript-eslint/naming-convention -- variable name Constructor should be camelCase
  const Constructor = getConstructor(offset);
  return new Constructor(value);
}

export function toISOString(value: Date): string {
  if (!(value instanceof Date)) {
    throw new Error("Expected an argument of type Date");
  }
  const prefix = applyUtcOffset(moment(value)).subtract(value.getTimezoneOffset(), "m").format("YYYY-MM-DDTHH:mm:ss");
  const ms = value.getMilliseconds();
  const msString = ms > 0 ? `.${padInt(ms, 3)}` : "";
  const offsetString = getOffsetString(value.getTimezoneOffset());
  return `${prefix}${msString}${offsetString}`;
}

export function getOffsetString(offset: number): string {
  const sign = offset > 0 ? "-" : "+";
  const hours = padInt(Math.trunc(Math.abs(offset) / 60), 2);
  const minutes = padInt(Math.abs(offset) % 60, 2);
  return `${sign}${hours}:${minutes}`;
}

function padInt(num: number, length: number): string {
  return num.toString().padStart(length, "0");
}

function getOffset(timezone: string): number {
  if (timezone === "Z") {
    return 0;
  } else {
    const multiplier = timezone[0] === "+" ? 1 : -1;
    const hour = intOrZero(timezone.substring(1, 3));
    const minute = intOrZero(timezone.substring(4));
    return multiplier * (hour * 60 + minute);
  }
}

function intOrZero(value: string): number {
  return value ? parseInt(value, 10) : 0;
}

function getConstructor(offset: number): DateConstructor {
  let result = constructorCache[offset];
  if (!result) {
    constructorCache[offset] = result = makeConstructor(offset);
    Object.defineProperty(result.prototype, "hasOffset", {
      get() {
        return true;
      },
    });
  }

  return result;
}
