import ajaxService from 'AjaxService';
import * as authenticationService from 'AuthenticationService';
import captionService from 'CaptionService';
import connection from 'Connection';
import constants from 'Constants';
import dataService from 'DataService';
import dialogService from 'DialogService';
import entitySetRightsProvider from 'EntitySetRightsProvider';
import errorReportingService from 'ErrorReportingService';
import * as externalLogonHandler from 'ExternalLogonHandler';
import global from 'Global';
import log from 'Log';
import nativeBridge from 'NativeBridge';
import notificationType from 'NotificationType';
import resStringRepository from 'ResStringRepository';
import serverMessageQueue from 'ServerMessageQueue';
import { isSessionLimitError, tryHandleSessionLimitErrorAsync } from 'SessionLimitReachedHandler';
import userSession from 'UserSession';
import windowManager from 'WindowManager';
import breeze from 'breeze-client';
import $ from 'jquery';
import wellknown from 'wellknown';

function LogonService() {
}

LogonService.prototype.logOffAsync = async () => {
	log.withTag('UserSession').info('LogonService.logOffAsync');
	const sessionId = userSession.sessionData().sessionId;
	const authenticationToken = userSession.sessionData().authenticationToken;

	try {
		if (sessionId) { await authenticationService.session.destroyAsync(); }
		if (authenticationToken) { await authenticationService.credentials.revokeAsync(authenticationToken); }
		stopNativeAppGPSTracking();
	} catch (error) {
		$.noop(error);
	} finally {
		await userSession.clearSessionAsync();
	}
};

async function tryResumeExistingSessionOrStartSSOSessionAsync(self) {
	try {
		const sessionData = userSession.sessionData();
		const ssoTokenInfo = await checkAmbiguousLoginAsync(self, sessionData);
		const newToken = ssoTokenInfo && ssoTokenInfo.newToken;
		const isNewUser = ssoTokenInfo && ssoTokenInfo.isNewUser;
		const existingToken = sessionData.authenticationToken;

		let result;
		if (existingToken && !isNewUser) {
			if (shouldResumeSession(sessionData) && (await resumeSessionAsync(sessionData))) {
				await revokeTokenAsync(newToken);
				return;
			}

			const { sessionId } = sessionData;
			const ignoreError = true;
			result = await beginSessionWithRetryAsync(existingToken, sessionId, ignoreError);
			if (result) {
				await revokeTokenAsync(newToken);
			} else if (newToken) {
				result = await beginSessionWithRetryAsync(newToken, sessionId, !ignoreError);
			}
		} else if (newToken) {
			result = await beginSessionWithRetryAsync(newToken, undefined, false);
		}

		if (result) {
			try {
				await self.logOnWithUserInfoAsync(result.authenticationToken, result.userInfo, (ssoTokenInfo || sessionData).authenticationSource);
			} catch (error) {
				await destroySessionAsync();
				throw error;
			}
		} else {
			await Promise.reject();
		}
	} catch (error) {
		if (isNotOfflineError(error)) {
			await clearSessionAsync();
		}
		throw error;
	}

	function shouldResumeSession(sessionData) {
		return !!(sessionData.sessionId && sessionData.entitySetRights);
	}

	async function resumeSessionAsync(sessionData) {
		try {
			const userInfo = (await authenticationService.session.checkAsync()).userInfo;
			if (!userInfo || userInfo.identityKey !== sessionData.userPK) {
				throw new Error('Cannot resume session because the resumed identity key does not match session data.');
			}
			return true;
		} catch (error) {
			const isPotentialSessionExpiry =
				error instanceof authenticationService.AuthenticationError &&
				error.authenticationResult !==
					authenticationService.AuthenticationResult.SessionEvicted &&
				error.innerError instanceof ajaxService.AjaxError &&
				error.innerError.status === 401;

			if (isPotentialSessionExpiry) {
				return false;
			} else {
				throw error;
			}
		}
	}
}

async function beginSessionWithRetryAsync(token, sessionId, ignoreError) {
	const sessionType = await nativeBridge.getSessionTypeAsync();
	try {
		try {
			return await beginSessionAsync(token, sessionId);
		} catch (error) {
			if (isSessionLimitError(error)) {
				if (await tryHandleSessionLimitErrorAsync(error)) {
					return await beginSessionAsync(token, sessionId);
				} else {
					throw error;
				}
			} else if (!ignoreError || error instanceof connection.OfflineError) {
				throw error;
			}
		}
	} catch (error) {
		await revokeTokenAsync(token);
		throw error;
	}

	async function beginSessionAsync(token, sessionId) {
		const sessionBeginAsync = await authenticationService.session.beginAsync(
			token,
			authenticationService.AuthenticationClaimType.LocalToken,
			sessionType,
			sessionId
		);
		return sessionBeginAsync;
	}
}

async function checkAmbiguousLoginAsync(self, sessionData) {
	const tokenUserInfo = await claimSingleSignOnSessionAsync();
	if (tokenUserInfo) {
		const {
			authenticationToken: newToken,
			authenticationSource,
			userName: newUserName,
			branchKey: newBranchKey,
			departmentKey: newDepartmentKey,
		} = tokenUserInfo;

		if (validateUserInfo() && hasUserInfoChanged()) {
			const { userName, departmentCode, branchCode } = sessionData;

			const { departmentCode: newDepartmentCode, branchCode: newBranchCode } =
				await getBranchAndDepartmentCodesAsync(
					newBranchKey,
					newDepartmentKey
				);

			const loggedInUserInfo = `${
				userName ||
				captionService.getString('68911c32-b5a2-3c3f-e713-d70b2a1d14ce', 'another user')
			} (${departmentCode}/${branchCode})`;
			const newLoginUserInfo = `${newUserName} (${newDepartmentCode}/${newBranchCode})`;

			const message = captionService.getString(
				'17dcac98-3bc2-4f31-8535-6b21524ba148',
				'You are currently logged on as {0}. Do you want to log out from this session and log in as {1}?\nPlease note that should you choose to logout you will lose all your current work in other open tabs.',
				loggedInUserInfo,
				newLoginUserInfo
			);
			const title = captionService.getString(
				'cd2ccdd9-ee90-4b03-80ce-d168d38eba26',
				'Ambiguous login'
			);

			const confirmAsyncResult = await dialogService.yesNoConfirmAsync(message, title, {
				notificationType: notificationType.Warning,
			});
			const shouldLogout = (await confirmAsyncResult) === dialogService.buttonTypes.Yes().value;
			if (shouldLogout) {
				await self.logOffAsync();
				return { newToken, isNewUser: true, authenticationSource };
			}

			return revokeTokenAsync(newToken);
		}

		return { newToken, authenticationSource };
	}

	function validateUserInfo() {
		return (
			sessionData.authenticationToken &&
			tokenUserInfo.departmentKey && sessionData.departmentPK &&
			tokenUserInfo.branchKey && sessionData.branchPK
		);
	}

	function hasUserInfoChanged() {
		return (
			tokenUserInfo.userKey !== sessionData.userPK ||
			tokenUserInfo.departmentKey !== sessionData.departmentPK ||
			tokenUserInfo.branchKey !== sessionData.branchPK
		);
	}

	async function getBranchAndDepartmentCodesAsync(branchKey, departmentKey) {
		/*! SuppressStringValidation Global is a route name */
		const entityManager = await breeze.EntityManager.forRouteAsync('Global');

		const branchQuery = entityManager.createQuery('IGlbBranch')
			.where('GB_PK', '==', branchKey)
			.select('GB_Code')
			.noTracking()
			.execute();

		const departmentQuery = entityManager.createQuery('IGlbDepartment')
			.where('GE_PK', '==', departmentKey)
			.select('GE_Code')
			.noTracking()
			.execute();

		const [branchResult, departmentResult] = await Promise.all([branchQuery, departmentQuery]);
		return { branchCode: branchResult.results[0]?.GB_Code, departmentCode: departmentResult.results[0]?.GE_Code };
	}
}

async function destroySessionAsync() {
	try { return await authenticationService.session.destroyAsync(); }
	catch (error) { $.noop(error); }
}

async function clearSessionAsync() {
	try { return await userSession.clearSessionAsync(); }
	catch (error) { $.noop(error); }
}

async function revokeTokenAsync(token) {
	try { if (token) { return await authenticationService.credentials.revokeAsync(token); } }
	catch (error) { $.noop(error); }
}

async function claimSingleSignOnSessionAsync() {
	const window = global.getWindow();
	const url = new URL(window.location.href);

	let singleSignOnToken = getAndRemoveToken(url.searchParams);
	if (!singleSignOnToken) {
		singleSignOnToken = getAndRemoveTokenFromHash(url);
	}

	if (singleSignOnToken) {
		const { href } = url;
		windowManager.historyPushState({ href }, '', href);
		const result = await authenticationService.credentials.claimSsoTokenAsync(singleSignOnToken);
		return result;
	}

	function getAndRemoveToken(searchParams) {
		const paramName = 'sso_otp';
		const token = searchParams.get(paramName);
		if (token) {
			searchParams.delete(paramName);
		}

		return token;
	}

	function getAndRemoveTokenFromHash(url) {
		const index = url.hash.indexOf('?');
		if (index > 0) {
			const searchParams = new URLSearchParams(url.hash.substring(index + 1));
			const token = getAndRemoveToken(searchParams);
			if (token) {
				const search = searchParams.toString();
				let hash = url.hash.substring(0, index);
				if (search) {
					hash += '?' + search;
				}
				url.hash = hash;
			}

			return token;
		}
	}
}

LogonService.prototype.tryResumeAsync = async function () {
	log.withTag('UserSession').info('LogonService.tryResumeAsync');

	try {
		await tryResumeExistingSessionOrStartSSOSessionAsync(this);
	} catch (error) {
		if (!isNotOfflineError(error)) {
			throw error;
		}
		const authenticationInfo = await externalLogonHandler.tryCompleteExternalLogonAsync();
		try {
			if (!authenticationInfo) {
				return;
			}

			const result = await beginSessionWithRetryAsync(
				authenticationInfo.authenticationToken,
				undefined,
				false
			);
			try {
				await this.logOnWithUserInfoAsync(
					result.authenticationToken,
					result.userInfo,
					authenticationInfo.authenticationSource
				);
			} catch (e) {
				await revokeTokenAsync(authenticationInfo.authenticationToken);
				await destroySessionAsync();
				throw e;
			}
		} catch (e) {
			await clearSessionAsync();
			throw e;
		} finally {
			const url = new URL(global.windowLocation.href);
			/*! SuppressStringValidation state is a query key */
			const state = url.searchParams.get('state');
			const stateObject = JSON.parse(state);
			const hash = stateObject.hash || '';
			global.windowLocation.href = global.getModuleUrl() + hash;
		}
	}
};

LogonService.prototype.changeUserContextAsync = async function (canSkip) {
	log.withTag('UserSession').info('LogonService.changeUserContextAsync');

	const sessionData = userSession.sessionData();
	const sessionId = sessionData.sessionId;

	const newAuthenticationToken = await authenticationService.session.changeContextAsync(
		sessionData,
		canSkip
	);
	if (!newAuthenticationToken) {
		return false;
	}

	await destroySessionAsync();
	await clearSessionAsync();

	try {
		const result = await authenticationService.session.beginAsync(
			newAuthenticationToken,
			authenticationService.AuthenticationClaimType.LocalToken,
			await nativeBridge.getSessionTypeAsync(),
			sessionId
		);

		await this.logOnWithUserInfoAsync(result.authenticationToken, result.userInfo, sessionData.authenticationSource).tapCatch(
			destroySessionAsync
		);
	} catch (e) {
		await revokeTokenAsync(newAuthenticationToken);
		await clearSessionAsync();
		throw e;
	}

	return true;
};

LogonService.prototype.logOnWithUserInfoAsync = async (authenticationToken, userInfo, authenticationSource) => {
	log.withTag('UserSession').info('LogonService.logOnWithUserInfoAsync');
	const [licenceCode, userDetails, moduleAccess, entitySetRights] = await Promise.all([
		getLicenceCodeAsync(userInfo.identityProvider),
		getUserDetailsAsync(userInfo),
		getModuleAccessAsync(),
		getEntitySetRightsAsync(),
	]);
	await loadResourceStringsAsync(userDetails.details.languageCode);
	storeSessionDetails({ userInfo, licenceCode, userDetails, moduleAccess, authenticationToken, entitySetRights, authenticationSource });
	startNativeAppGPSTracking(userInfo);

	const result = await Promise.all([(serverMessageQueue.resumeAsync(), errorReportingService.sendPendingErrorReportsAsync())]);
	return result;
};

function loadResourceStringsAsync(languageCode) {
	return resStringRepository.loadStringsAsync(languageCode);
}

function startNativeAppGPSTracking(userInfo) {
	if (userInfo.identityProvider === userSession.UserType.Staff) {
		nativeBridge.performCommandAsync('startTracking');
	}
}

function stopNativeAppGPSTracking() {
	nativeBridge.performCommandAsync('stopTracking');
}

LogonService.prototype.logOnAsync = async function (logonProviderType, userName, password) {
	log.withTag('UserSession').info('LogonService.logOnAsync');

	const self = this;
	const serialNumber = nativeBridge.nativeClientSerialNumber;
	const deviceType = nativeBridge.nativeClientDeviceType;
	let authenticationInfo = null;

	try {
		authenticationInfo = await authenticationService.credentials.claimAsync(logonProviderType, userName, password, serialNumber, deviceType);
	} catch (error) {
		if (
			error instanceof authenticationService.AuthenticationError &&
			error.authenticationResult === authenticationService.AuthenticationResult.ThirdPartyUserValidationRequired
		) {
			externalLogonHandler.tryHandleThirdPartyLogon(error.claimDetails);
		}
		throw error;
	}

	try {
		const result = await authenticationService.session.beginAsync(
			authenticationInfo.authenticationToken,
			authenticationService.AuthenticationClaimType.LocalToken,
			await nativeBridge.getSessionTypeAsync(),
		);
		await self
			.logOnWithUserInfoAsync(result.authenticationToken, result.userInfo, authenticationInfo.authenticationSource)
			.tapCatch(destroySessionAsync);
	} catch (error) {
		await revokeTokenAsync(authenticationInfo.authenticationToken);
		await clearSessionAsync();
		throw error;
	}
};

LogonService.prototype.challengeAsync = async (authenticationSource) => {
	log.withTag('UserSession').info('LogonService.challengeAsync');

	const claimDetails = await authenticationService.credentials.challengeAsync(authenticationSource);
	return externalLogonHandler.tryHandleThirdPartyLogon(claimDetails);
};

async function getLicenceCodeAsync(identityProvider) {
	if (identityProvider === userSession.UserType.Person) {
		return Promise.resolve('');
	}
	const result = await dataService.getAsync('System/GetCurrentLicenceCode');

	if (result.value) {
		return result.value;
	}
	else {
		throw new authenticationService.AuthenticationError(
			authenticationService.AuthenticationResult.AbnormalFailure,
			captionService.getString('6e977cfe-06a0-49db-a6ec-f5514678407f', 'Login failed. Please check that your profile has an active Home Branch set, then try again.'));
	}
}

async function getModuleAccessAsync() {
	/*! SuppressStringValidation String validation suppressed in initial refactor */
	const ajaxResponse = await ajaxService.ajaxAsync(global.serviceUri + 'api/modules/authorized');
	return ajaxResponse;
}

async function getUserDataAsync(userQuery, branchQuery, departmentQuery) {
	/*! SuppressStringValidation Global is a route name */
	const entityManager = await breeze.EntityManager.forRouteAsync('Global');

	async function executeQueryAsync(query) {
		const { results } = await entityManager.executeQuery(query.noTracking());
		if (results.length !== 1) {
			throw new Error('Single record could not be loaded.');
		}
		return results[0];
	}
	const userBranchDepartmentResult = await Promise.all([
		executeQueryAsync(userQuery),
		executeQueryAsync(branchQuery),
		executeQueryAsync(departmentQuery),
	]);
	return userBranchDepartmentResult;
}

async function getUserDetailsForStaffAsync(userInfo) {
	const staffQuery = new breeze.EntityQuery('GlbStaffs')
		.where('GS_PK', '==', userInfo.identityKey)
		.select(['GS_Code', 'GS_LoginName', 'GS_FullName', 'GS_FriendlyName', 'GS_EmailAddress', 'GS_Title', 'GS_WorkingLanguage', 'GS_IsController']);
	const branchQuery = new breeze.EntityQuery('GlbBranches')
		.where('GB_PK', '==', userInfo.userContextBranchKey)
		.expand('HomePort')
		.select(['GB_PK', 'GB_Code', 'GB_GC', 'HomePort.RL_RN_NKCountryCode', 'HomePort.RL_GeoLocation']);
	const departmentQuery = new breeze.EntityQuery('GlbDepartments')
		.where('GE_PK', '==', userInfo.userContextDepartmentKey)
		.select(['GE_PK', 'GE_Code']);

	const [staff, branch, department] = await getUserDataAsync(staffQuery, branchQuery, departmentQuery);

	const countryCode = branch.HomePort ? branch.HomePort.RL_RN_NKCountryCode : '';
	const homeLocation = parseGeoLocation(branch.HomePort);
	return {
		code: staff.GS_Code,
		details: {
			userName: staff.GS_LoginName,
			friendlyName: staff.GS_FriendlyName,
			fullName: staff.GS_FullName,
			email: staff.GS_EmailAddress,
			title: staff.GS_Title,
			languageCode: staff.GS_WorkingLanguage,
			countryCode,
			isController: staff.GS_IsController,
			companyPK: branch.GB_GC,
			branchPK: branch.GB_PK,
			branchCode: branch.GB_Code,
			departmentPK: department.GE_PK,
			departmentCode: department.GE_Code,
			homeLocation,
		},
	};
}

async function getUserDetailsForContactAsync(userInfo) {
	const contactQuery = new breeze.EntityQuery('OrgContactInfos')
		.expand('OrgHeader, OrgHeader/ClosestPort')
		.where('OC_PK', '==', userInfo.identityKey)
		.select(['OC_ContactName', 'OC_Email', 'OC_Title', 'OC_OH', 'OC_Language', 'OrgHeader.OH_Code', 'OrgHeader.OH_FullName', 'OrgHeader.ClosestPort.RL_RN_NKCountryCode', 'OrgHeader.ClosestPort.RL_GeoLocation']);
	const branchQuery = new breeze.EntityQuery('GlbBranchInfos')
		.where('GB_PK', '==', userInfo.userContextBranchKey)
		.select(['GB_PK', 'GB_Code']);
	const departmentQuery = new breeze.EntityQuery('GlbDepartmentInfos')
		.where('GE_PK', '==', userInfo.userContextDepartmentKey)
		.select(['GE_PK', 'GE_Code']);

	const [contact, branch, department] = await getUserDataAsync(contactQuery, branchQuery, departmentQuery);
	const orgCode = contact.OrgHeader.OH_Code.trim(); //for some reason OH_Code comes back with a bunch of spaces;
	const countryCode = contact.OrgHeader.ClosestPort ? contact.OrgHeader.ClosestPort.RL_RN_NKCountryCode : '';
	const homeLocation = parseGeoLocation(contact.OrgHeader.ClosestPort);
	return {
		code: 'ZZ', // Remove this when BPM types work without a GS reference.
		details: {
			userName: orgCode + '/' + contact.OC_Email,
			fullName: contact.OC_ContactName,
			email: contact.OC_Email,
			title: contact.OC_Title,
			orgPK: contact.OC_OH,
			orgCode,
			orgName: contact.OrgHeader.OH_FullName,
			languageCode: contact.OC_Language,
			countryCode,
			branchPK: branch.GB_PK,
			branchCode: branch.GB_Code,
			departmentPK: department.GE_PK,
			departmentCode: department.GE_Code,
			homeLocation,
		}
	};
}

async function getUserDetailsForPersonAsync(userInfo) {
	const personQuery = new breeze.EntityQuery('GlbPeople')
		.where('PER_PK', '==', userInfo.identityKey)
		.select(['PER_EmailAddress', 'PER_FullName']);

	/*! SuppressStringValidation Global is a route name */
	const entityManager = await breeze.EntityManager.forRouteAsync('Global');
	const result = await entityManager.executeQuery(personQuery.noTracking());

	if (result.results.length !== 1) {
		throw new Error('Single record could not be loaded.');
	}

	const person = result.results[0];
	return {
		code: 'ZZ',
		details: {
			userName: person.PER_EmailAddress,
			fullName: person.PER_FullName,
			email: person.PER_EmailAddress,
		},
	};
}

async function getUserDetailsAsync(userInfo) {
	let result;
	if (userInfo.identityProvider === userSession.UserType.Staff) {
		result = await getUserDetailsForStaffAsync(userInfo);
	}
	else if (userInfo.identityProvider === userSession.UserType.Person) {
		result = await getUserDetailsForPersonAsync(userInfo);
	}
	else {
		result = await getUserDetailsForContactAsync(userInfo);
	}
	return result;
}

async function getEntitySetRightsAsync() {
	const result = await entitySetRightsProvider.loadAsync();
	return result;
}

function storeSessionDetails({ userInfo, licenceCode, userDetails, moduleAccess, authenticationToken, entitySetRights, authenticationSource }) {
	const sessionData = {
		sessionId: userInfo.sessionId,
		userType: userInfo.identityProvider,
		userPK: userInfo.identityKey,
		userCode: userDetails.code,
		userName: userDetails.details.userName,
		userEmailAddress: userDetails.details.email,
		licenceCode,
		loggedOnUser: userDetails.details,
		moduleAccess,
		authenticationToken,
		authenticationSource,
		languageCode: userDetails.details.languageCode || global.languageCode,
		entitySetRights,
		branchPK: userDetails.details.branchPK,
		branchCode: userDetails.details.branchCode || '',
		departmentPK: userDetails.details.departmentPK,
		departmentCode: userDetails.details.departmentCode || '',
		countryCode: userDetails.details.countryCode,
		homeLocation: userDetails.details.homeLocation,
	};

	userSession.saveSession(sessionData);
}

function isNotOfflineError(error) {
	return !(error instanceof connection.OfflineError);
}

function parseGeoLocation(port) {
	if (!port || !port.RL_GeoLocation) {
		return null;
	}

	const point = wellknown.parse(port.RL_GeoLocation);
	if (!point || !point.coordinates || !point.coordinates.length === 2 || point.type !== constants.WellknownTypes.Point) {
		return null;
	}

	return { lat: point.coordinates[1], lng: point.coordinates[0] };
}

export default new LogonService();