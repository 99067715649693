import $ from 'jquery';
import ko from 'knockout';
import { loadTemplateAsync } from 'ModuleLoader';

ko.bindingHandlers.gwLazyTemplate = {
	init(element, valueAccessor) {
		const args = arguments;
		const params = ko.unwrap(valueAccessor());
		const templateName = params.name;
		const templatePath = params.path;
		const loadTemplatePromise = templateIsLoaded(templateName) ?
			Promise.resolve() :
			loadTemplateAsync(templatePath).then((template) => {
				if (!templateIsLoaded(templateName)) {
					$(template).appendTo(document.body);
				}
			});

		loadTemplatePromise.then(() => {
			ko.bindingHandlers.template.init.apply(null, args);
			ko.bindingHandlers.template.update.apply(null, args);
		});
	}
};

function templateIsLoaded(templateName) {
	return !!$('#' + templateName).length;
}

ko.virtualElements.allowedBindings.gwLazyTemplate = true;
