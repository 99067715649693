import constants from 'Constants';
import KeyCaptor from 'KeyCaptor';
import nativeBridge from 'NativeBridge';
import shortcutService from 'ShortcutService';
import widgetUtils from 'WidgetUtils';
import Promise from 'bluebird';
import $ from 'jquery';

function ScanInputExtension($view, completeCallback) {
	this._$view = $view;
	this._completeCallback = completeCallback;
	this._disposeDocumentEvents = null;
}

ScanInputExtension.prototype.applyAsync = function () {
	const self = this;

	return Promise.try(() => {
		const $view = self._$view;
		const scanInputHandling = $view
			.find('[data-role=gwShellContainer]')
			.data('scaninputhandling');
		if (!scanInputHandling || scanInputHandling === constants.ScanInputHandling.None) {
			return;
		}

		return widgetUtils.waitInitAllWidgetsAsync($view).then(() => {
			let disposables;
			/*! StartNoStringValidationRegion Shortcut keys. */
			const focusShortcutKey = 'ctrl+[';
			const gs1ShortcutKey = 'ctrl+]';
			const barcodeShortcutKey = 'ctrl+\\';
			/*! EndNoStringValidationRegion */

			if (nativeBridge.isScanProvider()) {
				const endScanFn = endScan.bind(null, self, scanInputHandling);
				disposables = [
					shortcutService.bindCallbackToElement(
						$view,
						focusShortcutKey,
						findNextControl.bind(null, $view)
					),
					shortcutService.bindCallbackToElement($view, gs1ShortcutKey, endScanFn),
					shortcutService.bindCallbackToElement($view, barcodeShortcutKey, endScanFn),
				];
			} else {
				const keyCaptor = new KeyCaptor();
				let $control;
				const endScanFn = () => {
					const data = keyCaptor.endCapture();
					if ($control && $control.length && !$control.attr('readonly')) {
						if ($control.attr('data-settable')) {
							$control.component('setValue', data);
						} else {
							$control.val(data);
							$control.trigger('change');
						}
						setLastScannedElement($control);
					}
					return endScan(self, scanInputHandling);
				};
				disposables = [
					shortcutService.bindCallbackToElement($view, focusShortcutKey, () => {
						$control = findNextControl($view);
						keyCaptor.initCapture();
						return $control;
					}),
					shortcutService.bindCallbackToElement($view, gs1ShortcutKey, endScanFn),
					shortcutService.bindCallbackToElement($view, barcodeShortcutKey, endScanFn),
					{ dispose: () => keyCaptor.disposeListener() },
				];
			}

			$view.on('remove', () => {
				disposeDocumentEvents(self);
				disposables.forEach((disposable) => {
					disposable.dispose();
				});
			});

			if ($(':focus').length === 0) {
				const $nextControl = getComponent(findNextControl($view));
				setFocusOnControl(self, $nextControl);
			}
		});
	});
};

function endScan(self, scanInputHandlingType) {
	const $view = self._$view;
	$view.find('input:focus,textarea:focus').trigger('blur');

	const $nextControl = getComponent(findNextControl($view));
	setFocusOnControl(self, $nextControl);

	if (
		!$nextControl.length &&
		scanInputHandlingType === constants.ScanInputHandling.ScanNextAndSubmit
	) {
		self._completeCallback();
	}

	return $nextControl;
}

function setFocusOnControl(self, $control) {
	if ($control.length === 1) {
		setFocusEvents(self, $control);
		/*! SuppressStringValidation Scroll method parameters. */
		$control[0].scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'nearest' });
	} else {
		clearControlsFocus(self);
	}
}

function findNextControl($view) {
	const $focused = $view.find('input:focus,textarea:focus').filter(':visible');
	if ($focused.length > 0) {
		const $widget = getComponent($focused)
			.find('[data-ko-widget]:not([readonly])')
			.filter('[data-settable=true][data-readable=true]');
		return $widget.length ? $widget : $focused;
	}

	const $nexts = getNextControls($view);
	return $nexts.first();
}

function disposeDocumentEvents(self) {
	const dispose = self._disposeDocumentEvents;
	if (dispose) {
		self._disposeDocumentEvents = null;
		dispose();
	}
}

function getComponent($element) {
	return $element.closest('.g-container');
}

function setFocusEvents(self, $control) {
	const eventHandler = clearControlsFocus.bind(null, self);
	eventHandler();

	/*! SuppressStringValidation Event names. */
	const events = 'focus click touchstart';
	$(document).on(events, eventHandler);
	self._disposeDocumentEvents = () => {
		$(document).off(events, eventHandler);
	};

	$control.find('.g-widget-border').addClass('g-next-control-focus');
}

function clearControlsFocus(self) {
	disposeDocumentEvents(self);
	self._$view.find('.g-next-control-focus').removeClass('g-next-control-focus');
}

function getNextControls($view) {
	let $nexts = $view.find('[data-settable=true][data-readable=true]');
	const lastScannedIndex = getLastScannedIndex($nexts);

	$nexts = $nexts.slice(lastScannedIndex).filter((_, element) => {
		const widget = getComponent($(element))
			.filter(':visible')
			.find('[data-ko-widget]:not([readonly])');
		return (
			widget.length &&
			widget.closest('.gwDataGrid').length === 0 &&
			widget.find('input[readonly]').length === 0
		);
	});

	return $nexts;
}

function setLastScannedElement($element) {
	$('[data-last-scanned]').removeAttr('data-last-scanned');
	$element.attr('data-last-scanned', true);
}

function getLastScannedIndex($nexts) {
	let lastScannedIndex = -1;
	$nexts.each((index, element) => {
		if ($(element).attr('data-last-scanned')) {
			lastScannedIndex = index;
		}
	});
	return ++lastScannedIndex;
}

export default ScanInputExtension;
