import { ExpandDepthLimit, SearchListMode, WCFExpandPathsLimit } from "Constants";
import { getInterfaceName, getIsNotExpandable } from "EntityExtensions";
import type { EntityInfoStrategy, EntityStrategy } from "EntityStrategyProvider";
import { DependencyError } from "Errors";
import log from "Log";
import { enumeratePropertyPath, isComplexPath, replaceSeparators, type PropertyInfo } from "PropertyPathEnumerator";
import { isUnrestrictedBreezeProperty } from "PropertySecurity";
import RuleService from "RuleService";
import sortColumnService from "SortColumnService";
import type { SortInfo } from "SortProvider";
import type { EntityType, NavigationProperty } from "breeze-client";

const separator = ".";

// According WI00469975 remove this stop list once migrated to OData4
const nonExpandableTypes = new Set(["IProcessHeader", "IProcessTask"]);

type ExpandPath = { entityType: EntityType; path: string; children?: ExpandPath[] };
type HeaderData = { expandPaths: string[] };

export function getExpandPaths(entityType: EntityType, propertyPath: string): string[] {
  const root: ExpandPath = { entityType, path: "", children: [] };
  let previousNodes: ExpandPath[] = [root];

  for (const propertyInfo of enumeratePropertyPath(entityType, propertyPath)) {
    if (!propertyInfo.isValid) {
      throw new DependencyError(`Invalid property path "${propertyPath}" on entity ${getInterfaceName(entityType)}.`);
    }

    previousNodes = previousNodes.filter((p) => p.entityType === propertyInfo.entityType);
    if (!previousNodes.length) {
      break;
    }

    const expandPaths = getExpandPathsCore(propertyInfo);
    if (!expandPaths || !expandPaths.length) {
      break;
    }

    previousNodes = appendExpandPaths(expandPaths);
  }

  return mergeExpandPaths(collectExpandPaths(root));

  function getExpandPathsCore(propertyInfo: PropertyInfo): ExpandPath[] | null {
    if (!propertyInfo.entityType) {
      return null;
    }

    const expandPaths = RuleService.get(getInterfaceName(propertyInfo.entityType)).expandPaths(
      propertyInfo.propertyName
    );
    if (expandPaths) {
      return expandPaths
        .map((p) =>
          verifyExpandPath<ExpandPath>(propertyInfo.entityType, p, (entityType, path) => ({
            entityType,
            path,
          }))
        )
        .filter(isNotFalse);
    }

    return expandPaths;

    function isNotFalse(path: ExpandPath | false): path is ExpandPath {
      return Boolean(path);
    }
  }

  function appendExpandPaths(expandPaths: ExpandPath[]): ExpandPath[] {
    const newNodes: ExpandPath[] = [];
    previousNodes.forEach((prev) => {
      expandPaths.forEach((cur) => {
        const child = {
          entityType: cur.entityType,
          path: prev.path ? prev.path + separator + cur.path : cur.path,
          children: [],
        };

        prev.children?.push(child);
        newNodes.push(child);
      });
    });

    return newNodes;
  }

  function collectExpandPaths(node: ExpandPath, result: string[] = []): string[] {
    if (node.children?.length) {
      node.children.forEach((c) => collectExpandPaths(c, result));
    } else if (node.path) {
      result.push(node.path);
    }
    return result;
  }
}

export function getExpandPathsFromHeaders(
  strategy: EntityStrategy | EntityInfoStrategy,
  headers: HeaderData[],
  sortInfos: SortInfo[]
): string[] {
  return strategy.getMode() === SearchListMode.Entity
    ? trimExpandPaths(
        headers.filter((h) => h.expandPaths).flatMap((h) => h.expandPaths),
        sortInfos
      )
    : [];
}

export function mergeExpandPaths(expandPaths: string[]): string[] {
  return mergeExpandPathsCore(expandPaths.map((p) => replaceSeparators(p, separator)));
}

export function sanitizeExpandPaths(
  entityType: EntityType,
  expandPaths: string[],
  options?: { warn: boolean }
): string[] {
  const warn = options && options.warn;
  let result = expandPaths
    .map((p) => verifyExpandPath<string>(entityType, p, (_, path) => path, warn))
    .filter(isNotFalse);
  result = mergeExpandPathsCore(result);
  return result;

  function isNotFalse(path: ExpandPath | string | false): path is string {
    return Boolean(path);
  }
}

function mergeExpandPathsCore(expandPaths: string[]): string[] {
  const result: string[] = [];
  expandPaths.forEach((newPath) => {
    if (!newPath) {
      return;
    }

    for (let i = 0; i < result.length; i++) {
      const existingPath = result[i];
      if (newPath.length >= existingPath.length) {
        if (isSubPath(existingPath, newPath)) {
          result[i] = newPath;
          return;
        }
      } else if (isSubPath(newPath, existingPath)) {
        return;
      }
    }

    result.push(newPath);
  });

  return result;

  function isSubPath(source: string, target: string): boolean {
    return target.startsWith(source) && (source.length === target.length || target[source.length] === separator);
  }
}

function verifyExpandPath<T>(
  entityType: EntityType | undefined,
  expandPath: string,
  valueSelector: (entityType: EntityType, path: string) => T,
  warn?: boolean
): T | false {
  const parts = [];
  let leafEntityType;

  for (const propertyInfo of enumeratePropertyPath(entityType, expandPath)) {
    if (
      propertyInfo.property &&
      propertyInfo.property.entityType &&
      !getIsNotExpandable(propertyInfo.property.entityType) &&
      !nonExpandableTypes.has(getInterfaceName(propertyInfo.property.entityType)) &&
      isUnrestrictedBreezeProperty(propertyInfo.property)
    ) {
      parts.push(propertyInfo.property.name);
      leafEntityType = propertyInfo.property.entityType;
    } else {
      if (warn) {
        addWarning(propertyInfo);
      }
      break;
    }
  }

  if (!leafEntityType) {
    return false;
  }

  return valueSelector(leafEntityType, parts.join(separator));

  function addWarning({
    entityType,
    property,
    propertyName,
  }: {
    entityType?: EntityType;
    property?: NavigationProperty;
    propertyName: string;
  }): void {
    let reason;
    /*! StartNoStringValidationRegion Warning message */
    if (!property || !property.entityType) {
      reason = "it is not a navigation property";
    } else if (getIsNotExpandable(property.entityType)) {
      reason = `${getInterfaceName(property.entityType)} is not an expandable entity type`;
    } else {
      reason = "of security restrictions";
    }
    log.warning(`Cannot expand "${entityType ? getInterfaceName(entityType) : ""}.${propertyName}" because ${reason}.`);
    /*! EndNoStringValidationRegion */
  }
}

export function trimExpandPaths(expandPaths: string[], sortInfos: SortInfo[]): string[] {
  const hasComplexSorts =
    sortInfos && sortInfos.some((s) => sortColumnService.getSortNames(s.column).some(isComplexPath));
  const maxExpandCount = WCFExpandPathsLimit - (hasComplexSorts ? 1 : 0);
  return mergeExpandPaths(expandPaths).slice(0, maxExpandCount).map(applyExpandDepthLimit);
}

export function applyExpandDepthLimit(path: string): string {
  if (!path) {
    return "";
  }

  const normalizedPath = normalizeExpandPath(path);
  const arr = normalizedPath.split(separator);

  return arr.length <= ExpandDepthLimit ? normalizedPath : arr.slice(0, ExpandDepthLimit).join(separator);
}

export function normalizeExpandPath(path: string): string {
  return path.replace(/\//g, separator);
}
