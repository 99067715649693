export class StorageError extends Error {
  override readonly name = "StorageError";
  readonly type: StorageErrorType;
  readonly innerError: unknown;

  constructor(message: string, innerError: unknown, type = StorageErrorType.Unknown) {
    super(message);
    this.innerError = innerError;
    this.type = type;
  }
}

export enum StorageErrorType {
  Unknown,
  Write,
}
