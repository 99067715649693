import { loadBusinessRuleAsync, tryGetBusinessRule } from "ModuleLoader";

type UnknownFunction = (...args: unknown[]) => unknown;

export interface RuleFunction extends UnknownFunction {
  isLoaded: () => boolean;
  loadAsync: () => Promise<UnknownFunction>;
}

interface RuleFunctionInternals extends RuleFunction {
  _func: string | UnknownFunction;
}

function wrap(func: string | UnknownFunction): RuleFunction {
  const wrapper: RuleFunctionInternals = function (this: unknown, ...args: unknown[]) {
    const func = getFunc(wrapper);
    if (typeof func === "function") {
      return func.apply(this, args);
    }

    return (async (): Promise<unknown> => {
      const actualFunc = await func;
      return actualFunc.apply(this, args);
    })();
  };

  wrapper._func = func;
  wrapper.isLoaded = isLoaded;
  wrapper.loadAsync = loadAsync;

  return wrapper;
}

function getFunc(wrapper: RuleFunctionInternals): UnknownFunction | Promise<UnknownFunction> {
  const func = wrapper._func;
  if (typeof func === "function") {
    return func;
  }

  const index = func.lastIndexOf(".");
  const resourceName = func.substring(0, index);
  const functionName = func.substring(index + 1);
  const rules = tryGetBusinessRule(resourceName);

  if (rules) {
    return getFuncCore(wrapper, rules, resourceName, functionName);
  }

  return (async (): Promise<UnknownFunction> => {
    const rules = await loadBusinessRuleAsync(resourceName);
    return getFuncCore(wrapper, rules, resourceName, functionName);
  })();
}

function getFuncCore(
  wrapper: RuleFunctionInternals,
  rules: Record<string, unknown> | undefined,
  resourceName: string,
  functionName: string
): UnknownFunction {
  const func = rules?.[functionName];
  const type = typeof func;
  if (type !== "function") {
    throw new Error(`${resourceName}.${functionName} is not a function. Its type is '${type}'.`);
  }

  return (wrapper._func = func as UnknownFunction);
}

function isLoaded(this: RuleFunctionInternals): boolean {
  return typeof getFunc(this) === "function";
}

async function loadAsync(this: RuleFunctionInternals): Promise<UnknownFunction> {
  const result = await getFunc(this);
  return result;
}

export default function getRuleFunction(func: string | UnknownFunction): RuleFunction;
/** @deprecated If you don't have a string or function then don't call this. */
export default function getRuleFunction<T>(func: T): T;
export default function getRuleFunction<T>(func: string | UnknownFunction | T): RuleFunction | T {
  const type = typeof func;
  return type === "string" || type === "function" ? wrap(func as string | UnknownFunction) : (func as T);
}
