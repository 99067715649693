import Promise from 'bluebird';
import ko from 'knockout';
import RuleVertex from 'RuleVertex';

export default class CalculatedPropertyVertex extends RuleVertex {
	constructor(context, cacheDependencyFunc) {
		super(context.entity, context.rule);
		this._context = context;
		this._disposed = false;
		this._cacheDependencyComputed = cacheDependencyFunc
			? createComputed(this, cacheDependencyFunc)
			: undefined;
	}

	dispose() {
		this._disposed = true;
		this._cacheDependencyComputed && this._cacheDependencyComputed.dispose();
	}

	isValid() {
		return !this._disposed && super.isValid();
	}

	reportChangedAsync() {
		return Promise.try(() => {
			this.wireDependencies();
			return this._context.handleChanged();
		}).return(undefined);
	}
}

function createComputed(vertex, cacheDependencyFunc) {
	let valueHolder;
	return ko
		.computed(() => {
			if (!vertex.isValid()) {
				return;
			}

			const { entity } = vertex;
			const value = cacheDependencyFunc(entity);
			if (!valueHolder) {
				valueHolder = { value };
			} else if (valueHolder.value !== value) {
				valueHolder.value = value;
				vertex._context.handleChanged();
			}
		})
		.extend({ deferred: true });
}
