export default class KOComponentProxy {
	constructor($element) {
		if (!$element) {
			throw new Error('Must provide an element');
		}

		this.invoke = function () {
			const params = Array.prototype.slice.call(arguments);
			return $element.component(...params);
		};
	}
}
