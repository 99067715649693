const { DeferredPromise } = require('DeferredPromise');

const Q = {
    // eslint-disable-next-line rulesdir/async-function-suffix
    resolve(value) {
        return Promise.resolve(value);
    },
    // eslint-disable-next-line rulesdir/async-function-suffix
    reject(reason) {
        return Promise.reject(reason);
    },
    defer() {
        return new DeferredPromise();
    }
};

module.exports = Q;