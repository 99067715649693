import Promise from 'bluebird';
import captionService from 'CaptionService';
import documentService from 'DocumentService';
import errorHandler from 'ErrorHandler';
import navigationService from 'NavigationService';

async function getDocumentMenuItemsAsync(entity, saveableContext) {
	if (!entity) {
		return [];
	}

	if (saveableContext && saveableContext.hasChanges()) {
		const isSaved = await saveableContext.saveWithPromptAsync();
		if (!isSaved) {
			// Will be updated in WI00454068 to use errorMessage.
			return [
				{
					label: captionService.getString(
						'3e68c9df-cc02-4be2-8740-8e4f30c4c197',
						'Please save before using documents.'
					),
				},
			];
		}

		return getDocumentMenuItemsAsync(entity, saveableContext);
	}
	try {
		const documents = await documentService.getDocumentsAsync(entity);
		if (!documents.length) {
			// Will be updated in WI00454068 to use errorMessage.
			return [
				{
					label: captionService.getString(
						'd643943c-362f-4a29-9d78-0f554f0f35e3',
						'No documents found.'
					),
				},
			];
		}

		return createMenuItems(documents);
	} catch (error) {
		/*! SuppressStringValidation user error description for Devs only */
		errorHandler.reportError(error, 'Loading documents Error');
		// Will be updated in WI00454068 to use errorMessage.
		return [
			{
				label: captionService.getString(
					'041a6958-9708-4e52-810f-6f80293dc4d7',
					'Failed to retrieve documents. Please contact your System Administrator.'
				),
			},
		];
	}
}

function createMenuItems(documents) {
	return documents.map((document) => {
		const action = {
			label: document.Name
		};

		const isGroupHeader = document.items && document.items.length > 0;
		if (isGroupHeader) {
			const createMenuItemsAction = createMenuItems.bind(null, document.items);
			action.loadItemsAsync = () => Promise.resolve(createMenuItemsAction());
		} else {
			action.onClick = showDocument.bind(null, document);
		}

		return action;
	});
}

function showDocument(document) {
	navigationService.post('#/document/showDocument', {
		name: document.Name,
		id: document.Id,
		tableCode: document.tableCode,
		entityPk: document.entityPK,
		downloadOnly: document.DownloadOnly
	});
}

export default { getDocumentMenuItemsAsync, showDocument };
