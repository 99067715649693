import type PageViewModel from "PageViewModel";
import { inject, provide } from "vue";

export const pageViewModelInjectionKey = Symbol("pageViewModel");

export function usePageViewModel(): PageViewModel | undefined {
  return inject<PageViewModel | undefined>(pageViewModelInjectionKey, undefined);
}

export function setPageViewModel(value: PageViewModel): void {
  provide(pageViewModelInjectionKey, value);
}
