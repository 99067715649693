import { trackBusyStateAsync } from 'GlobalBusyStateTracker';
import navigationService from 'NavigationService';
import pages from 'Pages';
import uiService from 'UIService';
import userAgreementManager from 'UserAgreementManager';
import userSession from 'UserSession';

class UserAgreementController {
	checkModule(context, callback) {
		if (!navigationService.routeRequiresAgreement(context) || !userSession.isLoggedOn()) {
			callback();
		} else {
			const promise = (async () => {
				const result = await userAgreementManager.checkModuleAsync();
				if (result.isRequired) {
					return await uiService.loadPageAsync(pages.UserAgreement, {
						isAccessDenied: true,
						skipShowHelpInstructions: true,
						viewModel: result.viewModel
					});
				} else {
					callback();
				}
			})();

			trackBusyStateAsync(promise);
		}
	}
}

export default new UserAgreementController();
