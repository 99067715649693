import captionService from 'CaptionService';
import Constants from 'Constants';
import guid from 'GuidGenerator';
import base64 from 'base64';
import ko from 'knockout';

function GroupBoxViewModel(params) {
	this._title = ko.isObservable(params.title) ? params.title : ko.observable(params.title);
	this._headerDisplayMode = ko.isObservable(params.headerDisplayMode) ? params.headerDisplayMode : ko.observable(params.headerDisplayMode);
	this.headerAlignment = params.headerAlignment || Constants.HeaderAlignment.Left;
	this.titleTemplateId = params.titleTemplateId;
	this.isCollapsible = getBooleanValue(params.isCollapsible);
	this.isCollapsed = getBooleanValue(params.isCollapsed);
	this.showCollapsibleIcon = getBooleanValue(params.showCollapsibleIcon);
	this.controlID = params.fieldId;
	this.collapsibleID = this.isCollapsible ? guid.newGuid() + '-gwGroupBox-collapsible' : '';
	this.anchor = params.anchor;
	this.backgroundImagePk = params.backgroundImagePk;
	this.backgroundImageOverride = ko.observable(params.backgroundImageOverride ? base64.toByteArray(params.backgroundImageOverride) : null);
	this.backgroundImageOverrideSrc = ko.pureComputed(getBackgroundImageOverrideSrc.bind(null, this));

	this.title = ko.pureComputed({
		read: getTitle.bind(null, this),
		write: setTitle.bind(null, this),
	});

	this.headerDisplayMode = ko.pureComputed({
		read: getHeaderDisplayMode.bind(null, this),
		write: setHeaderDisplayMode.bind(null, this),
	});

	this.isHeaderHidden = ko.pureComputed(getIsHeaderHidden.bind(null, this));
}

function getTitle(vm) {
	const title = vm._title();
	return typeof title === 'object' ? captionService.getStringFromInfo(title) : title;
}

function setTitle(vm, value) {
	vm._title(value);
}

function getHeaderDisplayMode(vm) {
	return vm._headerDisplayMode();
}

function setHeaderDisplayMode(vm, value) {
	vm._headerDisplayMode(value);
}

function getIsHeaderHidden(vm) {
	return vm._headerDisplayMode() === Constants.HeaderDisplayModes.Hidden;
}

function getBooleanValue(value) {
	return value === true || value === 'true';
}

function getBackgroundImageOverrideSrc(vm) {
	const backgroundImageOverride = vm.backgroundImageOverride();
	const src = backgroundImageOverride instanceof Uint8Array ? base64.fromByteArray(backgroundImageOverride) : backgroundImageOverride;

	return src ? 'data:image/jpeg;base64,' + src : null;
}

export default GroupBoxViewModel;
