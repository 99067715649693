/*! StartNoStringValidationRegion No captions in this file */
import breeze, {
  type Entity,
  type OpConfig,
  type PredicateContext,
  type PredicateExpression,
  type PredicateVisitor,
} from "breeze-client";

export class BooleanPredicate extends breeze.Predicate {
  override visitorMethodName = "litExpr";
  readonly dataType = breeze.DataType.Boolean;
  readonly op: OpConfig = { key: "bool", aliases: [] };

  constructor(readonly value: boolean) {
    super();
  }

  _validate(): void {}
}

export class IsNullPredicate extends breeze.Predicate {
  override visitorMethodName = "binaryPredicate";
  readonly expr1: PredicateExpression;
  readonly expr2: PredicateExpression;
  readonly op: OpConfig;

  constructor(propertyPath: string, isNull: boolean) {
    super();
    this.op = { key: isNull ? "eq" : "ne", aliases: [] };
    this.expr1 = new breeze.PropExpr(propertyPath);
    this.expr2 = new breeze.LitExpr(null, breeze.DataType.Undefined);
  }

  _validate(): void {}
}

export class TypeOfPredicate extends breeze.Predicate {
  exprs?: PredicateExpression[];
  override visitorMethodName = "fnExpr";
  readonly fnName = "isof";
  readonly op: OpConfig = { key: this.fnName, aliases: [] };

  constructor(private readonly propertyPath: string, private readonly type: string) {
    super();
  }

  localFn(entity: Entity | undefined, type: string): boolean {
    if (!entity) {
      return false;
    }

    let candidateType = entity.entityType;
    const desiredType = candidateType.metadataStore.getEntityType(type);

    do {
      if (candidateType === desiredType) {
        return true;
      }
      candidateType = candidateType.baseEntityType;
    } while (candidateType);

    return false;
  }

  override visit(context: PredicateContext, visitor?: PredicateVisitor): unknown {
    const exprs: PredicateExpression[] = [];
    if (isFunctionVisitor(visitor ?? context.visitor!)) {
      if (this.propertyPath) {
        exprs.push(new breeze.PropExpr(this.propertyPath));
      } else {
        exprs.push(entityFnExpr);
      }
      exprs.push(new breeze.LitExpr(this.type, breeze.DataType.String));
    } else {
      if (context.prefix) {
        exprs.push(new breeze.LitExpr(context.prefix, breeze.DataType.Undefined));
        context = { ...context };
        context.prefix = undefined;
      }
      if (this.propertyPath) {
        exprs.push(new breeze.PropExpr(this.propertyPath));
      }
      exprs.push(new breeze.LitExpr(getTypeName(context, this.type), breeze.DataType.String));
    }
    this.exprs = exprs;

    return super.visit(context, visitor!);
  }

  _validate(): void {}
}

const entityFnExpr: PredicateExpression = {
  _validate() {},

  visit() {
    return (entity: Entity) => entity;
  },
};

function getTypeName(context: PredicateContext, type: string): string {
  if (!context.entityType) {
    return `'${type}'`;
  }

  const entityType = context.entityType.metadataStore.getEntityType(type);
  return `${entityType.namespace}.${entityType.shortName}`;
}

const functionVisitorMap = new WeakMap<PredicateVisitor, boolean>();
function isFunctionVisitor(visitor: PredicateVisitor): boolean {
  let result = functionVisitorMap.get(visitor);
  if (result === undefined) {
    const expr = new breeze.LitExpr(null, breeze.DataType.Undefined, true);
    result = typeof visitor.litExpr.call(expr) === "function";
    functionVisitorMap.set(visitor, result);
  }

  return result;
}
/*! EndNoStringValidationRegion */
