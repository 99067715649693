import ko from 'knockout';
import global from 'Global';
import shortcutService from 'ShortcutService';
import tooltipService from 'TooltipService';

function shouldOmitTooltip() {
	return global.isPortable();
}

ko.bindingHandlers.gwTooltip = {
	init(element) {
		if (!shouldOmitTooltip()) {
			tooltipService.createTooltipWidget(element);
			shortcutService.registerTooltipsShow();
		}
	},
	update(element, valueAccessor) {
		if (shouldOmitTooltip()) {
			return;
		}

		const argument = ko.unwrap(valueAccessor());
		if (!argument) {
			return;
		}

		if (typeof argument === 'string') {
			tooltipService.populateTooltip(element, argument);
		} else {
			if (argument.shortcut) {
				const disposable = shortcutService.bindToElement(element, argument.shortcut);
				ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
					if (disposable) {
						disposable.dispose();
					}
				});
			}
			tooltipService.populateTooltip(
				element,
				argument.tooltip,
				argument.title,
				shortcutService.getTooltipCaption(argument.shortcut),
				argument.placement,
				argument.isError,
				argument.tooltipCssClass);
		}
	}
};
