import Promise from 'bluebird';
import filterEvaluator from './FilterEvaluator';
import ModelProvider from 'ModelProvider';
import activeStateDecorator from 'Shared/Filters/EntityActiveStateDecorator';

export default class EntityQueryLookupProvider {
	constructor(args) {
		this.ensurePreconditions = args.ensurePreconditions;
		this.extraInfo = args.extraInfo;
		this.itemType = args.itemType;
		this.filter = args.filter;
		this.advancedFilter = args.advancedFilter;
		this.valuePath = args.valuePath;
		this.hasContextlessFilter = args.hasContextlessFilter;
		this._entityName = args.entityName;
		this.invokers = {
			getQueryAsync,
			decorateActiveState: activeStateDecorator.decorateQuery,
			getMetadataStoreAsync,
		};
	}

	get isDataServiceQuery() {
		return true;
	}

	getLookupRecordsAsync(entity, queryDecorator, searchKeyword, modelProvider) {
		const self = this;
		return getLookupRecordsAsync(this, entity, modelProvider, (query, metadataStore) => {
			const entityType = metadataStore.getEntityType(self.itemType);

			return Promise.resolve(queryDecorator ? queryDecorator(query, entityType) : query).then((query) => {
				return self.invokers.decorateActiveState(query, entityType);
			});
		});
	}

	getRecordAsync(entity, value, queryDecorator, modelProvider) {
		const self = this;
		const queryDecoratorWrapper = (query, metadataStore) => {
			query = query.where(self.valuePath, '==', value).take(1);
			if (queryDecorator) {
				query = queryDecorator(query, metadataStore.getEntityType(self.itemType));
			}

			return query;
		};

		return getLookupRecordsAsync(this, entity, modelProvider, queryDecoratorWrapper).then((data) => {
			return data.results.length > 0 ? data.results[0] : null;
		});
	}
}

function getBlankQueryResult() {
	return { inlineCount: 0, results: [] };
}

function getLookupRecordsAsync(self, entity, modelProvider, queryDecorator) {
	const shouldApplyFilter = (self.hasContextlessFilter && typeof self.filter !== 'function') || (entity?.entityAspect && entity?.entityType?.interfaceName === self._entityName);
	let queryPromise;
	if (shouldApplyFilter) {
		queryPromise = Promise.resolve(!self.ensurePreconditions || self.ensurePreconditions(entity, self.extraInfo)).then((preconditionsMet) => {
			if (preconditionsMet) {
				return self.invokers.getQueryAsync(self.itemType, modelProvider).then((query) => {
					return applyFilterAsync(self, query, entity, modelProvider);
				});
			}
		});
	}
	else {
		queryPromise = self.invokers.getQueryAsync(self.itemType, modelProvider);
	}

	return queryPromise
		.then((query) => {
			if (query) {
				return self.invokers.getMetadataStoreAsync(self, query, modelProvider)
					.then((metadataStore) => {
						return queryDecorator(query, metadataStore);
					});
			}
		})
		.then((query) => {
			return query ? query.execute() : getBlankQueryResult();
		});
}

function getQueryAsync(itemType, modelProvider) {
	modelProvider = modelProvider || new ModelProvider();
	return modelProvider.createEntityManagerAsync(itemType)
		.then((entityManager) => {
			return entityManager.createQuery(itemType).inlineCount(true);
		});
}

function getMetadataStoreAsync(self, query) {
	return Promise.resolve(query.entityManager.metadataStore);
}

function applyFilterAsync(self, query, entity, modelProvider) {
	if (typeof self.filter === 'function') {
		return Promise.resolve(self.filter(entity, self.extraInfo)).then((predicate) => {
			if (predicate) {
				// Predicate is optional.
				query = query.where(predicate);
			}
			return query;
		});
	}
	else if (self.filter) {
		return self.invokers.getMetadataStoreAsync(self, query, modelProvider)
			.then((metadataStore) => {
				const itemType = metadataStore.getEntityType(self.itemType);
				return filterEvaluator.getPredicateAsync(self.filter, entity, itemType).then((predicate) => {
					// Predicate is mandatory.
					return predicate ? query.where(predicate) : null;
				});
			});
	}

	return Promise.resolve(query);
}
