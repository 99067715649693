import { isNullOrWhitespace } from "StringUtils";

export default class LookupListItem {
  constructor(
    public readonly code: string | undefined | null,
    public readonly description?: string,
    public readonly iconFontCode?: string,
    public readonly sentiment?: string,
  ) {}

  get codeAndDescription(): string {
    let result = this.code ?? "";

    if (!isNullOrWhitespace(this.description)) {
      result += " - " + this.description;
    }

    return result;
  }

  isEmptyPair(): boolean {
    return isNullOrWhitespace(this.code) && isNullOrWhitespace(this.description);
  }
}
