import Promise from 'bluebird';
import global from 'Global';
import _ from 'underscore';
import ajaxService from 'AjaxService';
import captionService from 'CaptionService';
import languageService from 'LanguageService';
import ruleService from 'RuleService';
import lookupRuleEngine from 'LookupRuleEngine';

let cachedLanguageCode;
let listCache;
const rules = {};

rules.getDescriptionLookupAsync = async (entityName, propertyPath, entity, value) => {
	const rules = ruleService.get(entityName);
	const rule = rules.lookupRule(propertyPath);

	const lookupList = await lookupRuleEngine.getValueAsync(entity, rule);
	const lookupItem = _.findWhere(lookupList, { code: value });
	const description = lookupItem ? lookupItem.description : '';

	return description;
};

rules.getLookupListByIdAsync = (id) => {
	if (id) {
		id = id.replace(/-/g, '');
		return getListByIdAsync(id);
	}

	return Promise.resolve(null);
};

function getDescriptionText(description) {
	return description ? captionService.getString(description.resKey, description.text) : null;
}

function getListByIdAsync(id) {
	const currentLanguageCode = languageService.getLanguageCode();

	if (cachedLanguageCode !== currentLanguageCode || !listCache) {
		listCache = Promise.dictionary();
		cachedLanguageCode = currentLanguageCode;
	}

	return listCache.getOrAddAsync(id, getListByIdCoreAsync);
}

async function getListByIdCoreAsync(id) {
	/*! SuppressStringValidation Service URI. */
	const list = await ajaxService.getAsync(global.serviceUri + 'api/config/lookups/' + id);
	return list.items.map((item) => ({
		code: item.code,
		description: getDescriptionText(item.description),
		iconFontCode: item.iconFontCode,
		sentiment: item.sentiment,
	}));
}

export default rules;
