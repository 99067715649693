import errors from 'Errors';
import { decoratedWaitAvailableOne, getAvailableResult, operationTypes } from './Utils';

const decorationInfo = Object.freeze({
	operationType: operationTypes.Boolean,
	operandCount: 1,
	booleanResult: true,
});

function and(left, right, data, context) {
	return decoratedWaitAvailableOne(
		left,
		data,
		context,
		(left) => {
			return left !== true ? getAvailableResult(left) : right(data, context);
		},
		decorationInfo
	);
}

function coalesce(value, data, context) {
	return decoratedWaitAvailableOne(
		value,
		data,
		context,
		(value) => {
			if (typeof value === 'boolean') {
				return getAvailableResult(value);
			} else if (value == null) {
				return getAvailableResult(false);
			} else {
				throw new errors.DependencyError(
					'Encountered a boolean expression that evaluated to a non-boolean value.'
				);
			}
		},
		decorationInfo
	);
}

function not(value, data, context) {
	return decoratedWaitAvailableOne(
		value,
		data,
		context,
		(value) => {
			if (value != null && typeof value !== 'boolean') {
				throw new errors.DependencyError('! operator can only be used on a boolean value.');
			} else {
				return getAvailableResult(!value);
			}
		},
		decorationInfo
	);
}

function or(left, right, data, context) {
	return decoratedWaitAvailableOne(
		left,
		data,
		context,
		(left) => {
			if (left !== false && left != null) {
				return getAvailableResult(left);
			} else {
				return right(data, context);
			}
		},
		decorationInfo
	);
}

export default { and, coalesce, not, or };
