import captionService from 'CaptionService';
import constants from 'Constants';
import global from 'Global';
import widgetFactory from 'WidgetFactory';
import Promise from 'bluebird';
import $ from 'jquery';

export default class WidgetUtils {
	static decorateMarginPadding(element, margin, padding) {
		const $element = $(element);
		if (typeof margin === 'number') {
			$element.css('margin', margin + 'px');
		}

		if (typeof padding === 'number') {
			$element.css('padding', padding + 'px');
		}
	}

	static decorateMaxLength(element, maxLength) {
		maxLength = typeof maxLength === 'string' ? parseInt(maxLength, 10) : maxLength;
		$(element).attr('maxlength', isNaN(maxLength) || maxLength < 0 ? null : maxLength);
	}

	static decorateReadOnly(element, isReadOnly) {
		const $element = $(element);
		if ($element.is('input,textarea')) {
			/*! SuppressStringValidation prop name */
			$element.prop('readonly', isReadOnly);

			WidgetUtils.decorateTabBehaviour(element, isReadOnly);
		}
	}

	static decorateTabBehaviour(element, disableTab) {
		const $element = $(element);
		const tabindex = $element.attr('tabindex');
		const tabindexOverride = $element.data('tabindexOverride');

		if (disableTab && tabindex !== '-1') {
			if (tabindex && !tabindexOverride) {
				$element.data('tabindexOverride', tabindex);
			}

			$element.attr('tabindex', -1);
		} else if (!disableTab && tabindex === '-1') {
			if (tabindexOverride) {
				$element.attr('tabindex', tabindexOverride);
				$element.removeData('tabindexOverride');
			} else {
				$element.removeAttr('tabindex');
			}
		}
	}

	static triggerFocus($element, isInitial) {
		const funcName = 'setFocus';
		if ($element.hasComponentMethod(funcName)) {
			$element.component(funcName, isInitial);
		} else {
			$element.trigger('focus', { initialFocus: isInitial });
		}

		return $element;
	}

	static setInitialFocus(container) {
		const $container = $(container);
		let $initialFocus = $container.find('[data-initial-focus="true"]:first');
		if ($initialFocus.length === 0) {
			if (global.formFactor === global.formFactors.Desktop) {
				/*! SuppressStringValidation String validation suppressed in initial refactor */
				$initialFocus = $container.find(
					':input:enabled:visible:not(button):not([data-prevent-initial-focus="true"]):not([readonly]):first'
				);
				if ($initialFocus.length === 0) {
					$initialFocus = $container.find('[data-default="true"]:first');
				}
			} else {
				$initialFocus = $container.find('[data-default="true"]:first');
			}
		}

		if ($initialFocus.length === 1) {
			if (!$initialFocus.data('role')) {
				const widgetName = widgetFactory.getComponentName($initialFocus);
				if (widgetName) {
					$initialFocus = $initialFocus.find('[data-ko-widget=' + widgetName + ']');
				}
			}

			WidgetUtils.triggerFocus($initialFocus, true);
		}
	}

	static getElementToDecorateForValidation($element) {
		const methodName = 'getElementToDecorateForValidation';
		let $elementToDecorate =
			$element.hasComponentMethod(methodName) && $element.component(methodName);
		if (!$elementToDecorate || $elementToDecorate.length === 0) {
			$elementToDecorate = $element;
		}

		return $elementToDecorate;
	}

	static getLabelForElement($element) {
		const methodName = 'getCaptionElement';
		let $label = $element.hasComponentMethod(methodName) && $element.component(methodName);

		if (!$label) {
			$label = $("label[for='" + $element.attr('id') + "']");
			if ($label.length === 0) {
				$label = $("label[for='" + $element.attr('data-input-id') + "']");
				if ($label.length === 0) {
					const $parent = $element.parent();
					if ($parent.length === 1 && $parent[0].nodeName.toLowerCase() === 'label') {
						$label = $parent;
					}
				}
			}
		}

		return $label;
	}

	/**
	 * @function: getBytesWithUnit()
	 * @purpose: Converts bytes to the most simplified unit.
	 * @param: (number) bytes, the amount of bytes
	 * @returns: (string)
	 */
	static getBytesWithUnit(bytes, precision) {
		if (isNaN(bytes) || !bytes || bytes === 0) {
			return captionService.getString('f8f3290b-cc94-466a-a177-8482af07741b', 'N/A');
		}

		/*! SuppressStringValidation String validation suppressed in initial refactor */
		const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
		const calculation = bytes / Math.pow(1024, i);
		return (i === 0 ? calculation : calculation.toFixed(precision)) + ' ' + units[i];
	}

	static removeLineBreaks(inputStr) {
		inputStr = inputStr.replace(/(\r\n|\n|\r)/gm, ' ');
		return inputStr.replace(/\s+/g, ' ');
	}

	static setPickerPosition($element, $picker, dropAboveCssClass, $scrollViewport) {
		const useViewportOverride = $scrollViewport && $scrollViewport.length;
		$scrollViewport = useViewportOverride ? $scrollViewport : $(window);

		const elementWidth = $element.outerWidth();
		const elementHeight = $element.outerHeight();
		const pickerWidth = $picker.outerWidth();
		const pickerHeight = $picker.outerHeight();

		const position = useViewportOverride ? $element.position() : $element.offset();
		const adjustedPosition = $.extend({}, position, {
			top: position.top + elementHeight - 3,
		});
		const aboveNow = $picker.hasClass(dropAboveCssClass);
		let above = false;

		const screen = {
			scroll: {
				left: $scrollViewport.scrollLeft(),
				top: $scrollViewport.scrollTop(),
			},
			width: $scrollViewport.width(),
			height: $scrollViewport.height(),
		};

		const overflow = {
			right: adjustedPosition.left + pickerWidth >= screen.width + screen.scroll.left,
			top: position.top - pickerHeight < screen.scroll.top,
			bottom: adjustedPosition.top + pickerHeight >= screen.height + screen.scroll.top,
		};

		if (overflow.right) {
			const proposedLeftPosition = position.left - pickerWidth + elementWidth;
			const proposedOverflowLeft =
				proposedLeftPosition < 0 || proposedLeftPosition < screen.scroll.left;
			if (!proposedOverflowLeft) {
				adjustedPosition.left = proposedLeftPosition;
			}
		}

		if ((aboveNow || overflow.bottom) && !overflow.top) {
			const proposedTopPosition = position.top - pickerHeight - 3;
			if (proposedTopPosition >= 0) {
				above = true;
				adjustedPosition.top = proposedTopPosition;
			}
		}

		$picker.toggleClass(dropAboveCssClass, above).css(adjustedPosition);
	}

	static outerHtml($element) {
		const $clone = $element.clone();
		$clone.wrap('<div>');
		return $clone.parent().html();
	}

	static waitInitAllWidgetsAsync($container) {
		if (!$container) {
			/*! SuppressStringValidation error message for developers only */
			throw 'Container must not be null or undefined';
		}

		let $components = $container.find('[data-ko-widget]');
		if ($container.is('[data-ko-widget]')) {
			$components = $components.add($container);
		}

		const promises = $components
			.map((_, element) => {
				const $element = $(element);
				const waitingForDataItem = Promise.resolve(
					$element.component('waitForDataItemAsync')
				).then((dataItem) => {
					const isReadOnly =
						dataItem && dataItem.entityAspect && dataItem.entityAspect.isReadOnly;
					if (isReadOnly && isReadOnly.getState() === constants.States.NotLoaded) {
						return isReadOnly.loadAsync();
					}
				});

				return Promise.join(
					$element.component('waitForInitAsync'),
					waitingForDataItem
				).then(() => {
					const method = 'getIdleAwaiter';
					if ($element.hasComponentMethod(method)) {
						return $element.component(method);
					}
				});
			})
			.get();

		return Promise.all(promises);
	}

	static waitIdleAllWidgetsAsync($container) {
		return Promise.try(() => {
			let $components = $container.find('[data-ko-widget]');
			if ($container.is('[data-ko-widget]')) {
				$components = $components.add($container);
			}

			const promises = $components
				.map((_, element) => {
					const $element = $(element);
					const method = 'getIdleAwaiter';
					if ($element.hasComponentMethod(method)) {
						return $element.component(method);
					}
				})
				.get();

			return Promise.all(promises);
		});
	}
}
