import bindingEvaluator from 'BindingEvaluator';
import Promise from 'bluebird';
import captionService from 'CaptionService';
import configurationHelper from 'ConfigurationHelper';
import constants from 'Constants';
import $ from 'jquery';
import ko from 'knockout';
import Notifications from 'Notifications';
import notificationType from 'NotificationType';
import { getPageExtensions } from 'PageExtensions';
import { htmlEscape } from 'StringUtils';
import tooltipService from 'TooltipService';
import 'TooltipBindings';

(function () {
	ko.bindingHandlers.gwValidationTooltip = {
		init(element, valueAccessor) {
			ensureSameUltimateDataItem(valueAccessor());
			ko.bindingHandlers.gwTooltip.init(element);
		},
		update(element, valueAccessor, allBindings, viewModel, bindingContext) {
			ko.bindingHandlers.gwTooltip.update(element, valueAccessor);
			const tooltipOwnerKey = 'gwValidationTooltip';
			const $tooltip = tooltipService.getTooltipTarget(element);
			tooltipService.removeTooltipOverride($tooltip, tooltipOwnerKey);

			const tooltip = $tooltip.data('bs.tooltip');
			if (!tooltip) {
				return;
			}

			tooltip.options.html = true;
			const $tip = tooltip.tip();
			removeTooltipClass($tip);

			const validationOptions = getValidationOptions(valueAccessor(), viewModel);
			const captionOptions = allBindings.get('gwValidationTooltipOptions');
			addTooltip($tooltip, $tip, tooltipOwnerKey, validationOptions, bindingContext, captionOptions && captionOptions.ignoreFieldCaption);
		}
	};

	ko.bindingHandlers.gwValidationConfigTooltip = {
		init(element) {
			const $highlightElement = getHighlightConfigElement($(element));
			ko.bindingHandlers.gwTooltip.init($highlightElement[0]);
		},
		update(element, valueAccessor, allBindings, viewModel, bindingContext) {
			const $element = $(element);
			const designControlId = $element.component('getParam', 'designControlId');
			if (designControlId) {
				const $highlightElement = getHighlightConfigElement($element);
				ko.bindingHandlers.gwTooltip.update($highlightElement[0], valueAccessor);
				const currentConfigurableForm = configurationHelper.getConfigurationContext(bindingContext).currentConfigurableForm();

				const tooltipOwnerKey = 'gwValidationConfigTooltip';
				const $tooltip = tooltipService.getTooltipTarget($highlightElement);
				tooltipService.removeTooltipOverride($tooltip, tooltipOwnerKey);

				const tooltip = $tooltip.data('bs.tooltip');
				if (!tooltip) {
					return;
				}

				tooltip.options.html = true;
				const $tip = tooltip.tip();
				removeTooltipClass($tip);

				if (currentConfigurableForm) {
					const alerts = currentConfigurableForm.notifications.alerts(designControlId);
					addAlertsTooltip({ alerts }, $tooltip, $tip, tooltipOwnerKey);
				}
			}
		}
	};

	function addTooltip($tooltip, $tip, tooltipOwnerKey, options, bindingContext, ignoreFieldCaption) {
		const result = getValidationResult(options, bindingContext);
		addAlertsTooltip(result, $tooltip, $tip, tooltipOwnerKey, !ignoreFieldCaption && bindingContext.$fieldCaption);
	}

	function addAlertsTooltip(result, $tooltip, $tip, tooltipOwnerKey, fieldCaption) {
		if (result.alerts.length) {
			const messages = [];
			let maxLevel = 0;
			result.alerts.forEach((alert) => {
				let message = htmlEscape(alert.Text);
				if (ko.utils.unwrapObservable(alert.acknowledged)) {
					message = '<i class="icon-check-square-o"></i> <strong>Acknowledged!</strong> - ' + message;
				}

				messages.push(message);
				maxLevel = Math.max(alert.Level, maxLevel);
			});

			tooltipService.overrideTooltip($tooltip, tooltipOwnerKey, messages.join('<br/>'));
			addTooltipClass($tip, maxLevel);
		}
		else if (result.mainDataItem) {
			if (!tooltipService.getOriginalTooltip($tooltip)) {
				const tooltip = getPropertyTooltip(result.mainDataItem, result.mainPropertyName, fieldCaption);
				tooltipService.overrideTooltip($tooltip, tooltipOwnerKey, tooltip, tooltipService.Priority.Low);
			}
		}
	}

	function addTooltipClass($tip, level) {
		switch (level) {
			case notificationType.Error:
				$tip.addClass('tooltip-error');
				break;

			case notificationType.MessageError:
				$tip.addClass('tooltip-message-error');
				break;

			case notificationType.Warning:
				$tip.addClass('tooltip-warning');
				break;

			case notificationType.Information:
				$tip.addClass('tooltip-information');
				break;
		}
	}

	function removeTooltipClass($tip) {
		$tip.removeClass('tooltip-error tooltip-message-error tooltip-warning tooltip-information');
	}

	function getPropertyTooltip(dataItem, propertyName, fieldCaption) {
		if (!fieldCaption && propertyName && dataItem.entityType) {
			fieldCaption = captionService.getCaptionFromField({ entityName: dataItem.entityType.interfaceName, propertyName });
		}
		if (fieldCaption) {
			return {
				title: fieldCaption.longCaption,
				tooltip: fieldCaption.description
			};
		}
		else {
			return '';
		}
	}

	function getValidationResult(options, bindingContext) {
		const result = { alerts: [] };

		options.valuePaths.forEach((valuePath, index) => {
			const dataItem = bindingEvaluator.getUltimateDataItem(bindingContext, options.dataItem, valuePath);
			const propertyName = bindingEvaluator.getPropertyName(valuePath);

			if (index === 0) {
				result.mainDataItem = dataItem;
				result.mainPropertyName = propertyName;
			}

			if (dataItem) {
				const notifications = Notifications.get(dataItem);
				if (notifications) {
					result.alerts = result.alerts.concat(notifications.alerts(propertyName));
				}
			}
		});

		return result;
	}
})();

ko.bindingHandlers.validationHighlight = {
	init(element, valueAccessor, allBindings, viewModel, bindingContext) {
		const options = valueAccessor();
		ensureSameUltimateDataItem(options);

		const validationRegistrar = getPageExtensions(bindingContext).validationRegistrar;
		if (validationRegistrar) {
			const validationGetter = getBoundPropertiesAsync.bind(null, bindingContext, viewModel, options);
			validationRegistrar.registerBoundItem(validationGetter);

			ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
				validationRegistrar.unregisterBoundItem(validationGetter);
			});
		}
	},
	update(element, valueAccessor, allBindings, viewModel, bindingContext) {
		const $element = $(element);
		removeHighlight($element);
		removeAlertDataAttribs($element);

		const options = getValidationOptions(valueAccessor(), viewModel);
		addHighlightForDataItem($element, options.dataItem, options.valuePaths, bindingContext);
	}
};

ko.bindingHandlers.validationConfigHighlight = {
	init(element) {
		getHighlightConfigElement($(element)).addClass(constants.CssClasses.ConfigWidgetStyleValidation.Class);
	},
	update(element, valueAccessor, allBindings, viewModel, bindingContext) {
		const $element = $(element);
		const designControlId = $element.component('getParam', 'designControlId');
		if (designControlId) {
			const $highlightElement = getHighlightConfigElement($element);

			removeHighlight($highlightElement);
			removeAlertDataAttribs($highlightElement);

			addHighlightForConfig($element, $highlightElement, designControlId, bindingContext);
		}
	}
};

ko.bindingHandlers.validationHighlightForLevel = {
	update(element, valueAccessor) {
		const $element = $(element);
		removeHighlight($element);
		addHighlight($element, ko.unwrap(valueAccessor()));
	}
};

function addHighlight($element, level) {
	switch (level) {
		case notificationType.Information:
			$element.addClass('alert-info');
			break;

		case notificationType.Warning:
			$element.addClass('alert-warning');
			break;

		case notificationType.Error:
			$element.addClass('alert-danger');
			break;

		case notificationType.MessageError:
			$element.addClass('alert-message-error');
			break;
	}
}

function addHighlightForDataItem($element, rootDataItem, valuePaths, bindingContext) {
	let notifications;
	let maxLevel = 0;
	let alertEntityPK;
	const alertPropertyNames = [];

	for (let i = 0; i < valuePaths.length; i++) {
		const valuePath = valuePaths[i];

		if (i === 0) {
			const dataItem = bindingEvaluator.getUltimateDataItem(bindingContext, rootDataItem, valuePath);
			if (dataItem) {
				notifications = Notifications.get(dataItem);
			}
			if (!notifications) {
				break;
			}
		}

		const propertyName = bindingEvaluator.getPropertyName(valuePath);
		const alerts = notifications.alerts(propertyName);

		if (alerts.length > 0) {
			const level = notifications.level(propertyName);
			maxLevel = Math.max(level, maxLevel);
			alertPropertyNames.push(propertyName);

			if (!alertEntityPK) {
				alertEntityPK = alerts[0].entityPK;
			}
		}
	}

	addAlertDataAttribs($element, $element, alertPropertyNames, alertEntityPK, maxLevel);
}

function getBoundPropertiesAsync(bindingContext, viewModel, options) {
	const validationItem = getValidationOptions(options, viewModel);
	const boundItems = new Map();
	return Promise.map(validationItem.valuePaths, (valuePath) => {
		return bindingEvaluator.loadUltimateDataItemAsync(bindingContext, validationItem.dataItem, valuePath)
			.then((entity) => {
				if (entity && entity.entityAspect) {
					const propertyName = bindingEvaluator.getPropertyName(valuePath);
					const key = entity.entityAspect.getPrimaryKey();
					let boundItem = boundItems.get(key);
					if (!boundItem) {
						boundItem = { entity, propertyNames: [] };
						boundItems.set(key, boundItem);
					}
					if (boundItem.propertyNames.indexOf(propertyName) === -1) {
						boundItem.propertyNames.push(propertyName);
					}
				}
			});
	}).then(() => {
		return Array.from(boundItems.values());
	});
}

function getValidationOptions(options, viewModel) {
	let valuePath;
	let dataItem = viewModel;

	if (typeof options === 'object' && !Array.isArray(options)) {
		valuePath = options.valuePath;
		if ('dataItem' in options) {
			dataItem = ko.unwrap(options.dataItem);
		}
	}
	else {
		valuePath = options;
	}

	return {
		dataItem,
		valuePaths: Array.isArray(valuePath) ? valuePath : [valuePath]
	};
}

function ensureSameUltimateDataItem(options) {
	const valuePaths = getValidationOptions(options).valuePaths;

	if (valuePaths.length > 1) {
		let dataItemPath;
		valuePaths.forEach((valuePath) => {
			const nextDataItemPath = bindingEvaluator.getUltimateDataItemPathWithSeparator(valuePath);
			if (dataItemPath && nextDataItemPath !== dataItemPath) {
				throw new Error('All value paths must have the same ultimate data item.');
			}

			dataItemPath = nextDataItemPath;
		});
	}
}

function removeHighlight($element) {
	$element.removeClass('alert-info alert-warning alert-danger alert-message-error');
}

function removeAlertDataAttribs($element) {
	$element.removeAttr('data-alert-entity data-alert-property data-alert-control');
}

function addAlertDataAttribs($element, $highlightElement, alertPropertyNames, alertEntityPK, maxLevel) {
	if (alertPropertyNames.length) {
		addHighlight($highlightElement, maxLevel);

		$highlightElement.attr({
			'data-alert-entity': alertEntityPK,
			'data-alert-property': alertPropertyNames.join(' ')
		});

		/*! SuppressStringValidation 'id' attribute name */
		const $target = $element.attr('id') ? $element : $element.find('[id]');
		const alertControlId = $target.attr('id');
		if (alertControlId) {
			$highlightElement.attr('data-alert-control', alertControlId);
		}
	}
}

function getHighlightConfigElement($element) {
	if ($element.hasClass('gwGroupBox')) {
		return $element;
	}

	const component = $element.data('component.glow');
	if (component) {
		const $validationEl = component.$container.find(constants.CssClasses.ConfigWidgetStyleValidation.Selector);
		if ($validationEl.length) {
			return $validationEl;
		}

		const $widgetEl = component.$container.find('.g-widget-config-mode');
		if ($widgetEl.length) {
			return $widgetEl;
		}
	}

	return $element;
}

function addHighlightForConfig($element, $highlightElement, designControlId, bindingContext) {
	const currentConfigurableForm = configurationHelper.getConfigurationContext(bindingContext).currentConfigurableForm();
	if (currentConfigurableForm) {
		let maxLevel = 0;
		let alertEntityPK;
		const alertPropertyNames = [];
		const alerts = currentConfigurableForm.notifications.alerts(designControlId);

		if (alerts.length) {
			const level = currentConfigurableForm.notifications.level(designControlId);
			maxLevel = Math.max(level, maxLevel);
			alertPropertyNames.push(designControlId);

			if (!alertEntityPK) {
				alertEntityPK = alerts[0].entityPK;
			}
		}

		addAlertDataAttribs($element, $highlightElement, alertPropertyNames, alertEntityPK, maxLevel);
	}
}
