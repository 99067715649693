import { DeferredPromise } from "DeferredPromise";
import { trackBusyStateAsync } from "GlobalBusyStateTracker";

let navigationPromise: DeferredPromise<void> | undefined;

export function startNewNavigation(): void {
  if (!navigationPromise) {
    navigationPromise = new DeferredPromise();
    // We shouldn't await for this promise as it will block the execution completely.
    trackBusyStateAsync(navigationPromise.promise);
  }
}

export function resolveLastNavigation(): void {
  navigationPromise?.resolve();
  navigationPromise = undefined;
}
