import captionService from 'CaptionService';

export const colorScheme = {
    addRule: () => captionService.getString('4e3cadf2-5501-448e-ba0a-dc80f65ca0d8', 'Add Rule'),
    colorSchemeName: () => captionService.getString('11152f0e-3980-4cfa-b53b-7c89aa53a480', 'Color Scheme Name'),
    colorSchemeTitle: () => captionService.getString('68a4919c-0976-42e3-96bd-b9a0d44e3e3f', 'Color Scheme'),
    colorSchemesTitle: () => captionService.getString('3b2f617d-9c36-45ae-a0ab-8ace1e1813f3', 'Color Schemes'),
    delete: () => captionService.getString('e1b5ad1a-7846-4c33-962d-981933f2d32a', 'Delete'),
    loadSaved: () => captionService.getString('61598cc3-0016-4df9-90fa-30dd58a5088a', 'Load Saved'),
	header: () => captionService.getString('5b72e313-8d3e-4b5a-a33d-8eb8403e48f2', 'Column'),
	ruleLightModeColor: () => captionService.getString('f78fc185-ce03-4aca-9240-b3cd85f8b559', 'Color (Light Mode)'),
	ruleDarkModeColor: () => captionService.getString('e91c4564-38f1-458f-a834-a2c60634f412', 'Color (Dark Mode)'),
    ruleFilter: () => captionService.getString('105312ca-1df9-42b9-9edc-211e4c96171f', 'Filter'),
    ruleName: () => captionService.getString('0afe9fd5-fd60-466e-98b1-4622b388f278', 'Name')
};
