import constants from 'Constants';

export function areValuesEqual(a, b) {
	if (a == null) {
		return b == null;
	} else if (b == null) {
		return false;
	} else {
		return a.valueOf() === b.valueOf();
	}
}

export function getAvailableResult(value) {
	return getResult(constants.States.Available, value);
}

export function getResult(state, value) {
	return { state, value };
}

export function decoratedWaitAvailable(args, data, context, callback, decorationInfo) {
	return context.strategy.waitAvailable(
		args,
		data,
		context,
		decorateCallback(context, callback, decorationInfo)
	);
}

export function decoratedWaitAvailableOne(args, data, context, callback, decorationInfo) {
	return context.strategy.waitAvailableOne(
		args,
		data,
		context,
		decorateCallback(context, callback, decorationInfo)
	);
}

export function mapData(args, data, context, mapper, decorationInfo) {
	return mapDataCore(context.strategy.waitAvailable, args, data, context, mapper, decorationInfo);
}

export function mapDataOne(arg, data, context, mapper, decorationInfo) {
	return mapDataCore(
		context.strategy.waitAvailableOne,
		arg,
		data,
		context,
		mapper,
		decorationInfo
	);
}

function mapDataCore(wait, args, data, context, mapper, decorationInfo) {
	mapper = decorateCallback(context, mapper, decorationInfo);

	if (Array.isArray(data)) {
		return context.strategy.map(
			data,
			(item) => wait(args, item, context, mapper),
			(items) => {
				const result = [];
				for (let i = 0; i < items.length; i++) {
					const item = items[i];
					if (item.state === constants.States.NotLoaded) {
						return item;
					} else if (item.state === constants.States.Available) {
						result.push(item.value);
					}
				}
				return getAvailableResult(result);
			}
		);
	} else {
		return wait(args, data, context, mapper);
	}
}

function decorateCallback(context, callback, decorationInfo) {
	if (context.options.decorate) {
		return context.options.decorate(callback, decorationInfo);
	}
	return callback;
}

export const operationTypes = Object.freeze({
	Boolean: 'Boolean',
	NotEquals: 'NotEquals',
	Comparison: 'Comparison',
	Method: 'Method',
});
