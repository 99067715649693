import captionService from "CaptionService";

export const errorReporting = {
  continue: (): string => captionService.getString("d0b701a6-95d0-4073-bd47-cbc8fdf71789", "Continue"),
  defaultMessage: (): string =>
    captionService.getString(
      "31827c27-0685-4997-85a8-590769c51aa9",
      "Please describe what you were doing when this occurred. The details will be sent to WiseTech Global for investigation."
    ),
  environmentMessage: (): string =>
    captionService.getString(
      "abc8b9b8-1f6b-4315-88f1-1058ce655336",
      "IMPORTANT: If this error occurred due to the changes you are testing in UAT, please discuss it with the developer before submitting."
    ),
  pleaseDescribeMessage: (): string =>
    captionService.getString(
      "fd3bb06d-3ff3-4a44-aa39-869733018d99",
      "Please describe, in as much detail as possible, what you were doing prior to this error occurring."
    ),
  minLength: (): string =>
    captionService.getString(
      "bed67171-8cb3-4d96-8578-b64fdea358df",
      "You must enter at least 10 characters."
    ),
  errorResolutionMessage: (): string =>
    captionService.getString(
      "5d3b65ba-0af8-4306-84de-78568c75b770",
      "By doing this, WiseTech Global will be able to provide a resolution much more quickly and prevent this error from happening again."
    ),
  technicalDetailLink: (): string =>
    captionService.getString("832756b3-517b-427e-a7f2-1330c0bc072a", "Show Technical Error Detail (Development only)"),
  title: (): string => captionService.getString("dbda3f77-8547-4aa8-a8c0-5684f84da00a", "An Error Has Occurred"),
};
