import Promise from 'bluebird';
import ko from 'knockout';
import bindingEvaluator from 'BindingEvaluator';
import { disableBinding as disableReadOnlyBinding } from 'gwReadOnly';
import { commaSeparate, isEmptyGuid, format } from 'StringUtils';
import widgetUtils from 'WidgetUtils';
import captionDecorator from 'Shared/KOCaptionComponentDecorator';
import readRestrictionDecorate from 'Shared/KOReadRestrictionComponentDecorator';
import validationDecorator from 'ValidationDecorator';
import readOnlyEvaluator from 'ReadOnlyEvaluator';
import 'gwComponentVisibility';

function Decorator() {
}

Decorator.prototype.decorate = (component, initPromise) => {
	const $component = component.$elementObsoleteDoNotUse;
	const instance = component.instance;
	const params = component.params;

	captionDecorator.decorate(component);
	decorateInitAwaiter(instance, initPromise);
	decorateDataItemAwaiter(params.bindingPath, instance);
	decorateBorderRadius(component.$container, $component, params, instance);
	readRestrictionDecorate(component.$container, params);

	if (!params.isConfigTemplateApplied) {
		decorateBooleanAttributes($component, params);
		decorateProposedValues(component.viewModel);
		decorateVisibility($component, params, component.viewModel);
		decorateAccessors($component);

		if (params.bindingPath || params.withReadonlyness) {
			decorateReadOnly($component, params, component.viewModel, instance);
		}

		if (params.bindingPath) {
			decorateValidation($component, params, instance);
		}
	}

	if (params.isInConfigurationMode && !params.subWidget && !params.disableConfigTmpl) {
		const bind = commaSeparate($component.attr('data-bind'), 'gwValidationConfigTooltip', 'validationConfigHighlight');
		$component.attr('data-bind', bind);
	}

	$component.attr({
		'data-design-control-id': params.designControlId,
		'data-sub-widget': params.subWidget
	});
};

function decorateInitAwaiter(instance, initPromise) {
	if (initPromise) {
		initPromise = initPromise.finally(() => {
			initPromise = null;
		});
	}

	instance.isInitialized = () => {
		return !initPromise;
	};

	instance.waitForInitAsync = () => {
		return initPromise || Promise.resolve();
	};
}

function decorateDataItemAwaiter(bindingPath, instance) {
	instance.waitForDataItemAsync = function () {
		const self = this;
		return Promise.try(() => {
			const context = ko.contextFor(self.container[0]);
			if (!bindingPath || !context.$data) {
				return context.$data;
			}

			return bindingEvaluator.loadUltimateDataItemAsync(context, context.$data, bindingPath);
		});
	};
}

function decorateReadOnly($component, params, viewModel, instance) {
	/*! SuppressStringValidation (This is not a caption) */
	let bind = typeof params.isReadOnly === 'string' ? params.isReadOnly : JSON.stringify(params.isReadOnly || false);
	bind = commaSeparate($component.attr('data-bind'), 'gwReadOnly: ' + bind + '');
	$component.attr('data-bind', bind);

	instance.isComponentReadOnly = (bindingContext, value) => {
		const result = ko.unwrap(viewModel.isReadOnly) ||
			readOnlyEvaluator.isReadOnly(bindingContext, ko.unwrap(params.dataItem), value, params.propertyName);
		return result;
	};

	if (!instance.markAsReadOnly) {
		instance.markAsReadOnly = (value) => {
			widgetUtils.decorateReadOnly($component, value);
		};
	}

	instance.unbindReadOnly = () => {
		disableReadOnlyBinding($component);
	};
}

function decorateAccessors($component) {
	if ($component.hasComponentMethod('setValue')) {
		$component.attr('data-settable', true);
	}
	if ($component.hasComponentMethod('getValue')) {
		$component.attr('data-readable', true);
	}
}

function decorateVisibility($element, params, viewModel) {
	if (viewModel && !params.isInConfigurationMode && !isEmptyGuid(params.visibilityCondition)) {
		/*! SuppressStringValidation binding string */
		const bind = commaSeparate($element.attr('data-bind'), `gwComponentVisibility: { rootDataItem: $parent, dataItem: $parentContext.$ultimateDataItem('${params.bindingPath}'), condition: '${params.visibilityCondition}' }`);
		$element.attr('data-bind', bind);
	}
}

function decorateValidation($component, params) {
	const validationPath = params.validation ? params.validation.valuePath : params.bindingPath;
	validationDecorator.decorate($component, format('{ dataItem: $parent, valuePath: {0} }', JSON.stringify(validationPath)));
}

function decorateBooleanAttributes($component, params) {
	if (params.booleanAttributes) {
		params.booleanAttributes.split(',').forEach((attribute) => {
			$component.prop(attribute, true);
		});
	}
}

function decorateBorderRadius($container, $component, params, instance) {
	if (params.cornerRadius) {
		/*! SuppressStringValidation px is not a caption */
		const cornerRadius = params.cornerRadius.replace(/,/g, 'px ').concat('px');
		if (instance.setCornerRadius) {
			instance.setCornerRadius.call({ elementObsoleteDoNotUse: $component }, cornerRadius);
		}
		else {
			$container.find('.g-widget-border').css('border-radius', cornerRadius);
		}
	}
}

function decorateProposedValues(viewModel) {
	if (viewModel && viewModel.dataItem) {
		let oldDataItem = null;
		const originalDispose = viewModel.dispose;
		const listener = ko.computed(() => {
			const newDataItem = ko.unwrap(viewModel.dataItem);
			if (newDataItem !== oldDataItem) {
				ko.ignoreDependencies(() => {
					stopListeningToProposedValues(oldDataItem);
					if (newDataItem && newDataItem.entityAspect) {
						newDataItem.entityAspect.proposedValues.startListeningAsync();
					}
				});
				oldDataItem = newDataItem;
			}
		});

		viewModel.dispose = function () {
			listener.dispose();
			stopListeningToProposedValues(oldDataItem);
			oldDataItem = null;

			if (originalDispose) {
				originalDispose.call(this);
			}
		};
	}
}

function stopListeningToProposedValues(dataItem) {
	if (dataItem && dataItem.entityAspect) {
		dataItem.entityAspect.proposedValues.stopListening();
	}
}

export default new Decorator();
