import ajaxService from "AjaxService";
import { getCachedPromise } from "CachedPromise";
import global from "Global";
import LRUCache from "lru-cache";

export type RegistryValue<T> = {
  Value: T;
  Type: string;
};

export type CodeDescriptionPair = {
  Code: string;
  Description: string;
};

export class RegistryService {
  private readonly registryCache: LRUCache<string, () => Promise<unknown>>;

  constructor() {
    this.registryCache = new LRUCache({ max: 50 });
  }

  async getValueAsync<T>(name: string): Promise<T> {
    let itemCache = this.registryCache.get(name);
    if (itemCache === undefined) {
      const uri = `${global.serviceUri}api/registry/value`;
      const query = { name };
      itemCache = getCachedPromise(async () => {
        const registryValue = await ajaxService.getAsync<RegistryValue<T>>(uri, query);
        return registryValue.Value;
      });
      this.registryCache.set(name, itemCache);
    }
    const value = await itemCache();
    return value as T;
  }

  clearCache(): void {
    this.registryCache.clear();
  }
}
export default new RegistryService();
