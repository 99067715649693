import { MenuItemsProvider } from 'MenuItemsProviderBase';

class DesktopMenuItemsProvider extends MenuItemsProvider {
	getMoreMenuItems(options, customPinnableMenuItems) {
		const menuItems = this.getLoggedInInfo(options).concat(
			this.getHome(options),
			this.getUserOptions(options),
			this.getCustomPinnableMenuItems(customPinnableMenuItems),
			this.getDocuments(options),
			this.getNotesOptions(options),
			this.getLogsOptions(options),
			this.getWorkflowMenuItems(options),
			this.getMessages(options),
			this.getAdditionalInfoOptions(options),
			this.getDisplayOptions({
				isHeaderLinksAllowed: true,
				isGridFilterAndLinksAllowed: true
			}),
			this.getConfiguratorOptions(options),
			this.getLoggedInOptions(options, true));

		return menuItems;
	}
}

export default new DesktopMenuItemsProvider();
