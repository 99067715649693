// Generates RFC-compliant version 4 UUIDs.
// Based on https://github.com/kelektiv/node-uuid

const randoms = new Uint8Array(16);
const byteToHex = ((): string[] => {
  const result: string[] = [];
  for (let i = 0; i < 256; ++i) {
    result[i] = (i + 0x100).toString(16).substring(1);
  }

  return result;
})();

function bytesToUuid(buffer: Uint8Array): string {
  let i = 0;
  return (
    byteToHex[buffer[i++]] +
    byteToHex[buffer[i++]] +
    byteToHex[buffer[i++]] +
    byteToHex[buffer[i++]] +
    "-" +
    byteToHex[buffer[i++]] +
    byteToHex[buffer[i++]] +
    "-" +
    byteToHex[buffer[i++]] +
    byteToHex[buffer[i++]] +
    "-" +
    byteToHex[buffer[i++]] +
    byteToHex[buffer[i++]] +
    "-" +
    byteToHex[buffer[i++]] +
    byteToHex[buffer[i++]] +
    byteToHex[buffer[i++]] +
    byteToHex[buffer[i++]] +
    byteToHex[buffer[i++]] +
    byteToHex[buffer[i++]]
  );
}

export function newGuid(): string {
  window.crypto.getRandomValues(randoms);

  // Per 4.4, set bits for version and clock_seq_hi_and_reserved.
  /* eslint-disable no-bitwise */
  randoms[6] = (randoms[6] & 0x0f) | 0x40;
  randoms[8] = (randoms[8] & 0x3f) | 0x80;
  /* eslint-enable no-bitwise */

  return bytesToUuid(randoms);
}

/** @deprecated Use named import instead -- import { newGuid } from 'GuidGenerator'; */
export default { newGuid };
