import constants from 'Constants';
import dependency from 'Dependency2';
import dependencyExtractor from 'DependencyExtractorVisitor';
import embeddedDependenciesExtractor from 'ExtractEmbeddedDependenciesVisitor';
import { getGlowMacroVertex } from 'GlowMacroVertex';
import PropertyVertex from 'PropertyVertex';
import _ from 'underscore';

export default class DependencyExpression {
	constructor(expression) {
		this.expression = expression;
	}

	getAllDependencyPaths() {
		return _.uniq(
			getDependencyPaths(this.expression)
				.concat(getEmbeddedDependencyPaths(this.expression))
				.filter(isPropertyPath)
		);
	}

	getDependencyGraphVertexes(entity, predicate) {
		const result = [];
		const { entityManager } = entity.entityAspect;
		dependency.getValue(entity, this.expression, {
			onVisitMacro(path, state) {
				if (state === constants.States.NotLoaded) {
					getDependencyPaths(path).forEach((path) => {
						const vertex = getGlowMacroVertex(entityManager.dependencyGraph, path);
						vertex.loadMacroAsync();
						result.push(vertex);
					});
				}
			},
			onVisitProperty(propertyEntity, propertyName) {
				if (
					propertyEntity.entityAspect.entityManager === entityManager &&
					(!predicate || predicate(propertyEntity, propertyName))
				) {
					result.push(new PropertyVertex(propertyEntity, propertyName));
				}
			},
		});

		return result;
	}
}

function getDependencyPaths(expression) {
	return dependencyExtractor.visitDependency(expression, { extractPathsOnly: true });
}

function getEmbeddedDependencyPaths(expression) {
	return embeddedDependenciesExtractor.extractEmbeddedDependencies(expression, {
		extractPathsOnly: true,
	});
}

function isPropertyPath(dependency) {
	return !dependency.startsWith('%');
}
