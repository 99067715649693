/*! SuppressStringValidation regex is not translatable */
const typeNameFromQualifiedTypeNamePart = "(?:\\w*\\.)*(\\w+)";
const assemblyNamePart = "(?:,\\s[\\w\\.]+)?";
const typeNameWithPossibleGenericTypePart = `^${typeNameFromQualifiedTypeNamePart}(?:\`1\\[\\[${typeNameFromQualifiedTypeNamePart}\\]\\])?(\\[\\])?${assemblyNamePart}$`;

const entityTypeNameRegex = new RegExp(typeNameWithPossibleGenericTypePart);
const genericTypeArgumentRegex = new RegExp("\\[\\[(.*)\\]\\]");

export function getEntityTypeNameFromExtendedTypeName(extendedTypeName: string | undefined): string | null {
  if (extendedTypeName) {
    const matches = extendedTypeName.match(entityTypeNameRegex);
    if (matches) {
      if (matches[2]) {
        return matches[1] + "[[" + matches[2] + "]]";
      } else if (matches[1]) {
        return matches[1];
      }
    }
  }

  return null;
}

export function getGenericTypeArgument(typeName: string): string | null {
  if (typeName) {
    const matches = typeName.match(genericTypeArgumentRegex);

    return matches?.[1] ?? null;
  }

  return null;
}

export function isCollectionType(typeName: string | undefined): boolean {
  return !!typeName && typeName.endsWith("[]");
}

/** @deprecated use named import instead */
export default {
  getEntityTypeNameFromExtendedTypeName,
  getGenericTypeArgument,
};
