import $ from 'jquery';
import global from 'Global';
import * as stringUtils from 'StringUtils';
import Promise from 'bluebird';
import ajaxService from 'AjaxService';
import { ResourceStringsNotLoadedError } from 'ResourceStringsNotLoadedError';

function ResStringRepository() {
	/*! StartNoStringValidationRegion These properties define routes */
	this._stringsCache = {};
	this._stringsCacheLanguageCode = ''; // During loading this will be set to the new language code before the strings are loaded
	this._entityToKeyMappingsCache = {};
	this._ajaxServicePromiseCache = new Map();
	this._loadPromise = null;
	this._stringsLoaded = false;
	this._captionServicePath = 'captionservice/captiondata/HTML';
	this._entityMappingPath = 'captionservice/entitytokeymappings';
	this._moduleCaptionPath = 'captionservice/moduleCaptions/{0}';
	/*! EndNoStringValidationRegion */
}

ResStringRepository.prototype.loadStringsAsync = function (languageCode) {
	let result;

	if (this._stringsCacheLanguageCode === languageCode) {
		result = this._loadPromise || Promise.resolve();
	}
	else {
		const mappingRequestUri = global.serviceUri + this._entityMappingPath;
		this._stringsCacheLanguageCode = languageCode;
		const captionDataRequestUri = global.serviceUri + this._captionServicePath;
		const captionDataRequestUriQuery = { languageCode };
		const moduleCaptionUri = global.serviceUri + stringUtils.format(this._moduleCaptionPath, global.moduleName);

		const self = this;
		this._loadPromise = result = Promise.join(
			ajaxService.getAsync(captionDataRequestUri, captionDataRequestUriQuery),
			loadCachedAsync(self, mappingRequestUri),
			loadCachedAsync(self, moduleCaptionUri),
			(resourceStrings, entityToKeyMappings, moduleResStrings) => {
				if (self._stringsCacheLanguageCode === languageCode) {
					$.extend(resourceStrings, moduleResStrings);
					$.each(resourceStrings, (i, receivedCaption) => {
						receivedCaption.caption = receivedCaption.medium || receivedCaption.long || receivedCaption.short;
						receivedCaption.longCaption = receivedCaption.long || receivedCaption.medium || receivedCaption.short;
						receivedCaption.description = receivedCaption.description || receivedCaption.long || receivedCaption.caption;
					});

					self._stringsCache = resourceStrings;
					self._entityToKeyMappingsCache = entityToKeyMappings;
					self._loadPromise = null;
				}
			}).finally(() => {
				self._stringsLoaded = true;
			});
	}

	return result;
};

function loadCachedAsync(vm, uri) {
	let cache = vm._ajaxServicePromiseCache.get(uri);
	if (!cache) {
		cache = Promise.cache(() => ajaxService.getAsync(uri));
		vm._ajaxServicePromiseCache.set(uri, cache);
	}
	return cache();
}

ResStringRepository.prototype.clearCache = function () {
	this._stringsCache = {};
	this._stringsCacheLanguageCode = '';
	this._loadPromise = null;
	this._stringsLoaded = false;
	this._ajaxServicePromiseCache.clear();
};

ResStringRepository.prototype.getResourceStrings = function (key) {
	if (!this._stringsLoaded) {
		/*! SuppressStringValidation message of error */
		throw new ResourceStringsNotLoadedError('Cannot load resource strings before translations have been loaded.');
	}

	if (key) {
		return this._stringsCache[key.toUpperCase()];
	}

	throw new Error('Empty key was provided to getResourceStrings');
};

ResStringRepository.prototype.getKeysForEntity = function (entityName) {
	return this._entityToKeyMappingsCache[entityName] || [];
};

export default new ResStringRepository();
