import {
  formatDate as _formatDate,
  isValidDate,
  minValue,
  parseDate,
  parseDateExpression,
  toDateTimeTypeString,
  today,
} from "DateExtensions";
import { DateTimeType } from "DateTimeConstants";

/*! SuppressStringValidation Type value */

export default {
  format(date: string): string {
    if (date) {
      const parsed = parseDate(date, DateTimeType.Date).toDate();

      if (isValidDate(parsed)) {
        const result = !isMinDate(parsed) ? toDateTimeTypeString(parsed, DateTimeType.Date) : "";
        return result;
      }
    }
    return date;
  },
  parse(date: string): string {
    let result = date;
    if (date) {
      const value = date;
      let momentValue = parseDateExpression(value, DateTimeType.Date);
      if (!momentValue.isValid()) {
        momentValue = parseDate(value, DateTimeType.Date);
      }

      if (isValidDate(momentValue.toDate())) {
        result = formatDate(momentValue.toDate());
      }
    }
    return result;
  },
  today(): string {
    return formatDate(today().toDate());
  },
  isValid(isoDate: string): boolean {
    const momentValue = parseDate(isoDate, DateTimeType.Date);
    return isValidDate(momentValue.toDate());
  },
};

export function formatDate(date: Date): string {
  return _formatDate(date, "YYYY-MM-DD");
}

function isMinDate(date: Date): boolean {
  return (
    minValue().year() === date.getFullYear() &&
    minValue().month() === date.getMonth() &&
    minValue().date() === date.getDate()
  );
}
