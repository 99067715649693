import widgetUtils from 'WidgetUtils';

function ValidationDecorator()
{
}

function decorateElement($element, property)
{
	const disableValidation = $element.data('disableValidationDecorations');
	if (!disableValidation)
	{
		const bind = $element.attr('data-bind') || '';
		const bindings = [];

		if (bind)
		{
			bindings.push(bind);
		}

		const disableTooltip = $element.data('disableTooltip');
		if (!disableTooltip && bind.indexOf('gwValidationTooltip:') === -1)
		{
			bindings.push('gwValidationTooltip: ' + property);
		}

		if (bind.indexOf('validationHighlight:') === -1)
		{
			bindings.push('validationHighlight: ' + property);
		}

		$element.attr('data-bind', bindings.join(', '));
	}
}

ValidationDecorator.prototype.decorate = ($element, bindingValue) => {
	const $elementToDecorate = widgetUtils.getElementToDecorateForValidation($element);
	const bindingValueForCurrent = bindingValue || getBindingValue($elementToDecorate);
	if (bindingValueForCurrent)
	{
		decorateElement($elementToDecorate, bindingValueForCurrent);
	}
};

function getBindingValue($element)
{
	const property = $element.data('property');
	return property ? "'" + property + "'" : null;
}

export default new ValidationDecorator();
