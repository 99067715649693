import Promise from 'bluebird';
import captionService from 'CaptionService';
import ko from 'knockout';

function DynamicMenuViewModel(options) {
	this.itemTemplate = options.itemTemplate;
	this.options = options;
	this.items = ko.asyncComputed(
		getItemsAsync.bind(null, this),
		() => {
			ko.unwrap(this.options.items);
		},
		this
	);
	this.loaded = ko.pureComputed(() => {
		return (!options.loaded || options.loaded()) && (!this.items.loaded || this.items.loaded());
	});
	this.customCaption = options.customCaption;
	this.statusCaption = ko.pureComputed({ read: statusCaption.bind(null, this) });
	this.loadingCaption =
		options.loadingCaption ||
		captionService.getString('6a897614-5255-4196-bcc3-102503b0c2b4', 'Loading...');
	this.emptyCaption =
		options.emptyCaption ||
		captionService.getString('1b916766-7807-49af-a922-82ca2e309719', 'No results found.');
	this.menuItems = ko.pureComputed(() =>
		this.items().filter((item) => ko.unwrap(item.isHiddenFromMenu) !== true)
	);
}

DynamicMenuViewModel.prototype.dispose = function () {
	this.items.dispose();
};

function getItemsAsync(vm) {
	const items = vm.options.items;
	if (typeof items === 'object' && items !== null && !Array.isArray(items)) {
		return {
			syncValue: [],
			asyncPromise: Promise.resolve(items.factory(items.param)).then((resultantItems) => {
				return ko.unwrap(resultantItems);
			}),
		};
	} else {
		return { syncValue: ko.unwrap(items) };
	}
}

function statusCaption(vm) {
	const customCaption = ko.unwrap(vm.customCaption);
	if (customCaption) {
		return customCaption;
	}

	const items = ko.unwrap(vm.items);
	const loaded = ko.unwrap(vm.loaded);
	if (!loaded) {
		return null;
	} else if (loaded && items && !items.length) {
		return { css: { disabled: true }, text: vm.emptyCaption };
	}

	return undefined;
}

export default DynamicMenuViewModel;
