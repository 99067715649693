import AsyncIndicator from 'AsyncIndicator';
import entitySaveService from 'EntitySaveService';
import MessageBus from 'MessageBus';
import ModelProvider from 'ModelProvider';
import noteTypesService from 'NoteTypesService';
import NotificationSummary from 'NotificationSummary';
import breeze from 'breeze-client';
import ko from 'knockout';

/*! StartNoStringValidationRegion Enum */
export const NotesEventType = {
	Close: 'Close',
	ConfirmClose: 'ConfirmClose',
	LoadFailure: 'LoadFailure',
};
/*! EndNoStringValidationRegion */

export class NotesViewModel {
	constructor(entityKey) {
		this._entity = ko.observable();
		this._entityKey = entityKey;
		this._hasSaved = false;

		this.entity = ko.pureComputed(this._entity);
		this.hasChanges = ko.pureComputed(this._hasChanges, this);
		this.messageBus = new MessageBus();
		this.notificationSummary = new NotificationSummary(this.entity, ko.pureComputed(this._getAllNotes, this));
		this.pageExtensions = { modelProvider: new ModelProvider(null, entityKey.entityType.interfaceName) };
		this._noteTypes = new Map();
	}

	get hasSaved() {
		return this._hasSaved;
	}

	isPlainTextNote(note) {
		return this._shouldShowDataNotes(note) === true;
	}

	isRichTextNote(note) {
		return this._shouldShowDataNotes(note) === false;
	}

	_shouldShowDataNotes(note) {
		const description = note?.ST_Description();
		if (!description || !this._noteTypes) {
			return;
		}

		let result = this._noteTypes.find((x) => x.description === description)?.isTextOnly;
		if (result === undefined && note.ST_IsCustomDescription()) {
			result = false;
		}
		return result;
	}

	async loadEntityAsync() {
		const entityKey = this._entityKey;
		const entityType = entityKey.entityType;
		const entityManager = breeze.EntityManager.forEntityType(entityType);
		const query = breeze.EntityQuery.fromEntityKey(entityKey);

		const [data, noteTypes] = await Promise.all([
			entityManager.executeQuery(query),
			noteTypesService.getNoteTypesAsync(entityType.interfaceName),
		]);

		const entity = data.results[0];
		this._noteTypes = noteTypes;

		if (entity) {
			this._entity(entity);
		} else {
			return this.messageBus.publishAsync(NotesEventType.LoadFailure);
		}
	}

	saveAsync() {
		return this._runActionAsync(() => {
			return this._saveCoreAsync()
				.tap((isSaved) => {
					if (isSaved) {
						return this.loadEntityAsync();
					}
				});
		});
	}

	saveAndCloseAsync() {
		return this._runActionAsync(() => {
			return this._saveCoreAsync()
				.tap((isSaved) => {
					if (isSaved) {
						return this.messageBus.publishAsync(NotesEventType.Close);
					}
				});
		});
	}

	confirmCloseAsync() {
		return this._runActionAsync(() => {
			return this.messageBus.publishAsync(NotesEventType.ConfirmClose);
		});
	}

	_getAllNotes() {
		return this.entity().Notes();
	}

	_hasChanges() {
		return this.entity().entityAspect.entityManager.observeHasChanges();
	}

	_runActionAsync(callback) {
		const asyncIndicator = new AsyncIndicator();
		asyncIndicator.show();
		return callback().finally(() => asyncIndicator.hideAsync());
	}

	_saveCoreAsync() {
		return entitySaveService.saveWithAlertsAsync(this.entity().entityAspect.entityManager)
			.then(({ isSaved }) => {
				if (isSaved) {
					this._hasSaved = true;
				}

				return isSaved;
			});
	}
}
