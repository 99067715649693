import appConfig from 'AppConfig';
import { asyncTaskNotifier } from 'AsyncTaskNotifier';
import captionPresentationService from 'CaptionPresentationService';
import Constants from 'Constants';
import dialogService from 'DialogService';
import { CancellationError } from 'Errors';
import global from 'Global';
import { trackBusyStateAsync } from 'GlobalBusyStateTracker';
import helpPresenter from 'HelpPresenter';
import 'KOBindingContextExtensions';
import materialDesignDialogService from 'MaterialDesignDialogService';
import { loadTemplateAsync } from 'ModuleLoader';
import navigationService from 'NavigationService';
import PageViewModel from 'PageViewModel';
import pages from 'Pages';
import userSession from 'UserSession';
import vueFactory from 'VueFactory';
import widgetFactory from 'WidgetFactory';
import widgetService from 'WidgetService';
import 'WidgetTemplates/PageShell.ejs';
import 'Widgets/gwButton';
import 'Widgets/gwConfigureMenu';
import windowManager from 'WindowManager';
import 'gwFormExtender';
import immediate from 'immediate';
import $ from 'jquery';
import ko from 'knockout';

function UIService() {
	this.pageBindingContext = ko.observable();
	this._pageTitleComputed = ko.computed(computeNewTitle.bind(null, this.pageBindingContext));
	this.asyncTaskNotifier = asyncTaskNotifier;
}

function computeNewTitle(pageTitleContext) {
	let title;
	const context = pageTitleContext();

	if (context && !global.isPWA()) {
		title = context.$data.title();
		userSession.currentPage.name = title;
	}

	windowManager.changeTitle(title);
}

UIService.prototype.showAlertsTray = ($pageShell) => {
	/*! SuppressStringValidation g-sidebar is valid css selector */
	const $trayToggler = $pageShell.siblings('.g-sidebar').find('.g-validation-group-box a[data-toggle=collapse]');
	if ($trayToggler.is('.collapsed')) {
		$trayToggler.trigger('click');
	}
};

UIService.prototype.loadUnavailablePageAsync = function () {
	return this.loadPageAsync(pages.Unavailable);
};

UIService.prototype.loadForbiddenPageAsync = function () {
	return this.loadPageAsync(pages.Forbidden);
};

UIService.prototype.loadNoModuleAccessPageAsync = function (options) {
	options = options || {};
	return this.loadPageAsync(pages.NoModuleAccess, options);
};

UIService.prototype.loadPopoutPageAsync = function (page, options) {
	/*! SuppressStringValidation g-page-popout is valid css class */
	options = $.extend(options, { pageCss: 'g-page-popout' });
	return this.loadPageAsync(page, options);
};

UIService.prototype.loadPageAsync = function (page, options) {
	return loadViewAsync(this, options, loadPageAsync.bind(null, page));
};

UIService.prototype.loadFormAsync = function (formType, options) {
	return loadViewAsync(this, options, loadFormViewInfoAsync.bind(null, formType));
};

async function loadViewAsync(service, options, loader) {
	options = $.extend({}, options);

	const promise = (async () => {
		const [viewInfo, contentViewModel] = await Promise.all([
			loader(options),
			options.viewModel,
		]);

		dialogService.hide();
		materialDesignDialogService.hideAllDialogs();
		navigationService.setPageContext(Symbol());

		const [$pageContainer, pageBindingContext] = setUpContainer(options, viewInfo, contentViewModel);

		let $pageShell = $pageContainer.find(Constants.CssClasses.PageShell.Selector);

		userSession.currentPage.id = viewInfo.helpId;
		service.pageBindingContext(pageBindingContext);

		if (options.pageCss) {
			$pageContainer.addClass(options.pageCss);
		}

		const headerTemplatePromise =
			options.headerTemplate ||
			loadTemplateAsync(global.formFactorPath + '/PageHeaderTemplate.ejs');
		const widgetsPromise = injectViewAndCreateWidgetsAsync(
			$pageShell,
			$pageContainer,
			viewInfo.contentMarkup,
			viewInfo.formExtender,
			viewInfo.configurationItemPK,
			viewInfo.materialDesignForm === true,
			options
		);

		try {
			const [headerTemplate] = await Promise.all([headerTemplatePromise, widgetsPromise]);
			ensureElementIsInDocument($pageContainer);
			const $header = $('#g-header-for-binding');
			if ($header.length) {
				ko.utils.emptyDomNode($header[0]);
				const $headerContents = $('<div></div>').appendTo($header);

				$headerContents.addClass('g-header-contents invisible');
				$headerContents.append(headerTemplate);

				if (!global.isNoHeader) {
					applyHeaderAsync(pageBindingContext, $header, $headerContents);
				}
			}
		} catch (error) {
			if(error instanceof CancellationError) {
				throw error;
			}
			// ignore other errors
		}

		const pageExtensions = pageBindingContext.$root.pageExtensions;
		const contentContainer = $pageShell.closest(Constants.CssClasses.VueApp.Selector)[0];
		const instance = await vueFactory.createVueInstanceAsync({
			contentContainer,
			validationRegistrar: pageExtensions && pageExtensions.validationRegistrar,
			knockoutContext: pageBindingContext,
			bindingContext: pageBindingContext.$root,
			name: 'GlowPageContext',
			formId: viewInfo.helpId,
		});

		const $appContainer = $(instance.$el);
		$pageShell = $appContainer.find(Constants.CssClasses.PageShell.Selector);
		setupFormSubmit($pageShell);
		if (options.onBeforePageBinding) {
			options.onBeforePageBinding($pageContainer);
		}

		ko.applyBindings(pageBindingContext, $pageContainer[0]);
		const $modalsContainer = $appContainer.find(Constants.CssClasses.ModalsContainer.Selector);
		$modalsContainer.data('is-initialized', true);
		enableResizeEvents($pageShell, options);
		global.isInteractive = true;

		if (!options.skipShowHelpInstructions && !global.materialDesign) {
			helpPresenter.showHelpInstructionsAsync(viewInfo.helpId, pageBindingContext.$data.title);
		}

		return [$pageContainer, pageBindingContext];
	})();

	return await trackBusyStateAsync(promise);
}

export function setUpContainer(options, viewInfo, contentViewModel) {
	const $shell = $(appConfig.contentContainer);
	emptyContainer($shell);

	const pageBindingContext = getPageBindingContext(
		options,
		viewInfo.title,
		viewInfo.titleArgs,
		viewInfo.formExtender,
		contentViewModel
	);

	const $pageContainer = $(viewInfo.shellMarkup).appendTo($shell);

	return [$pageContainer, pageBindingContext];
}

function getMarkupAsync(pageName) {
	return loadTemplateAsync(pageName);
}

function getPageBindingContext(options, title, titleArgs, formExtender, contentViewModel) {
	contentViewModel = contentViewModel || {};
	const pageViewModel = new PageViewModel($.extend({ title, titleArgs, contentViewModel }, options));
	const bindingContext = new ko.bindingContext(pageViewModel);
	bindingContext.$contentViewModel = contentViewModel;
	if (!options.isInConfigurationMode) {
		bindingContext.$formExtender = formExtender;
	}

	return bindingContext;
}

export async function loadFormViewInfoAsync(formType, options) {
	let shellName = formType.Shell;
	if (formType.FormFactorSpecific) {
		shellName = global.formFactorPath + '/' + shellName;
	}

	const [shellMarkup, form] = await Promise.all([getMarkupAsync(shellName), options.form]);
	return {
		contentMarkup: form.Markup,
		helpId: form.PK,
		shellMarkup,
		title: form.Caption ?? form.FormID,
		titleArgs: form.CaptionArgs,
		formExtender: form.ExtenderFunc,
		configurationItemPK: form.ConfigurationItemPK,
		materialDesignForm: form.MaterialDesign === true,
	};
}

async function loadPageAsync(page) {
	let pageName = page.Name;
	if (page.FormFactorSpecific) {
		pageName = global.formFactorPath + '/' + pageName;
	}

	const pageShell = Constants.PageTypes.Page;
	let shellName = pageShell.Shell;
	if (pageShell.FormFactorSpecific) {
		shellName = global.formFactorPath + '/' + shellName;
	}

	const [shellMarkup, contentMarkup] = await Promise.all([
		getMarkupAsync(shellName),
		getMarkupAsync(pageName),
	]);
	return {
		contentMarkup,
		helpId: page.Name,
		shellMarkup,
		title: page.Title,
	};
}

function emptyContainer($viewContainer) {
	if ($viewContainer.length > 0) {
		ko.utils.emptyDomNode($viewContainer[0]);
	}
}

async function injectViewAndCreateWidgetsAsync($pageShell, $pageContainer, contentMarkup, formExtender, configurationItemPK, materialDesignForm, options) {
	// View might be a string with some content not wrapped in an element.
	// In order to only parse the string once and to preserve all content correctly, we store the content in a temporary container element
	// and move the content into $pageShell later.
	const $tempContainer = $('<div>');
	$tempContainer.append(contentMarkup);

	if (formExtender && !options.isInConfigurationMode) {
		$tempContainer
			.find('>[data-role="gwShellContainer"]')
			.append(`<div data-bind="gwFormExtender: { extender: $formExtender, materialDesignForm: ${materialDesignForm} }" data-wtg-layout-grid-ignore>`)
			.attr('data-configuration-item-pk', configurationItemPK);
	}

	await widgetService.preloadWidgetsAsync($pageContainer.add($pageShell).add($tempContainer));
	$pageShell.append($tempContainer.contents());
	widgetService.createGlowWidgetsSync($pageContainer);
}

function enableResizeEvents($pageShell, options) {
	if (!options.skipHookResize) {
		const $container = $pageShell.find('div[data-role="gwShellContainer"].g-anchor-container:eq(0)');
		if ($container.length) {
			widgetFactory.hookResizableContainer($container);
		}
	}

	captionPresentationService.resizeCaptions($pageShell);
	immediate(() => {
		$pageShell.fadeIn();
	});
}

function setupFormSubmit($pageShell) {
	const $form = $pageShell.closest('form');
	$form.on('submit', (e) => {
		e.preventDefault();
		e.stopPropagation();
	});
}

async function applyHeaderAsync(pageBindingContext, $header, $headerContents) {
	let $headerTitle = $header.find('#headerTitleTemplate');
	if ($headerTitle.length) {
		$headerTitle = $($headerTitle.html());

		const rootContext = pageBindingContext.$root;

		if (rootContext.moreMenuItems && !global.isPortable()) {
			$headerTitle.attr('data-bind', 'style: { maxWidth: headerTitleMaxWidth }');
			rootContext.headerTitleMaxWidth = ko.pureComputed(getHeaderTitleMaxWidth.bind(null, rootContext.moreMenuItems, $headerContents)).extend({ deferred: true });
		}

		$headerContents.append($headerTitle);
	}

	const $headerMenu = $header.find('#headerMenuTemplate');
	if ($headerMenu.length) {
		$headerContents.append($('<div>').addClass('g-header-menu').append($headerMenu.html()));
	}

	await widgetService.createGlowWidgetsAsync($headerContents);
	ensureElementIsInDocument($headerContents);
	ko.applyBindings(pageBindingContext, $headerContents[0]);
	$headerContents.removeClass('invisible');
}

function getHeaderTitleMaxWidth(moreMenuItems, $headerContents) {
	const hasStickyElements = moreMenuItems().some((item) => {
		return item.isSticky;
	});

	if (hasStickyElements) {
		let totalWidth = 0;
		$headerContents.find('.gwPinnedItem-sticky-button').each((_, element) => {
			totalWidth += $(element).outerWidth(true);
		});
		/*! SuppressStringValidation css calc function*/
		return 'calc(100% - ' + totalWidth + 'px)';
	}
}

function isPageContainerInDocument($pageContainer) {
	return $pageContainer.parent().length > 0;
}

function ensureElementIsInDocument($element) {
	if (!isPageContainerInDocument($element)) {
		throw new CancellationError();
	}
}

export default new UIService();
