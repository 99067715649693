import ajaxService from 'AjaxService';
import { getCachedPromise } from 'CachedPromise';
import global from 'Global';
import ko from 'knockout';
import komapping from 'knockout.mapping';
import Storage from 'Storage';
import userSession from 'UserSession';

const shouldShowHelpStorageKey = 'ShouldShowHelp' + global.rootPathForModule;
function HelpService() {
	this._isConfigurationAllowedAsync = getCachedPromise(isConfigurationRightAllowedAsync);
}

HelpService.prototype.isConfigurationAllowedAsync = function () {
	return this._isConfigurationAllowedAsync();
};

HelpService.prototype.getFormHelpAsync = async (formId, okIfNotFound) => {
	let content;
	try {
		content = await getHelpContentAsync(formId);
	}
	catch (error) {
		if (!okIfNotFound) {
			throw error;
		}
	}

	if (!content) {
		return null;
	}
	const helpPage = content.UserDefinedHelp || content.SystemDefinedHelp || {};
	let result = userSession.sessionData().userType === userSession.UserType.Staff ? helpPage.InternalHelp : helpPage.ExternalHelp;

	if (!result && helpPage.IsExternalAndInternalSame) {
		result = helpPage.InternalHelp;
	}

	return result;
};

HelpService.prototype.getHelpPagesAsync = async (formId, isInConfigurationMode) => {
	const content = await getHelpContentAsync(formId, isInConfigurationMode);
	const userDefinedHelp = content.UserDefinedHelp ? komapping.fromJS(content.UserDefinedHelp) : content.UserDefinedHelp;
	const systemDefinedHelp = content.SystemDefinedHelp ? komapping.fromJS(content.SystemDefinedHelp) : content.SystemDefinedHelp;
	const fallbackHelp = ko.observable(content.FallbackHelp);
	const helpPages = { UserDefinedHelp: userDefinedHelp, SystemDefinedHelp: systemDefinedHelp, FallbackHelp: fallbackHelp };

	return helpPages;
};

HelpService.prototype.saveHelpPagesAsync = async (formId, formHelp) => {
	const data = komapping.toJS(formHelp);
	const uri = `${global.serviceUri}api/support/help/SaveHelpConfiguration?formId=${formId}`;

	await ajaxService.postAsync(uri, data);
};

function updateShouldShowHelpCachedValue(shouldShowHelp) {
	Storage.set(shouldShowHelpStorageKey, shouldShowHelp);
	return shouldShowHelp;
}

HelpService.prototype.shouldShowHelpAsync = async () => {
	const cachedValue = Storage.get(shouldShowHelpStorageKey);
	if (cachedValue !== null) {
		return cachedValue;
	}
	const uri = `${global.serviceUri}api/support/help/GetShouldShowHelpBeforeForms`;

	const shouldShowHelp = await ajaxService.getAsync(uri, { userId: userSession.sessionData().userPK });
	return updateShouldShowHelpCachedValue(shouldShowHelp);
};

HelpService.prototype.updateShouldShowHelpAsync = async (newValue) => {
	const cachedValue = Storage.get(shouldShowHelpStorageKey);
	if (cachedValue === newValue) {
		return;
	}
	const uri = `${global.serviceUri}api/support/help/UpdateShouldShowHelpBeforeForms?userId=${userSession.sessionData().userPK}&newValue=${newValue}`;
	updateShouldShowHelpCachedValue(newValue);
	await ajaxService.postAsync(uri);
};

async function getHelpContentAsync(formId, isInConfigurationMode) {
	const uri = `${global.serviceUri}api/support/help/GetHelpConfiguration`;
	const query = { formId, isInConfigurationMode: isInConfigurationMode === true };
	const helpConfig = await ajaxService.getAsync(uri, query);
	return helpConfig;
}

async function isConfigurationRightAllowedAsync() {
	if (userSession.sessionData().userType !== userSession.UserType.Staff) {
		return false;
	}

	const uri = `${global.serviceUri}api/support/help/IsHelpConfigurationAllowed`;

	const isConfigRightAllowed = await ajaxService.getAsync(uri);
	return isConfigRightAllowed;
}

export default new HelpService();
