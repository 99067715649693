import constants from 'Constants';
import { loadWorkflowAuditLogsViewModelAsync, loadWorkflowEventsViewModelAsync, loadWorkflowMilestonesViewModelAsync } from 'ModuleLoader';
import captionService from 'CaptionService';
import materialDesignVueDialogService from 'MaterialDesignVueDialogService';

class MaterialDesignWorkflowDialogService {
	constructor() {
	}

	async showAuditLogsAsync(entity, saveableViewModel) {
		const state = {};
		const result = await checkSaveThenShowAsync(saveableViewModel);
		if (result) {
			const ViewModelCtor = await loadWorkflowAuditLogsViewModelAsync();
			const viewModel = createViewModel(this, entity, ViewModelCtor, state);
			return await materialDesignVueDialogService.showDialogAsync(
				entity,
				state,
				constants.MaterialDesignPageTypes.WorkflowAuditLogs.Shell,
				viewModel,
				captionService.getString('f243cbba-f45b-4ce2-9b1e-be51ff13c14f', 'Change Logs')
			);
		}
	}

	async showEventsAsync(entity, saveableViewModel) {
		const state = {};
		const result = await checkSaveThenShowAsync(saveableViewModel);
		if (result) {
			const ViewModelCtor = await loadWorkflowEventsViewModelAsync();
			const viewModel = createViewModel(this, entity, ViewModelCtor, state);
			return await materialDesignVueDialogService.showDialogAsync(
				entity,
				state,
				constants.MaterialDesignPageTypes.WorkflowEvents.Shell,
				viewModel,
				captionService.getString('ca84aa56-3611-48b3-a084-b94269025838', 'Events')
			);
		}
	}

	async showMilestonesAsync(entity, saveableViewModel) {
		const state = {};
		const result = await checkSaveThenShowAsync(saveableViewModel);
		if (result) {
			const ViewModelCtor = await loadWorkflowMilestonesViewModelAsync();
			const viewModel = createViewModel(this, entity, ViewModelCtor, state);
			const captions = {
				lastEventLabel: captionService.getString(
					'4c29e36d-3437-498c-890b-0a26e0c53ceb',
					'Last Event'
				),
				eventAt: captionService.getString('bc0c5abd-18cd-4e57-a2ae-26e62fb6463c', ' at '),
			};
			return await materialDesignVueDialogService.showDialogAsync(
				entity,
				state,
				constants.MaterialDesignPageTypes.WorkflowMilestones.Shell,
				viewModel,
				captionService.getString('180d2e9f-4687-4dc2-aefb-7e675bd6dae3', 'Milestones'),
				captions
			);
		}
	}

	canShowDialog() {
		return materialDesignVueDialogService.canShowDialog();
	}
}

function checkSaveThenShowAsync(saveableViewModel) {
	if (saveableViewModel) {
		return saveableViewModel.saveWithPromptAsync();
	}
	return Promise.resolve(true);
}

function createViewModel(self, entity, ViewModelCtor, state) {
	return new ViewModelCtor(entity.entityType, entity.entityAspect.getPrimaryKey(), {
		onCloseCallback() {
			materialDesignVueDialogService.hideDialogVue(state);
		}
	});
}

export default new MaterialDesignWorkflowDialogService();