import templates from 'WidgetTemplates/gwLoadingAnimation.html';

function createViewModel(params) {
	return { loadingText: params.loadingText };
}

/*! StartNoStringValidationRegion (No captions here) */
const componentConfig =
{
	template: templates,
	viewModel:
	{
		createViewModel
	}
};
/*! EndNoStringValidationRegion */

export default componentConfig;
