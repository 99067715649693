import $ from 'jquery';
import ko from 'knockout';
import templates from 'WidgetTemplates/gwGroupBox2.html';
import GroupBoxViewModel from 'GroupBoxViewModel';
import constants from 'Constants';
import imageService from 'ImageService';
import * as stringUtils from 'StringUtils';

function init(component) {
	const viewModel = component.viewModel;
	const groupBoxTmpl = $(templates)
		.filter(viewModel.isCollapsible ? '#gwGroupBoxCollapsible' : '#gwGroupBoxPanel')
		.html();

	component.$elementObsoleteDoNotUse
		.css('borderWidth', getBorderWidth(component.params.borderWidth))
		.append(groupBoxTmpl);

	component.$container.addClass('gwGroupBox-container');

	if (component.originalTemplateNodes && component.originalTemplateNodes.length) {
		component.$elementObsoleteDoNotUse
			.find('.panel-body')
			.first()
			.append(component.originalTemplateNodes);
	}
}

function getBorderWidth(borderWidth) {
	borderWidth = parseInt(borderWidth, 10);

	return (!isNaN(borderWidth) ? borderWidth : 0);
}

/** @this { elementObsoleteDoNotUse: componentInfo.$elementObsoleteDoNotUse, container: componentInfo.$container } */
function getTitle() {
	return ko.dataFor(this.elementObsoleteDoNotUse[0]).title();
}

function createViewModel(params, componentInfo) {
	const backgroundImageOverride = $(componentInfo.element).data('backgroundImageOverride');
	params.backgroundImageOverride = backgroundImageOverride;

	return new GroupBoxViewModel(params);
}

(() => {
	function setBackgroundImage($element, backgroundUrl) {
		$element.css({
			'backgroundImage': `url("${backgroundUrl}")`,
			'backgroundSize': constants.BackgroundImage.Size.Contain.toLowerCase(),
			'backgroundPosition': constants.BackgroundImage.Position.Center,
			'backgroundRepeat': constants.BackgroundImage.Repeat.None
		});
	}

	ko.bindingHandlers.gwGroupBoxBackgroundImage = {
		update(element, valueAccessor, allBindingsAccessor, viewModel) {
			const $element = $(element);
			const backgroundImageOverrideSrc = viewModel.backgroundImageOverrideSrc();

			if (backgroundImageOverrideSrc) {
				setBackgroundImage($element, backgroundImageOverrideSrc);
			}
			else if (stringUtils.isGuid(viewModel.backgroundImagePk)) {
				const uri = imageService.getImageUri(viewModel.backgroundImagePk);
				setBackgroundImage($element, uri);
			}
			else {
				$element.css({
					'backgroundImage': ''
				});
			}
		}
	};
})();

/*! StartNoStringValidationRegion No captions here */
const componentConfig =
	{
		template: templates,
		widgetSelector: '.gwGroupBox',
		viewModel: { createViewModel },
		init,
		methods: {
			getTitle
		}
	};
/*! EndNoStringValidationRegion */

export default componentConfig;
