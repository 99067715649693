import global from "Global";
import log, { DefaultLogTag, type LogData } from "Log";

interface CspReport {
  cspReport: {
    blockedUri: string;
    columnNumber: number;
    disposition: string;
    documentUri: string;
    effectiveDirective: string;
    lineNumber: number;
    originalPolicy: string;
    referrer: string;
    sourceFile: string;
    statusCode: number;
    violatedDirective: string;
  };
  currentUri: string;
  userAgent: string;
  glowLogs: LogData[];
}

function sendReport(violation: CspReport): void {
  fetch(`${global.rootPath}csp/report`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(violation),
  });
}

function getTruncatedGlowLogs(): LogData[] {
  const maxLogSize = 1024 * 2;
  const logs = log.getLogs()[DefaultLogTag] || [];
  let size = 0;
  let startIndex = logs.length;
  for (let i = logs.length - 1; i >= 0; i--) {
    size += logs[i].message.length;
    if (size > maxLogSize) {
      break;
    }
    startIndex--;
  }
  return logs.slice(startIndex);
}

export default function cspBootstrapper(): void {
  if (global.sendCspReport) {
    window.document.addEventListener("securitypolicyviolation", (event) => {
      const violation: CspReport = {
        cspReport: {
          blockedUri: event.blockedURI,
          columnNumber: event.columnNumber,
          disposition: event.disposition,
          documentUri: event.documentURI,
          effectiveDirective: event.effectiveDirective,
          lineNumber: event.lineNumber,
          originalPolicy: event.originalPolicy,
          referrer: event.referrer,
          sourceFile: event.sourceFile,
          statusCode: event.statusCode,
          violatedDirective: event.violatedDirective,
        },
        currentUri: window.location.href,
        userAgent: navigator.userAgent,
        glowLogs: getTruncatedGlowLogs(),
      };
      sendReport(violation);
    });
  }
}
