import captionService from 'CaptionService';

export const autoRefresh = {
	autoRefresh: () => captionService.getString('f72118ae-91d3-449d-8f79-b2caf67df6fc', 'Auto refresh'),
	never: () => captionService.getString('ab1d6b09-4dd1-497f-931f-8ea4a600cac1', 'Never'),
	oneMinute: () => captionService.getString('003a8953-df51-4bba-bd87-a5ae797674ee', '1 Minute'),
	fiveMinutes: () => captionService.getString('d59d524b-e9c3-44d3-ba4d-21950a389078', '5 Minutes'),
	tenMinutes: () => captionService.getString('32b4870a-b18d-4c92-90a2-6dec035db19b', '10 Minutes'),
	fifteenMinutes: () => captionService.getString('ee9e5cb6-d418-4f11-a8b0-0b130417f595', '15 Minutes'),
	thirtyMinutes: () => captionService.getString('4cd10a39-d946-4628-8129-3e095c9a10a0', '30 Minutes'),
	fortyFiveMinutes: () => captionService.getString('60bfe18b-ab8d-474c-b1d8-e04fd977218d', '45 Minutes'),
	oneHour: () => captionService.getString('70e5b644-4eb9-40ad-8464-ab6813fb5ee1', '1 Hour'),
	twoHours: () => captionService.getString('350228ff-6d2c-4600-bc62-6870340a0438', '2 Hours'),
	threeHours: () => captionService.getString('77562eaf-0fd9-4e1a-b158-fed8d499f7c6', '3 Hours'),
	fourHours: () => captionService.getString('6ed626c8-8295-49d0-900d-5bb988b70918', '4 Hours'),
};
