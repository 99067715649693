import global from 'Global';
import { getModuleValue } from 'ModuleLoader';

export default async function bootstrapperAsync(formFactor, formFactorPath) {
	try {
		global.formFactor = formFactor;
		global.formFactorPath = formFactorPath;

		await runCoreBootstrappersAsync();

		await runStartupBootstrappersAsync();
	} catch (error) {
		getModuleValue(require('ErrorHandler')).reportBootstrapError(error);
	}
}

async function runCoreBootstrappersAsync() {
	run(require('Shared/Bootstrappers/CspBootstrapper'));

	run(require('Shared/Rules/LookupMetadataRulesBootstrapper'));

	await run(require('Shared/Bootstrappers/CoreBootstrapper'));
}

async function runStartupBootstrappersAsync() {
	await run(require('Shared/Bootstrappers/StartupBootstrapper'));

	await run(require('Shared/Bootstrappers/ServiceWorkerBootstrapper'));
}

function run(bootstrapper) {
	return (getModuleValue(bootstrapper))();
}
