class DocumentWrapper {
  getDocument(): Document {
    return document;
  }

  getActiveElement(): Element | null {
    return this.getDocument().activeElement;
  }
}

export default new DocumentWrapper();
