import { credentials, AuthenticationResult, AuthenticationError } from 'AuthenticationService';
import global from 'Global';
import dialogService from 'DialogService';
import notificationType from 'NotificationType';
import captionService from 'CaptionService';
import contextChangeService from 'ContextChangeService';

function getSelectedAuthenticationContextAsync(authenticationToken) {
	return credentials.listContextsAsync(authenticationToken)
		.then(({ contexts }) => {
			if (!contexts || !contexts.length) {
				return dialogService.alertAsync(
					notificationType.Error,
					captionService.getString('ef7fa04f-de73-44dc-ae7a-54d66ddc40b5', 'You do not have permission to log in to this portal. Contact your system administrator to be granted appropriate permissions.'),
					captionService.getString('7a76a8fe-56fd-4f45-bdc9-87e7f71d4061', 'Unable to login'))
					.return(null);
			}

			return contextChangeService.selectUserAsync(contexts);
		});
}

function tryHandleAuthenticationErrorAsync(authenticationSource, error) {
	if (error.authenticationResult !== AuthenticationResult.ContextChangeRequired ||
		!error.claimDetails ||
		!error.claimDetails.authenticationToken) {
		return dialogService.alertAsync(
			notificationType.Error,
			error.message,
			captionService.getString('7a76a8fe-56fd-4f45-bdc9-87e7f71d4061', 'Unable to login'))
			.return(null);
	}

	return getSelectedAuthenticationContextAsync(error.claimDetails.authenticationToken)
		.then((authenticationContext) => {
			if (!authenticationContext) {
				return;
			}

			const { userKey, branchKey, departmentKey } = authenticationContext;
			return credentials.selectUserContextAsync(error.claimDetails.authenticationToken,userKey,branchKey,departmentKey)
				.then((authenticationToken) => {
					return {
						result: AuthenticationResult.Success,
						authenticationToken,
						authenticationSource,
					};
				});
		});
}

export function tryHandleThirdPartyLogon(claimDetails) {
	if (!claimDetails ||
		!claimDetails.oauthRedirectUri ||
		!claimDetails.oauthSalientReturnedValues ||
		!claimDetails.authenticationSource) {
		throw new Error('Could not construct third party redirect uri.');
	}

	const stateObject = {
		p: claimDetails.oauthSalientReturnedValues,
		uk: claimDetails.userKey,
		as: claimDetails.authenticationSource,
		url: global.windowLocation.origin + global.rootPathForModule + global.formFactorPath,
		portals: true,
		ap: claimDetails.oauthAdditionalParameter,
	};

	if (global.windowLocation.hash) {
		stateObject.hash = global.windowLocation.hash;
	}

	const state = JSON.stringify(stateObject);

	/*! StartNoStringValidationRegion - dealing with URI parameters */
	const callbackUri = global.getAbsoluteUrl(global.serviceUri + 'signin-oidc');

	const redirectUri = claimDetails.oauthRedirectUri;
	const separator = redirectUri.indexOf('?') < 0 ? '?' : '&';
	const fullRedirectUri = redirectUri + separator +
		'redirect_uri=' + encodeURIComponent(callbackUri) + '&' +
		'state=' + encodeURIComponent(state);
	/*! EndNoStringValidationRegion */

	// After redirect and back, we continue with tryCompleteExternalLogonAsync.
	global.windowLocation.href = fullRedirectUri;
}

export function tryCompleteExternalLogonAsync() {
	const searchParams = new URL(global.windowLocation.href).searchParams;
	/*! SuppressStringValidation state is an oauth parameter name */
	const state = searchParams.get('state');
	if (!state) {
		throw new Error('No OAuth flow to finish.');
	}

	const stateObject = JSON.parse(state);

	const listOfParameters = stateObject.p;
	const userKey = stateObject.uk;
	const authenticationSource = stateObject.as;
	const keysToReport = {};

	for (const idx in listOfParameters) {
		const key = listOfParameters[idx];
		const value = searchParams.get(key);
		keysToReport[key] = value;
	}

	/*! SuppressStringValidation well known path */
	keysToReport.redirect_uri = global.getAbsoluteUrl(global.serviceUri + 'signin-oidc');

	return credentials.externalAsync(authenticationSource, userKey, keysToReport)
		.catch((error) => {
			if (error instanceof AuthenticationError) {
				return tryHandleAuthenticationErrorAsync(authenticationSource, error);
			}
			throw error;
		});
}
