import { AsyncDictionary } from 'AsyncDictionary';
import $ from 'jquery';
import ajaxService from 'AjaxService';
import global from 'Global';
import { startsWith } from 'StringUtils';
import { FormTemplateNotFoundError, FormTemplateForbiddenError } from 'Errors';

class FormTemplateService {
	constructor() {
		this._formCache = new AsyncDictionary();
	}

	async getFormTemplateAsync(pk, options) {
		const uri = getFormTemplateUri(pk);

		const formTemplate = await getFormTemplateAsync(this._formCache, uri, options);
		return formTemplate;
	}

	async getFormTemplateByIDAsync(id, options) {
		if (options) {
			options.id = id;
		} else {
			options = { id };
		}
		const uri = getFormTemplateUri();

		const formTemplate = await getFormTemplateAsync(this._formCache, uri, options);
		return formTemplate;
	}

	clearCache(pk) {
		const uri = getFormTemplateUri(pk);
		this._formCache.removeWhere((key) => startsWith(key, uri));
	}
}

const getFormTemplateUri = (pk) => {
	const uri = `${global.serviceUri}api/forms/${global.formFactor}/${global.formLanguage}`;

	return pk ? `${uri}/${pk}` : uri;
};

const getFormTemplateAsync = async (cache, uri, options) => {
	const cacheKey = getCacheKey(uri, options);

	const cachedResult = await cache.getOrAddAsync(cacheKey, async () => {
		try {
			const response = await ajaxService.getAsync(uri, options);
			return response;
		} catch (error) {
			if (error instanceof ajaxService.AjaxError) {
				if (error.status === 404) {
					throw new FormTemplateNotFoundError(error);
				} else if (error.status === 403) {
					throw new FormTemplateForbiddenError(error);
				}
			}

			throw error;
		}
	});
	return cachedResult;
};

const getCacheKey = (uri, options) => options ? `${uri}?${$.param(options)}` : uri;

export default new FormTemplateService();
