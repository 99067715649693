import DependencyExtractorVisitor from 'DependencyExtractorVisitor';
import errors from 'Errors';
import _ from 'underscore';
import { ExpressionVisitor } from 'wtg-expressions';

export default class ExtractEmbeddedDependenciesVisitor extends ExpressionVisitor {
	constructor(options) {
		super();
		this._result = [];
		this._extractPathsOnly = options && options.extractPathsOnly;
	}

	static extractEmbeddedDependencies(dependency, options) {
		if (!dependency) {
			return [];
		}

		const visitor = new ExtractEmbeddedDependenciesVisitor(options);
		const tree = this.getDependencyTree(dependency, ExpressionVisitor.TreeName.Dependency, onError(dependency));
		visitor.visit(tree);
		return _.uniq(visitor._result);
	}

	visitEmbedded_path(ctx) {
		const pathCtx = ctx.path();
		const depVisitor = new DependencyExtractorVisitor({ extractPathsOnly: this._extractPathsOnly });
		depVisitor.visit(pathCtx);
		this._result.push(...depVisitor._dependencyPaths);
	}
}

function onError(path) {
	return (msg) => {
		throw new errors.DependencyError(`Error with ${path}: ${msg}`);
	};
}
