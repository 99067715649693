import errors from 'Errors';
import { decoratedWaitAvailable, getAvailableResult, operationTypes } from './Utils';

const containsDecorationInfo = Object.freeze({
	operationType: operationTypes.Method,
	operandCount: 2,
	booleanResult: true,
});

function contains(enumerable, searchElement, data, context) {
	return decoratedWaitAvailable(
		[enumerable, searchElement],
		data,
		context,
		containsCore,
		containsDecorationInfo
	);
}

function containsCore([enumerable, searchElement]) {
	if (enumerable == null) {
		return getAvailableResult(false);
	} else if (Array.isArray(searchElement)) {
		throw new errors.DependencyError('The argument for Contains() must be a scalar value.');
	} else if (typeof enumerable === 'string' || Array.isArray(enumerable)) {
		return getAvailableResult(enumerable.includes(searchElement));
	} else {
		throw new errors.DependencyError('Contains() can only be used on a collection or string.');
	}
}

export default { contains };
