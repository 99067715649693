class SortColumnService {
	getSortNames(sortCol) {
		if (sortCol.colDef && sortCol.colDef.sortNames) {
			return sortCol.colDef.sortNames;
		}
		return [sortCol.field];
	}

	getSortableColumns(columns, sortCols) {
		if (!columns || !sortCols) {
			return [];
		}

		const sortNamesMap = columns.reduce(
			(map, col) => col.sortable ? map.set(col.field, col.sortNames) : map,
			new Map());

		return sortCols.reduce((results, sortCol) => {
			if (sortNamesMap.has(sortCol.column.field)) {
				const sortNames = sortNamesMap.get(sortCol.column.field);
				if (sortNames) {
					const result = Object.assign({}, sortCol);
					result.column.colDef = { sortNames };
					results.push(result);
				}
				else {
					results.push(sortCol);
				}
			}
			return results;
		}, []);
	}
}

export default new SortColumnService();
