import captionService from 'CaptionService';
import workflowMenuItemProvider from 'WorkflowMenuItemProvider';
import breeze from 'breeze-client';
import ko from 'knockout';

class WorkflowMenuViewModel {
	constructor(entityPK, entityType, entity, saveableViewModel) {
		this._entityPK = entityPK;
		this._entityType = entityType;
		this._entity = ko.observable(entity);
		this.isLoaded = ko.pureComputed(() => !!this._entity());
		this.statusCaption = ko.pureComputed(this._statusCaption, this);
		this.hasFailedToLoad = ko.observable(false);
		this.workflowOptions = ko.pureComputed(() => {
			if (this.isLoaded()) {
				return workflowMenuItemProvider.getWorkflowOptions(
					this._entity(),
					saveableViewModel
				);
			}
		});
		this.actionableWorkflowExceptions = ko.pureComputed(() => {
			if (this.isLoaded()) {
				workflowMenuItemProvider.getActionableWorkflowExceptions(this._entity());
			}
		});
	}

	ensureEntityLoadedAsync() {
		if (!this._entity()) {
			return fetchEntityByKeyAsync(this._entityType, this._entityPK).then((result) => {
				if (result) {
					const entity = result.entity;
					this.hasFailedToLoad(!entity);
					this._entity(entity);
					return entity;
				}
				this.hasFailedToLoad(true);
			});
		}
	}

	_statusCaption() {
		if (this.hasFailedToLoad()) {
			return {
				css: {
					disabled: true,
					'g-error-text': true,
				},
				text: captionService.getString(
					'10CEDF07-1BDC-4643-A3CE-4C1753815F00',
					'Failed to retrieve workflow, entity might be deleted.'
				),
			};
		}
	}
}

function fetchEntityByKeyAsync(entityType, entityPK) {
	return breeze.EntityManager.forEntityType(entityType).fetchEntityByKey(entityType, entityPK);
}

export default WorkflowMenuViewModel;
