import _ from 'underscore';
import entityMappingService from 'EntityMappingService';
import lookupMetadataFactory from 'LookupMetadataFactory';
import ruleFunction from 'RuleFunction';
import ruleRepository from 'RuleRepository';

function decorateFuncs(item, funcPropertyNames) {
	funcPropertyNames.forEach((name) => {
		const func = item[name];
		if (func) {
			item[name] = ruleFunction(func);
		}
	});
}

export default function lookupMetadataRulesBootstrapper() {
	ruleRepository.addRegistrar('lookupMetadata', (ruleSet) => {
		const lookupMetadata = ruleSet.lookupMetaData;
		if (lookupMetadata) {
			const result = {};
			/*! SuppressStringValidation (No caption here) */
			const funcPropertyNames = ['ensurePreconditions', 'filter', 'lookupFunc'];
			for (const propertyName in lookupMetadata) {
				let item = lookupMetadata[propertyName];
				if (item.itemType && !entityMappingService.hasInterfaceName(item.itemType)) {
					continue;
				}

				item = _.extend({ entityName: ruleSet.entityName }, item);
				decorateFuncs(item, funcPropertyNames);

				const metadata = lookupMetadataFactory.create(item);
				if (metadata) {
					result[propertyName] = metadata;
				}
			}

			if (!_.isEmpty(result)) {
				return result;
			}
		}
	});
}
