import { DeferredPromise } from "DeferredPromise";

export type Work<T> = () => Promise<T> | T;

class WorkItem<T = unknown> extends DeferredPromise<T> {
  readonly work: Work<T>;
  constructor(work: Work<T>) {
    super();
    this.work = work;
  }
}

export default class AsyncLock {
  private readonly queue: WorkItem[];
  private isActive = false;

  constructor() {
    this.queue = [];
  }

  /** Used to queue async work to be done strictly in series, in FIFO order.
   * Note that a work function that internally waits for the lock will deadlock
   * (e.g. lock.doAsync(() => lock.doAsync(f))). */
  async doAsync<T>(work: Work<T>): Promise<T> {
    const item = new WorkItem<T>(work);
    this.queue.push(item as WorkItem);
    this.processNextAsync();
    const result = await item.promise;
    return result;
  }

  private async processNextAsync(): Promise<void> {
    if (this.isActive) {
      return;
    }

    this.isActive = true;
    const nextItem = this.queue.shift();
    if (!nextItem) {
      this.isActive = false;
      return;
    }

    try {
      const result = await nextItem.work();
      nextItem.resolve(result);
    } catch (error) {
      nextItem.reject(error);
    } finally {
      this.isActive = false;
      this.processNextAsync();
    }
  }
}
