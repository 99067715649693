import { createFocusTrap } from 'focus-trap';
import $ from 'jQueryExtensions';

/*! StartNoStringValidationRegion Selectors are not captions. */
const focusTrapKey = 'focusTrap.glow';
const modalActiveAttribute = 'data-modal-active';
const modalActiveSequenceAttribute = 'data-modal-active-sequence';
const modalOpenClass = 'g-modal-open';
/*! EndNoStringValidationRegion */

function* sequenceGenerator() {
	let sequence = 1;
	while (true) {
		yield sequence++;
	}
}

const sequence = sequenceGenerator();

function ModalService() {}

ModalService.prototype.createFocusTrap = (element, options) => {
	const $element = $(element);
	if (getFocusTrap($element)) {
		throw new Error('Element already has a focus trap.');
	}

	const inner = createFocusTrap(element, {
		fallbackFocus: element,
		escapeDeactivates: false,
		clickOutsideDeactivates: (e) =>
			clickOutsideDeactivates(e) || !!(options && options.clickOutsideDeactivates),
		onDeactivate: options && options.onDeactivate,
		allowOutsideClick,
	});

	const result = new FocusTrap(element, inner);
	$element.data(focusTrapKey, result);
	$element.on('remove', onElementRemoved);

	return result;
};

function onElementRemoved(e) {
	getFocusTrap($(e.target)).deactivate();
}

function allowOutsideClick(e) {
	const $target = $(e.target);
	return (
		$target.closest('.toast').length ||
		$target.closest('.v-dialog').length ||
		$target.closest('.v-menu__content').length ||
		$target.is('a[target="_blank"]')
	);
}

function clickOutsideDeactivates(e) {
	return (
		$(e.target).closest('.v-dialog').length || $(e.target).closest('.v-menu__content').length
	);
}

ModalService.prototype.getModalOpenClass = () => {
	return modalOpenClass;
};

ModalService.prototype.getFocusTrap = (element) => {
	return getFocusTrap($(element));
};

function getFocusTrap($element) {
	return $element.data(focusTrapKey);
}

function FocusTrap(element, inner) {
	this._element = element;
	this._inner = inner;
	this._isActivated = false;
}

FocusTrap.prototype.activate = function () {
	if (this._isActivated) {
		this._inner.unpause();
	} else {
		this._inner.activate();
		this._isActivated = true;
	}

	markActive(this._element);
};

function markActive(element) {
	$('[' + modalActiveAttribute + ']').attr(modalActiveAttribute, 'false');
	$('body').addClass(modalOpenClass);
	$(element).attr(modalActiveAttribute, 'true');
	$(element).attr(modalActiveSequenceAttribute, sequence.next().value);
}

FocusTrap.prototype.deactivate = function () {
	this._inner.deactivate();
	this._isActivated = false;
	markInactive(this._element);
};

function markInactive(element) {
	$(element).removeAttr(modalActiveAttribute);
	restorePreviousActiveState();
}

function restorePreviousActiveState() {
	const $modals = $('[' + modalActiveAttribute + ']');
	if ($modals.length === 0) {
		$('body').removeClass(modalOpenClass);
	} else {
		const highestModal = $modals
			.sort((x, y) => {
				const xSequenceAttributeValue = $(x).attr(modalActiveSequenceAttribute);
				const ySequenceAttributeValue = $(y).attr(modalActiveSequenceAttribute);
				const xSequence = xSequenceAttributeValue ? parseInt(xSequenceAttributeValue) : 0;
				const ySequence = ySequenceAttributeValue ? parseInt(ySequenceAttributeValue) : 0;

				return xSequence - ySequence;
			})
			.last();
		getFocusTrap(highestModal).activate();
	}
}

export default new ModalService();
