import { htmlEscape, isNullOrEmpty } from 'StringUtils';
import tooltipServiceExtender from 'TooltipServiceExtender';
import $ from 'jquery';
import 'jquery.ui.core';
import ko from 'knockout';

const Priority = { Low: 0, Normal: 1 };

function TooltipService() {
	this._tooltipCounter = 0;
}

function getTooltipTarget(element) {
	const $element = !(element instanceof $) ? $(element) : element;
	const tooltipTarget = $element.data('tooltip-target');
	return tooltipTarget ? $element.find(tooltipTarget) : $element;
}

TooltipService.prototype.Priority = Priority;

TooltipService.prototype.getOriginalTooltip = ($element) => {
	const overrides = $element.data('_tooltipOverrides');
	return overrides ? overrides[0].value : ($element.attr('data-original-title') || '');
};

TooltipService.prototype.getTooltipTarget = getTooltipTarget;

TooltipService.prototype.populateTooltip = (
    element,
    tooltip,
    title,
    shortcutTooltip,
    placement,
    isError,
    tooltipCssClass
) => {
	tooltip = htmlEscape(ko.unwrap(tooltip) || '');
	title = htmlEscape(ko.unwrap(title) || '');
	shortcutTooltip = shortcutTooltip || '';
	/*! SuppressStringValidation String validation suppressed in initial refactor */
	placement = placement || 'top';

	if (!isNullOrEmpty(tooltip) && !isNullOrEmpty(shortcutTooltip)) {
		/*! SuppressStringValidation String validation suppressed in initial refactor */
		shortcutTooltip = '<br />' + htmlEscape(shortcutTooltip);
	}

	const options = getTooltipTarget(element).data('bs.tooltip').options;
	options.html = true;
	options.placement = placement;
	options.title = getTooltipMarkup(title, tooltip + shortcutTooltip);

	/*! SuppressStringValidation css class */
	const errorCss = isError ? ' tooltip-error' : '';
	const additionalCss = tooltipCssClass ? ' ' + tooltipCssClass : '';
	options.template = '<div class="tooltip' + errorCss + additionalCss + '" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';
};

function getTooltipMarkup(title, tooltip) {
	if (!title || (title === tooltip)) {
		return tooltip;
	} else if (!tooltip) {
		return title;
	}
	/*! SuppressStringValidation Html tags for formatting */
	return '<p class="tooltipHeader">' + title + '</p>' + '<p>' + tooltip + '</p>';
}

TooltipService.prototype.createTooltipWidget = function (element, cssScrollSelector, isManualTrigger) {
	cssScrollSelector = cssScrollSelector || '.g-page-shell .gwShellContainer';
	const $scrollElement = $(cssScrollSelector);
	const $target = getTooltipTarget(element);
	const $tooltip = $target.tooltip({
		container: 'body',
		/*! SuppressStringValidation css class */
		trigger: isManualTrigger ? 'manual' : 'hover',
		delay: { show: 1000, hide: 0 }
	});
	const tooltipId = this._tooltipCounter;

	$tooltip.data('scrollSelector', cssScrollSelector);
	$tooltip.data('tooltipId', tooltipId);
	this._tooltipCounter++;

	if (isManualTrigger) {
		$tooltip.data('bs.tooltip').tip().addClass('tooltip-no-hide');
	}

	$scrollElement.on(`scroll.tooltip${tooltipId}`, (event) => {
		if ($(event.target).is($scrollElement)) {
			$target.tooltip('hide');
		}
	});

	$target.on('input.tooltip blur.tooltip', () => {
		if (!isManualTrigger) {
			$target.tooltip('hide');
		}
	});

	$tooltip.off('remove', dispose).on('remove', dispose);

	tooltipServiceExtender.extend($target);

	return $tooltip;
};

function dispose(event) {
	const $this = $(event?.currentTarget);
	const $scrollElement = $($this.data('scrollSelector'));
	const tooltipId = $this.data('tooltipId');
	const tooltipData = $this.data('bs.tooltip');

	$this.off(`input.tooltip${tooltipId}`);
	$scrollElement.off(`scroll.tooltip${tooltipId}`);

	if (tooltipData.hoverState === 'in') {
		tooltipData.disable();
	}
	tooltipData.destroy();
	if (tooltipData.$tip) {
		tooltipData.$tip.remove();
	}

	const disabledStateObserver = $this.data('gw.disabledStateObserver');
	if (disabledStateObserver) {
		disabledStateObserver.disconnect();
	}
}

TooltipService.prototype.overrideTooltip = ($element, ownerKey, tooltip, priority) => {
	if (tooltip && typeof tooltip === 'object') {
		tooltip = getTooltipMarkup(htmlEscape(tooltip.title), htmlEscape(tooltip.tooltip));
	}

	let overrides = $element.data('_tooltipOverrides');
	priority = $.isNumeric(priority) ? priority : Priority.Normal;

	if (!overrides) {
		const originalTooltip = $element.attr('data-original-title') || '';
		overrides = [{ key: '', value: originalTooltip }];
		$element.data('_tooltipOverrides', overrides);
	} else {
		removeTooltipOverrideCore(overrides, ownerKey);
	}

	const index = getIndexToInsertForOverride(overrides, priority);
	overrides.splice(index, 0, { key: ownerKey, value: tooltip, priority });

	if (index === overrides.length - 1) {
		$element.attr('data-original-title', tooltip);
	}
};

TooltipService.prototype.removeTooltipOverride = ($element, ownerKey) => {
	const overrides = $element.data('_tooltipOverrides');
	if (overrides) {
		removeTooltipOverrideCore(overrides, ownerKey);
		const value = overrides[overrides.length - 1].value || null;
		$element.attr('data-original-title', value);

		if (overrides.length === 1) {
			$element.removeData('_tooltipOverrides');
		}
	}
	};

TooltipService.prototype.hideAll = () => {
	/*! SuppressStringValidation css class */
	$('.tooltip').not('.tooltip-no-hide').tooltip('hide');
};

function getIndexToInsertForOverride(overrides, priority) {
	if (priority === Priority.Normal) {
		return overrides.length;
	}

	let i;
	for (i = 0; i < overrides.length; i++) {
		const nextItem = overrides[i + 1];
		if (!nextItem || nextItem.priority > priority) {
			break;
		}
	}

	return i + 1;
}

function removeTooltipOverrideCore(overrides, ownerKey) {
	for (let i = overrides.length - 1; i >= 0; i--) {
		if (overrides[i].key === ownerKey) {
			overrides.splice(i, 1);
			break;
		}
	}
}

export default new TooltipService();
