import { getDependencyValue } from "Dependency2";
import { decimalToString, isNumber } from "NumericService";
import { State } from "StateConstants";
import { restrictedPlaceholder } from "StringUtils";

export default class Measure {
  readonly magnitude: number;
  readonly unit: string;

  constructor(magnitude: number, unit: string) {
    this.magnitude = magnitude;
    this.unit = unit;
  }

  static formatWithScale(entity: unknown, measure: Measure, scale: number, dynamicScale: string): string {
    if (!measure) {
      return "";
    }

    if (isNumber(scale)) {
      if (dynamicScale) {
        const { value, state } = getDependencyValue(entity, dynamicScale);

        if (state === State.NotAvailable) {
          return restrictedPlaceholder;
        } else if (state === State.NotLoaded) {
          return "";
        }
        if (isNumber(value)) {
          scale = value;
        }
      }

      return toMeasureString(decimalToString(measure.magnitude.toString(), scale), measure.unit);
    }
    return measure.toString();
  }

  toString(): string {
    return this.toStringCore((value) => value.toString());
  }

  toLocaleString(locale?: string, options?: Intl.NumberFormatOptions): string {
    return this.toStringCore((value) => value.toLocaleString(locale, options));
  }

  private toStringCore(toStringConverter: (value: number) => string): string {
    if (this.magnitude || (this.magnitude === 0 && this.unit)) {
      return toMeasureString(toStringConverter(this.magnitude), this.unit);
    }

    return "";
  }
}

function toMeasureString(value: string, unit?: string): string {
  return unit ? `${value} ${unit}` : value;
}
