import captionService from 'CaptionService';
import Colour from 'Colour';
import colourService from 'ColourService';
import constants from 'Constants';
import Dependency from 'Dependency';
import ko from 'knockout';
import themeHelper from 'ThemeHelper';
import themeItems from 'ThemeItems';

/*! StartNoStringValidationRegion constants */
const VerticalAlignments = {
	Top: 'top',
	Middle: 'middle',
	Bottom: 'bottom',
};
/*! EndNoStringValidationRegion */
export default class LabelViewModel {
	constructor(params) {
		this._labelText = ko.observable(params.labelText);
		this._backgroundColorPath = params.backgroundColor;

		this.anchor = params.anchor;
		this.propertyName = params.propertyName;
		this.dataItem = params.dataItem;
		this.isEntityBoundLabel = params.isEntityBoundLabel;
		this.labelNotificationType = params.labelNotificationType;
		this.labelAlignment = ko.observable(params.labelAlignment);
		this.labelPosition = ko.pureComputed(this._getLabelPosition, this);
		this.backgroundColor = ko.pureComputed(this._getBackgroundColorString, this);
		this.textColor = ko.pureComputed(this._getTextColorString, this);
		this.labelStyleClass =
			params.labelType !== constants.LabelTypes.Notification
				? getNormalLabelStyleClass(
						params.foregroundThemeColor,
						params.textSize,
						params.padding
				)
				: getNotificationLabelStyleClass(
						this.labelNotificationType,
						params.textSize,
						params.padding
				);

		this.labelText = ko.pureComputed({
			read: this._getLabelText,
			write: this._setLabelText,
			owner: this,
		});
	}

	_getBackgroundColorString() {
		const color = getColor(this);
		return color && color.toString();
	}

	_getTextColorString() {
		const color = getColor(this);
		return color && color.getContrastingColour().toString();
	}

	_getLabelPosition() {
		/*! StartNoStringValidationRegion these are just label classes */
		let alignment = this.labelAlignment();
		if (alignment) {
			alignment = alignment.toUpperCase();

			if (alignment === constants.LabelAlignments.Justify.toUpperCase()) {
				return { vertical: VerticalAlignments.Top, horizontal: alignment.toLowerCase() };
			}
			if (alignment === constants.LabelAlignments.Center.toUpperCase()) {
				return { vertical: VerticalAlignments.Middle, horizontal: alignment.toLowerCase() };
			}
			if (alignment.indexOf('TOP') === 0) {
				return {
					vertical: VerticalAlignments.Top,
					horizontal: alignment.slice(3, alignment.length).toLowerCase(),
				};
			}
			if (alignment.indexOf('CENTER') === 0) {
				return {
					vertical: VerticalAlignments.Middle,
					horizontal: alignment.slice(6, alignment.length).toLowerCase(),
				};
			}
			if (alignment.indexOf('BOTTOM') === 0) {
				return {
					vertical: VerticalAlignments.Bottom,
					horizontal: alignment.slice(6, alignment.length).toLowerCase(),
				};
			}
		}

		return { vertical: VerticalAlignments.Top, horizontal: 'left' };
		/*! EndNoStringValidationRegion */
	}

	_getLabelText() {
		if (this.isEntityBoundLabel) {
			const dataItem = this.dataItem();

			return dataItem ? ko.unwrap(dataItem[this.propertyName]) : '';
		} else {
			const labelText = this._labelText();
			if (labelText) {
				return typeof labelText === 'object'
					? captionService.getStringFromInfo(labelText)
					: labelText;
			}

			return '';
		}
	}

	_setLabelText(value) {
		this._labelText(value);
	}
}

function getColor(self) {
	const backgroundColorPath = self._backgroundColorPath;
	if (!backgroundColorPath || !self.dataItem) {
		return null;
	}
	const colorValue = Dependency.getValue(ko.unwrap(self.dataItem), backgroundColorPath);
	if (colorValue instanceof Colour) {
		return colorValue;
	}
	return colorValue && colourService.fromArgbIntValue(colorValue);
}

function getTextSizeClass(labelTextSize) {
	/*! StartNoStringValidationRegion these are just text size classes */
	switch (ko.unwrap(labelTextSize)) {
		case constants.LabelTextSizes.ExtraSmall:
			return 'g-extra-small-text';

		case constants.LabelTextSizes.ExtraExtraLarge:
			return 'g-extra-extra-large-text';

		case constants.LabelTextSizes.ExtraLarge:
			return 'g-extra-large-text';

		case constants.LabelTextSizes.Medium:
			return 'g-medium-text';

		case constants.LabelTextSizes.Large:
			return 'g-large-text';

		default:
			return 'g-small-text';
	}
	/*! EndNoStringValidationRegion */
}

function getPaddingClass(labelPadding) {
	/*! StartNoStringValidationRegion these are just padding size classes */
	switch (ko.unwrap(labelPadding)) {
		case constants.LabelPaddingSizes.None:
			return '';

		case constants.LabelPaddingSizes.Small:
			return 'g-small-padding';

		case constants.LabelPaddingSizes.Medium:
			return 'g-medium-padding';

		case constants.LabelPaddingSizes.Large:
			return 'g-large-padding';

		default:
			return '';
	}
	/*! EndNoStringValidationRegion */
}

function getNormalLabelStyleClass(labelForegroundThemeColor, labelTextSize, labelPadding) {
	const textSizeCss = getTextSizeClass(labelTextSize);
	const foregroundCss = themeHelper.getForegroundCss(
		ko.unwrap(labelForegroundThemeColor),
		themeItems.GeneralText.Value
	);
	const paddingCss = getPaddingClass(labelPadding);
	/*! SuppressStringValidation CSS */
	return ['normal-label', textSizeCss, foregroundCss, paddingCss]
		.filter((style) => style)
		.join(' ');
}

function getNotificationLabelStyleClass(labelNotificationType, labelTextSize, labelPadding) {
	/*! StartNoStringValidationRegion these are just label classes */
	const styleClass = ['notification-label'];
	switch (ko.unwrap(labelNotificationType)) {
		case constants.LabelNotificationTypes.Error:
			styleClass.push('g-error-label');
			break;
		case constants.LabelNotificationTypes.Success:
			styleClass.push('g-success-label');
			break;
		case constants.LabelNotificationTypes.Warning:
			styleClass.push('g-warning-label');
			break;
		case constants.LabelNotificationTypes.MessageError:
			styleClass.push('g-message-error-label');
			break;
		default:
			styleClass.push('g-information-label');
	}

	styleClass.push(getTextSizeClass(labelTextSize));
	const paddingCss = getPaddingClass(labelPadding);
	if (paddingCss) {
		styleClass.push(paddingCss);
	}

	return styleClass.join(' ');
	/*! EndNoStringValidationRegion */
}
