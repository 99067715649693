import errors from 'Errors';
import { getFullText } from 'ExpressionVisitorUtils';
import _ from 'underscore';
import { ExpressionVisitor } from 'wtg-expressions';

export default class DependencyExtractorVisitor extends ExpressionVisitor {
	constructor(options) {
		super();
		this._dependencyPaths = [];
		this._extractPathsOnly = options && options.extractPathsOnly;
		this._path = undefined;
		this._topLevel = true;
		this._hasPathPart = false;
	}

	static visitDependency(dependencyPath, options) {
		if (!dependencyPath) {
			return [];
		}

		const tree = DependencyExtractorVisitor.getDependencyTree(
			dependencyPath,
			DependencyExtractorVisitor.TreeName.Dependency,
			onDependencyError
		);
		const visitor = new DependencyExtractorVisitor(options);
		visitor.visit(tree);
		return _.uniq(visitor._dependencyPaths);
	}

	visitEmbedded_path() {}

	visitPath(ctx) {
		if (this._extractPathsOnly) {
			const backupPath = this._path;
			this.visitChildren(ctx);
			if (this._path && this._path !== backupPath) {
				this._dependencyPaths.push(this._path);
			}
			this._path = backupPath;
		} else {
			if (this._topLevel) {
				this._topLevel = false;
				this.visitChildren(ctx);
				this._topLevel = true;

				if (this._hasPathPart) {
					this._dependencyPaths.push(getFullText(ctx));
				}
				this._hasPathPart = false;
			} else if (!this._hasPathPart) {
				this.visitChildren(ctx);
			}
		}
	}

	visitPath_part(ctx) {
		if (this._extractPathsOnly) {
			const propertyName = ctx.children[0].getText();
			if (this._path) {
				this._path += '.' + propertyName;
			} else {
				this._path = propertyName;
			}
			this.visitChildren(ctx);
		} else {
			this._hasPathPart = true;
		}
	}
}

function onDependencyError(msg) {
	throw new errors.DependencyError(msg);
}
