import type { EntityType } from "BreezeExtensions";

const entityTypes = new Set<EntityType>();

export function registerCalculatedProperties(entityType: EntityType): void {
  entityTypes.add(entityType);
}

export function resetCalculatedProperties(): void {
  entityTypes.forEach((t) => t.resetCalculatedProperties());
  entityTypes.clear();
}
