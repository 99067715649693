import { DeferredPromise } from "DeferredPromise";
import { computed, ignoreDependencies, isObservable, observable, type Computed, type Observable } from "knockout.base";

export interface ExtendedComputed<T> extends Computed<T> {
  loaded: Observable<boolean | undefined>;
  refresh: () => void;
}

export function lazyObservable<TValue, TContext>(
  callback: (this: TContext | undefined, value?: TValue) => void,
  context?: TContext,
  initialValue?: TValue
): ExtendedComputed<TValue> {
  const value = observable(initialValue);
  return lazyComputed(callback, value, context);
}

export function lazyComputed<TValue, TContext>(
  callback: (this: TContext | undefined, value?: TValue) => void,
  value: Observable<TValue | undefined>,
  context?: TContext
): ExtendedComputed<TValue> {
  const result = computed({
    read() {
      //if it has not been loaded, execute the supplied function
      if (!result.loaded.peek()) {
        ignoreDependencies(() => {
          callback.call(context, value());
        });
      }
      //always return the current value
      return value();
    },
    write(newValue: TValue | undefined) {
      //indicate that the value is now loaded and set it
      result.loaded(true);
      value(newValue);
    },
    deferEvaluation: true, //do not evaluate immediately when created
  }) as ExtendedComputed<TValue>;

  //expose the current state, which can be bound against
  result.loaded = observable<boolean>();

  //load it again
  result.refresh = (): void => {
    result.loaded(false);
    callback.call(context);
  };

  return result;
}

export async function waitForValueAsync<TValue>(observable: Observable<TValue>, targetValue: TValue): Promise<TValue> {
  if (!isObservable(observable)) {
    return observable;
  }

  const value = observable.peek();
  const isTargetValueSpecified = typeof targetValue !== "undefined";
  if (value !== null && typeof value !== "undefined" && (!isTargetValueSpecified || value === targetValue)) {
    return value;
  }

  const deferred = new DeferredPromise<TValue>();
  const subscription = observable.subscribe((value: TValue) => {
    if (value !== null && typeof value !== "undefined" && (!isTargetValueSpecified || value === targetValue)) {
      deferred.resolve(value);
    }
  });

  try {
    return await deferred.promise;
  } finally {
    subscription.dispose();
  }
}
