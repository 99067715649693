import appConfig from 'AppConfig';
import constants from 'Constants';
import errors from 'Errors';
import { getFullText } from 'ExpressionVisitorUtils';
import Promise from 'bluebird';
import ko from 'knockout';
import { ExpressionParser, ExpressionVisitor } from 'wtg-expressions';
import {
	Embedded_pathContext,
	Func_sequenceContext,
	PathContext,
	Path_partContext,
	SlashSeparatorContext,
} from 'wtg-expressions/lib/ExpressionParser';
import booleanOperators from './BooleanOperators';
import collectionFunctions from './CollectionFunctions';
import dateFunctions from './DateFunctions';
import equalityOperators from './EqualityOperators';
import pathEvaluator from './PathEvaluator';
import polymorphicFunctions from './PolymorphicFunctions';
import { asyncStrategy, syncStrategy } from './Strategies';
import stringFunctions from './StringFunctions';
import { getAvailableResult } from './Utils';

export default class DependencyV2Visitor extends ExpressionVisitor {
	constructor() {
		super();
		this._dependencyType = DependencyType.Other;
		this._state = getState(true);
	}

	static avoidLoading(callback) {
		try {
			avoidLoadingSemaphore++;
			return callback();
		} finally {
			avoidLoadingSemaphore--;
		}
	}

	static isValidDependency(dependency) {
		return this.isValid(dependency, this.TreeName.Dependency);
	}

	static visitAsync(data, dependency, options) {
		return Promise.try(() => {
			return this._visit(asyncStrategy, data, dependency, options);
		}).tapCatch((error) => {
			setErrorData(error, data, dependency);
		});
	}

	static visitSync(data, dependency, options) {
		try {
			return this._visit(syncStrategy, data, dependency, options);
		} catch (error) {
			setErrorData(error, data, dependency);
			throw error;
		}
	}

	static _visit(strategy, data, dependency, options) {
		let parsed = reducerCache.get(dependency);
		if (!parsed) {
			parsed = this._parseDependency(dependency);
			if (reducerCache.size + 1 > appConfig.dependencyVisitorCacheLimit) {
				reducerCache.clear();
			}
			reducerCache.set(dependency, parsed);
		}

		options = options || emptyOptions;
		this._validateOptions(strategy, options, parsed);
		if (!parsed.reducer) {
			return getAvailableResult(data);
		}

		data = ko.unwrap(data);
		return parsed.reducer(data, getContext(strategy, data, options));
	}

	static _parseDependency(dependency) {
		const visitor = this._getVisitor();
		const tree = this.getDependencyTree(
			dependency,
			this.TreeName.Dependency,
			onDependencyError.bind(undefined, dependency)
		);

		visitor.visit(tree);
		const reducer = visitor._state.operands[0];
		const isSelfDependency = !reducer;
		return {
			dependencyType: isSelfDependency ? DependencyType.BindingPath : visitor._dependencyType,
			reducer,
		};
	}

	static _getVisitor() {
		return new DependencyV2Visitor();
	}

	static _validateOptions(strategy, options, parsed) {
		if (options.valueToSet !== undefined) {
			switch (parsed.dependencyType) {
				case DependencyType.MacroPath:
					throw new errors.DependencyOptionsError(
						'valueToSet cannot be specified for a macro path.'
					);

				case DependencyType.PropertyPath:
					if (strategy !== asyncStrategy) {
						throw new errors.DependencyOptionsError(
							'valueToSet cannot be used with the sync strategy.'
						);
					}
					break;

				default:
					throw new errors.DependencyOptionsError(
						'valueToSet cannot be specified for a dependency that is not a property path.'
					);
			}

			if (options.skipLastSegmentEvaluation) {
				throw new errors.DependencyOptionsError(
					'skipLastSegmentEvaluation cannot be true if valueToSet is specified.'
				);
			}
		} else if (options.skipLastSegmentEvaluation) {
			if (parsed.dependencyType === DependencyType.Other) {
				throw new errors.DependencyOptionsError(
					'skipLastSegmentEvaluation cannot be true for a dependency that is not a binding path.'
				);
			}
		}
	}

	visitAdddays(ctx) {
		this._visitFunction(ctx, dateFunctions.addDays, 1);
	}

	visitAll(ctx) {
		this._visitFunction(ctx, collectionFunctions.all, 1);
	}

	visitAny(ctx) {
		this._visitFunction(ctx, collectionFunctions.any, 1);
	}

	visitBoolean_operator(ctx) {
		const state = this._state;
		switch (ctx.children[0].symbol.type) {
			case ExpressionParser.AND:
				state.operators.push(booleanOperators.and);
				break;
			case ExpressionParser.OR:
				state.operators.push(booleanOperators.or);
				break;
			default:
				throw new errors.DependencyError(`Unknown boolean operator '${ctx.getText()}'.`);
		}
	}

	visitContains(ctx) {
		this._visitFunction(ctx, polymorphicFunctions.contains, 1);
	}

	visitDependency_path(ctx) {
		this.visitChildren(ctx);

		const { children } = ctx.children[0];
		const firstChild = children[0];
		if (firstChild instanceof Path_partContext) {
			const lastChild = children[children.length - 1];
			if (isPropertyName(lastChild)) {
				if (firstChild.MACRO()) {
					this._dependencyType = DependencyType.MacroPath;
				} else {
					this._dependencyType = DependencyType.PropertyPath;
				}
			} else if (
				lastChild instanceof SlashSeparatorContext &&
				isPropertyName(children[children.length - 2])
			) {
				this._dependencyType = DependencyType.BindingPath;
			}
		}
	}

	visitDependency_expr(ctx) {
		this.visitChildren(ctx);

		const state = this._state;
		state.operands[0] = booleanOperators.coalesce.bind(undefined, state.operands[0]);
	}

	visitEndswith(ctx) {
		this._visitFunction(ctx, stringFunctions.endsWith, 1);
	}

	visitEqualsOperator() {
		this._state.operators.push(equalityOperators.equal);
	}

	visitExpr(ctx) {
		const parentState = this._state;
		const state = (this._state = getState());
		this.visitChildren(ctx);

		// Apply binary operators not yet handled by visitExpr_part.
		while (state.operators.length) {
			const operator = state.operators.shift();
			state.operands.splice(
				0,
				2,
				operator.bind(undefined, state.operands[0], state.operands[1])
			);
		}

		parentState.operands.push(...state.operands);
		this._state = parentState;
	}

	visitExpr_part(ctx) {
		this.visitChildren(ctx);

		const state = this._state;
		while (
			state.operators.length &&
			!isLowPriorityOperator(state.operators[state.operators.length - 1])
		) {
			const operator = state.operators.pop();
			if (isUnaryOperator(operator)) {
				const itemIndex = state.operands.length - 1;
				state.operands[itemIndex] = operator.bind(undefined, state.operands[itemIndex]);
			} else {
				const itemIndex = state.operands.length - 2;
				state.operands.splice(
					itemIndex,
					2,
					operator.bind(
						undefined,
						state.operands[itemIndex],
						state.operands[itemIndex + 1]
					)
				);
			}
		}
	}

	visitFirst(ctx) {
		this._visitFunction(ctx, collectionFunctions.first, 0);
	}

	visitGreaterOperator() {
		this._state.operators.push(equalityOperators.greaterThan);
	}

	visitGreaterOrEqualOperator() {
		this._state.operators.push(equalityOperators.greaterThanOrEqual);
	}

	visitIndexof(ctx) {
		this._visitFunction(ctx, stringFunctions.indexOf, 1);
	}

	visitIsdefault(ctx) {
		const propertyName = getPrecedingPropertyName(ctx, true);
		if (!propertyName) {
			throw new errors.DependencyError(
				'IsDefault() can only be specified after a property name.'
			);
		}

		this._addFunction(pathEvaluator.isDefault, [propertyName]);
	}

	visitCount(ctx) {
		this._visitFunction(ctx, collectionFunctions.count, 0);
	}

	visitDate(ctx) {
		this._visitFunction(ctx, dateFunctions.date, 0);
	}

	visitDatetime(ctx) {
		this._visitFunction(ctx, dateFunctions.dateTime, 0);
	}

	visitLength(ctx) {
		this._visitFunction(ctx, stringFunctions.length, 0);
	}

	visitLessOperator() {
		this._state.operators.push(equalityOperators.lessThan);
	}

	visitLessOrEqualOperator() {
		this._state.operators.push(equalityOperators.lessThanOrEqual);
	}

	visitNot() {
		this._state.operators.push(booleanOperators.not);
	}

	visitNotEqualsOperator() {
		this._state.operators.push(equalityOperators.notEqual);
	}

	visitOrderby_core(ctx) {
		const parentState = this._state;
		const clauses = [];

		let reducer;
		for (let i = 0; i < ctx.children.length; i++) {
			const child = ctx.children[i];
			if (child instanceof PathContext) {
				if (reducer) {
					clauses.push({ reducer, isDescending: false });
				}
				this._state = getState();
				this.visit(child);
				reducer = this._state.operands[0];
			} else if (child.symbol && child.symbol.type === ExpressionParser.DESC) {
				clauses.push({ reducer, isDescending: true });
				reducer = undefined;
			}
		}
		if (reducer) {
			clauses.push({ reducer, isDescending: false });
		}

		this._state = parentState;
		this._addFunction(collectionFunctions.orderBy, [clauses]);
	}

	visitOriginalvalue(ctx) {
		const propertyName = getPrecedingPropertyName(ctx, false);
		if (!propertyName) {
			throw new errors.DependencyError(
				'OriginalValue() can only be specified immediately after a property name.'
			);
		}

		const state = this._state;
		state.operands[state.operands.length - 1] = pathEvaluator.originalValue.bind(
			undefined,
			propertyName
		);
	}

	visitPath(ctx) {
		const parentState = this._state;
		const state = (this._state = getState(parentState.isTopLevel));
		this.visitChildren(ctx);

		if (isMacroPathPart(ctx.children[0])) {
			parentState.operands.push(
				pathEvaluator.macroPath.bind(undefined, getFullText(ctx), state.operands)
			);
		} else {
			const isEmbeddedPath = ctx.parentCtx instanceof Embedded_pathContext;
			if (isEmbeddedPath) {
				parentState.operands.push(pathEvaluator.rootPath.bind(undefined, state.operands));
			} else if (state.operands.length === 1) {
				parentState.operands.push(state.operands[0]);
			} else {
				parentState.operands.push(pathEvaluator.path.bind(undefined, state.operands));
			}
		}

		this._state = parentState;
	}

	visitPath_part(ctx) {
		if (ctx.MACRO()) {
			if (ctx.parentCtx.children[0] !== ctx) {
				throw new errors.DependencyError(
					'Macro symbol can only be the first property in a path.'
				);
			}
			return;
		}

		let nextCtx;
		if (ctx.children.length > 1) {
			nextCtx = ctx.children[1];
		} else {
			const parentChildren = ctx.parentCtx.children;
			nextCtx = parentChildren[parentChildren.indexOf(ctx) + 1];
		}

		const propertyName = ctx.children[0].getText();
		const separator = nextCtx && nextCtx.getText();
		this._visitPropertyValueFunction(propertyName, separator);

		this.visitChildren(ctx);
	}

	visitSkipQ(ctx) {
		this._visitFunction(ctx, collectionFunctions.skip, 1);
	}

	visitStartswith(ctx) {
		this._visitFunction(ctx, stringFunctions.startsWith, 1);
	}

	visitSubstring(ctx) {
		this._visitFunction(ctx, stringFunctions.substring, 2);
	}

	visitSum(ctx) {
		this._visitFunction(ctx, collectionFunctions.sum, 1);
	}

	visitTake(ctx) {
		this._visitFunction(ctx, collectionFunctions.take, 1);
	}

	visitTerminal(ctx) {
		const evaluator = terminalEvaluator[ctx.symbol.type];
		if (evaluator) {
			this._state.operands.push(evaluator(ctx));
		}
	}

	visitTolower(ctx) {
		this._visitFunction(ctx, stringFunctions.toLower, 0);
	}

	visitToupper(ctx) {
		this._visitFunction(ctx, stringFunctions.toUpper, 0);
	}

	visitTrimmilliseconds(ctx) {
		this._visitFunction(ctx, dateFunctions.trimMilliseconds, 0);
	}

	visitTrimseconds(ctx) {
		this._visitFunction(ctx, dateFunctions.trimSeconds, 0);
	}

	visitType_name(ctx) {
		this._state.operands.push(pathEvaluator.ofType.bind(undefined, ctx.getText()));
	}

	visitUrlencode(ctx) {
		this._visitFunction(ctx, stringFunctions.urlEncode, 0);
	}

	visitUtcdatetime(ctx) {
		this._visitFunction(ctx, dateFunctions.utcDateTime, 0);
	}

	visitWhere(ctx) {
		this._visitFunction(ctx, collectionFunctions.where, 1);
	}

	_addFunction(fn, args) {
		const state = this._state;
		const index = state.operands.length - 1;
		const previousOperand = state.operands[index];

		if (previousOperand) {
			state.operands[index] = fn.bind(undefined, previousOperand, ...args);
		} else {
			state.operands.push(fn.bind(undefined, identity, ...args));
		}
	}

	_visitFunction(ctx, fn, argCount) {
		let args;
		if (argCount > 0) {
			const parentState = this._state;
			const state = (this._state = getState());
			this.visitChildren(ctx);
			args = state.operands;
			if (args.length !== argCount) {
				args.length = argCount;
			}
			this._state = parentState;
		} else {
			args = [];
		}

		this._addFunction(fn, args);
	}

	_visitPropertyValueFunction(propertyName, separator) {
		const state = this._state;
		state.operands.push(
			pathEvaluator.property.bind(undefined, propertyName, separator, state.isTopLevel)
		);
	}
}

let avoidLoadingSemaphore = 0;

const reducerCache = new Map();
const emptyOptions = Object.freeze({});

const nullLiteral = literal.bind(undefined, null);
const falseLiteral = literal.bind(undefined, false);
const trueLiteral = literal.bind(undefined, true);

const terminalEvaluator = {
	[ExpressionParser.FALSE]() {
		return falseLiteral;
	},

	[ExpressionParser.FLOAT](ctx) {
		return literal.bind(undefined, parseFloat(ctx.getText()));
	},

	[ExpressionParser.INT](ctx) {
		return literal.bind(undefined, parseInt(ctx.getText()));
	},

	[ExpressionParser.NULL]() {
		return nullLiteral;
	},

	[ExpressionParser.PARAM](ctx) {
		const name = ctx.getText();
		const index = parseInt(name.substring(2)) - 1;
		if (isNaN(index) || index < 0) {
			throw new errors.DependencyError(`Parameter '${name}' is not in a supported format.`);
		}
		return param.bind(undefined, index);
	},

	[ExpressionParser.QUOTED_STRING](ctx) {
		return literal.bind(undefined, unquoteString(ctx.getText()));
	},

	[ExpressionParser.TRUE]() {
		return trueLiteral;
	},
};

export function clearReducerCache() {
	reducerCache.clear();
}

function getContext(strategy, data, options) {
	return {
		options,
		rootData: data,
		shouldAvoidLoading,
		strategy,
	};
}

function getPrecedingPropertyName(ctx, allowPrecedingFunction) {
	while (ctx.parentCtx) {
		const { parentCtx } = ctx;
		if (parentCtx instanceof Func_sequenceContext) {
			if (!allowPrecedingFunction && parentCtx.children[0] !== ctx) {
				break;
			}
		} else if (parentCtx instanceof Path_partContext) {
			const { children } = parentCtx;
			if (children.indexOf(ctx) === 2) {
				return children[0].getText();
			}
			break;
		} else if (parentCtx instanceof PathContext) {
			break;
		}
		ctx = parentCtx;
	}
}

function getState(isTopLevel = false) {
	return {
		isTopLevel,
		operands: [],
		operators: [],
	};
}

function identity(data) {
	return getAvailableResult(data);
}

function isLowPriorityOperator(operator) {
	return operator === booleanOperators.or;
}

function isMacroPathPart(ctx) {
	return ctx instanceof Path_partContext && ctx.MACRO() != null;
}

function isPropertyName(ctx) {
	return ctx instanceof Path_partContext && ctx.children.length === 1;
}

function isUnaryOperator(operator) {
	return operator === booleanOperators.not;
}

function literal(value) {
	return { state: constants.States.Available, value };
}

function onDependencyError(expression, message) {
	throw new errors.DependencyError(`Invalid expression '${expression}'. Error: ${message}`);
}

function param(index, _data, context) {
	const { params } = context.options;
	if (!params || index >= params.length) {
		throw new errors.DependencyError(
			`Invalid parameter access. Requested position: ${index + 1}, available count: ${
				params ? params.length : 0
			}`
		);
	}
	return { state: constants.States.Available, value: params[index] };
}

function setErrorData(error, data, dependency) {
	data = ko.unwrap(data);
	errors.addErrorData(error, [
		{ name: 'Dependency', value: dependency },
		{
			name: 'EntityType',
			value: data && data.entityType ? data.entityType.interfaceName : null,
		},
	]);
}

function shouldAvoidLoading() {
	return avoidLoadingSemaphore > 0;
}

function unquoteString(text) {
	return text.substring(1, text.length - 1);
}

export const DependencyType = {
	Other: 0,
	BindingPath: 1,
	PropertyPath: 2,
	MacroPath: 3,
};
