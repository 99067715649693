import base64 from 'base64';

function EncodingUtils()
{
}

EncodingUtils.prototype.encodeData = (data) => {
	return base64.encode(typeof data !== 'string' ? data.toString() : data);
};

EncodingUtils.prototype.decodeDataToBoolean = (data, defaultValue) => {
	return data ? base64.decode(data.toString(), /*outType*/ 2) === 'true' : (defaultValue || false);
};

EncodingUtils.prototype.decodeData = (data, defaultValue) => {
	return data ? base64.decode(data.toString(), /*outType*/ 2) : (defaultValue || '');
};

EncodingUtils.prototype.encodeDataForUri = (data) => {
	const input = typeof data !== 'string' ? data.toString() : data;
	const encoded = base64.encode(input, /*unicode*/ false, /*safe*/ true);
	// remove trailing '='
	return encoded.replace(/=+$/, '');
};

EncodingUtils.prototype.decodeDataForUri = (data) => {
	if (!data) {
		return '';
	}

	if (data.length % 4 > 0) {
		// append trailing '='
		const dataLengthWithPadding = data.length + (4 - (data.length % 4));
		data = data.padEnd(dataLengthWithPadding, '=');
	}
	return base64.decode(data, /*outType*/ 1, /*safe*/ true);
};

export default new EncodingUtils();
