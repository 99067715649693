/*! StartNoStringValidationRegion DateTime Formats */
export enum DateTimeType {
  Date = "Date",
  DateTime = "DateTime",
  DateTimeUtc = "DateTimeUtc",
  DateTimeOffset = "DateTimeOffset",
  Duration = "Duration",
  Time = "Time",
}
/*! EndNoStringValidationRegion */
