import captionService from 'CaptionService';
import bindingEvaluator from 'BindingEvaluator';
import { useBindingContext } from 'BindingContextHook';

export function useCaption(caption, captionOverride, bindingPath, skipCaptionFallback) {
	if (!captionService.isEmptyCaption(caption)) {
		return getCaption(caption);
	}

	if (!captionService.isEmptyCaption(captionOverride)) {
		return getCaption(captionOverride);
	}

	return getFallbackCaption(bindingPath, skipCaptionFallback);
}

function getCaption(caption) {
	const resKey = caption.key || caption.ResKey;
	if (resKey) {
		const fallback = caption.text || caption.Fallback;
		return captionService.getCaptionFromKey(resKey, fallback);
	}
	return caption;
}

function getFallbackCaption(bindingPath, skipCaptionFallback) {
	let caption;
	if (!skipCaptionFallback) {
		if (bindingPath) {
			const bindingContext = useBindingContext();
			const entityType = bindingContext && bindingContext.value && bindingContext.value.entityType;
			if (entityType) {
				const bindingInformation = bindingEvaluator.getEntityBindingInfo(entityType, bindingPath);
				caption = captionService.getCaptionFromField(bindingInformation);
			}
		}
	}

	return getCaption(caption || '');
}
