import captionService from 'CaptionService';
import errors from 'Errors';
import { loadVueComponentAsync, loadVueExtenderAsync } from 'ModuleLoader';
import { kebabToPascalCase } from 'StringUtils';
import GlowWrapper from 'VueComponents/GlowWrapper.vue';

export function getGlowWrapperInstance(element) {
	let vm = getVueInstance(element);
	if (vm) {
		if (vm && vm.visibilityCondition) {
			if (vm.$children?.length === 1) {
				vm = vm.$children[0];
			} else {
				throw new Error(`A VisibilityWrapper should wrap only one child element. Number of children is ${vm.$children?.length}.`);
			}
		} else {
			while (vm && !(vm instanceof GlowWrapper) && !(vm.extension)) {
				vm = vm.$parent;
			}
		}
		if (!(vm instanceof GlowWrapper) && !(vm?.extension)) {
			throw new Error('Could not find GlowWrapper instance.');
		}
	}

	return vm;
}

export function getVueInstance(element) {
	return element.__vue__;
}

function getAllComponentContainers(container) {
	const componentContainers = Array.from(container.querySelectorAll('.glow-vue-component'));
	/*! SuppressStringValidation html tag name */
	const templates = Array.from(container.querySelectorAll('template'));

	if (templates.length) {
		const templatedComponentContainers = templates.map((template) => getAllComponentContainers(template.content)).flat();

		return [...componentContainers, ...templatedComponentContainers];
	}

	return componentContainers;
}

export async function loadVueComponentDefinitionsAsync(container) {
	/*! SuppressStringValidation html class name */
	const componentsContainers = getAllComponentContainers(container);
	const componentsToLoad = new Set();
	const extendersToLoad = new Set();

	for (const componentContainer of componentsContainers) {
		componentsToLoad.add(kebabToPascalCase(componentContainer.tagName));

		/*! SuppressStringValidation html attribute name */
		const componentName = componentContainer.getAttribute('inner-component');
		if (componentName) {
			componentsToLoad.add(componentName);
		}

		/*! SuppressStringValidation html attribute name */
		const extender = componentContainer.getAttribute('extender');
		if (extender) {
			extendersToLoad.add(extender);
		}
	}

	const results = {};
	const componentsPromise = Promise.all(
		Array.from(componentsToLoad).map(async (componentName) => {
			try {
				const componentDefinition = await loadVueComponentAsync(componentName);
				results[componentName] = componentDefinition;
			} catch (error) {
				if (error instanceof errors.UnknownModuleError) {
					throw new errors.ConfigurableModuleNotFoundError(
						captionService.getString(
							'37ad8c8e-c95b-4c30-95fa-3654b1158962',
							'Unable to load Material component "{0}" because it does not exist. If this is a custom component, please check that you have entered the name correctly.',
							componentName
						),
						error
					);
				}
				throw error;
			}
		})
	);

	const extendersPromise = Promise.all(
		Array.from(extendersToLoad).map((extender) => {
			return loadVueExtenderAsync(extender);
		})
	);

	await Promise.all([componentsPromise, extendersPromise]);
	return results;
}
