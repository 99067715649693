import ajaxService from "AjaxService";
import errorHandler from "ErrorHandler";
import global from "Global";
import { joinUri } from "UriUtils";

export type SemaphoreUsage = {
  UsageCount: number;
  ParentTableCode: string;
  ParentUserId: string;
  CreateDateTimeUTC: string;
  UserFullName: string;
};

type SemaphoreHandle = {
  Usages: SemaphoreUsage[];
  Token: string;
};

export class SemaphoreProvider {
  private disposed: boolean;
  private tokens: string[];

  constructor() {
    this.disposed = false;
    this.tokens = [];
  }

  async acquireHandleAsync(entityPK: string): Promise<SemaphoreHandle | undefined> {
    if (this.disposed) {
      throw new Error("Cannot access a disposed object.");
    }

    /*! SuppressStringValidation URI is not translatable */
    const url = joinUri(global.serviceUri, "api/semaphores/pendingUserAction/acquire");
    try {
      const handle = await ajaxService.ajaxAsync<SemaphoreHandle>({
        url,
        data: JSON.stringify(entityPK),
        type: "post",
        contentType: "application/json",
      });
      if (handle.Token) {
        this.tokens.push(handle.Token);
      }
      return handle;
    } catch (error) {
      errorHandler.reportError(error);
      return undefined;
    }
  }

  async releaseHandlesAsync(tokens: string[]): Promise<void> {
    if (this.disposed) {
      throw new Error("Cannot access a disposed object.");
    }

    /*! SuppressStringValidation URI is not translatable */
    const url = joinUri(global.serviceUri, "api/semaphores/release");
    try {
      await ajaxService.ajaxAsync({
        url,
        data: JSON.stringify(tokens),
        type: "post",
        contentType: "application/json",
      });
      tokens.forEach((token) => {
        const i = this.tokens.indexOf(token);
        if (i > -1) {
          this.tokens.splice(i, 1);
        }
      });
    } catch (error) {
      errorHandler.reportError(error);
    }
  }

  dispose(): void {
    if (this.disposed) {
      throw new Error("Cannot access a disposed object.");
    }

    if (this.tokens.length !== 0) {
      /*! SuppressStringValidation URI is not translatable */
      const uri = joinUri(global.serviceUri, "api/semaphores/release");
      const params = new URLSearchParams();
      this.tokens.forEach((t) => params.append("", t));
      const blob = new Blob([params.toString()], {
        type: "application/x-www-form-urlencoded;charset=UTF-8",
      });
      navigator.sendBeacon(uri, blob);

      this.tokens = [];
    }

    this.disposed = true;
  }
}

export default new SemaphoreProvider();
