import captionService from 'CaptionService';

export const dateField = {
	ariaLabelPrompter: () => captionService.getString('70d1c294-bb0d-4d58-8dc7-8781b5a8ea19', 'Select Date'),
	today: () => captionService.getString('2e18a9b3-693a-44bd-b67d-878195fc7065', 'Today')
};

export const timeField = {
	ariaLabelPrompter: () => captionService.getString('dc912d7f-644a-4e8d-9f43-fea5a8166cd5', 'Select Time'),
	now: () => captionService.getString('52aad6fa-28e0-407d-b27f-79425ea5c2de', 'Now')
};

export const dateTimeField = {
	ariaLabelPrompter: dateField.ariaLabelPrompter,
	now: timeField.now,
	today: dateField.today,
	setDate: () => captionService.getString('8fbd5527-1627-46b9-9438-9926b5bbeb0a', 'Set Date'),
	setTime: () => captionService.getString('a96a0fd9-3f8d-4141-a8ec-0f3f25daa2bb', 'Set Time'),
};

export const dateOffset = {
	atLeast: () => captionService.getString('ed89194c-bf6c-423d-8088-bbf95a885206', 'At least'),
	atMost: () => captionService.getString('2f4dee83-be7c-403b-95d5-4aa893514ee6', 'At most'),
};
