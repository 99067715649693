export class PromiseCacheItem<T> {
  private promise?: Promise<T>;
  private value?: T;
  private error?: unknown;
  private isValueSet = false;
  private isErrorSet = false;

  cachePromise(promise: Promise<T>): void {
    this.promise = promise;
  }

  setResolved(value: T): void {
    this.isValueSet = true;
    this.value = value;
  }

  setRejected(error: unknown): void {
    this.isErrorSet = true;
    this.error = error;
  }

  accessCache(): Promise<T> | undefined {
    if (this.isValueSet) {
      return Promise.resolve(this.value as T);
    }

    if (this.isErrorSet) {
      return Promise.reject(this.error);
    }

    return this.promise;
  }

  clearCachedPromise(): void {
    this.promise = undefined;
  }

  get isFulfilled(): boolean {
    return this.isValueSet;
  }

  get isRejected(): boolean {
    return this.isErrorSet;
  }

  get isPending(): boolean {
    return !this.isFulfilled && !this.isRejected;
  }
}
