import ajaxService from "AjaxService";
import captionService from "CaptionService";
import dialogService from "DialogService";
import global from "Global";
import log from "Log";
import navigationService from "NavigationService";
import { NotificationType } from "NotificationType";

export async function isVersionNumberCurrentAsync(): Promise<boolean> {
  const version = await getCurrentVersionAsync();
  return global.versionNumber === version;
}

export async function handleVersionsMismatchAsync(): Promise<boolean> {
  const version = await getCurrentVersionAsync();

  let errorMessage;
  if (global.versionNumber === version) {
    if (!global.serviceVersionNumber || global.serviceVersionNumber === global.versionNumber) {
      return false;
    }

    errorMessage = captionService.getString(
      "c511d92a-fb0c-4d46-9859-2057e0446f5a",
      "You are running a mismatched version of the application. Please refresh the page and try again or contact your Administrator if the error persists."
    );
  } else {
    errorMessage = captionService.getString(
      "51866e27-861f-4782-983e-753f566164d9",
      "You are running an outdated version of the application. Please refresh the page and try again or contact your Administrator if the error persists.\n\nCurrently running version: {0}\nNew version available: {1}",
      global.versionNumber,
      version
    );
  }

  await dialogService.showAsync(
    NotificationType.Error,
    errorMessage,
    captionService.getString("8e83286d-e6f8-4516-8d33-635e1ca7d4aa", "Error"),
    [
      {
        caption: captionService.getString("9d901f87-8758-4ed1-a558-ed6577aeb55d", "Refresh"),
        isPrimary: true,
        isDismiss: true,
        result(): void {
          navigationService.reloadPage(true);
        },
      },
    ],
    {
      closeOnDismissOnly: true,
    }
  );

  return true;
}

async function getCurrentVersionAsync(): Promise<string> {
  try {
    /*! SuppressStringValidation relative path */
    return await ajaxService.getAsync(global.rootPath + "version");
  } catch (error) {
    log.error("Unable to get current version number.");
    log.error(error);
    return global.versionNumber;
  }
}

/** @deprecated use named import instead */
export default { isVersionNumberCurrentAsync, handleVersionsMismatchAsync };
