import {
  assign,
  assignIn,
  countBy,
  debounce,
  defaults,
  difference,
  each,
  every,
  filter,
  find,
  findIndex,
  findLastIndex,
  first,
  forEach,
  groupBy,
  has,
  identity,
  includes,
  intersection,
  invokeMap,
  isBoolean,
  isEmpty,
  isEqual,
  isFunction,
  last,
  map,
  mapValues,
  max,
  maxBy,
  memoize,
  min,
  minBy,
  negate,
  omit,
  pick,
  range,
  reduce,
  reject,
  shuffle,
  some,
  sortBy,
  take,
  takeRight,
  toArray,
  union,
  uniq,
  uniqBy,
  uniqWith,
  without,
  zip,
  zipObject,
} from "lodash-es";

type Dictionary<T> = {
  [index: string]: T;
};
type NestedArray<T> = Array<NestedArray<T> | T>;

const _ = {
  all: every, // Iterates over collection
  any: some, // Iterates over collection
  compact: <T>(array: Array<T>): Array<T> => array.filter(Boolean),
  constant:
    <T>(value: T): (() => T) =>
    () =>
      value,
  contains: includes, // Iterates over collection
  countBy,
  debounce,
  defaults,
  difference,
  each, // Iterates over collection
  every, // Iterates over collection
  extend: assignIn,
  extendOwn: assign,
  filter, // Iterates over collection
  find, // Iterates over collection
  findIndex,
  findLastIndex,
  findWhere: find, // Iterates over collection
  first: <T>(array: Array<T>, count?: number): T | T[] | undefined => (count ? take(array, count) : first(array)),
  flatten: <T>(array: NestedArray<T> | null | undefined): Array<T> => (array ? (array.flat() as Array<T>) : []),
  forEach, // Iterates over collection
  groupBy,
  has,
  identity,
  indexOf: <T>(array: Array<T> | null | undefined, value: T, fromIndex = 0): number =>
    array ? array.indexOf(value, fromIndex) : -1,
  intersection,
  invoke: invokeMap,
  isArray: <T>(value: T): boolean => Array.isArray(value),
  isBoolean,
  isEmpty,
  isEqual,
  isFunction,
  isNull: <T>(value: T): boolean => value === null,
  isNumber: <T>(value: T): boolean => typeof value === "number" || value instanceof Number,
  isObject: <T>(value: T): boolean => value !== null && (typeof value === "object" || typeof value === "function"),
  isString: <T>(value: T): boolean => typeof value === "string" || value instanceof String,
  isUndefined: <T>(value: T): boolean => value === undefined,
  keys: <T>(value?: T): Array<string> => (value ? Object.keys(value) : []),
  last: <T>(array: Array<T>, count?: number): T | T[] | undefined => (count ? takeRight(array, count) : last(array)),
  lastIndexOf: <T>(array: Array<T> | null | undefined, value: T, fromIndex = -1): number =>
    array ? array.lastIndexOf(value, fromIndex) : -1,
  map, // Iterates over collection
  mapObject: mapValues,
  max: <T>(array: Array<T>, iteratee?: T): T | undefined => (iteratee ? maxBy(array, iteratee) : max(array)),
  memoize,
  min: <T>(array: Array<T>, iteratee?: T): T | undefined => (iteratee ? minBy(array, iteratee) : min(array)),
  negate,
  noop: (): undefined => undefined,
  now: (): number => +new Date(),
  object: zipObject,
  omit,
  pick,
  pluck: map, // Iterates over collection
  range,
  reduce, // Iterates over collection
  reject, // Iterates over collection
  rest: <T>(array: Array<T> | undefined, n = 1): Array<T> => (array ? array.slice(n) : []),
  shuffle,
  some, // Iterates over collection
  sortBy, // Iterates over collection
  toArray,
  union,
  uniq: <T>(array: Array<T>, iteratee?: T): Array<T> => (iteratee ? uniqBy(array, iteratee) : uniq(array)),
  uniqWith,
  values: <T>(value: Dictionary<T>): Array<T> => (value === null ? [] : Object.values(value)),
  where: filter, // Iterates over collection
  without,
  zip,
};

/** @deprecated Underscore is replaced with lodash, use lodash-es instead */
export default _;
