import breeze from 'breeze-client';

class DataServiceWebApiAdapter extends breeze.AbstractDataServiceAdapter {
	constructor() {
		super();
		this.name = 'WebApi';
	}

	checkForRecomposition() { }

	initialize() { }
}

breeze.config.registerAdapter('dataService', DataServiceWebApiAdapter);
