import captionService from 'CaptionService';
import LookupListItem from 'LookupListItem';
import Measure from 'Measure';
import notificationType from 'NotificationType';
import RuleValidationResult from 'RuleValidationResult';
import { defaultValueSymbol } from 'Symbols';
import { isNil, isNumber, isString } from 'lodash-es';

export default {
	lookup(value) {
		if (value === defaultValueSymbol) {
			return null;
		}

		if (!value || value.length === 0 || value[0] instanceof LookupListItem) {
			return value;
		}

		return value.map((item) => {
			return new LookupListItem(
				item.code,
				item.description,
				item.iconFontCode,
				item.sentiment
			);
		});
	},
	validation(value, values) {
		if (isNil(value) || value === true || value === defaultValueSymbol) {
			return null;
		}

		if (value instanceof RuleValidationResult) {
			return value;
		}

		let text;
		let level;
		const length = values.length;

		if (value === false) {
			const key = values[length - 3];
			const fallbackText = values[length - 2];
			const type = values[length - 1];

			text = key ? captionService.getString(key, fallbackText) : fallbackText;
			level = notificationType.fromString(type);
		} else if (isString(value)) {
			const type = values[length - 1];

			text = value;
			level = notificationType.fromString(type);
		} else if (isNumber(value)) {
			const key = values[length - 2];
			const fallbackText = values[length - 1];

			text = key ? captionService.getString(key, fallbackText) : fallbackText;
			level = value;
		} else {
			throw new Error('Unknown validation rule value: ' + JSON.stringify(value));
		}

		return new RuleValidationResult(text, level);
	},
	property(value, values, returnType) {
		if (value !== defaultValueSymbol) {
			return value;
		}

		switch (returnType) {
			case 'Byte':
			case 'Int16':
			case 'Int32':
			case 'Int64':
			case 'Double':
			case 'Decimal':
			case 'Single':
				return 0;

			case 'Boolean':
				return false;

			case 'Measure':
				return new Measure(0, '');

			default:
				return null;
		}
	},
};
