import ajaxService from 'AjaxService';
import {
	AuthenticationClaimType,
	AuthenticationResult,
	getAuthenticationResultName,
	GlowAuthenticationResultHeaderName,
	session,
} from 'AuthenticationService';
import errors from 'Errors';
import log from 'Log';
import logonService from 'LogonService';
import nativeBridge from 'NativeBridge';
import { tryHandleSessionLimitErrorAsync } from 'SessionLimitReachedHandler';
import userSession from 'UserSession';

function SessionExpirationService() {
	this._renewSessionPromise = null;
}

async function renewSessionCoreAsync() {
	const sessionData = userSession.sessionData();
	const authenticationToken = sessionData.authenticationToken;
	const sessionID = sessionData.sessionId;
	const authenticationSource = sessionData.authenticationSource;

	if (!authenticationToken) {
		throw new errors.RenewSessionError(
			/*! SuppressStringValidation Exception message */
			'Authentication token is missing.',
			AuthenticationResult.SessionExpired
		);
	}

	// If there's no sessionID, the server will give us one.
	// If we supply one, we can continue with our current ID.
	try {
		await tryBeginSessionAsync(authenticationToken, sessionID, authenticationSource);
	} catch (error) {
		try {
			const isHandled = await tryHandleSessionLimitErrorAsync(error);
			if (isHandled) {
				await tryBeginSessionAsync(authenticationToken, sessionID, authenticationSource);
			} else {
				throw error;
			}
		} catch (error) {
			throw new errors.RenewSessionError(
				/*! SuppressStringValidation Exception message */
				'Session renewal failed.',
				AuthenticationResult.SessionExpired,
				error
			);
		}
	}
}

async function tryBeginSessionAsync(authenticationToken, sessionID, authenticationSource) {
	const result = await session.beginAsync(
		authenticationToken,
		AuthenticationClaimType.LocalToken,
		await nativeBridge.getSessionTypeAsync(),
		sessionID
	);
	try {
		await logonService.logOnWithUserInfoAsync(result.authenticationToken, result.userInfo, authenticationSource);
	} catch (error) {
		await destroySessionAsync();
		throw error;
	}
}

async function destroySessionAsync() {
	try {
		await session.destroyAsync();
	} catch { /* empty */ }
}

SessionExpirationService.prototype.getErrorHandler = function (httpError, retrieveHeader) {
	return new SessionExpirationErrorHandler(this, httpError, retrieveHeader);
};

SessionExpirationService.prototype.renewSessionAsync = async function () {
	log.withTag('UserSession').info('SessionExpirationService.renewSessionAsync');

	const self = this;
	let promise = this._renewSessionPromise;
	if (!promise) {
		this._renewSessionPromise = promise = (async () => {
			try {
				await renewSessionCoreAsync();
			} finally {
				self._renewSessionPromise = null;
			}
		})();
	}

	await promise;
};

SessionExpirationService.prototype.setAjaxServiceErrorHandler = function () {
	const self = this;
	ajaxService.errorHandler = (jqXHR, ajaxFunc) => {
		const handler = self.getErrorHandler(jqXHR.status, jqXHR.getResponseHeader);
		if (handler.canHandle()) {
			return (async () => {
				await handler.handleAsync();
				return ajaxFunc();
			})();
		}
	};
};

function SessionExpirationErrorHandler(service, httpError, retrieveHeader) {
	this._service = service;
	this._errorInfo = getErrorInfo(httpError, retrieveHeader);
}

SessionExpirationErrorHandler.prototype.canHandle = function () {
	return this._errorInfo.canHandle;
};

SessionExpirationErrorHandler.prototype.handleAsync = async function () {
	const errorInfo = this._errorInfo;
	if (!errorInfo.canHandle) {
		/*! SuppressStringValidation Exception message */
		throw new Error('Cannot handle non-expired session.');
	}

	if (!errorInfo.canRenew) {
		throw new errors.RenewSessionError(
			/*! SuppressStringValidation Exception message */
			'Session cannot be renewed.',
			errorInfo.wasEvicted
				? AuthenticationResult.SessionEvicted
				: AuthenticationResult.SessionExpired
		);
	}

	await this._service.renewSessionAsync();
};

function getErrorInfo(httpError, retrieveHeader) {
	if (httpError === 401 /* HTTP Unauthorized */) {
		const headerValue = retrieveHeader(GlowAuthenticationResultHeaderName);

		if (headerValue) {
			const sessionExpiryIndicatorAuthenticationResults = [
				AuthenticationResult.SessionExpired,
				AuthenticationResult.SessionNotFound,
				AuthenticationResult.CredentialsNotProvided,
				AuthenticationResult.SessionLimitReached,
			];
			const isSessionExpiry = sessionExpiryIndicatorAuthenticationResults.some(
				(x) => headerValue === getAuthenticationResultName(x)
			);
			if (isSessionExpiry) {
				return { canHandle: true, canRenew: true };
			}

			if (headerValue !== getAuthenticationResultName(AuthenticationResult.Success)) {
				const wasEvicted =
					headerValue ===
					getAuthenticationResultName(AuthenticationResult.SessionEvicted);
				return { canHandle: true, canRenew: false, wasEvicted };
			}
		}
	}

	return { canHandle: false };
}

export default new SessionExpirationService();
