import { addWithoutMutating, removeWithoutMutating } from 'ArrayUtils';
import ClientLinkNotifier from 'ClientLinkNotifier';
import global from 'Global';
import log from 'Log';
import { startNewNavigation } from 'NavigationTracker';
import { format } from 'StringUtils';
import $ from 'jquery';

const PageHideLogTag = 'PageHide';

class WindowManager {
	constructor(window) {
		this._closeListeners = [];
		this._isListeningToMessages = false;
		this._handles = new Map();
		this._opener = null;
		this._preventCloseListeners = [];
		this._window = window;
		this._disablePreventClose = false;

		this.hasOpener = !!window.opener;
	}

	init() {
		const window = this._window;
		this._opener = (window.name && window.opener) || null;

		this.addCloseListener(() => {
			this.triggerEventOnOpener(CloseEventName);
			this.closeChildWindows();
		});

		$(window)
			.on('beforeunload', (e) => this._onBeforeUnload(e))
			.on('pagehide', (e) => {
				const logWithTag = log.withTag(PageHideLogTag);
				if (!e.persisted) {
					logWithTag.info('pageHide event was triggered with falsy persisted event so it is not due to a freezing, calling all close listeners through it');
					this._onPageHide();
				} else {
					logWithTag.info("pageHide event was triggered but just because it is freezing (persisted is true) so we won't be calling the close listeners");
				}
			})
			.on('hashchange.glow', () => {
				this.closeChildWindows((handle) => handle.shouldCloseOnHashChange);
				startNewNavigation();
			})
			.on('appinstalled', () => this.changeTitle());
	}

	isOpen(name) {
		return getHandle(this, getWindowNameWithDomain(name)) !== null;
	}

	open(url, name, options) {
		if (!name) {
			throw new Error('A window name must be specified.');
		}

		const nameWithDomain = getWindowNameWithDomain(name);
		const window = this._window;
		const result = window.open(url, nameWithDomain, getWindowOptions(window, options));

		if (result) {
			const handle = new WindowHandle(result, !!(options && options.keepOpenOnHashChange));
			this._handles.set(nameWithDomain, handle);
			listenToMessages(this);

			return handle;
		} else {
			return null;
		}
	}

	triggerEventOnOpener(eventName, arg) {
		const opener = this._opener;

		if (opener) {
			const window = this._window;
			const message = {
				key: Key,
				windowName: window.name,
				eventName,
				arg
			};

			opener.postMessage(message, window.location.origin);
		}
	}

	resizeToOpener() {
		const opener = this._opener;
		const window = this._window;

		if (opener) {
			window.moveTo(getScreenTop(opener), getScreenLeft(opener));
		}

		const size = opener ? { width: opener.outerWidth, height: opener.outerHeight } : DefaultWindowSize;
		window.resizeTo(size.width, size.height);
		window.focus();
	}

	closeCurrent() {
		this._window.close();
	}

	closeChildWindows(predicate) {
		this._handles.forEach((handle) => {
			if (!predicate || predicate(handle)) {
				handle.close();
			}
		});
	}

	changeTitle(title) {
		this._window.document.title = title ? `${title} - ${global.appName}` : global.appName;
	}

	addCloseListener(callback) {
		this._closeListeners = addWithoutMutating(this._closeListeners, callback);
	}

	removeCloseListener(callback) {
		this._closeListeners = removeWithoutMutating(this._closeListeners, callback);
	}

	addPreventCloseListener(callback) {
		this._preventCloseListeners = addWithoutMutating(this._preventCloseListeners, callback);
	}

	removePreventCloseListener(callback) {
		this._preventCloseListeners = removeWithoutMutating(this._preventCloseListeners, callback);
	}

	_onBeforeUnload(event) {
		const preventClose = !ClientLinkNotifier.isClientLinkIdSetInHref && this._preventCloseListeners.some((x) => x());
		if (preventClose) {
			event.preventDefault();
			return '';
		}
	}

	_onPageHide() {
		const closeListeners = this._closeListeners;
		while (closeListeners.length) {
			closeListeners.shift()();
		}
		this._closeListeners = closeListeners;
	}

	clearListeners() {
		this._closeListeners = [];
		this._preventCloseListeners = [];
		$(this._window).off(MessageEventName);
		this._isListeningToMessages = false;
	}

	historyPushState(...args) {
		if (ClientLinkNotifier.isClientLinkIdSetInHref) {
			return false;
		}
		this._window.history.pushState(...args);
		return true;
	}
}

function getWindowNameWithDomain(name) {
	return name + global.getAbsoluteUrl(global.rootPath);
}

function handleChildWindowUnload(self, handle) {
	if (!handle.isBeingUnloaded) {
		handle.isBeingUnloaded = true;

		let iterations = 0;
		const closedChecker = setInterval(
			() => {
				let done;
				if (handle.isClosed) {
					handle.triggerEvent(CloseEventName);
					removeHandle(self, handle.name);
					done = true;
				} else {
					iterations++;
					done = iterations >= 100;
				}

				if (done) {
					handle.isBeingUnloaded = false;
					clearInterval(closedChecker);
				}
			},
			100);
	}
}

/*! SuppressStringValidation (Not a caption) */
const CloseEventName = 'close';
/*! SuppressStringValidation (name of event) */
const MessageEventName = 'message.glow';
const Key = 'QwCnStQ1yEOqb_duhFI5iA';
const DefaultWindowSize = {
	width: 1000,
	height: 650
};

function getHandle(self, name) {
	const handle = self._handles.get(name);
	if (handle) {
		if (handle.isClosed) {
			removeHandle(self, name);
		} else {
			return handle;
		}
	}

	return null;
}

function getWindowOptions(window, options) {
	options = $.extend({}, DefaultWindowSize, options);

	if (!options.left) {
		options.left = getScreenLeft(window) + (window.outerWidth / 2) - (options.width / 2);
	}

	if (!options.top) {
		options.top = getScreenTop(window) + (window.outerHeight / 2) - (options.height / 2);
	}

	const optionsFormat = 'width={0},height={1},left={2},top={3},resizable=yes';
	return format(optionsFormat, options.width, options.height, options.left, options.top);
}

function getScreenLeft(window) {
	return window.screenLeft || window.screenX;
}

function getScreenTop(window) {
	return window.screenTop || window.screenY;
}

function listenToMessages(self) {
	if (!self._isListeningToMessages) {
		self._isListeningToMessages = true;
		$(self._window).on(MessageEventName, (e) => {
			onMessage(self, e);
		});
	}
}

function onMessage(self, e) {
	e = e.originalEvent;
	const data = e.data;
	if (e.origin === self._window.location.origin && data && data.key === Key) {
		const windowName = data.windowName;
		const handle = self._handles.get(windowName);
		if (handle) {
			if (data.eventName === CloseEventName) {
				handleChildWindowUnload(self, handle);
			} else {
				handle.triggerEvent(data.eventName, data.arg);
			}
		}
	}
}

function removeHandle(self, name) {
	const handles = self._handles;
	handles.delete(name);

	if (!handles.size) {
		$(self._window).off(MessageEventName);
		self._isListeningToMessages = false;
	}
}

class WindowHandle {
	constructor(window, keepOpenOnHashChange) {
		this.isBeingUnloaded = false;
		this.name = window.name;
		this._events = {};
		this._window = window;
		this._keepOpenOnHashChange = keepOpenOnHashChange;
	}

	get isClosed() {
		return this._window.closed;
	}

	get shouldCloseOnHashChange() {
		return !this._keepOpenOnHashChange;
	}

	close() {
		this._window.close();
	}

	replaceUrl(url) {
		this._window.location.replace(url);
	}

	off(eventName) {
		delete this._events[eventName];
		return this;
	}

	on(eventName, callback) {
		this._events[eventName] = callback;
		return this;
	}

	triggerEvent(eventName, arg) {
		const callback = this._events[eventName];
		if (callback) {
			callback(arg);
		}
	}
}

export default new WindowManager(window);
