import ajaxService, { AjaxError } from "AjaxService";
import { AsyncDictionary } from "AsyncDictionary";
import captionService from "CaptionService";
import { FormFlowNotFoundError } from "Errors";
import { FormFlowError, FormFlowErrorType } from "FormFlowError";
import { type FormFlowDefinition } from "FormFlowTypes";
import global from "Global";
import { isNonEmptyGuid } from "StringUtils";

function buildKey(pk: string, clientPK?: string): string {
  return `${pk}_${clientPK}_${global.moduleName}`;
}

function buildRemoveKey(
  routeName: string,
  entityTypeName: string,
  headerEntityTypeName?: string,
  headerCollectionName?: string
): string {
  return `${routeName}|${entityTypeName}|${headerEntityTypeName}|${headerCollectionName}|${global.moduleName}`;
}

const cache: AsyncDictionary<FormFlowDefinition> = new AsyncDictionary<FormFlowDefinition>();
const removeCache: AsyncDictionary<FormFlowDefinition> = new AsyncDictionary<FormFlowDefinition>();

/**
 * Clears FormFlowDefinition cache data
 * @returns void
 */
export function clearFormFlowDefinitions(): void {
  cache.clear();
  removeCache.clear();
}

/**
 * Gets FormFlowDefinition for given pk and clientPK combination
 *
 * @param pk Primary Key
 * @param clientPK Client Primary Key
 * @returns FormFlowDefinition
 */
export async function getFormFlowDefinitionAsync(pk: string, clientPK?: string): Promise<FormFlowDefinition> {
  return await cache.getOrAddAsync(buildKey(pk, clientPK), async () => {
    const url = `${global.serviceUri}api/bpm/formFlow/getDefinition`;
    try {
      return await ajaxService.getAsync(url, {
        pk,
        clientPK: isNonEmptyGuid(clientPK) ? clientPK : "",
      });
    } catch (error) {
      if (error instanceof AjaxError) {
        const notFoundMessage = `FormFlow not found for pk: ${pk} and clientPK: ${clientPK}`;
        handleGetDefinitionError(notFoundMessage, error);
      }
      throw error;
    }
  });
}

/**
 * Gets FormFlowDefinition  if it exists for specific combination of routeName, entityTypeName, headerEntityTypeName, headerCollectionName
 * else returns undefined
 *
 * @param routeName
 * @param entityTypeName The entity type name.
 * @param headerEntityTypeName Optional property header entity name.
 * @param headerCollectionName Optional property collection property name.
 * @returns FormFlowDefinition or undefined
 */
export async function getFormFlowDefinitionForRemoveAsync(
  routeName: string,
  entityTypeName: string,
  headerEntityTypeName?: string,
  headerCollectionName?: string
): Promise<FormFlowDefinition | undefined> {
  const definition = await removeCache.getOrAddAsync(
    buildRemoveKey(routeName, entityTypeName, headerEntityTypeName, headerCollectionName),
    async () => {
      const url = `${global.serviceUri}api/bpm/formFlow/getDefinitionForRemove`;
      const params: Record<string, string | undefined> = { routeName, entityTypeName };
      if (headerEntityTypeName) {
        params.headerEntityTypeName = headerEntityTypeName;
        params.headerCollectionName = headerCollectionName;
      }
      try {
        return await ajaxService.getAsync(url, params);
      } catch (error) {
        if (error instanceof AjaxError) {
          const notFoundMessage = `FormFlow not found for routeName: ${routeName} and entityType: ${entityTypeName}`;
          handleGetDefinitionError(notFoundMessage, error);
        }
        throw error;
      }
    }
  );
  if (definition) {
    await cache.getOrAddAsync(buildKey(definition.PK), async () => await definition);
  }
  return definition;
}

function handleGetDefinitionError(notFoundMessage: string, error: AjaxError): void {
  if (error.status === 403) {
    throw new FormFlowError(
      captionService.getString(
        "fe7e8c5a-3480-4fdc-85f1-959fea82837a",
        "The currently logged on user does not have the necessary Security Checkpoint for a selected form-flow. Please check your Security Roles and form-flow Security Checkpoint settings."
      ),
      { type: FormFlowErrorType.NonReportableRuntimeError }
    );
  }

  if (error.status === 404) {
    throw new FormFlowNotFoundError(notFoundMessage);
  }

  if (error.status >= 500 && error.status < 600) {
    throw new FormFlowError(
      captionService.getString(
        "29c67c1c-0a4a-426b-8d77-e41d15fefeb1",
        "An unexpected error occurred while fetching a form-flow definition, and so the form-flow will exit. This may be intermittent."
      ),
      { type: FormFlowErrorType.NonReportableRuntimeError }
    );
  }

  throw error;
}

/** @deprecated Use named exports instead */
export default {
  clear: clearFormFlowDefinitions,
  getAsync: getFormFlowDefinitionAsync,
  getForRemoveAsync: getFormFlowDefinitionForRemoveAsync,
};
