import appConfig from 'AppConfig';
import { isEntity } from 'EntityExtensions';
import { trackEntityManagerOperationAsync } from 'EntityManagerExtensions';
import errorHandler from 'ErrorHandler';
import errors from 'Errors';
import LookupListItem from 'LookupListItem';
import ruleDependencyValue from 'RuleDependencyValue2';
import RuleService from 'RuleService';
import { State } from 'StateConstants';
import ko from 'knockout';
import LRUCache from 'lru-cache';

class DependencyResult {
	state = State.NotLoaded;
	value;

	constructor(state, value) {
		this.state = state;
		this.value = value;
	}

	static NotAvailable() {
		return new DependencyResult(State.NotAvailable);
	}

	static NotLoaded() {
		return new DependencyResult(State.NotLoaded);
	}

	static Available(value) {
		return new DependencyResult(State.Available, value);
	}
}

function LookupRuleEngine() {
	this._cache = new LRUCache({max: appConfig.lookupCacheLimit});
	this._dynamicValuesMap = new WeakMap();
}

LookupRuleEngine.prototype.observeValue = function (entity, entityName, propertyName) {
	const rule = RuleService.get(entityName).lookupRule(propertyName, true);
	return rule ? this.observeValueForRule(entity, rule) : getNotLoadedValue(entity, propertyName);
};

LookupRuleEngine.prototype.observeValueForRule = function (entity, rule, emptyDefaultValue) {
	const dependencies = getDependencies(rule);
	let { value } = observeValue(this._cache, entity, rule, dependencies);

	if (entity && dependencies.length > 0) {
		value = handleDynamicValue(this._dynamicValuesMap, entity, rule.property, value);
	}

	return value || getNotLoadedValue(entity, rule.property, emptyDefaultValue);
};

LookupRuleEngine.prototype.observeValueWithState = function (entity, entityName, propertyName) {
	const rule = RuleService.get(entityName).lookupRule(propertyName, true);
	return rule ? this.observeValueWithStateForRule(entity, rule) : DependencyResult.NotAvailable();
};

LookupRuleEngine.prototype.observeValueWithStateForRule = function (entity, rule) {
	const dependencies = getDependencies(rule);
	const { value, state } = observeValue(this._cache, entity, rule, dependencies);

	return state === State.Available ? DependencyResult.Available(value) : new DependencyResult(state);
};

function getDependencies(rule) {
	const paths = rule.getAllDependencies();
	return paths.length > 0 ? paths.map(getDependency) : paths;
}

function observeValue(cache, entity, rule, dependencies) {
	let dependencyValues;
	if (dependencies.length > 0) {
		dependencyValues = ruleDependencyValue.getValues(entity, dependencies);
		if (dependencyValues.state !== State.Available) {
			return { value: undefined, state: dependencyValues.state };
		}
	}

	const { cacheValue, hasErrorOccurred } = getCacheValue(cache, entity, rule, dependencyValues);

	const valueWithState = cacheValue.read();

	if (valueWithState) {
		return valueWithState;
	}

	return hasErrorOccurred() ? DependencyResult.NotAvailable() : DependencyResult.NotLoaded();
}

function handleDynamicValue(map, entity, propertyName, value) {
	let entityMap = map.get(entity);
	if (value) {
		if (!entityMap) {
			entityMap = {};
			map.set(entity, entityMap);
		}

		entityMap[propertyName] = value;
		return value;
	}
	else {
		return entityMap && entityMap[propertyName];
	}
}

function getNotLoadedValue(entity, propertyName, emptyDefaultValue) {
	if (!entity || emptyDefaultValue) {
		return [];
	}

	const propertyValue = entity[propertyName].peek();
	return [new LookupListItem(propertyValue, propertyValue)];
}

function getDependency({ expression }) {
	return { type: 'path', value: '<' + expression + '>' };
}

function getCacheValue(cache, entity, rule, dependencyValues) {
	const key = getCacheKey(rule, dependencyValues && dependencyValues.values);
	let { cacheValue, hasErrorOccurred } = cache.get(key) || {};

	if (!hasErrorOccurred) {
		hasErrorOccurred = ko.observable(false);
	}

	if (!cacheValue || cacheValue.hasError()) {
		const valueFactoryAsync = async () => {
			try {
				const result = await rule.processAsync(entity);
				const value = getValueOrDefault(result);
				return value;
			}
			catch (ex) {
				hasErrorOccurred(true);
				throw ex;
			}
		};
		cacheValue = ko.promiseObserver(trackPromiseAsync(entity, valueFactoryAsync()));
		cache.set(key, { cacheValue, hasErrorOccurred });
	}

	return { cacheValue, hasErrorOccurred };
}

function getCacheKey(rule, dependencyValues) {
	/*! SuppressStringValidation Cache key */
	let result = 'Rule_' + rule.ruleId;
	if (dependencyValues && dependencyValues.length > 0) {
		result = dependencyValues.reduce((accumulator, value, index) => {
			if (typeof value !== 'undefined' && value !== null) {
				accumulator += '_' + index + '=' + value;
			}
			return accumulator;
		}, result);
	}

	return result;
}

function getValueOrDefault(result) {
	return result.isSuccess ? DependencyResult.Available(result.value) : new DependencyResult(State.NotAvailable, []);
}

LookupRuleEngine.prototype.getValueAsync = function (entity, rule) {
	const cache = this._cache;
	const dependencies = getDependencies(rule);
	return trackPromiseAsync(entity, ruleDependencyValue.getValuesAsync(entity, dependencies).then((result) => {
		if (result.state !== State.Available) {
			return [];
		}

		const { cacheValue } = getCacheValue(cache, entity, rule, result);
		return cacheValue.getValueAsync().then(({ value }) => value).catch((error) => {
			if (error instanceof errors.RuleInvocationException) {
				/*! SuppressStringValidation Developer error message */
				errorHandler.reportError(error, 'Error while invoking lookup rule.');
				return [];
			}
			throw error;
		});
	}));
};

LookupRuleEngine.prototype.reset = function () {
	this._cache.clear();
};

function trackPromiseAsync(entity, promise) {
	return isEntity(entity)
		? trackEntityManagerOperationAsync(entity.entityAspect.entityManager, promise)
		: promise;
}

export default new LookupRuleEngine();
