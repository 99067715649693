/*
This module is meant to be used as an import in typescript files instead of directly importing breeze.
It loads the Glow extensions for breeze classes defined in BreezeInit.
BreezeInit being a .js with d.ts cannot be imported directly
since the type definitions are not recognized when importing using a path alias.
The only alternative is importing using full path which would be difficult for imports in business solutions.
This module provides a convenient alias for importing the extended breeze classes.
*/
import breeze, { type Entity as BreezeEntity } from "./BreezeInit.js";
import type { EntityAdapter } from "./EntityAdapter";

export type {
  AutoGeneratedKeyType,
  ComplexType,
  DataProperty,
  DataService,
  DataType,
  EntityAspect,
  EntityByKeyResult,
  EntityKey,
  EntityManager,
  EntityQuery,
  EntityState,
  EntityStateSymbol,
  EntityType,
  EntityTypeOptions,
  ExportEntitiesOptions,
  FilterQueryOp,
  FilterQueryOpSymbol,
  FunctionImport,
  FunctionImportParameter,
  IProperty,
  MergeStrategy,
  MergeStrategySymbol,
  MetadataStore,
  NavigationProperty,
  Predicate,
  QueryResult,
} from "./BreezeInit.js";

export type Entity<T = unknown> = EntityAdapter<BreezeEntity, T>;

export default breeze;
