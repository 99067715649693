import $ from 'jquery';
import * as stringUtils from 'StringUtils';
import 'Widgets/gwLoadingAnimation';
import 'jquery.ui.core';
import 'jquery.ui.keyCode';

function ButtonHelper() {
}

ButtonHelper.prototype.decorate = ($element, options) => {
	options = options || {};
	$element.addClass('btn');

	if (!$element.attr('type')) {
		/*! SuppressStringValidation No captions here. */
		$element[0].setAttribute('type', options.isDefault ? 'submit' : 'button'); // Cannot use jQuery.
	}

	if (options.isDefault) {
		$element.attr('data-default', 'true');
	}

	if (options.isDefault || options.isPrimary) {
		$element.addClass('btn-primary');
	}

	if (options.isDismiss) {
		$element.attr('data-dismiss', 'modal');
	}

	if (options.caption) {
		setCaption($element, options.caption);
	}

	let bindingString = options.bindingString;
	if (options.shortcut) {
		bindingString = stringUtils.commaSeparate(bindingString, stringUtils.format("gwTooltip: { shortcut: '{0}' }", options.shortcut));
	}

	if (bindingString) {
		$element.attr('data-bind', bindingString);
	}

	if (options.isAsync && !$element.hasClass('g-btn-async')) {
		$element.addClass('g-btn-async');
	}
};

function setCaption($element, value) {
	let $span = $element.find('span');
	if ($span.length === 0) {
		$span = $('<span>').appendTo($element);
	}

	$span.text(value);
}

ButtonHelper.prototype.disable = ($element, value) => {
	/*! StartNoStringValidationRegion No captions here. */
	$element.toggleClass('disabled', value);
	$element.prop('disabled', value);
	/*! EndNoStringValidationRegion */
};

ButtonHelper.prototype.setLoadingState = function ($element, value) {
	$element.toggleClass('g-btn-loading', value);
	this.disable($element, value);
};

export default new ButtonHelper();
