import errorHandler from 'ErrorHandler';
import global from 'Global';
import bowser from 'bowser';

class Browser {
	constructor() {
		this._browserInfo = null;
		this._isElectronOrAndroidRuntime = null;
	}

	getBrowserInfo() {
		if (this._browserInfo) {
			return this._browserInfo;
		}

		const userAgentData = global.userAgentData;

		if (userAgentData &&
			userAgentData.brands.length > 0) {
			this._browserInfo = getBrowserWithUserAgentData(userAgentData);

			if (!this._browserInfo) {
				this._browserInfo = getBrowserWithBowser();
				// This error is being thrown to alert developers to the need to add more browser conversion types
				// as more browsers include UAData objects
				errorHandler.reportError(new Error(`Cannot find valid browser brand using userAgentData object: ${JSON.stringify(global.userAgentData)}`));
			}

		} else {
			this._browserInfo = getBrowserWithBowser();
		}

		return this._browserInfo;
	}

	isElectronOrAndroidRuntime() {
		if (this._isElectronOrAndroidRuntime !== null) {
			return this._isElectronOrAndroidRuntime;
		}

		if (typeof window !== 'undefined') {
			// Renderer process
			if (typeof window.process === 'object' && window.process.type === 'renderer') {
				return true;
			}

			if (window.Android) {
				return true;
			}
		}

		if (typeof global.userAgent === 'string') {
			// Detect the user agent when the `nodeIntegration` option is set to true
			if (global.userAgent.indexOf('Electron') >= 0) {
				return true;
			}

			// Detect Android webview
			if (global.userAgent.indexOf('wv') >= 0) {
				return true;
			}
		}

		return false;
	}
}

function getBrowserWithBowser() {
	const bowserInfo = bowser.detect(global.userAgent);
	const versionParts = bowserInfo.version.split('.');
	const browserInfo = {
		name: bowserInfo.name,
		version: parseInt(versionParts[0], 10),
		minorVersion: versionParts.length > 1 ? parseInt(versionParts[1], 10) : 0,
		platform: bowserInfo.osname,
	};

	return browserInfo;
}

function getBrowserWithUserAgentData(userAgentData) {
	// Object which relates Client Hints API brand names to bowser names
	// so that naming is uniform across each browser detection method

	/*! StartNoStringValidationRegion browser brand-name conversion*/
	const convert = {
		'Google Chrome': 'Chrome',
		'Microsoft Edge': 'Edge',
	};

	const notABrandRegexp = /Not.*A.*Brand/;
	/*! EndNoStringValidationRegion */

	const filteredBrands = userAgentData.brands.filter(
		({ brand }) => brand !== 'Chromium' && !notABrandRegexp.test(brand)
	);
	const chromiumBrands = userAgentData.brands.filter(
		({ brand }) => brand === 'Chromium'
	);

	if (filteredBrands.length === 1 || chromiumBrands.length === 1) {
		const { brand, version } = filteredBrands.length === 1 ? filteredBrands[0] : chromiumBrands[0];

		return {
			name: convert[brand] || brand,
			version: parseInt(version, 10),
			minorVersion: null,
			platform: userAgentData.platform,
		};
	}

	return;
}

export default new Browser();
