import $ from "jquery";
import { onBusyStateChanged } from "GlobalBusyStateTracker";

/*! SuppressStringValidation selector of indicator bar */
const progressBarSelector = "#global-loading-indicator";

class AsyncTaskNotifier {
  private _removeAsyncNotifierSubscription: null | (() => void);

  constructor() {
    this._removeAsyncNotifierSubscription = null;
    this.init();
  }

  init(): void {
    if (this._removeAsyncNotifierSubscription) {
      return;
    }

    this._removeAsyncNotifierSubscription = onBusyStateChanged((showInProgress) => {
      if (showInProgress) {
        this._showInProgress();
      } else {
        this._hideInProgress();
      }
    });
  }

  private _showInProgress(): void {
    $(progressBarSelector).show();
  }

  private _hideInProgress(): void {
    $(progressBarSelector).hide();
  }

  dispose(): void {
    this._removeAsyncNotifierSubscription?.();
    this._removeAsyncNotifierSubscription = null;
  }
}

export const asyncTaskNotifier = new AsyncTaskNotifier();
