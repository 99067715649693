export interface MenuItemViewModel {
  label: string;
  onClick: () => void;
  iconClass?: string;
  isPinned?: boolean;
  isSticky?: boolean;
  buttonTemplateID?: string;
  templateID?: string;
}

export class MenuItem implements MenuItemViewModel {
  index: number | null;
  label!: string;
  onClick!: () => void;
  iconClass?: string | undefined;
  buttonTemplateID?: string;
  templateID?: string;

  constructor(index: number | null, viewModel: MenuItemViewModel) {
    this.index = index !== undefined ? index : null;
    Object.assign(this, viewModel);
  }

  static compare(item0: MenuItem, item1: MenuItem): number {
    if (item0.index === item1.index) {
      return 0;
    } else if (item0.index === null || (item1.index !== null && item0.index > item1.index)) {
      return 1;
    }
    return -1;
  }
}

export default MenuItem;
