import * as stringUtils from 'StringUtils';
import visibilityEvaluator from 'VisibilityEvaluator';
import ko from 'knockout';
import { onUnmounted, ref, watch } from 'vue';
import { useBindingContext } from './BindingContextHook';
import { useUltimateDataItem } from './UltimateDataItemHook';

export function useConditionalVisibility(bindingPath, conditionExpressionOrPK) {
	const isVisible = ref(false);
	const bindingContext = useBindingContext();
	const boundDataItem = useUltimateDataItem(bindingPath);

	if (bindingContext) {
		let computed;
		watch(
			() => [bindingContext.value, boundDataItem.value],
			([rootDataItem, dataItem]) => {
				computed && computed.dispose();
				computed = ko.computed(() => {
					isVisible.value = stringUtils.isGuid(conditionExpressionOrPK)
						? visibilityEvaluator.isVisibleFromConditionWithFallback(rootDataItem, dataItem, conditionExpressionOrPK)
						: visibilityEvaluator.isVisibleFromExpression(rootDataItem, conditionExpressionOrPK);
				});
			},
			{
				immediate: true,
			}
		);

		onUnmounted(() => computed.dispose());
	}

	return isVisible;
}
