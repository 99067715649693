import captionService, { type ResourceInfo } from "CaptionService";
import { getHumanReadableName } from "EntityCalculatedPropertyExtensions";
import global from "Global";
import macroUtils from "MacroUtils";
import { type Entity } from "breeze-client";
import ko, { type Computed, type MaybeSubscribable } from "knockout";

export function observeTitle(
  entity: MaybeSubscribable<Entity | undefined> | undefined,
  formCaption: ResourceInfo | string | undefined,
  formCaptionArgs?: string[]
): Computed<string> {
  return ko.asyncComputed(
    () => ({
      syncValue: getLoadingCaption(),
      asyncPromise: calculateTitleAsync(entity, formCaption, formCaptionArgs),
    }),
    () => {
      if (entity) {
        const rawEntity = ko.unwrap(entity);
        if (rawEntity) {
          // Causes title above to be recalculated if this value changes.
          // This will not consider the dependencies read during macro replacment.
          getHumanReadableName(rawEntity)();
        }
      }
    }
  );
}

export function getLoadingCaption(): string {
  return captionService.getString("985c2f99-9eaa-491e-b9f4-eeeb8af71190", "Loading...");
}

export function getTransformedTitle(
  entityObservable: MaybeSubscribable<Entity | undefined> | undefined,
  caption: string
): string {
  let title = caption;

  const entity = ko.unwrap(entityObservable);
  const isMaterialDesign = global.materialDesign;
  if (entity && !isMaterialDesign) {
    const humanReadableName = getHumanReadableName(entity)();

    if (humanReadableName && !humanReadableName.isGeneric) {
      title = humanReadableName.name + " - " + caption;
    }
  }

  return title;
}

async function calculateTitleAsync(
  entity: MaybeSubscribable<Entity | undefined> | undefined,
  caption: ResourceInfo | string | undefined,
  captionArgs?: string[]
): Promise<string> {
  if (typeof caption !== "object") {
    caption = caption || "";
    return getTransformedTitle(entity, caption);
  }

  if (!captionArgs) {
    const captionFromService = captionService.getStringFromInfo(caption);
    return getTransformedTitle(entity, captionFromService);
  }

  const captionWithMacros = captionService.getString(caption.ResKey, caption.Fallback, ...captionArgs);
  return await macroUtils.replaceMacrosAsync(ko.unwrap(entity), captionWithMacros);
}
