import bindingEvaluator from "BindingEvaluator";
import type { Entity } from "BreezeExtensions";
import { NotificationType } from "NotificationType";
import Notifications from "Notifications";
import { type default as ValidationRegistrar, type ValidationRegistrarItem } from "ValidationRegistrar";
import { useBindingContext } from "VueHooks/BindingContextHook";
import { useKnockoutContext } from "VueHooks/KnockoutContextHook";
import { getUltimateDataItemRef } from "VueHooks/UltimateDataItemHook";
import { useValidationRegistrar } from "VueHooks/ValidationRegistrarHook";
import { type ValidationState } from "glow-types";
import ko, { type Computed, type BindingContext } from "knockout";
import { computed, reactive, onUnmounted, watch } from "vue";
import { toValue, type MaybeRefOrGetter } from "vue3-polyfill";

export function useValidationState(bindingPath: MaybeRefOrGetter<string>): ValidationState {
  const bindingContext = useBindingContext();
  return useValidationStateWithBindingContext(bindingContext, bindingPath);
}

export function useValidationStateWithBindingContext(
  bindingContext: MaybeRefOrGetter<Entity | null> | undefined,
  bindingPath: MaybeRefOrGetter<string>,
): ValidationState {
  const koContext = useKnockoutContext();
  const validationRegistrar = useValidationRegistrar();

  const [validationState, dispose] = getValidationState(bindingPath, bindingContext, koContext, validationRegistrar);

  onUnmounted(() => dispose());

  return validationState;
}

export function getValidationState(
  bindingPath: MaybeRefOrGetter<string>,
  bindingContext: MaybeRefOrGetter<Entity | null> | undefined,
  koContext: BindingContext | undefined,
  validationRegistrar: ValidationRegistrar | undefined,
): [ValidationState, () => void] {
  const targetProperty = computed(() => bindingEvaluator.getPropertyName(toValue(bindingPath)));
  const validationState = reactive<ValidationState>({
    alertLevel: NotificationType.Success,
    targetKey: undefined,
    targetProperty: toValue(targetProperty),
    messages: [],
    error: false,
    warning: false,
  });

  if (!toValue(bindingPath)) {
    return [validationState, (): void => {}];
  }

  const [dataItem, disposeDataItem] = getUltimateDataItemRef(bindingPath, bindingContext, koContext);

  if (!dataItem) {
    return [validationState, (): void => {}];
  }

  let koComputed: Computed<void> | undefined;
  const unwatch = watch(
    [(): Entity | null | undefined => toValue(dataItem), (): string => toValue(targetProperty)],
    ([dataItem, targetProperty]) => {
      koComputed?.dispose();
      if (dataItem && targetProperty) {
        koComputed = ko.computed(() => {
          const notifications = Notifications.get(dataItem);
          if (notifications) {
            const alerts = notifications.alerts(targetProperty);
            if (alerts.length > 0) {
              validationState.alertLevel = notifications.level(targetProperty);
              validationState.targetKey = alerts[0].entityPK as string;
              validationState.targetProperty = targetProperty;
              validationState.messages = [alerts.find((a) => a.Level === validationState.alertLevel)?.Text ?? ""];
              validationState.error =
                validationState.alertLevel === NotificationType.Error ||
                validationState.alertLevel === NotificationType.MessageError;
              validationState.warning = validationState.alertLevel === NotificationType.Warning;
            } else {
              setEmptyValidationState(validationState, targetProperty);
            }
          } else {
            setEmptyValidationState(validationState, targetProperty);
          }
        });
      } else {
        koComputed = undefined;
      }
    },
    { immediate: true },
  );

  const unregisterBoundItem = registerBoundItem(bindingPath, bindingContext, koContext, validationRegistrar);

  return [
    validationState,
    (): void => {
      koComputed?.dispose();
      disposeDataItem();
      unregisterBoundItem();
      unwatch();
    },
  ];
}

function setEmptyValidationState(validationState: ValidationState, targetProperty: string): void {
  validationState.alertLevel = NotificationType.Success;
  validationState.targetKey = undefined;
  validationState.targetProperty = targetProperty;
  validationState.messages = [];
  validationState.error = false;
  validationState.warning = false;
}

function registerBoundItem(
  bindingPath: MaybeRefOrGetter<string>,
  bindingContext: MaybeRefOrGetter<Entity | null> | undefined,
  koContext: BindingContext | undefined,
  validationRegistrar: ValidationRegistrar | undefined,
): () => void {
  if (validationRegistrar) {
    const propertyGetterFunc = getBoundPropertyAsync.bind(null, bindingContext, koContext, bindingPath);

    validationRegistrar.registerBoundItem(propertyGetterFunc);
    return () => validationRegistrar.unregisterBoundItem(propertyGetterFunc);
  }

  return () => {};
}

function getBoundPropertyAsync(
  bindingContextRef: MaybeRefOrGetter<Entity | null> | undefined,
  koContext: BindingContext | undefined,
  bindingPath: MaybeRefOrGetter<string>,
): Promise<ValidationRegistrarItem | undefined> {
  return bindingEvaluator
    .loadUltimateDataItemAsync(koContext, toValue(bindingContextRef), toValue(bindingPath))
    .then((entity: unknown) => {
      if (entity && (entity as Entity).entityAspect) {
        const propertyName = bindingEvaluator.getPropertyName(toValue(bindingPath));
        return {
          entity: entity as Entity,
          propertyNames: [propertyName],
        };
      }
      return undefined;
    });
}
