import Measure from "Measure";

interface Item {
  description: string;
  factor: number;
  offset?: number;
  sequence: number;
}

interface UnitData {
  unitType: string;
  hasOffsets: boolean;
  items: Record<string, Item>;
  defaultUnit: string;
  unitMaxLength: number;
  allowNegatives: boolean;
}

interface CodeDescription {
  code: string;
  description: string;
}

class UnitStrategy {
  private readonly unitData: UnitData;

  get unitType(): string {
    return this.unitData.unitType;
  }

  private readonly _defaultUnit: string;
  get defaultUnit(): string {
    return this._defaultUnit;
  }

  private readonly _unitMaxLength: number;
  get unitMaxLength(): number {
    return this._unitMaxLength;
  }

  private readonly _allowNegatives: boolean;
  get allowNegatives(): boolean {
    return this._allowNegatives;
  }

  private readonly _allUnits: string[];
  get allUnits(): string[] {
    return this._allUnits;
  }

  private readonly _codeDescList: CodeDescription[];
  get codeDescList(): CodeDescription[] {
    return this._codeDescList;
  }

  constructor(data: UnitData) {
    this.unitData = data;
    this._defaultUnit = data.defaultUnit;
    this._unitMaxLength = data.unitMaxLength;
    this._allowNegatives = data.allowNegatives;

    const sortedItemPairs = Object.entries(data.items).sort((pair1, pair2) => {
      return pair1[1].sequence - pair2[1].sequence;
    });

    this._allUnits = sortedItemPairs.map((pair) => {
      return pair[0];
    });

    this._codeDescList = sortedItemPairs.map((pair) => {
      return { code: pair[0], description: pair[1].description };
    });
  }

  convert(value: number, fromUnit: string, toUnit: string): number | null {
    const fromItem = this.unitData.items[fromUnit];
    const toItem = this.unitData.items[toUnit];
    if (!fromItem || !toItem) {
      return null;
    }

    if (fromUnit === toUnit) {
      return value;
    }

    if (this.unitData.hasOffsets) {
      if (fromItem.offset === undefined || toItem.offset === undefined) {
        return NaN;
      }
      value = (value - fromItem.offset) / fromItem.factor;
      return value * toItem.factor + toItem.offset;
    } else {
      return (value * fromItem.factor) / toItem.factor;
    }
  }

  sum(measures: Measure[]): Measure {
    const totalsByUnit: { [key: string]: number } = {};

    measures.forEach((measure) => {
      if (this.hasUnit(measure.unit)) {
        if (totalsByUnit[measure.unit]) {
          totalsByUnit[measure.unit] += measure.magnitude;
        } else {
          totalsByUnit[measure.unit] = measure.magnitude;
        }
      }
    });

    const unitKeys = Object.keys(totalsByUnit);
    if (unitKeys.length === 1) {
      const onlyUnit = unitKeys[0];
      return new Measure(totalsByUnit[onlyUnit], onlyUnit);
    }

    let sumNumer = 0;

    unitKeys.forEach((unit) => {
      const magnitude = totalsByUnit[unit];
      const convertedMagnitude = this.convert(magnitude, unit, this.defaultUnit);
      if (convertedMagnitude) {
        sumNumer += convertedMagnitude;
      }
    });

    return new Measure(sumNumer, this.defaultUnit);
  }

  getResourceStringKey(unitCode: string): string {
    const mstring = "MeasureUnit";
    return `${mstring}|${this.unitData.unitType}|${unitCode}`;
  }

  hasUnit(unit: string): boolean {
    return !!this.unitData.items[unit];
  }
}

export default UnitStrategy;
