import Promise from 'bluebird';
import breeze from 'breeze-client';
import captionService from 'CaptionService';
import errorHandler from 'ErrorHandler';
import Notifications from 'Notifications';
import { NotificationType } from 'NotificationType';
import { SaveEntityError, SaveEntityErrorType } from 'SaveEntityError';
import {
	isEqual,
	notifyChangedFieldValuesAsync,
} from 'Shared/Services/EntitySaveServiceConcurrencyMergeHelper';
import systemAuditPropertiesHelper from 'SystemAuditPropertiesHelper';

const indexPropertyNames = Object.freeze(['E2_ParentID', 'E2_AddressType', 'E2_AddressSequence']);

async function handleJobDocAddressDuplicateUniqueIndexConflictAsync(
	entityManager,
	entity,
	error,
	options
) {
	if (entity?.entityType?.baseEntityType?.interfaceName !== 'IAddress') {
		const message = `JobDocAddressDuplicateUniqueIndexConflict entity has non-Address type: ${entity?.entityType?.interfaceName}`;
		const concurrencyError = new SaveEntityError(SaveEntityErrorType.Concurrency, {
			entity,
			message,
			innerError: error,
		});
		errorHandler.reportError(concurrencyError, message);
		throw concurrencyError;
	}

	if (!entity.entityAspect.entityState.isAdded()) {
		setIndexErrorAlert(entity);
		throw new SaveEntityError(SaveEntityErrorType.SaveValidation);
	}

	const query = breeze.EntityQuery.from(entity.entityType.defaultResourceName).where(
		breeze.Predicate.and(
			indexPropertyNames.map((propertyName) =>
				breeze.Predicate.create(
					propertyName,
					breeze.FilterQueryOp.Equals,
					entity[propertyName]()
				)
			)
		)
	);

	if (entityManager.executeQueryLocally(query).length > 1) {
		setIndexErrorAlert(entity);
		throw new SaveEntityError(SaveEntityErrorType.SaveValidation);
	}

	if (options?.shouldReconcileConflicts === false) {
		return false;
	}

	const localChanges = [];
	const tableCode = entity.entityType.tableCode;
	for (const dataProperty of entity.entityType.dataProperties) {
		if (
			!(
				dataProperty.isPartOfKey ||
				systemAuditPropertiesHelper.isSystemAuditProperty(dataProperty.name, tableCode)
			)
		) {
			const localValue = entity[dataProperty.name]();
			if (localValue !== dataProperty.defaultValue) {
				localChanges.push({ dataProperty, localValue });
			}
		}
	}

	const queryResult = await entityManager.executeQuery(query);

	if (queryResult.results.length === 1) {
		const resultEntity = queryResult.results[0];
		await entity.entityAspect.deleteAsync();

		let mergedSuccessfully = true;
		const notificationPromises = localChanges.map(({ dataProperty, localValue }) => {
			const reloadedValue = resultEntity[dataProperty.name]();

			if (reloadedValue !== localValue) {
				if (isEqual(reloadedValue, dataProperty.defaultValue, dataProperty)) {
					resultEntity[dataProperty.name](localValue);
				} else {
					mergedSuccessfully = false;
					return notifyChangedFieldValuesAsync(
						resultEntity,
						dataProperty.name,
						localValue,
						reloadedValue
					);
				}
			}
			return Promise.resolve();
		});

		await Promise.all(notificationPromises);

		return mergedSuccessfully;
	} else if (queryResult.results.length > 1) {
		const message =
			'JobDocAddressDuplicateUniqueIndexConflict: query returned more than one result';
		const concurrencyError = new SaveEntityError(SaveEntityErrorType.Concurrency, {
			entity,
			message,
			innerError: error,
		});
		errorHandler.reportError(concurrencyError, message);
		throw concurrencyError;
	} else {
		return false;
	}
}

function setIndexErrorAlert(entity) {
	const interfaceName = entity.entityType.interfaceName;
	const propertyName = 'E2_AddressSequence';
	const alertMessage = captionService.getString(
		'f22a248c-f200-484b-aa93-dfa0ca7ae37e',
		'There is already another {0} address with sequence {1}.',
		entity.E2_AddressType(),
		entity.E2_AddressSequence()
	);

	Notifications.get(entity).push({
		propertyName,
		caption: captionService.getCaptionForProperty(interfaceName, propertyName).caption,
		Text: alertMessage,
		Level: NotificationType.Error,
		isServerNotification: true,
	});
}

export default handleJobDocAddressDuplicateUniqueIndexConflictAsync;
