import $ from 'jQueryExtensions';
import ko from 'knockout';
import guid from 'GuidGenerator';
import 'jquery.ui.resizable';

ko.bindingHandlers.gwResizable = {
	init(element, valueAccessor) {
		const $element = $(element);
		let $adjustmentElement = $([]);
		const options = ko.unwrap(valueAccessor());

		if (options.resizeAdjustment) {
			$adjustmentElement = $element.getFocusScope().find(options.resizeAdjustment.selector).css(options.resizeAdjustment.css, $element.outerWidth());
			options.resize = function (event, ui) {
				$adjustmentElement.css(options.resizeAdjustment.css, ui.size.width);
			};
		}

		if (options.maxWidthPercent) {
			/*! SuppressStringValidation String validation suppressed in initial refactor */
			const eventId = 'resize.resizable' + guid.newGuid();
			const denominator = 100 / options.maxWidthPercent;
			options.maxWidth = $(window).width() / denominator;
			$(window).on(eventId, () => {
				const maxWidth = $(window).width() / denominator;
				/*! SuppressStringValidation String validation suppressed in initial refactor */
				$element.resizable('option', 'maxWidth', maxWidth);

				const sidebarWidth = $element.outerWidth();
				if (sidebarWidth > maxWidth) {
					$element.width(maxWidth);
				}

				if (options.resizeAdjustment) {
					$adjustmentElement.css(options.resizeAdjustment.css, sidebarWidth > maxWidth ? maxWidth : sidebarWidth);
				}
			});

			ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
				$(window).off(eventId);
			});
		}

		$element.resizable(options);
	}
};
