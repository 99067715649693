import { DateTimeType } from 'DateTimeConstants';
import { fromISOString } from 'DateTimeOffset';
import breeze from 'breeze-client';
import moment from 'moment';

export function createJsonResultsAdapter(expandPathsMap, enableDateTimeConversion) {
	return new breeze.JsonResultsAdapter({
		/*! SuppressStringValidation valid name glow */
		name: 'glow',
		visitNode(node, mappingContext, nodeContext) {
			const result = {};
			if (!node) {
				return result;
			}

			let entityType = getEntityType(node, mappingContext, nodeContext);
			const metadataStore = mappingContext.entityManager.metadataStore;

			let isProjection = mappingContext.query && mappingContext.query.selectClause;
			if (isProjection) {
				let fromEntityType = mappingContext.query.fromEntityType;
				if (!fromEntityType) {
					const fromEntityTypeName = metadataStore.getEntityTypeNameForResourceName(
						mappingContext.query.resourceName
					);
					if (fromEntityTypeName) {
						fromEntityType = metadataStore.getEntityType(fromEntityTypeName);
					}
				}

				if (fromEntityType) {
					const selectEntityTypes = mappingContext.query.selectClause.propertyPaths
						.map((x) => getPathEntityType(0, x.split('.'), fromEntityType))
						.filter(Boolean);

					if (selectEntityTypes.length > 0) {
						const type = getNodeEntityType(node, selectEntityTypes);
						if (type) {
							isProjection = false;
							entityType = type;
						}
					}
				}
			}
			if (entityType) {
				if (!isProjection || mappingContext.query.geoColumnsHackApplied) {
					result.entityType = entityType;

					const baseUri = mappingContext.dataService.serviceName;
					let uriKey = node['@odata.id'];
					if (uriKey && uriKey.startsWith(baseUri)) {
						uriKey = uriKey.substring(baseUri.length);
					}

					let unknownProperties;
					entityType.dataProperties.forEach(({ name }) => {
						if (node[name] === undefined) {
							if (!unknownProperties) {
								unknownProperties = [];
							}
							unknownProperties.push(name);
						}
					});

					const expandPathsContext = expandPathsMap.get(mappingContext);
					result.extraMetadata = {
						uriKey,
						etag: node['@odata.etag'],
						expands:
							expandPathsContext &&
							expandPathsContext[node[entityType.keyProperties[0].name]],
						unknownProperties,
					};
				}

				if (enableDateTimeConversion) {
					initDateTimeProperties(node, entityType);
				}
			}

			// OData v3 - projection arrays will be enclosed in a results array
			if (node.results) {
				result.node = node.results;
			}

			const propertyName = nodeContext.propertyName;
			const isMetadataProperty = propertyName?.startsWith('@odata');
			result.ignore =
				node.__deferred ||
				isMetadataProperty ||
				// EntityKey properties can be produced by EDMX models
				(propertyName === 'EntityKey' &&
					node.$type &&
					/*! SuppressStringValidation valid name */
					node.$type.startsWith('System.Data'));

			return result;
		},
	});
}

function getEntityType(node, mappingContext, nodeContext) {
	const metadataStore = mappingContext.entityManager.metadataStore;

	const type = node['@odata.type'] && node['@odata.type'].replace('#', '');
	let entityTypeName = breeze.MetadataStore.normalizeTypeName(type);

	if (!entityTypeName && nodeContext.nodeType === 'root' && mappingContext.query.resourceName) {
		entityTypeName = metadataStore.getEntityTypeNameForResourceName(
			mappingContext.query.resourceName
		);
	}

	let entityType = entityTypeName && metadataStore.getEntityType(entityTypeName, true);

	if (
		!entityType &&
		nodeContext.navigationProperty &&
		nodeContext.navigationProperty.entityType
	) {
		entityType = nodeContext.navigationProperty.entityType;
	}

	return entityType;
}

function getNodeEntityType(node, selectEntityTypes) {
	return selectEntityTypes.find((type) => {
		const keyProp =
			type.keyProperties && type.keyProperties.length > 0 && type.keyProperties[0].name;
		return node[keyProp];
	});
}

function getPathEntityType(segmentIndex, pathSegments, entityType) {
	const navProp = entityType.getNavigationProperty(pathSegments[segmentIndex]);
	if (navProp) {
		if (pathSegments.length === segmentIndex + 1) {
			return navProp.entityType;
		}

		return getPathEntityType(segmentIndex + 1, pathSegments, navProp.entityType);
	}

	return null;
}

const nodeInitializedSymbol = Symbol();

function initDateTimeProperties(node, entityType) {
	if (node[nodeInitializedSymbol]) {
		return;
	}
	node[nodeInitializedSymbol] = true;
	entityType.getProperties().forEach((property) => {
		if (property.dataType !== breeze.DataType.DateTimeOffset) {
			return;
		}

		let value = node[property.name];

		if (typeof value !== 'string') {
			return;
		}

		if (property.dateTimeType === DateTimeType.DateTimeOffset) {
			node[property.name] = fromISOString(value);
		} else {
			if (property.dateTimeType === DateTimeType.DateTimeUtc) {
				node[property.name] = fromISOString(`${value}`);
			} else {
				value = moment(value.slice(0, -1));
				if (value.isValid()) {
					node[property.name] = value.toDate();
				}
			}
		}
	});
}
