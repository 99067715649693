import themeItems from 'ThemeItems';

/*! StartNoStringValidationRegion No captions here! */
const themeItemsToCssMapping = {
	[themeItems.Background.Value]: 'background',
	[themeItems.Button.Value]: 'button',
	[themeItems.DefaultButton.Value]: 'default-button',
	[themeItems.DefaultButtonText.Value]: 'default-button-text',
	[themeItems.FieldControl.Value]: 'field-control',
	[themeItems.FieldTitleText.Value]: 'field-title-text',
	[themeItems.GeneralText.Value]: 'general-text',
	[themeItems.Hover.Value]: 'hover',
	[themeItems.HoverText.Value]: 'hover-text',
	[themeItems.Icons.Value]: 'icons',
	[themeItems.ValueText.Value]: 'value-text'
};
/*! EndNoStringValidationRegion */

class ThemeHelper {
	getForegroundCss(themeColor, defaultColor) {
		/*! SuppressStringValidation CSS */
		return getCss('g-foreground-theme', themeColor, defaultColor);
	}

	getBackgroundCss(themeColor, defaultColor) {
		/*! SuppressStringValidation CSS */
		return getCss('g-background-theme', themeColor, defaultColor);
	}
}

const getCss = (prefixCss, themeColor, defaultColor) => `${prefixCss}-${themeItemsToCssMapping[themeColor] || themeItemsToCssMapping[defaultColor]}`;

export default new ThemeHelper();
