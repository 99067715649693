import { AuthenticationSource } from 'AuthenticationSourceConstants';
import autoLogonService from 'AutoLogonService';
import browser from 'Browser';
import captionService from 'CaptionService';
import {
    AlertDisplayTime,
    HeaderMenuItem,
    LinkTargetAttributeValue,
    LinksDisplayMode,
    LogonProviderType,
    PinSettingsKey,
    WorkflowMenuTemplate,
} from 'Constants';
import global from 'Global';
import PinnableMenuItem from 'PinnableMenuItem';
import userSession from 'UserSession';
import ko from 'knockout';
import _ from 'underscore';

export class MenuItemsProvider {
	getHome(options) {
		const menuItems = [];
		/*! SuppressStringValidation disabled is css class */
		const homePageOptions = options.isHomePage || options.accessIsDenied ? { additionalCss: 'disabled', clickHandler() { } } : {};
		/*! SuppressStringValidation #/index is not a caption */
		menuItems.push(createAnchorItem('#/index', captionService.getString('dcc3916d-d6d7-43c3-a4ea-8931f1ec748c', 'Home'), homePageOptions));
		return menuItems;
	}

	getLoggedInInfo(options) {
		const menuItems = [];
		const username = userSession.sessionData().loggedOnUser ? userSession.sessionData().loggedOnUser.fullName : undefined;
		menuItems.push({ username, profileImage: options.profileImage, templateID: 'loggedInInfoTemplate' });
		return menuItems;
	}

	getDocuments(options) {
		const menuItems = [];
		if (options.isTaskPage) {
			if (ko.unwrap(options.canShowDocuments)) {
				menuItems.push(createPinnableMenuItem(HeaderMenuItem.Documents, { workflowContext: options.workflowContext, templateID: 'documentsTemplate', buttonTemplateID: 'documentsButtonTemplate' }));
			}

			if (ko.unwrap(options.canShowEDocs)) {
				/*! SuppressStringValidation icon-edoc is not a caption */
				menuItems.push(createPinnableMenuItem(HeaderMenuItem.EDocs, createAnchorItem('', captionService.getString('ade056a0-c6e2-4dbd-adc6-30740ff04745', 'eDocs'), { clickHandler: options.showEDocsAsync })));
			}
		}
		return menuItems;
	}

	getMessages(options) {
		const menuItems = [];
		if (ko.unwrap(options.canShowMessages)) {
			menuItems.push(createPinnableMenuItem(HeaderMenuItem.Messages, createAnchorItem('', captionService.getString('a9f4a691-1c18-4103-9f60-05f16321fbe5', 'Messages'), { clickHandler: options.showMessagesAsync })));
		}
		return menuItems;
	}

	getNotesOptions(options) {
		const menuItems = [];
		if (ko.unwrap(options.canShowNotes)) {
			menuItems.push(createPinnableMenuItem(HeaderMenuItem.Notes, createAnchorItem('#', captionService.getString('6F4586F5-B380-453D-958E-9269197DCDB8', 'Notes'), { clickHandler: options.showNotesAsync, buttonTemplateID: 'notesButtonTemplate' })));
		}
		return menuItems;
	}

	getLogsOptions(options) {
		const menuItems = [];
		if (ko.unwrap(options.canShowLogs)) {
			menuItems.push(createPinnableMenuItem(HeaderMenuItem.Logs, createAnchorItem('#', captionService.getString('4B0D6D3B-ED13-45F0-816A-B9EDBF58009F', 'Logs'), { clickHandler: options.showLogsAsync })));
		}
		return menuItems;
	}

	getUserOptions(options) {
		const menuItems = [];
		if (!options.accessIsDenied) {
			menuItems.push(createHeaderListItem(captionService.getString('778056ef-083b-46e5-b7c9-32d24437c58f', 'User')));
			if (options.isStaff) {
				/*! SuppressStringValidation icon-user is not a caption */
				const profileFormFlow = global.materialDesign ? '88737014a0b34589a5f14fa1017f2a38' : 'e17d3fd8477d42f49f8616d7b28499cd';
				menuItems.push(createPinnableMenuItem(HeaderMenuItem.MyProfile, createAnchorItem(`#/formFlow/${profileFormFlow}/${userSession.sessionData().userPK}`, captionService.getString('55788855-8ba5-4c64-87d8-5f85f61ace47', 'My Profile'))));
			}
			if (userSession.sessionData().authenticationSource === AuthenticationSource.Local) {
				menuItems.push(createAnchorItem('#/changePassword', captionService.getString('6e549d86-8f45-468b-8893-b3f04d1d1a5d', 'Change Password'), { clickHandler: options.postNavigateByHref, templateID: 'anchorMenuItemTemplateWithoutPin' }));
			}
			if (options.isStaff) {
				menuItems.push(createAnchorItem('#/lastLoginDetails/changeBranchAndDepartment', captionService.getString('1C6218B0-4431-443A-8F8A-CBED47936BC6', 'Change Branch/Department'), { clickHandler: options.postNavigateByHref }));
			}
		}
		return menuItems;
	}

	getAdditionalInfoOptions(options) {
		const menuItems = [];
		if (options.showAdditionalInfo) {
			menuItems.push(createHeaderListItem(captionService.getString('bc4f161b-c3b5-48ec-afe1-d6a3b1350402', 'Show In Side Panel')));

			/*! SuppressStringValidation Caption key and text is passed to template */
			menuItems.push(createCheckBox(options.showAdditionalInfo, captionService.getCaptionFromKey('c892074d-7861-4c60-9151-8ea0f1c649ef', 'Additional Info'), { disableConfigTmpl: true }));
		}

		return menuItems;
	}

	getDisplayOptions(options) {
		const menuItems = [];

		menuItems.push(createHeaderListItem(captionService.getString('28a2cb3b-1926-4634-96db-fb43342e2eea', 'Display Options')));

		if (options.isHeaderLinksAllowed) {
			menuItems.push({ templateID: 'headerLinksSubmenuTemplate' , LinksDisplayMode });
		}

		if (options.isGridFilterAndLinksAllowed) {
			menuItems.push({ templateID: 'gridLinksSubmenuTemplate' , LinksDisplayMode});
			menuItems.push({ templateID: 'filterLinksSubmenuTemplate', LinksDisplayMode });
		}

		menuItems.push({ templateID: 'alertDisplayTimeSubmenuTemplate' , AlertDisplayTime});

		return menuItems;
	}

	getConfiguratorOptions(options) {
		const menuItems = [];
		const isSecurityConfigurationAllowed = userSession.isLoggedOn() && !!userSession.sessionData().loggedOnUser.isController;

		if (isSecurityConfigurationAllowed || options.configureMenuViewModel) {
			menuItems.push(createHeaderListItem(captionService.getString('ccc0f97d-fc7b-4e52-b502-f24d5e49a9d0', 'Configuration'), isSecurityConfigurationAllowed || options.configureMenuViewModel.hasConfigureOptions));
		}

		if (options.configureMenuViewModel) {
			menuItems.push(createPinnableMenuItem(HeaderMenuItem.Configure,
				{ caption: captionService.getString('12477B24-9035-47C6-AACF-003099885142', 'Configure'), templateID: 'configurationMenuItemTemplate', buttonTemplateID: 'configurationButtonTemplate', configureMenuViewModel: options.configureMenuViewModel }));
		}

		return menuItems;
	}

	getHelpOptions(options) {
		const helpMenuItems = createHelpItems(options);
		if (!helpMenuItems.length) {
			return [];
		}

		let menuItems = [];
		menuItems.push(createHeaderListItem(captionService.getString('96ca36a0-08e5-4a8a-bd9e-adaf92c2681d', 'Help')));
		menuItems = menuItems.concat(helpMenuItems);
		return menuItems;
	}

	getLoggedInOptions(options) {
		const menuItems = [];
		/*! SuppressStringValidation divider class is not a caption */
		menuItems.push({ additionalClass: 'divider' });
		/*! SuppressStringValidation disabled class is not a caption */
		menuItems.push(createPinnableMenuItem(HeaderMenuItem.Jump, { templateID: 'jumpMenuItemTemplate', buttonTemplateID: 'jumpButtonTemplate' }));

		/*! SuppressStringValidation url is not a caption */
		const module = options.isStaff ? 'GHS' : 'GHC';
		const globalDashboardURL = global.getModuleUrl(module);

		/*! SuppressStringValidation _self opens link */
		if (!options.isDashboardPage && !options.accessIsDenied && global.portalInfo.showGlobalDashboard) {
			menuItems.push(createPinnableMenuItem(HeaderMenuItem.GlobalDashboard, createAnchorItem(globalDashboardURL, captionService.getString('e22c8fbf-ad65-414e-92b7-43293d9c810c', 'Global Dashboard'), { anchorLinkTarget: LinkTargetAttributeValue.Blank })));
		}

		menuItems.push(createAnchorItem('#/logOff', captionService.getString('34b06b96-1672-482e-b434-24f2ca7b95c5', 'Log Out'), { clickHandler: options.postNavigateByHref }));
		return menuItems;
	}

	getHelpMenuItems(options) {
		return createHelpItems(options);
	}

	getMappingWizardMenuItems(options) {
		return createMappingWizardMenuItems(options);
	}

	getCustomizeColumnMenuItems(options) {
		return createCustomizeColumnMenuItems(options);
	}

	getWorkflowMenuItems(options) {
		const menuItems = [];
		if (ko.unwrap(options.canShowWorkflow)) {
			menuItems.push(createPinnableMenuItem(HeaderMenuItem.Workflow, { templateID: 'workflowMenuItemTemplate', buttonTemplateID: 'workflowButtonTemplate', WorkflowMenuTemplate }));
		}

		return menuItems;
	}

	getJumpMenuItems() {
		return [{ templateID: 'jumpMenuTemplate' }];
	}

	getCustomPinnableMenuItems(customPinnableMenuItems) {
		if (!customPinnableMenuItems || !Array.isArray(customPinnableMenuItems)) {
			return [];
		}

		const menuItems = [];
		const groups = _.groupBy(customPinnableMenuItems, 'header');
		const headers = Object.keys(groups).sort();

		headers.forEach((header) => {
			menuItems.push(createHeaderListItem(header));
			groups[header].forEach((group) => {
				group.items.forEach((item) => {
					menuItems.push(createPinnableMenuItem(item.key, item.viewModel));
				});
			});
		});

		return menuItems;
	}
}

function getUserERequestString() {
	const userSessionData = userSession.sessionData();
	return `?languageCode=${(userSessionData.languageCode || '')}&licenceCode=${(userSessionData.licenceCode || '')}&userCode=${(userSessionData.userCode || '')}&userEmailAddress=${(userSessionData.userEmailAddress || '')}`;
}

function createAnchorItem(anchorLink, anchorLinkCaption, anchorOptions) {
	anchorOptions = anchorOptions || {};

	return {
		link: anchorLink,
		linkCaption: anchorLinkCaption,
		onClick: anchorOptions.clickHandler,
		linkTarget: anchorOptions.anchorLinkTarget,
		additionalCss: anchorOptions.additionalCss,
		iconClass: anchorOptions.iconClass,
		templateID: anchorOptions.templateID || 'anchorMenuItemTemplate',
		buttonTemplateID: anchorOptions.buttonTemplateID || 'anchorButtonTemplate',
	};
}

export function createHeaderListItem(caption, condition) {
	/*! StartNoStringValidationRegion dropdown-header class is not a caption */
	const result = {
		caption,
		additionalClass: 'dropdown-header'
	};
	/*! EndNoStringValidationRegion */

	if (typeof condition !== 'undefined') {
		result.templateID = 'conditionalMenuItemTemplate';
		result.condition = condition;
	}

	return result;
}

function createPinnableMenuItem(itemInfo, viewModel) {
	return new PinnableMenuItem(PinSettingsKey.Header, itemInfo, viewModel);
}

function createCheckBox(value, caption, options) {
	return {
		value,
		checkBoxCaption: caption,
		templateID: options.template || 'checkBoxTemplate',
		disableConfigTmpl: options.disableConfigTmpl,
		isReadOnly: options.isReadOnly || false
	};
}

function createHelpItems(options) {
	const menuItems = [];

	// Help on this Page
	if (!options.accessIsDenied && !global.materialDesign) {
		menuItems.push(createPinnableMenuItem(HeaderMenuItem.Help, createAnchorItem('#', captionService.getString('ee7d37f2-61d6-4736-9df8-94a7e92cb8e2', 'Help on this Page'), { clickHandler: options.showHelpPage })));
	}

	// CargoWise Learning
	if (!options.accessIsDenied && global.portalInfo.userType === LogonProviderType.Staff && !browser.isElectronOrAndroidRuntime()) {
		menuItems.push(createPinnableMenuItem(HeaderMenuItem.ELearning, createAnchorItem('#', captionService.getString('96d579a9-542f-4672-8733-bca5a46d386f', 'CargoWise Learning'), { clickHandler: options.openWiseLearning })));
	}

	// eRequest
	if (global.portalInfo.userType === LogonProviderType.Staff && !browser.isElectronOrAndroidRuntime()) {
		const newERequestFormFlowPK = 'ac2649c8b61c45b984b07856bd190d7c';
		const incidentManagementPortalUrl = `https://myaccount-portal.cargowise.com/Portals/INC/${global.formFactorPath}`;
		const newERequestURL = `${incidentManagementPortalUrl}#/formFlow/${newERequestFormFlowPK}${getUserERequestString()}`;
		menuItems.push(createPinnableMenuItem(HeaderMenuItem.ERequestPortal, createAnchorItem('#', captionService.getString('2A0303F8-3588-4D71-A8EC-C55873194A92', 'Manage eRequest'), { clickHandler: autoLogonService.redirectWithAutologinAsync.bind(null, incidentManagementPortalUrl, false) })));
		menuItems.push(createPinnableMenuItem(HeaderMenuItem.NewERequest, createAnchorItem('#', captionService.getString('465377D9-65F9-47DB-8671-B5D05849898E', 'New eRequest'), { clickHandler: autoLogonService.redirectWithAutologinAsync.bind(null, newERequestURL, false) })));
	}

	// Policy Links
	if (!options.accessIsDenied && global.policyLinks?.length && !browser.isElectronOrAndroidRuntime()) {
		global.policyLinks.forEach((policyLink) => {
			menuItems.push(createAnchorItem(policyLink.url, policyLink.caption, { anchorLinkTarget: LinkTargetAttributeValue.Blank }));
		});
	}

	// About
	if (!options.accessIsDenied && !global.materialDesign) {
		menuItems.push(createPinnableMenuItem(HeaderMenuItem.About, createAnchorItem('#/support/showAbout', captionService.getString('ef3f87a5-055c-4882-8b2d-f11a725fd854', 'About'), { clickHandler: options.postNavigateByHref })));
	}

	// Disclaimer
	if (!options.accessIsDenied && options.showGPSDisclaimer) {
		menuItems.push(createPinnableMenuItem(HeaderMenuItem.GPSDisclaimer, createAnchorItem('#/support/showGPSDisclaimer', captionService.getString('2e9e6d0b-2929-4d1a-8016-e779a24778d2', 'Disclaimer'), { clickHandler: options.showGPSDisclaimer })));
	}

	return menuItems;
}

function createMappingWizardMenuItems(options) {
	const menuItems = [];
	menuItems.push(createAnchorItem('#', captionService.getString('46e65265-f4bd-4511-bd17-c8f5390b6c1f', 'Clear Map'), { clickHandler: options.clearMappings.bind(null, options.mapping) }));
	menuItems.push(createAnchorItem('#', captionService.getString('d6e4d60d-1724-4675-944f-28f7adb7ecd3', 'Import Mapping from File'), { clickHandler: options.importMappingFromFileAsync }));
	if (ko.unwrap(options.mapping.PK)) {
		menuItems.push(createAnchorItem('#', captionService.getString('5591a36e-088a-46fd-8547-a6e2ae197314', 'Export Mapping to File'), { clickHandler: options.exportMappingToFile }));
		menuItems.push(createAnchorItem('#', captionService.getString('7ef27ee8-affc-48ac-8ccb-14f84b8b9af6', 'Delete Mapping'), { clickHandler: options.deleteMapping.bind(null, options) }));
	}
	return menuItems;
}

function createCustomizeColumnMenuItems(options) {
	const menuItems = [];
	if (ko.unwrap(options.showEnableAllButton())) {
		menuItems.push(createAnchorItem('#', captionService.getString('fc1c6bb2-930c-4b1e-b8e0-2f82dbeee705', 'Add All Columns'), { clickHandler: options.enableAllItems.bind(options) }));
	}
	if (ko.unwrap(options.showDisableAllButton())) {
		menuItems.push(createAnchorItem('#', captionService.getString('7385d072-b127-4963-bd26-689717226a05', 'Remove All Columns'), { clickHandler: options.disableAllItems.bind(options) }));
	}
	return menuItems;
}
