import global from 'Global';
import * as stringUtils from 'StringUtils';
import 'jQueryBinaryTransport';
import 'jq-ajax-progress';
import $ from 'jquery';
import 'jquery.ui.widget';
import ko from 'knockout';

// From http://stackoverflow.com/questions/11467201/jquery-statuscode-404-how-to-get-jqxhr-url
$.ajaxSetup(
	{
		beforeSend(jqXHR, settings) {
			jqXHR.url = settings.url;
		}
	});

const $originalAjax = $.ajax.bind($);

$.ajax = function (url, options) {
	if (typeof url === 'object') {
		options = url;
		url = undefined;
	}

	const clonedOptions = Object.assign({}, options);
	return $originalAjax(url, clonedOptions);
};

// From http://stackoverflow.com/a/7557433/5628
$.fn.isInViewport = function () {
	const rect = this[0].getBoundingClientRect();
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= window.innerHeight &&
		rect.right <= window.innerWidth
	);
};

$.fn.scrollIntoViewIfRequired = function (alignTop) {
	if (this.length === 1 && !this.isInViewport()) {
		if (alignTop !== false) {
			alignTop = true;
		}
		this[0].scrollIntoView(alignTop);
	}
};

$.fn.getFontProperty = function () {
	if (this === undefined) {
		throw 'Trying to get a font of an empty jQuery element';
	}

	const fontFamily = this.css('font-family');
	const fontSize = this.css('font-size');
	const fontStyle = this.css('font-style');
	const fontVariant = this.css('font-variant');
	const fontWeight = this.css('font-weight');
	const lineHeight = this.css('line-height');

	return stringUtils.format('{0} {1} {2} {3}/{4} {5}', fontStyle, fontVariant, fontWeight, fontSize, lineHeight, fontFamily);
};

$.fn.widgetData = function (widgetName) {
	widgetName = widgetName || this.data('role');
	return this.data(`glow-${widgetName}`);
};

$.fn.clearPositionAndDimension = function () {
	return this.css({
		'width': '',
		'height': '',
		'top': '',
		'left': ''
	});
};

// Added from jQuery 3.2.1. Supported since jQuery 3.0.
// Once we upgrade jQuery to at least version 3.0 we can remove this
$.escapeSelector = (sel) => {
	return (sel + '').replace(rcssescape, fcssescape);
};

const rcssescape = /([\0-\x1f\x7f]|^-?\d)|^-$|[^\0-\x1f\x7f-\uFFFF\w-]/g; // eslint-disable-line no-control-regex
const fcssescape = (ch, asCodePoint) => {
	if (asCodePoint) {
		// U+0000 NULL becomes U+FFFD REPLACEMENT CHARACTER
		if (ch === '\0') {
			return '\uFFFD';
		}

		// Control characters and (dependent upon position) numbers get escaped as code points
		return ch.slice(0, -1) + '\\' + ch.charCodeAt(ch.length - 1).toString(16) + ' ';
	}

	// Other potentially-special ASCII characters get backslash-escaped
	return '\\' + ch;
};

const origCleanData = $.cleanData;
$.cleanData = (elems, acceptData) => {
	if (global.isDebugMode) {
		if (!acceptData) {
			for (let i = 0; i < elems.length; i++) {
				const elem = elems[i];
				if (elem !== null && ko.utils.domData.get(elem, '__ko_boundElement')) {
					throw new Error('A node was removed without proper cleaning - ' + elem.outerHTML);
				}
			}
		}
	}
	origCleanData(elems);
};

const scrollOffset = 50;
/*! SuppressStringValidation event name */
const wheelEvent = 'wheel';

$.event.special.horizontalWheelScroll = {
	delegateType: wheelEvent,
	bindType: wheelEvent,
	handle(e) {
		const $scrollViewer = $(e.delegateTarget);
		const currentScrollLeft = $scrollViewer.scrollLeft();
		const originalEvent = e.originalEvent;
		$scrollViewer.scrollLeft(currentScrollLeft + (originalEvent.deltaY > 0 ? scrollOffset : -scrollOffset));

		return e.handleObj.handler.call(this);
	}
};

/*! StartNoStringValidationRegion event names */
const clickEvent = 'click';
const scrollEvent = 'scroll';
/*! EndNoStringValidationRegion */

$.event.special.singleOrDoubleClick = {
	delegateType: clickEvent,
	bindType: clickEvent,
	handle(e) {
		if (e.ctrlKey || e.shiftKey) {
			return;
		}

		const originalEvent = $.Event(clickEvent, e);
		const self = this;
		const $element = $(e.delegateTarget);
		const targetData = $element.data();
		const clicks = (targetData.clickCount || 0) + 1;
		$element.data('clickCount', clicks);
		$element.data('currentEvent', originalEvent);

		if (clicks === 1) {
			setTimeout(() => {
				if ($.contains(document.body, $element[0])) {
					const isDoubleClick = $element.data('clickCount') === 2;
					if (isDoubleClick) {
						originalEvent.currentEvent = $element.data('currentEvent');
					}
					$element.data('clickCount', 0);
					$element.data('currentEvent', null);

					return originalEvent.handleObj.handler.apply(self, [originalEvent, isDoubleClick]);
				}
			}, 450);
		}
	}
};

$.event.special.scrollBottom = {
	delegateType: scrollEvent,
	bindType: scrollEvent,
	handle(e) {
		const $element = $(e.delegateTarget);
		if ($element.scrollTop() + (1.2 * $element.height()) >= $element[0].scrollHeight) {
			return e.handleObj.handler.apply(this, arguments);
		}
	}
};

$.fn.hasVerticalScrollbar = function () {
	return this.length ? this[0].scrollHeight > this.innerHeight() : false;
};

$.fn.hasHorizontalScrollbar = function () {
	return this.length ? this[0].scrollWidth > this.innerWidth() : false;
};

$.fn.getFocusScope = function () {
	const $modal = this.closest('.g-modal');
	return $modal.length ? $modal : $(document.body);
};

$.fn.isInsideActiveFocusScope = function () {
	const $modal = $('.g-modal').last();
	return !$modal.length || $.contains($modal[0], this[0]);
};

// from https://api.jqueryui.com/1.12/zIndex/#zIndex
$.fn.zIndex = function (zIndex) {
	if (zIndex !== undefined) {
		return this.css('zIndex', zIndex);
	}

	if (this.length) {
		let elem = $(this[0]), position, value;
		while (elem.length && elem[0] !== document) {
			// Ignore z-index if position is set to a value where z-index is ignored by the browser
			// This makes behavior of this function consistent across browsers
			// WebKit always returns auto if the element is positioned
			position = elem.css('position');
			if (position === 'absolute' || position === 'relative' || position === 'fixed') {
				// we ignore the case of nested elements with an explicit value of 0
				// <div style="z-index: -10;"><div style="z-index: 0;"></div></div>
				value = parseInt(elem.css('zIndex'), 10);
				if (!isNaN(value) && value !== 0) {
					return value;
				}
			}
			elem = elem.parent();
		}
	}

	return 0;
};

export default $;
