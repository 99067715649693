import type { Item, Strategy } from "./Strategy.ts";

export class PersistentStore {
  readonly name: string;
  private readonly initPromise: Promise<Strategy>;

  constructor(name: string, getStrategyAsync: (name: string) => Promise<Strategy>) {
    this.name = name;
    this.initPromise = getStrategyAsync(name);
  }

  async clearAsync(): Promise<void> {
    const strategy = await this.initPromise;
    return strategy.clearAsync();
  }

  async getAsync<T>(key: string): Promise<T | undefined> {
    const strategy = await this.initPromise;
    return strategy.getAsync(key);
  }

  async deleteAsync(key: string): Promise<void> {
    const strategy = await this.initPromise;
    return strategy.deleteAsync(key);
  }

  async deleteAllAsync(predicate: (item: Item) => boolean): Promise<void> {
    const strategy = await this.initPromise;
    return strategy.deleteAllAsync(predicate);
  }

  async getAllValuesAsync<T>(): Promise<T[]> {
    const strategy = await this.initPromise;
    return strategy.getAllValuesAsync();
  }

  async getAllItemsAsync<T>(): Promise<Item<T>[]> {
    const strategy = await this.initPromise;
    return strategy.getAllItemsAsync();
  }

  async replaceAllAsync<T>(items: Item<T>[]): Promise<void> {
    const strategy = await this.initPromise;
    return strategy.replaceAllAsync(items);
  }

  async setAsync<T>(key: string, value: T): Promise<void> {
    const strategy = await this.initPromise;
    return strategy.setAsync(key, value);
  }
}
