import ko from 'knockout';
import dialogService from 'DialogService';
import notificationType from 'NotificationType';
import RuleService from 'RuleService';
import RuleCommandResult from 'RuleCommandResult';
import captionService from 'CaptionService';
import errors from 'Errors';

function WorkflowCommandRuleHandler() {
}

WorkflowCommandRuleHandler.prototype.getClickHandler = function (entity, commandName) {
	let command = entity[commandName];

	if (!command) {
		const self = this;
		const isProcessing = ko.observable(false);
		command = {
			onClick(ruleContext) {
				isProcessing(true);
				return self.runCommandAsync(entity, commandName, ruleContext).finally(() => {
					isProcessing(false);
				});
			},
			isProcessing
		};
		entity[commandName] = command;
	}

	return command;
};

WorkflowCommandRuleHandler.prototype.runCommandAsync = (entity, commandName, ruleContext) => {
	if (!entity) {
		/*! SuppressStringValidation only used in debugger log */
		throw new errors.WorkflowError(errors.WorkflowErrorType.EntityNotFound, 'Entity was not found.');
	}

	return RuleService.loadAsync(entity.entityType.interfaceName).then((rules) => {
		const rule = rules.commandRule(commandName, /*okIfNotFound:*/ true);
		if (!rule) {
			/*! SuppressStringValidation only used in debugger log */
			throw new errors.WorkflowError(errors.WorkflowErrorType.CommandNotFound, 'Command ' + commandName + ' is not found.');
		}

		return processRuleAsync(entity, rule, commandName, ruleContext);
	});
};

function processRuleAsync(entity, rule, commandName, ruleContext) {
	return rule.processAsync(entity, ruleContext)
		.then((result) => {
			let message;
			if (!result.isSuccess) {
				message = captionService.getString('6f406940-a81c-4fbc-9e3d-72db96b14aa5', 'Cannot run command "{0}". This could be due to conditions, or security restrictions.', commandName);
			}
			else if (result.value instanceof RuleCommandResult && !result.value.isSuccess()) {
				message = result.value.errorMessage;
			}
			else {
				return true;
			}

			return dialogService.showAsync(
				notificationType.Error,
				message,
				captionService.getString('54d69684-d59f-4f68-9574-5c78dde5b734', 'Unable to Perform Operation'),
				[
					{
						caption: captionService.getString('4079e3c7-7d15-4679-9ee1-6efc4d65136b', 'OK'),
						isPrimary: true,
						isDismiss: true
					}
				])
				.return(false);
		});
}

export default new WorkflowCommandRuleHandler();
