import ajaxService from "AjaxService";
import { AmbiguousModelError } from "Errors";
import global from "Global";

interface EntityName {
  Type: string;
  Interface: string;
}

interface EntityMapping {
  EntityNames: EntityName[];
  DefaultFor?: string[];
  AllowAnonymous?: boolean;
}

type RouteInfo = {
  name: string;
  isDefault?: boolean;
};

export type EntityMappings = Record<string, EntityMapping>;

class EntityMappingService {
  private interfaceNameToRouteMapping: Record<string, RouteInfo[]> = {};
  private interfaceNameToTypeNameMapping: Record<string, string> = {};
  private typeNameToInterfaceNameMapping: Record<string, string> = {};
  private loaded: boolean = false;

  public async loadAsync(): Promise<void> {
    const mappings = await ajaxService.getAsync<EntityMappings>(`${global.serviceUri}api/entityMappings`);
    this.processMappings(mappings);
    this.addCustomMappings();
  }

  public getInterfaceName(typeOrInterfaceName: string, okIfNotFound?: boolean): string | null {
    this.assertLoaded();

    let result = this.typeNameToInterfaceNameMapping[typeOrInterfaceName];

    if (!result) {
      if (this.interfaceNameToTypeNameMapping[typeOrInterfaceName]) {
        result = typeOrInterfaceName;
      } else {
        if (okIfNotFound) {
          return null;
        }
        /*! SuppressStringValidation Developer exception message */
        throw new Error(`There is no interface mapping for ${typeOrInterfaceName}.`);
      }
    }

    return result;
  }

  public getTypeName(interfaceOrTypeName: string, okIfNotFound?: boolean): string | null {
    this.assertLoaded();

    let result = this.interfaceNameToTypeNameMapping[interfaceOrTypeName];

    if (!result) {
      if (this.typeNameToInterfaceNameMapping[interfaceOrTypeName]) {
        result = interfaceOrTypeName;
      } else {
        if (okIfNotFound) {
          return null;
        }
        /*! SuppressStringValidation Developer exception message */
        throw new Error(`There is no type mapping for ${interfaceOrTypeName}.`);
      }
    }

    return result;
  }

  public getFirstRouteNameObsoleteDoNotUse(typeOrInterfaceName: string, okIfNotFound?: boolean): string | null {
    const routes = this.getRoutes(typeOrInterfaceName, okIfNotFound);
    if (!routes) {
      return null;
    }

    return routes[0].name;
  }

  public getRouteName(typeOrInterfaceName: string, okIfNotFound?: boolean): string | null {
    const routes = this.getRoutes(typeOrInterfaceName, okIfNotFound);
    if (!routes) {
      return null;
    }

    if (routes.length > 1 && !routes[0].isDefault) {
      /*! SuppressStringValidation Developer exception message */
      throw new AmbiguousModelError(`Multiple registrations exist for '${typeOrInterfaceName}'.`, typeOrInterfaceName);
    }

    return routes[0].name;
  }

  public getRouteNames(typeOrInterfaceName: string, okIfNotFound?: boolean): string[] | null {
    const routes = this.getRoutes(typeOrInterfaceName, okIfNotFound);
    if (!routes) {
      return null;
    }

    return routes.map((route) => {
      return route.name;
    });
  }

  public getTypeNames = (): string[] => Object.keys(this.interfaceNameToRouteMapping);

  public hasDefaultRoute(typeOrInterfaceName: string): boolean {
    const routes = this.getRoutes(typeOrInterfaceName, true);
    return !!(routes && (routes.length === 1 || routes[0].isDefault));
  }

  public hasInterfaceName(typeOrInterfaceName: string): boolean {
    this.assertLoaded();
    return (
      typeOrInterfaceName in this.interfaceNameToTypeNameMapping ||
      typeOrInterfaceName in this.typeNameToInterfaceNameMapping
    );
  }

  private processMappings(mappings: EntityMappings): void {
    this.interfaceNameToRouteMapping = {};
    this.interfaceNameToTypeNameMapping = {};
    this.typeNameToInterfaceNameMapping = {};

    Object.entries(mappings).forEach(([routeName, mapping]) => {
      mapping.EntityNames.forEach((entityName) => {
        if (!mapping.AllowAnonymous) {
          let routeInfos = this.interfaceNameToRouteMapping[entityName.Interface];
          if (!routeInfos) {
            this.interfaceNameToRouteMapping[entityName.Interface] = (routeInfos = []);
          }

          const routeInfo: RouteInfo = { name : routeName };
          if (mapping.DefaultFor && mapping.DefaultFor.indexOf(entityName.Interface) > -1) {
            routeInfo.isDefault = true;
            routeInfos.unshift(routeInfo);
          } else {
            routeInfos.push(routeInfo);
          }
        }
        this.interfaceNameToTypeNameMapping[entityName.Interface] = entityName.Type;
        this.typeNameToInterfaceNameMapping[entityName.Type] = entityName.Interface;
      });
    });

    this.loaded = true;
  }

  private getRoutes(typeOrInterfaceName: string, okIfNotFound?: boolean): RouteInfo[] {
    const interfaceName = this.getInterfaceName(typeOrInterfaceName, okIfNotFound)!;
    return this.interfaceNameToRouteMapping[interfaceName];
  }

  private assertLoaded(): void {
    if (!this.loaded) {
      throw new Error("Entity mapping has not been loaded yet.");
    }
  }

  private addCustomMappings(): void {
    this.interfaceNameToTypeNameMapping.IGlowMacro = "GlowMacro";
    this.typeNameToInterfaceNameMapping.GlowMacro = "IGlowMacro";
    // make ruleService.getAsync('IGlowMacro') possible
    /*! SuppressStringValidation No captions here */
    this.interfaceNameToRouteMapping.IGlowMacro = [{ name: "Logon" }];
  }
}

export default new EntityMappingService();
