import Promise from 'bluebird';
import ko from 'knockout';
import _ from 'underscore';

export default class FilterGroup {
	constructor(isImplicit) {
		this.filterStrips = ko.observableArray();
		this.isImplicit = !!isImplicit;
		this.isValid = ko.pureComputed(this._getIsValid, this);
	}

	addStrip(filterStrip) {
		this.filterStrips.push(filterStrip);
	}

	removeStrip(filterStrip) {
		const index = this.filterStrips().indexOf(filterStrip);
		if (index > -1) {
			this.filterStrips.splice(index, 1);
			return true;
		} else {
			return false;
		}
	}

	clearStrips() {
		this.filterStrips().forEach((filterStrip) => {
			const filter = filterStrip.filter();
			if (filter) {
				filter.clear();
			}
		});
	}

	getFilterPredicatesAsync() {
		const promises = this.filterStrips()
			.map((strip) => {
				const filter = strip.filter();
				if (!filter || !filter.getPredicateAsync) {
					return null;
				}
				return filter.getPredicateAsync();
			});

		return Promise.filter(promises, _.identity);
	}

	_getIsValid() {
		return this.filterStrips().every((fs) => fs.isValid());
	}
}
