import capsLockService from 'CapsLockService';
import captionService from 'CaptionService';
import global from 'Global';
import * as stringUtils from 'StringUtils';
import tooltipService from 'TooltipService';
import widgetService from 'WidgetService';
import templates from 'WidgetTemplates/gwTextBox.html';
import 'bootstrap';
import $ from 'jquery';
import 'jquery.ui.uniqueId';
import ko from 'knockout';

function init(component) {
	const params = component.params;
	let bind = params.bind || '';
	let $element = component.$elementObsoleteDoNotUse;
	const isPopover = params.isPopover && !global.isPortable();
	const isTextArea = params.textWrapping === 'Wrap' || params.textWrapping === 'WrapWithOverflow';

	if (bind.indexOf('value:') === -1 && params.propertyName) {
		/*! SuppressStringValidation valid data-bind attribute value */
		bind = stringUtils.commaSeparate(bind, 'value: value');
	}

	/*! StartNoStringValidationRegion 'label' is valid siblings css selector */
	$element
		.siblings('label')
		.attr('for', params.fieldId);
	/*! EndNoStringValidationRegion */

	if (isTextArea) {
		$element.attr('data-input-id', params.fieldId);
		$element = $('<textarea></textarea>').insertAfter($element);

		$element
			.addClass('form-control')
			.on('focus', onTextAreaFocus)
			.on('blur', onTextAreaBlur)
			.parent()
			.addClass('gwTextBox-textarea g-widget-border');
	}
	else {
		if (global.isPortable()) {
			initialiseTextType($element, params);
		}
		else {
			/*! SuppressStringValidation 'type' is valid input attribute, and 'text' is valid attribute value */
			$element.prop('type', params.type || 'text');
            }

		if (params.type === 'password') {
			$element.attr('autocomplete', 'off');
		}
		else if (params.enableAutoComplete) {
			$element.attr('autocomplete', 'on');

			if (component.params.dataItemEntityTypeName && component.params.propertyName) {
				$element.attr('name', `${component.params.dataItemEntityTypeName}_${component.params.propertyName}`);
			}
		} else {
			$element.attr('autocomplete', 'off');
		}

		$element.addClass('g-widget-border');
	}

	if (isPopover) {
		const popover = $element.popover({
			trigger: 'manual',
			html: true,
			timer: null
		})
			.data('bs.popover');

		/*! SuppressStringValidation html attribute name */
		popover.options.whiteList.textarea = ['data-bind'];

		popover.tip()
			.addClass('gwTextBox-popover')
			.css('margin-left', '0');
		popover.options.content = $('<textarea></textarea>').addClass('gwTextBox-popover-textarea').attr({ 'data-bind': bind })[0].outerHTML;
		popover.getCalculatedOffset = () => {
			return $element.offset();
		};

		const setPopoverWidth = () => {
			popover.tip().css('width', $element.outerWidth());
		};

		const showPopover = () => {
			popover.show();
			setPopoverWidth();
		};

		const hidePopover = () => {
			setTimeout(() => {
				popover.hide();
			}, 0);
		};

		$element.on('focus', showPopover);
		$element.on('resize', setPopoverWidth);
		$(window).on('resize.gwTextBox', hidePopover);

		$element.on('inserted.bs.popover', () => {
			const $textarea = $('.gwTextBox-popover-textarea');
			const isReadOnly = $element.parent().hasClass('g-uneditable');
			popover.tip().toggleClass('g-uneditable', isReadOnly);
			widgetService.utils.decorateReadOnly($textarea, isReadOnly);
		});

		$element.on('shown.bs.popover', () => {
			const $textarea = $('.gwTextBox-popover-textarea');
			ko.cleanNode($textarea[0]);
			ko.applyBindings(ko.dataFor($element[0]), $textarea[0]);

			$textarea.off('keydown.gwTextBox').on('keydown.gwTextBox', (e) => {
				if (e.which === 9) {
					$element.off('focus', showPopover);
					$element.trigger('focus');
					$element.on('focus', showPopover);
				}
			});
			$textarea.off('blur.gwTextBox').on('blur.gwTextBox', hidePopover);

			setTimeout(() => {
				$textarea.trigger('focus');
				$textarea.scrollTop($textarea.height());
			}, 0);
		});
	}

	$element
		.attr({
			'id': params.fieldId,
			'data-bind': bind,
			'data-caps-lock-state': params.capsLockState
		})
		.addClass(params.css);

	widgetService.utils.decorateMaxLength($element, params.maxLength);

	if (hasCapsLockOn($element)) {
		component.viewModel._removeCapsLockHandler = initialiseCapsLockState($element);
	}

	initialiseCharacterCasing($element, params.characterCasing);
	component.viewModel._$element = $element;
}

function initialiseTextType($element, params) {
	switch (params.textType) {
		case 'Email':
			/*! SuppressStringValidation 'type' is valid input attribute, and 'email' is valid attribute value */
			$element.prop('type', 'email');
			break;
		case 'Phone':
			/*! SuppressStringValidation 'type' is valid input attribute, and 'tel' is valid attribute value */
			$element.prop('type', 'tel');
			break;
		default:
			/*! SuppressStringValidation 'type' is valid input attribute, and 'text' is valid attribute value */
			$element.prop('type', params.type || 'text');
			break;
	}
}

function initialiseCharacterCasing($element, casing) {
	let classToAdd;

	if (casing === 'Upper') {
		/*! SuppressStringValidation (No captions here) */
		classToAdd = 'character-casing-upper';
	}
	else if (casing === 'Lower') {
		/*! SuppressStringValidation (No captions here) */
		classToAdd = 'character-casing-lower';
	}

	if (classToAdd) {
		$element.addClass(classToAdd);
	}
}

function hasCapsLockOn($element) {
	const capsLockState = $element.data('capsLockState');
	return capsLockState === 'on' || (capsLockState === undefined && $element.attr('type') === 'password');
}

function initialiseCapsLockState($element) {
	const $parent = $element.parent();
	const title = $parent.attr('data-caps-lock-title') || captionService.getString('68e99af8-3aff-4cc4-b15a-94f8bd6b4334', 'Caps Lock is On');
	const message = $parent.attr('data-caps-lock-message') || captionService.getString('201a1ac7-4161-4b69-af53-7fd28f0c7f19', 'Having Caps Lock on may cause you to enter your password incorrectly.') + '\n' + captionService.getString('201a1ac7-4161-4b69-af53-7fd28f0c7c21', 'You should press Caps Lock to turn it off before entering your password.');
	$element.uniqueId();

	tooltipService.createTooltipWidget($parent[0], null, true);
	/*! SuppressStringValidation 'bottom' valid tooltip position */
	tooltipService.populateTooltip($parent[0], message, title, null, 'bottom');

	$element.on('focusout', () => {
		$parent.tooltip('hide');
	});
	$element.on('focusin', () => {
		if (capsLockService.isCapsOn()) {
			$parent.tooltip('show');
		}
	});

	return capsLockService.onCapsChanged((capsLock) => {
		if (capsLock) {
			if ($element.is(':focus')) {
				$parent.tooltip('show');
			}
		} else {
			$parent.tooltip('hide');
		}
	});
}

function isTextarea(self) {
	return self.elementObsoleteDoNotUse.next().is('textarea') || self.elementObsoleteDoNotUse.data('bs.popover');
}

/** @this { elementObsoleteDoNotUse: componentInfo.$elementObsoleteDoNotUse, container: componentInfo.$container } */
function getElementToDecorateForValidation() {
	if (isTextarea(this)) {
		return this.elementObsoleteDoNotUse.parent();
	}
}

/** @this { elementObsoleteDoNotUse: componentInfo.$elementObsoleteDoNotUse, container: componentInfo.$container } */
function markAsReadOnly(value) {
	if (isTextarea(this)) {
		this.elementObsoleteDoNotUse.parent().toggleClass('g-uneditable', value);
		widgetService.utils.decorateReadOnly(this.elementObsoleteDoNotUse.next(), value);
	}

	widgetService.utils.decorateReadOnly(this.elementObsoleteDoNotUse, value);
}

function onTextAreaFocus(e) {
	$(e.delegateTarget)
		.parent()
		.addClass('active');
}

function onTextAreaBlur(e) {
	$(e.delegateTarget)
		.parent()
		.removeClass('active');
}

function dispose(component) {
	if (component.viewModel._removeCapsLockHandler) {
		component.viewModel._removeCapsLockHandler();
	}
}

function createViewModel(params) {
	const viewModel = {
		_$element: null,
		_propertyName: params.propertyName,
		dataItem: params.dataItem,
		captionOverride: params.captionOverride,
		anchor: params.anchor
	};

	viewModel.value = ko.pureComputed({ read: readValue.bind(null, viewModel), write: writeValue.bind(null, viewModel) });
	return viewModel;
}

function readValue(viewModel) {
	const dataItem = ko.unwrap(viewModel.dataItem);
	return dataItem ? ko.unwrap(dataItem[viewModel._propertyName]) : '';
}

function writeValue(viewModel, value) {
	const dataItem = ko.unwrap(viewModel.dataItem);
	const property = dataItem && dataItem[viewModel._propertyName];
	if (!ko.isWritableObservable(property)) {
		return;
	}

	const $element = viewModel._$element;
	if ($element.attr('type') !== 'password') {
		value = value.trim();
	}

	if ($element.hasClass('character-casing-upper')) {
		value = value.toLocaleUpperCase();
	}
	else if ($element.hasClass('character-casing-lower')) {
		value = value.toLocaleLowerCase();
	}

	if ($element.is('textarea')) {
		value = value.replace(/\r\n|\r|\n/g, '\r\n');
	}

	if (value === '') {
		value = handleEmptyString(viewModel);
	}

	$element.val(value);
	property(value);
}

function handleEmptyString(viewModel) {
	const propertyName = viewModel._propertyName;
	const dataItem = viewModel.dataItem();

	if (dataItem && dataItem.entityType) {
		const property = dataItem.entityType.getDataProperty(propertyName);
		if (property) {
			return property.emptyValue;
		}
	}

	return '';
}

function getInput(component) {
	if (isTextarea(component)) {
		return component.elementObsoleteDoNotUse.next();
	} else {
		return component.elementObsoleteDoNotUse;
	}
}

/** @this { elementObsoleteDoNotUse: componentInfo.$elementObsoleteDoNotUse, container: componentInfo.$container } */
function setFocus() {
	getInput(this).trigger('focus');
}

/** @this { elementObsoleteDoNotUse: componentInfo.$elementObsoleteDoNotUse, container: componentInfo.$container } */
function getCaptionElement() {
	return this.container.find('label');
}

/*! StartNoStringValidationRegion No captions here */
const componentConfig =
	{
		template: templates,
		widgetSelector: 'input',
		viewModel: { createViewModel },
		init,
		dispose,
		methods: {
			getCaptionElement,
			getElementToDecorateForValidation,
			markAsReadOnly,
			setFocus,
			setValue(value) {
				const $input = getInput(this).filter(':not([readonly])');
				$input.val(value);
				$input.trigger('change');
			},
			getValue() {
				return getInput(this).val();
			}
		}
	};
/*! EndNoStringValidationRegion */

export default componentConfig;
