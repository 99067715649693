import type { Disposable } from "Disposable";

export class Subscribable<T> {
  private readonly subscriptions: Subscription<T>[] = [];

  subscribe(callback: (value: T) => void): Disposable {
    const subscription = { callback, dispose: () => this.unsubscribe(subscription) };
    this.subscriptions.push(subscription);
    return subscription;
  }

  private unsubscribe(subscription: Subscription<T>): void {
    const index = this.subscriptions.indexOf(subscription);
    if (index >= 0) {
      this.subscriptions.splice(index, 1);
    }
  }

  notifySubscribers(valueToNotify: T): void {
    if (this.subscriptions.length) {
      this.subscriptions.slice().forEach((subscription) => subscription.callback(valueToNotify));
    }
  }
}

interface Subscription<T> extends Disposable {
  callback: (value: T) => void;
}
