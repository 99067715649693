import DependencyV2Visitor, { DependencyType } from 'DependencyV2Visitor';
import errors from 'Errors';
import { getAvailableResult } from './DependencyV2Visitor/Utils';
import pathEvaluator, { getPropertyValueForItem } from './DependencyV2Visitor/PathEvaluator';

export default class SecurityExpressionVisitor extends DependencyV2Visitor {
	static _getVisitor() {
		return new SecurityExpressionVisitor();
	}

	static _validateOptions(strategy, options, parsed) {
		switch (parsed.dependencyType) {
			case DependencyType.MacroPath:
				throw new errors.DependencyOptionsError('Does not support a macro path.');
		}

		if (options.valueToSet !== undefined) {
			throw new errors.DependencyOptionsError('Does not support setting values.');
		} else if (options.skipLastSegmentEvaluation) {
			throw new errors.DependencyOptionsError('Does not support skipLastSegmentEvaluation.');
		}
	}

	_visitPropertyValueFunction(propertyName, separator) {
		const state = this._state;
		state.operands.push(
			getValueForItem.bind(undefined, propertyName, separator, state.isTopLevel)
		);
	}
}

function getValueForItem(propertyName, separator, isTopLevel, data, context) {
	if (data == null) {
		return getAvailableResult(null);
	}

	if (Array.isArray(data)) {
		throw new errors.DependencyError('Data is a collection, which is not supported.');
	}

	const { entityAspect } = data;
	if (!entityAspect) {
		throw new errors.DependencyError(
			`Property '${propertyName}' cannot be used because the owner is not an entity.`
		);
	}

	const property = data.entityType.getProperty(propertyName);
	if (!property) {
		throw new errors.DependencyError(
			`Property '${data.entityType.interfaceName}.${propertyName}' does not exist.`
		);
	}

	if (property.isNavigationProperty) {
		if (!property.isScalar) {
			throw new errors.DependencyError(
				`Navigation property '${propertyName}' is a collection, which is not supported.`
			);
		}
		if (
			!entityAspect.entityState.isAdded() &&
			entityAspect.hasPropertyChanged(property.foreignKeyNames[0])
		) {
			throw new errors.DependencyError(
				`Foreign key for '${data.entityType.interfaceName}.${propertyName}' cannot be changed.`
			);
		}
	}

	if (property.isDataProperty) {
		if (entityAspect.entityState.isAdded()) {
			return getAvailableResult(data.entityType.getProperty(propertyName).defaultValue);
		} else {
			return pathEvaluator.originalValue(propertyName, data, context);
		}
	} else {
		return getPropertyValueForItem(propertyName, separator, isTopLevel, data, context);
	}
}
