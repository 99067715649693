import buttonHelper from 'ButtonHelper';
import captionService from 'CaptionService';
import constants from 'Constants';
import documentWrapper from 'DocumentWrapper';
import { createSubTrackerAsync } from 'GlobalBusyStateTracker';
import widgetService from 'WidgetService';
import 'bootstrap';
import 'jQueryExtensions';
import $ from 'jquery';
import 'jquery.ui.core';
import 'jquery.ui.draggable';
import 'jquery.ui.resizable';

function DialogServiceProvider() {
	this.selectors = Selectors;
	this.constants = { iconsSize: 14 };
}

/*! StartNoStringValidationRegion Suppression added on initial refactor */
export const Selectors = {
	template: '#g-modal',
	visibleDialog: '.modal.in',
	modalDialog: '.modal',
	innerWrap: '.g-modal-inner-wrap',
	header: '.modal-header',
	title: '.modal-header .g-title-name',
	body: '.modal-body',
	footer: '.modal-footer',
	content: '.modal-content',
	alertBar: '.g-modal-alert-bar'
};
/*! EndNoStringValidationRegion */

DialogServiceProvider.prototype.show = ($dialog) => {
	$dialog.modal('show');
};

DialogServiceProvider.prototype.hide = ($dialog) => {
	if (isShown($dialog)) {
		$dialog.modal('hide');
	}
};

DialogServiceProvider.prototype.onBeforeShow = ($dialog, fn) => {
	return $dialog.on('show.bs.modal', fn);
};

DialogServiceProvider.prototype.offBeforeShow = ($dialog) => {
	return $dialog.off('show.bs.modal');
};

DialogServiceProvider.prototype.onAfterShow = ($dialog, fn) => {
	return $dialog.on('shown.bs.modal', fn);
};

DialogServiceProvider.prototype.offAfterShow = ($dialog) => {
	return $dialog.off('shown.bs.modal');
};

DialogServiceProvider.prototype.onAfterHide = ($dialog, fn) => {
	return $dialog.on('hidden.bs.modal', fn);
};

DialogServiceProvider.prototype.offAfterHide = ($dialog) => {
	return $dialog.off('hidden.bs.modal');
};

DialogServiceProvider.prototype.onBeforeHide = ($dialog, fn) => {
	return $dialog.on('hide.bs.modal', fn);
};

DialogServiceProvider.prototype.offBeforeHide = ($dialog) => {
	return $dialog.off('hide.bs.modal');
};

DialogServiceProvider.prototype.create = function (options) {
	const template = $(Selectors.template).html();
	const $modal = $(template)
		.appendTo(getParentElement(options))
		.modal({
			show: false,
			backdrop: 'static',
			keyboard: !(options && options.closeOnDismissOnly)
		});

	this.onAfterShow($modal, async (event) => {
		try {
			const $dialog = $(event?.currentTarget);
			decorateModal($dialog, options);

			const $visibleModals = $(Selectors.visibleDialog);
			if ($visibleModals.length > 1) {
				const $previousModal = $visibleModals.eq($visibleModals.length - 2);
				if ($previousModal.length) {
					const previousIndex = $previousModal.zIndex();
					const previousModal = $previousModal.data('bs.modal');
					if (previousModal && previousModal.$backdrop) {
						previousModal.$backdrop.addClass('hidden');
					}

					const currentModal = $dialog.css('zIndex', previousIndex + 1).data('bs.modal');
					if (currentModal && currentModal.$backdrop) {
						currentModal.$backdrop.css('zIndex', previousIndex);
					}
				}
			}
		} finally {
			this.disposeBusyStateSubTracker = await createSubTrackerAsync();
		}
	});

	this.onAfterHide($modal, async () => {
		try {
			const lastModal = $(Selectors.visibleDialog).last();
			widgetService.utils.setInitialFocus(lastModal);
			const lastModalData = lastModal.data('bs.modal');
			if (lastModalData && lastModalData.$backdrop) {
				lastModalData.$backdrop.removeClass('hidden');
			}
		} finally {
			if (this.disposeBusyStateSubTracker) {
				await this.disposeBusyStateSubTracker();
				this.disposeBusyStateSubTracker = undefined;
			}
		}
	});

	return $modal;
};

function isShown($dialog) {
	const modal = $dialog.data('bs.modal');
	return modal && modal.isShown;
}

function decorateModal($dialog, options) {
	const $containment = $dialog.find('.modal-dialog');
	const $content = $dialog.find(Selectors.content);
	/*! SuppressStringValidation resizable drag direction*/
	const resizeDirection = 'se';

	if (options && options.autoresize) {
		if (options.height) {
			$content.css('height', options.height);
		}
		if (options.width) {
			$content.css('width', options.width);
		}

		$content.resizable({
			minHeight: options.minHeight || 200,
			minWidth: options.minWidth || 135,
			containment: $containment,
			handles: resizeDirection,
		})
			.draggable({
				cursor: 'move',
				handle: Selectors.header,
				containment: $containment,
				cancel: '.ui-draggable-handle .dropdown'
			});

		$(Selectors.header).on('mousedown', () => {
			$(documentWrapper.getActiveElement()).blur();
		});
	}

	const $body = $dialog.closest('body.modal-open');
	const $headerButtons = $body.find('.g-header-menu .nav');
	let $popupHeaderButtons = $dialog.find('.ui-draggable-handle .nav');
	if (!$popupHeaderButtons.length) {
		$popupHeaderButtons = $dialog.find('.ui-draggable-handle .g-modal-header-buttonpane');
	}
	if (!$popupHeaderButtons.length) {
		$popupHeaderButtons = $dialog.find('.g-modal-header-buttonpane');
	}

	if ($headerButtons.hasClass('buttons-only')) {
		$popupHeaderButtons.addClass('buttons-only');
	}
	else if ($headerButtons.hasClass('text-only')) {
		$popupHeaderButtons.addClass('text-only');
	}
}

DialogServiceProvider.prototype.isShown = isShown;
DialogServiceProvider.prototype.decorateModal = decorateModal;

DialogServiceProvider.prototype.enhanceButton = () => {
};

function getParentElement(options) {
	if (options && options.dialogContainer) {
		return options.dialogContainer;
	}
	const $modalsContainer = $(constants.CssClasses.ModalsContainer.Selector);
	/*! SuppressStringValidation (html element tags) */
	return $modalsContainer.data('is-initialized') ? $modalsContainer[0] : 'body';
}

function initAutoResizeDialog($modalWrapper, dialog, $container, options) {
	//modal is not fixed. bind container for resizing
	$modalWrapper.addClass('g-modal-autoresize');
	$modalWrapper.css('margin-left', '');
	$modalWrapper.css('margin-top', '');

	if (options.minHeight || options.minWidth) {
		//set min size as well
		if (options.minHeight) {
			dialog.$dialog.css('minHeight', options.minHeight);
		}
		if (options.minWidth) {
			dialog.$dialog.css('minWidth', options.minWidth);
		}
	}
}

function initSizeToContentDialog($modalWrapper, dialog, options) {
	$modalWrapper.addClass('g-modal-sizetocontent');

	if (options.minHeight) {
		dialog.$dialog.css('minHeight', options.minHeight);
	}

	if (dialog.domElements.$body.height() === 0) {
		dialog.domElements.$body.height($modalWrapper.height());
	}
}

DialogServiceProvider.prototype.setDialogPosition = (dialog, options, $window) => {
	if (options) {
		const $modalWrapper = dialog.domElements.$innerWrap;
		const $container = dialog.domElements.$body.find('div[data-role="gwShellContainer"].g-anchor-container:eq(0)');
		if (options.autoresize) {
			initAutoResizeDialog($modalWrapper, dialog, $container, options);
		}
		else {
			initSizeToContentDialog($modalWrapper, dialog, options, $window);
		}
		$modalWrapper.addClass('in');
	}
};

DialogServiceProvider.prototype.createWidgetsAsync = ($dialog) => {
	return widgetService.createGlowWidgetsAsync($dialog);
};

DialogServiceProvider.prototype.getMaximizeHeaderButton = () => {
	return '<li>' +
		`<a class="g-header-button ${constants.CssClasses.RestoreMaximizeButton.Class}">` +
		`<i class="g-restore g-default-menu-button icon-window-restore" data-bind="gwTooltip: { tooltip: '${captionService.getString('b2888b73-28a3-440c-9259-37287436aff7', 'Restore')}', placement: 'bottom'}"></i>` +
		`<i class="g-maximize g-default-menu-button icon-window-maximize" data-bind="gwTooltip: { tooltip: '${captionService.getString('e5cdde1c-eed7-4ac2-a07c-96d623ac6d99', 'Maximize')}', placement: 'bottom'}"></i>` +
		'</a>' +
		'</li>';
};

DialogServiceProvider.prototype.setDialogFooter = function (dialog, options) {
	const buttonOptions = options && options.length > 0 ? options : [{ caption: captionService.getString('8c4c737e-d9ba-4e2f-b071-1485e1023a00', 'OK'), isDismiss: true }];
	const $flexContainerLeft = $('<div class="g-footer-container left">').appendTo(dialog.domElements.$footerButtonsWrap);
	const $flexContainerRight = $('<div class="g-footer-container right">').appendTo(dialog.domElements.$footerButtonsWrap);
	let $btn;
	for (let i = 0; i < buttonOptions.length; i++) {
		const buttonOption = buttonOptions[i];
		if (buttonOption.isNoButton && buttonOptions.length > 1) {
			$btn = $('<button>').appendTo($flexContainerLeft);
		} else {
			$btn = $('<button>').appendTo($flexContainerRight);
		}

		buttonHelper.decorate($btn, buttonOption);
		this.enhanceButton($btn, dialog.$dialog);
	}
};

DialogServiceProvider.prototype.reduceModalBody = (dialogDomElements, alertBarHeight) => {
	dialogDomElements.$body
		.data('original-padding-top', dialogDomElements.$body.data('original-padding-top') || dialogDomElements.$body.css('padding-top'))
		.animate({ 'padding-top': alertBarHeight + dialogDomElements.$header.outerHeight() }, 'slow');
};

DialogServiceProvider.prototype.restoreModalBody = ($body) => {
	$body.animate({ 'padding-top': $body.data('original-padding-top') }, 'slow');
};

DialogServiceProvider.prototype.getFocusTrapSettings = () => { return {}; };

export default new DialogServiceProvider();
