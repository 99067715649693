import _ from 'underscore';
import ko from 'knockout';
import { ExpressionVisitor } from 'wtg-expressions';
import constants from 'Constants';
import errors from 'Errors';

class ExpandPathScoreVisitor extends ExpressionVisitor {
	constructor(entity, options) {
		super(...arguments);

		this._currentPropertyValue = entity;
		this._currentPropertyValueType = entity.entityType;
		this._hadVisitedCollection = false;
		this._wasParentLoaded = true;
		this._currentScore = 0;
		this._visitedPath = '';
		this._options = options;
	}
}

ExpandPathScoreVisitor.visit = (entity, expandPath, options) => {
	if (!expandPath) {
		return 0;
	}

	const tree = ExpandPathScoreVisitor.getDependencyTree(expandPath, ExpandPathScoreVisitor.TreeName.Dependency, onDependencyError);
	const visitor = new ExpandPathScoreVisitor(entity, options);
	return visitor.visit(tree);
};

ExpandPathScoreVisitor.prototype.visitDependency = function (ctx) {
	this._fullOriginalPath = ctx.children[0].getText().replace(/\//, '.');
	const eofCtx = ctx.children[0].EOF();
	if (eofCtx) {
		this._fullOriginalPath = this._fullOriginalPath.replace(eofCtx.getText(), '');
	}
	this.visitChildren(ctx);
	return this._currentScore;
};

ExpandPathScoreVisitor.prototype.visitPath = function (ctx) {
	_.some(ctx.children, (childrenContext) => {
		const shouldStop = this.visit(childrenContext);
		return shouldStop === true || _.some(_.flatten(shouldStop), _.identity);
	});
};

ExpandPathScoreVisitor.prototype.visitPath_part = function (ctx) {
	const propertyName = ctx.children[0].getText();
	appendToVisitedPath(this, propertyName);
	this._currentPropertyValue = getNewPropertyValue(this._currentPropertyValue, propertyName);
	const isPropertyLoaded = isRelatedPropertyLoaded(this._currentPropertyValue, this._wasParentLoaded);
	if (isPropertyLoaded && this._currentPropertyValue && _.isArray(this._currentPropertyValue())) {
		const remainingPath = getRemainingPath(this);
		this._currentScore += this._currentPropertyValue().reduce((totalScore, entity) => {
			return totalScore + ExpandPathScoreVisitor.visit(entity, remainingPath);
		}, 0);
		return true;
	}

	const propertyInfo = this._currentPropertyValueType.getProperty(propertyName);

	if (!propertyInfo) {
		if (this._options && this._options.throwOnError) {
			throw new errors.DependencyError('Expected property named ' + propertyName + ', but did not exist.');
		}

		this._currentScore = 0;
		return true;
	}

	this._currentPropertyValueType = propertyInfo.entityType;
	this._wasParentLoaded = isPropertyLoaded;

	if (this._hadVisitedCollection && !isPropertyLoaded) {
		this._currentScore += 2;
	}

	if (propertyInfo.isScalar) {
		if (!isPropertyLoaded) {
			this._currentScore += 1;
		}
	} else {
		this._hadVisitedCollection = true;
		if (!isPropertyLoaded) {
			this._currentScore += 2;
		}
	}
};

function appendToVisitedPath(visitor, propertyName) {
	visitor._visitedPath += propertyName + '.';
}

function getRemainingPath(visitor) {
	return visitor._fullOriginalPath.slice(visitor._visitedPath.length);
}

function isRelatedPropertyLoaded(property, parentIsLoaded) {
	return (property && property.getState() !== constants.States.NotLoaded) || (!property && parentIsLoaded);
}

function getNewPropertyValue(entity, propertyName) {
	const entityValue = ko.unwrap(entity);
	return entityValue ? entityValue[propertyName] : null;
}

function onDependencyError(msg) {
	throw new errors.DependencyError(msg);
}

export default ExpandPathScoreVisitor;
