import type { ResourceInfo } from "CaptionService";
import { type FormFlowSession } from "FormFlowSession";
import type { FormFlowActivity, Transition } from "FormFlowTypes";
import { joinUri } from "UriUtils";

/*! StartNoStringValidationRegion No captions*/
export enum FormAction {
  EDocs = "EDocs",
  Messages = "Messages",
  Notes = "Notes",
  Logs = "Logs",
  Workflows = "Workflows",
  Documents = "Documents",
}
/*! EndNoStringValidationRegion */

export interface ShowFormActivity extends FormFlowActivity {
  /*! SuppressStringValidation Activity Kind */
  Kind: "ShowForm";
  FormId: string;
  SetAsCurrentURL?: boolean;
  CaptionOverride?: ResourceInfo;
  CaptionOverrideArgs?: string[];
  EntityVariableName?: string;
  HideFooter?: boolean;
  HideSaveButtons?: boolean;
  HideCloseButtons?: boolean;
  Transitions: Transition[];
  DisabledFormActions?: FormAction[];
}

export function actionEnabled(activity: ShowFormActivity, action: FormAction): boolean {
  return !activity.DisabledFormActions?.includes(action);
}

export default async function invokeShowFormAsync(session: FormFlowSession, activity: ShowFormActivity): Promise<void> {
  if (activity.SetAsCurrentURL) {
    /*! SuppressStringValidation Controller route */
    const uri = joinUri("#", "page", activity.FormId);
    history.pushState(null, "", uri);
  }

  await session.uiContext.showFormAsync(session, activity);
}
