import constants from 'Constants';
import { delayAsync } from 'DelayedPromise';
import encodingUtils from 'EncodingUtils';
import errors from 'Errors';
import notificationType from 'NotificationType';
import settingService from 'SettingService';
import $ from 'jquery';
import toastr from 'toastr';
import global from 'Global';
import * as stringUtils from 'StringUtils';

function ToastService() {
	/*! StartNoStringValidationRegion Suppression added on initial refactor */
	this.position = {
		topFullWidth: 'toast-top-full-width',
		bottomFullWidth: 'toast-bottom-full-width',
		topLeft: 'toast-top-left',
		topRight: 'toast-top-right',
		bottomRight: 'toast-bottom-right',
		bottomLeft: 'toast-bottom-left',
		center: 'toast-center',
		centerGlowTwo: 'toast-center-glow-2',
	};
	/*! EndNoStringValidationRegion */

	toastr.options.hideDuration = 500;
}

ToastService.prototype.showToastAlertAsync = async function (
	toastMessage,
	toastNotificationType,
	isSticky,
	options
) {
	toastMessage = stringUtils.htmlEscape(toastMessage);
	const mergedOptions = await getMergedOptionsAsync(
		options,
		isSticky,
		isSticky ? this.position.topFullWidth : this.position.center
	);
	checkGlowConfig(mergedOptions);
	await delayAsync(mergedOptions.showDelay, mergedOptions);
	return showToastAlertCore(toastMessage, toastNotificationType, mergedOptions);
};

ToastService.prototype.clearToastAlerts = ($toast) => {
	toastr.clear($toast);
};

async function getMergedOptionsAsync(options, isSticky, defaultPosition) {
	// The timeout may be overridden by specifying options.timeOut or using isSticky (which sets it to 0).
	// Either of these will skip the alert display time setting lookup.
	// In the case that options.timeOut is specified, the 0 is just a placeholder used when the setting lookup is skipped;
	// it will be set to the actual specified value by the call to $.extend.
	const timeOutOverrideIsDefined = options && options.timeOut !== undefined;

	let defaultTimeOut;
	if (isSticky || timeOutOverrideIsDefined) {
		defaultTimeOut = 0;
	} else {
		let value;
		try {
			value = await settingService.getSettingAsync(
				constants.DisplayModeSetting.AlertDisplayTime
			);
			value = encodingUtils.decodeData(value, constants.AlertDisplayTime.Short);
		} catch (error) {
			// This error can be thrown when getting setting before login.
			if (errors.findError(error, errors.RenewSessionError)) {
				value = constants.AlertDisplayTime.Short;
			} else {
				throw error;
			}
		}

		switch (value) {
			case constants.AlertDisplayTime.Long:
				defaultTimeOut = 4500;
				break;
			case constants.AlertDisplayTime.UntilDismissed:
				defaultTimeOut = 0;
				break;
			default:
				defaultTimeOut = 2500;
				break;
		}
	}

	// These settings should be in sync with ToastAlert.xaml
	return $.extend(
		{},
		{
			timeOut: defaultTimeOut,
			extendedTimeOut: isSticky ? 0 : 1000,
			showDelay: 0,
			showDuration: 1000,
			hideDuration: 500,
			showIcon: true,
			caption: null,
			closeButton: true,
			positionClass: defaultPosition,
			hideEasing: 'linear',
			placementTarget: null,
		},
		options
	);
}

function checkGlowConfig(options) {
	if (global.materialDesign && options.positionClass === 'toast-center') {
		/*! SuppressStringValidation css class */
		options.positionClass = 'toast-center-glow-2';
	}
}

function showToastAlertCore(toastMessage, toastNotificationType, options) {
	let $toast;

	switch (toastNotificationType) {
		case notificationType.Success:
			$toast = toastr.success(toastMessage, options.caption, options);
			break;
		case notificationType.Error:
			$toast = toastr.error(toastMessage, options.caption, options);
			break;
		case notificationType.Warning:
			$toast = toastr.warning(toastMessage, options.caption, options);
			break;
		case notificationType.MessageError:
			$toast = toastr.messageError(toastMessage, options.caption, options);
			break;
		default:
			$toast = toastr.info(toastMessage, options.caption, options);
	}

	if (options.showIcon) {
		addIcon($toast, toastNotificationType);
	} else {
		/*! SuppressStringValidation String validation suppressed in initial refactor */
		options.iconClass = 'none';
	}

	const $container = $toast.parent();
	clearPosition($container);
	if (options.placementTarget) {
		setPosition($container, $(options.placementTarget));
	} else if (options.positionClass === 'toast-center' || options.positionClass === 'toast-center-glow-2') {
		setPosition($container, $container.parent());
	}

	return $toast;
}

function addIcon($toast, toastNotificationType) {
	const iconName = notificationType.getIconName(toastNotificationType);
	const classSuffix = notificationType.toString(toastNotificationType);
	const $icon = $('<i>')
		.addClass('toast-icon')
		.addClass(iconName)
		.addClass(`g-icon-${classSuffix}`);
	$toast.prepend($icon);
}

function clearPosition($container) {
	$container.css({ left: '', top: '' });
}

function setPosition($container, $target) {
	if ($target) {
		const targetOffset = $target.offset();

		$container.css({ left: '0', top: '0' });
		const containerOffset = $container.offset();

		$container.css({
			left:
				targetOffset.left +
				$target.width() / 2 -
				containerOffset.left -
				$container.width() / 2,
			top:
				targetOffset.top +
				$target.height() / 2 -
				containerOffset.top -
				$container.height() / 2,
		});
	}
}

export default new ToastService();
