import constants from 'Constants';
import errors from 'Errors';
import { getAvailableResult } from './Utils';

function all(collection, predicate, data, context) {
	return context.strategy.waitAvailableOne(collection, data, context, (c) =>
		allCore(c, predicate, context)
	);
}

function allCore(collection, predicate, context) {
	if (collection == null) {
		return getAvailableResult(null);
	} else if (!Array.isArray(collection)) {
		throw new errors.DependencyError('All() can only be used on a collection.');
	} else if (!collection.length) {
		return getAvailableResult(true);
	} else {
		return context.strategy.map(
			collection,
			(item) => predicate(item, context),
			(predicateResults) => {
				let nonAvailableResult;
				for (let i = 0; i < predicateResults.length; i++) {
					const predicateResult = predicateResults[i];
					if (predicateResult.state === constants.States.Available) {
						if (!predicateResult.value) {
							return getAvailableResult(false);
						}
					} else if (
						!nonAvailableResult ||
						predicateResult.state === constants.States.NotAvailable
					) {
						nonAvailableResult = predicateResult;
					}
				}
				return nonAvailableResult || getAvailableResult(true);
			}
		);
	}
}

function any(collection, predicate, data, context) {
	return context.strategy.waitAvailableOne(collection, data, context, (c) =>
		anyCore(c, predicate, context)
	);
}

function anyCore(collection, predicate, context) {
	if (collection == null) {
		return getAvailableResult(null);
	} else if (!Array.isArray(collection)) {
		throw new errors.DependencyError('Any() can only be used on a collection.');
	} else if (!collection.length) {
		return getAvailableResult(false);
	} else if (!predicate) {
		return getAvailableResult(true);
	} else {
		return context.strategy.map(
			collection,
			(item) => predicate(item, context),
			(predicateResults) => {
				let nonAvailableResult;
				for (let i = 0; i < predicateResults.length; i++) {
					const predicateResult = predicateResults[i];
					if (predicateResult.state === constants.States.Available) {
						if (predicateResult.value) {
							return getAvailableResult(true);
						}
					} else if (
						!nonAvailableResult ||
						predicateResult.state === constants.States.NotLoaded
					) {
						nonAvailableResult = predicateResult;
					}
				}
				return nonAvailableResult || getAvailableResult(false);
			}
		);
	}
}

function count(collection, data, context) {
	return context.strategy.waitAvailableOne(collection, data, context, countCore);
}

function countCore(collection) {
	if (collection == null) {
		return getAvailableResult(null);
	} else if (!Array.isArray(collection)) {
		throw new errors.DependencyError('Count() can only be used on a collection.');
	} else {
		return getAvailableResult(collection.length);
	}
}

function first(collection, data, context) {
	return context.strategy.waitAvailableOne(collection, data, context, firstCore);
}

function firstCore(collection) {
	if (collection == null) {
		return getAvailableResult(null);
	} else if (!Array.isArray(collection)) {
		throw new errors.DependencyError('First() can only be used on a collection.');
	} else {
		return getAvailableResult(collection.length ? collection[0] : null);
	}
}

function orderBy(collection, clauses, data, context) {
	return context.strategy.waitAvailableOne(collection, data, context, (c) =>
		orderByCore(c, clauses, context)
	);
}

function orderByCore(collection, clauses, context) {
	if (collection == null) {
		return getAvailableResult(null);
	} else if (!Array.isArray(collection)) {
		throw new errors.DependencyError('OrderBy() can only be used on a collection.');
	} else if (!collection.length) {
		return getAvailableResult([]);
	} else {
		return context.strategy.map(
			collection,
			(item) =>
				context.strategy.map(
					clauses,
					(c) => c.reducer(item, context),
					(values) => ({ item, values })
				),
			(results) => {
				for (let i = 0; i < results.length; i++) {
					const { values } = results[i];
					for (let j = 0; j < values.length; j++) {
						if (values[j].state !== constants.States.Available) {
							return getAvailableResult(collection);
						}
					}
				}

				return getSortedData(clauses, results);
			}
		);
	}
}

function getSortedData(clauses, results) {
	const sortedData = results
		.sort((left, right) => {
			for (let i = 0; i < clauses.length; i++) {
				const { isDescending } = clauses[i];
				const leftValue = left.values[i].value;
				const rightValue = right.values[i].value;
				if (leftValue < rightValue) {
					return isDescending ? 1 : -1;
				} else if (leftValue > rightValue) {
					return isDescending ? -1 : 1;
				}
			}

			return 0;
		})
		.map(({ item }) => item);

	return getAvailableResult(sortedData);
}

function skip(collection, count, data, context) {
	return context.strategy.waitAvailable([collection, count], data, context, skipCore);
}

function skipCore([collection, count]) {
	if (collection == null) {
		return getAvailableResult(null);
	} else if (!Array.isArray(collection)) {
		throw new errors.DependencyError('Skip() can only be used on a collection.');
	}
	return getAvailableResult(collection.slice(count));
}

function sum(collection, expr, data, context) {
	return context.strategy.waitAvailableOne(collection, data, context, (c) =>
		sumCore(c, expr, context)
	);
}

function sumCore(collection, expr, context) {
	if (collection == null) {
		return getAvailableResult(0);
	} else if (!Array.isArray(collection)) {
		throw new errors.DependencyError(
			'Sum() can only be used on a collection of numbers or an entity collection specifying a numeric property name.'
		);
	} else if (collection.length === 0) {
		return getAvailableResult(0);
	} else {
		return context.strategy.map(
			collection,
			(item) => (expr == null ? item : expr(item, context)),
			(predicateResults) => {
				let result = null;
				for (let i = 0; i < predicateResults.length; i++) {
					const value = expr == null ? predicateResults[i] : predicateResults[i].value;

					if (value != null) {
						if (typeof value !== 'number') {
							throw new errors.DependencyError('Can only use Sum on numeric types.');
						} else {
							result = result == null ? value : result + value;
						}
					}
				}
				return getAvailableResult(result ?? 0);
			}
		);
	}
}

function take(collection, count, data, context) {
	return context.strategy.waitAvailable([collection, count], data, context, takeCore);
}

function takeCore([collection, count]) {
	if (collection == null) {
		return getAvailableResult(null);
	} else if (!Array.isArray(collection)) {
		throw new errors.DependencyError('Take() can only be used on a collection.');
	}
	return getAvailableResult(collection.slice(0, count));
}

function where(collection, predicate, data, context) {
	return context.strategy.waitAvailableOne(collection, data, context, (c) =>
		whereCore(c, predicate, context)
	);
}

function whereCore(collection, predicate, context) {
	if (collection == null) {
		return getAvailableResult(null);
	} else if (!Array.isArray(collection)) {
		throw new errors.DependencyError('Where() can only be used on a collection.');
	} else if (collection.length === 0) {
		return getAvailableResult([]);
	} else {
		return context.strategy.map(
			collection,
			(item) => predicate(item, context),
			(predicateResults) => {
				const result = [];
				for (let i = 0; i < predicateResults.length; i++) {
					const predicateResult = predicateResults[i];
					if (predicateResult.state !== constants.States.Available) {
						return predicateResult;
					} else {
						const { value } = predicateResult;
						if (value != null && typeof value !== 'boolean') {
							throw new errors.DependencyError(
								'Predicate for Where() must return a boolean value.'
							);
						} else if (value) {
							result.push(collection[i]);
						}
					}
				}
				return getAvailableResult(result);
			}
		);
	}
}

export default { all, any, count, first, orderBy, skip, sum, take, where };
