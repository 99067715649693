import _ from 'underscore';

export default {
	init(errorHandler) {
		this.reportBootstrapError = errorHandler.reportBootstrapError;
		this.reportError = errorHandler.reportError;
	},
	reportBootstrapError: _.noop,
	reportError: _.noop,
};
