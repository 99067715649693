import storage from 'Storage';

const storageKey = 'EntityChanges-c53f97f9-b46e-4f0a-ae33-b79287b3333c';

class EntityChangesRegistrar {
	getChanges() {
		const changes = storage.get(storageKey);
		return parseDatesInObject(changes);
	}

	addChanges(entityNames, time) {
		const changes = this.getChanges();
		entityNames.forEach((entityName) => {
			const currentValue = changes[entityName];
			let newValue = time;
			if (currentValue) {
				newValue = time > currentValue ? time : currentValue;
			}
			changes[entityName] = newValue;
		});
		storage.set(storageKey, changes);
	}
}

function parseDatesInObject(originalObject) {
	originalObject = originalObject || {};
	const result = {};
	Object.keys(originalObject).forEach((key) => {
		result[key] = new Date(originalObject[key]);
	});
	return result;
}

export default new EntityChangesRegistrar();
