import ajaxService from 'AjaxService';
import global from 'Global';
import Storage from 'Storage';
import userSession from 'UserSession';
import errors from 'Errors';
import constants from 'Constants';
import SecurityExpressionVisitor from 'SecurityExpressionVisitor';

function get(entityType, throwIfNotFound = true) {
	let result;
	const routeName = entityType.metadataStore.getRouteName();
	const sessionData = userSession.sessionData();
	if (!sessionData.entitySetRights) {
		if (throwIfNotFound) {
			const error = new Error('Session data is missing entity set rights.');
			const errorData = [
				{
					name: 'StorageData',
					value: JSON.stringify(Storage.get(userSession.getEntitySetRightsStorageKey())),
				},
				{
					name: 'SessionData',
					value: JSON.stringify({
						loggedOnUser: sessionData.loggedOnUser,
						entitySetRights: sessionData.entitySetRights,
					}),
				},
			];
			error.getData = () => errorData;
			throw error;
		}
		return null;
	}
	const entitySets = sessionData.entitySetRights[routeName];
	if (entitySets) {
		result = entitySets[entityType.ensureResourceName()];
		while (!result && entityType.baseEntityType) {
			entityType = entityType.baseEntityType;
			result = entitySets[entityType.ensureResourceName()];
		}
	}

	if (!result) {
		if (throwIfNotFound) {
			throw new errors.SessionOutdatedError();
		}
		return null;
	}
	return new EntitySetRights(result);
}

function loadAsync() {
	const uri = global.serviceUri + 'api/entitySets/rights';
	return ajaxService.getAsync(uri);
}

class EntitySetRights {
	constructor(rights) {
		this._rights = rights;
	}

	canRead(entity) {
		return this._hasAccess(entity, this._rights.CanRead);
	}

	canWrite(entity) {
		return this._hasAccess(entity, this._rights.CanWrite);
	}

	canDelete(entity) {
		return this._hasAccess(entity, this._rights.CanDelete);
	}

	canReadProperty(entity, propertyName) {
		const right = this._getPropertyRightCondition(propertyName, 'CanRead');
		return this._hasAccess(entity, right);
	}

	canReadPropertyAsync(entity, propertyName) {
		const right = this._getPropertyRightCondition(propertyName, 'CanRead');
		return this._hasAccessAsync(entity, right);
	}

	canWriteProperty(entity, propertyName) {
		const right = this._getPropertyRightCondition(propertyName, 'CanWrite');
		return this._hasAccess(entity, right);
	}

	get canReadCondition() {
		return this._rights.CanRead;
	}

	get canWriteCondition() {
		return this._rights.CanWrite;
	}

	get canDeleteCondition() {
		return this._rights.CanDelete;
	}

	canReadPropertyCondition(propertyName) {
		return this._getPropertyRightCondition(propertyName, 'CanRead');
	}

	canWritePropertyCondition(propertyName) {
		return this._getPropertyRightCondition(propertyName, 'CanWrite');
	}

	_getPropertyRightCondition(propertyName, rightName) {
		const propertyRights = this._rights.Properties && this._rights.Properties[propertyName];
		/*! SuppressStringValidation bool constant */
		return propertyRights && propertyRights[rightName] ? propertyRights[rightName] : 'true';
	}

	_hasAccess(entity, condition) {
		this._validateCondition(condition);

		const result = SecurityExpressionVisitor.visitSync(entity, condition);
		return result.state === constants.States.Available ? !!result.value : false;
	}

	async _hasAccessAsync(entity, condition) {
		this._validateCondition(condition);

		const result = await SecurityExpressionVisitor.visitAsync(entity, condition);
		return !!result.value;
	}

	_validateCondition(condition) {
		if (!condition) {
			throw new Error('Empty condition.');
		}
	}
}

export default { get, loadAsync };
