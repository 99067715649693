import ajaxService from 'AjaxService';
import AsyncLock from 'AsyncLock';
import connection from 'Connection';
import errorHandler from 'ErrorHandler';
import errors from 'Errors';
import { sessionStore, StorageError } from 'PersistentStorage';

const messageQueueKey = 'ServerMessageQueue';

class ServerMessageQueue {
	constructor() {
		this._localQueue = [];
		this._storage = sessionStore();
		this._asyncLock = new AsyncLock();
		this._isAjaxInProcess = false;
		this._isOnline = true;
		this._connectionSubscription = null;
		this._hasResumed = false;
	}

	resetAsync() {
		this._isAjaxInProcess = false;
		this._isOnline = true;

		if (this._connectionSubscription) {
			connection.unsubscribe(this._connectionSubscription);
			this._connectionSubscription = null;
		}

		this._hasResumed = false;

		this._localQueue = [];

		if (!this._storage) {
			this._storage = sessionStore();
		}

		return storeQueueInLocalStorageAsync(this);
	}

	queueMessage(request) {
		const queueMessageAsync = async () => {
			await this.resumeAsync();
			this._localQueue.push(request);
			storeQueueInLocalStorageAsync(this);

			drainQueue(this);
		};

		queueMessageAsync();
	}

	resumeAsync() {
		return this._asyncLock.doAsync(async () => {
			if (!this._hasResumed) {
				try {
					const queue = await this._storage?.getAsync(messageQueueKey);
					this._localQueue = queue || [];

					storeQueueInLocalStorageAsync(this);
					drainQueue(this);
				} catch (error) {
					if (!(error instanceof StorageError)) {
						throw error;
					}
					this._storage = null;
				} finally {
					this._hasResumed = true;
				}
			}
		});
	}
}

function storeQueueInLocalStorageAsync(self) {
	const queueToSave = self._localQueue.slice();
	return self._asyncLock.doAsync(async () => {
		try {
			await self._storage?.setAsync(messageQueueKey, queueToSave);
		} catch (error) {
			if (!(error instanceof StorageError)) {
				throw error;
			}
		}
	});
}

function drainQueue(self) {
	if (!self._isAjaxInProcess && self._isOnline && self._localQueue.length) {
		const request = self._localQueue.shift();
		storeQueueInLocalStorageAsync(self);

		self._isAjaxInProcess = true;
		ajaxAsync(self, request);
	}
}

async function ajaxAsync(self, request) {
	const localQueue = self._localQueue;
	const adaptedRequest = adaptRequestToAjaxService(request);
	const isReset = () => self._localQueue !== localQueue;

	try {
		await ajaxService.ajaxAsync(adaptedRequest);
		if (isReset()) {
			return;
		}

		self._isAjaxInProcess = false;

		drainQueue(self);
	} catch (error) {
		if (isReset()) {
			return;
		}

		if (error instanceof connection.OfflineError) {
			self._isOnline = false;
			self._isAjaxInProcess = false;
			self._localQueue.unshift(request);
			storeQueueInLocalStorageAsync(self);

			if (!self._connectionSubscription) {
				self._connectionSubscription = connection.subscribe((isOnline) => {
					if (isOnline) {
						connection.unsubscribe(self._connectionSubscription);
						self._connectionSubscription = null;
						self._isOnline = true;

						drainQueue(self);
					}
				});
			}
		} else if (error instanceof errors.RenewSessionError) {
			throw error;
		} else {
			errorHandler.reportError(error, 'Error while processing MessageQueue');
			self._isAjaxInProcess = false;
			drainQueue(self);
		}
	}
}

function adaptRequestToAjaxService(request) {
	const convertedRequest = {
		url: request.uri,
		headers: request.headers,
		type: request.verb,
	};

	if (request.data) {
		convertedRequest.headers = convertedRequest.headers || {};
		/*! SuppressStringValidation Header Content Type */
		convertedRequest.headers['Content-Type'] = 'application/json';
		convertedRequest.data = JSON.stringify(request.data);
	}

	return convertedRequest;
}

export default new ServerMessageQueue();
