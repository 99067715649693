interface InnerError extends Error {
  innerError?: InnerError;
}

export class NestedError extends Error {
  readonly innerError?: InnerError;

  constructor(message?: string, innerError?: Error) {
    super(message);

    this.innerError = innerError;
  }
}
