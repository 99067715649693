import captionService from 'CaptionService';
import dialogService from 'DialogService';
import _ from 'UnderscoreExtensions';
import ko from 'knockout';

function ButtonViewModel(params) {
	this._caption = ko.isObservable(params.caption) ? params.caption : ko.observable(params.caption);

	this.dataItem = params.dataItem;
	this.command = params.command;
	this.isDefault = params.isDefault;
	this.anchor = params.anchor;
	this.buttonClass = params.buttonClass;

	if (params.clickAction) {
		this.clickAction = params.clickAction;
	}
	else if (params.opensMobileMenu) {
		this.clickAction = () => {
			return dialogService.showDialogAsync({
				mobileMenu: true,
				target: params.target,
				mobileMenuViewModel: params.createMobileMenuViewModel && params.createMobileMenuViewModel()
			});
		};
	}
	else {
		this.clickAction = _.noop;
	}

	this.caption = ko.pureComputed({
		read: getCaption.bind(null, this),
		write: setCaption.bind(null, this),
	});
}

function getCaption(vm) {
	const caption = vm._caption();

	return typeof caption === 'object' ? captionService.getStringFromInfo(caption) : caption;
}

function setCaption(vm, value) {
	vm._caption(value);
}

export default ButtonViewModel;
