import $ from 'jquery';
import errors from 'Errors';
import global from 'Global';
import { getQueryStringValue } from 'UriUtils';

export default class ClientLinkNotifier {
	constructor(hubsService) {
		this._hubsService = hubsService;
		this._subscriptions = new Set();
		this._sendFailureMessages = [];
	}

	init() {
		if (!ClientLinkNotifier.isClientLinkIdSetInHref) {
			return;
		}

		/*! SuppressStringValidation url param key */
		const clientLinkSessionKey = 'ClientLink-Session';
		$.connection.hub.qs[clientLinkSessionKey] = ClientLinkNotifier._clientLinkSession;
		$.connection.ClientLink.client.handleMessage = (message) => {
			const { kind, payload } = JSON.parse(message);
			this._subscriptions.forEach((s) => s.call(null, kind, payload));
		};

		$.connection.hub.stateChanged(async (state) => {
			if (
				state.newState === $.signalR.connectionState.connected &&
				this._sendFailureMessages.length
			) {
				// Messages that failed to send are retried once upon reconnection.
				// If they fail to send again, they are discarded.
				const messages = this._sendFailureMessages;
				this._sendFailureMessages = [];
				try {
					await this._hubsService.ensureConnectionAsync();
					await Promise.all(
						messages.map((serializedMessage) =>
							this._hubsService.wrapSignalRAsync(
								$.connection.ClientLink.server.sendMessage(serializedMessage)
							)
						)
					);
				} catch (e) {
					if (!(e instanceof errors.SignalRError)) {
						throw e;
					}
				}
			} else if (state.newState === $.signalR.connectionState.disconnected) {
				/*! SuppressStringValidation event name */
				this._subscriptions.forEach((s) => s.call(null, 'disconnected', null));
			}
		});
	}

	async subscribeAsync(messageCallback) {
		if (!ClientLinkNotifier.isClientLinkIdSetInHref) {
			throw new errors.MissingClientLinkError();
		}

		try {
			await this._hubsService.ensureConnectionAsync();
		} catch (e) {
			if (!(e instanceof errors.SignalRError)) {
				throw e;
			}
		}
		this._subscriptions.add(messageCallback);
		return {
			dispose: () => this._subscriptions.delete(messageCallback),
		};
	}

	async sendMessageAsync(kind, payload) {
		if (!ClientLinkNotifier.isClientLinkIdSetInHref) {
			throw new errors.MissingClientLinkError();
		}

		const message = { kind, payload };
		const serializedMessage = JSON.stringify(message);

		try {
			await this._hubsService.ensureConnectionAsync();
			await this._hubsService.wrapSignalRAsync(
				$.connection.ClientLink.server.sendMessage(serializedMessage)
			);
		} catch (e) {
			if (!(e instanceof errors.SignalRError)) {
				throw e;
			}
			this._sendFailureMessages.push(serializedMessage);
		}
	}

	static get isClientLinkIdSetInHref() {
		return !!ClientLinkNotifier._clientLinkSession;
	}

	static get _clientLinkSession() {
		/*! SuppressStringValidation url query param name */
		const queryParam = 'clid';
		return getQueryStringValue(global.getWindow().location.href, queryParam);
	}
}