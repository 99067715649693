import ajaxService from "AjaxService";
import { AsyncDictionary } from "AsyncDictionary";
import global from "Global";
import { getLanguageCode } from "LanguageService";
import { joinUri } from "UriUtils";

async function getNoteTypesAsync(entityTypeName: string): Promise<NoteType[]> {
  const currentLanguageCode = getLanguageCode();
  if (cache.languageCode !== currentLanguageCode) {
    cache.reset(currentLanguageCode);
  }

  return await cache.noteTypes.getOrAddAsync(entityTypeName, () => getNoteTypesCoreAsync(entityTypeName));
}

async function getNoteTypesCoreAsync(entityTypeName: string): Promise<NoteType[]> {
  const uri = joinUri(global.serviceUri, `/api/notetypes/${entityTypeName}`);
  const result = await ajaxService.getAsync<NoteType[]>(uri);
  return result;
}

function clearCache(): void {
  cache.reset();
}

class LocalCache {
  languageCode?: string;
  readonly noteTypes: AsyncDictionary<NoteType[]> = new AsyncDictionary<NoteType[]>();
  constructor() {}
  reset(languageCode?: string): void {
    this.languageCode = languageCode;
    this.noteTypes.clear();
  }
}

const cache = new LocalCache();

export default {
  getNoteTypesAsync,
  clearCache,
};

export type NoteType = {
  name: string;
  description: string;
  defaultVisibility: string;
  isTextOnly: boolean;
};
