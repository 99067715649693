import global from 'Global';
import $ from 'jquery';
import ko from 'knockout';
import { CssClass } from 'Constants';
import { loadTemplateAsync } from 'ModuleLoader';
import dialogService from 'DialogService';
import vueFactory from 'VueFactory';
import MessageBus from 'MessageBus';
import { DialogTypes } from "DialogTypes";
import { type BasePageViewModel } from "PageViewModel";
import { type Entity } from "BreezeExtensions";
import type { PageExtensions } from 'PageExtensions';
import { type DeferredPromise } from 'DeferredPromise';

export class MaterialDesignVueDialogService {
	states: DialogState[];
	constructor() {
		this.states = [];
	}

	canShowDialog(): boolean {
		return global.materialDesign && !dialogService.isDialogOpen(DialogTypes.Default);
	}

	async showDialogAsync(dataItem: ko.Observable | Entity | undefined,
						  state: DialogState,
						  template: string | Element,
						  viewModel: BasePageViewModel,
						  title: string,
						  captions: Record<string, string> | undefined): Promise<[JQuery.PlainObject, ko.BindingContext<BasePageViewModel>]> {
		this.hideDialogVue(state);
		const $template = $('<div>');
		if (typeof template === 'string') {
			const shell = await loadTemplateAsync(template);
			$(shell).appendTo($template);
		}
		else {
			$(template).appendTo($template);
		}
		$template.appendTo($(CssClass.ModalsContainer.Selector));
		const contentContainer: Element = $template[0];

		const bindingContext = new ko.bindingContext(viewModel);
		bindingContext.$contentViewModel = viewModel;
		if (viewModel) {
			viewModel.pageExtensions = { dataItem, messageBus: new MessageBus() } as PageExtensions;
		}
		state.data = {
			captions,
			viewModel,
			showDialog: false,
			title,
		};
		const instance = await vueFactory.createVueInstanceAsync({
			contentContainer,
			knockoutContext: bindingContext,
			bindingContext: viewModel,
			requiresParent: true,
			name: 'VueDialogContext',
			data: () => state.data,
			methods: {
				onInput: () => {
					this.hideDialogVue(state);
				},
			},
		});
		state.data.showDialog = true;
		state.element = instance.$el;
				this.states.push(state);
		return [$(state.element), bindingContext];
	}

	hideDialogVue(state: DialogState): void {
		this.removeState(state);
		hideDialogVueCore(state);
	}

	removeState(state: DialogState): void {
		const index = this.states.indexOf(state);
		if (index >= 0) {
			this.states.splice(index, 1);
		}
	}

	hideAllDialogs(): void {
		for(const state of this.states) {
			hideDialogVueCore(state);
		}

		this.states = [];
	}
}

function hideDialogVueCore(state: DialogState): void {
	if (state.data) {
		state.data.showDialog = false;
		state.deferred && state.deferred.resolve();
		setTimeout(() => {
			if (state.element) {
				ko.removeNode(state.element as Node);
				state.element = null;
			}
		}, 500);
	}
}

export interface DialogState {
    data?: DialogStateData;
    deferred?: DeferredPromise<void>;
    element?: JQuery.PlainObject | null;
}

export interface DialogStateData {
    captions?: Record<string, string>;
    viewModel?: BasePageViewModel;
    showDialog: boolean;
    title?: string;
}

export default new MaterialDesignVueDialogService();