/*
	Usage:

	<div data-initial-focus*="false" data-bind="component: {
		name: 'gwSomeComponent',
		params: {
			fieldId: 'SomeUniqueId',
			bindingPath: 'Some.BindingPath',

			caption*: { short: '', medium: '', long: ''} / { key: '', text: '' },
			isReadOnly*: 'false',
			validation*: { valuePath: 'UseNotifications.FromThisPathInstead' }
		}
	}"></div>

	Additional params may be required by the individual component.
*/

import Promise from 'bluebird';
import { compact, flow } from 'lodash-es';
import $ from 'jquery';
import ko from 'knockout';
import bindingEvaluator from 'BindingEvaluator';
import captionService from 'CaptionService';
import configurationHelper from 'ConfigurationHelper';
import guid from 'GuidGenerator';
import decorator from 'Shared/KOComponentDecorator';
import global from 'Global';
import { tryGetWidget } from 'ModuleLoader';
import entitySetRightsProvider from 'EntitySetRightsProvider';
import Dependency from 'Dependency2';
import constants from 'Constants';

function unwrapTemplates(domNodes) {
	// By convention a node is the actual widget template if:
	// - it is not a <script> tag
	// - OR it is a <script> tag without ID
	// - OR it is a <script> tag with ID of "widget-template"
	const result = flow(
	(x) => x.map((node) => {
		if (node.nodeName === 'SCRIPT') {
			return !node.id || node.id === 'widget-template' ? $(node.innerHTML).toArray() : null;
		}
		else {
			return node;
		}
	}),
	(x) => x.flat(),
	compact
	)(domNodes);
	return result;
}

function appendGlobalTemplatesToBody(domNodes) {
	const $body = $(document.body);
	const templates = [];
	domNodes.forEach((node) => {
		/*! SuppressStringValidation DOM attribute */
		if (node.nodeName === 'SCRIPT' && node.getAttribute('data-append-to-body')) {
			$body.append(node);
		}
		else {
			templates.push(node);
		}
	});
	return templates;
}

function removeOtherFormFactorNodes(domNodes) {
	/*! SuppressStringValidation element selector */
	const formFactorSelector = '[data-form-factor]';
	/*! SuppressStringValidation element selector */
	const currentFormFactorSelector = '[data-form-factor=' + global.formFactor + ']';
	return domNodes
		.filter((node) => {
			return !$(node).is(formFactorSelector) || $(node).is(currentFormFactorSelector);
		})
		.map((node) => {
			if (node.innerHTML && node.innerHTML.indexOf('data-form-factor') > -1) {
				const $node = $('<div>').append(node.innerHTML);
				if ($node.find(formFactorSelector).not(currentFormFactorSelector).remove().length) {
					node.innerHTML = $node[0].innerHTML;
				}
			}
			return node;
		});
}

function getParams(originalParams, componentInfo, isInConfigurationMode, componentConfig) {
	const params = $.extend({}, originalParams);
	params.fieldId = params.fieldId || guid.newGuid();

	const bindingPath = params.bindingPath;
	const context = ko.contextFor(componentInfo.element);
	params.dataItem = context.$ultimateDataItem(bindingPath || '');
	params.propertyName = bindingPath ? bindingEvaluator.getPropertyName(bindingPath) : null;

	if (isEntity(context.$data)) {
		const childType = bindingEvaluator.getEntityBindingInfo(context.$data.entityType, bindingPath);
		params.dataItemEntityType = childType.entityType;
		params.dataItemEntityTypeName = childType.entityType.interfaceName;
		params.boundPropertyType = childType.propertyType;
		if (isInConfigurationMode && bindingPath) {
			params.originalCaption = captionService.getCaptionFromField(childType).caption;
		}
		const entitySetRights = entitySetRightsProvider.get(params.dataItemEntityType, false);
		if (params.propertyName && entitySetRights && !componentConfig.canHandleReadRestriction) {
			params.isReadRestrictedAsync = () => {
				return bindingEvaluator.loadUltimateDataItemWithStateAsync(context, context.$data, bindingPath).then((dataItemResult) => {
					if (dataItemResult.state === constants.States.NotAvailable) {
						return true;
					}
					return entitySetRights.canReadPropertyAsync(dataItemResult.value, params.propertyName).then((canRead) => {
						if (!canRead) {
							return true;
						}
						return Dependency.getValueAsync(dataItemResult.value, params.propertyName).then((result) => {
							return result.state === constants.States.NotAvailable;
						});
					});
				});
			};
		}
	}

	const captionOverride = params.captionOverride;
	const getJsonOrValue = (str) => {
		try {
			return JSON.parse(str);
		} catch (e) {
			return str;
		}
	};
	params.captionOverride = typeof captionOverride === 'string' ? (captionOverride ? getJsonOrValue(captionOverride) : null) : captionOverride;
	params.isInConfigurationMode = isInConfigurationMode;

	return params;
}

function isEntity(dataItem) {
	return !!(dataItem && dataItem.entityType && dataItem.entityAspect);
}

function init(component, componentConfig, isConfigTemplateApplied) {
	let initPromiseOrValue;

	if (componentConfig.init && (!isConfigTemplateApplied || componentConfig.forceInitInConfigurationMode)) {
		initPromiseOrValue = componentConfig.init(component);
	}

	if (componentConfig.dispose) {
		ko.utils.domNodeDisposal.addDisposeCallback(component.$elementObsoleteDoNotUse[0], () => {
			componentConfig.dispose(component);
		});
	}

	const onInit = component.params.onInit;
	const resolveCallback = onInit && onInit.resolve;
	const rejectCallback = onInit && onInit.reject;
	return Promise.wait(initPromiseOrValue, resolveCallback, rejectCallback);
}

const loader = {
	getConfig(name, callback) {
		const config = tryGetWidget(name);
		if (!config) {
			throw new Error('Component not loaded yet: ' + name);
		}

		if (!config.template) {
			throw new Error('Template was not specified for component: ' + name);
		}

		config.synchronous = true;
		callback(config);
	},
	loadComponent(name, componentConfig, callback) {
		ko.components.defaultLoader.loadComponent(name, componentConfig, (baseResult) => {
			let templates = removeOtherFormFactorNodes(baseResult.template);
			templates = appendGlobalTemplatesToBody(templates);
			const result = {
				template: unwrapTemplates(templates),
				createViewModel: createViewModel.bind(null, name, componentConfig, baseResult)
			};

			callback(result);
		});
	}
};

function createViewModel(name, componentConfig, baseResult, params, componentInfo) {
	const $container = $(componentInfo.element);
	if (!$.contains(document.body, $container[0])) {
		$container.html('');
		return params;
	}

	if (params && !isValidEntityBindingPath($container, params.bindingPath)) {
		makeErrorControl($container);
		return params;
	}

	const componentContext = ko.contextFor(componentInfo.element);
	const isInConfigurationMode = !!configurationHelper.getConfigurationContext(componentContext);

	params = getParams(params, componentInfo, isInConfigurationMode, componentConfig);
	const viewModel = baseResult.createViewModel(params, componentInfo, componentConfig);
	if (isInConfigurationMode && !params.subWidget && !params.disableConfigTmpl) {
		const configTemplate = baseResult.template.find((node) => {
			/*! SuppressStringValidation DOM attribute */
			return node.nodeName === 'SCRIPT' && node.getAttribute('id') === 'config-template';
		});

		if (configTemplate) {
			params.isConfigTemplateApplied = true;
			$container.html(configTemplate.innerHTML);
		}
	}

	let $widget = $container;
	if (componentConfig.widgetSelector) {
		$widget = $widget.find(componentConfig.widgetSelector);
	}

	const component = {
		$container,
		$elementObsoleteDoNotUse: $widget,
		instance: $.extend({
			name,
			$container,
			$elementObsoleteDoNotUse: $widget,
			getParams() {
				return params;
			},
			getParam(name) {
				return params[name];
			}
		}, componentConfig.methods),
		params,
		viewModel,
		originalTemplateNodes: componentInfo.templateNodes
	};

	component.$container
		.data('component.glow', component.instance);
	component.$elementObsoleteDoNotUse
		.attr('data-ko-widget', name)
		.data('component.glow', component.instance);
	$container.addClass('g-container');

	const initResult = init(component, componentConfig, params.isConfigTemplateApplied);
	const initPromise = initResult instanceof Promise ? initResult : null;
	decorator.decorate(component, initPromise);

	if (componentContext && typeof componentConfig.menu === 'function') {
		const rootViewModel = componentContext.$root;
		if (rootViewModel && rootViewModel.customPinnableMenuItems) {
			const menu = componentConfig.menu(viewModel, params);
			rootViewModel.customPinnableMenuItems.push(menu);
		}
	}

	return viewModel;
}

ko.components.loaders.unshift(loader);

$.fn.component = function () {
	const componentInfo = this.data('component.glow');
	if (!arguments.length) {
		return componentInfo ?
			componentInfo.name :
			this.data('role'); // Backwards compatibility
	}

	if (componentInfo) {
		if (typeof arguments[0] === 'string') {
			const methodName = arguments[0];
			const method = componentInfo[methodName];
			if (typeof method !== 'function') {
				throw new Error("No such method '" + methodName + "' for " + componentInfo.name + ' widget instance.');
			}

			const args = Array.prototype.slice.call(arguments, 1);
			return method.apply({ elementObsoleteDoNotUse: componentInfo.$elementObsoleteDoNotUse, container: componentInfo.$container }, args);
		}
	}
	else {
		// Backwards compatibility
		const widgetData = this.widgetData();
		if (widgetData) {
			return this[this.data('role')].apply(this, arguments);
		}
	}

	return null;
};

$.fn.hasComponentMethod = function (methodName) {
	const componentInfo = this.data('component.glow') || /* Backwards compatibility */ this.widgetData();
	return componentInfo && typeof componentInfo[methodName] === 'function';
};

function isValidEntityBindingPath($widget, bindingPath) {
	if (bindingPath) {
		const data = ko.dataFor($widget[0]);
		if (isEntity(data) && !bindingEvaluator.isValidEntityBindingPath(data.entityType, bindingPath)) {
			return false;
		}
	}

	return true;
}

function makeErrorControl($widget) {
	const tooltip = captionService.getString(
		'b5682b06-b0bf-4a5c-b0e9-588e4bb58ac0',
		'This data field had to be removed due to an invalid property name. Contact your administrator to have the Visual Designer template corrected.');
	const errorMessage = captionService.getString(
		'845A1A97-41A2-4239-893F-6AB47575A9F8',
		'Data field removed');
	/*! SuppressStringValidation DOM attribute */
	$widget.html('<div class="g-invalid-property" data-bind="gwTooltip: { tooltip: \'' + tooltip + '\', isError: true }"><span>' + errorMessage + '</span></div>');
}
