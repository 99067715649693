import { getCachedPromise } from "CachedPromise";
import { getDependencyValueAsync } from "Dependency2";
import type { DependencyGraph, DependencyVertex } from "DependencyGraph";

const vertexMap = new WeakMap<DependencyGraph, Map<string, GlowMacroVertex>>();

export function getGlowMacroVertex(
  dependencyGraph: DependencyGraph,
  path: string
): GlowMacroVertex {
  let vertexes = vertexMap.get(dependencyGraph);
  if (!vertexes) {
    vertexes = new Map();
    vertexMap.set(dependencyGraph, vertexes);
  }

  let vertex = vertexes.get(path);
  if (!vertex) {
    vertex = new GlowMacroVertex(dependencyGraph, path);
    vertexes.set(path, vertex);
  }

  return vertex;
}

class GlowMacroVertex implements DependencyVertex {
  readonly loadMacroAsync: () => Promise<void>;

  constructor(private readonly dependencyGraph: DependencyGraph, private readonly path: string) {
    this.loadMacroAsync = getCachedPromise(this._loadMacroCoreAsync, this);
  }

  getId(): string {
    return "GlowMacro" + this.path;
  }

  isValid(): boolean {
    return true;
  }

  reportChangedAsync(): Promise<void> {
    return Promise.reject(new Error("GlowMacroVertex cannot have dependencies."));
  }

  private async _loadMacroCoreAsync(): Promise<void> {
    await getDependencyValueAsync({}, this.path);
    await this.dependencyGraph.notifyDependentsAsync(this, true);
  }
}
