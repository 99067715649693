import buttonHelper from 'ButtonHelper';
import ButtonViewModel from 'ButtonViewModel';
import constants from 'Constants';
import template from 'WidgetTemplates/gwButton.html';
import 'Widgets/gwCommand';
import 'Widgets/gwLoadingAnimation';
import _ from 'underscore';

function createViewModel(params) {
	return new ButtonViewModel(params);
}

function init(component) {
	const $component = component.$elementObsoleteDoNotUse;
	buttonHelper.decorate(getButton($component), { isAsync: true, isDefault: component.params.isDefault });

	// The default behaviour on touch-based devices (iOS) is that none of the elements' hover states are activated on touch and move.
	// Adding an action listener to on touchend causes the $element to apply the :hover properties straight away when it is touched
	$component.on('touchend', () => { });
}

/** @this { elementObsoleteDoNotUse: componentInfo.$elementObsoleteDoNotUse, container: componentInfo.$container } */
function getCaptionElement() {
	return this.elementObsoleteDoNotUse.find('span');
}

/** @this { elementObsoleteDoNotUse: componentInfo.$elementObsoleteDoNotUse, container: componentInfo.$container } */
function markAsReadOnly(value) {
	buttonHelper.disable(getButton(this.elementObsoleteDoNotUse), value);
}

function getButton($element) {
	return $element.find('button');
}

/*! StartNoStringValidationRegion No captions here. */
const componentConfig = {
	template,
	widgetSelector: '.gwButton',
	viewModel: { createViewModel },
	init,
	methods: {
		getCaptionElement,
		markAsReadOnly,
		resizeAxis: _.constant(constants.ResizeAxis.Horizontal)
	}
};
/*! EndNoStringValidationRegion */

export default componentConfig;
