import { inject, provide } from "vue";

export const formIdInjectionKey = Symbol("formId");

export function useFormId(): string | undefined {
  return inject<string | undefined>(formIdInjectionKey, undefined);
}

export function setFormId(value: string): void {
  provide(formIdInjectionKey, value);
}
