export function isSystemAuditProperty(propertyName: string, tableCode?: string): boolean {
	const propertyNameWithoutTableCode = tableCode ? propertyName.substring(tableCode.length + 1) : propertyName;

	return propertyNameWithoutTableCode === 'CHANGE_VERSION' ||
		propertyNameWithoutTableCode === 'AutoVersion' ||
		propertyNameWithoutTableCode === 'SystemLastEditUser' ||
		propertyNameWithoutTableCode === 'SystemLastEditTime' ||
		propertyNameWithoutTableCode === 'SystemLastEditTimeUtc' ||
		propertyNameWithoutTableCode === 'SystemCreateUser' ||
		propertyNameWithoutTableCode === 'SystemCreateTime' ||
		propertyNameWithoutTableCode === 'SystemCreateTimeUtc';
}

export default { isSystemAuditProperty };
