import type { BindingInfo } from "BindingEvaluator";
import entitySetRightsProvider from "EntitySetRightsProvider";
import type { ComplexType, EntityType, IProperty, NavigationProperty } from "breeze-client";

export function isAlwaysRestrictedBindingInfo(bindingInfo: BindingInfo): boolean {
  return isRestricted(bindingInfo) || bindingInfo.parents.some(isRestricted);

  function isRestricted(part: BindingInfo): boolean {
    const property = part.entityType.getProperty(part.propertyName);
    return !!property && isAlwaysRestrictedBreezeProperty(property);
  }
}

export function isAlwaysRestrictedBreezeProperty(property: IProperty): boolean {
  /*! SuppressStringValidation Boolean literal */
  return checkBreezeProperty(property, "false");
}

export function isUnrestrictedBindingInfo(bindingInfo: BindingInfo): boolean {
  return isUnrestricted(bindingInfo) && bindingInfo.parents.every(isUnrestricted);

  function isUnrestricted(part: BindingInfo): boolean {
    const property = part.entityType.getProperty(part.propertyName);
    return !property || isUnrestrictedBreezeProperty(property);
  }
}

export function isUnrestrictedBreezeProperty(property: IProperty): boolean {
  /*! SuppressStringValidation Boolean literal */
  return checkBreezeProperty(property, "true");
}

function checkBreezeProperty(property: IProperty, targetReadCondition: string): boolean {
  if (property.isNavigationProperty) {
    const navigationProperty = property as NavigationProperty;
    return (
      navigationProperty.foreignKeyNames.some((fk) => hasTargetReadCondition(navigationProperty.parentType, fk)) ||
      navigationProperty.invForeignKeyNames.some((fk) => hasTargetReadCondition(navigationProperty.entityType, fk))
    );
  }

  return hasTargetReadCondition(property.parentType, property.name);

  function hasTargetReadCondition(entityType: EntityType | ComplexType, propertyName: string): boolean {
    return entitySetRightsProvider.get(entityType).canReadPropertyCondition(propertyName) === targetReadCondition;
  }
}
