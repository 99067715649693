/*! StartNoStringValidationRegion Does not contain captions */
import Promise from 'bluebird';
import breeze from 'breeze-client';
import { BreezeOData4 } from 'breeze-odata4';
import constants from 'Constants';
import CustomOData4DataService from 'CustomOData4DataService';
import { toISOString } from 'DateTimeOffset';
import entityChangesRegistrar from 'EntityChangesRegistrar';
import errors from 'Errors';
import global from 'Global';
import $ from 'jquery';
import { createJsonResultsAdapter } from 'JsonResultsAdapterFactory';
import moment from 'moment';
import oData from 'OData';
import { getODataHeaders, MetadataType } from 'ODataUtils';
import ruleRepository from 'RuleRepository';
import _ from 'underscore';
import { getRouteName } from 'UriUtils';

BreezeOData4.configure();
const BaseDataService = CustomOData4DataService;

const dummyMetadataStore = {
	hasMetadataFor() {
		return true;
	},
};

const expandPathsMap = new WeakMap();

class GlowDataService extends BaseDataService {
	constructor() {
		super();
		this.name = 'glow';
		this.cachePendingQueries = true;
		this._pendingQueries = {};

		this.headers = getODataHeaders(MetadataType.Minimal);
		this.changeRequestInterceptor = ChangeRequestInterceptor;
	}

	getAbsoluteUrl(dataService, url) {
		if (url && $.url(url).attr('protocol')) {
			return url;
		}

		return super.getAbsoluteUrl.apply(this, arguments);
	}
}

class ChangeRequestInterceptor {
	constructor() {
		this.dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
	}

	done(/*requests*/) {}

	getRequest(request, entity /*, index*/) {
		if (!entity.entityAspect.entityState.isDeleted()) {
			for (const propertyName in request.data) {
				const dataProperty = entity.entityType.getDataProperty(propertyName);
				const propertyValue = request.data[propertyName];

				if (dataProperty.dataType !== breeze.DataType.DateTimeOffset) {
					continue;
				}

				if (dataProperty.dateTimeType === constants.DateTimeTypes.DateTimeOffset) {
					if (moment.minValue.isSame(propertyValue)) {
						request.data[propertyName] =
							moment.utc(propertyValue).format(this.dateTimeFormat) + 'Z';
					} else if (moment.isValidDate(propertyValue)) {
						request.data[propertyName] = toISOString(propertyValue);
					}
				} else {
					if (moment.isValidDate(propertyValue)) {
						if (
							dataProperty.dateTimeType === constants.DateTimeTypes.DateTimeUtc ||
							moment.minValue.isSame(propertyValue)
						) {
							request.data[propertyName] =
								moment.utc(propertyValue).format(this.dateTimeFormat) + 'Z';
						} else {
							request.data[propertyName] =
								moment([
									propertyValue.getFullYear(),
									propertyValue.getMonth(),
									propertyValue.getDate(),
									propertyValue.getHours(),
									propertyValue.getMinutes(),
									propertyValue.getSeconds(),
									propertyValue.getMilliseconds(),
								]).format(this.dateTimeFormat) + 'Z';
						}
					}
				}
			}
		}

		return request;
	}
}

(function extendExecuteQuery() {
	GlowDataService.prototype.urlLengthThreshold = 1024;
	GlowDataService.prototype.urlMaximumLimit = 64000;

	GlowDataService.prototype.executeQuery = function (mappingContext) {
		if (typeof mappingContext.query === 'string') {
			throw new Error(
				'The query was specified as a string but only EntityQuery is supported.'
			);
		}

		mappingContext = processParameters(mappingContext);
		excludeGeographyColumnsHack(mappingContext);

		return invokeRequestAsync(
			this,
			mappingContext.getUrl(),
			() => {
				const expandPathsContext = {};
				expandPathsMap.set(mappingContext, expandPathsContext);

				const query = executeQueryCoreAsync(this, mappingContext).then((data) => {
					if (data.results.length > 0) {
						return expandQueryResultAsync(this, mappingContext, data);
					}

					return data;
				});

				return { query, state: expandPathsContext };
			},
			(expandPathsContext) => {
				expandPathsMap.set(mappingContext, expandPathsContext);
			}
		);
	};

	// This is a hack that is put in place because it is isolated and easily patchable
	// It will be removed when we properly implement geography column in odata4 (WI00526390)
	// It will result in significantly better performance for all OrgAddress lookup except the GPS portal as the geography columns are required there.
	function excludeGeographyColumnsHack(mappingContext) {
		const query = mappingContext.query;
		if (
			query.resourceName === 'OrgAddresses' &&
			!query.selectClause &&
			global.moduleName !== 'GPS'
		) {
			const rootTypeName = mappingContext.metadataStore.getEntityTypeNameForResourceName(
				mappingContext.query.resourceName
			);
			const rootEntityType = mappingContext.metadataStore.getEntityType(rootTypeName);
			if (rootEntityType.interfaceName === 'IOrgAddress') {
				let propertyPaths = rootEntityType.dataProperties
					.map((x) => x.name)
					.filter((x) => x !== 'OA_GeofencePolygon' && x !== 'OA_GeoLocation');
				if (query.expandClause) {
					propertyPaths = propertyPaths.concat(
						query.expandClause.propertyPaths
							.map(convertExpandPathToSelectClauses)
							.flat()
					);
				}
				mappingContext.query = mappingContext.query.select(propertyPaths);
				mappingContext.query.geoColumnsHackApplied = true;
			}
		}
	}

	function convertExpandPathToSelectClauses(expandPath) {
		const arr = expandPath.split('.');
		if (arr.length === 1) {
			return arr;
		}

		const result = [];
		let current = '';
		for (let i = 0; i < arr.length; i++) {
			current = current ? current + '.' + arr[i] : arr[i];
			result.push(current);
		}
		return result;
	}

	function getResultingUrl(self, mappingContext) {
		const url = self.getAbsoluteUrl(mappingContext.dataService, mappingContext.getUrl());

		// Add query params if .withParameters was used
		if (!_.isEmpty(mappingContext.query.parameters)) {
			return self.addQueryString(url, mappingContext.query.parameters);
		}

		return url;
	}

	function executeQueryCoreAsync(dataService, mappingContext) {
		return Promise.try(() => {
			const resultingUrl = getResultingUrl(dataService, mappingContext);
			if (resultingUrl.length >= dataService.urlLengthThreshold) {
				if (resultingUrl.length >= dataService.urlMaximumLimit) {
					throw new errors.QueryTooComplexError(
						'The query is too long. URL: ' + resultingUrl
					);
				}
				return executeQueryRequestAsync(dataService, resultingUrl, mappingContext)
					.catch(handleODataValidationError);
			} else {
				return BaseDataService.prototype.executeQuery
					.call(dataService, mappingContext)
					.catch(handleODataValidationError);
			}
		});
	}

	function executeQueryRequestAsync(dataService, absoluteUrl) {
		const { requestUri, data } = getUriAndDataFrom(absoluteUrl);
		const headers = { 'Content-Type': 'text/plain', ...dataService.headers };

		const deferred = Promise.deferred();

		oData.read(
			{
				requestUri,
				method: 'POST',
				headers,
				data,
			},
			(data, response) => {
				let inlineCount, results, __next;

				if (data) {
					inlineCount = Number(data['@odata.count']);
					results = data.value;
					__next = data['@odata.nextLink'];
				}

				return deferred.resolve({ results, inlineCount, httpResponse: response, __next });
			},
			(err) => {
				const error = dataService.createError(err, requestUri);
				return deferred.reject(error);
			}
		);

		return deferred.promise();

		function getUriAndDataFrom(absoluteUrl) {
			let baseUrl = absoluteUrl;
			let data = '';

			const index = absoluteUrl.indexOf('?');
			if (index !== -1) {
				baseUrl = absoluteUrl.substring(0, index);
				data = absoluteUrl.substring(index + 1);
			}

			return {
				requestUri: baseUrl + '/$query',
				data,
			};
		}
	}

	function handleODataValidationError(error) {
		if (error.body && error.body.error && error.body.error.code === 'ODataValidation') {
			error = new errors.QueryTooComplexError(
				`The query has too many nodes. URL: ${error.url}`,
				error
			);
		}
		throw error;
	}

	function processParameters(mappingContext) {
		const query = mappingContext.query;
		const parameters = query.parameters;
		const resourceName = query.resourceName;

		if (
			!_.isEmpty(parameters) &&
			resourceName &&
			mappingContext.metadataStore.getFunctionImport
		) {
			const outputParameters = {};
			const functionImport = mappingContext.metadataStore.getFunctionImport(
				resourceName,
				true
			);

			for (const name in parameters) {
				let dataType;
				let value = parameters[name];

				if (functionImport) {
					const parameter = _.findWhere(functionImport.parameters, { name });
					if (!parameter) {
						throw new Error(
							`Parameter named '${name}' is not valid for function import '${resourceName}'.`
						);
					}

					dataType = parameter.type;
				} else {
					dataType = breeze.DataType.fromValue(value);
				}

				value = dataType.fmtOData(value);
				outputParameters[name] = value;
			}

			mappingContext = _.extend({}, mappingContext);
			mappingContext.query = query.withParameters(outputParameters);
		}

		return mappingContext;
	}

	function addToExpandsMetadata(expandPathsContext, entity, segment) {
		const key = entity[segment.parentType.keyProperties[0].name];
		let expands = expandPathsContext[key];
		if (!expands) {
			expandPathsContext[key] = expands = [];
		}

		if (expands.indexOf(segment.name) === -1) {
			expands.push(segment.name);
		}
	}

	async function expandCollectionAsync(
		self,
		mappingContext,
		expandPathsContext,
		collection,
		segments,
		segmentIndex
	) {
		const expandEntityResults = [];

		for (const entity of collection) {
			const result = await expandEntityAsync(
				self,
				mappingContext,
				expandPathsContext,
				entity,
				segments,
				segmentIndex
			);

			expandEntityResults.push(result);
		}

		return expandEntityResults;
	}

	async function expandEntityAsync(
		self,
		mappingContext,
		expandPathsContext,
		entity,
		segments,
		segmentIndex
	) {
		const segment = segments[segmentIndex];
		const property = entity[segment.name];
		const isLastSegment = segmentIndex === segments.length - 1;
		addToExpandsMetadata(expandPathsContext, entity, segment);

		if (segment.isScalar) {
			if (property && !isLastSegment) {
				return expandEntityAsync(
					self,
					mappingContext,
					expandPathsContext,
					property,
					segments,
					segmentIndex + 1
				);
			}
		}

		const hasProperty = property && property.length > 0;

		if (hasProperty) {
			const nextLink = entity[`${segment.name}@odata.nextLink`];
			const removeNextLink = () => delete entity[`${segment.name}@odata.nextLink`];
			const collection = property;
			const loadContinuationsPromise = loadContinuationsAsync(
				self,
				mappingContext,
				collection,
				nextLink,
				removeNextLink
			);

			if (!isLastSegment) {
				await loadContinuationsPromise;

				return expandCollectionAsync(
					self,
					mappingContext,
					expandPathsContext,
					collection,
					segments,
					segmentIndex + 1
				);
			}

			return loadContinuationsPromise;
		}
	}

	async function expandQueryResultAsync(self, mappingContext, data) {
		await loadContinuationsAsync(self, mappingContext, data.results, data.__next);

		const expandItems = getExpandItems(mappingContext).filter((x) => x.length > 0);
		if (expandItems.length > 0) {
			const expandPathsContext = expandPathsMap.get(mappingContext);

			for (const segments of expandItems) {
				await expandCollectionAsync(
					self,
					mappingContext,
					expandPathsContext,
					data.results,
					segments,
					0
				);
			}
		}

		return data;
	}

	function getExpandItems(mappingContext) {
		const { expandClause } = mappingContext.query;

		if (!expandClause) {
			return [];
		}

		const result = expandClause.propertyPaths.map((item) => item.replaceAll('.', '/'));
		const metadataStore = mappingContext.entityManager.metadataStore;
		const rootTypeName = metadataStore.getEntityTypeNameForResourceName(
			mappingContext.query.resourceName
		);
		const rootEntityType = metadataStore.getEntityType(rootTypeName);

		for (let i = 0; i < result.length; i++) {
			let entityType = rootEntityType;
			const itemSegments = result[i].trim().split('/');

			for (let j = 0; j < itemSegments.length; j++) {
				const property = entityType.getNavigationProperty(itemSegments[j]);
				itemSegments[j] = property;
				if (property) {
					entityType = property.entityType;
				}
			}

			if (itemSegments.length > 0) {
				result[i] = itemSegments.filter(_.identity);
			}
		}

		return result;
	}

	function loadContinuationsAsync(
		self,
		mappingContext,
		aggregatedData,
		nextLink,
		removeNextLink
	) {
		if (!nextLink) {
			return Promise.resolve(aggregatedData);
		}

		mappingContext = _.extend({}, mappingContext, {
			getUrl: _.constant(nextLink),
			query: {},
		});

		return executeQueryCoreAsync(self, mappingContext).then((newData) => {
			aggregatedData.push(...newData.results);
			removeNextLink && removeNextLink();
			return loadContinuationsAsync(self, mappingContext, aggregatedData, newData.__next);
		});
	}
})();

(function extendFetchMetadata() {
	GlowDataService.prototype.fetchMetadata = function (metadataStore, dataService) {
		return invokeRequestAsync(this, dataService.qualifyUrl('$metadata'), () => {
			const routeName = getRouteName(dataService.serviceName);
			const fetchers = [
				BaseDataService.prototype.fetchMetadata.call(this, dummyMetadataStore, dataService),
				ruleRepository.loadRouteAsync(routeName),
			];

			const query = Promise.all(fetchers).spread((csdlMetadata) => {
				return commitMetadata(metadataStore, dataService, csdlMetadata);
			});

			return { query, state: undefined };
		});
	};

	function commitMetadata(metadataStore, dataService, csdlMetadata) {
		if (!metadataStore.hasMetadataFor(dataService.serviceName)) {
			metadataStore.importMetadata(csdlMetadata);
			metadataStore.addDataService(dataService);
		}
	}
})();

(function overrideJsonResultsAdapter() {
	GlowDataService.prototype.jsonResultsAdapter = createJsonResultsAdapter(expandPathsMap, true);
})();

(function extendSaveChanges() {
	GlowDataService.prototype.saveChanges = function (saveContext, saveBundle) {
		saveBundle = _.extend({}, saveBundle);
		saveBundle.entities = sortChangeRequestEntities(saveBundle.entities);
		saveBundle.entities.forEach(ensureUriKeyOnExtraMetadata);
		const saveTime = new Date();
		return BaseDataService.prototype.saveChanges.call(this, saveContext, saveBundle).tap(() => {
			const modifiedEntities = saveBundle.entities.map((e) => e.entityType.interfaceName);
			entityChangesRegistrar.addChanges(modifiedEntities, saveTime);
		});
	};

	function sortChangeRequestEntities(entities) {
		return entities.slice(0).sort((a, b) => {
			a = getChangeRequestEntityIndex(a);
			b = getChangeRequestEntityIndex(b);
			if (a > b) {
				return 1;
			} else if (a < b) {
				return -1;
			}
			return 0;
		});
	}

	function getChangeRequestEntityIndex(entity) {
		const entityState = entity.entityAspect.entityState;
		if (entityState.isAdded()) {
			return 0;
		} else if (entityState.isModified()) {
			return 1;
		} else {
			return 2;
		}
	}

	function ensureUriKeyOnExtraMetadata(entity) {
		const aspect = entity.entityAspect;
		if (!aspect.extraMetadata || aspect.extraMetadata.uriKey) {
			return;
		}

		const entityType = entity.entityType;
		let uriKey = entityType.defaultResourceName + '(';
		uriKey = uriKey + fmtProperty(entityType.keyProperties[0], aspect) + ')';
		aspect.extraMetadata.uriKey = uriKey;
	}

	function fmtProperty(prop, aspect) {
		return prop.dataType.fmtOData(aspect.getPropertyValue(prop.name));
	}
})();

function invokeRequestAsync(self, uri, requestFactory, onReuseQuery) {
	let pending = self._pendingQueries[uri];

	if (pending) {
		onReuseQuery && onReuseQuery(pending.state);
	} else {
		pending = requestFactory();
		pending.query = pending.query.finally(() => {
			delete self._pendingQueries[uri];
		});

		if (self.cachePendingQueries) {
			self._pendingQueries[uri] = pending;
		}
	}

	return pending.query;
}

breeze.config.registerAdapter('dataService', GlowDataService);

export default GlowDataService;

/*! EndNoStringValidationRegion */
