import Dependency from 'Dependency';
import sortColumnService from 'SortColumnService';

function SortProvider() {}

SortProvider.prototype.constructOrderByClause = (sortInfos) => {
	if (sortInfos && Array.isArray(sortInfos) && sortInfos.length > 0) {
		const sanitizedSortInfos = removeSortInfosWithDuplicatedSortNames(sortInfos);

		return sanitizedSortInfos.reduce((result, sortInfo) => {
			const sortNames = sortColumnService.getSortNames(sortInfo.column);
			return result.concat(
				sortNames.map(constructOrderByColumn.bind(null, sortInfo.direction))
			);
		}, []);
	}

	return null;
};

SortProvider.prototype.sortData = (data, sortInfos, valueGetter) => {
	data = data.slice(0);

	if (sortInfos) {
		valueGetter = valueGetter || getValue;
		data.sort((a, b) => {
			for (let i = 0; i < sortInfos.length; i++) {
				const sortColumn = sortInfos[i];
				const isAscendingOrder = sortColumn.direction === 'asc';
				const sortNames = sortColumnService.getSortNames(sortColumn.column);
				for (let j = 0; j < sortNames.length; j++) {
					const aValue = valueGetter(a, sortNames[j]);
					const bValue = valueGetter(b, sortNames[j]);
					const result = compare(aValue, bValue);
					if (result !== 0) {
						return isAscendingOrder ? result : result * -1;
					}
				}
			}

			return 0;
		});
	}

	return data;
};

function getValue(obj, path) {
	return Dependency.getValue(obj, path);
}

function compare(a, b) {
	const aIsNullOrUndefined = a === null || a === void 0;
	const bIsNullOrUndefined = b === null || b === void 0;

	if (aIsNullOrUndefined) {
		return bIsNullOrUndefined ? 0 : -1;
	} else if (bIsNullOrUndefined) {
		return 1;
	} else {
		if (typeof a === 'string') {
			a = a.toUpperCase();
			b = b.toUpperCase();
		}

		if (a > b) {
			return 1;
		} else if (b > a) {
			return -1;
		}

		return 0;
	}
}

function constructOrderByColumn(direction, sortName) {
	return `${sortName} ${direction || ''}`;
}

function removeSortInfosWithDuplicatedSortNames(sortInfos) {
	const sanitizedSortInfos = [];
	const sortNamesDetected = new Set();

	sortInfos.forEach((item) => {
		const itemSortNames = sortColumnService.getSortNames(item.column);
		if (itemSortNames.some((itemSortName) => sortNamesDetected.has(itemSortName))) {
			return;
		}

		itemSortNames.forEach((sortName) => sortNamesDetected.add(sortName));
		sanitizedSortInfos.push(item);
	});

	return sanitizedSortInfos;
}

export default new SortProvider();
