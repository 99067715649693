import ko from 'knockout';

export default class FooterTransitionMenuViewModel {
	constructor(taskViewModel) {
		this.footerTransitions = getFooterTransitionsWithCategories(taskViewModel.footerTransitions() || []);
		this.selectedCategory = ko.observable(null);
		this._taskViewModel = taskViewModel;
	}

	onMenuClick(transition) {
		if (transition.categoryKey) {
			const clickedCategory = transition.categoryKey;
			this.selectedCategory(clickedCategory === this.selectedCategory() ? null : clickedCategory);
		} else {
			this._taskViewModel.invokeTransitionAsync(transition);
		}
	}

	getCategoryKey(transition) {
		return transition.categoryKey || (transition.Category && transition.Category.ResKey);
	}
}

function getFooterTransitionsWithCategories(transitions) {
	const lv1Transitions = [], headings = new Map(), lv2Transitions = [];
	for (const transition of transitions) {
		if (!transition.Category) {
			lv1Transitions.push(transition);
		} else {
			lv2Transitions.push(transition);
			headings.set(transition.Category.ResKey, transition.Category);
		}
	}
	return [
		...lv1Transitions,
		...[...headings.values()].map((h) => ({ Caption: h, categoryKey: h.ResKey })),
		...lv2Transitions,
	];
}
