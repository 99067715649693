import ajaxService from 'AjaxService';
import captionService from 'CaptionService';
import errors from 'Errors';
import global from 'Global';

class ChangePasswordService {
	async changePasswordAsync(oldPassword, newPassword) {
		/*! StartNoStringValidationRegion Constructing HTTP request */
		const request = {
			url: global.serviceUri + 'api/user/change_password',
			type: 'post',
			dataType: 'json',
			contentType: 'application/json',
			data: JSON.stringify({
				old_password: oldPassword,
				new_password: newPassword,
			}),
			isSensitive: true,
		};
		/*! EndNoStringValidationRegion */
		try {
			await ajaxService.ajaxAsync(request);
		} catch (error) {
			if (error instanceof errors.RenewSessionError || errors.isDbUpgradeError(error)) {
				throw error;
			} else if (error.status === 403) {
				const json = JSON.parse(error.responseText);
				const errors = json.validation_errors;
				const notifications = [];
				for (const idx in errors) {
					const validationError = errors[idx];
					const errorDescription = validationError.description;
					switch (validationError.aspect) {
						case 0: {
							const notification = new ChangePasswordAlertNotification(
								errorDescription
							);
							notifications.push(notification);

							break;
						}
						case 1: {
							const notification = new ChangePasswordValidationNotification(
								errorDescription,
								ChangePasswordServiceConstants.ChangePasswordFields.OldPassword
							);
							notifications.push(notification);

							break;
						}
						case 2: {
							const notification = new ChangePasswordValidationNotification(
								errorDescription,
								ChangePasswordServiceConstants.ChangePasswordFields.NewPassword
							);
							notifications.push(notification);

							break;
						}
					}
				}

				throw new ChangePasswordServiceError(notifications);
			} else {
				const notification = new ChangePasswordAlertNotification(
					captionService.getString(
						'7f5fb410-11c5-4f00-adbd-471c6fdd1696',
						'An unknown error occurred. Please try again.'
					)
				);
				throw new ChangePasswordServiceError([notification]);
			}
		}
	}
}

export const ChangePasswordServiceConstants = {
	ChangePasswordFields: {
		OldPassword: 'oldPassword',
		NewPassword: 'newPassword',
	},
};

export class ChangePasswordValidationNotification {
	constructor(description, field) {
		this.description = description;
		this.field = field;
	}
}

export class ChangePasswordAlertNotification {
	constructor(description) {
		this.description = description;
	}
}

export class ChangePasswordServiceError extends Error {
	constructor(notifications) {
		/*! SuppressStringValidation Internal exception message */
		super('Error changing password');
		this.name = 'ChangePasswordServiceError';
		this.notifications = notifications;
	}
}

export default new ChangePasswordService();
