import type { RulesetMetadata } from "./RulesetMetadata";

export function extractDerivedRulesets(ruleSets: RulesetMetadata[]): void {
  const baseEntities: Record<string, RulesetMetadata> = {};
  const derivedEntities: RulesetMetadata[] = [];
  ruleSets.forEach((ruleSet) => {
    if (ruleSet.baseEntityName) {
      derivedEntities.push(ruleSet);
    } else {
      baseEntities[ruleSet.entityName] = ruleSet;
    }
  });

  derivedEntities.forEach((ruleSet) => {
    deepMerge(baseEntities[ruleSet.baseEntityName as string], ruleSet);
  });
}

function deepMerge(baseObject: Record<string, unknown>, derivedObject: Record<string, unknown>): void {
  Object.entries(baseObject).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (!Object.hasOwn(derivedObject, key)) {
        derivedObject[key] = value;
      } else {
        const derivedValue = derivedObject[key];
        if (!Array.isArray(derivedValue)) {
          throw new Error(`Expected derivedObject['${key}'] to be an array.`);
        }

        derivedObject[key] = derivedValue.flatMap((item: unknown) => {
          if (isObject(item)) {
            if (hasBaseArrayIndex(item)) {
              return value[item.baseArrayIndex];
            } else if (hasBaseArrayIndexRange(item)) {
              return value.slice(item.baseArrayIndexRange[0], item.baseArrayIndexRange[1] + 1);
            }
          }
          return item;
        });
      }
    } else if (typeof value === "function") {
      derivedObject[key] = value;
    } else if (isObject(value)) {
      if (!Object.hasOwn(derivedObject, key)) {
        derivedObject[key] = {};
      }

      const derivedValue = derivedObject[key];
      if (isObject(derivedValue)) {
        deepMerge(value, derivedValue);
      }
    } else if (!Object.hasOwn(derivedObject, key)) {
      derivedObject[key] = value;
    }
  });
}

function hasBaseArrayIndex(obj: object): obj is { baseArrayIndex: number } {
  return Object.hasOwn(obj, "baseArrayIndex");
}

function hasBaseArrayIndexRange(obj: object): obj is { baseArrayIndexRange: [number, number] } {
  return Object.hasOwn(obj, "baseArrayIndexRange");
}

function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === "object" && value != null;
}
