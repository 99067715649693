import $ from 'jquery';
import ko from 'knockout';
import 'jQueryResizeEvent';

ko.bindingHandlers.gwFooterResizeHandler = {
	init(container, valueAccessor) {
		const $container = $(container);
		const params = valueAccessor();
		const $left = $container.find(params.left);
		const $right = $container.find(params.right);
		const $leftCollapsed = $container.find(params.leftCollapsed);
		const $rightCollapsed = $container.find(params.rightCollapsed);

		const initialMeasures = {
			left: getComputedWidth($left),
			right: getComputedWidth($right),
			leftCollapsed: getComputedWidth($leftCollapsed)
		};

		const onResizeFunction = onContainerResize.bind(null, $container, $left, $right, $leftCollapsed, $rightCollapsed, initialMeasures);

		$left.on('resize', updateMeasures.bind(null, $left, 'left', initialMeasures, onResizeFunction));
		$right.on('resize', updateMeasures.bind(null, $right, 'right', initialMeasures, onResizeFunction));
		$leftCollapsed.on('resize', updateMeasures.bind(null, $leftCollapsed, 'leftCollapsed', initialMeasures, onResizeFunction));

		$container.on('resize', onResizeFunction);
		onResizeFunction();
	}
};

function getComputedWidth($element) {
	return $element[0].clientWidth;
}

function updateMeasures($element, propertyName, footerMeasures, refresh) {
	footerMeasures[propertyName] = getComputedWidth($element) || footerMeasures[propertyName];
	refresh();
}

function onContainerResize($container, $left, $right, $leftCollapsed, $rightCollapsed, footerMeasures) {
	const newContainerWidth = getComputedWidth($container);
	if (newContainerWidth >= footerMeasures.right + footerMeasures.left) {
		$left.show();
		$right.show();
		$leftCollapsed.hide();
		$rightCollapsed.hide();
	}
	else if (newContainerWidth >= footerMeasures.right + footerMeasures.leftCollapsed) {
		$left.hide();
		$right.show();
		$leftCollapsed.show();
		$rightCollapsed.hide();
	}
	else {
		$left.hide();
		$right.hide();
		$leftCollapsed.show();
		$rightCollapsed.show();
	}
}
