import $ from 'jquery';
import 'jquery.ui.widget';
import 'jQueryResizeEvent';

function WidgetFactory() {
	this.defaultBaseWidget = $.Widget;
}

WidgetFactory.prototype.select = (element, includeSelf, includeScriptTemplates) => {
	const $element = $(element);
	const selector = '[data-role^=gw], [data-bind^=component]';
	let $result = $element.find(selector);
	if (includeScriptTemplates) {
		$element.find('script[type="text/html"]').each((_, element) => {
			$result = $(element.innerHTML).find(selector).addBack(selector).add($result);
		});
	}

	if (includeSelf) {
		$element.each((i, v) => {
			const $v = $(v);
			if ($v.is(selector)) {
				$result = $v.add($result);
			}
		});
	}

	return $result;
};

WidgetFactory.prototype.getComponentName = ($element) => {
	const match = /^component:\s*\{\s*name:\s*['"](\w+)['"]/.exec($element.attr('data-bind'));
	return match ? match[1] : null;
};

WidgetFactory.prototype.getWidgetName = ($element) => {
	return $element.data('role');
};

WidgetFactory.prototype.resizeContainer = (container) => {
	resizeInnerElements(container, null, null, isContainerInRelaxedMode(container));
};

function resizeInnerElements(container, wOffset, hOffset, relaxedMode) {
	const $container = $(container);
	let widthOffset;
	let heightOffset;

	const newWidth = Math.round($container.width());
	const newHeight = Math.round($container.height());

	const minWidth = $container.data('minwidth');
	const minHeight = $container.data('minheight');

	if (
		!relaxedMode &&
		((minWidth && newWidth < minWidth) || (minHeight && newHeight < minHeight))
	) {
		return;
	}

	if (wOffset || hOffset) {
		widthOffset = wOffset;
		heightOffset = hOffset;
	} else {
		let previousWidth = $container.data('prevWidth');
		let previousHeight = $container.data('prevHeight');

		if (!previousWidth || !previousHeight) {
			previousWidth = minWidth;
			previousHeight = minHeight;
		}

		widthOffset = newWidth - previousWidth;
		heightOffset = newHeight - previousHeight;
	}

	$container.data('prevHeight', newHeight);
	$container.data('prevWidth', newWidth);

	if (heightOffset || widthOffset) {
		resizeChildren($container, heightOffset, widthOffset, relaxedMode);
	}
}

function resizeChildren($container, heightOffset, widthOffset, relaxedMode) {
	const childrenCount = $container.data('positioned-children-count');
	const containerID = $container[0].id;

	for (let i = 0; i < childrenCount; i++) {
		const $element = $container.find('#' + containerID + '_' + i);
		if ($element.length > 0 && !$element.data('noResize')) {
			resizeChildElement($element, heightOffset, widthOffset, relaxedMode);
		}
	}
}

function resizeChildElement($element, heightOffset, widthOffset, relaxedMode) {
	let newChildWidth;
	let newChildHeight;
	const anchors = $element.data('anchor') || '';

	if (heightOffset) {
		newChildHeight = applyHeightOffset($element, heightOffset, anchors, relaxedMode);
	}
	if (widthOffset && !relaxedMode) {
		newChildWidth = applyWidthOffset($element, widthOffset, anchors);
	}

	if (newChildHeight || newChildWidth) {
		if ($element.hasClass('g-anchor-container') && !$element.hasClass('gwShellContainer')) {
			resizeInnerElements(
				$element,
				newChildWidth ? widthOffset : null,
				newChildHeight ? heightOffset : null,
				relaxedMode
			);
		}

		if (newChildHeight) {
			$element.height(newChildHeight);
		}

		if (newChildWidth) {
			$element.width(newChildWidth);
		}
	}
}

function applyHeightOffset($element, heightOffset, anchors, relaxedMode) {
	if (anchors.indexOf('Bottom') !== -1) {
		if (anchors.indexOf('Top') !== -1) {
			return $element.height() + heightOffset;
		} else if (!relaxedMode) {
			$element.css('top', parseInt($element.css('top'), 10) + heightOffset);
		}
	} else if (anchors.indexOf('Top') === -1 && !relaxedMode) {
		$element.css('top', parseInt($element.css('top'), 10) + heightOffset / 2);
	}
}

function applyWidthOffset($element, widthOffset, anchors) {
	if (anchors.indexOf('Right') !== -1) {
		if (anchors.indexOf('Left') !== -1) {
			return $element.width() + widthOffset;
		} else {
			$element.css('left', parseInt($element.css('left'), 10) + widthOffset);
		}
	} else if (anchors.indexOf('Left') === -1) {
		$element.css('left', parseInt($element.css('left'), 10) + widthOffset / 2);
	}
}

WidgetFactory.prototype.hookResizableContainer = function (container) {
	if (!isContainerInRelaxedMode(container)) {
		const self = this;
		container.on('resize', () => {
			self.resizeContainer(container);
		});
	}
	this.resizeContainer(container);
};

function isContainerInRelaxedMode(container) {
	return $(container).hasClass('g-relaxed');
}

export default new WidgetFactory();
