import $ from 'jquery';
import ko from 'knockout';
import captionService from 'CaptionService';
import captionPresentationService from 'CaptionPresentationService';
import widgetService from 'WidgetService';
import tooltipService from 'TooltipService';
import shortcutService from 'ShortcutService';

ko.bindingHandlers.gwTooltipCaption = {
	init(element) {
		tooltipService.createTooltipWidget(element);
		shortcutService.registerTooltipsShow();
	},

	update(element, valueAccessor) {
		const boundObject = ko.unwrap(valueAccessor());
		const captionStrings = getCaptionStrings(boundObject, null);

		if (captionStrings.caption) {
			tooltipService.populateTooltip(element, captionStrings.caption);
		}
	}
};

function getLabelForCaption($element, options) {
	let $labelForCaption = $element;
	if (options && options.labelSelector) {
		$labelForCaption = $element.find(options.labelSelector);
	}
	else if (!$element.data('isCaptionLabel')) {
		const $label = widgetService.utils.getLabelForElement($element);
		if ($label.length) {
			$labelForCaption = $label;
		}
	}

	return $labelForCaption;
}

function getCaptionStrings(boundObject, fallback) {
	if (boundObject) {
		if (typeof boundObject === 'string') {
			return captionService.getCaptionFromKey(boundObject, fallback);
		}
		else {
			return captionService.getCaptionFromKey(boundObject.ResKey, boundObject.Fallback || fallback);
		}
	}
}

ko.bindingHandlers.gwBoundCaption = {
	update(element, valueAccessor, allBindings) {
		const $element = $(element);
		const captionStrings = ko.unwrap(valueAccessor());

		if (captionStrings) {
			const options = allBindings.get('gwCaptionOptions');
			updateCaptions($element, captionStrings, options);
		}
	}
};

ko.bindingHandlers.gwCaption = {
	update(element, valueAccessor, allBindings) {
		const $element = $(element);
		const boundObject = ko.unwrap(valueAccessor());
		const captionStrings = getCaptionStrings(boundObject, $element.text());

		if (captionStrings) {
			const options = allBindings.get('gwCaptionOptions');
			updateCaptions($element, captionStrings, options);
		}
	}
};

function hookCaptionResizeIfRequired($element, $labelForCaption, captionStrings) {
	const initializationDataKey = '4a42e8c3-is-caption-initialized';
	if (!$element.data(initializationDataKey)) {
		const captions = [captionStrings.short, captionStrings.medium, captionStrings.long].filter((c) => c);
		if (captions.length > 1) {
			$element.data(initializationDataKey, true);
			$element.on('resize.captions', () => {
				captionPresentationService.resizeCaption($labelForCaption);
			});
			ko.utils.domNodeDisposal.addDisposeCallback($element[0], () => {
				$element.off('resize.captions');
			});
		}
	}
}

function updateCaptions($element, captionStrings, options) {
	const $labelForCaption = getLabelForCaption($element, options);
	$labelForCaption.data('caption-detail', captionStrings);
	hookCaptionResizeIfRequired($element, $labelForCaption, captionStrings);
	captionPresentationService.resizeCaption($labelForCaption);
}
