import errors from 'Errors';
import { capitalizeWords } from 'StringUtils';
import { getAvailableResult, mapData, mapDataOne, operationTypes } from './Utils';

const lengthCore = getMapperWithZeroArgs('Length', (v) => v.length);
const toLowerCore = getMapperWithZeroArgs('ToLower', (v) => v.toLowerCase());
const toUpperCore = getMapperWithZeroArgs('ToUpper', (v) => v.toUpperCase());
const urlEncodeCore = getMapperWithZeroArgs('UrlEncode', encodeURIComponent);

function getMapperWithZeroArgs(description, valueMapper) {
	return (value) => {
		if (value == null) {
			return getAvailableResult(null);
		}

		ensureStringValue(description, value);
		return getAvailableResult(valueMapper(value));
	};
}

function ensureStringValue(description, value) {
	if (typeof value !== 'string') {
		description = capitalizeWords(description);
		throw new errors.DependencyError(`${description}() can only be used on a string.`);
	}
}

const stringMethodDecorationInfo = Object.freeze({
	operationType: operationTypes.Method,
	operandCount: 1,
});

function length(string, data, context) {
	return mapDataOne(string, data, context, lengthCore, stringMethodDecorationInfo);
}

function toLower(string, data, context) {
	return mapDataOne(string, data, context, toLowerCore, stringMethodDecorationInfo);
}

function toUpper(string, data, context) {
	return mapDataOne(string, data, context, toUpperCore, stringMethodDecorationInfo);
}

function urlEncode(string, data, context) {
	return mapDataOne(string, data, context, urlEncodeCore, stringMethodDecorationInfo);
}

const indexOfCore = getMapperWithOneArg('indexOf', null);
const endsWithCore = getMapperWithOneArg('endsWith', false);
const startsWithCore = getMapperWithOneArg('startsWith', false);

function getMapperWithOneArg(fnName, nullValue) {
	return ([string, searchString]) => {
		if (string == null) {
			return getAvailableResult(nullValue);
		}

		ensureStringValue(fnName, string);
		return getAvailableResult(string[fnName](searchString));
	};
}

const indexOfDecorationInfo = Object.freeze({
	operationType: operationTypes.Method,
	operandCount: 2,
});

function indexOf(string, searchString, data, context) {
	return mapData([string, searchString], data, context, indexOfCore, indexOfDecorationInfo);
}

const startsEndsWithDecorationInfo = Object.freeze({
	operationType: operationTypes.Method,
	operandCount: 2,
	booleanResult: true,
});

function endsWith(string, searchString, data, context) {
	return mapData(
		[string, searchString],
		data,
		context,
		endsWithCore,
		startsEndsWithDecorationInfo
	);
}

function startsWith(string, searchString, data, context) {
	return mapData(
		[string, searchString],
		data,
		context,
		startsWithCore,
		startsEndsWithDecorationInfo
	);
}

const substringDecorationInfo = Object.freeze({
	operationType: operationTypes.Method,
	operandCount: 3,
});

function substring(string, start, end, data, context) {
	return mapData([string, start, end], data, context, substringCore, substringDecorationInfo);
}

function substringCore([string, start, end]) {
	if (string == null) {
		return getAvailableResult(null);
	}

	ensureStringValue('Substring', string);
	if (start == null) {
		start = 0;
	}

	return getAvailableResult(string.substring(start, end != null ? start + end : undefined));
}

export default { endsWith, indexOf, length, startsWith, substring, toLower, toUpper, urlEncode };
