import Promise from 'bluebird';
import constants from 'Constants';
import DynamicMenuViewModel from 'DynamicMenuViewModel';
import buttonVisibilityAndHoveringStrategy from 'gwDynamicMenu.PopoverButtonStrategy';
import $ from 'jQueryExtensions';
import ko from 'knockout';
import { loadTemplateAsync } from 'ModuleLoader';
import _ from 'underscore';
import widgetService from 'WidgetService';
import 'bootstrap';
import 'Widgets/gwLoadingAnimation';

function hideAllPopovers(notSelector) {
	$('.popover-shown').not(notSelector).each((index, widgetButton) => {
		$(widgetButton).popover('hide');
	});
}

function hideAllPopoversButParents($widgetButton) {
	const wrappersToKeepOpen = getWrappersToKeepOpen($widgetButton);
	hideAllPopovers(wrappersToKeepOpen);
}

function getWrappersToKeepOpen($widgetButton) {
	const $parentPopover = $widgetButton.parents('.popover');

	if ($parentPopover.length) {
		const popoverData = $parentPopover.data('bs.popover');
		const $parentPopoverButton = popoverData.$element;
		const buttonsToKeepInOpenState = [$parentPopoverButton[0]];

		return _.union(buttonsToKeepInOpenState, getWrappersToKeepOpen($parentPopoverButton));
	}

	return [];
}

function hideAllSubmenus() {
	$('.dropdown-submenu.open:not(.keep-open)').each((_, element) => {
		$(element).removeClass('open');
	});
}

function hideAllDropdowns() {
	$('.dropdown.open > a[data-toggle="dropdown"]').each((_, element) => {
		$(element).dropdown('toggle');
	});
	$('.dropdown-menu.open').each((_, element) => {
		$(element).removeClass('open');
	});
}

function resizeHandler() {
	$('.popover-shown').each((_, element) => {
		const $element = $(element);
		const popover = $element.data('bs.popover');
		if (popover) {
			const $tip = popover.tip();
			const placement = calculatePlacementFromTip($tip);
			setPopoverMaxHeight($element, placement);
			adjustPopoverPlacement($element, popover, $tip, placement);
		}
	});
}

function calculatePlacementFromTip($tip) {
	/*! StartNoStringValidationRegion Positions not captions */
	return $tip.hasClass('bottom') ? 'bottom' :
		$tip.hasClass('top') ? 'top' :
			$tip.hasClass('right') ? 'right' :
				'left';
	/*! EndNoStringValidationRegion */
}

//This method is the same as the positioning calculation for Tooltip.prototype.show in bootstrap.js. Please keep the calculations in sync.
function adjustPopoverPlacement($element, popover, $tip, placement) {
	const pos = popover.getPosition();
	const actualWidth = $tip[0].offsetWidth;
	const actualHeight = $tip[0].offsetHeight;
	const $window = $(window);
	const orgPlacement = placement;

	if (!popover.options || (popover.options && !popover.options.static)) {
		const areaAboveElement = $element.offset().top - $window.scrollTop();
		const areaBelowElement = $window.height() - $element.outerHeight() - areaAboveElement;
		const areaLeftOfElement = $element.offset().left - $window.scrollLeft();
		const areaRightOfElement = $window.width() - $element.outerWidth() - areaLeftOfElement;
		/*! StartNoStringValidationRegion Positions not captions */
		placement =
			placement === 'bottom' && areaAboveElement > areaBelowElement ? 'top' :
				placement === 'top' && areaAboveElement < areaBelowElement ? 'bottom' :
					placement === 'right' && areaLeftOfElement > areaRightOfElement ? 'left' :
						placement === 'left' && areaLeftOfElement < areaRightOfElement ? 'right' :
							placement;
		/*! EndNoStringValidationRegion */
	}

	$tip
		.removeClass(orgPlacement)
		.addClass(placement);

	const calculatedOffset = popover.getCalculatedOffset(placement, pos, actualWidth, actualHeight);
	popover.applyPlacement(calculatedOffset, placement);
}

function setPopoverMaxHeight($element, placement) {
	/*! SuppressStringValidation No captions here */
	let maxHeight = 'none';
	const $window = $(window);
	const $popoverTitle = $('.popover-title');
	const popoverTitleHeight = $popoverTitle.is(':visible') ? $popoverTitle.outerHeight() : 0;

	if (placement === 'bottom' || placement === 'top') {
		const areaAboveElement = $element.offset().top - $window.scrollTop();
		const areaBelowElement = $window.height() - $element.outerHeight() - areaAboveElement;
		maxHeight = areaAboveElement > areaBelowElement ? areaAboveElement - 20 : areaBelowElement - 20;
	}
	else if (placement === 'left' || placement === 'right') {
		maxHeight = $window.height() - 20;
	}

	const dynamicMenuMaxHeight = maxHeight !== 'none' ? maxHeight - popoverTitleHeight : maxHeight;
	const popoverContentMaxHeight = maxHeight !== 'none' ? dynamicMenuMaxHeight - 5 : maxHeight;
	$('.gwDynamicMenu').css('max-height', dynamicMenuMaxHeight);
	$('.popover-content').css('max-height', popoverContentMaxHeight);
}

function scrollPopoverToActiveElementIfNecessary($element) {
	const popover = $element.data('bs.popover');
	if (popover) {
		const $tip = popover.tip();
		if ($tip) {
			const $popoverContent = $tip.find('div.popover-content');
			if ($popoverContent.length > 0) {
				const fullPopoverHeight = $popoverContent[0].scrollHeight;
				const visiblePopoverHeight = $popoverContent.height();
				if (fullPopoverHeight > visiblePopoverHeight) { // ie is scrolling
					const $activeMenuItem = $popoverContent.find('li.active');
					if ($activeMenuItem.length > 0) {
						const currentScrollTop = $popoverContent.scrollTop();
						const currentScrollBottom = currentScrollTop + visiblePopoverHeight;

						const $firstMenuItem = $popoverContent.find('li:first');
						const firstItemRelativeTop = $firstMenuItem.position().top;
						const itemRelativeTop = $activeMenuItem.position().top;
						const itemHeight = $activeMenuItem.height();

						const itemTop = itemRelativeTop - firstItemRelativeTop;
						const itemBottom = itemTop + itemHeight;

						if (itemTop < currentScrollTop) {
							$popoverContent.scrollTop(itemTop);
						}
						else if (itemBottom > currentScrollBottom) {
							$popoverContent.scrollTop(itemBottom - visiblePopoverHeight);
						}
					}
				}
			}
		}
	}
}

$(document)
	.on('click hideDynamicMenu.glow', (e) => {
		const $target = $(e.target);
		if (shouldDismissMenu(e)) {
			hideAllPopovers();
			hideAllSubmenus();
		}
		else {
			hideIfNotInPopover($target, true);
		}
	})
	.on('show.bs.dropdown', '.dropdown', (e) => {
		hideIfNotInPopover($(e.target), true);
	})
	.on('click', '.g-dropdown-arrow', (e) => {
		hideIfNotInPopover($(e.target), true);
	})
	.on('click', '.popover-title', (e) => {
		e.preventDefault();
		e.stopPropagation();
	})
	.on('click', '.popover-title-button', (e) => {
		e.preventDefault();
		e.stopPropagation();
		$(e.target).parents('.popover').popover('hide');
	});

document.addEventListener('scroll', (e) => {
	if (shouldDismissMenu(e)) {
		hideIfNotInPopover($(e.target), true);
	}
}, true);

function shouldDismissMenu(e) {
	const $target = $(e.target);
	return !popoverPreventDismiss.some((preventDismissClass) => {
		return $target.hasClass(preventDismissClass);
	});
}

function hideIfNotInPopover($target, hideSubmenus) {
	if (!(buttonVisibilityAndHoveringStrategy && buttonVisibilityAndHoveringStrategy.isHoveringOverPopover()) &&
		!$target.hasClass('popover-source') &&
		$target.closest('.popover').length <= 0) {
		hideAllPopovers();
		if (hideSubmenus) {
			hideAllSubmenus();
		}
	}
}

/*! StartNoStringValidationRegion Class selectors not captions */
const popoverPreventDismiss = [
	'g-does-not-close-popover',
	'g-click-stop-propagation',
	'popover',
	'g-dynamic-menu-list',
	'dropdown-submenu',
	'dropdown-menu',
	'popover-content',
	'arrow',
	'loggedin-user',
	'loggedin-img',
	'g-loggedin-info-section',
	'dropdown-header',
	'divider',
	'disabled',
	'g-favorites-wrap',
	'g-page-padding',
];
/*! EndNoStringValidationRegion */

const titleTemplate = '<div><span></span><div class="popover-title-button"><i class="icon-times"></i></div></div>';
const contentTemplate =
	'<div class="gwDynamicMenu">' +
	'	<!-- ko if: $dynamicMenu.statusCaption() -->' +
	'		<ul><li><span data-bind="css: $dynamicMenu.statusCaption().css, text: $dynamicMenu.statusCaption().text"></span></li></ul>' +
	'	<!-- /ko -->' +
	'	<!-- ko if: !$dynamicMenu.statusCaption() && !$dynamicMenu.loaded() -->' +
	'		<ul><li><span><span data-bind="component: { name: \'gwLoadingAnimation\', params: { loadingText: $dynamicMenu.loadingCaption } }"></span></span></li></ul>' +
	'	<!-- /ko -->' +
	'	<!-- ko if: !$dynamicMenu.statusCaption() && $dynamicMenu.loaded() -->' +
	'		<ul class="g-dynamic-menu-list" data-bind="template: { name: $dynamicMenu.itemTemplate, foreach: $dynamicMenu.menuItems }"></ul>' +
	'	<!-- /ko -->' +
	'</div>';

ko.bindingHandlers.gwDynamicMenu = {
	init(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		const options = valueAccessor();

		const $template = $(contentTemplate);
		if (options.menuCssClass) {
			$template.find('ul.g-dynamic-menu-list').addClass(options.menuCssClass);
		}

		const $element = $(element);
		let placement;
		if (!options.static) {
			/*! SuppressStringValidation No captions here */
			placement = options.placement ? options.placement + ' auto' : 'bottom auto';
		} else {
			placement = options.placement;
		}

		let container = options.container && $element.closest(options.container);

		if (!container || container.length === 0) {
			container = $element.getFocusScope();
		}

		$element
			.popover({
				trigger: 'manual',
				container,
				html: true,
				title: getTitle(options.title),
				placement,
				static: options.static,
				content: $template[0].outerHTML,
				animation: false,
				viewport: {
					selector: 'body',
					padding: constants.Menus.IsPopoverFullScreen ? 0 : 10
				}
			})
			.on('click', onElementClick.bind(null, $template, bindingContext, options))
			.on('show.bs.popover', onElementPopoverShow.bind(null, valueAccessor, placement))
			.on('hide.bs.popover', onElementPopoverHide.bind(null, options.onHide))
			.on('shown.bs.popover', onElementPopoverShown.bind(null, options))
			.on('hidden.bs.popover', onElementPopoverHidden.bind(null, options))
			.on('hide.gwDynamicMenu', onElementHide)
			.on('scrollToActiveItem.gwDynamicMenu', onElementScrollToActiveItem);

		const $tip = $element.data('bs.popover')
			.tip()
			.addClass('hideable-dropdown-menu');

		if (options.popoverCssClass) {
			$tip.addClass(options.popoverCssClass);
		}

		if (buttonVisibilityAndHoveringStrategy) {
			buttonVisibilityAndHoveringStrategy.decorate($element);
		}

		let observer;
		if (options.templateParentSelector) {
			const parent = $element.closest(options.templateParentSelector)[0];
			if (parent) {
				observer = new MutationObserver(() => {
					if (canHidePopover($element)) {
						const placement = calculatePlacementFromTip($tip);
						const popover = $element.data('bs.popover');
						adjustPopoverPlacement($element, popover, $tip, placement);
					}
				});

				observer.observe(parent, { childList: true });
			}
		}

		ko.utils.domNodeDisposal.addDisposeCallback(element, (element) => {
			dispose(element);
			if (observer) {
				observer.disconnect();
			}
		});
	},
	update(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		const options = valueAccessor();

		if (ko.isObservable(options.isShown)) {
			const $element = $(element);
			const isShown = options.isShown();
			if (isShown && canShowPopover($element)) {
				const $template = $(contentTemplate);
				showPopover($element, $template, bindingContext, options);
			}
			else if (!isShown && canHidePopover($element)) {
				hidePopover($element);
			}
		}
	}
};

function getTitle(title) {
	if (title) {
		const $titleTemplate = $(titleTemplate);
		$titleTemplate.find('span').text(title);
		return $titleTemplate[0].outerHTML;
	}
}

function onElementClick($template, bindingContext, options, e) {
	e.preventDefault();
	e.stopPropagation();
	const $element = $(e.delegateTarget);
	if (canShowPopover($element)) {
		showPopover($element, $template, bindingContext, options);
	}
	else if (canHidePopover($element)) {
		hidePopover($element);
	}
}

function canShowPopover($element) {
	return !$element.hasClass('popover-shown') && !$element.hasClass('disabled');
}

function canHidePopover($element) {
	return $element.hasClass('popover-shown') || $element.hasClass('disabled');
}

function showPopover($element, $template, bindingContext, options) {
	const templatesLoaded = ko.observable(false);
	const vmOptions = $.extend({}, options);
	vmOptions.loaded = ko.pureComputed(() => {
		return (!options.loaded || options.loaded()) && templatesLoaded();
	});

	const childBindingContext = bindingContext.createChildContext(bindingContext.$data);
	childBindingContext.$dynamicMenu = new DynamicMenuViewModel(vmOptions);

	const popover = $element.data('bs.popover');
	const $content = $($template[0].outerHTML);

	$content.addClass('hide').appendTo('body'); // Some widgets like gwImageEngine require content to be on the DOM to behave properly
	ko.applyBindings(childBindingContext, $content[0]);
	popover.options.content = $content[0];

	hideAllPopoversButParents($element);
	hideAllSubmenus();
	hideAllDropdowns();

	$element.addClass('popover-source');
	$element.popover('show');
	$content.removeClass('hide');

	loadTemplatesAsync(childBindingContext.$dynamicMenu)
		.then(() => {
			templatesLoaded(true);
		});
}

function loadTemplatesAsync(viewModel) {
	const itemPromise = viewModel.items.loaded ? ko.waitForValueAsync(viewModel.items.loaded, true) : Promise.resolve([]);

	return itemPromise.then(() => {
		let allTemplates;
		if (_.isFunction(viewModel.itemTemplate)) {
			allTemplates = _.map(viewModel.items(), viewModel.itemTemplate);
		}
		else {
			allTemplates = _
				.union(
					[viewModel.itemTemplate],
					_.pluck(viewModel.items(), 'templateID'));
		}

		const templatesToLoad = allTemplates.filter((id) => id && !templateIsLoaded(id));

		return Promise.map(templatesToLoad, (templateName) => {
			/*! SuppressStringValidation No captions here */
			return loadTemplateAsync('MenuItems/' + templateName + '.ejs')
				.tap((template) => widgetService.preloadTemplateWidgetsAsync(template))
				.then((template) => {
					$(template).appendTo(document.body);
				});
		});
	});
}

function templateIsLoaded(templateName) {
	return !!$('#' + templateName).length;
}

function hidePopover($element) {
	$element.popover('hide');
}

function onElementPopoverShow(valueAccessor, placement, e) {
	const options = valueAccessor();
	if (options.onShow) {
		options.onShow.call(null, e);
	}

	const $element = $(e.delegateTarget);
	const popover = $element.data('bs.popover');
	const $tip = popover.tip();
	$tip.addClass('g-fade');

	if (!constants.Menus.IsPopoverFullScreen) {
		const autoToken = /\s?auto?\s?/i;
		const placementWithoutAuto = placement.replace(autoToken, '');
		setPopoverMaxHeight($element, placementWithoutAuto);

		$tip
			.off('resize.gwDynamicMenu')
			.on('resize.gwDynamicMenu', resizeHandler);

		$(window)
			.off('resize.gwDynamicMenu')
			.on('resize.gwDynamicMenu', resizeHandler);
	}
}

function onElementPopoverHide(onHide, e) {
	const $element = $(e.delegateTarget);
	const popover = $element.data('bs.popover');
	const $tip = popover.tip();
	$tip.off('resize.gwDynamicMenu');

	if (onHide) {
		onHide($element[0]);
	}

	if (popover) {
		const currentContent = $tip.find('.popover-content :first-child')[0];
		if (currentContent) {
			const context = ko.contextFor(currentContent);
			if (context && context.$dynamicMenu) {
				context.$dynamicMenu.dispose();
			}
			ko.cleanNode(currentContent);
		}

		setTimeout($tip.remove.bind($tip), 250); // Wait for ease out animation to end
	}
}

function onElementPopoverShown(options, e) {
	const $element = $(e.delegateTarget);
	$element.data('bs.popover').tip().addClass('fade');
	$element.addClass('popover-shown');

	if (options.onShown) {
		options.onShown($element[0]);
	}
	if (options.isShown) {
		options.isShown(true);
	}
}

function onElementPopoverHidden(options, e) {
	const $element = $(e.delegateTarget);
	if (options.onHidden) {
		options.onHidden($element[0]);
	}

	$element.removeClass('popover-shown');
	if (options.isShown) {
		options.isShown(false);
	}
}

function onElementHide(e) {
	$(e.delegateTarget).popover('hide');
}

function onElementScrollToActiveItem(e) {
	scrollPopoverToActiveElementIfNecessary($(e.delegateTarget));
}

function dispose(element) {
	$(element).popover('destroy');
}
