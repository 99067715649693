import captionService from "CaptionService";
import { getInterfaceName, getPrimaryKey } from "EntityExtensions";
import type { NotificationType } from "NotificationType";
import type breeze from "breeze-client";

export default class RuleValidationResult {
  readonly Text: string;
  readonly Level: NotificationType;
  entityName?: string;
  entityPK?: string;
  propertyName?: string;
  caption?: string;
  controlAlertId?: string;

  constructor(text: string, level: NotificationType) {
    this.Text = text;
    this.Level = level;
  }

  setEntityAndPropertyName<T extends breeze.Entity>(entity: T, propertyName: string): void {
    this.entityName = getInterfaceName(entity);
    this.entityPK = getPrimaryKey(entity);
    this.propertyName = propertyName;
    this.caption = captionService.getCaptionForProperty(this.entityName, this.propertyName).caption;
  }
}
