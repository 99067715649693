import ajaxService from 'AjaxService';
import { AsyncDictionary } from 'AsyncDictionary';
import Promise from 'bluebird';
import breeze from 'breeze-client';
import dataService from 'DataService';
import entitySaveService from 'EntitySaveService';
import global from 'Global';
import $ from 'jquery';
import ko from 'knockout';
import { addODataUriParams, ODataType, oDataValue } from 'ODataUtils';
import registryService from 'RegistryService';
import { SaveEntityError, SaveEntityErrorType } from 'SaveEntityError';
import sortProvider from 'SortProvider';
import { tryGetGuidOrValue } from 'StringUtils';
import _ from 'underscore';
import userSession from 'UserSession';

function ConfigurationService() {
	this._isConfigurationTmplEnabled = null;
	this._isConfigurationAllowedCache = new AsyncDictionary();
}

ConfigurationService.prototype.isConfigurationAllowedAsync = async function (configurationRight) {
	return await this._isConfigurationAllowedCache.getOrAddAsync(configurationRight, isConfigurationRightAllowedAsync);
};

ConfigurationService.prototype.hasClientConfigurationsAsync = async (parentID) => {
	const uri = addODataUriParams('BPM/HasClientConfigurations', {
		parentID: oDataValue(parentID, ODataType.Guid),
	});
	const result = await dataService.getAsync(uri);
	return result && result.value;
};

ConfigurationService.prototype.getIsConfigurationEnabledAsync = async () => {
	return userSession.sessionData().userType === userSession.UserType.Staff
		? await getGlowConfigurationTmplEnabledAsync()
		: false;
};

ConfigurationService.prototype.getConfigurationItemDataAsync = async (
	configurationKey,
	configurationType,
	parentID,
	configurationTmplPK,
	clientPK
) => {
	const uri = addODataUriParams('BPM/GetConfigurationItemData', {
		configurationKey: oDataValue(configurationKey),
		configurationType: oDataValue(configurationType),
		parentID: oDataValue(parentID, ODataType.Guid),
		configurationTmplPK: oDataValue(configurationTmplPK, ODataType.Guid),
		clientPK: oDataValue(clientPK, ODataType.Guid),
	});

	const result = await dataService.getAsync(uri);
	return result && result.value;
};

ConfigurationService.prototype.getConfigurationTmplsAsync = async (parentID, filterPredicates, sortInfos) => {
	const entityType = 'IBPMConfigurationTmpl';
	const entityManager = await breeze.EntityManager.forEntityTypeAsync(entityType);
	let query = new breeze.EntityQuery('BPMConfigurationTmpls')
		.expand('Client,Company,Branch,Department')
		.where(filterPredicates)
		.where('VCT_ParentID', '==', tryGetGuidOrValue(parentID));

	const orderBys = sortProvider.constructOrderByClause(sortInfos) || [];
	if (orderBys.length) {
		query = query.orderBy(orderBys);
	}

	const value = await entityManager.executeQuery(query);
	return value && value.results;
};

ConfigurationService.prototype.createConfigurationTmplAsync = async (parentID, parentTableCode, additionalValues) => {
	const entityType = 'IBPMConfigurationTmpl';
	const initialValues = $.extend({}, additionalValues, { VCT_ParentID: tryGetGuidOrValue(parentID), VCT_ParentTableCode: parentTableCode });

	const entityManager = await breeze.EntityManager.forEntityTypeAsync(entityType);
	return entityManager.createEntityWithKeyAsync(entityType)
		.setObservables(initialValues);
};

ConfigurationService.prototype.getDataValuesAsync = (configurationTmplPK, configurationKey, configurationType) => {
	const uri = global.serviceUri + 'api/bpm/configuration/dataValues';
	return ajaxService.getAsync(uri, { configurationTmplPK, configurationKey, configurationType });
};

ConfigurationService.prototype.getDataKeyValuePairsAsync = (configurationTmplPK, configurationKey, configurationType) => {
	const uri = global.serviceUri + 'api/bpm/configuration/dataKeyValuePairs';
	return ajaxService.getAsync(uri, { configurationTmplPK, configurationKey, configurationType });
};

ConfigurationService.prototype.isConfigurationTmplEnabled = function () {
	const self = this;
	return ko.pureComputed(() => {
		if (userSession.sessionData().userType === userSession.UserType.Staff) {
			return isConfigurationTmplEnabled(self);
		}
		else {
			return false;
		}
	});
};

ConfigurationService.prototype.getDefaultTemplateAsync = async function (parentID, parentTableCode) {
	const self = this;

	const configurationTmpl = await this.getConfigurationTemplateAsync(parentID);
	return (!configurationTmpl ? createAndSaveConfigurationTmplAsync(self, parentID, parentTableCode) : configurationTmpl);
};

ConfigurationService.prototype.getConfigurationTemplateAsync = async (parentID, filters) => {
	const entityType = 'IBPMConfigurationTmpl';
	const entityManager = await breeze.EntityManager.forEntityTypeAsync(entityType);
	let query = new breeze.EntityQuery('BPMConfigurationTmpls')
		.where('VCT_ParentID', breeze.FilterQueryOp.Equals, tryGetGuidOrValue(parentID));

	if (!filters) {
		filters = {
			VCT_OH_Client: null,
			VCT_GC_Company: null,
			VCT_GB_Branch: null,
			VCT_GE_Department: null
		};
	}

	const predicates = _.map(filters, (value, key) => {
		return breeze.Predicate.create(key, breeze.FilterQueryOp.Equals, value);
	});

	if (predicates.length) {
		query = query.where(breeze.Predicate.and(predicates));
	}

	const data = await entityManager.executeQuery(query);
	return data.results[0];
};

ConfigurationService.prototype.getConfigurationTemplateByKeyAsync = async (configurationTmplPK) => {
	const entityType = 'IBPMConfigurationTmpl';
	const entityManager = await breeze.EntityManager.forEntityTypeAsync(entityType);
	const query = new breeze.EntityQuery('BPMConfigurationTmpls')
		.expand('Client,Company,Branch,Department')
		.where('VCT_PK', breeze.FilterQueryOp.Equals, configurationTmplPK);

	const data = await entityManager.executeQuery(query);
	return data.results[0];
};

 ConfigurationService.prototype.saveConfigurationAsync = async (configurationTmplPK, configurationItems) => {
		if (!configurationItems || !configurationItems.length) {
			return;
		}

		const uri = global.serviceUri + 'api/bpm/configuration/saveConfigurationTmpl';
		const model = {
			pk: configurationTmplPK,
			configurationItems,
		};

		return await ajaxService.postAsync(uri, model);
};

ConfigurationService.prototype.deleteConfigurationAsync = async (configurationTmpl, itemsPredicate, isDeleteTmpl) => {
	const entityManager = configurationTmpl.entityAspect.entityManager;
	let query = new breeze.EntityQuery('BPMConfigurationItems')
		.where('VCM_VCT_Template', '==', configurationTmpl.entityAspect.getPrimaryKey());
	if (itemsPredicate) {
		query = query.where(itemsPredicate);
	}

	const data = await entityManager.executeQuery(query);
	const items = data.results;
	const deletePromises = [];
	for (let i = 0; i < items.length; i++) {
		deletePromises.push(items[i].entityAspect.deleteAsync());
	}

	if (isDeleteTmpl) {
		deletePromises.push(configurationTmpl.entityAspect.deleteAsync());
	}

	await Promise.all(deletePromises);
	return await entitySaveService.saveAsync(entityManager);
};

async function isConfigurationRightAllowedAsync(configurationRight) {
	if (global.isPortable() || userSession.sessionData().userType !== userSession.UserType.Staff) {
		return false;
	}

	const uri = `${global.serviceUri}api/bpm/configuration/${configurationRight}/isAllowed`;

	return await ajaxService.getAsync(uri);
}

async function createAndSaveConfigurationTmplAsync(self, parentID, parentTableCode) {
	let configurationTmpl = await self.createConfigurationTmplAsync(parentID, parentTableCode);
	try {
		await entitySaveService.saveAsync(configurationTmpl.entityAspect.entityManager);
		return configurationTmpl;
	} catch (error) {
		if (!(error instanceof SaveEntityError)) {
			throw error;
		}
		if (error.type !== SaveEntityErrorType.SaveValidation) {
			throw error;
		}
		configurationTmpl = await self.getConfigurationTemplateAsync(parentID);

		if (!configurationTmpl) {
			throw error;
		}

		return configurationTmpl;
	}
}

async function getGlowConfigurationTmplEnabledAsync() {
	if (global.isPortable()) {
		return false;
	}

	return await registryService.getValueAsync('GlowConfigurationTmplEnabled');
}

function isConfigurationTmplEnabled(self) {
	let observable = self._isConfigurationTmplEnabled;
	if (!observable) {
		self._isConfigurationTmplEnabled = observable = ko.lazyObservable(
			async () => {
				const value = await getGlowConfigurationTmplEnabledAsync();
				observable(value);
			},
			null,
			false);
	}

	return observable();
}

export default new ConfigurationService();
