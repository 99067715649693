import buttonHelper from 'ButtonHelper';
import global from 'Global';
import imageService from 'ImageService';
import 'Shared/KOCaptionBindings';
import 'Shared/KOValidationBindings';
import { isNullOrWhitespace, isNonEmptyString } from 'StringUtils';
import 'TooltipBindings';
import tooltipService from 'TooltipService';
import userSession from 'UserSession';
import widgetService from 'WidgetService';
import Promise from 'bluebird';
import 'gwDisplayOption';
import 'gwDynamicMenu';
import 'gwFooterResizeHandler';
import 'gwReadOnly';
import $ from 'jquery';
import ko from 'knockout';

function getImageDimension(element, parent, dimensionName) {
	let value = element.attr(dimensionName);

	if (!value) {
		value = element[dimensionName]();

		if (!value && parent) {
			value = parent[dimensionName]();
		}
	}

	return value;
}

ko.bindingHandlers.gwImageEngine = {
	init(element, valueAccessor, allBindings, viewModel) {
		const $element = $(element);

		$element.on('load', onImageLoaded.bind(element, viewModel));
	},
	update(element, valueAccessor) {
		const show = ($element) => {
			$element.css('visibility', '');
		};

		const hide = ($element) => {
			$element.css('visibility', 'hidden');
		};

		updateImageEngineBinding(element, valueAccessor, show, hide);
	}
};

function onImageLoaded(vm) {
	if (ko.isObservable(vm.imageLoaded)) {
		vm.imageLoaded(true);
	}
}

function updateImageEngineBinding(element, valueAccessor, show, hide) {
	const $element = $(element);
	const options = valueAccessor();
	const imagePk = options ? ko.utils.unwrapObservable(options.pk) : null;
	if (!isNullOrWhitespace(imagePk)) {
		show($element);

		const uri = imageService.getImageUri(imagePk);

		if (isNonEmptyString(uri)) {
			$element.attr('src', uri);
			const parent = $element.parent();
			const resize = ko.utils.unwrapObservable(options.resize);
			if (resize !== false) {
				/*! StartNoStringValidationRegion Attribute names. */
				const size = {
					height: getImageDimension($element, parent, 'height'),
					width: getImageDimension($element, parent, 'width')
				};
				$element.css({
					'height': size.height + 'px',
					'width': size.width + 'px',
					'object-fit': 'contain'
				});
				/*! EndNoStringValidationRegion */
			}
		}
	} else if (!options.notHideInMissingImagePK) {
		hide($element);
	}
}

ko.bindingHandlers.gwSelectOnFocus = {
	init(element) {
		$(element).on('focusin', (event) => {
			const input = event?.currentTarget;
			const selectAllText = () => {
				input.selectionStart = 0;
				if (input.value) {
					input.selectionEnd = input.value.length;
				}
			};
			selectAllText();
		});
	}
};

(() => {
	ko.bindingHandlers.gwTextOverflowPopover = {
		init(element, valueAccessor) {
			const options = $.extend({
				trigger: 'manual',
				container: 'body',
				html: true,
				timer: null
			}, ko.unwrap(valueAccessor()));

			const hide = () => {
				hidePopover(element);
			};

			const popover = $(element)
				.popover(options)
				.on('mousemove', (e) => {
					if ($(e?.currentTarget).data('hasTextOverflow')) {
						showPopover(e?.currentTarget, e);
					}
				})
				.on('mouseleave', hide)
				.on('remove', destroyPopover)
				.data('bs.popover');

			popover.tip()
				.addClass('gwTextOverflowPopover')
				.on('mouseover', () => {
					clearTimeout(popover.options.timer);
				})
				.on('mouseleave', hide);
		},
		update(element) {
			const value = hasTextOverflow(element);
			$(element).data('hasTextOverflow', value);
		}
	};

	function hasTextOverflow(element) {
		let hasOverflow = false;
		const $element = $(element);
		const popover = $(element).data('bs.popover');
		const $selector = isNonEmptyString(popover.options.cssSelector) ? $element.find(popover.options.cssSelector) : $element;
		$selector.each((i, elem) => {
			if (elem.scrollWidth > ($(elem).innerWidth() + 1)) {
				hasOverflow = true;
				return false;
			}
		});

		return hasOverflow;
	}

	function destroyPopover(event) {
		$(event?.currentTarget).popover('destroy');
	}

	function hidePopover(element) {
		const popover = $(element).data('bs.popover');
		if (popover) {
			clearTimeout(popover.options.timer);

			popover.options.timer = setTimeout(() => {
				popover.options.content = null;
				$(element).popover('hide');
			}, 350);
		}
	}

	function showPopover(element, event) {
		const $element = $(element);
		const popover = $element.data('bs.popover');
		clearTimeout(popover.options.timer);

		popover.options.timer = setTimeout(
			() => {
				if (!popover.$tip) {
					return;
				}

				const offset = { top: event.clientY, left: event.clientX };
				if (popover.options.placement === 'left') {
					offset.left -= popover.$tip.outerWidth();
				}
				popover.getCalculatedOffset = () => {
					if (popover.options.placement === 'left') {
						offset.left -= popover.$tip.outerWidth();
					}

					return offset;
				};

				if (!popover.$tip.is(':visible')) {
					popover.options.content = $element.html();
					$element.popover('show');
				} else {
					popover.applyPlacement(offset);
				}
			},
			350);
	}
})();

ko.bindingHandlers.gwButtonState = {
	init(element) {
		buttonHelper.decorate($(element), { isAsync: true });
	},
	update(element, valueAccessor) {
		const value = valueAccessor();
		const isLoading = !!ko.unwrap(value);
		buttonHelper.setLoadingState($(element), isLoading);
	}
};

ko.bindingHandlers.asyncClick = {
	init(element, valueAccessor, allBindings, viewModel, bindingContext) {
		const $element = $(element);
		$element.addClass('g-async-click-active');

		if ($element.is('button')) {
			buttonHelper.decorate($element, { isAsync: true });
		}

		const wrapperFunction = function () {
			tooltipService.hideAll();
			const clickHandler = valueAccessor();
			let result = clickHandler.apply(arguments[0], arguments);
			if (result && result instanceof Promise) {
				buttonHelper.setLoadingState($element, true);
				result = result.finally(() => {
					buttonHelper.setLoadingState($element, false);
				});
			}
		};

		const newValueAccessor = () => {
			return wrapperFunction;
		};

		ko.bindingHandlers.click.init(element, newValueAccessor, allBindings, viewModel, bindingContext);
	}
};

ko.bindingHandlers.gwStopBindings = {
	init() {
		return { controlsDescendantBindings: true };
	}
};

(() => {
	ko.bindingHandlers.gwImageWithFallback = {
		update(element, valueAccessor) {
			const options = valueAccessor();
			const fallback = ko.unwrap(options.fallback);

			// Firefox is caching all GET request, so we have to add a timestamp to our request
			// https://stackoverflow.com/questions/14155480/dynamically-change-image-src-using-jquery-not-working-in-ie-and-firefox
			let src = ko.unwrap(options.src) || fallback;
			if (!options.useCachedImage && src !== fallback) {
				src += '?' + new Date().getTime();
			}

			const $element = $(element).attr('src', src);

			if (fallback) {
				$element.off('error.gwImageWithFallback').on('error.gwImageWithFallback', onImageError(fallback, options.error));
			}
		}
	};

	function onImageError(fallback, error) {
		if (error && typeof error === 'function') {
			return error;
		}

		return function (event) {
			const $element = $(event?.currentTarget);
			if ($element.attr('src') !== fallback) {
				$element.attr('src', fallback);
			}
		};
	}
})();

ko.bindingHandlers.gwWidgify = {
	init(element) {
		widgetService.createGlowWidgetsSync(element, true);
	}
};

ko.bindingHandlers.option = {
	update(element, valueAccessor) {
		const value = ko.utils.unwrapObservable(valueAccessor());
		ko.selectExtensions.writeValue(element, value);
	}
};

// From http://stackoverflow.com/questions/14321012/prevent-event-bubbling-when-using-the-checked-binding-in-knockoutjs
ko.bindingHandlers.stopBubble = {
	init(element) {
		ko.utils.registerEventHandler(element, 'click', (event) => {
			if (event.stopPropagation) {
				event.stopPropagation();
			}
		});
	}
};

/*! StartNoStringValidationRegion (No captions here) */
ko.bindingHandlers.gwResizeVisibilityHelper = {
	after: ['css'],
	update(element, valueAccessor) {
		invokeResizeVisibilityHelper(element, valueAccessor);
	}
};

function invokeResizeVisibilityHelper(element, valueAccessor, retryCount) {
	const $element = $(element);
	const value = ko.unwrap(valueAccessor());

	if (value) {
		if ($element.parent().is(':visible')) {
			$element.trigger('resize');
			$element.removeClass('hideWithSpace');
		} else {
			retryCount = retryCount || 0;
			if (retryCount < 20) {
				setTimeout(() => {
					invokeResizeVisibilityHelper(element, valueAccessor, retryCount + 1);
				},
					50);
			}
		}
	} else {
		$element.addClass('hideWithSpace');
	}
}
/*! EndNoStringValidationRegion */

ko.bindingHandlers.gwDispose = {
	init(element, valueAccessor) {
		ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
			const disposable = ko.unwrap(valueAccessor());
			if (disposable) {
				disposable.dispose();
			}
		});
	}
};

ko.bindingHandlers.gwSubmenu = {
	init(element) {
		const $element = $(element).addClass('dropdown-submenu');
		const $itemAnchor = $element.find('.submenu-arrow').first()
			.addClass('g-does-not-close-popover')
			.attr('href', '#');
		const $chevronDown = $('<i>').addClass('chevron icon-chevron-down');

		$chevronDown.prependTo($itemAnchor);
	}
};

ko.bindingHandlers.blurOnDisposal = {
	init(element) {
		ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
			$(element).trigger('blur');
		});
	}
};

ko.bindingHandlers.gwClick = {
	init(element, valueAccessor) {
		const params = valueAccessor();
		let $element = $(element);
		if (params.parentSelector) {
			$element = $element.closest(params.parentSelector);
		}

		$element.on('click', params.onClick);
	}
};

ko.bindingHandlers.gwPinButton = {
	init(element, valueAccessor, allBindingsAccessor, viewModel) {
		const params = ko.unwrap(valueAccessor());

		if (!global.isPortable() && userSession.isLoggedOn() && ko.unwrap(viewModel.isPinnable)) {
			const $element = $(element);
			if (!$element.parents('.g-overflow-menu').length) {
				const $pinTarget = $element.children('.g-pin-target');
				const $target = $pinTarget.length ? $pinTarget : $element;

				if (!$pinTarget.length && params && params.dontPinToContainer) {
					return;
				}

				const $pinButton = $($('#gwPinButtonTemplate').html());
				$target.append($pinButton).addClass('g-menu-item-target');
				ko.applyBindingsToDescendants(viewModel, $pinButton[0]);
			}
		}
	}
};

ko.bindingHandlers.visibleWithNotify = {
	eventName: 'visibleChanged.koCustomBindings',
	update(element, valueAccessor) {
		const value = ko.utils.unwrapObservable(valueAccessor());
		const isCurrentlyVisible = !(element.style.display === 'none');

		ko.bindingHandlers.visible.update(element, valueAccessor);

		if (value !== isCurrentlyVisible) {
			$(element).trigger(ko.bindingHandlers.visibleWithNotify.eventName);
		}
	}
};

ko.bindingHandlers.gwAppLogo = {
	init(element) {
		element.src = global.appLogo;
	}
};

ko.bindingHandlers.scrollIntoView = {
	update(element, valueAccessor) {
		const value = ko.unwrap(valueAccessor());
		if (value) {
			/*! SuppressStringValidation valid scroll behaviour */
			element.scrollIntoView({ behavior: 'smooth' });
		}
	}
};
