import bindingEvaluator from 'BindingEvaluator';
import Promise from 'bluebird';
import glowMacroProvider from 'GlowMacroProvider';
import { ExpressionVisitor } from 'wtg-expressions';

export default class PropertyInfoVisitor extends ExpressionVisitor {
	constructor(entityType) {
		super();
		this._promise = Promise.resolve(getResult(entityType));
	}

	static getPropertyInfoAsync(entityType, propertyPath) {
		return Promise.try(() => {
			if (!propertyPath) {
				throw new Error('Property path must be specified.');
			}

			const tree = this.getDependencyTree(propertyPath, this.TreeName.Dependency);
			const visitor = new PropertyInfoVisitor(entityType);
			visitor.visit(tree);
			return visitor._promise.then(({ propertyInfo }) => propertyInfo);
		});
	}

	visitPath_part(ctx) {
		this._promise = this._promise.then((result) => {
			if (result.propertyInfo && result.propertyInfo.error) {
				return result;
			} else if (ctx.children.length > 1 && isLastPathPart(ctx)) {
				return getResult();
			} else if (ctx.MACRO()) {
				return getResult(glowMacroProvider.getGlowMacroEntity().entityType);
			} else {
				const { entityType } = result;
				if (!entityType) {
					return getResult();
				}

				const propertyName = ctx.children[0].getText();
				return bindingEvaluator
					.getPropertyInfoAsync(entityType, propertyName)
					.then((propertyInfo) => {
						propertyInfo.parentEntityType = entityType;
						propertyInfo.propertyName = propertyName;
						return getResult(propertyInfo.propertyEntityType, propertyInfo);
					});
			}
		});
	}
}

function getResult(entityType, propertyInfo) {
	return { entityType, propertyInfo };
}

function isLastPathPart(ctx) {
	const { children } = ctx.parentCtx;
	return ctx === children[children.length - 1];
}
