import { type EntityManager } from "BreezeExtensions";
import captionService from "CaptionService";
import dialogService from "DialogService";
import { type EntitySaveResult } from "EntitySaveService";
import errorHandler from "ErrorHandler";
import { FormFlowAbortError } from "Errors";
import { getFormFlowDefinitionAsync } from "FormFlowDefinitionProvider";
import { FormFlowError } from "FormFlowError";
import { FormFlowSession } from "FormFlowSession";
import { type FormFlowDefinition, type FormFlowDefinitionProviderFn } from "FormFlowTypes";
import uiContextProvider, { type FormProviderFn } from "FormFlowUIContextProvider";
import formTemplateService from "FormTemplateService";
import global from "Global";
import log from "Log";
import { NotificationType } from "NotificationType";
import type { TaskShellOptions } from "TaskPresenter";
import toastService from "ToastService";
import { makeStrategy, type FormFlowVariableStrategy } from "VariableStrategyFactory";

export type FormFlowOptions = Partial<TaskShellOptions>;
export class FormFlowInvoker {
  private readonly sessions: FormFlowSession[];

  constructor(sessions: FormFlowSession[]) {
    this.sessions = sessions;
  }

  invokeAsync(
    definitionPK: string,
    argumentValues: unknown[],
    options: FormFlowOptions
  ): Promise<FormFlowInvokerResult> {
    const argumentStrategies = argumentValues.map((x) => makeStrategy(x));
    return this.invokeWithStrategiesAsync(definitionPK, argumentStrategies, options);
  }

  invokeWithStrategiesAsync(
    definitionPK: string,
    argumentStrategies: FormFlowVariableStrategy[],
    options: FormFlowOptions,
    modalDialogOnlyEntityManager?: EntityManager
  ): Promise<FormFlowInvokerResult> {
    const clientPK = options?.clientPK;
    return this.invokeCoreAsync(
      definitionPK,
      argumentStrategies,
      (pk: string) => getFormFlowDefinitionAsync(pk, clientPK),
      (pk: string) => formTemplateService.getFormTemplateAsync(pk, { parentID: definitionPK, clientPK }),
      { ...{ parentID: definitionPK }, ...options } as TaskShellOptions,
      modalDialogOnlyEntityManager
    );
  }

  private async invokeCoreAsync(
    definitionPK: string,
    argumentStrategies: FormFlowVariableStrategy[],
    formFlowDefinitionProviderFn: FormFlowDefinitionProviderFn,
    formProviderFn: FormProviderFn,
    options: TaskShellOptions,
    modalDialogOnlyEntityManager?: EntityManager
  ): Promise<FormFlowInvokerResult> {
    try {
      const definition = await formFlowDefinitionProviderFn(definitionPK);
      const uiContext = options?.isDialog
        ? uiContextProvider.createDialogContext(formProviderFn, options)
        : uiContextProvider.createPageContext(formProviderFn, options);
      const session = FormFlowSession.create(
        definition,
        argumentStrategies,
        formFlowDefinitionProviderFn,
        uiContext,
        modalDialogOnlyEntityManager
      );
      this.sessions.push(session);
      let hasChanges = false;

      const awaiter = (async (): Promise<EntitySaveResult | undefined> => {
        try {
          await session.invokeStartActivityAsync();
          hasChanges = session.hasChanges();
          const saveResult = await session.saveAsync();

          if (hasChanges) {
            const toastMessage = captionService.getString(
              "c7b00380-e72a-46d5-8300-d1600a6a15fb",
              "Changes have been saved."
            );
            toastService.showToastAlertAsync(toastMessage, NotificationType.Success);
          }

          return saveResult;
        } catch (error: unknown) {
          if (error instanceof FormFlowError) {
            await handleErrorAsync(definitionPK, error);
            return;
          }

          if (error instanceof FormFlowAbortError) {
            return;
          }

          throw error;
        } finally {
          const index = this.sessions.indexOf(session);
          this.sessions.splice(index, 1);
          await session.disposeAsync();
          uiContext.dispose();
        }
      })();

      return new FormFlowInvokerResult(awaiter, definition, session);
    } catch (error: unknown) {
      if (error instanceof FormFlowError) {
        await handleErrorAsync(definitionPK, error);
        return new FormFlowInvokerResult(Promise.resolve(undefined));
      }

      throw error;
    }
  }

  hasChanges(): boolean {
    return this.sessions.some((s) => s.hasChanges());
  }

  abort(): void {
    this.sessions.forEach((s) => s.abort());
  }
}

export class FormFlowInvokerResult {
  constructor(
    public awaiter: Promise<EntitySaveResult | undefined>,
    public definition?: FormFlowDefinition,
    public session?: FormFlowSession
  ) {}
}

async function handleErrorAsync(definitionPK: string, error: FormFlowError): Promise<void> {
  log.error(error);
  if (error.isReportable && (!error.isConfigurationError || !global.isPreviewMode())) {
    errorHandler.reportError(error, `FormFlowError, Type=${error.type}, PK=${definitionPK}`);
  }

  const message = error.isConfigurationError ? getMessageForConfigurationError(error) : error.friendlyMessage;

  if (message) {
    const caption = error.friendlyCaption || getFallbackCaption();

    await dialogService.alertAsync(NotificationType.Error, message, caption, {
      overwriteDialog: false,
    });
  }
}

function getMessageForConfigurationError(error: FormFlowError): string {
  return global.isPreviewMode()
    ? captionService.getString(
        "77f288cf-acf2-452f-8d21-2e50b68acf0a",
        "An unexpected error occurred while running a form-flow, and so the form-flow will exit. The error may be caused by an invalid configuration.\r\n{0}{1}",
        error.message,
        getStackTrace(error)
      )
    : captionService.getString(
        "f151fb3a-5d7f-407d-8d33-000724671ba3",
        "An unexpected error occurred while performing that action; the error has been automatically reported. Please contact your system administrator."
      );
}

function getStackTrace(error: FormFlowError): string {
  return error.activityStack ? "\r\n    " + error.activityStack.join("\r\n    ") : "";
}

function getFallbackCaption(): string {
  return global.isPreviewMode()
    ? captionService.getString("8a1541eb-d2a3-4e98-a994-3740459aef5e", "Form-flow error")
    : captionService.getString("ffadadbf-c25a-479d-aff2-17704b72bf12", "Something Went Wrong");
}

export default new FormFlowInvoker([]);
