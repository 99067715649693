import $ from 'jquery';
import 'jquery.capslockstate';

class CapsLockService {
	constructor() {
		this._listeners = [];
		this._capslockstateInitialized = false;
		this._capsOnHandler = () => {
			this._listeners.forEach((listener) => {
				listener(true);
			});
		};
		this._capsOffHandler = () => {
			this._listeners.forEach((listener) => {
				listener(false);
			});
		};
	}

	onCapsChanged(handler) {
		this._listeners.push(handler);
		if (this._listeners.length === 1) {
			if (!this._capslockstateInitialized) {
				$(window).capslockstate();
				this._capslockstateInitialized = true;
			}

			$(window).on('capsOn', this._capsOnHandler);
			$(window).on('capsOff capsUnknown', this._capsOffHandler);
		}

		return () => {
			const idx = this._listeners.indexOf(handler);
			if (idx !== -1) {
				this._listeners.splice(idx, 1);
				if (this._listeners.length === 0) {
					$(window).off('capsOn', this._capsOnHandler);
					$(window).off('capsOff capsUnknown', this._capsOffHandler);
				}
			}
		};
	}

	isCapsOn() {
		/*! SuppressStringValidation Property value */
		return $(window).capslockstate('state') === true;
	}
}

export default new CapsLockService();
