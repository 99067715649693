import captionService from 'CaptionService';

export const customFields = {
	error: () =>
		captionService.getString(
			'2921870e-a1dd-4c95-b40d-efedc1b4252e',
			'Failed to load custom fields'
		),
	invalid: () =>
		captionService.getString(
			'cc739ca8-c0fb-42c5-aeb5-60e386a3e0eb',
			'Please enter a valid value.'
		),
	loading: () =>
		captionService.getString(
			'2f29894b-a52c-461f-acc2-4a303d771dc9',
			'Loading custom fields...'
		),
	restricted: () =>
		captionService.getString(
			'2215781e-fd1d-4af6-a9a2-0aabeb971197',
			'Custom fields have been restricted'
		),
};
