import Promise from 'bluebird';
import constants from 'Constants';
import dependency from 'Dependency2';
import entityExpander from 'EntityExpander';
import ko from 'knockout';
import PropertyVertex from 'PropertyVertex';
import CalculatedPropertyVertex from './CalculatedPropertyVertex';

export default class DependencyGraphStrategy {
	constructor(context) {
		this._calculatedPropertyVertex = undefined;
		this._context = context;
		this._disposed = false;
		this._initialLoader = undefined;
		this._initiallyLoaded = false;
		this._observableSubscription = undefined;
	}

	dispose() {
		this._disposed = true;
		this._calculatedPropertyVertex && this._calculatedPropertyVertex.dispose();
		this._observableSubscription && this._observableSubscription.dispose();
	}

	handleValueChanged(newValue) {
		if (this._observableSubscription) {
			this._observableSubscription.dispose();
			this._observableSubscription = undefined;
		}
		if (ko.isObservable(newValue)) {
			this._observableSubscription = newValue.subscribe(() => {
				if (this._context.getState() !== constants.States.NotLoaded) {
					this.notifyDependentsAsync(false);
				}
			});
		}
	}

	notifyDependentsAsync(loadedOnly) {
		return Promise.try(() => {
			const context = this._context;
			const { property } = context.rule;
			if (property && this._canUpdateDependencies()) {
				return this._dependencyGraph.notifyDependentsAsync(
					new PropertyVertex(context.entity, property),
					loadedOnly
				);
			}
		});
	}

	performInitialLoad() {
		if (this._initialLoader) {
			return this._initialLoader;
		}

		if (!this._canUpdateDependencies()) {
			return;
		}

		if (this._initiallyLoaded) {
			return;
		}

		const context = this._context;
		this._initialLoader = loadDependenciesAsync(context.entity, context.rule).then(
			({ cacheDependencyFunc, dependencies }) => {
				this._initiallyLoaded = true;
				this._initialLoader = undefined;

				if (this._canUpdateDependencies() && (cacheDependencyFunc || dependencies.length)) {
					this._initGraph(cacheDependencyFunc);
				}
			}
		);

		return this._initialLoader;
	}

	get _dependencyGraph() {
		return this._context.entity.entityAspect.entityManager.dependencyGraph;
	}

	_canUpdateDependencies() {
		const { entityState } = this._context.entity.entityAspect;
		return !this.disposed && !entityState.isDeleted() && !entityState.isDetached();
	}

	_initGraph(cacheDependencyFunc) {
		const context = this._context;
		const vertex = new CalculatedPropertyVertex(context, cacheDependencyFunc);
		if (context.rule.property) {
			this._dependencyGraph.addOrReplaceDependencies(
				new PropertyVertex(context.entity, context.rule.property),
				[vertex]
			);
		}
		vertex.wireDependencies();
		this._calculatedPropertyVertex = vertex;
	}
}

function loadDependenciesAsync(entity, rule) {
	const gettingDependencies = entityExpander
		.expandPathsAsync(entity, rule.expandPaths)
		.then(() => rule.getDependenciesAsync(entity))
		.tap((dependencies) => {
			return Promise.map(dependencies, (d) => dependency.getValueAsync(entity, d.expression));
		});

	return Promise.all([
		rule.getCacheDependencyFuncAsync(),
		gettingDependencies,
	]).then(([cacheDependencyFunc, dependencies]) => ({ cacheDependencyFunc, dependencies }));
}
