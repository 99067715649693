import template from 'WidgetTemplates/gwConfigureMenu.html';

function createViewModel(params) {
	return {
		buttonMode: params.buttonMode,
		viewModel: params.configureMenuViewModel
	};
}

/*! StartNoStringValidationRegion No Captions Here */
const componentConfig = {
	template,
	widgetSelector: '>a',
	viewModel: {
		createViewModel
	}
};
/*! EndNoStringValidationRegion */

export default componentConfig;
