import breeze from 'breeze-client';
import captionService from 'CaptionService';
import lookupDisplayModeHelper from 'LookupDisplayModeHelper';
import moment from 'moment';
import Notifications from 'Notifications';
import notificationType from 'NotificationType';
import ruleService from 'RuleService';

async function getChangedFieldValuesAsync(entity, propertyName, localValue, conflictingValue) {
	if (localValue == null || conflictingValue == null) {
		return null;
	}

	const property = entity.entityType.getProperty(propertyName);
	if (property.dataType.isDate) {
		return {
			yours: moment(localValue).toDateTimeTypeString(property.dateTimeType),
			theirs: moment(conflictingValue).toDateTimeTypeString(property.dateTimeType)
		};
	}

	const navigationProperty = property.relatedNavigationProperty;
	if (navigationProperty) {
		const entityManager = breeze.EntityManager.withMetadataStore(navigationProperty.entityType.metadataStore);
		const localValuePromise = entityManager.fetchEntityByKey(navigationProperty.entityType, localValue);
		const conflictingValuePromise = entityManager.fetchEntityByKey(navigationProperty.entityType, conflictingValue);

		const [localData, conflictingData] = await Promise.all([localValuePromise, conflictingValuePromise]);
		const [yours, theirs] = await Promise.all([getEntityDisplayValueAsync(localData), getEntityDisplayValueAsync(conflictingData)]);
		return {
			yours: yours || null,
			theirs: theirs || null,
		};
	}

	return property.dataType.name !== 'Guid' ? { yours: localValue, theirs: conflictingValue } : null;
}

function getEntityDisplayValueAsync(data) {
	const entity = data.entity;
	if (!entity) {
		return null;
	}
	const rules = ruleService.get(entity.entityType.interfaceName);
	const displayMode = lookupDisplayModeHelper.getDisplayMode(rules);
	return lookupDisplayModeHelper.getDisplayValueAsync(entity, displayMode, rules.codeProperty(), rules.descriptionProperty());
}

async function notifyChangedFieldValuesAsync(entity, propertyName, localValue, conflictingValue) {
	const values = await getChangedFieldValuesAsync(entity, propertyName, localValue, conflictingValue);
	const message = values && values.yours != null && values.theirs != null
		? captionService.getString('db75c52b-9903-4ecf-a2aa-1d3bf4a1f92f', "Another user has changed this field. Yours: '{0}', Theirs: '{1}'", values.yours, values.theirs)
		: captionService.getString('3bad252f-e5fb-4057-b333-8c01e1067f20', 'Another user has changed this field.');
	Notifications.get(entity).push({
		propertyName,
		caption: captionService.getCaptionFromField({ entityName: entity.entityType.interfaceName, propertyName }).caption,
		Text: message,
		Level: notificationType.Information,
		isServerNotification: true
	});
}

function isEqual(reloadedValue, originalValue, dataProperty) {
	// Temporary workaround until WI00197635 is done.
	if (dataProperty.scale) {
		const decimals = countDecimals(originalValue);
		if (decimals > dataProperty.scale) {
			const difference = Math.abs(originalValue - reloadedValue) * Math.pow(10, dataProperty.scale);
			return difference < 1;
		}
	}

	if (
		(dataProperty.dataType === breeze.DataType.DateTime ||
			dataProperty.dataType === breeze.DataType.DateTimeOffset) &&
		reloadedValue != null &&
		originalValue != null
	) {
		return moment(reloadedValue).isSame(originalValue);
	}

	return reloadedValue === originalValue;
}

function countDecimals(value) {
	return Math.floor(value) === value ? 0 : value.toString().split('.')[1].length;
}

export { notifyChangedFieldValuesAsync, isEqual };
