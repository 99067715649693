import ko from 'knockout';
import _ from 'underscore';
import settingService from 'SettingService';
import encodingUtils from 'EncodingUtils';
import Constants from 'Constants';

function PinSettingsManager() {
	this._cache = {};
}

PinSettingsManager.prototype.getIsPinnedObservable = function (settingsKey, itemKey) {
	if (!this._cache[settingsKey]) {
		this._cache[settingsKey] = {};
	}

	if (!this._cache[settingsKey][itemKey]) {
		const rawObservable = settingService.getObservableSetting(settingsKey);

		this._cache[settingsKey][itemKey] = ko.computed(() => {
			const keys = parseSettingsData(rawObservable(), settingsKey);

			return _.contains(keys, itemKey);
		});
	}

	return this._cache[settingsKey][itemKey];
};

PinSettingsManager.prototype.togglePinAsync = (settingsKey, itemKey) => {
	return settingService.getSettingAsync(settingsKey)
		.then((data) => {
			const keys = parseSettingsData(data, settingsKey);
			const idx = _.indexOf(keys, itemKey);
			if (idx === -1) {
				keys.push(itemKey);
			}
			else {
				keys.splice(idx, 1);
			}

			return settingService.saveSettingAsync(settingsKey, encodingUtils.encodeData(JSON.stringify(keys)));
		});
};

function parseSettingsData(data, settingsKey) {
	if (data) {
		const json = encodingUtils.decodeData(data);
		if (json) {
			return _.uniq(JSON.parse(json));
		}

		return [];
	}

	return getDefaultSettingsData(settingsKey);
}

function getDefaultSettingsData(settingsKey) {
	if (settingsKey === Constants.PinSettingsKeys.Header) {
		return [
			Constants.HeaderMenuItems.Documents.Key,
			Constants.HeaderMenuItems.EDocs.Key,
			Constants.HeaderMenuItems.Notes.Key,
			Constants.HeaderMenuItems.Jump.Key,
			Constants.HeaderMenuItems.Workflow.Key,
			Constants.HeaderMenuItems.Messages.Key
		];
	}
	else if (settingsKey === Constants.PinSettingsKeys.Actions) {
		return [
			Constants.ActionsMenuItems.Map.Key,
			Constants.ActionsMenuItems.New.Key,
			Constants.ActionsMenuItems.Refresh.Key,
			Constants.ActionsMenuItems.Filter.Key
		];
	}

	return [];
}

export default new PinSettingsManager();
