import $ from 'jquery';

$.ajaxTransport('+*', (options) => {
	/*! SuppressStringValidation Not a caption. */
	const dataType = options.dataType || 'text';
	if (dataType === 'arraybuffer' || options.data instanceof Uint8Array) {
		return {
			send(headers, completeCallback) {
				let xhr = options.xhr ? options.xhr() : new XMLHttpRequest();

				if (options.xhrFields) {
					xhr = Object.assign(xhr, options.xhrFields);
				}

				const done = handleCompletedEvent.bind(null, xhr, completeCallback);
				xhr.addEventListener('load', done);
				xhr.addEventListener('error', done);

				xhr.open(options.type, options.url, options.async);
				xhr.responseType = dataType;

				for (const key in headers) {
					if (Object.prototype.hasOwnProperty.call(headers, key)) {
						xhr.setRequestHeader(key, headers[key]);
					}
				}

				xhr.send(options.data);
			},
			abort() {
			}
		};
	}
});

function handleCompletedEvent(xhr, completeCallback, event) {
	const response = {};
	if (xhr.status === 200 || xhr.status === 204 || xhr.status === 304) {
		response.arraybuffer = xhr.response;
	}
	else if (xhr.responseType === '' || xhr.responseType === 'text') {
		response.text = xhr.responseText;
	}
	else if (event.type === 'error') {
		response.error = true;
	}

	completeCallback(xhr.status, xhr.statusText, response, xhr.getAllResponseHeaders());
}
