import { isNonEmptyString, type NullableString } from "StringUtils";

export enum NotificationType {
  // These enum values should be in sync with NotificationType.cs, you can add more but at the end
  Success,
  Information,
  Warning,
  MessageError,
  Error,
  // Not defined in the server but used for the HTML client
  None,
  Question,
}
/*! StartNoStringValidationRegion string values for notification types */
export type NotificationTypeString = "Success" | "Information" | "Warning" | "MessageError" | "Error";
/*! EndNoStringValidationRegion */

const ordinalInformation = {
  [NotificationType.None]: 0,
  [NotificationType.Success]: 0,
  [NotificationType.Question]: 0,
  [NotificationType.Information]: 1,
  [NotificationType.Warning]: 2,
  [NotificationType.MessageError]: 3,
  [NotificationType.Error]: 4,
};

export function getNotificationIconName(value: NotificationType): string {
  /*! StartNoStringValidationRegion css classes */
  switch (value) {
    case NotificationType.Success:
      return "icon-check-circle";

    case NotificationType.Error:
      return "icon-exclamation-circle";

    case NotificationType.MessageError:
      return "icon-envelope";

    case NotificationType.Warning:
      return "icon-warning";

    case NotificationType.Question:
      return "icon-question-circle";

    default:
      return "icon-info-circle";
  }
  /*! EndNoStringValidationRegion */
}

export function compareNotificationTypes(first: NotificationType, second: NotificationType): -1 | 0 | 1 {
  const ordinalInformationFirstItem = ordinalInformation[first];
  const ordinalInformationSecondItem = ordinalInformation[second];

  if (ordinalInformationFirstItem > ordinalInformationSecondItem) {
    return 1;
  } else if (ordinalInformationFirstItem < ordinalInformationSecondItem) {
    return -1;
  } else {
    return 0;
  }
}

export function notificationTypeFromString(value: NullableString): NotificationType {
  if (isNonEmptyString(value)) {
    switch (value.replace("-", "").toLowerCase()) {
      case "information":
        return NotificationType.Information;

      case "warning":
        return NotificationType.Warning;

      case "error":
        return NotificationType.Error;

      case "messageerror":
        return NotificationType.MessageError;

      case "success":
        return NotificationType.Success;

      case "question":
        return NotificationType.Question;
    }
  }

  return NotificationType.None;
}

export function notificationTypeToString(value: NotificationType): NullableString {
  /*! StartNoStringValidationRegion string values for notification types */
  switch (value) {
    case NotificationType.Information:
      return "information";

    case NotificationType.Warning:
      return "warning";

    case NotificationType.Error:
      return "error";

    case NotificationType.MessageError:
      return "message-error";

    case NotificationType.Success:
      return "success";

    case NotificationType.Question:
      return "question";

    default:
      return null;
  }
  /*! EndNoStringValidationRegion */
}

class LegacyNotificationType {
  readonly Success = NotificationType.Success;
  readonly Information = NotificationType.Information;
  readonly Warning = NotificationType.Warning;
  readonly MessageError = NotificationType.MessageError;
  readonly Error = NotificationType.Error;
  readonly None = NotificationType.None;
  readonly Question = NotificationType.Question;

  getIconName(value: NotificationType): string {
    return getNotificationIconName(value);
  }

  compare(first: NotificationType, second: NotificationType): -1 | 0 | 1 {
    return compareNotificationTypes(first, second);
  }

  fromString(value: NullableString): NotificationType {
    return notificationTypeFromString(value);
  }

  toString(value: NotificationType): NullableString {
    return notificationTypeToString(value);
  }
}

/** @deprecated Legacy export for compatibility. Use "import { NotificationType } from 'NotificationType'" instead. */
export default new LegacyNotificationType();
