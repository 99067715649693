import { getInterfaceName } from "EntityExtensions";
import { FormFactor } from "FormFactors";
import global from "Global";
import RuleService from "RuleService";
import userSession from "UserSession";
import { UserType } from "UserTypeConstants";
import type { EntityType } from "breeze-client";

export function canShowMessages(entityType: EntityType): boolean {
  const rules = RuleService.get(getInterfaceName(entityType));
  return !!rules.isConversationProvider();
}

export function canViewEDocs(entityName: string): boolean {
  return global.formFactor !== FormFactor.Mobile && !!RuleService.get(entityName).docManagerCode();
}

export function hideEDocs(entityName: string): boolean {
  return !!RuleService.get(entityName).hideEDocs();
}

export function canViewDocuments(entityName: string): boolean {
  const rules = RuleService.get(entityName);
  return rules.canViewDocuments();
}

export function canShowWorkflow(entityType: EntityType): boolean {
  return global.formFactor !== FormFactor.Mobile && canShowWorkflowMenu(entityType);
}

export function hideWorkflow(entityName: string): boolean {
  return !!RuleService.get(entityName).hideWorkflow();
}

export function canShowLogs(entityType: EntityType): boolean {
  const isStaff = userSession.sessionData().userType === UserType.Staff;

  return (
    global.formFactor !== FormFactor.Mobile &&
    canShowWorkflowOptionCore(entityType, "WorkflowAuditLogsSummary", isStaff)
  );
}

export function canShowWorkflowOption(entityType: EntityType, propertyName: string): boolean {
  const isStaff = userSession.sessionData().userType === UserType.Staff;

  return canShowWorkflowOptionCore(entityType, propertyName, isStaff);
}

function canShowWorkflowMenu(entityType: EntityType): boolean {
  const isStaff = userSession.sessionData().userType === UserType.Staff;

  return (
    canShowWorkflowOptionCore(entityType, "WorkflowTasks", isStaff) ||
    canShowWorkflowOptionCore(entityType, "WorkflowMilestonesSummary", isStaff) ||
    canShowWorkflowOptionCore(entityType, "WorkflowEventsSummary", isStaff) ||
    canShowWorkflowOptionCore(entityType, "WorkflowExceptionsSummary", isStaff) ||
    canShowWorkflowOptionCore(entityType, "WorkflowTriggers", isStaff)
  );
}

function canShowWorkflowOptionCore(entityType: EntityType, propertyName: string, isStaff: boolean): boolean {
  if (isStaff || propertyName === "WorkflowMilestonesSummary" || propertyName === "WorkflowEventsSummary") {
    if (entityType.getProperty(propertyName)) {
      return true;
    }

    const rules = RuleService.get(getInterfaceName(entityType));
    if (rules.propertyRule(propertyName, true)) {
      return true;
    }
  }

  return false;
}

/** @deprecated Use named exports instead */
export default {
  canShowMessages,
  canViewEDocs,
  hideEDocs,
  canViewDocuments,
  canShowWorkflow,
  hideWorkflow,
  canShowLogs,
  canShowWorkflowOption,
};
