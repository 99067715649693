import breeze from 'breeze-client';
import Dependency from 'Dependency2';
import entityMappingService from 'EntityMappingService';
import errors from 'Errors';
import { enumeratePropertyPath, getPropertyName, isComplexPath } from 'PropertyPathEnumerator';
import RuleService from 'RuleService';
import Promise from 'bluebird';
import { unwrapCollectionType, unwrapNullableType } from 'DataTypes';

function BindingEvaluator() {
}

BindingEvaluator.prototype.getEntityBindingInfo = (entityType, path, options) => {
	const validateSeparators = options && options.validateSeparators;
	return getEntityBindingInfoCore(entityType, path || '', validateSeparators);
};

function getEntityBindingInfoCore(entityType, path, validateSeparators) {
	if (!(entityType instanceof breeze.EntityType)) {
		throw new Error('entityType argument must be a breeze.EntityType.');
	}

	const parents = [];
	let propertyInfo;
	let parentPropertyInfo;

	for (const nextPropertyInfo of enumeratePropertyPath(entityType, path)) {
		if (propertyInfo) {
			if (!propertyInfo.isValid) {
				return getInvalidPropertyError(propertyInfo, parentPropertyInfo);
			}
			if (!nextPropertyInfo.entityType) {
				return getInvalidPropertyError(nextPropertyInfo, propertyInfo);
			}

			const isCollection = propertyInfo.isCalculated
				? unwrapCollectionType(propertyInfo.rule.returnType).isCollection
				: !propertyInfo.property.isScalar;

			if (validateSeparators !== false) {
				const expectCollection = propertyInfo.nextSeparator === '/';
				if (isCollection !== expectCollection) {
					return getInvalidTypeError(expectCollection);
				}
			}

			parents.push({
				entityName: propertyInfo.entityType.interfaceName,
				entityType: propertyInfo.entityType,
				propertyName: propertyInfo.propertyName,
				isCalculated: propertyInfo.isCalculated,
				isCollection,
			});
		}

		parentPropertyInfo = propertyInfo;
		propertyInfo = nextPropertyInfo;
	}

	return propertyInfo
		? new BindingInfo(propertyInfo.entityType, propertyInfo.propertyName, parents)
		: new BindingInfo(entityType, path, parents);

	function getInvalidPropertyError(propertyInfo, parentPropertyInfo) {
		if (propertyInfo.entityType) {
			return new BindingInfo(
				getMissingPropertyError(propertyInfo.entityType, propertyInfo.propertyName)
			);
		}

		if (parentPropertyInfo.isCalculated) {
			return new BindingInfo(
				getEntityTypeError(
					entityType.metadataStore,
					unwrapCollectionType(parentPropertyInfo.rule.returnType).typeName
				)
			);
		}

		return new BindingInfo(
			`Expected property "${parentPropertyInfo.propertyName}" on entity ${parentPropertyInfo.entityType.interfaceName} to be an entity type but it is not.`
		);
	}

	function getInvalidTypeError(expectCollection) {
		/*! SuppressStringValidation Error message */
		const expectedType = expectCollection ? 'a collection' : 'scalar';
		return new BindingInfo(
			`Expected property "${propertyInfo.propertyName}" on entity ${propertyInfo.entityType.interfaceName} to be ${expectedType} but it is not.`
		);
	}
}

BindingEvaluator.prototype.getPropertyName = getPropertyName;

BindingEvaluator.prototype.getUltimateDataItem = function (bindingContext, data, path) {
	return this.getUltimateDataItemWithState(bindingContext, data, path).value;
};

BindingEvaluator.prototype.getUltimateDataItemWithState = (bindingContext, data, path) => {
	return Dependency.getUltimateDataItem(data, path, getDependencyOptions(bindingContext));
};

BindingEvaluator.prototype.loadUltimateDataItemAsync = function (bindingContext, data, path) {
	return this.loadUltimateDataItemWithStateAsync(bindingContext, data, path).get('value');
};

BindingEvaluator.prototype.loadUltimateDataItemWithStateAsync = (bindingContext, data, path) => {
	return Dependency.getUltimateDataItemAsync(data, path, getDependencyOptions(bindingContext));
};

BindingEvaluator.prototype.loadValueAsync = (bindingContext, data, path) => {
	return Dependency.getValueAsync(data, path, getDependencyOptions(bindingContext)).get('value');
};

BindingEvaluator.prototype.joinBindingPath = (bindingPath, property) => {
	if(bindingPath) {
		return bindingPath + (bindingPath.endsWith('/') ? '' : '.') + property;
	}
	return property;
};

function getDependencyOptions(bindingContext) {
	return {
		currentItemAccessor: {
			read: currentItem.bind(null, bindingContext),
			shouldLoad(entity, propertyName) {
				const property = entity[propertyName];
				return property.isCalculatedProperty || property().length === 0;
			},
			loadAsync(entity, propertyName) {
				const property = entity[propertyName];
				return property.isCalculatedProperty
					? property.loadAsync()
					: property.temporaryHack_loadFirstAsync();
			},
		},
	};
}

BindingEvaluator.prototype.getUltimateDataItemPath = (path) => {
	const index = getLastIndexOfSeparator(path);
	return index > -1 ? path.substr(0, index) : '';
};

BindingEvaluator.prototype.getUltimateDataItemPathWithSeparator = (path) => {
	const index = getLastIndexOfSeparator(path);
	return index > -1 ? path.substr(0, index + 1) : '';
};

function getLastIndexOfSeparator(path) {
	for (let i = path.length - 1; i >= 0; i--) {
		const char = path[i];
		if (char === '.' || char === '/') {
			return i;
		}
	}

	return -1;
}

BindingEvaluator.prototype.getValue = function (bindingContext, data, path, options) {
	return this.getValueWithState(bindingContext, data, path, options).value;
};

BindingEvaluator.prototype.getValueWithState = (bindingContext, data, path, options) => {
	const dependencyOptions = getDependencyOptions(bindingContext);
	dependencyOptions.unwrapFinalValue = !options || options.unwrap !== false;
	return Dependency.getValue(data, path, dependencyOptions);
};

BindingEvaluator.prototype.isComplexPath = isComplexPath;

BindingEvaluator.prototype.isValidEntityBindingPath = (entityType, path) => {
	const info = getEntityBindingInfoCore(entityType, path);
	return info.isValid;
};

function currentItem(bindingContext, data, propertyName) {
	return bindingContext.$currentItem(data, propertyName);
}

function getEntityType(metadataStore, entityName) {
	const result = metadataStore.getEntityType(entityName, /*okIfNotFound:*/ true);
	return result || { error: getEntityTypeError(metadataStore, entityName) };
}

function getEntityTypeError(metadataStore, entityName) {
	/*! StartNoStringValidationRegion Error message */
	return `Could not find entity type with name "${entityName}" in ${
		metadataStore ? metadataStore.getRouteName() : 'any'
	} route.`;
	/*! EndNoStringValidationRegion */
}

BindingEvaluator.prototype.getPropertyInfoAsync = (entityType, propertyName) => {
	return Promise.try(() => {
		return getPropertyInfo(entityType, propertyName, true);
	});
};

function getPropertyInfo(entityType, propertyName, isAsync) {
	if (!propertyName) {
		return getEmptyPropertyInfo();
	}

	if (entityType.error) {
		return entityType;
	}

	let result;
	const property = entityType.getProperty(propertyName);
	if (property) {
		result = getDataPropertyInfo(property);
	}
	else {
		result = getCalculatedPropertyInfo(entityType, propertyName, isAsync);
	}

	return result;
}

function getEmptyPropertyInfo() {
	return {
		propertyType: null,
		propertyEntityType: null,
		isCollection: false,
		isCalculated: false,
		isCharBoolean: false,
		isNullable: false,
		isGuid: false
	};
}

function getDataPropertyInfo(property) {
	const propertyType = property.isNavigationProperty ? property.entityType.interfaceName : property.dataType.toString();
	return {
		propertyType,
		propertyEntityType: property.isNavigationProperty ? property.entityType : null,
		isCollection: !property.isScalar,
		isCalculated: false,
		isCharBoolean: RuleService.get(property.parentType.interfaceName).charBoolean(property.name),
		isNullable: property.isNullable || property.isNavigationProperty,
		isGuid: propertyType === 'Guid'
	};
}

function getCalculatedPropertyInfo(entityType, propertyName, isAsync) {
	const rules = RuleService.get(entityType.interfaceName);
	const rule = rules.propertyRule(propertyName, /*okIfNotfound:*/ true);
	if (!rule) {
		return { error: getMissingPropertyError(entityType, propertyName) };
	}

	const collectionTypeInfo = unwrapCollectionType(rule.returnType);
	const { isNullable, typeName: propertyType } = unwrapNullableType(collectionTypeInfo.typeName);
	let propertyEntityType = entityMappingService.hasInterfaceName(propertyType) ? getEntityType(entityType.metadataStore, propertyType) : null;

	const { isCollection } = collectionTypeInfo;
	if (propertyEntityType && propertyEntityType.error) {
		if (isAsync) {
			return breeze.MetadataStore
				.forEntityTypeAsync(propertyType)
				.then((metadataStore) => {
					propertyEntityType = getEntityType(metadataStore, propertyType);
					return getPropertyInfoObject(propertyType, isCollection, isNullable, propertyEntityType);
				});
		} else {
			return propertyEntityType;
		}
	} else {
		return getPropertyInfoObject(propertyType, isCollection, isNullable, propertyEntityType);
	}
}

function getMissingPropertyError(entityType, propertyName) {
	return `Could not find property "${propertyName}" on entity ${entityType.interfaceName}.`;
}

function getPropertyInfoObject(propertyType, isCollection, isNullable, propertyEntityType) {
	return {
		propertyType,
		propertyEntityType,
		isCollection,
		isCalculated: true,
		isCharBoolean: false,
		isNullable: isNullable || !!propertyEntityType,
		isGuid: false
	};
}

function BindingInfo(entityTypeOrError, propertyName, parents) {
	if (typeof entityTypeOrError === 'string') {
		this._error = entityTypeOrError;
	} else {
		this._metadataStore = entityTypeOrError.metadataStore;
		this._entityType = entityTypeOrError;
		this._entityName = entityTypeOrError.interfaceName;
		this._propertyName = propertyName;
		this._parents = parents;
	}
}

Object.defineProperties(BindingInfo.prototype, {
	entityType: {
		get() {
			throwErrorIfRequired(this);
			return this._entityType;
		}
	},
	entityName: {
		get() {
			throwErrorIfRequired(this);
			return this._entityName;
		}
	},
	error: {
		get() {
			loadPropertyInfo(this, /*ignoreError:*/ true);
			return this._error || null;
		}
	},
	isCalculated: {
		get() {
			return loadPropertyInfo(this).isCalculated || this._parents.some(isCalculatedParent);
		}
	},
	isCollection: {
		get() {
			return loadPropertyInfo(this).isCollection;
		}
	},
	isCharBoolean: {
		get() {
			return loadPropertyInfo(this).isCharBoolean;
		}
	},
	isExpandable: {
		get() {
			const { propertyEntityType } = this;
			return !!propertyEntityType && propertyEntityType.isExpandable;
		}
	},
	isNullable: {
		get() {
			return loadPropertyInfo(this).isNullable;
		}
	},
	isGuid: {
		get() {
			return loadPropertyInfo(this).isGuid;
		}
	},
	isValid: {
		get() {
			return !this.error;
		}
	},
	parents: {
		get() {
			throwErrorIfRequired(this);
			return this._parents;
		}
	},
	propertyName: {
		get() {
			throwErrorIfRequired(this);
			return this._propertyName;
		}
	},
	ultimateDataItemPath: {
		get() {
			throwErrorIfRequired(this);
			return this._parents.map((parent) => {
				return parent.propertyName + (parent.isCollection ? '/' : '.');
			})
				.join('');
		}
	},
	propertyType: {
		get() {
			return loadPropertyInfo(this).propertyType;
		}
	},
	propertyEntityType: {
		get() {
			return loadPropertyInfo(this).propertyEntityType;
		}
	}
});

function isCalculatedParent(parent) {
	return parent.isCalculated;
}

function loadPropertyInfo(bindingInfo, ignoreError) {
	let result = bindingInfo._propertyInfo;
	if (!result && !bindingInfo._error) {
		const entityType = getEntityType(bindingInfo._metadataStore, bindingInfo._entityName);
		result = getPropertyInfo(entityType, bindingInfo._propertyName);
		if (result.error) {
			bindingInfo._error = result.error;
		} else {
			bindingInfo._propertyInfo = result;
		}
	}

	if (!ignoreError) {
		throwErrorIfRequired(bindingInfo);
	}

	return result;
}

function throwErrorIfRequired(bindingInfo) {
	const error = bindingInfo._error;
	if (error) {
		throw new errors.BindingPathError(error);
	}
}

export default new BindingEvaluator();
