import ko, { type BindingContext } from "knockout";
import { inject, provide } from "vue";

export const knockoutContextInjectionKey = Symbol("koContext");

export function useKnockoutContext(): BindingContext | undefined {
  return inject<BindingContext | undefined>(knockoutContextInjectionKey, undefined);
}

export function setKnockoutContext(value: BindingContext): void {
  if (!(value instanceof ko.bindingContext)) {
    throw new Error("Knockout binding context should be passed as the value.");
  }

  provide(knockoutContextInjectionKey, value);
}
