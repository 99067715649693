class ClassHelper {
	inherit(C, P) {
		F.prototype = P.prototype;
		C.prototype = new F();
		C.prototype.constructor = C;

		for (const name in P) {
			if (Object.prototype.hasOwnProperty.call(P, name)) {
				C[name] = P[name];
			}
		}
	}

	inheritError(name, ctor) {
		function SubError() {
			this.name = name;
			ctor.apply(this, arguments);

			if (Error.captureStackTrace) {
				Error.captureStackTrace(this, this.constructor);
			}
			else {
				Error.call(this);
			}
		}

		this.inherit(SubError, Error);
		return SubError;
	}
}

const F = function () { };

export default new ClassHelper();
