import breeze from 'breeze-client';
import Promise from 'bluebird';
import { getODataRoute } from 'ODataUtils';
import ModelProvider from 'ModelProvider';
import activeStateDecorator from 'Filters/EntityInfoActiveStateDecorator';
import EntityQueryLookupProvider from 'Shared/Lookups/EntityQueryLookupProvider';
import { normalizeEntityInfoTypeName } from 'IndexUtils';

export default class EntityInfoQueryLookupProvider extends EntityQueryLookupProvider {
	constructor(args) {
		super(args);

		this.invokers = {
			getQueryAsync,
			decorateActiveState: activeStateDecorator.decorateQuery,
			getMetadataStoreAsync,
		};
	}
}

function getEntityManager() {
	/*! StartNoStringValidationRegion (No captions here) */
	return new breeze.EntityManager({
		dataService: new breeze.DataService({
			serviceName: getODataRoute('Index'),
			hasServerMetadata: false,
			adapterName: 'Glow'
		})
	});
	/*! EndNoStringValidationRegion */
}

function getQueryAsync(itemType) {
	const manager = getEntityManager();
	let query = new breeze.EntityQuery('EntityInfos').using(manager).inlineCount(true);

	const normalizedTypeName = normalizeEntityInfoTypeName(itemType);
	const entityPredicate = breeze.Predicate.create('EntityType', '==', normalizedTypeName);
	query = query.where(entityPredicate);

	return Promise.resolve(query);
}

function getMetadataStoreAsync(self, query, modelProvider) {
	modelProvider = modelProvider || new ModelProvider();
	return modelProvider.createEntityManagerAsync(self.itemType)
		.then((entityManager) => {
			return entityManager.metadataStore;
		});
}
