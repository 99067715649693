import appConfig from 'AppConfig';
import navigationService from 'NavigationService';

function InitialPage()
{
	this.privates = { overriddenPath: null };
	this.defaultPath = '#/' + appConfig.initialPage;
}

InitialPage.prototype.logonRedirectPath = function (value)
{
	if (arguments.length === 1)
	{
		this.privates.overriddenPath = value;
	}
	else
	{
		return this.privates.overriddenPath || this.defaultPath;
	}
};

InitialPage.prototype.navigateToSelf = function (reload)
{
	const path = this.logonRedirectPath();
	if (reload)
	{
		navigationService.changePageWithReload(path);
	}
	else
	{
		navigationService.changePage(path);
	}
};

export default new InitialPage();
