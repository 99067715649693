import QueryBuilderV2Visitor from 'QueryBuilderV2Visitor';
import Promise from 'bluebird';
import _ from 'underscore';

function BreezeQueryable(baseQuery) {
	if (!baseQuery) {
		throw new Error('Base query must be provided.');
	}
	this._baseQuery = baseQuery;
}

BreezeQueryable.prototype.select = function (expression, params, embeddedDependenciesMap) {
	const query = QueryBuilderV2Visitor.visit(expression, this._baseQuery, { params, embeddedDependenciesMap });
	return new BreezeQueryable(query);
};

BreezeQueryable.prototype.toArrayAsync = function () {
	return toEnumerableAsync(this._baseQuery);
};

BreezeQueryable.prototype.toCountAsync = function () {
	if (shouldUnwrapResult(this._baseQuery)) {
		return toEnumerableAsync(this._baseQuery).then((result) => {
			return result.length;
		});
	}

	return this._baseQuery.noTracking().take(0).inlineCount(true).execute().then((result) => {
		return result.inlineCount;
	});
};

BreezeQueryable.prototype.getFirstAsync = function () {
	return toEnumerableAsync(this._baseQuery.take(1)).get(0);
};

function toEnumerableAsync(query) {
	if (query.wherePredicate?.toJSON() === false) {
		return Promise.resolve([]);
	}

	return query.execute().then((result) => {
		const unwrappedResult = shouldUnwrapResult(query) ? result.results.map(unwrapSingleResult) : result.results;
		return _.flatten(unwrappedResult);
	});
}

function unwrapSingleResult(item) {
	if (Array.isArray(item)) {
		return item.map(unwrapSingleResult);
	}
	if (item instanceof Object) {
		const keys = Object.keys(item);
		if (keys.length === 1) {
			return unwrapSingleResult(item[keys[0]]);
		}
	}
	return item;
}

function shouldUnwrapResult(query) {
	return query.selectClause && query.selectClause.propertyPaths && query.selectClause.propertyPaths.length === 1;
}

export default BreezeQueryable;
