import AsyncLock, { type Work } from "AsyncLock";

const syncMap = new WeakMap<object, AsyncLock>();

export async function synchronizeAsync<T>(owner: object, work: Work<T>): Promise<T> {
  let syncLock = syncMap.get(owner);
  if (!syncLock) {
    syncLock = new AsyncLock();
    syncMap.set(owner, syncLock);
  }

  const result = await syncLock.doAsync(work);
  return result;
}
