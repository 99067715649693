import appConfig from 'AppConfig';
import AsyncLock from 'AsyncLock';
import audioService from 'AudioService';
import captionService from 'CaptionService';
import constants from 'Constants';
import { DeferredPromise } from 'DeferredPromise';
import dialogServiceProvider from 'DialogServiceProvider';
import { blurActiveElement, setUpDialogUIAsync } from 'DialogServiceUI';
import global from 'Global';
import modalService from 'ModalService';
import notificationType from 'NotificationType';
import { commaSeparate, format, htmlEscape } from 'StringUtils';
import $ from 'jquery';
import ko from 'knockout';

/*! StartNoStringValidationRegion These strings specify system values for buttons */
const ButtonTypes = {
	Ok(text) { return { text: text || captionService.getString('93f85d2e-d180-49b1-8630-15f4d81dd77b', 'OK'), value: 'ok' }; },
	Cancel(text) { return { text: text || captionService.getString('d5b0b792-6408-4d81-96f4-5bff043bd62d', 'Cancel'), value: 'cancel' }; },
	Yes(text) { return { text: text || captionService.getString('3df4f950-ed61-4dff-8b46-22fec58b4e39', 'Yes'), value: 'yes' }; },
	No(text) { return { text: text || captionService.getString('27ffa69a-cd93-4007-a987-8553dad049f2', 'No'), value: 'no' }; },
	Submit(text) { return { text: text || captionService.getString('ccffe91d-4245-42bb-9c77-dce44d5f99d0', 'Submit'), value: 'submit' }; },
};

const DialogTypes = {
	Default: 'default',
	ReportError: 'report-error'
};
/*! EndNoStringValidationRegion */

export class DialogService {
	constructor() {
		this.buttonTypes = ButtonTypes;
		this.dialogTypes = DialogTypes;
		this._uniqueDialogs = {};
		this._asyncLock = new AsyncLock();
	}

	getDefaultOptions() {
		return {
			title: null,
			body: null,
			bodyDeferred: null,
			preHideCallback: null,
			afterHideCallback: null,
			buttonOptions: null,
			includeValidationSummary: false,
			viewModel: null,
			buttonsOrientation: 'horizontal',
			dialogType: DialogTypes.Default,
			bodyAllowHtml: false,
			dialogCss: null,
			closeOnDismissOnly: false,
			titleAllowHtml: false,
			maximized: false
		};
	}

	isDialogOpen(dialogType) {
		let $dialogs = $(dialogServiceProvider.selectors.visibleDialog);

		if (dialogType) {
			$dialogs = $dialogs.filter(`[data-dialog-type="${dialogType}"]`);
		}

		return $dialogs.length > 0;
	}

	showDialogAsync(options) {
		options = $.extend({}, this.getDefaultOptions(), options);

		if (options.dialogID && this._uniqueDialogs[options.dialogID]) {
			return this._uniqueDialogs[options.dialogID];
		}

		const dialog = getDialog(dialogServiceProvider.create(options));
		const focusTrap = modalService.createFocusTrap(dialog.$dialog[0], dialogServiceProvider.getFocusTrapSettings(options, dialog.$dialog));

		const initPromise = this._asyncLock.doAsync(async () => {
			try {
				await initDialogAsync(dialog, options);

				if (options.dialogID) {
					dialogServiceProvider.onAfterHide(dialog.$dialog, () => this.clearUniqueDialog(options.dialogID));
				}

				if (global.portalInfo.playSystemSounds && !options.suspendSounds) {
					dialogServiceProvider.onBeforeShow(dialog.$dialog, audioService.playNotificationAudio.bind(audioService, options.notificationType));
				}

				options.dialogService = this;
				blurActiveElement();
				focusTrap.activate();
				await populateDialogAsync(dialog, options);

				if (options.notificationType !== null && options.notificationType !== undefined && options.notificationType !== notificationType.None) {
					dialog.domElements.$body.addClass('modal-body-with-icon');
					addIcon(dialog, options.notificationType);
				} else {
					dialog.domElements.$body.removeClass('modal-body-with-icon');
				}

				dialogServiceProvider.show(dialog.$dialog);
				setDialogPosition(dialog, options);

				if (options.maximized) {
					maximizeDialog(dialog.$dialog);
				}

				return dialog;

			} catch (e) {
				focusTrap.deactivate();
				throw e;
			}
		});

		if (options.dialogID) {
			this._uniqueDialogs[options.dialogID] = initPromise;
		}

		return initPromise;
	}

	clearUniqueDialog(dialogID) {
		this._uniqueDialogs[dialogID] = null;
	}

	async showAsync(messageNotificationType, message, title, buttonOptions, options, viewModel) {
		let resolver;
		viewModel = $.extend({ buttonResults: {} }, viewModel);

		if (buttonOptions) {
			$.each(buttonOptions,
				(index, option) => {
					let result = option.result;
					if (typeof result === 'function') {
						if (option.isAsync) {
							viewModel.buttonResults[index] = async () => {
								const optionResult = await result();
								return resolver(optionResult);
							};
							option.bindingString = 'asyncClick: buttonResults[' + index + ']';
						} else {
							viewModel.buttonResults[index] = () => resolver(result());
							option.bindingString = 'click: buttonResults[' + index + ']';
						}
					} else {
						result = result === false ? result : (result || option.caption);
						viewModel.buttonResults[index] = () => resolver(result);
						option.bindingString = 'click: buttonResults[' + index + ']';
					}
				});
		}

		options = $.extend({ title, body: message, buttonOptions, notificationType: messageNotificationType }, options);
		options.viewModel = viewModel;

		if (!options.preHideCallback) {
			options.preHideCallback = () => resolver();
		} else {
			const existingPreHideCallback = options.preHideCallback;
			options.preHideCallback = function () {
				existingPreHideCallback.apply(this, arguments);
				resolver();
			};
		}

		const dialogInfoPromise = this.showDialogAsync(options);
		const showAsyncResult = await new Promise((resolve) => {
			resolver = resolve;
		});
		this.hide(await dialogInfoPromise);
		return showAsyncResult;
	}

	showValidationResultsAsync(messageNotificationType, message, title, notificationSummary, options) {
		const body = message + '<div data-bind="component: { name: \'gwValidationSummary\', params: { showNoAlertsMessage: false, disableNavigation: true, filter: filter } }"></div>';
		const buttonOptions = [{ caption: this.buttonTypes.Ok().text, result: this.buttonTypes.Ok().value, isDismiss: true, isPrimary: true }];
		const dialogOptions = { bodyAllowHtml: true };

		const filter = (options && options.filter) || null;
		const viewModel = { notificationSummary, filter };

		return this.showAsync(
			messageNotificationType,
			body,
			title,
			buttonOptions,
			dialogOptions,
			viewModel);
	}

	alertAsync(notificationType, message, title, options) {
		const buttonOption = this.buttonTypes.Ok(options?.buttons?.[0]?.caption);
		return this.showAsync(
			notificationType,
			message,
			title,
			[{ caption: buttonOption.text, result: buttonOption.value, isDismiss: true, isPrimary: true }],
			$.extend({ overwriteDialog: options ? !options.keepDialog : true }, options),
		);
	}

	confirmAsync(message, title, buttonOptions, dialogOptions) {
		return this.showAsync(notificationType.Question,
			message,
			title,
			buttonOptions ||
			[
				{ caption: this.buttonTypes.Cancel().text, isDismiss: true, result: this.buttonTypes.Cancel().value },
				{ caption: this.buttonTypes.Ok().text, isDismiss: true, result: this.buttonTypes.Ok().value }
			],
			dialogOptions);
	}

	okCancelConfirmAsync(message, title) {
		return this.confirmAsync(message, title);
	}

	yesNoConfirmAsync(message, title, dialogOptions) {
		const noButtonOptions = $.extend({
			buttonType: this.buttonTypes.No().value,
			caption: this.buttonTypes.No().text,
			result: this.buttonTypes.No().value,
			isDismiss: true
		}, dialogOptions && dialogOptions.noButtonOptions);

		const yesButtonOptions = $.extend({
			buttonType: this.buttonTypes.Yes().value,
			caption: this.buttonTypes.Yes().text,
			result: this.buttonTypes.Yes().value,
			isDefault: true,
			isDismiss: true
		}, dialogOptions && dialogOptions.yesButtonOptions);

		const yesNoButtons = [noButtonOptions, yesButtonOptions];
		return this.confirmAsync(message, title, yesNoButtons, dialogOptions);
	}

	yesNoCancelConfirmAsync(message, title) {
		return this.confirmAsync(message,
			title,
			[
				{ caption: this.buttonTypes.No().text, isDismiss: true, isNoButton: true, result: this.buttonTypes.No().value },
				{ caption: this.buttonTypes.Cancel().text, isDismiss: true, result: this.buttonTypes.Cancel().value },
				{ caption: this.buttonTypes.Yes().text, result: this.buttonTypes.Yes().value, isDismiss: true }
			]);
	}

	async warnUnsavedChangesAsync(options) {
		let dialogInfo = {};
		const buttonOptions = [{
			caption: captionService.getString('0e2ceae9-be11-4aa7-8ae6-2c3188ef0076', "Don't Save"),
			bindingString: 'asyncClick: doNotSaveAsync',
			isNoButton: true
		}, {
			caption: captionService.getString('d5765518-7e48-4d50-ae9d-40ccd9e23216', 'Cancel'),
			bindingString: 'asyncClick: cancelAsync',
			isDismiss: true
		}, {
			caption: captionService.getString('b122e480-8919-48e1-a009-15bf3b936617', 'Save'),
			bindingString: 'asyncClick: saveAsync',
			isPrimary: true,
			isDefault: true
		}];

		let isActionInvoked = false;
		let isResolved = false;
		const deferred = new DeferredPromise();
		const wrap = (action) => {
			return async () => {
				isActionInvoked = true;
				try {
					const result = await action?.();
					deferred.resolve(result);
				} catch (error) {
					deferred.reject(error);
				} finally {
					isResolved = true;
					this.hide(dialogInfo);
				}
			};
		};

		const viewModel = {
			doNotSaveAsync: wrap(options.doNotSave),
			cancelAsync: wrap(options.cancel),
			saveAsync: wrap(options.save)
		};

		const preHideCallback = (e) => {
			if (!isResolved) {
				e.preventDefault();
				if (!isActionInvoked) {
					viewModel.cancelAsync();
				}
			}
		};

		const dialogOptions = {
			title: captionService.getString('4c72e25d-c0e3-438f-8bde-a3808a38a5b2', 'Warning - Unsaved Changes'),
			body: options.body || captionService.getString('7a515406-90f0-4a59-93e1-73050c5047be', 'This record has been modified. Would you like to save the changes?'),
			notificationType: notificationType.Warning,
			viewModel,
			buttonOptions,
			preHideCallback,
			dialogID: options.dialogID
		};

		dialogInfo = await this.showDialogAsync(dialogOptions);
		return deferred.promise;
	}

	hide(dialog) {
		hideCore(dialog);
	}

	async showDialogAlertAsync(dialogInfo, message, notificationTypeIn, allowHtml) {
		const dialog = await dialogInfo;
		await showAlertAsync(dialog, message, notificationTypeIn, allowHtml);
	}

	async showAndHideDialogAlertAsync(dialogInfo, message, notificationTypeIn, allowHtml) {
		const dialog = await dialogInfo;
		return showAndHideAlertAsync(dialog, message, notificationTypeIn, allowHtml);
	}
}

function addIcon(dialog, dialogNotificationType) {
	const iconName = notificationType.getIconName(dialogNotificationType);
	const classSuffix = notificationType.toString(dialogNotificationType);
	const $icon = $('<i>').addClass(iconName).addClass(`g-icon-${classSuffix}`);
	dialog.domElements.$body.prepend($icon);
}

function cleanUp(event) {
	const $dialog = $(event?.currentTarget);
	dialogServiceProvider.offBeforeHide($dialog);
	dialogServiceProvider.offAfterHide($dialog);
	dialogServiceProvider.offBeforeShow($dialog);
	dialogServiceProvider.offAfterShow($dialog);

	const dialog = getDialog($dialog);
	window.clearTimeout(dialog.domElements.$alertBar.data('alert-timeout'));

	ko.removeNode($dialog[0]);
}

function hideCore(dialog) {
	if (dialog) {
		// hide given
		dialogServiceProvider.hide(dialog.$dialog);
	} else {
		// hide all
		$(dialogServiceProvider.selectors.modalDialog).each((_, element) => {
			const $dialog = $(element);
			if ($dialog && $dialog.attr('data-dialog-type') !== DialogTypes.ReportError) {
				dialogServiceProvider.hide($dialog);
			}
		});
	}
}

function getDialog($modalDialog) {
	const s = dialogServiceProvider.selectors;

	return {
		$dialog: $modalDialog,
		domElements: {
			$innerWrap: $(s.innerWrap, $modalDialog),
			$header: $(s.header, $modalDialog),
			$title: $(s.title, $modalDialog),
			$body: $(s.body, $modalDialog),
			$footer: $(s.footer, $modalDialog),
			$footerButtonsWrap: $(),
			$alertBar: $(s.alertBar, $modalDialog)
		}
	};
}

async function initDialogAsync(dialog, options) {
	if (options.overwriteDialog) {
		hideCore();
	}

	dialog.$dialog.attr('data-dialog-type', options.dialogType);

	if (options.dialogCss) {
		dialog.$dialog.addClass(options.dialogCss);
	}

	if (options.title) {
		setDialogTitle(dialog, options.title, options.titleAllowHtml);
	} else if (options.titleDeferred) {
		const title = await options.titleDeferred;
		setDialogTitle(dialog, title, options.titleAllowHtml);
	}

	if (options.closeOnDismissOnly) {
		dialog.domElements.$header.find('.g-modal-header-buttonpane > li:has(.g-modal-close)').remove();
	}

	setDialogHeaderAsync(dialog, options.headerButtonsDeferred, options.autoresize, options.titleBindingString, options.includeValidationSummary);
}

function setDialogPosition(dialog, options) {
	dialogServiceProvider.setDialogPosition(dialog, options, $(window));
}

function setDialogTitle(dialog, title, titleAllowHtml) {
	const $title = dialog.domElements.$title;

	/*! SuppressStringValidation Valid jquery function names */
	const funcName = titleAllowHtml ? 'html' : 'text';
	$title.empty()[funcName](title);

	const titleText = titleAllowHtml ? $title.text().trim() : htmlEscape(title);
	const bindingString = $title.attr('data-bind');
	const bindingTooltipString = global.formFactor === global.formFactors.Mobile ? 'gwTooltip: { tooltip: \'{0}\', placement: \'bottom\'}' : 'gwTooltip: \'{0}\'';
	$title.attr('data-bind', commaSeparate(bindingString, format(bindingTooltipString, titleText)));
}

async function setDialogHeaderAsync(dialog, headerButtonsDeferred, autoresize, titleBindingString, includeValidationSummary) {
	if (titleBindingString) {
		dialog.domElements.$title.attr('data-bind', titleBindingString);
	}

	const $buttonPane = dialog.domElements.$header.find('.g-modal-header-buttonpane');

	if (autoresize) {
		const maximiseAndRestoreButton = dialogServiceProvider.getMaximizeHeaderButton();

		$(maximiseAndRestoreButton)
			.prependTo($buttonPane)
			.children()
			.on('click', (event) => {
				if ($(event?.currentTarget).hasClass(constants.CssClasses.RestoreButtonVisible.Class)) {
					restoreDialog(dialog.$dialog);
				} else {
					maximizeDialog(dialog.$dialog);
				}
			});
	}

	if (includeValidationSummary) {
		/*! StartNoStringValidationRegion Suppressed in initial refactor */
		const alertPopoverHtml = '<li class="dropdown g-tasktray-alerts" data-bind="if: notificationSummary">' +
			'<!-- ko with: notificationSummary -->' +
			'<!-- ko if: hasAlerts() -->' +
			'<a class="g-header-button" href="#" data-toggle="dropdown" title="Alerts" data-bind="gwTooltip: { tooltip: $caption(\'8ab6ea65-dea6-4379-b675-cb8b1c87077c\', \'Alerts\'), placement: \'top\'}, css: { \'g-has-alerts\': hasAlerts }">' +
			'<i class="g-display-option-button icon-exclamation-triangle"></i>' +
			'<span class="g-display-option-text" data-bind="gwCaption: \'D695443A-76A4-4D8D-A76D-45335FA405E3\'">Alerts</span>' +
			'<!-- ko if: all().length -->' +
			'<span class="alert-count error-count" data-bind="text: all().length"></span>' +
			'<!-- /ko -->' +
			'</a>' +
			'<!-- /ko -->' +
			'<!-- /ko -->' +
			'<div class="dropdown-menu">' +
			'<div data-bind="component: { name: \'gwValidationSummary\', params: { scope: \'' + dialogServiceProvider.selectors.visibleDialog + '\', notificationSummary: $context.notificationSummary } }"></div>' +
			'</div>' +
			'</li>';

		/*! EndNoStringValidationRegion */

		$(alertPopoverHtml).prependTo($buttonPane);
	}

	if (headerButtonsDeferred) {
		const headerButtons = await headerButtonsDeferred;
		$(headerButtons).prependTo($buttonPane);
	}
}

function restoreDialog($dialog) {
	toggleRestoreButtonClass($dialog, false);
	/*! StartNoStringValidationRegion No captions here! */
	$dialog.find(dialogServiceProvider.selectors.content)
		.removeClass(`${constants.CssClasses.MaximizedDialog.Class} ${constants.CssClasses.MaximizedResizedDialog.Class} ${constants.CssClasses.MaximizedDraggedDialog.Class}`)
		.resizable('enable')
		.draggable('enable');
	/*! EndNoStringValidationRegion */

	$(window).trigger('resize');
}

function maximizeDialog($dialog) {
	const $content = $dialog.find(dialogServiceProvider.selectors.content);
	const top = $content.css('top');
	const left = $content.css('left');
	const position = $content.css('position');

	toggleRestoreButtonClass($dialog, true);

	if (top === 'auto' && left === 'auto') {
		$content.addClass(constants.CssClasses.MaximizedDialog.Class);
	} else if (position === 'absolute') {
		$content.addClass(constants.CssClasses.MaximizedResizedDialog.Class);
	} else {
		$content.addClass(constants.CssClasses.MaximizedDraggedDialog.Class);
	}
	/*! StartNoStringValidationRegion No captions here! */
	$content
		.resizable('disable')
		.draggable('disable');
	/*! EndNoStringValidationRegion */

	$(window).trigger('resize');
}

function toggleRestoreButtonClass($dialog, value) {
	$dialog
		.find(constants.CssClasses.RestoreMaximizeButton.Selector)
		.toggleClass(constants.CssClasses.RestoreButtonVisible.Class, value);
}

async function setDialogBodyAsync(dialog, options) {
	dialog.domElements.$body.empty().hide();

	if (options.body) {
		setDialogBodyInternal(dialog, options);
	} else if (options.bodyDeferred) {
		const body = await options.bodyDeferred;
		options.body = body;
		setDialogBodyInternal(dialog, options);
	}
}

function setDialogBodyInternal(dialog, options) {
	if (options.bodyAllowHtml) {
		dialog.domElements.$body.html(options.body);
	} else {
		dialog.domElements.$body.addClass('text-only');
		dialog.domElements.$body.text(options.body);
	}
}

async function setDialogFooterAsync(dialog, options) {
	/*! StartNoStringValidationRegion Suppressed in initial refactor */
	dialog.domElements.$footer
		.addClass('g-' + (options.buttonsOrientation || 'horizontal').toLowerCase())
		.empty()
		.hide();
	/*! EndNoStringValidationRegion */

	if (options.footerDeferred) {
		const footer = await options.footerDeferred;
		options.footer = footer;
		setDialogFooterInternal(dialog, options);
	} else {
		setDialogFooterInternal(dialog, options);
	}
}

function setDialogFooterInternal(dialog, options) {
	if (options.footer) {
		dialog.domElements.$footer.append(options.footer);
		dialog.domElements.$footer.find('button').each((_, element) => {
			dialogServiceProvider.enhanceButton($(element), dialog.$dialog);
		});
	} else {
		const $buttonsContainer = $('<div class="g-footer-buttons-wrap" data-bind="gwDialogFooterButtons: { dialog: $dialog, buttonOptions: $buttonOptions }"></div>');
		dialog.domElements.$footer.append($buttonsContainer);
		dialog.domElements.$footerButtonsWrap = $buttonsContainer;
	}

	dialog.domElements.$footer.fadeTo('default', 1);
}

async function setViewModelInternalAsync(dialog, options) {
	if (options.viewModel) {
		setViewModelBindings(dialog, options);
	} else if (options.viewModelDeferred) {
		const viewModel = await options.viewModelDeferred;
		options.viewModel = viewModel;
		setViewModelBindings(dialog, options);
	} else {
		options.viewModel = {};
		setViewModelBindings(dialog, options);
	}
}

function setViewModelBindings(dialog, options) {
	const viewModel = options.viewModel;
	const bindingContext = viewModel instanceof ko.bindingContext ?
		viewModel.createChildContext(viewModel.$rawData) :
		new ko.bindingContext(options.viewModel);
	bindingContext.$dialog = dialog;
	bindingContext.$buttonOptions = options.buttonOptions;
	bindingContext.$dialogService = options.dialogService;
	options.bindingContext = bindingContext;
}

async function populateDialogAsync(dialog, options) {
	setDialogFooterAsync(dialog, options);
	await Promise.all([
		setDialogBodyAsync(dialog, options),
		setViewModelInternalAsync(dialog, options),
	]);

	await setUpDialogUIAsync(dialog, options);

	if (options.preHideCallback) {
		dialogServiceProvider.onBeforeHide(dialog.$dialog, (event) =>
			options.preHideCallback(event, dialog, options.viewModel)
		);
	}

	if (options.afterHideCallback) {
		dialogServiceProvider.onAfterHide(dialog.$dialog, (event) =>
			options.afterHideCallback(event, dialog, options.viewModel)
		);
	}

	dialogServiceProvider.onAfterHide(dialog.$dialog, cleanUp);
}

async function showAlertAsync(dialog, message, notificationTypeIn, allowHtml) {
	let messageCssClass = '';
	switch (notificationTypeIn) {
		case notificationType.Success:
			/*! SuppressStringValidation String validation suppressed in initial refactor */
			messageCssClass = 'alert-success';
			break;
		case notificationType.Error:
			/*! SuppressStringValidation String validation suppressed in initial refactor */
			messageCssClass = 'alert-danger';
			break;
		case notificationType.MessageError:
			/*! SuppressStringValidation String validation suppressed in initial refactor */
			messageCssClass = 'alert-message-error';
			break;
		case notificationType.Information:
			/*! SuppressStringValidation String validation suppressed in initial refactor */
			messageCssClass = 'alert-info';
			break;
		case notificationType.Warning:
			/*! SuppressStringValidation String validation suppressed in initial refactor */
			messageCssClass = 'alert-warning';
			break;
	}

	const $alertBar = dialog.domElements.$alertBar;
	$alertBar
		.removeClass('alert-success alert-danger alert-info alert-warning alert-message-error')
		.addClass(messageCssClass);
	if (allowHtml) {
		$alertBar.html(message).hide();
	} else {
		$alertBar.text(message).hide();
	}
	window.clearTimeout($alertBar.data('alert-timeout'));

	if (dialog.domElements.$innerWrap.hasClass('g-modal-autoresize')) {
		dialogServiceProvider.reduceModalBody(dialog.domElements, $alertBar.outerHeight());
	}

	await $alertBar.slideDown('slow').promise();
}

async function showAndHideAlertAsync(dialog, message, notificationTypeIn, allowHtml) {
	await showAlertAsync(dialog, message, notificationTypeIn, allowHtml);
	const delayDuration = appConfig.messageTimeout || 4000;
	const alertTimeout = window.setTimeout(() => {
		dialog.domElements.$alertBar.slideUp('slow');
		if (dialog.domElements.$innerWrap.hasClass('g-modal-autoresize')) {
			dialogServiceProvider.restoreModalBody(dialog.domElements.$body);
		}
	}, delayDuration);
	dialog.domElements.$alertBar.data('alert-timeout', alertTimeout);
}

ko.bindingHandlers.gwDialogFooterButtons = {
	init: () => {
		return { controlsDescendantBindings: true };
	},
	update: (element, valueAccessor, allBindings, viewModel, bindingContext) => {
		const value = valueAccessor();

		const footerButtonsWrap = value.dialog.domElements.$footerButtonsWrap[0];
		ko.utils.emptyDomNode(footerButtonsWrap);

		if ((global.formFactor === global.formFactors.Mobile || global.formFactor === global.formFactors.Tablet)
			&& value.buttonOptions
			&& value.buttonOptions.filter((buttonOption) => buttonOption.isDismiss).length === 0) {
			value.buttonOptions.push({
				caption: ButtonTypes.Cancel().text,
				isDismiss: true
			});
		}

		let hasOptions = true;
		let visibleOptions;
		if (value.buttonOptions) {
			if (value.buttonOptions.length > 0) {
				visibleOptions = value.buttonOptions.filter((buttonOption) =>
					typeof buttonOption.isVisible === 'undefined' ||
					(buttonOption.invertIsVisible === true ? !ko.unwrap(buttonOption.isVisible) : ko.unwrap(buttonOption.isVisible)));
			} else {
				hasOptions = false;
			}
		}

		if (hasOptions) {
			dialogServiceProvider.setDialogFooter(value.dialog, visibleOptions, bindingContext);
		}

		ko.applyBindingsToDescendants(bindingContext, footerButtonsWrap);
	}
};

export default new DialogService();
