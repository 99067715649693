import captionService from "CaptionService";
import { PinSettingsKey } from "Constants";
import MenuItem, { type MenuItemViewModel } from "MenuItem";
import pinSettingsManager from "PinSettingsManager";
import userSession from "UserSession";
import ko, { type Observable } from "knockout";

interface MenuItemInfo {
  IconClass?: string;
  Key?: string;
  buttonTemplateID?: string;
  templateID?: string;
}

export default class PinnableMenuItem extends MenuItem {
  itemInfo: MenuItemInfo;
  isInOverflow: Observable<boolean>;
  isPinnable?: boolean;
  isPinned?: Observable<boolean>;
  togglePinAsync?: () => Promise<void>;
  pinCaption?: string;
  unpinCaption?: string;

  constructor(settingsKey: PinSettingsKey, itemInfo: MenuItemInfo, viewModel: MenuItemViewModel, index: number) {
    super(index, viewModel);
    this.itemInfo = itemInfo;
    this.isInOverflow = ko.observable(false);

    if (itemInfo) {
      this.iconClass = itemInfo.IconClass;
      if (settingsKey && userSession.isLoggedOn()) {
        this.isPinnable = true;

        this.isPinned = pinSettingsManager.getIsPinnedObservable(settingsKey, itemInfo.Key);

        this.togglePinAsync = (): Promise<void> => {
          return pinSettingsManager.togglePinAsync(settingsKey, itemInfo.Key);
        };
      }
      if (itemInfo.buttonTemplateID) {
        this.buttonTemplateID = itemInfo.buttonTemplateID;
      }
      if (itemInfo.templateID) {
        this.templateID = itemInfo.templateID;
      }
    }

    setCaptions(this, settingsKey);
  }

  forcePinnedMenuItem(isPinned: boolean): void {
    this.isPinned = ko.observable(isPinned);
    this.togglePinAsync = async (): Promise<void> => {
      await this.isPinned?.(!this.isPinned());
    };
  }
}

function setCaptions(menuItem: PinnableMenuItem, settingsKey: PinSettingsKey): void {
  if (settingsKey === PinSettingsKey.Actions) {
    menuItem.pinCaption = captionService.getString("16c54eb8-75b8-4bc5-b6e0-59835c92f7d4", "Pin to grid header");
    menuItem.unpinCaption = captionService.getString("04693978-8959-4399-8223-4b465083d9f2", "Unpin from grid header");
  } else {
    menuItem.pinCaption = captionService.getString("7185af12-361c-4be9-9aa1-80282d747082", "Pin to header");
    menuItem.unpinCaption = captionService.getString("e048ef15-3890-489f-9911-a8eb2d56ec6b", "Unpin from header");
  }
}
