import DependencyGraph from "DependencyGraph";
import { createDisposable, type Disposable } from "Disposable";
import type { ProposedValuesStorage } from "ProposedValueEngine";
import validationEngine from "ValidationEngine";
import type { Entity, EntityAspect, EntityManager } from "breeze-client";

const dependencyGraphs = new WeakMap<EntityManager, DependencyGraph>();

export interface EntityWithProposedValues extends Entity {
  entityAspect: EntityAspect & { proposedValues: ProposedValuesStorage };
}

export function getDependencyGraph(entityManager: EntityManager): DependencyGraph {
  let result = dependencyGraphs.get(entityManager);
  if (!result) {
    result = DependencyGraph.create();
    dependencyGraphs.set(entityManager, result);
  }

  return result;
}

export async function enableProposedValuesAsync(entity: Entity): Promise<Disposable> {
  if (!supportsProposedValues(entity)) {
    throw new Error("Entity does not support proposed values");
  }

  const { proposedValues } = entity.entityAspect;
  await proposedValues.startListeningAsync();
  return createDisposable(() => proposedValues.stopListening());
}

export function suspendValidation(entity: Entity): Disposable {
  return validationEngine.suspendValidation([entity]);
}

function supportsProposedValues(entity: Entity): entity is EntityWithProposedValues {
  return "proposedValues" in entity.entityAspect;
}
