/*! StartNoStringValidationRegion Does not contain captions */
import Promise from 'bluebird';
import breeze from 'breeze-client';
import GlowDataService from 'breeze.dataService.glow';
import CustomOData4DataService from 'CustomOData4DataService';
import oData from 'OData';
import { getODataHeaders, MetadataType } from 'ODataUtils';

const BaseDataService = CustomOData4DataService;

class LightDataService extends BaseDataService {
	constructor() {
		super();
		this.name = 'Glow.Light';
		this._pendingQueries = {};
	}

	fetchMetadata(...args) {
		return GlowDataService.prototype.fetchMetadata.apply(this, args);
	}

	// eslint-disable-next-line rulesdir/async-function-suffix
	executeQuery(mappingContext) {
		return new Promise((resolve, reject) => {
			const url = mappingContext.getUrl();
			const headers = getODataHeaders(MetadataType.None);
			oData.read(
				{ requestUri: url, headers },
				(data) => {
					const results = data.value;
					const inlineCount = Number(data['@odata.count']);
					const __next = data['@odata.nextLink'];

					resolve({ results, inlineCount, __next });
				},
				(error) => {
					reject(this.createError(error, url));
				}
			);
		});
	}
}

breeze.config.registerAdapter('dataService', LightDataService);

export default LightDataService;

/*! EndNoStringValidationRegion */
