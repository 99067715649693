import * as stringUtils from 'StringUtils';

function Colour(r, g, b, a) {
	this.red = r;
	this.green = g;
	this.blue = b;
	this.alpha = a;
}

Colour.prototype.getContrastingColour = function () {
	return getContrastingColour(this);
};

Colour.prototype.toString = function () {
	/*! SuppressStringValidation String validation suppressed in initial refactor */
	return stringUtils.format('rgba({0}, {1}, {2}, {3})', this.red, this.green, this.blue, this.alpha);
};

function getContrastingColour(colour) {
	let brightness;
	brightness = (colour.red * 299) + (colour.green * 587) + (colour.blue * 114);
	brightness = brightness / 255000;

	// values range from 0 to 1
	// anything greater than 0.5 should be bright enough for dark text
	return (brightness >= 0.5) ? new Colour(0, 0, 0, colour.alpha) : new Colour(255, 255, 255, colour.alpha);
}

export default Colour;
