import { DateTimeType } from "DateTimeConstants";
import { getLanguageCode } from "LanguageService";
import moment, {
  type Duration,
  type DurationInputArg1,
  type DurationInputArg2,
  type Moment,
} from "../../../node_modules/moment/moment.js";

export type { Duration };

export const localTimezoneOffset = new Date().getTimezoneOffset();
export const zeroDurationString = "0:00";
export const invalidOffset = 13 * 60 + 59;

export function isDuration(value: unknown): value is Duration {
  return moment.isDuration(value);
}

export function durationEpoch(): Moment {
  return moment([1901, 0, 1]);
}

export function emptyDuration(): Duration {
  return moment.duration();
}

export function createDuration(inp: DurationInputArg1, unit?: DurationInputArg2): Duration {
  return moment.duration(inp, unit);
}

export function maxDuration(): Duration {
  return moment.duration("999:59:00");
}

export function maxDurationAsDateTime(): Moment {
  return durationEpoch().add(maxDuration());
}

export function timeEpoch(): Moment {
  return moment([1900, 0, 1]);
}

export function minValue(): Moment {
  return moment.utc([1, 0, 1]);
}

export function durationFromDate(date: Date | Moment): Duration {
  const base = moment(date).year(durationEpoch().year());
  const difference = base.diff(durationEpoch());
  return moment.duration(difference);
}

export function formatStringForDateTimeType(dateTimeType: DateTimeType): string {
  switch (dateTimeType) {
    case DateTimeType.Date:
      /*! SuppressStringValidation String validation suppressed in initial refactor */
      return "DD-MMM-YY";

    case DateTimeType.Duration:
      throw new Error("DateTimeType of Duration is not supported.");

    case DateTimeType.Time:
      return "HH:mm";

    default:
      return "DD-MMM-YY HH:mm";
  }
}

export function isNonMinDate(date: unknown): date is Date {
  return isValidDate(date) && !minValue().isSame(date);
}

export function isValidDate(date: unknown): date is Date {
  return date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() >= 1;
}

function isCurrentCultureUS(): boolean {
  return getLanguageCode() === "EN-US";
}

export function formatDate(dateOrMoment: Date | Moment, formatString: string): string {
  let formattable: moment.Moment;
  if (dateOrMoment instanceof Date) {
    if (dateOrMoment.getTimezoneOffset() !== localTimezoneOffset) {
      // Do not use add/subtract with offset because that can be affected by daylight savings.
      formattable = moment(
        new Date(
          dateOrMoment.getFullYear(),
          dateOrMoment.getMonth(),
          dateOrMoment.getDate(),
          dateOrMoment.getHours(),
          dateOrMoment.getMinutes(),
          dateOrMoment.getSeconds(),
          dateOrMoment.getMilliseconds()
        )
      );
    } else {
      formattable = moment(dateOrMoment);
    }
  } else {
    formattable = dateOrMoment;
  }

  return formattable.format(formatString);
}

// The function combines date and time formats, keeping all original date formats
function combineDateAndTimeFormats(dateFormats: string[], timeFormats: string[]): string[] {
  const dateTimeFormats: string[] = [...dateFormats]; // Start with all original date formats
  dateFormats.forEach((dateFormat) => {
    /*! StartNoStringValidationRegion date formats */
    if (["D", "M", "YY"].every((format) => dateFormat.includes(format))) {
      timeFormats.forEach((timeFormat) => {
        dateTimeFormats.push(`${dateFormat} ${timeFormat}`);
        if (["H", "m", "s"].every((format) => timeFormat.includes(format))) {
          dateTimeFormats.push(`${dateFormat}T${timeFormat}`);
          dateTimeFormats.push(`${dateFormat}T${timeFormat}Z`);
        }
      });
    }
    /*! EndNoStringValidationRegion */
  });
  return dateTimeFormats;
}

function getDateFormatsSeparatedBy(separator: string, isCultureUS: boolean): string[] {
  // Define base components of a date
  /*! StartNoStringValidationRegion date formats */
  const day = "D";
  const month = "M";
  const year2 = "YY";
  const year4 = "YYYY";
  /*! EndNoStringValidationRegion */

  // Construct date formats based on the cultural convention
  const dateFormats: string[] = isCultureUS
    ? [
        // U.S. formats: Month-Day-Year
        `${month}${separator}${day}`,
        `${month}${separator}${day}${separator}${year2}`,
        `${month}${separator}${day}${separator}${year4}`,
      ]
    : [
        // European formats: Day-Month-Year
        `${day}${separator}${month}`,
        `${day}${separator}${month}${separator}${year2}`,
        `${day}${separator}${month}${separator}${year4}`,
      ];

  // ISO formats: Year-Month-Day
  const dateFormatsISO = [
    `${year4}${separator}${month}${separator}${day}`,
    `${year2}${separator}${month}${separator}${day}`,
  ];

  return dateFormats.concat(dateFormatsISO);
}

function aggregateDateFormats(separators: string[], isCultureUS: boolean): string[] {
  return separators.reduce<string[]>(
    (acc, separator) => [...acc, ...getDateFormatsSeparatedBy(separator, isCultureUS)],
    []
  );
}

export function getDateFormats(dateTimeType?: DateTimeType): string[] {
  /*! StartNoStringValidationRegion date and time formats */
  const separators = ["-", "/", " ", "", "."];
  const timeFormats = ["Hm", "H:m", "H.m", "H:m:s", "H:m:s.S", "H:m:s.SS", "H:m:s.SSS"];
  const otherFormats = ["YYYY-MM-DDTHH:mm:ss.SSSSSSS", "YYYY"];
  /*! EndNoStringValidationRegion */

  const dateFormats = aggregateDateFormats(separators, isCurrentCultureUS());

  const dateAndTimeFormats = combineDateAndTimeFormats(dateFormats, timeFormats).concat(otherFormats);
  switch (dateTimeType) {
    case DateTimeType.Duration:
      throw new Error("DateTimeType of Duration is not supported.");
    case DateTimeType.Time:
      // Some places expect a Time type data to be parsed as Datetime, e.g. https://devops.wisetechglobal.com/wtg/Glow/_git/Glow?path=%2FDotNet%2FHTML%2FClient%2FClient%2FJS%2FShared%2F__tests__%2FFormatIndexGridData.test.js&version=GBmaster&line=183&lineEnd=184&lineStartColumn=1&lineEndColumn=1&lineStyle=plain&_a=contents
      return timeFormats.concat(dateAndTimeFormats);
    default:
      return dateAndTimeFormats;
  }
}

export function replaceMonthWithNumber(value: string): string {
  if (value) {
    if (isCurrentCultureUS()) {
      value = value.replace(/^(\d+)([-\\/.]?\s*)([A-Za-z]{3})/, "$3$2$1");
    }

    const pattern = /[A-Za-z]{3}/;
    const matches = pattern.exec(value);

    if (matches && matches.length === 1) {
      const months: Record<string, string> = {
        jan: "01",
        feb: "02",
        mar: "03",
        apr: "04",
        may: "05",
        jun: "06",
        jul: "07",
        aug: "08",
        sep: "09",
        oct: "10",
        nov: "11",
        dec: "12",
      };
      const month = matches[0];
      return value.replace(month, months[month.toLowerCase()]);
    }
  }
  return value;
}

function replaceTwoDigitYearWithFourDigits(value: string): string {
  if (value) {
    const pattern = /^(\d{2})([-\\/.,]?)(\d{2})\2(\d{2})(\s(0\d|1\d|2[0-3]):?([0-5]\d))?$/;
    const matches = pattern.exec(value);

    if (matches && matches.length > 4) {
      const year = Number(matches[4]);
      const newYear = (year < 50 ? 2000 : 1900) + year;

      return value.replace(pattern, `$1$2$3$2${newYear}$5`);
    }
  }

  return value;
}

function parseISODuration(input: string, formats: string[]): Moment {
  // Check if the input is a valid ISO 8601 duration, e.g. P3Y6M4DT12H30M5S (3 years, 6 months, 4 days, 12 hours, 30 minutes, 5 seconds)
  if (!/^P(?=\d|T\d)(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?)?$/.test(input)) {
    return moment.invalid();
  }

  const duration = moment.duration(input);
  if (duration.asMilliseconds() <= 0) {
    return moment.invalid();
  }

  return moment(durationToString(duration), formats, true);
}

export function parseDate(value: string, dateTimeType?: DateTimeType): Moment {
  if (!value) {
    return moment.invalid();
  }
  //if date contains MMM, have to replace it with MM format, because of an issue with moment.js. See https://github.com/moment/moment/issues/2592
  const valueToParse = replaceTwoDigitYearWithFourDigits(replaceMonthWithNumber(value));
  const formats = getDateFormats(dateTimeType);

  let result: Moment;

  if (dateTimeType === DateTimeType.DateTimeUtc) {
    // Using strict mode to avoid parsing invalid formats, e.g. '12Batch' as a date
    result = moment.utc(valueToParse, formats, true).local();
  } else {
    // When input is in UTC format, parse it as UTC, e.g. https://devops.wisetechglobal.com/wtg/Glow/_git/Glow?path=%2FDotNet%2FHTML%2FClient%2FClient%2FJS%2FShared%2FVueExtenders%2F__tests__%2FWtgModuleSearchFormatter.test.ts&version=GBmaster&line=19&lineEnd=19&lineStartColumn=57&lineEndColumn=60&lineStyle=plain&_a=contents
    result = valueToParse.endsWith("Z") ? moment.utc(valueToParse, formats, true) : moment(valueToParse, formats, true);
    if (dateTimeType === DateTimeType.Time) {
      if (!result.isValid()) {
        // Some projects expect an ISO duration string to be parsed as Time, e.g. https://devops.wisetechglobal.com/wtg/Glow/_git/Glow?path=%2FDotNet%2FHTML%2FClient%2FClient%2FJS%2FShared%2F__tests__%2FEntityStrategyProvider.test.js&version=GBmaster&line=469&lineEnd=469&lineStartColumn=1&lineEndColumn=6&lineStyle=plain&_a=contents
        result = parseISODuration(valueToParse, formats);
      }
      const epoch = timeEpoch();
      result.year(epoch.year());
      result.month(epoch.month());
      result.date(epoch.date());
    }
  }

  // Do not need seconds info from the parsed date
  result.seconds(0).milliseconds(0);

  return result;
}

export function parseDurationExpression(input: string): Moment | null {
  const matches = /^\s*((\d{0,3})\s*:\s*(\d{0,2})|0+)\s*$/.exec(input);
  if (!matches) {
    return null;
  }

  const minutes = matches[3] ? parseInt(matches[3], 10) : 0;
  if (minutes > 59) {
    return null;
  }

  const hours = matches[2] ? parseInt(matches[2], 10) : 0;
  /*! SuppressStringValidation Time units */
  return durationEpoch().add(minutes, "m").add(hours, "h");
}

export function parseDurationExpressionWithDays(input: string): Moment | null {
  input = input.trim();
  if (!input) {
    return null;
  }

  const [days, hours, minutes] = input
    .slice()
    .replace(/\s+/g, "")
    .split(/[-/:\\]/);

  let validDays = 0;
  let validHours = 0;
  let validMinutes = 0;

  if (days) {
    const hasNonDigits = /\D/.exec(days);
    if (!hasNonDigits) {
      validDays = Number(days);
    } else {
      return null;
    }
  }

  if (hours) {
    const hasNonDigits = /\D/.exec(hours);
    if (!hasNonDigits) {
      validHours = Number(hours);
    } else {
      return null;
    }
  }

  if (minutes) {
    const hasNonDigits = /\D/.exec(minutes);
    if (!hasNonDigits) {
      validMinutes = Number(minutes);
    } else {
      return null;
    }
  }

  if (Number(validDays) > 99 || Number(validHours) > 23 || Number(validMinutes) > 59) {
    return null;
  }

  /*! SuppressStringValidation Time units */
  return durationEpoch().add(minutes, "m").add(hours, "h").add(days, "d");
}

export function parseDateExpression(expression: string, dateTimeType?: DateTimeType): Moment {
  if (expression && dateTimeType !== DateTimeType.Time) {
    expression = expression.toLowerCase().replace(/ /g, "");

    if (expression) {
      const getExpression = (expr: string): string => {
        expr = expr.substring(1);
        if (expr === "") {
          /*! SuppressStringValidation String validation suppressed in initial refactor */
          expr = "+0d";
        }
        return expr;
      };

      /*! SuppressStringValidation minute is valid param */
      const date = dateTimeType === DateTimeType.Date ? today() : moment().startOf("minute");
      switch (expression.charAt(0)) {
        case "y":
          /*! SuppressStringValidation String validation suppressed in initial refactor */
          date.subtract(1, "d");
          expression = getExpression(expression);
          break;

        case "t":
          expression = getExpression(expression);
          break;
      }

      if (date.isValid()) {
        const parsed = parseExpressionCore(expression, date);

        if (parsed.isValid()) {
          /*! SuppressStringValidation day param */
          return dateTimeType === DateTimeType.Date ? parsed.startOf("day") : parsed;
        }
      }
    }
  }

  return moment.invalid();
}

export function parseNumericShortcut(value: string): Moment {
  const isCultureUS = isCurrentCultureUS();
  const pattern = isCultureUS
    ? /^(0[1-9]|1[0-2])([./,\\-]?)(0[1-9]|[1-2][0-9]|3[0-1])\2(\d{2}|\d{4})(\s(0\d|1\d|2[0-3])([0-5]\d))?$/
    : /^(0[1-9]|[1-2][0-9]|3[0-1])([./,\\-]?)(0[1-9]|1[0-2])\2(\d{2}|\d{4})(\s(0\d|1\d|2[0-3])([0-5]\d))?$/;
  const matches = pattern.exec(replaceTwoDigitYearWithFourDigits(value));

  if (matches && matches.length === 8) {
    const day = Number(isCultureUS ? matches[3] : matches[1]);
    const month = Number(isCultureUS ? matches[1] : matches[3]) - 1;
    const year = Number(matches[4]);

    if (day <= moment([year, month]).daysInMonth()) {
      const hours = matches[6] || 0;
      const minutes = matches[7] || 0;
      return moment([year, month, day, hours, minutes]);
    }
  }

  return moment.invalid();
}

export function toDateTimeTypeString(dateOrMoment: Date | Moment, dateTimeType: DateTimeType): string {
  if (dateTimeType === DateTimeType.Duration) {
    return durationFromDate(dateOrMoment).toString();
  } else {
    const formatString = formatStringForDateTimeType(dateTimeType);
    return formatDate(dateOrMoment, formatString);
  }
}

export function today(): Moment {
  /*! SuppressStringValidation unit of time */
  return moment().startOf("day");
}

export function todayAsDate(): Date {
  return today().toDate();
}

export function applyUtcOffset(value: Moment, dateTimeType?: DateTimeType): Moment {
  if (dateTimeType !== DateTimeType.DateTimeUtc) {
    /*! SuppressStringValidation String validation suppressed in initial refactor */
    return value.subtract(value.utcOffset(), "m");
  }

  return value;
}

export function durationToString(duration: Duration): string {
  const durationParts = [Math.floor(duration.asHours()), duration.minutes().toString().padStart(2, "0")];
  return durationParts.join(":");
}

export function durationToDate(duration: Duration): Date {
  /*! SuppressStringValidation String validation suppressed in initial refactor */
  return durationEpoch().add(duration.valueOf(), "millisecond").toDate();
}

export function reverseUtcOffset(value: Moment, dateTimeType: DateTimeType): Moment {
  if (dateTimeType !== "DateTimeUtc" && !minValue().isSame(value)) {
    /*! SuppressStringValidation String validation suppressed in initial refactor */
    return value.add(value.utcOffset(), "m");
  }

  return value;
}

export function setHoursAndMinutes(value: Moment, date: Date): Moment {
  value.hours(date.getHours());
  value.minutes(date.getMinutes());
  return value;
}

export function setYearMonthAndDay(value: Moment, date: Date): Moment {
  value.year(date.getFullYear());
  value.month(date.getMonth());
  value.date(date.getDate());
  return value;
}

export function toFilterValueString(value: Moment, dateTimeType?: DateTimeType): string {
  const date = dateTimeType === DateTimeType.DateTimeUtc ? value.clone().utc() : value;
  return date.format("YYYY-MM-DDTHH:mm:ss");
}

export function trimSeconds(value: Moment): Moment {
  return value.seconds(0).milliseconds(0);
}

export function isToday(localSentDate: Date): boolean {
	const todayDate = new Date();
	return localSentDate.getFullYear() === todayDate.getFullYear()
      && localSentDate.getMonth() === todayDate.getMonth()
      && localSentDate.getDate() === todayDate.getDate();
}

export function formatDateStringIntoUTC(date: string): string {
  return date.endsWith("Z") ? date : date + "Z";
}

function parseExpressionCore(expression: string, date: Moment): Moment {
  if (["+", "-"].indexOf(expression.charAt(0)) !== -1) {
    let isValid = false;
    const expPlusArr = expression.split("+");
    expPlusArr.forEach((exp) => {
      const expArr = exp.split("-");

      for (let index = 0; index < expArr.length; index++) {
        const set = expArr[index];
        let num: string, unit: string;
        if (set.slice(-2) === "mo") {
          /*! SuppressStringValidation String validation suppressed in initial refactor */
          unit = "M";
          num = set.substring(0, set.length - 2);
        } else {
          unit = set.slice(-1);
          if (/^\d+$/.test(unit)) {
            num = set;
            /*! SuppressStringValidation String validation suppressed in initial refactor */
            unit = "d";
          } else {
            num = set.substring(0, set.length - 1);
          }
        }

        if (/^\d+$/.test(num)) {
          const value = Number(num) * (index === 0 ? 1 : -1);
          date.add(value, unit as moment.unitOfTime.Base);
          isValid = true;
        }
      }
    });

    return isValid ? date : moment.invalid();
  } else {
    const numericExpression = replaceMonthWithNumber(expression);
    return parseNumericShortcut(numericExpression);
  }
}
