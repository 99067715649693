import { using } from "Disposable";
import { isDeletedOrDetached } from "EntityExtensions";
import { getEntityType } from "EntityMetadataExtensions";
import { getPropertyValueAsync } from "EntityPropertyExtensions";
import { enableProposedValuesAsync, suspendValidation } from "EntityRuleExtensions";
import { newGuid } from "GuidGenerator";
import proposedValueEngine from "ProposedValueEngine";
import { synchronizeAsync } from "Synchronizer";
import breeze, { type Entity, type EntityManager, type EntityType } from "breeze-client";

enum InitiallyUnchangedState {
  Initializing,
  Initialized,
  Changed,
}

export interface EntityCreationOptions<T> {
  /** Whether to apply default proposed value rules. Defaults to true if not specified. */
  applyDefaultValues?: boolean;
  /**
   * An initialization function to invoke after creating the entity. Proposed values are
   * enabled and validation is suspended during this time.
   */
  initialize?: (result: T) => Promise<void> | void;
  /**
   * Whether the created entity should be in the special "initially unchanged" state. The
   * entity starts off in the Unchanged state and becomes Added when modified.
   */
  initiallyUnchanged?: boolean;
}

/**
 * Creates a new Breeze entity instance.
 *
 * @param entityManager The EntityManager to create the entity in.
 * @param entityType The type of the entity to create.
 * @param options
 * * applyDefaultValues
 * > Whether to apply default proposed value rules. Defaults to true if not specified.
 *
 * * initialize
 * > An initialization function to invoke after creating the entity. Proposed values are enabled and
 *   validation is suspended during this time.
 *
 * * initiallyUnchanged
 * > Whether the created entity should be in the special "initially unchanged" state. The entity
 *   starts off in the Unchanged state and becomes Added when modified.
 *
 * @returns A promise that resolves with the created entity.
 */
export async function createEntityAsync<T extends Entity>(
  entityManager: EntityManager,
  entityType: EntityType | string,
  options?: EntityCreationOptions<T>
): Promise<T> {
  if (typeof entityType === "string") {
    entityType = getEntityType(entityManager, entityType);
  }

  const applyDefaultValues = options?.applyDefaultValues !== false;
  const initialize = options?.initialize;
  const initiallyUnchanged = options?.initiallyUnchanged
    ? { state: InitiallyUnchangedState.Initializing }
    : undefined;

  const keyName = entityType.keyProperties[0].name;
  const initialValues = { [keyName]: newGuid() };
  const entityState = initiallyUnchanged ? breeze.EntityState.Unchanged : undefined;
  const result = entityManager.createEntity(entityType, initialValues, entityState) as T;

  if (initiallyUnchanged) {
    decorateInitiallyUnchanged(result, initiallyUnchanged);
  }

  if (applyDefaultValues || initialize) {
    await using(await enableProposedValuesAsync(result), () =>
      using(suspendValidation(result), async () => {
        if (applyDefaultValues) {
          await proposedValueEngine.applyDefaultValuesAsync(result);
        }
        if (initialize) {
          await initialize(result);
        }
      })
    );
  }

  if (initiallyUnchanged) {
    initiallyUnchanged.state = InitiallyUnchangedState.Initialized;
  }

  return result;
}

/**
 * Get existing or create new related object for entity
 *
 * @param entity The parent entity.
 * @param propertyName The related property name.
 * @returns A promise that resolves with the related property value.
 */
export async function getOrCreateRelatedObjectAsync<T extends Entity>(
  entity: Entity,
  propertyName: string
): Promise<T> {
  if (isDeletedOrDetached(entity)) {
    throw new Error("You cannot get or create related entity of a deleted or detached entity.");
  }

  let relatedEntity = await getPropertyValueAsync<T>(entity, propertyName);
  if (relatedEntity) {
    return relatedEntity;
  }

  relatedEntity = await synchronizeAsync<T>(entity, async () => {
    let relatedEntity = await getPropertyValueAsync<T>(entity, propertyName);
    if (relatedEntity) {
      return relatedEntity;
    }

    const entityType = entity.entityType.getNavigationProperty(propertyName).entityType;
    relatedEntity = await createEntityAsync(entity.entityAspect.entityManager, entityType);
    entity.setProperty(propertyName, relatedEntity);
    return relatedEntity;
  });

  return relatedEntity;
}

function decorateInitiallyUnchanged(
  entity: Entity,
  stateHolder: { state: InitiallyUnchangedState }
): void {
  const { setEntityState } = entity.entityAspect;
  entity.entityAspect.setEntityState = function (entityState): void {
    switch (stateHolder.state) {
      case InitiallyUnchangedState.Initializing:
        if (!entityState.isUnchangedOrModified()) {
          throw new Error(
            `Cannot set entity state to ${entityState} while initializing entity that is initially unchanged.`
          );
        }
        break;

      case InitiallyUnchangedState.Initialized:
        if (entityState.isUnchanged()) {
          return;
        } else if (entityState.isModified()) {
          entityState = breeze.EntityState.Added;
        } else if (entityState.isDeleted()) {
          entityState = breeze.EntityState.Detached;
        }

        stateHolder.state = InitiallyUnchangedState.Changed;
        setEntityState.call(this, entityState);
        break;

      default:
        setEntityState.call(this, entityState);
        break;
    }
  };
}
