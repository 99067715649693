import ko from "knockout";

export function addWithoutMutating<T>(array: Array<T>, value: T): Array<T> {
  array = array ? array.slice() : [];
  array.push(value);
  return array;
}

export function removeWithoutMutating<T>(array: Array<T>, value: T): Array<T> {
  const index = array ? array.indexOf(value) : -1;
  if (index > -1) {
    array = array.slice();
    array.splice(index, 1);
  }
  return array;
}

export function findWhereUnwrap<T>(array: Array<T>, attrs: T): T | undefined {
  return array
    ? array.find((item) => {
        for (const key in attrs) {
          if (ko.unwrap(item[key]) !== attrs[key]) {
            return false;
          }
        }

        return true;
      })
    : undefined;
}
