import global from 'Global';
import { joinUri } from 'UriUtils';
import ajaxService from 'AjaxService';

function openUrl(url, openInSameWindow) {
	/*! SuppressStringValidation _self opens link in current page */
	openInSameWindow ? window.open(url, '_self') : window.open(url);
}

export default {
	async redirectWithAutologinAsync(destinationUrl, openInSameWindow) {
		if (!destinationUrl) {
			throw new Error('destinationUrl must not be empty');
		}
		const autoLoginUrl = joinUri(
			global.serviceUri,
			'autologon/url?baseUrl=' + encodeURIComponent(destinationUrl)
		);

		try {
			const url = await ajaxService.postAsync(autoLoginUrl);

			if (!url) {
				openUrl(destinationUrl, openInSameWindow);
			} else {
				openUrl(url, openInSameWindow);
			}
		} catch (e) {
			if (!(e instanceof ajaxService.AjaxError)) {
				throw e;
			}
			openUrl(destinationUrl, openInSameWindow);
		}
	},
};
