import captionService from "CaptionService";
import { NestedError } from "NestedError";
import { isNumber } from "NumericService";
import { getODataErrorMessage, type ErrorObject } from "ODataUtils";
import userSession from "UserSession";
import type { HttpOData } from "ts-odatajs";
import type { ErrorData } from "./Errors";

export class DataServiceRequestError extends NestedError {
  override readonly name = "DataServiceRequestError";
  readonly status?: number;
  readonly innerMessage?: string;
  /*! SuppressStringValidation property name */
  readonly request?: DataServiceRequestErrorData["request"];
  /*! SuppressStringValidation property name */
  readonly response?: DataServiceRequestErrorData["response"];

  constructor(uri?: string, data?: DataServiceRequestErrorData, innerError?: Error) {
    const innerMessage = getInnerMessage(data);
    const status = getStatusCode(data);
    const message = getMessage(uri, innerMessage, status);

    super(message, innerError);

    this.status = status;
    this.innerMessage = innerMessage;
    this.request = data?.request;
    this.response = data?.response;
  }

  getData(): ErrorData[] {
    const result: ErrorData[] = [{ name: "userIsLoggedIn", value: userSession.isLoggedOn() }];

    if (this.request) {
      result.push({ name: "RequestObject", value: JSON.stringify(this.request) });
    }

    if (this.response) {
      result.push({ name: "ResponseObject", value: JSON.stringify(this.response) });
    }

    return result;
  }

  friendlyMessage(): string {
    return (
      this.innerMessage ??
      captionService.getString(
        "74faf139-d693-4011-a5d0-fbaf0e375d7b",
        "The server did not process your action successfully."
      )
    );
  }

  isTransientError(): boolean {
    return !!this.status && this.status >= 500 && this.status < 600;
  }
}

function getInnerMessage(data: DataServiceRequestErrorData | undefined): string | undefined {
  if (!data) {
    return;
  }

  if (data.response?.body) {
    let body: ErrorObject | undefined;
    try {
      body = JSON.parse(data.response.body);
    } catch (e) {
      // Don't care.
    }

    const message = getODataErrorMessage(body);

    if (message) {
      return message;
    }
  }

  if (data.message) {
    return data.message;
  }

  return;
}

function getStatusCode(data: DataServiceRequestErrorData | undefined): number | undefined {
  if (data) {
    if (isNumber(data.status)) {
      return data.status;
    }
    if (data.response?.statusCode) {
      return parseInt(data.response.statusCode);
    }
  }

  return;
}

function getMessage(uri: string | undefined, innerMessage: string | undefined, status: number | undefined): string {
  let message = `Data service request failed for URI: ${uri}`;
  if (status) {
    message += `; Status code: ${status}`;
  }
  if (innerMessage) {
    message += `; ${innerMessage}`;
  }

  return message;
}

type DataServiceRequestErrorData = {
  message?: string;
  request?: HttpOData.Request;
  status?: number;
  response?: {
    requestUri: string;
    statusCode: string;
    statusText: string;
    headers?: Record<string, string>;
    body?: string;
  };
};
