import $ from 'jquery';

class DesktopTooltipServiceExtender {
	extend($target) {
		$target.on('show.bs.tooltip', (e) => {
			/*! SuppressStringValidation tooltip-no-hide is css class */
			$('.tooltip').not(e?.currentTarget).not('.tooltip-no-hide').tooltip('hide');
		});
	}
}

export default new DesktopTooltipServiceExtender();

