import captionService from 'CaptionService';

export const layouts = {
	confirmDelete: (params) => captionService.getString('75bf9aaf-b9f9-40d2-89c8-6d5a39859f72', 'Are you sure you want to delete {0}?', ...params),
	confirmDeletePublic: (params) => captionService.getString('629c0c9a-2c5e-41cd-9abd-cb39b8692e30', 'Warning: This is a published filter that is available to all users. Are you sure you want to delete {0}?', ...params),
	confirmDeleteTitle: () => captionService.getString('c999bc53-b4ef-418d-aab3-e44479de0667', 'Delete Filter'),
	confirmOverride:
		(params) => captionService.getString('5dba3058-1ef8-4db9-93ad-3c28531c923a', '{0} already exists. Are you sure you want to replace the existing filter?', ...params),
	confirmOverrideTitle: () => captionService.getString('9760eb5d-31ab-48b1-9ddd-dc7d67a442cb', 'Warning - Replace Filter'),
	editLayout: () => captionService.getString('b98cb336-46ac-42c5-a034-115c0c64a037', 'Edit Filter'),
	includeFilter: () => captionService.getString('e5178a5f-0323-47ab-bc13-b58d654a860e', 'Include Filter'),
	includeHeaders: () => captionService.getString('28826099-2215-4fac-900a-7cedf6b0e763', 'Include Columns'),
	includeColorScheme: () => captionService.getString('29cdfa92-c3b3-4e31-a3de-aad2fcab6925', 'Include Color Scheme'),
	includeDense: () => captionService.getString('c4cd536f-c06f-4820-a530-31361eef5be1', 'Include Dense'),
	dense: () => captionService.getString('8b585bd3-8687-4d2e-ba9e-fe670ab3ddb9', 'Dense'),
	layoutName: () => captionService.getString('6981d05b-2072-4176-8ab2-deb8c1716ce6', 'Filter Name'),
	layouts: () => captionService.getString('6246a132-a514-497e-a351-4688c8232fb5', 'Filters'),
	manageLayouts: () => captionService.getString('4184f226-68ec-4d52-8d86-0f88c22b358c', 'Manage Filters'),
	noAvailableLayouts: () => captionService.getString('f6a41e2a-1769-48e1-a9ed-7751a966f973', 'There are no available filters'),
	resetLayout: () => captionService.getString('17e3c93e-2590-4975-9ae5-73d4519eff28', 'Reset Current'),
	saveLayout: () => captionService.getString('4ab539c8-9ae0-4669-8f84-9160f704c7da', 'Save Current'),
	viewLayouts: () => captionService.getString('0b46c14f-e6a1-43ff-8b0d-4c421d7c2c61', 'Manage Filters'),
};
