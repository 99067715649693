import $ from 'jquery';
import toastr from 'toastrbase';

toastr.messageError = (message, title, optionsOverride) => {
	/*! SuppressStringValidation String validation suppressed in initial refactor */
	const messageErrorOptions = $.extend({ iconClass: 'toast-message-error'}, optionsOverride);
	return toastr.info(message, title, messageErrorOptions);
};

export default toastr;
