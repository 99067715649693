import dependency2 from 'Dependency2';

function Dependency() {
}

Dependency.getValue = (data, path, options) => {
	return dependency2.getValue(data, path, options).value;
};

Dependency.loadValueAsync = (data, path, options) => {
	return dependency2.getValueAsync(data, path, options).get('value');
};

Dependency.getUltimateDataItem = (data, path, options) => {
	return dependency2.getUltimateDataItem(data, path, options).value;
};

Dependency.loadUltimateDataItemAsync = (data, path, options) => {
	return dependency2.getUltimateDataItemAsync(data, path, options).get('value');
};

Dependency.trySetValueAsync = (data, path, value) => {
	return dependency2.trySetValueAsync(data, path, value);
};

Dependency.prototype.getValue = (data, path, options) => {
	return dependency2.getValue(data, path, options).value;
};

Dependency.prototype.loadValueAsync = (data, path, options) => {
	return dependency2.getValueAsync(data, path, options).get('value');
};

Dependency.prototype.getUltimateDataItem = (data, path, options) => {
	return dependency2.getUltimateDataItem(data, path, options).value;
};

Dependency.prototype.loadUltimateDataItemAsync = (data, path, options) => {
	return dependency2.getUltimateDataItemAsync(data, path, options).get('value');
};

Dependency.prototype.trySetValueAsync = (data, path, value) => {
	return dependency2.trySetValueAsync(data, path, value);
};

export default Dependency;
