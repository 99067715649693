import { BrowserName } from "BrowserNames";
import { DateTimeType } from "DateTimeConstants";
import { DeviceType } from "DeviceTypes";
import global from "Global";
import { State } from "StateConstants";
import { UserType } from "UserTypeConstants";

export interface CssClassType {
  Class: string;
  Selector: string;
}

/*! StartNoStringValidationRegion (type names) */
//eslint-disable-next-line @typescript-eslint/naming-convention -- Variable name ActionsMenuItem must be camelCase
export const ActionsMenuItem = {
  CustomizeColumns: { Key: 1, IconClass: "icon-table" },
  Import: { Key: 2, IconClass: "icon-download" },
  Export: { Key: 3, IconClass: "icon-upload" },
  Map: { Key: 4, IconClass: "icon-map-all" },
  ClearMap: { Key: 5, IconClass: "icon-eraser" },
  New: { Key: 6, IconClass: "icon-plus-circle" },
  Refresh: { Key: 7, IconClass: "icon-refresh" },
  Filter: { Key: 8, IconClass: "icon-filter" },
  DisplayOptions: { Key: 9, IconClass: "icon-th-list" },
  ColorScheme: { Key: 10, IconClass: "icon-paint-brush" },
  FilterTag: { Key: 11 },
  Attach: { Key: 12, IconClass: "icon-paperclip" },
  Xlsx: { Key: 13, IconClass: "icon-upload" },
};

export enum AnchorEdge {
  Left = "Left",
  Top = "Top",
  Right = "Right",
  Bottom = "Bottom",
  None = "None",
}

export enum AlertDisplayTime {
  Short = "0",
  Long = "1",
  UntilDismissed = "2",
}

//eslint-disable-next-line @typescript-eslint/naming-convention -- Variable name BackgroundImage must be camelCase
export const BackgroundImage = {
  Size: {
    Auto: "Auto",
    Cover: "Cover",
    Contain: "Contain",
  },
  Position: {
    Center: "50% 50%",
    Left: "0% 50%",
    Right: "100% 50%",
    Bottom: "50% 100%",
    Top: "50% 0%",
  },
  Repeat: {
    Both: "repeat",
    None: "no-repeat",
    Horizontal: "repeat-x",
    Vertical: "repeat-y",
  },
};

export enum ColumnType {
  Address = "Address",
  Boolean = "Boolean",
  DateTime = "DateTime",
  Measure = "Measure",
  Number = "Number",
  String = "String",
}

export enum ConfigurationRight {
  Branding = "branding",
  Form = "form",
  Help = "help",
}

export enum ConfigurationKey {
  CoBranding = "CoBranding",
  Theme = "Theme",
  AnonymousTrackingFields = "AnonymousTrackingFields",
}

export enum ConfigurationTmplParentTableCode {
  Form = "VZ",
  FormFlow = "FF3",
  Module = "V9",
  System = "ZZ",
  WorkflowTmpl = "VM",
}

//eslint-disable-next-line @typescript-eslint/naming-convention -- Variable name CssClass must be camelCase
export const CssClass = {
  AdditionalFields: getCssClass("g-additional-fields"),
  AdditionalPanels: getCssClass("g-additional-panels"),
  ConfigurableContainer: getCssClass("g-configurable-container"),
  ConfigurableCustom: getCssClass("g-configurable-custom"),
  ConfigurableDefault: getCssClass("g-configurable-default"),
  ConfigurableShell: getCssClass("g-configurable-shell"),
  ConfigurableWidget: getCssClass("g-configurable-widget"),
  ConfigWidgetStyleValidation: getCssClass("g-config-widget-style-validation"),
  FormConfigurationWrapper: getCssClass("g-form-configuration-wrapper"),
  ModalsContainer: getCssClass("g-modals-container"),
  ModalThemeConfigurator: getCssClass("g-modal-theme-configurator"),
  OverlapContainer: getCssClass("g-overlap-container"),
  OverlapWidget: getCssClass("g-overlap-widget"),
  PageShell: getCssClass("g-page-shell"),
  VueApp: getCssClass("g-app-container"),
  Selected: getCssClass("g-selected"),
  StackContainer: getCssClass("g-stack-container"),
  CriticalStatus: getCssClass("status-critical"),
  CheckStatus: getCssClass("status-check"),
  OkStatus: getCssClass("status-ok"),
  RestoreMaximizeButton: getCssClass("g-restore-maximize-button"),
  RestoreButtonVisible: getCssClass("g-restore-button-visible"),
  MaximizedDialog: getCssClass("g-maximized-dialog"),
  MaximizedResizedDialog: getCssClass("g-maximized-resized-dialog"),
  MaximizedDraggedDialog: getCssClass("g-maximized-dragged-dialog"),
  GlowVueComponent: getCssClass("glow-vue-component"),
};

export enum DateTimeFormat {
  ShortDateFormat = "DD-MMM-YY",
  ISO8601ShortDateFormat = "YYYY-MM-DD",
  ShortTimeFormat = "HH:mm",
  LongTimeFormat = "DD-MMM-YY HH:mm:ss",
  BestReadableDateFormat = "DD MMM YYYY",
  BestReadableDateTimeFormat = "DD MMM YYYY HH:mm",
  DayOfWeekDateFormat = "ddd D MMM",
  DayOfWeekDateFormatWithYear = "ddd D MMM YYYY",
  DayOfWeekDateFormatLong = "ddd, Do MMM",
}

export enum DeliveryErrorReason {
  Delivered = "Delivered",
  NotInDeliverableState = "NotInDeliverableState",
  DocumentNotRetrievable = "DocumentNotRetrievable",
  CouldNotBeDelivered = "CouldNotBeDelivered",
  DocumentIsNotValid = "DocumentIsNotValid",
  SessionNotSaved = "SessionNotSaved",
  UserIsNotStaff = "UserIsNotStaff",
}

export enum DisplayModeSetting {
  Header = "HeaderLinksDisplayMode",
  Grid = "GridLinksDisplayMode",
  Filter = "FilterLinksDisplayMode",
  AlertDisplayTime = "AlertDisplayTime",
}

export enum ResizeAxis {
  Both = "both",
  Horizontal = "x",
  None = "none",
  Vertical = "y",
}

export enum DataType {
  Byte = "System.Byte",
  Boolean = "System.Boolean",
  Datetime = "System.DateTime",
  DatetimeOffset = "System.DateTimeOffset",
  Decimal = "System.Decimal",
  Integer = "System.Int32",
  Long = "System.Int64",
  Short = "System.Int16",
  String = "System.String",
}

export enum DropDownDisplayMode {
  CodeOnly = "CodeOnly",
  DescOnly = "DescOnly",
  CodeDesc = "CodeDesc",
  Unspecified = "Unspecified",
}

export enum FieldColumnDisplayMode {
  Mandatory = "Mandatory",
  Default = "Default",
  Optional = "Optional",
  FilterOnly = "FilterOnly",
}

//eslint-disable-next-line @typescript-eslint/naming-convention -- Variable name Filter must be camelCase
export const Filter = {
  CollectionOperators: {
    Exists: "Exists",
    ForAll: "ForAll",
    NotExists: "NotExists",
  },
};

export enum FilterDisplayMode {
  InlineAndPopup = "InlineAndPopup",
  PopupOnly = "PopupOnly",
  InlineOnly = "InlineOnly",
  Hidden = "Hidden",
}

export enum FilterFactory {
  Entity = "Entity",
  EntityInfo = "EntityInfo",
  ColorSchemeEntityInfo = "ColorSchemeEntityInfo",
  IgnoreStringCase = "IgnoreStringCase",
}

export enum FinderItemPosition {
  Top = "Top",
  Bottom = "Bottom",
}

export enum GridAction {
  Export = "Export",
  Import = "Import",
}

export enum GridRowAction {
  eDocs = "eDocs",
  Messages = "Messages",
  Notes = "Notes",
  Logs = "Logs",
  Documents = "Documents",
  Workflow = "Workflow",
  Tracking = "Tracking",
  Activation = "Activation",
  Remove = "Remove",
}

export enum GridRowActionsMode {
  RowAndMenu = "RowAndMenu",
  None = "None",
  MenuItems = "MenuItems",
  ExpandedRow = "ExpandedRow",
}

export enum HeaderDisplayMode {
  Visible = "Visible",
  Hidden = "Hidden",
}

export enum HeaderAlignment {
  Left = "Left",
  Center = "Center",
  Right = "Right",
}

//eslint-disable-next-line @typescript-eslint/naming-convention -- Variable name HeaderMenuItem must be camelCase
export const HeaderMenuItem = {
  Documents: { Key: 1 },
  EDocs: { Key: 2, IconClass: "icon-edoc" },
  Notes: { Key: 3, IconClass: "icon-sticky-note-o" },
  Jump: { Key: 4, IconClass: "icon-mail-forward" },
  MyProfile: { Key: 5, IconClass: "icon-user" },
  Track: { Key: 6, IconClass: "icon-location-arrow" },
  Logs: { Key: 7 },
  Help: { Key: 9, IconClass: "icon-question-circle" },
  ConfigureHelp: { Key: 10, IconClass: "icon-gear" },
  ELearning: { Key: 11, IconClass: "icon-graduation-cap" },
  About: { Key: 12, IconClass: "icon-info-circle" },
  Configure: { Key: 13 },
  Workflow: { Key: 14 },
  ERequestPortal: { Key: 15, IconClass: "icon-manageRequest" },
  NewERequest: { Key: 16, IconClass: "icon-addRequest" },
  Messages: { Key: 18, IconClass: "icon-commenting-o" },
  GlobalDashboard: { Key: 19, IconClass: "icon-globe" },
  GPSDisclaimer: { Key: 20, IconClass: "icon-compass" },
  Favorites: {
    Key: 21,
    IconClass: "icon-star",
    buttonTemplateID: "favoritesButtonTemplate",
    templateID: "favoritesMenuTemplate",
  },
  Recents: {
    Key: 22,
    IconClass: "icon-clock-o",
    buttonTemplateID: "recentsButtonTemplate",
    templateID: "recentsMenuTemplate",
  },
  BoardMeetingMode: { Key: 101 },
  BoardSearch: { Key: 102 },
  BoardRefreshTimer: { Key: 103 },
  BoardSettings: { Key: 104 },
};

export enum Icon {
  Actions = "icon-actions",
  Arrows = "icon-arrows",
  ArrowsHorizontal = "icon-arrows-h",
  Barcode = "icon-barcode",
  Building = "icon-building-o",
  Calendar = "icon-calendar",
  CaretDown = "icon-caret-square-o-down",
  CheckSquare = "icon-check-square-o",
  Close = "icon-close",
  Cog = "icon-cog",
  Comment = "icon-comment-o",
  CounterTile = "icon-counter-tile",
  ExclamationTriangle = "icon-exclamation-triangle",
  Eye = "icon-eye",
  Font = "icon-font",
  Image = "icon-image",
  Link = "icon-link",
  List = "icon-list-alt",
  LocationArrow = "icon-location-arrow",
  MapMarker = "icon-map-marker",
  Motorcycle = "icon-motorcycle",
  Numeric = "icon-numeric",
  ObjectGroup = "icon-object-group",
  Plane = "icon-plane",
  Reply = "icon-reply",
  Rocket = "icon-rocket",
  Rules = "icon-rules",
  Search = "icon-search",
  SearchList = "icon-search-list",
  Ship = "icon-ship",
  Signature = "icon-signature",
  Star = "icon-star-o",
  Table = "icon-table",
  Tag = "icon-tag",
  TableHeader = "icon-th-large",
  TaskStack = "icon-TaskStack",
  Tile = "icon-tile",
  TimesCircle = "icon-times-circle",
  TrackingSearchList = "icon-tracking-search-list",
  Train = "icon-train",
  Truck = "icon-truck",
  ToggleOn = "icon-toggle-on",
}

export enum JobDocAddressEditMode {
  FreeTextOnly = "FreeTextOnly",
  RealAddressOnly = "RealAddressOnly",
  RealAddressAndFreeText = "RealAddressAndFreeText",
}

export enum JobDocAddressFilterMode {
  Address = "Address",
  Company = "Company",
}

export enum LabelAlignment {
  TopLeft = "TopLeft",
  TopCenter = "TopCenter",
  TopRight = "TopRight",
  CenterLeft = "CenterLeft",
  Center = "Center",
  CenterRight = "CenterRight",
  BottomLeft = "BottomLeft",
  BottomCenter = "BottomCenter",
  BottomRight = "BottomRight",
  Justify = "Justify",
}

export enum LabelNotificationType {
  Information = "Information",
  Success = "Success",
  Warning = "Warning",
  MessageError = "MessageError",
  Error = "Error",
}

export enum LabelStyle {
  Default = "Default",
  Header1 = "Header1",
  Header2 = "Header2",
  Note = "Note",
}

export enum LabelPaddingSize {
  None = "None",
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
}

export enum LabelTextSize {
  ExtraSmall = "ExtraSmall",
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
  ExtraLarge = "ExtraLarge",
  ExtraExtraLarge = "ExtraExtraLarge",
}

export enum LabelType {
  NormalLabel = "NormalLabel",
  Notification = "Notification",
}

export enum LinksDisplayMode {
  ButtonsAndText = "0",
  ButtonsOnly = "1",
  TextOnly = "2",
}

export enum LinkTargetAttributeValue {
  Blank = "_blank",
  Self = "_self",
}

export enum LogonProviderType {
  Staff = "Staff",
  Contact = "Contact",
  Person = "Person",
}

//eslint-disable-next-line @typescript-eslint/naming-convention -- Variable name MaterialDesignPageType must be camelCase
export const MaterialDesignPageType = {
  Notes: { Name: "notes", Shell: "NotesShellVue.ejs" },
  Tracking: { Name: "tracking", Shell: "TrackingShellVue.ejs" },
  WorkflowAuditLogs: { Name: "workflowAuditLogs", Shell: "WorkflowAuditLogsShellVue.ejs" },
  WorkflowEvents: { Name: "workflowEvents", Shell: "WorkflowEventsShellVue.ejs" },
  WorkflowMilestones: { Name: "workflowMilestones", Shell: "WorkflowMilestonesShellVue.ejs" },
};

export enum MapView {
  Roadmap = "roadmap",
  Satellite = "satellite",
}

//eslint-disable-next-line @typescript-eslint/naming-convention -- Variable name OptionButtonGrid must be camelCase
export const OptionButtonGrid = {
  GridColumns: {
    SingleRow: "0",
    OneColumn: "1",
    TwoColumn: "2",
    ThreeColumn: "3",
    FourColumn: "4",
  },
  IconPosition: {
    Left: "Left",
    Top: "Top",
    Right: "Right",
    Bottom: "Bottom",
  },
  IconSize: {
    Default: "Default",
    M: "M",
    L: "L",
    XL: "XL",
    XXL: "XXL",
  },
};

//eslint-disable-next-line @typescript-eslint/naming-convention -- Variable name PageType must be camelCase
export const PageType = {
  Form: { Name: "form", Shell: global.materialDesign ? "PageShellVue.ejs" : "PageShell.ejs" },
  Logon: { Name: "logon", Shell: "LogonShell.ejs" },
  LogonTask: {
    Name: "logonTask",
    Shell: global.materialDesign ? "LogonTaskShellVue.ejs" : "LogonShell.ejs",
  },
  Page: { Name: "page", Shell: global.materialDesign ? "PageShellVue.ejs" : "PageShell.ejs" },
  Task2: {
    Name: "task2",
    Shell: global.materialDesign ? "TaskShellVue.ejs" : "TaskShell2.ejs",
    FormFactorSpecific: !global.materialDesign,
  },
  DialogTask: { Name: "dialogTask", Shell: "DialogTaskShell.ejs", FormFactorSpecific: true },
  DialogTaskVue: { Name: "dialogTaskVue", Shell: "DialogTaskShellVue.ejs" },
  ConfigurationFormFlow: {
    Name: "configurationFormFlow",
    Shell: "ConfigurationFormFlowShell.ejs",
    FormFactorSpecific: true,
  },
  ConfigurationPage: {
    Name: "configurationPage",
    Shell: "ConfigurationPageShell.ejs",
    FormFactorSpecific: true,
  },
  ImportMappingPage: {
    Name: "ImportMappingStandalone",
    Shell: "Desktop/DataAutomation/ImportMappingStandalonePage.ejs",
  },
};

//eslint-disable-next-line @typescript-eslint/naming-convention -- Variable name Placeholder must be camelCase
export const Placeholder = {
  ReturnName: {
    OpenInNewWindow: "OpenInNewWindow",
    DisplayMode: "DisplayMode",
    ButtonDisplayMode: "ButtonDisplayMode",
    GridColumns: "GridColumns",
    Size: "Size",
    CaptionOverride: "CaptionOverride",
    Anchor: "Anchor",
    Title: "Header",
    HeaderDisplayMode: "HeaderDisplayMode",
    Source: "URL",
    CountryVisible: "IsCountryVisible",
    FaxVisible: "IsFaxVisible",
    StreetDetailsVisible: "IsStreetDetailsVisible",
    UseReferenceData: "UseLocalityData",
    Header: "Header",
    EditMode: "EditMode",
    ImageOverride: "ImageOverride",
    BackgroundImageOverride: "BackgroundImageOverride",
    BackgroundSize: "BackgroundSize",
    BackgroundThemeColor: "BackgroundThemeColor",
    ForegroundThemeColor: "ForegroundThemeColor",
    Content: "Content",
    LabelAlignment: "LabelAlignment",
    LabelText: "LabelText",
    Text: "Text",
    ItemPosition: "ItemPosition",
    ShowFavorites: "ShowFavorites",
    ShowRecents: "ShowRecents",
    Color: "Color",
    Image: "Image",
    VisibilityCondition: "VisibilityCondition",
    IsIconVisible: "IsIconVisible",
    IconPosition: "IconPosition",
    IconSize: "IconSize",
    PagePK: "PagePK",
    FilterType: "FilterType",
    EntityType: "EntityType",
    Category: "Category",
    DefaultFilter: "DefaultFilter",
  },
  LocalName: {
    OpenInNewWindow: "openInNewWindow",
    DisplayMode: "displayMode",
    ButtonDisplayMode: "buttonDisplayMode",
    GridColumns: "gridColumns",
    Size: "size",
    CaptionOverride: "captionOverride",
    Anchor: "anchor",
    Title: "title",
    HeaderDisplayMode: "headerDisplayMode",
    Source: "source",
    CountryVisible: "isCountryVisible",
    FaxVisible: "isFaxVisible",
    StreetDetailsVisible: "isStreetDetailsVisible",
    UseReferenceData: "useReferenceData",
    Header: "header",
    EditMode: "editMode",
    ImageOverride: "imageOverride",
    BackgroundImageOverride: "backgroundImageOverride",
    BackgroundSize: "backgroundSize",
    BackgroundThemeColor: "backgroundThemeColor",
    ForegroundThemeColor: "foregroundThemeColor",
    Content: "caption",
    LabelAlignment: "labelAlignment",
    LabelText: "labelText",
    Text: "text",
    ItemPosition: "itemPosition",
    ShowFavorites: "showFavorites",
    ShowRecents: "showRecents",
    Color: "color",
    Image: "imagePK",
    VisibilityCondition: "visibilityCondition",
    IsIconVisible: "isIconVisible",
    IconPosition: "iconPosition",
    IconSize: "iconSize",
    PagePK: "pagePK",
    FilterType: "filterType",
    EntityName: "entityName",
    Category: "category",
    DefaultFilter: "defaultFilter",
  },
  Template: {
    Anchor: "configurablePlaceholderAnchorTemplate",
    TextBox: "configurablePlaceholderTextBoxTemplate",
    CheckBox: "configurablePlaceholderCheckBoxTemplate",
    ConfigureFilter: "configurablePlaceholderConfigureFilterTemplate",
    ImageEdit: "configurablePlaceholderImageEditTemplate",
    ImageSearchBox: "configurablePlaceholderImageSearchBoxTemplate",
    ConditionSearchBox: "configurablePlaceholderConditionSearchBoxTemplate",
    PageSearchBox: "configurablePlaceholderPageSearchBoxTemplate",
    EntityTypeSearchBox: "configurablePlaceholderEntityTypeSearchBoxTemplate",
    EntityCategorySearchBox: "configurablePlaceholderEntityCategorySearchBoxTemplate",
    SelectBox: "configurablePlaceholderSelectBoxTemplate",
    Numeric: "configurablePlaceholderNumericTemplate",
    ThemeColorSelectMenu: "configurablePlaceholderThemeColorSelectMenuTemplate",
  },
};

export enum Platform {
  Windows = "Windows",
  Mac = "macOS",
  Linux = "Linux",
  Android = "Android",
  iOS = "iOS",
}

export enum ProgressStatus {
  Success = "success",
  Warning = "warning",
  Default = "default",
}

export enum RemovalMode {
  Delete = "Delete",
  Detach = "Detach",
  Custom = "Custom",
  None = "None",
}

export enum ScanInputHandling {
  None = "NON",
  ScanAndNext = "ANF",
  ScanNextAndSubmit = "ANS",
}

export enum SearchBoxProviderType {
  Address = "Address",
  AddressInfo = "AddressInfo",
  Entity = "Entity",
  JobDocAddress = "JobDocAddress",
  JobDocAddressContact = "JobDocAddressContact",
  JobAddress = "JobAddress",
  JobAddressContact = "JobAddressContact",
}

export enum SearchControlFilterType {
  StartsWith = "startswith",
  EndsWith = "endswith",
  Contains = "contains",
}

export enum SearchListAddressDisplayMode {
  Grid = "Grid",
  List = "List",
}

export enum SearchListCategory {
  Trackable = "Trackable",
  InfoCard = "InfoCard",
}

export enum SearchListFilterType {
  Global = "Global",
  Entity = "Entity",
}

export enum SearchListMode {
  Entity = "Entity",
  EntityInfo = "EntityInfo",
}

export enum SearchMode {
  Entity = "Entity",
  Index = "Index",
}

export enum SortDirection {
  Ascending = "asc",
  Descending = "desc",
}

export enum TaskMessageBusEvent {
  InvokeControlTransition = "invokeControlTransition",
  InvokeSubFormFlow = "invokeSubFormFlow",
  RefreshForm = "refreshForm",
  RegisterPendingActionHandler = "registerPendingActionHandler",
  RegisterSaveHandler = "registerSaveHandler",
  UnregisterPendingActionHandler = "unregisterPendingActionHandler",
  UnregisterSaveHandler = "unregisterSaveHandler",
  ValidateControls = "validateControls",
  RegisterGetStateHandler = "registerGetStateHandler",
  UnregisterGetStateHandler = "unregisterGetStateHandler",
  CancelTask = "cancelTask",
}

export enum StateKey {
  AttachedEDocs = "attachedEDocs",
}

//eslint-disable-next-line @typescript-eslint/naming-convention -- Variable name TaskStatus must be camelCase
export const TaskStatus = {
  NotReady: { Code: "NOR", Description: "Not Ready" },
  NotStarted: { Code: "NOS", Description: "Not Started" },
  InProgress: { Code: "INP", Description: "In Progress" },
  Postponed: { Code: "POS", Description: "Postponed" },
  Completed: { Code: "CMP", Description: "Completed" },
  Canceled: { Code: "CAN", Description: "Canceled" },
  CommandInProgress: { Code: "CIP", Description: "Command in Progress" },
};

//eslint-disable-next-line @typescript-eslint/naming-convention -- Variable name Tile must be camelCase
export const Tile = {
  Size: {
    Standard: "Standard",
    Wide: "Wide",
    Large: "Large",
    CustomWidth: "CustomWidth",
  },
  Color: {
    Default: "Default",
    Primary: "Primary",
    Secondary: "Secondary",
  },
  DisplayModes: {
    TextAndIcon: "TextAndIcon",
    TextOnly: "TextOnly",
    IconOnly: "IconOnly",
    Button: "Button",
  },
  Behaviour: {
    Url: "Url",
    Page: "Page",
    Workflow: "Workflow",
    Property: "Property",
    FormFlow: "FormFlow",
    Extender: "Extender",
  },
};

export enum WellknownType {
  Point = "Point",
  Polygon = "Polygon",
}

export enum ControlType {
  Label = "LBL",
}

export enum IgnitionStatus {
  Inactive = 1,
  Slow = 2,
  Active = 3,
}

export enum ProcessTaskType {
  Milestone = "MIL",
  Trigger = "TRG",
}

export enum WorkflowType {
  New = "NEW",
  Maintain = "MNT",
  Both = "BTH",
}

export enum FormFlowUsage {
  Maintain = "MNT",
  MaintainMultiple = "MMU",
  New = "NEW",
  MaintainWithHeader = "MNH",
  MaintainMultipleWithHeader = "MMH",
  NewWithHeader = "NEH",
}

export enum RoundedCorner {
  None = "0, 0, 0, 0",
  Top = "4, 4, 0, 0",
  Left = "4, 0, 0, 4",
  BottomLeft = "0, 0, 0, 4",
  Bottom = "0, 0, 4, 4",
  BottomRight = "0, 0, 4, 0",
  Right = "0, 4, 4, 0",
}

export enum ConversationUserType {
  Unknown = 0,
  Staff = 1,
  Group = 2,
  Contact = 3,
  Organization = 4,
  Email = 5,
}

export enum DataGrid {
  HeaderRowHeight = 25,
  RowHeight = 26,
  ActionColumnWidth = 30,
}

export enum GridFilterVisibility {
  Visible = 0,
  Minimized = 1,
  InPopup = 2,
}

//eslint-disable-next-line @typescript-eslint/naming-convention -- Variable name Menu must be camelCase
export const Menu = {
  IsPopoverFullScreen: false,
};

export enum ConfigurationItemType {
  Form = "FRM",
  DisabledTaskTransitions = "TTS",
  ExcludedListItems = "ELI",
  Config = "CFG",
  Values = "VLS",
  KeyValuePairs = "KVP",
}

export enum ConfigurableControlType {
  Widget = 1,
  Container = 2,
  OverlapWidget = 3,
  OverlapContainer = 4,
}

export enum MappingFilterOption {
  All = "0",
  Mapped = "1",
  NotMapped = "2",
}

export enum DefinitionColumnAccessRight {
  CanRead = "CanRead",
  CanWrite = "CanWrite",
}

export enum PinSettingsKey {
  Header = "HeaderPinnedItems",
  Actions = "ActionsPinnedItems",
}

export enum GridCommonTemplate {
  HeaderCell = "#gridHeaderCellTemplate",
}

export enum GridActionsDisplayMode {
  ShowAll = 0,
  ShowPinnableOnly = 1,
  None = 2,
}

export enum ComponentMessageBusEvent {
  DisplayMapMarkers = "displayMapMarkers",
  RequestGlobalFilter = "requestGlobalFilter",
}

export enum WorkflowMenuTemplate {
  InlineMenuItem = 1,
  GridIconItem = 2,
}

export enum GridAvailableColumnsMode {
  AvailableColumnsOnly = 1,
  MergeDefaultAndSavedColumns = 2,
  MergeAllColumns = 3,
}

export enum KeyFieldType {
  AddressValue = "CargoWise.Glow.Index.Interfaces.IAddressValue, CargoWise.Glow.Index.Interfaces",
  DecimalValue = "CargoWise.Glow.Index.Interfaces.IDecimalValue, CargoWise.Glow.Index.Interfaces",
  MeasureValue = "CargoWise.Glow.Index.Interfaces.IMeasureValue, CargoWise.Glow.Index.Interfaces",
}

export enum ListDisplayMode {
  GridView = 0,
  CardView = 1,
}

export enum Registry {
  GoogleMapsAPIKey = "GoogleMapsAPIKey",
  GlowEnableDevelopmentMode = "GlowEnableDevelopmentMode",
  EnableAdvancedDataAutomationWizard = "EnableAdvancedDataAutomationWizard",
}

//eslint-disable-next-line @typescript-eslint/naming-convention -- Variable name WCFExpandPathsLimit must be camelCase
export const WCFExpandPathsLimit = 12;

//eslint-disable-next-line @typescript-eslint/naming-convention -- Variable name ExpandDepthLimit must be camelCase
export const ExpandDepthLimit = 10;

export enum NoteTypes {
  Public = "PUB",
  Private = "PRV",
  Internal = "INT",
  AgentVisible = "AGV",
}

function getCssClass(cssClass: string): CssClassType {
  return {
    Class: cssClass,
    Selector: "." + cssClass,
  };
}

const constants = {
  ActionsMenuItems: ActionsMenuItem,
  AnchorEdges: AnchorEdge,
  AlertDisplayTime,
  BackgroundImage,
  Browser: BrowserName,
  ColumnTypes: ColumnType,
  ComponentMessageBusEvents: ComponentMessageBusEvent,
  ConfigurableControlTypes: ConfigurableControlType,
  ConfigurationItemType,
  ConfigurationKeys: ConfigurationKey,
  ConfigurationTmplParentTableCodes: ConfigurationTmplParentTableCode,
  ConfigurationRights: ConfigurationRight,
  ControlTypes: ControlType,
  ConversationUserType,
  CssClasses: CssClass,
  DataGrids: DataGrid,
  DataTypes: DataType,
  DateTimeFormats: DateTimeFormat,
  DateTimeTypes: DateTimeType,
  DefinitionColumnAccessRights: DefinitionColumnAccessRight,
  DeliveryErrorReason,
  DeviceType,
  DisplayModeSetting,
  DropDownDisplayModes: DropDownDisplayMode,
  ExpandDepthLimit,
  FieldColumnDisplayMode,
  Filters: Filter,
  FilterDisplayMode,
  FilterFactories: FilterFactory,
  FinderItemPositions: FinderItemPosition,
  FormFlowUsage,
  GridActionsDisplayMode,
  GridAvailableColumnsMode,
  GridCommonTemplates: GridCommonTemplate,
  GridFilterVisibility,
  GridActions: GridAction,
  GridRowActions: GridRowAction,
  GridRowActionsMode,
  HeaderAlignment,
  HeaderDisplayModes: HeaderDisplayMode,
  HeaderMenuItems: HeaderMenuItem,
  Icons: Icon,
  IgnitionStatus,
  JobDocAddressEditModes: JobDocAddressEditMode,
  JobDocAddressFilterModes: JobDocAddressFilterMode,
  KeyFieldTypes: KeyFieldType,
  LabelAlignments: LabelAlignment,
  LabelNotificationTypes: LabelNotificationType,
  LabelStyles: LabelStyle,
  LabelPaddingSizes: LabelPaddingSize,
  LabelTextSizes: LabelTextSize,
  LabelTypes: LabelType,
  LinksDisplayMode,
  LinkTargetAttributeValues: LinkTargetAttributeValue,
  ListDisplayMode,
  LogonProviderType,
  MaterialDesignPageTypes: MaterialDesignPageType,
  MapView,
  MappingFilterOptions: MappingFilterOption,
  Menus: Menu,
  OptionButtonGrids: OptionButtonGrid,
  PageTypes: PageType,
  PinSettingsKeys: PinSettingsKey,
  Placeholders: Placeholder,
  Platform,
  ProgressStatus,
  ProcessTaskTypes: ProcessTaskType,
  Registry,
  RemovalMode,
  ResizeAxis,
  RoundedCorners: RoundedCorner,
  ScanInputHandling,
  SearchBoxProviderTypes: SearchBoxProviderType,
  SearchControlFilterTypes: SearchControlFilterType,
  SearchListAddressDisplayModes: SearchListAddressDisplayMode,
  SearchListCategory,
  SearchListFilterTypes: SearchListFilterType,
  SearchListModes: SearchListMode,
  SearchModes: SearchMode,
  SortDirections: SortDirection,
  States: State,
  StateKey,
  TaskMessageBusEvents: TaskMessageBusEvent,
  TaskStatus,
  Tiles: Tile,
  WellknownTypes: WellknownType,
  WCFExpandPathsLimit,
  WorkflowMenuTemplates: WorkflowMenuTemplate,
  WorkflowTypes: WorkflowType,
  UserType,
};

/** @deprecated use named import instead */
export default constants;
/*! EndNoStringValidationRegion */
