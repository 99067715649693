import breeze from 'breeze-client';
import captionService from 'CaptionService';

function RunAsClientMessageBarProvider(clientPK) {
	this._clientPK = clientPK;
}

RunAsClientMessageBarProvider.prototype.getMessageBarAsync = function () {
	const entityTypeName = 'IOrgHeaderInfo';
	const pk = this._clientPK;

	return breeze.EntityManager.forEntityTypeAsync(entityTypeName)
		.then((em) => {
			return em.fetchEntityByKey(entityTypeName, pk);
		})
		.then((x) => {
			if (x && x.entity) {
				return {
					messageText: captionService.getString('79b62248-4c26-478c-9235-6775228dd612', 'Running As Client'),
					messageHighlight: `${x.entity.OH_FullName()} (${x.entity.OH_Code().trim()})`
				};
			}
		});
};

export default RunAsClientMessageBarProvider;
