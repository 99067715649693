import * as stringUtils from 'StringUtils';
import Colour from 'Colour';

function ColourService() {
}

ColourService.prototype.fromArgbIntValue = (intValue) => {
	let hexValue = intValue + 0xFFFFFFFF + 1;				// twos complement
	hexValue = hexValue.toString(16);						// to hex
	hexValue = ('00000000' + hexValue).substr(-8);			// zero-pad to 8-digits
	const a = parseInt(hexValue.substring(0, 2), 16) / 255;
	const r = parseInt(hexValue.substring(2, 4), 16);
	const g = parseInt(hexValue.substring(4, 6), 16);
	const b = parseInt(hexValue.substring(6, 8), 16);
	return new Colour(r, g, b, a);
};

ColourService.prototype.fromRgbStringValue = (stringValue) => {
	const rgbArray = /(\d{1,3}),\s?(\d{1,3}),\s?(\d{1,3})/.exec(stringValue);
	if (!rgbArray) {
		return new Colour(255, 255, 255, 1);
	}
	const a = 1;
	const r = parseInt(rgbArray[1], 10);
	const g = parseInt(rgbArray[2], 10);
	const b = parseInt(rgbArray[3], 10);

	return new Colour(r, g, b, a);
};

ColourService.prototype.getHex = (value) => {
	if (value.substr(0, 1) === '#') {
		return value;
	}

	const digits = /([\w\W]*?)rgb\((\d+), (\d+), (\d+)\)/.exec(value);
	const red = parseInt(digits[2], 10);
	const green = parseInt(digits[3], 10);
	const blue = parseInt(digits[4], 10);

	const rgb = blue | (green << 8) | (red << 16); // eslint-disable-line no-bitwise
	return '#' + stringUtils.padLeft(rgb.toString(16), 6, '0');
};

ColourService.prototype.adjustLightness = (hex, adjustment) => {
	let rgb = hexToRgb(hex);
	const hsl = rgbToHsl.apply(null, rgb);
	hsl[2] = Math.max(0, Math.min(1, hsl[2] * adjustment));
	rgb = hslToRgb.apply(null, hsl);

	return rgbToHex.apply(null, rgb);
};

ColourService.prototype.adjustAlpha = (hex, adjustment) => {
	const rgb = hexToRgb(hex);
	const colour = new Colour(rgb[0], rgb[1], rgb[2], adjustment);

	return colour.toString();
};

// Based on https://gist.github.com/Arahnoid/9923989
function rgbToHex(r, g, b) {
	return ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1); // eslint-disable-line no-bitwise
}

// Based on https://gist.github.com/Arahnoid/9923989
function hexToRgb(hex) {
	/*! SuppressStringValidation 0x used in hexadecimal notation */
	return ['0x' + hex[0] + hex[1] | 0, '0x' + hex[2] + hex[3] | 0, '0x' + hex[4] + hex[5] | 0]; // eslint-disable-line no-bitwise
}

// Based on https://gist.github.com/mjackson/5311256
function rgbToHsl(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h;
    let s;
    const l = (max + min) / 2;

    if (max === min) {
		h = s = 0; // achromatic
	} else {
		const d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
		}
		h /= 6;
	}

    return [h, s, l];
}

// Based on https://gist.github.com/mjackson/5311256
function hslToRgb(h, s, l) {
	let r, g, b;

	if (s === 0) {
		r = g = b = l; // achromatic
	} else {
		const hue2rgb = (p, q, t) => {
			if (t < 0) {
				t += 1;
			}

			if (t > 1) {
				t -= 1;
			}

			if (t < 1 / 6) {
				return p + (q - p) * 6 * t;
			}

			if (t < 1 / 2) {
				return q;
			}

			if (t < 2 / 3) {
				return p + (q - p) * (2 / 3 - t) * 6;
			}

			return p;
		};

		const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
		const p = 2 * l - q;
		r = hue2rgb(p, q, h + 1 / 3);
		g = hue2rgb(p, q, h);
		b = hue2rgb(p, q, h - 1 / 3);
	}

	return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

export default new ColourService();
