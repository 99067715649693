import Promise from 'bluebird';
import constants from 'Constants';
import ExpandPathScoreVisitor from 'ExpandPathScoreVisitor';
import log from 'Log';
import { applyExpandDepthLimit } from 'ExpandPathsProvider';
import { enumeratePropertyPath } from 'PropertyPathEnumerator';
import { isUnrestrictedBreezeProperty } from 'PropertySecurity';

class EntityExpander {
	expandPathsAsync(entity, expandPaths, options) {
		return Promise.try(() => {
			if (!expandPaths || expandPaths.length === 0) {
				return;
			}

			let totalScore = 0;
			const expandWorthPaths = [];
			expandPaths.forEach((expandPath) => {
				const score = ExpandPathScoreVisitor.visit(entity, expandPath, options);
				if (score > 0 && canExpandPath(entity.entityType, expandPath)) {
					totalScore = totalScore + score;
					expandWorthPaths.push(expandPath);
				}
			});

			if (totalScore <= 1) {
				return;
			}

			const paths = expandWorthPaths
				.slice(0, constants.WCFExpandPathsLimit)
				.map(applyExpandDepthLimit);

			return entity.entityAspect.expandAsync(paths);
		});
	}
}

function canExpandPath(entityType, path) {
	for (const { property } of enumeratePropertyPath(entityType, path)) {
		if (property.entityType.isNotExpandable) {
			log.warning(
				`ExpandPath '${path}' cannot be expanded because ${property.entityType.interfaceName} is not expandable.`
			);
			return false;
		} else if (!isUnrestrictedBreezeProperty(property)) {
			log.warning(`ExpandPath '${path}' cannot be expanded due to security restrictions.`);
			return false;
		}
	}
	return true;
}

export default new EntityExpander();
