import { type FormFlowSession } from "FormFlowSession";
import { State } from "StateConstants";
import ko, { type Computed, type Observable } from "knockout";

export interface ExpressionData {
  hasChanges?: Computed<boolean>;
  [key: string]: unknown;
}

export interface VariableValue<T = unknown> extends Observable {
  (value: T): void;
  getState?: Observable<State>;
  loadAsync?: () => Promise<void>;
}

export function buildExpressionData(session: FormFlowSession): ExpressionData {
  const data: ExpressionData = {};
  session.getVariableNames().forEach((variableName: string): void => {
    const variableValue: VariableValue = ko.observable({});
    variableValue.getState = ko.observable<State>(State.NotLoaded);
    variableValue.loadAsync = async (): Promise<void> => {
      const varValue = await session.getVariableValueAsync(variableName);
      variableValue(varValue);
      if (!variableValue.getState) {
        variableValue.getState = ko.observable<State>(State.Available);
      }
      variableValue.getState(State.Available);
    };

    data[variableName] = variableValue;
  });

  data.hasChanges = ko.pureComputed(() => session.hasChanges());
  return data;
}
