import breeze, { type EntityManager, type EntityType, type MetadataStore } from "breeze-client";

const entityMetadata = new WeakMap<EntityType, EntityMetadata>();

export function excludeReadOnlyProperty(entityType: EntityType): boolean;
export function excludeReadOnlyProperty(entityType: EntityType, value: boolean): void;
export function excludeReadOnlyProperty(entityType: EntityType, value?: boolean): boolean | void {
  if (value === undefined) {
    return !!entityMetadata.get(entityType)?.excludeReadOnlyProperty;
  } else {
    let metadata = entityMetadata.get(entityType);
    if (!metadata) {
      metadata = {};
      entityMetadata.set(entityType, metadata);
    }
    metadata.excludeReadOnlyProperty = value;
  }
}

export function getEntityType(
  entityManagerOrMetadataStore: EntityManager | MetadataStore,
  entityType: string
): EntityType {
  const metadataStore =
    entityManagerOrMetadataStore instanceof breeze.EntityManager
      ? entityManagerOrMetadataStore.metadataStore
      : entityManagerOrMetadataStore;
  const structuralType = metadataStore.getEntityType(entityType);
  if (structuralType.isComplexType) {
    throw new Error(`${entityType} is a complex type but expected an entity type.`);
  }

  return structuralType as unknown as EntityType;
}

interface EntityMetadata {
  excludeReadOnlyProperty?: boolean;
}
