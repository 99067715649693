import captionService from 'CaptionService';
import configurationHelper from 'ConfigurationHelper';
import configurationService from 'ConfigurationService';
import constants from 'Constants';
import global from 'Global';
import helpPresenter from 'HelpPresenter';
import helpService from 'HelpService';
import userSession from 'UserSession';
import Promise from 'bluebird';
import ko from 'knockout';

function ConfigureMenuViewModel(isInConfigurationMode, parentID, isTaskPage, entityPK) {
	this._isInConfigurationMode = isInConfigurationMode;
	this._parentID = parentID;
	this._isTaskPage = isTaskPage;
	this._entityPK = entityPK;
	this._parentTableCode = isTaskPage ? constants.ConfigurationTmplParentTableCodes.FormFlow : constants.ConfigurationTmplParentTableCodes.Form;

	this.hasConfigureOptions = ko.pureComputed(getHasConfigureOptions.bind(null, this));
	this.configureOptions = ko.asyncComputed(getConfigureOptionsAsync.bind(null, this), null, this);
}

function getHasConfigureOptions(vm) {
	return vm.configureOptions().length > 0;
}

function getConfigureOptionsAsync(vm) {
	const result = { syncValue: [] };
	if (global.isPortable() || userSession.sessionData().userType !== userSession.UserType.Staff) {
		return result;
	}

	const isBrandingConfigurationAllowedPromise = configurationService.isConfigurationAllowedAsync(constants.ConfigurationRights.Branding);
	const isFormConfigurationAllowedPromise = configurationService.isConfigurationAllowedAsync(constants.ConfigurationRights.Form);
	const isHelpConfigurationAllowedPromise = helpService.isConfigurationAllowedAsync();

	result.asyncPromise = Promise.join(isBrandingConfigurationAllowedPromise, isFormConfigurationAllowedPromise, isHelpConfigurationAllowedPromise)
		.spread((
        isBrandConfigurationAllowed,
        isFormConfigurationAllowed,
        isHelpConfigurationAllowed
    ) => {
			const options = [];

			if (isBrandConfigurationAllowed) {
				options.push({
					caption: captionService.getString('fdc01a06-93ad-46e9-8e32-62398b516eca', 'Theme'),
					onClick: configurationHelper.showConfigureTheme
				});
			}

			if (isHelpConfigurationAllowed) {
				options.push({
					caption: captionService.getString('4b583ca4-e6b4-4e9b-8ae8-679ef5f4e890', 'Help'),
					onClick: helpPresenter.showConfigureHelp
				});
			}

			if (isFormConfigurationAllowed) {
				options.push({
					caption: captionService.getString('6ddf5cae-8eeb-4fdb-bfd9-c16dca2541ea', 'Tracking Fields'),
					onClick: configurationHelper.showConfigureAnonymousTrackingFields
				});

				if (!vm._isInConfigurationMode && vm._parentID) {
					options.push({
						caption: vm._isTaskPage ? captionService.getString('65eb6187-7922-4a41-b5d4-4dd1c5495a10', 'This form-flow')
							: captionService.getString('1ce85de2-1794-489c-acdb-798f87bd3ea1', 'This page'),
						onClick: configurationHelper.startConfiguringAsync.bind(configurationHelper, vm._parentID, vm._parentTableCode, vm._entityPK)
					});
				}

				options.push({
					caption: captionService.getString('da270464-95f2-4629-835f-cfbb7b851c66', 'All Pages'),
					onClick: configurationHelper.showMasterListPages
				});

				options.push({
					caption: captionService.getString('81c20e8e-9444-4600-bf91-8d335e98f63a', 'All Form-flows'),
					onClick: configurationHelper.showMasterListFormFlows
				});

				options.push({
					caption: captionService.getString('8a4750de-89d2-4157-a76e-f022d4c99ecd', '{0} Login', constants.LogonProviderType.Contact),
					items: createConfigureLoginPageSubMenuItems(constants.LogonProviderType.Contact)
				});
				options.push({
					caption: captionService.getString('cdedf1d1-3ad1-499f-b7b0-fe518438bff2', '{0} Login', constants.LogonProviderType.Staff),
					items: createConfigureLoginPageSubMenuItems(constants.LogonProviderType.Staff)
				});
			}

			return options;
		});

	return result;
}

function createConfigureLoginPageSubMenuItems(logonType) {
	const subMenuItems = [];

	subMenuItems.push({
		caption: captionService.getString('99b4b1ec-a83a-45f8-ad96-f48a34770e9d', 'Desktop'),
		onClick: configurationHelper.startConfiguringLoginPageAsync.bind(configurationHelper, logonType, global.formFactors.Desktop)
	});
	subMenuItems.push({
		caption: captionService.getString('a88f0bb5-08ab-4d7e-b049-ab3c3e72dfdd', 'Tablet'),
		onClick: configurationHelper.startConfiguringLoginPageAsync.bind(configurationHelper, logonType, global.formFactors.Tablet)
	});
	subMenuItems.push({
		caption: captionService.getString('e0f979ca-5f17-42cd-b9ad-2ba074ea60fb', 'Mobile'),
		onClick: configurationHelper.startConfiguringLoginPageAsync.bind(configurationHelper, logonType, global.formFactors.Mobile)
	});

	return subMenuItems;
}

export default ConfigureMenuViewModel;
