// Not for direct use - use properties of AuthenticationService instead.
// This is provided separately only to serve as a common definition for AuthenticationService, AuthenticationServiceMock and Arbiter.js.
import captionService from 'CaptionService';

/*! SuppressStringValidation header name */
const GlowAuthenticationResultHeaderName = 'Glow-Authentication-Result';

const constants =
{
	AuthenticationClaimType:
	{
		Invalid: 0,
		LocalToken: 1,
		WiseTechGlobalSupportToken: 2,
		LocalIdentityToken: 3
	},

	AuthenticationResult:
	{
		AbnormalFailure: 255,
		Success: 0,
		AccountLocked: 1,
		PasswordChangeRequired: 2,
		LogonDetailsIncorrect: 3,
		SessionExpired: 4,
		CredentialsNotProvided: 5,
		WebAccessNotEnabled: 6,
		SessionNotFound: 7,
		CouldNotCalculateEnterpriseContext: 10,
		ThirdPartyUserValidationRequired: 11,
		ThirdPartyUserValidationRejected: 12,
		ThirdPartyUserValidationUnavailable: 13,
		LoginDisabled: 14,
		UpstreamAuthenticationServerUnavailable: 15,
		LocalPasswordMustBeReset: 16,
		ContextChangeRequired: 17,
		ThirdPartyUserMissing: 18,
		ThirdPartyUserAmbiguous: 19,
		ContextInsufficientPrivileges: 20,
		LoginRateLimited: 21,
		SessionLimitReached: 22,
		SessionEvicted: 23,
	},

	/*! StartNoStringValidationRegion - session types */
	SessionType: {
		General: 'general',
		Mobility: 'mobility',
	},
	/*! EndNoStringValidationRegion */

	GlowAuthenticationResultHeaderName,

	getDefaultErrorMessage() {
		return captionService.getString('5ceb6e7d-f7da-4a36-b40d-1e725d243664', 'An unexpected error occurred while logging in. Contact your Administrator.');
	},

	getErrorMessage(authenticationResult, appName) {
		const AuthenticationResult = constants.AuthenticationResult;

		/*! SuppressStringValidation used in if condition */
		if (typeof authenticationResult === 'string') {
			authenticationResult = constants.AuthenticationResult[authenticationResult];
		}

		switch (authenticationResult) {
			case AuthenticationResult.AccountLocked:
				return captionService.getString('22172afe-191e-4944-ad40-19d8f990fe72', 'Your account is locked. Please contact your Administrator.');

			case AuthenticationResult.PasswordChangeRequired:
				return captionService.getString('b96fadbc-28ae-43c8-9782-01eb1b08cab5', 'Your password has expired and must be changed.');

			case AuthenticationResult.LogonDetailsIncorrect:
				return captionService.getString('66cb2242-69c4-430c-bf89-daf2c4d30f36', 'Your username and/or password is incorrect. Please try again.');

			case AuthenticationResult.CredentialsNotProvided:
				return captionService.getString('c2e7a7e5-23fe-4d44-9c40-8903ec42be62', 'No username or password was provided.');

			case AuthenticationResult.SessionNotFound:
			case AuthenticationResult.SessionExpired:
				return captionService.getString('2ebbf351-8d5e-42df-91c5-1c8e41926e23', 'Your session has expired. Please log back in.');

			case AuthenticationResult.WebAccessNotEnabled:
				return captionService.getString('339f2c59-a054-46cd-bc0f-2ab358f0aaa9', 'You do not have Web Access. Please contact your Administrator.');

			case AuthenticationResult.CouldNotCalculateEnterpriseContext:
				return captionService.getString('effa0cc6-4016-4403-b7ed-110b2877c9c3', 'Could not determine Branch/Department. Either set a Home Branch and Home Department, or log into {0} (Remote Desktop) at least once.', appName);

			case AuthenticationResult.ThirdPartyUserValidationRequired:
				return captionService.getString('488ab2a9-1c89-4ff3-a421-dc92df7ab270', 'External login is required...');

			case AuthenticationResult.ThirdPartyUserValidationRejected:
				return captionService.getString('fac96e64-17ce-476c-88cb-728b1381cd2c', 'The external login server rejected your login attempt. Please contact your Administrator.');

			case AuthenticationResult.ThirdPartyUserValidationUnavailable:
				return captionService.getString('4461100c-92fd-4283-b2ba-b488c70d83e3', 'External login is required, but unavailable. You cannot log into this account.');

			case AuthenticationResult.LoginDisabled:
				return captionService.getString('9583b4db-8e38-45c5-8abb-644f0efceb63', 'Login has been disabled for this user account. Please contact your Administrator.');

			case AuthenticationResult.UpstreamAuthenticationServerUnavailable:
				return captionService.getString('71dda086-bcec-495b-b3b5-b9b0c50ee827', 'The upstream login server is unavailable. Please contact your Administrator.');

			case AuthenticationResult.LocalPasswordMustBeReset:
				return captionService.getString('aa037ac9-ec87-4fa4-88af-4f17f28911ec', 'You must reset your password in {0}.', appName);

			case AuthenticationResult.ThirdPartyUserMissing:
				return captionService.getString('731c8814-22e7-4025-87bf-5b39599eac44', 'Could not find an account related to the external login. Please contact your Administrator.');

			case AuthenticationResult.ThirdPartyUserAmbiguous:
				return captionService.getString('6b5340d2-e6e4-4ed8-b124-1a674d0b2552', 'Multiple accounts found related to the external login. Please contact your Administrator.');

			case AuthenticationResult.ContextChangeRequired:
			case AuthenticationResult.ContextInsufficientPrivileges:
				return captionService.getString('604cd2fa-2f8d-45ce-924a-d7b3da6b3972', 'You do not have the security right to login to this branch or department. You may ask your system administrator to grant you this security right.');

			case AuthenticationResult.LoginRateLimited:
				return captionService.getString('126a918b-a222-41be-8cf0-a7a2faa5478e', 'Login rate limit exceeded. Please try again later or contact your Administrator.');

			case AuthenticationResult.SessionLimitReached:
				return captionService.getString('cd9882be-2c8a-4dea-8160-aa49d9fadd20', 'Your user account is logged in from another session. A user can only be logged in from one session at a time.');

			case AuthenticationResult.SessionEvicted:
				return captionService.getString('abcd4124-6a0c-4c91-84b2-9552b7367142', 'Your session has been logged out because it was terminated by another session.');

			default:
				return constants.getDefaultErrorMessage();
		}
	},

	getHttpErrorMessage(statusCode) {
		switch (parseInt(statusCode, 10)) {
			case 500:
				return captionService.getString('28b5afe7-112d-4c3e-8254-34429fa60c78', 'The server failed to log you in. Try again later or contact your Administrator.');

			case 502:
			case 504:
				return captionService.getString('4225160a-2dce-487f-b631-da76b3b00089', 'Unable to contact the server. Try again later or contact your Administrator.');

			case 503:
				return captionService.getString('d5d03e79-ce13-4803-9a8f-d0fd61af2389', 'The server is unavailable. Try again later or contact your Administrator.');

			default:
				return constants.getDefaultErrorMessage();
		}
	}
};

const AuthenticationResultStringTable = {};
for (const key in constants.AuthenticationResult) {
	AuthenticationResultStringTable[constants.AuthenticationResult[key]] = key;
}

constants.AuthenticationResultStringTable = AuthenticationResultStringTable;

export default constants;
