export default class ValidationRegistrar {
	constructor() {
		this._innerSet = new Set();
	}

	async getBoundItemsAsync() {
		const promises = [];
		this._innerSet.forEach((itemGetter) => {
			promises.push(itemGetter());
		});
		const results = await Promise.all(promises);
		return results
			.flat()
			.filter((e) => e);
	}

	registerBoundItem(itemGetter) {
		this._innerSet.add(itemGetter);
	}

	unregisterBoundItem(itemGetter) {
		this._innerSet.delete(itemGetter);
	}
}
