import BreezeQueryable from 'BreezeQueryable';
import constants from 'Constants';
import { DateTimeType } from 'DateTimeConstants';
import { fromISOString, toISOString } from 'DateTimeOffset';
import Dependency2 from 'Dependency2';
import embeddedDependenciesExtractor from 'ExtractEmbeddedDependenciesVisitor';
import { emptyGuid } from 'StringUtils';
import Promise from 'bluebird';
import { isNil, uniq } from 'lodash-es';
import moment from 'moment';

/*! StartNoStringValidationRegion enum values */
export const DependencyType = {
	Constant: 'constant',
	Expression: 'expression',
	Self: 'self',
};
/*! EndNoStringValidationRegion */

function RuleDependencyValue2() {
}

RuleDependencyValue2.prototype.getValues = (entity, parameters) => {
	return syncStrategy.evaluateDependencyValues(entity, parameters, byIndexStrategy);
};

RuleDependencyValue2.prototype.getValuesAsync = (entity, parameters) => {
	return asyncStrategy.evaluateDependencyValuesAsync(entity, parameters, byIndexStrategy);
};

RuleDependencyValue2.prototype.getSerializableValuesByNameAsync = (entity, parameters) => {
	return asyncStrategy.evaluateDependencyValuesAsync(entity, parameters, byNameStrategy);
};

RuleDependencyValue2.prototype.getDependencyInfo = (path) => {
	return getDependencyInfo(path);
};

function getValuesForParameters(entity, parameters, getStrategy, valuesStrategy) {
	const results = [];
	let embeddedDependencies = [];
	for (let i = 0; i < parameters.length; ++i) {
		results[i] = getValueForSingleParameter(entity, parameters[i], getStrategy);
		if (parameters[i].queryTypeArgument) {
			embeddedDependencies = embeddedDependencies.concat(embeddedDependenciesExtractor.extractEmbeddedDependencies(parameters[i].value));
		}
	}

	embeddedDependencies = uniq(embeddedDependencies);
	const embeddedDependencyResults = embeddedDependencies.map((path) => getStrategy.getValue(entity, path));

	return getStrategy.finaliseValues(results, embeddedDependencies, embeddedDependencyResults, parameters, valuesStrategy);
}

function getValueForSingleParameter(entity, parameter, getStrategy) {
	const info = getDependencyInfo(parameter.value);
	if (info.type === DependencyType.Constant) {
		let value = info.path;
		if (parameter.queryTypeArgument) {
			value = createQueryable(info.path);
			value.interfaceName = parameter.queryTypeArgument;
		} else {
			switch (parameter.type) {
				case DateTimeType.DateTime:
					value = new Date(value);
					break;
				case DateTimeType.DateTimeOffset:
					value = fromISOString(value);
					break;
			}
		}
		return { value, state: constants.States.Available };
	}
	else {
		return getStrategy.getValue(entity, info.path, parameter);
	}
}

function createQueryable(expression) {
	const result = (query, params, embeddedDependenciesMap) => {
		const queryable = new BreezeQueryable(query);
		return queryable.select(expression, params, embeddedDependenciesMap);
	};
	return result;
}

function finaliseValuesCore(results, embeddedDependencies, embeddedDependencyResult, parameters, valuesStrategy) {
	const notAvailableEmbeddedDependency = embeddedDependencyResult.find((result) => result.state !== constants.States.Available);
	if (notAvailableEmbeddedDependency) {
		return { values: valuesStrategy.init(), state: notAvailableEmbeddedDependency.state };
	}

	const embeddedDependenciesMap = {};
	embeddedDependencies.forEach((path, i) => {
		embeddedDependenciesMap[path] = embeddedDependencyResult[i].value;
	});

	const values = valuesStrategy.init();
	for (let i = 0; i < results.length; ++i) {
		const result = results[i];
		if (result.state === constants.States.Available) {
			const parameter = parameters[i];
			const convertedValue = valuesStrategy.shouldSerialize ? formatServerValue(result.value, parameter.type) : result.value;
			if (parameter.queryTypeArgument) {
				convertedValue.embeddedDependenciesMap = embeddedDependenciesMap;
			}
			valuesStrategy.add(values, i, parameter.name, convertedValue);
		}
		else {
			return { values: valuesStrategy.init(), state: result.state };
		}
	}
	return { values, state: constants.States.Available };
}

function getDefaultValue(parameter) {
	if (parameter.isNullable) {
		return null;
	}

	switch (parameter.type) {
		case 'DateTime':
			return moment.minValue.toFilterValueString();

		case 'Decimal':
		case 'Double':
		case 'Int32':
		case 'Int64':
		case 'Single':
			return 0;

		case 'Guid':
			return emptyGuid;

		case 'String':
			return '';

		default:
			return null;
	}
}

function formatServerValue(value, serverType) {
	if (value) {
		if (serverType === 'DateTime') {
			return moment(value).toFilterValueString();
		}
		if (serverType === 'DateTimeOffset') {
			return toISOString(value);
		}
		if (serverType === 'String') {
			return value;
		}
	}
	return value;
}

function getDependencyInfo(path) {
	/*! StartNoStringValidationRegion Dependency types are not translatable */
	let type;
	const match = /^<([\w\W]+)>$/.exec(path);
	const isDependency = match;
	if (isDependency) {
		path = match[1];
		if (path === '.') {
			type = DependencyType.Self;
		}
		else {
			type = DependencyType.Expression;
		}
	}
	else {
		type = DependencyType.Constant;
	}

	return { path, type };
	/*! EndNoStringValidationRegion */
}

function getValueOrDefault(value, parameter) {
	if (isNil(value.value) && value.state === constants.States.Available && parameter) {
		value.value = getDefaultValue(parameter);
	}
	return value;
}

const syncStrategy = {
	evaluateDependencyValues(entity, parameters, valuesStrategy) { return getValuesForParameters(entity, parameters, syncStrategy, valuesStrategy); },
	getValue(entity, path, parameter) {
		const value = Dependency2.getValue(entity, path);
		return getValueOrDefault(value, parameter);
	},
	finaliseValues: finaliseValuesCore
};
const asyncStrategy = {
	evaluateDependencyValuesAsync(entity, parameters, valuesStrategy) {
		return Promise.try(() => {
			return getValuesForParameters(entity, parameters, asyncStrategy, valuesStrategy);
		});
	},
	getValue(entity, path, parameter) {
		return Dependency2.getValueAsync(entity, path).then((value) => {
			return getValueOrDefault(value, parameter);
		});
	},
	finaliseValues(
		valuePromises,
		embeddedDependencies,
		embeddedDependencyPromises,
		parameters,
		valuesStrategy
	) {
		return Promise.join(Promise.all(valuePromises), Promise.all(embeddedDependencyPromises)).spread((values, embeddedDependencValues) => {
			return finaliseValuesCore(values, embeddedDependencies, embeddedDependencValues, parameters, valuesStrategy);
		});
	}
};

const byIndexStrategy = {
	init() { return []; },
	add(values, index, name, value) { values[index] = value; }
};
const byNameStrategy = {
	init() { return {}; },
	add(values, index, name, value) { values[name] = value; },
	shouldSerialize: true,
};

export default new RuleDependencyValue2();
