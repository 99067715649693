import type MessageBus from "MessageBus";
import type ValidationRegistrar from "ValidationRegistrar";
import { type BindingContext } from "knockout";
import type ModelProvider from "./Breeze/ModelProvider";
import type ConfigurationSessionBase from "./Configuration/ConfigurationSessionBase";
import type { WorkflowContext } from "./VueHooks/WorkflowContextHook";

export type PageExtensions = Readonly<{
  id?: string;
  messageBus?: MessageBus;
  configurationContext?: ConfigurationSessionBase;
  modelProvider?: ModelProvider;
  sessionData?: { configurationParentID?: string; clientPK?: string };
  validationRegistrar?: ValidationRegistrar;
  workflowContext?: WorkflowContext;
}>;

const emptyInstance: PageExtensions = Object.freeze({});

export function getPageExtensions(bindingContext: BindingContext | undefined): PageExtensions {
  return bindingContext?.$root?.pageExtensions ?? emptyInstance;
}

export function extendPageExtensions(bindingContext: BindingContext, newPageExtensions: PageExtensions): void {
  const rootBindingContext = bindingContext?.$root;
  if (rootBindingContext) {
    rootBindingContext.pageExtensions = {
      ...rootBindingContext.pageExtensions,
      ...newPageExtensions,
    };
  }
}
