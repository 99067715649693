import bindingEvaluator from 'BindingEvaluator';
import captionService from 'CaptionService';
import configurationHelper from 'ConfigurationHelper';
import ItemsConfigurator from 'ItemsConfigurator';
import ko from 'knockout';
import lookupRuleEngine from 'LookupRuleEngine';
import { getPageExtensions } from 'PageExtensions';
import sortProvider from 'SortProvider';
import _ from 'underscore';
import workflowCommandRuleHandler from 'WorkflowCommandRuleHandler';

const emptyCommand = { onClick: _.noop };

ko.bindingContext.prototype.$caption = function (/*key, fallbackText, params[0..*]*/) {
	return captionService.getString.apply(captionService, arguments);
};

ko.bindingContext.prototype.$itemsConfigurator = function (bindingPath) {
	const itemsConfigurators = getStorage(this, 'itemsConfigurators');
	const cacheKey = bindingPath || '.';
	let result = itemsConfigurators[cacheKey];
	if (!result) {
		const params = _.extend(
			{
				configurationKey: bindingPath,
				isInConfigurationMode: !!configurationHelper.getConfigurationContext(this),
			},
			getPageExtensions(this).sessionData
		);

		itemsConfigurators[cacheKey] = result = new ItemsConfigurator(params);
	}

	return result;
};

ko.bindingContext.prototype.$commandRule = function (bindingPath, data) {
	const ultimateDataItem =
		arguments.length > 1
			? ko.unwrap(data)
			: bindingEvaluator.getUltimateDataItem(this, unwrapData(this), bindingPath);

	if (ultimateDataItem) {
		const commandName = bindingEvaluator.getPropertyName(bindingPath);
		return workflowCommandRuleHandler.getClickHandler(ultimateDataItem, commandName);
	} else {
		return emptyCommand;
	}
};

ko.bindingContext.prototype.$lookupRuleFor = function (dataItem, propertyName) {
	const unwrappedDataItem = ko.unwrap(dataItem);
	return unwrappedDataItem && unwrappedDataItem.entityType
		? lookupRuleEngine.observeValue(
				unwrappedDataItem,
				unwrappedDataItem.entityType.interfaceName,
				propertyName
		)
		: [];
};

ko.bindingContext.prototype.$value = function (bindingPath) {
	return this.$valueFor(this.$rawData, bindingPath);
};

ko.bindingContext.prototype.$valueFor = function (data, bindingPath) {
	return bindingEvaluator.getValue(this, ko.unwrap(data), bindingPath, { unwrap: false });
};

ko.bindingContext.prototype.$sortedValueFor = function (data, bindingPath, sortFields) {
	let value = this.$valueFor(data, bindingPath);
	if (sortFields && sortFields.length) {
		value = ko.unwrap(value);

		if (value && value.length) {
			const sortColumns = sortFields.map((sortField) => {
				/*! SuppressStringValidation String validation suppressed in initial refactor */
				const direction = sortField.IsAscending ? 'asc' : '';
				return { column: { field: sortField.FieldName }, direction };
			});

			value = sortProvider.sortData(value, sortColumns);
		}
	}

	return value;
};

ko.bindingContext.prototype.$ultimateDataItem = function (bindingPath) {
	const cacheKey = bindingEvaluator.getUltimateDataItemPathWithSeparator(bindingPath);
	return getDataItem(this, bindingPath, cacheKey, true);
};

ko.bindingContext.prototype.$ultimateDataItemWithState = function (bindingPath) {
	const cacheKey =
		bindingEvaluator.getUltimateDataItemPathWithSeparator(bindingPath) +
		'7a755b9f-2181-4ca4-a0fd-4b5b0db53ff7'; // GUID to avoid collisions between the caches of ultimateDataItem and ultimateDataItemWithState
	return getDataItem(this, bindingPath, cacheKey, true, true);
};

ko.bindingContext.prototype.$enumerateProperties = function (targetObject) {
	if (!targetObject) {
		return [];
	}

	return Object.keys(targetObject).map((key) => {
		return { key, value: targetObject[key] };
	});
};

function getDataItem(bindingContext, bindingPath, cacheKey, isUltimateDataItem, isWithState) {
	const dataItems = getStorage(bindingContext, 'dataItems');
	cacheKey = cacheKey || '.';
	let result = dataItems[cacheKey];
	if (!result) {
		dataItems[cacheKey] = result = ko.pureComputed(
			getDataItemCore.bind(null, bindingContext, bindingPath, isUltimateDataItem, isWithState)
		);
	}

	return result;
}

function getDataItemCore(bindingContext, bindingPath, isUltimateDataItem, isWithState) {
	const data = ko.unwrap(bindingContext.$rawData);
	if (bindingPath) {
		if (isUltimateDataItem) {
			if (isWithState) {
				return bindingEvaluator.getUltimateDataItemWithState(
					bindingContext,
					data,
					bindingPath
				);
			} else {
				return bindingEvaluator.getUltimateDataItem(bindingContext, data, bindingPath);
			}
		}
		return bindingEvaluator.getValue(bindingContext, data, bindingPath);
	}

	return data;
}

const cacheStorage = new WeakMap();

function getStorage(self, name) {
	let glowStorage = cacheStorage.get(self);
	if (!glowStorage) {
		glowStorage = {};
		cacheStorage.set(self, glowStorage);
	}

	let storageWithName = glowStorage[name];
	if (!storageWithName) {
		glowStorage[name] = storageWithName = {};
	}

	return storageWithName;
}

function unwrapData(self) {
	return ko.unwrap(self.$rawData);
}

(function () {
	ko.bindingContext.prototype.$currentItem = function (data, propertyName) {
		if (!data) {
			return null;
		}

		const observable = $currentItemObservable(this, data, propertyName);
		const primaryKey = observable();
		const children = ko.recursiveUnwrap(data[propertyName], 0);
		if (!children) {
			return null;
		}

		if (typeof primaryKey === 'undefined') {
			return children.length ? children[0] : null;
		}

		if (primaryKey) {
			return (
				children.find((item) => item && item.entityAspect.getPrimaryKey() === primaryKey) ||
				null
			);
		}

		return null;
	};

	function $currentItemObservable(bindingContext, data, propertyName) {
		if (!data.entityAspect) {
			// When ES6 is the standard then we can support non-entities by using WeakMap and storing the data as the key.
			throw new Error('$currentItem is only supported for Breeze entities.');
		}

		const currentItems = getStorage(getRootContext(bindingContext), 'currentItems');
		const key = data.entityAspect.getKey().toString() + '_' + propertyName;
		let observable = currentItems[key];
		if (!observable) {
			currentItems[key] = observable = ko.observable();
		}

		return observable;
	}

	ko.bindingContext.prototype.$setCurrentItem = function (data, propertyName, value) {
		if (data) {
			const info = bindingEvaluator.getEntityBindingInfo(data.entityType, propertyName);
			if (
				value &&
				value.entityAspect &&
				data.entityAspect &&
				data.entityAspect.entityManager !== value.entityAspect.entityManager &&
				!info.isCalculated
			) {
				throw new Error(
					'$currentItem can only be set to an entity from the same entityManager.'
				);
			}

			const observable = $currentItemObservable(this, data, propertyName);
			observable(value ? value.entityAspect.getPrimaryKey() : null);
		}
	};

	/*! SuppressStringValidation storage key is not translatable */
	const cacheStorageKey = '_cache_';
	ko.bindingContext.prototype.$setCacheValue = function (key, value) {
		const cacheDictionary = getStorage(getRootContext(this), cacheStorageKey);
		cacheDictionary[key] = value;
	};

	ko.bindingContext.prototype.$getCacheValue = function (key) {
		const cacheDictionary = getStorage(getRootContext(this), cacheStorageKey);
		return cacheDictionary[key] || null;
	};

	function getRootContext(self) {
		let result = self;
		while (result.$parentContext) {
			result = result.$parentContext;
		}
		return result;
	}
})();
