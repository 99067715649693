import captionService from 'CaptionService';
import errors from 'Errors';
import $ from 'jquery';
import NotificationType from 'NotificationType';
import { getODataErrorMessage } from 'ODataUtils';

class AlertResultsDeserializer {
	static fromError(error, options) {
		const message = getErrorResponseMessage(error);
		return this.fromErrorMessage(message, options);
	}

	static fromErrorMessage(message, options) {
		const alerts = {};
		const alertsXml = getAlertsXml(message);
		if (alertsXml) {
			setAlerts(alertsXml, options, alerts);
		}
		return alerts;
	}
}

function getAlertsXml(message) {
	try {
		/*! SuppressStringValidation String validation suppressed in initial refactor */
		if (message && message.startsWith('<?xml')) {
			const xml = $.parseXML(message);

			return $(xml);
		}
	}
	catch (ex) {
		// Don't care.
	}

	return null;
}

function getAlert(xml, getCaption) {
	const alert = $(xml);
	const entityTag = alert.find('entity');
	const property = entityTag.find('property');
	const resultTag = alert.find('validationResult');

	const result = {
		entityName: entityTag.attr('type'),
		entityPK: entityTag.attr('key'),
		propertyName: property.attr('name'),
		ruleId: alert.attr('ruleId'),
		Text: resultTag.text(),
		Level: NotificationType.fromString(resultTag.attr('level')),
		isServerNotification: true
	};

	result.caption = getCaption(result);

	return result;
}

function getErrorResponseMessage(error) {
	/*! SuppressStringValidation String validation suppressed in initial refactor */
	return error instanceof errors.DataServiceRequestError
		? error.innerMessage
		: getODataErrorMessage(error.body);
}

function setAlerts(alertsXml, options, alerts) {
	options = $.extend({
		getCaption(x) {
			return captionService.getCaptionFromField(x).caption;
		}
	}, options);

	alertsXml.find('alert').each((idx, xml) => {
		const alert = getAlert(xml, options.getCaption);
		const bucketKey = alert.entityName + '_' + alert.entityPK;
		let bucket = alerts[bucketKey];

		if (!bucket) {
			alerts[bucketKey] = bucket = [];
		}

		bucket.push(alert);
	});
}

export default AlertResultsDeserializer;
