/* eslint-disable no-console */
const maxLogs = 100;

export interface LogData {
  timestamp: string;
  message: string;
}

interface LogWithTag {
  error: (...args: string[]) => void;
  info: (...args: string[]) => void;
  warning: (...args: string[]) => void;
}

/*! StartNoStringValidationRegion method name is used internally */
enum Method {
  Error = "error",
  Log = "log",
  Warn = "warn",
}
/*! EndNoStringValidationRegion */

class Log {
  private readonly logsMap: Record<string, LogData[] | undefined>;

  constructor() {
    this.logsMap = {};
  }

  error(...args: unknown[]): void {
    this.write(Method.Error, DefaultLogTag, ...args);
  }

  info(...args: unknown[]): void {
    this.write(Method.Log, DefaultLogTag, ...args);
  }

  warning(...args: unknown[]): void {
    this.write(Method.Warn, DefaultLogTag, ...args);
  }

  withTag(tag: string): LogWithTag {
    return {
      error: (...args: string[]): void => {
        this.write(Method.Error, tag, ...args);
      },
      info: (...args: string[]): void => {
        this.write(Method.Log, tag, ...args);
      },
      warning: (...args: string[]): void => {
        this.write(Method.Warn, tag, ...args);
      },
    };
  }

  getLogs(): Record<string, LogData[] | undefined> {
    return this.logsMap;
  }

  private write(method: Method, tag = DefaultLogTag, ...params: unknown[]): void {
    const logWindow = window as unknown as LogWindow;
    if (logWindow.glow?.isDebugMode) {
      console[method](...params);
    }

    let logs = this.logsMap[tag];
    if (!logs) {
      logs = [];
      this.logsMap[tag] = logs;
    }

    const message = params.map((p) => p?.toString()).join(", ");
    const log = {
      timestamp: new Date().toISOString(),
      message,
    };

    logs.push(log);
    if (logs.length > maxLogs) {
      logs.shift();
    }
  }
}

export interface LogWindow extends Window {
  glow?: {
    isDebugMode?: boolean;
  };
}

//eslint-disable-next-line @typescript-eslint/naming-convention -- variable name DefaultLogTag should be camelCase
export const DefaultLogTag = "DEFAULT";

export default new Log();
