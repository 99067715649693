import captionService from 'CaptionService';
import Constants from 'Constants';
import dialogService from 'DialogService';
import favoritesService from 'FavoritesService';
import { FormFlowError } from 'FormFlowError';
import formFlowInfoProvider from 'FormFlowInfoProvider';
import { trackBusyStateAsync } from 'GlobalBusyStateTracker';
import navigationService from 'NavigationService';
import PinnableMenuItem from 'PinnableMenuItem';
import RuleExpressionCondition from 'RuleExpressionCondition';
import { isNullOrEmpty } from 'StringUtils';
import { joinUri } from 'UriUtils';
import Promise from 'bluebird';
import breeze from 'breeze-client';
import 'gwLazyTemplate';
import $ from 'jquery';
import ko from 'knockout';

const maxRecents = 10;

function FavoritesViewModel(params) {
	params = $.extend({}, params);

	this._entityType = params.entityType;

	this.headerDisplayMode = ko.observable(params.headerDisplayMode);
	this.showFavorites = ko.observable(params.showFavorites);
	this.showRecents = ko.observable(params.showRecents);
	this.anchor = params.anchor;

	this.favorites = ko.pureComputed(getFavorites.bind(null, this));
	this.recents = ko.pureComputed(getRecents.bind(null, this));
	this.pinnedFavorites = ko.pureComputed(getPinnedFavorites.bind(null, this));
	this.currentIsFavorite = ko.pureComputed(getCurrentIsFavorite);

	this.hasError = favoritesService.hasError;
	this.isLoading = favoritesService.isLoading;

	this.favoritesMenuItem = new PinnableMenuItem(Constants.PinSettingsKeys.Header, Constants.HeaderMenuItems.Favorites, this);
	this.recentsMenuItem = new PinnableMenuItem(Constants.PinSettingsKeys.Header, Constants.HeaderMenuItems.Recents, this);

	this._favoritesFormFlowsCache = Promise.dictionary();
}

FavoritesViewModel.prototype.addToFavoritesAsync = (linkItem) => {
	return favoritesService.addToFavoritesAsync(linkItem);
};

FavoritesViewModel.prototype.addCurrentToFavoritesAsync = () => {
	return favoritesService.addCurrentToFavoritesAsync();
};

FavoritesViewModel.prototype.removeFromFavoritesAsync = (linkItem) => {
	return favoritesService.removeFromFavoritesAsync(linkItem);
};

FavoritesViewModel.prototype.removeCurrentFromFavoritesAsync = () => {
	return favoritesService.removeCurrentFromFavoritesAsync();
};

FavoritesViewModel.prototype.reorderFavoritesAsync = (sortableArgs) => {
	const linkItem = sortableArgs.item.data;
	let newShortcut = sortableArgs.targetIndex + 1;
	const favsLength = sortableArgs.targetParent.length;

	if (newShortcut > favsLength) {
		newShortcut = favsLength;
	}
	else if (newShortcut < 1) {
		newShortcut = 1;
	}

	return favoritesService.reorderFavoritesAsync(linkItem, newShortcut);
};

FavoritesViewModel.prototype.toggleFavoritesPinAsync = (linkItem) => {
	return favoritesService.togglePinAsync(linkItem);
};

function getFavorites(vm) {
	const favorites = favoritesService.favorites() || [];
	return favorites.map((linkItem) => {
		return getLinkItemWrapper(vm, linkItem);
	});
}

function getRecents(vm) {
	let counter = 0;

	const recents = ko.utils.arrayFilter(favoritesService.recents(), (linkItem) => {
		if (counter >= maxRecents) {
			return false;
		}

		const entityType = vm._entityType;
		const include = isNullOrEmpty(entityType) ? true : linkItem.EntityType === entityType;
		if (include) {
			counter++;
		}

		return include;
	});

	return recents.map((linkItem) => {
		return getLinkItemWrapper(vm, linkItem); });
}

function getPinnedFavorites(vm) {
	return favoritesService.pinnedFavorites().map((linkItem) => {
		return $.extend(getLinkItemWrapper(vm, linkItem), {
			templateID: 'pinnedFavoriteMenuTemplate',
			overflowTemplateID: linkItem.EntityPK ? 'entityOverflowTemplate' : undefined,
			buttonTemplateID: linkItem.EntityPK ? 'pinnedEntityButtonTemplate' : 'pinnedFavoriteButtonTemplate',
			isPinnable: true,
			isPinned: true,
			isInOverflow: ko.observable(false),
		});
	});
}

function getCurrentIsFavorite() {
	const currentRecent = favoritesService.currentRecent();

	return currentRecent && currentRecent.isFavorite();
}

function getLinkItemWrapper(favoritesViewModel, linkItem) {
	const result = { data: linkItem };
	if (linkItem.EntityPK) {
		result.hasError = ko.observable(false);
		result.formFlows = ko.observableArray();
		result.formFlowsLoaded = ko.observable(false);
		result.loadFormFlowsAsync = loadFormFlowsAsync.bind(null, favoritesViewModel, result);
	}

	return result;
}

function loadFormFlowsAsync(vm, linkItem) {
	if (linkItem.data.isInvalid) {
		const errorMessage = captionService.getString('ff8276bb-da5d-4741-853c-80140ea1e9f5', 'The record you are trying to access was deleted and does not exist anymore. Do you wish to remove it from Favorites?');
		const errorDialogTitle = captionService.getString('c4faf8c2-09e0-4bc0-bfc6-7ddf5a193dc7', 'Invalid Favorite');
		return dialogService.yesNoConfirmAsync(errorMessage, errorDialogTitle)
			.then((answer) => {
				if (answer === dialogService.buttonTypes.Yes().value) {
					return favoritesService.removeFromFavoritesAsync(linkItem.data);
				}
			});
	}
	else {
		const actionsPromise = vm._favoritesFormFlowsCache
			.getOrAddAsync(linkItem.data.EntityType + linkItem.data.EntityPK, () => getActionsAsync(linkItem.data.EntityType, linkItem.data.EntityPK))
			.then((results) => {
				linkItem.formFlows(results);
				linkItem.formFlowsLoaded(true);
				linkItem.hasError(false);
			})
			.catch((error) => {
				if (error instanceof FormFlowError) {
					return linkItem.hasError(true);
				}
				throw error;
			});
		return trackBusyStateAsync(actionsPromise);
	}
}

function getActionsAsync(entityTypeName, entityPK) {
	const maintainFormFlowPromise = formFlowInfoProvider.getForUsageAsync(entityTypeName, Constants.FormFlowUsage.Maintain)
		.filter(formFlowInfoProvider.isFormFlowAccessible);

	return Promise
		.join(
			fetchEntityByKeyAsync(entityTypeName, entityPK),
			maintainFormFlowPromise)
		.spread((entity, formFlowInfos) => {
			return Promise
				.filter(formFlowInfos, filterEntityVisibilityConditionAsync.bind(null, entity))
				.map((info) => {
					const uri = joinUri('#/formFlow', info.PK, entityPK);
					return {
						PK: info.PK,
						Name: captionService.getStringFromInfo(info.Caption),
						uri,
						onClick: () => navigationService.get(uri, { onCompletedUri: navigationService.getCurrentLocation() }),
					};
				});
		});
}

function fetchEntityByKeyAsync(entityTypeName, entityPK) {
	return breeze.EntityManager.forEntityTypeAsync(entityTypeName).then((entityManager) => {
		return entityManager.fetchEntityByKey(entityTypeName, entityPK).get('entity');
	});
}

async function filterEntityVisibilityConditionAsync(entity, info) {
	if (info.EntityVisibilityCondition) {
		const condition = new RuleExpressionCondition(info.EntityVisibilityCondition);
		return !!(await condition.evaluateAsync(entity));
	}
	return true;
}

export default FavoritesViewModel;
