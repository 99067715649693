import bindingEvaluator from 'BindingEvaluator';
import captionService from 'CaptionService';
import constants from 'Constants';
import Dependency from 'Dependency2';
import log from 'Log';
import lookupDisplayModeHelper from 'LookupDisplayModeHelper';
import { decimalToString } from 'NumericService';
import PropertyInfoVisitor from 'PropertyInfoVisitor';
import { splitPropertyPath } from 'PropertyPathEnumerator';
import registryService from 'RegistryService';
import RuleService from 'RuleService';
import Promise from 'bluebird';
import breeze from 'breeze-client';
import moment from 'moment';
import _ from 'underscore';

const macroUtils = {};

const notAvailable = 'NOT AVAILABLE';

macroUtils.replaceMacrosAsync = (entity, value) => {
	value = (value || '').toString();
	const promises = getMatchedPaths(value)
		.map((matchedPath) => {
			const path = replaceAsPath(matchedPath);
			const isMacro = path.startsWith('%');
			if (entity || isMacro) {
				if (Dependency.isValid(path)) {
					let promise = Promise.resolve([{ results: [entity] }, path]);
					const isOldValue = isOldPath(matchedPath);
					if (!isMacro) {
						if (entity.entityAspect) { //real entity, let's check state
							if (isOldValue && (entity.entityAspect.entityState.isModified() || entity.entityAspect.entityState.isDeleted())) {
								const query = breeze.EntityQuery.fromEntityKey(entity.entityAspect.getKey());
								promise = Promise.all([breeze.EntityManager.forEntityType(entity.entityType).executeQuery(query), path]);
							}
						}
						else { // not a real entity but an entitiesMap of every variables, let's find closest one
							let record = entity;
							const paths = splitPropertyPath(path);
							let resultPromise = Promise.resolve([{ results: [entity] }, path]);
							promise = Promise.while(
								() => paths.length > 1 && !record.entityAspect,
								() => {
									const currentPath = paths.shift();
									return Dependency.getValueAsync(record, currentPath)
										.then((result) => {
											record = result.state === constants.States.Available ? result.value : null;
											if (record?.entityAspect ?? false) {
												if (isOldValue && (record.entityAspect.entityState.isModified() || record.entityAspect.entityState.isDeleted())) {
													const query = breeze.EntityQuery.fromEntityKey(record.entityAspect.getKey());
													resultPromise = Promise.all([breeze.EntityManager.forEntityType(record.entityType).executeQuery(query), paths.join('.')]);
												}
												else {
													resultPromise = Promise.resolve([{ results: [record] }, paths.join('.')]);
												}
											}
										});
								}
							)
								.then(() => {
									return resultPromise;
								});
						}
					}

					return promise
						.then(([data, dependencyPath]) => {
							data = data.results[0];
							return Promise.all([
								data,
								dependencyPath,
								Dependency.getValueAsync(data, dependencyPath),
								PropertyInfoVisitor.getPropertyInfoAsync(
									data && data.entityType,
									dependencyPath
								),
							]);
						})
						.then(([data, dependencyPath, result, propertyInfo]) => {
							if (result.state !== constants.States.Available) {
								return [matchedPath, notAvailable];
							}

							const propertyOwnerLoader = () =>
								Dependency.getUltimateDataItemAsync(data, dependencyPath).get('value');

							return toStringCoreAsync(
								result.value,
								propertyInfo,
								propertyOwnerLoader
							).then((glowValue) => [matchedPath, glowValue]);
						});
				}
				else {
					log.error(`Invalid dependency path ${path}.`);
					return Promise.resolve([matchedPath, notAvailable]);
				}
			}
			else {
				return [matchedPath, notAvailable];
			}
		});

	return Promise.all(promises)
		.then((items) => {
			items.forEach((item) => {
				const placeholderValue = item[1] ?? '';
				value = value.replace(item[0], placeholderValue);
			});

			return value;
		});
};

macroUtils.toStringAsync = (entity, value, bindingInfo) => {
	if (bindingInfo.error) {
		log.error(bindingInfo.error);
		return Promise.resolve(notAvailable);
	}

	return bindingEvaluator.getPropertyInfoAsync(bindingInfo.entityType, bindingInfo.propertyName)
		.then((propertyInfo) => {
			propertyInfo.parentEntityType = bindingInfo.entityType;
			propertyInfo.propertyName = bindingInfo.propertyName;

			const propertyOwnerLoader = () => {
				if (bindingInfo.ultimateDataItemPath) {
					return Dependency.getValueAsync(
						entity,
						bindingInfo.ultimateDataItemPath.replace(/.$/, '')
					).get('value');
				} else {
					return Promise.resolve(entity);
				}
			};

			return toStringCoreAsync(value, propertyInfo, propertyOwnerLoader);
		});
};

macroUtils.getDependencyPaths = (value) => {
	value = (value || '').toString();
	return Array.from(
		new Set(
			getMatchedPaths(value)
				.filter((x) => !isOldPath(x))
				.map((matchedPath) => replaceAsPath(matchedPath)),
		),
	);
};

function toStringCoreAsync(value, propertyInfo, propertyOwnerLoader) {
	let promise;
	let propertyType;

	if (propertyInfo) {
		if (propertyInfo.error) {
			log.error(propertyInfo.error);
			return Promise.resolve(notAvailable);
		}

		if (!value && !propertyInfo.isNullable) {
			value = getDefaultValueByType(propertyInfo.propertyType);
		}

		promise = processDataProperty(propertyInfo.parentEntityType, propertyInfo.propertyName, propertyOwnerLoader);
		if (promise) {
			return promise;
		}

		if (propertyInfo.propertyType === 'DateTime') {
			return getDateTimeTypeAsync(propertyInfo)
				.then((dateType) => (value ? moment(value).toDateTimeTypeString(dateType) : value));
		}
		if (propertyInfo.propertyType === 'Decimal') {
			const rules = RuleService.get(propertyInfo.parentEntityType.interfaceName);
			const numericSize = rules.numericSize(propertyInfo.propertyName);
			if (numericSize?.dynamicScale) {
				return propertyOwnerLoader()
					.then((owner) => Dependency.getValueAsync(owner, numericSize?.dynamicScale))
					.then(({ value: dynamicScaleValue }) => {
						const finalScale = _.isNumber(dynamicScaleValue) ? dynamicScaleValue : numericSize?.scale;
						return _.isNumber(finalScale) ? decimalToString(value, finalScale) : value.toString();
					});
			}
			else {
				return registryService.getValueAsync('GlowSuppressTrailingZeroes')
					.then((suppressTrailingZeroes) => {
						return suppressTrailingZeroes || !_.isNumber(numericSize?.scale) ? value.toString() : decimalToString(value, numericSize?.scale);
					});
			}
		}

		propertyType = propertyInfo.propertyType;
	}

	/*! SuppressStringValidation data type */
	if (propertyType === 'Boolean' || typeof value === 'boolean') {
		promise = Promise.resolve(value ? captionService.getString('Yes', 'Yes') : captionService.getString('No', 'No'));
	}
	else if (propertyType === 'Measure') {
		promise = Promise.resolve(value.toLocaleString());
	}
	else if (isEntityObject(value)) {
		promise = getDisplayValueAsync(value, value.entityType.interfaceName);
	}
	else {
		promise = Promise.resolve(value === null || value === undefined ? '' : value.toString());
	}

	return promise;
}

function getDateTimeTypeAsync(propertyInfo) {
	return RuleService.loadAsync(propertyInfo.parentEntityType.interfaceName)
		.then((rules) => rules.dateTimeType(propertyInfo.propertyName));
}

function processDataProperty(entityType, propertyName, propertyOwnerLoader) {
	const dataProperty = entityType.getDataProperty(propertyName);
	if (dataProperty && dataProperty.relatedNavigationProperty) {
		return propertyOwnerLoader().then((owner) => Dependency
			.getValueAsync(owner, dataProperty.relatedNavigationProperty.name)
			.then(({ value }) => {
				return getDisplayValueAsync(value, dataProperty.relatedNavigationProperty.entityType.interfaceName);
			}));
	}
}

function getDefaultValueByType(propertyType) {
	const valueType = breeze.DataType[propertyType] || breeze.DataType.Undefined;
	return valueType.defaultValue;
}

function getDisplayValueAsync(value, entityName) {
	return RuleService.loadAsync(entityName)
		.then((rules) => {
			const displayMode = lookupDisplayModeHelper.getDisplayMode(rules);
			const codePath = rules.codeProperty();
			const descriptionPath = rules.descriptionProperty();

			return lookupDisplayModeHelper
				.getDisplayValueAsync(value, displayMode, codePath, descriptionPath)
				.then((result) => {
					return result || '';
				});
		});
}

function isEntityObject(value) {
	return value !== null
		&& value !== undefined
		&& typeof value.entityType !== 'undefined';
}

function getMatchedPaths(value) {
	const regex = /((\{<)|(\(\*)){1}([^\s<>*]+)(>|\*){1}(\.((OLD|NEW)))?(\}|\)){1}/g;
	return value.match(regex) || [];
}

function replaceAsPath(matchedPath) {
	return matchedPath
		.replace('{<', '')
		.replace(/>{1}(\.((OLD|NEW)))?\}{1}/g, '')
		.replace('(*', '')
		.replace(/\*{1}(\.((OLD|NEW)))?\){1}/g, '');
}

function isOldPath(path) {
	return />\.OLD\}$/g.test(path);
}

export default macroUtils;
