import dialogService from 'DialogService';
import materialDesignDialogService from 'MaterialDesignDialogService';
import NotificationType from 'NotificationType';

class ErrorDialogService {
	constructor() {
	}

	showValidationResultsAsync(message, title, notificationSummary, filter = null) {
		const buttonOptions = [{ caption: dialogService.buttonTypes.Ok().text, result: dialogService.buttonTypes.Ok().value, isDismiss: true, isDefault: true }];
		const notificationType = NotificationType.Error;

		if (materialDesignDialogService.canShowErrorDialog()) {
			return materialDesignDialogService.showErrorDialogAsync(notificationType, message, title, notificationSummary, buttonOptions, filter);
		}

		return dialogService.showValidationResultsAsync(
			notificationType,
			message,
			title,
			notificationSummary,
			{ filter }
		);
	}

}

export default new ErrorDialogService();
