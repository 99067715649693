export default class VueComponentProxy {
	constructor(vm, extension, materialDesignForm) {
		let extensionInitFn = extension?.initFormExtender;
		if (!vm) {
			throw new Error('Must provide a Vue instance.');
		}

		this.invoke = function (methodName, ...args) {
			if (extensionInitFn) {
				extensionInitFn();
				extensionInitFn = null;
			}

			const componentHasMethod = typeof vm.$options.methods?.[methodName] === 'function';
			if (!materialDesignForm && componentHasMethod) {
				return vm[methodName].apply(vm, args);
			}

			if (typeof extension?.methods?.[methodName] === 'function') {
				return extension.methods[methodName].apply(vm, args);
			}

			if (materialDesignForm && componentHasMethod) {
				throw new Error('Cannot invoke method directly on the component in G2.');
			}

			throw new Error(`No such method '${methodName}'.`);
		};
	}
}
