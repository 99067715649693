import Mousetrap from 'Mousetrap';
import { format, isNullOrEmpty } from 'StringUtils';
import $ from 'jQueryExtensions';
import _ from 'underscore';

function ShortcutService() {
}

let shortcutStack = {};

let isRegisteredForGlobalTooltip = false;
let isShowingTooltip = false;
const originalStopCallback = Mousetrap.stopCallback;

Mousetrap.stopCallback = (e, element, combo) => {
	if (shortcutStack[combo]) {
		return false;
	}
	return originalStopCallback(e, element, combo);
};

ShortcutService.prototype.reset = () => {
	shortcutStack = {};
	isRegisteredForGlobalTooltip = false;
	isShowingTooltip = false;
	Mousetrap.reset();
};

ShortcutService.prototype.bindFocusToElement = function ($element, shortcutKey, onFocus) {
	const self = this;
	return self.bind(shortcutKey,
		() => {
			const canExecuteBindCallback = self.canExecuteBindCallback($element);
			if (canExecuteBindCallback) {
				if (!$element.is(':focus')) {
					$element.trigger('focus');
				}

				if (onFocus) {
					onFocus($element);
				}

				return $element;
			}
		});
};

ShortcutService.prototype.bindCallbackToElement = function ($element, shortcutKey, callBack) {
	const self = this;
	return self.bind(shortcutKey, () => {
		const canExecuteBindCallback = self.canExecuteBindCallback($element);
		if (canExecuteBindCallback) {
			let returnElement;
			if (callBack) {
				returnElement = callBack($element);
			}

			return returnElement || $element;
		}
	});
};

ShortcutService.prototype.registerTooltipsShow = () => {
	if (!isRegisteredForGlobalTooltip) {
		const registerDispose = bindGlobal('ctrl+alt+enter', () => {
			$('[has-tooltip=true]').each((_, element) => {
				const $this = $(element);
				if ($this.tooltip) {
					if (isShowingTooltip) {
						$this.tooltip('hide');
					}
					else {
						$this.tooltip('show');
					}
				}
			});
			isShowingTooltip = !isShowingTooltip;
			return false;
		});
		$(document).on('click', hideShortcutTooltips);
		isRegisteredForGlobalTooltip = true;
		return registerDispose;
	}
};

ShortcutService.prototype.bindToElement = function (element, shortcut) {
	const self = this;
	const $element = !(element instanceof $) ? $(element) : element;
	const mousetrapBind = () => {
		if (self.canExecuteBindCallback($element)) {
			$element.trigger('click');
			hideShortcutTooltips();
		}
		return false;
	};

	$element.attr('has-tooltip', true);
	$element.data('shortcut', shortcut);
	return bindGlobal(shortcut.toLowerCase(), mousetrapBind);
};

ShortcutService.prototype.trigger = (shortcut, action) => {
	return shortcutHandler(null, shortcut, action);
};

ShortcutService.prototype.getTooltipCaption = (shortcut) => {
	if (!isNullOrEmpty(shortcut)) {
		return format('[{0}]', shortcut.toLowerCase());
	}
	return '';
};

ShortcutService.prototype.canExecuteBindCallback = ($element) => {
	return $element.isInsideActiveFocusScope() && !$element.is(':disabled') && $element.is(':visible');
};

function equalsCallback(callback, c) {
	return c === callback;
}

function bindGlobal(shortcut, callback) {
	let shortcutStackForShortcut = shortcutStack[shortcut];
	if (!shortcutStackForShortcut) {
		shortcutStackForShortcut = [];
	}

	if (shortcutStackForShortcut.length === 0 || shortcutStackForShortcut[0] !== callback) {
		shortcutStackForShortcut = _.reject(shortcutStackForShortcut, equalsCallback.bind(null, callback));
		shortcutStackForShortcut.unshift(callback);

		if (shortcutStackForShortcut.length === 1) {
			Mousetrap.bind(shortcut, shortcutHandler);
		}
	}

	shortcutStack[shortcut] = shortcutStackForShortcut;

	return {
		dispose: dispose.bind(null, shortcut, callback)
	};
}

function dispose(shortcut, callback) {
	let shortcutStackForShortcut = shortcutStack[shortcut];
	if (shortcutStackForShortcut) {
		shortcutStackForShortcut = _.reject(shortcutStackForShortcut, equalsCallback.bind(null, callback));
		if (shortcutStackForShortcut.length === 0) {
			Mousetrap.unbind(shortcut);
			delete shortcutStack[shortcut];
		}
		else {
			shortcutStack[shortcut] = shortcutStackForShortcut;
		}
	}
}

function shortcutHandler(event, shortcut) {
	const shortcutStackForShortcut = shortcutStack[shortcut];
	if (shortcutStackForShortcut && shortcutStackForShortcut.length > 0) {
		let handler;
		shortcutStackForShortcut.some((s) => handler = s.apply(null, arguments));
		return handler;
	}
}

ShortcutService.prototype.bind = bindGlobal;

function hideShortcutTooltips() {
	$('[has-tooltip=true]').each((_, element) => {
		const $this = $(element);
		if ($this.tooltip) {
			$this.tooltip('hide');
		}
	});
	isShowingTooltip = false;
}

export default new ShortcutService();
