import bindingEvaluator from 'BindingEvaluator';
import $ from 'jquery';
import ko from 'knockout';
import readOnlyEvaluator from 'ReadOnlyEvaluator';

const contextKey = '_gwReadOnlyContext';

ko.bindingHandlers.gwReadOnly = {
	init(element) {
		const $element = $(element);
		const context = { isReadOnly: ko.observable(true), parentChangeListener: ko.observable(null) };
		$element.addClass('gwReadOnlyProvider');
		$element.data(contextKey, context);
		$element.find('.gwReadOnlyProvider').not($element.find('.gwReadOnlyProvider .gwReadOnlyProvider')).each((_, element) => {
			$(element).data(contextKey).parentChangeListener.valueHasMutated();
		});
	},
	update(element, valueAccessor, allBindings, viewModel, bindingContext) {
		const $element = $(element);
		const context = $element.data(contextKey);
		if (context.isDisabled) {
			return;
		}

		const isReadOnly = evaluateIsReadOnly($element, ko.unwrap(valueAccessor()), bindingContext);
		ko.ignoreDependencies(() => {
			context.isReadOnly(isReadOnly);

			if ($element.component()) {
				$element.component('markAsReadOnly', isReadOnly);
			} else {
				const css = allBindings.get('gwReadOnlyCss');
				if (css) {
					$element.toggleClass(css, isReadOnly);
				} else {
					decorateReadOnly($element, isReadOnly);
				}
			}
		});
	}
};

function evaluateIsReadOnly($element, value, bindingContext) {
	const methodName = 'isComponentReadOnly';
	if ($element.hasComponentMethod(methodName)) {
		if ($element.component(methodName, bindingContext, value)) {
			return true;
		}
	} else {
		const propertyPath = $element.data('property');
		let dataItem = bindingContext.$data;
		let propertyName = null;

		if (propertyPath) {
			dataItem = bindingEvaluator.getUltimateDataItem(bindingContext, dataItem, propertyPath);
			propertyName = bindingEvaluator.getPropertyName(propertyPath);
		}

		if (readOnlyEvaluator.isReadOnly(bindingContext, dataItem, value, propertyName)) {
			return true;
		}
	}

	$element.data(contextKey).parentChangeListener();
	const parent = $element.parent().closest('.gwReadOnlyProvider');
	if (parent.length === 1) {
		const parentContext = parent.data(contextKey);
		if (parentContext) {
			return parentContext.isReadOnly();
		}
	}

	return false;
}

function decorateReadOnly(element, isReadOnly) {
	const $element = !(element instanceof $) ? $(element) : element;
	/*! StartNoStringValidationRegion No captions here */
	if (isReadOnly) {
		$element.prop('readonly', true);
		$element.parent().parent().addClass('ui-disabled');
	} else {
		$element.prop('readonly', false);
		$element.parent().parent().removeClass('ui-disabled');
	}
	/*! EndNoStringValidationRegion */
}

export function disableBinding($element) {
	$element.data(contextKey).isDisabled = true;
}
