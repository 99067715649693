import { newGuid } from "GuidGenerator";

export type MessageHandler<TData = void, TResult = void> = (
  data: TData
) => Promise<TResult> | TResult;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class MessageType<TData = void, TResult = void> {
  constructor(public id: string) {}
}

interface Subscription {
  id: string;
  handler: MessageHandler<unknown, unknown>;
}

export default class MessageBus {
  private readonly subscriptionMap = new Map<string, Subscription[]>();

  publishAsync<TData extends void, TResult>(
    messageType: MessageType<TData, TResult>
  ): Promise<TResult[]>;
  publishAsync<TData1, TResult, TData2 extends TData1>(
    messageType: MessageType<TData1, TResult>,
    data: TData2
  ): Promise<TResult[]>;
  /** @deprecated Use overload with MessageType<TData, TResult> instead */
  publishAsync(messageType: string, data?: unknown): Promise<unknown[]>;
  async publishAsync(messageType: MessageType | string, data?: unknown): Promise<unknown[]> {
    const subscriptions = this.subscriptionMap.get(getMessageTypeId(messageType));
    let result: unknown[];
    if (subscriptions) {
      result = await Promise.all(
        [...subscriptions].map((subscription) => subscription.handler(data))
      );
    } else {
      result = [];
    }

    return result;
  }

  subscribe<TData, TResult1, TResult2 extends TResult1>(
    messageType: MessageType<TData, TResult1>,
    messageHandler: MessageHandler<TData, TResult2>
  ): string;
  /** @deprecated Use overload with MessageType<TData, TResult> instead */
  subscribe(messageType: string, messageHandler: MessageHandler<unknown, unknown>): string;
  subscribe(
    messageType: MessageType | string,
    messageHandler: MessageHandler<unknown, unknown>
  ): string {
    const subscriptions = this.getOrCreateSubscriptionsByType(getMessageTypeId(messageType));
    const subscription = {
      id: newGuid(),
      handler: messageHandler,
    };

    subscriptions.push(subscription);
    return subscription.id;
  }

  unsubscribe(messageType: MessageType | string, subscriptionId: string): void {
    const subscriptions = this.subscriptionMap.get(getMessageTypeId(messageType));
    if (subscriptions) {
      const subscriptionIndex = subscriptions.findIndex(
        (subscription) => subscription.id === subscriptionId
      );
      if (subscriptionIndex !== -1) {
        subscriptions.splice(subscriptionIndex, 1);
      }
    }
  }

  private getOrCreateSubscriptionsByType(messageType: string): Subscription[] {
    let subscriptions = this.subscriptionMap.get(messageType);
    if (!subscriptions) {
      subscriptions = [];
      this.subscriptionMap.set(messageType, subscriptions);
    }

    return subscriptions;
  }

  getSubscriptionCount(messageType: MessageType | string): number {
    const subscriptions = this.subscriptionMap.get(getMessageTypeId(messageType));
    return subscriptions ? subscriptions.length : 0;
  }

  clear(): void {
    this.subscriptionMap.clear();
  }
}

function getMessageTypeId(messageType: MessageType | string): string {
  return typeof messageType === "string" ? messageType : messageType.id;
}
