import breeze from 'breeze-client';
import constants from 'Constants';
import errors from 'Errors';
import glowMacroProvider from 'GlowMacroProvider';
import ko from 'knockout';
import log from 'Log';
import { areValuesEqual, getAvailableResult, getResult } from './Utils';

function macroPath(propertyPath, items, _data, context) {
	const data = getAvailableResult(glowMacroProvider.getGlowMacroEntity());
	return context.strategy.wait(reducePath(items, 0, data, context), (result) => {
		if (context.options.onVisitMacro) {
			context.options.onVisitMacro(propertyPath, result.state);
		}
		return result;
	});
}

function path(items, data, context) {
	return reducePath(items, 0, getAvailableResult(data), context);
}

function rootPath(items, _data, context) {
	return reducePath(items, 0, getAvailableResult(context.rootData), context);
}

function reducePath(items, itemIndex, result, context) {
	if (itemIndex === items.length || result.state !== constants.States.Available) {
		return result;
	}

	const awaitable = items[itemIndex](result.value, context);
	return context.strategy.wait(awaitable, (result) =>
		reducePath(items, itemIndex + 1, result, context)
	);
}

function isDefault(value, propertyName, data, context) {
	if (data == null) {
		return getAvailableResult(true);
	}

	return context.strategy.waitAvailableOne(value, data, context, (value) => {
		if (Array.isArray(value)) {
			throw new errors.DependencyError('IsDefault() can only be used on a scalar property.');
		} else {
			const defaultValue = getDefaultValue(data, propertyName);
			return getAvailableResult(areValuesEqual(value, defaultValue));
		}
	});
}

function getDefaultValue({ entityType }, propertyName) {
	if (!entityType) {
		return;
	}

	const property = entityType.getProperty(propertyName);
	if (property && property.defaultValue !== undefined) {
		return property.defaultValue;
	}

	if (!property || property.isDataProperty) {
		return entityType.getEmptyValue && entityType.getEmptyValue(propertyName, true);
	}
}

function property(propertyName, separator, isTopLevel, data, context) {
	if (isTopLevel && !separator) {
		if (context.options.skipLastSegmentEvaluation) {
			return getAvailableResult(data);
		} else if (context.options.valueToSet !== undefined) {
			return setPropertyValue(propertyName, data, context);
		}
	}

	return getPropertyValue(propertyName, separator, isTopLevel, data, context);
}

function getPropertyValue(propertyName, separator, isTopLevel, data, context) {
	if (!Array.isArray(data)) {
		return getPropertyValueForItem(propertyName, separator, isTopLevel, data, context);
	}

	return context.strategy.map(
		data,
		(item) => getPropertyValueForItem(propertyName, separator, isTopLevel, item, context),
		(items) => {
			const result = [];
			for (let i = 0; i < items.length; i++) {
				const item = items[i];
				if (item.state === constants.States.NotLoaded) {
					return item;
				} else if (item.state === constants.States.Available) {
					if (Array.isArray(item.value)) {
						result.push(...item.value);
					} else {
						result.push(item.value);
					}
				}
			}
			return getAvailableResult(result);
		}
	);
}

export function getPropertyValueForItem(propertyName, separator, isTopLevel, data, context) {
	if (data == null) {
		return getAvailableResult(null);
	}

	let propertyValue = data[propertyName];
	if (propertyValue === undefined && context.options.throwOnError) {
		throw new errors.DependencyError(
			`Property '${getPropertyDescription(propertyName, data)}' does not exist.`
		);
	}

	if (context.options.onVisitProperty) {
		context.options.onVisitProperty(data, propertyName);
	}

	if (propertyValue == null) {
		return getAvailableResult(null);
	}

	const isSlashSeparator = separator === '/';
	const currentItemAccessor = isSlashSeparator && context.options.currentItemAccessor;
	let state = getPropertyState(propertyValue, context.options);

	if (state === constants.States.NotLoaded) {
		if (context.shouldAvoidLoading()) {
			return getResult(state, null);
		} else if (currentItemAccessor && !currentItemAccessor.shouldLoad(data, propertyName)) {
			state = constants.States.Available;
		} else {
			let promise;
			if (currentItemAccessor) {
				promise = currentItemAccessor.loadAsync(data, propertyName);
			} else {
				if (propertyValue.loadAsync) {
					promise = propertyValue.loadAsync();
				}
			}

			propertyValue(); // Subscribe to value.
			return context.strategy.wait(promise, () => {
				state = propertyValue.getState();
				if (state === constants.States.NotLoaded) {
					return getResult(state, null);
				} else {
					return getLoadedResult();
				}
			});
		}
	}

	return getLoadedResult();

	function getLoadedResult() {
		if (state === constants.States.NotAvailable) {
			return getNotAvailableResult(propertyName, data);
		} else if (currentItemAccessor) {
			propertyValue = currentItemAccessor.read(data, propertyName);
		} else if (separator || !isTopLevel || context.options.unwrapFinalValue !== false) {
			propertyValue = ko.recursiveUnwrap(propertyValue, 0);
		}

		if (propertyValue == null) {
			propertyValue = null;
		}

		return getResult(state, propertyValue);
	}
}

function getPropertyDescription(propertyName, data) {
	return data.entityType ? data.entityType.interfaceName + '.' + propertyName : propertyName;
}

function getNotAvailableResult(propertyName, data) {
	log.warning(`Property '${getPropertyDescription(propertyName, data)}' is not available.`);
	return getResult(constants.States.NotAvailable, null);
}

function setPropertyValue(propertyName, data, context) {
	const done = () => getAvailableResult(null);

	if (Array.isArray(data)) {
		return context.strategy.map(
			data,
			(item) => setPropertyValueCore(propertyName, item, context),
			done
		);
	} else {
		return context.strategy.wait(setPropertyValueCore(propertyName, data, context), done);
	}
}

function setPropertyValueCore(propertyName, data, context) {
	if (!data) {
		return;
	}

	const propertyValue = data[propertyName];
	if (propertyValue === undefined && context.options.throwOnError) {
		throw new errors.DependencyError(
			`Property '${getPropertyDescription(propertyName, data)}' does not exist.`
		);
	}

	const { valueToSet } = context.options;
	if (ko.isObservable(propertyValue)) {
		const { entityAspect } = data;

		if (entityAspect) {
			if (context.options.setDefaultValues) {
				return breeze.utils.runWithProposedValuesAsync(data, () => {
					return entityAspect.setValueAsync(propertyName, valueToSet);
				});
			} else {
				return entityAspect.setValueAsync(propertyName, valueToSet);
			}
		} else {
			propertyValue(valueToSet);
		}
	} else {
		data[propertyName] = valueToSet;
	}
}

function getPropertyState(propertyValue, options) {
	if (options.observeState && propertyValue.observeState) {
		return propertyValue.observeState();
	} else if (propertyValue.getState) {
		return propertyValue.getState();
	} else {
		return constants.States.Available;
	}
}

function ofType(typeName, data) {
	const match = matchesTypeName(data && data.entityType, typeName);
	return getAvailableResult(match ? data : null);
}

function matchesTypeName(entityType, typeName) {
	return (
		!!entityType &&
		(entityType.interfaceName === typeName ||
			matchesTypeName(entityType.baseEntityType, typeName))
	);
}

function originalValue(propertyName, data, context) {
	if (data == null) {
		return getAvailableResult(null);
	}

	const { entityAspect } = data;
	if (!entityAspect) {
		if (Array.isArray(data)) {
			throw new errors.DependencyError(
				'OriginalValue() can only be used on a scalar property.'
			);
		} else {
			throw new errors.DependencyError(
				`OriginalValue() cannot be used on property '${propertyName}' because the owner is not an entity.`
			);
		}
	}

	const propertyValue = data[propertyName];
	if (propertyValue !== undefined) {
		if (!data.entityType.getDataProperty(propertyName)) {
			throw new errors.DependencyError(
				`OriginalValue() cannot be used on property '${data.entityType.interfaceName}.${propertyName}' because it is not a data property.`
			);
		} else if (entityAspect.entityState.isAdded()) {
			return getAvailableResult(null);
		} else if (propertyValue.getState() === constants.States.Available) {
			return getAvailableResult(entityAspect.getOriginalValue(propertyName));
		} else {
			// Data property state can only be Available or NotAvailable.
			return getNotAvailableResult(propertyName, data);
		}
	} else if (context.options.throwOnError) {
		throw new errors.DependencyError(
			`Property '${getPropertyDescription(propertyName, data)}' does not exist.`
		);
	} else {
		return getAvailableResult(null);
	}
}

export default { path, isDefault, macroPath, ofType, originalValue, property, rootPath };
