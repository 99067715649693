import $ from 'jquery';

const keypressTimeout = 2000;

export default class KeyCaptor {
	constructor() {
		this._keydownTimeout = null;
		this._searchTermBuffer = '';
	}

	initCapture() {
		this.disposeListener();

		this._searchTermBuffer = '';

		$(document).on('keydown.KeyCaptor', this._handleDocumentKeyDown);
		this._keydownTimeout = window.setTimeout(() => this.disposeListener(), keypressTimeout);
	}

	endCapture() {
		const currentSearchTerm = this._searchTermBuffer;
		this.disposeListener();
		this._searchTermBuffer = '';

		return currentSearchTerm;
	}

	disposeListener() {
		$(document).off('keydown.KeyCaptor', this._handleDocumentKeyDown);
		window.clearTimeout(this._keydownTimeout);
	}

	_handleDocumentKeyDown = (e) => {
		if (!isComposing(e) && !(e.ctrlKey || e.altKey || e.metaKey) && e.key && e.key.length === 1) {
			window.clearTimeout(this._keydownTimeout);
			this._keydownTimeout = window.setTimeout(() => this.disposeListener(), keypressTimeout);

			this._searchTermBuffer += e.key;
		}
	};
}

function isComposing(e) {
	return e.isComposing || e.keyCode === 229;
}
