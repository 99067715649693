import captionService from 'CaptionService';

/*! StartNoStringValidationRegion Theme Items */
export default {
	Background: { Code: 'Background', Value: 'Background', get Description() { return captionService.getString('c5e55617-a5ac-47bb-a5fc-767b7b9d318c', 'System background'); } },
	GeneralText: { Code: 'GeneralText', Value: 'GeneralText', get Description() { return captionService.getString('71509f84-ab35-466a-bd9d-f203d1a422e4', 'Menu and general text'); } },
	Icons: { Code: 'Icons', Value: 'Icons', get Description() { return captionService.getString('2363a70e-d59e-4943-b5a9-e9e7cb14f3f9', 'Icons'); } },
	FieldControl: { Code: 'FieldControl', Value: 'FieldControl', get Description() { return captionService.getString('3ed09ee7-d674-42a6-9344-e4b51ec151c9', 'Fields'); } },
	FieldTitleText: { Code: 'FieldTitle', Value: 'FieldTitleText', get Description() { return captionService.getString('5b02ed6f-aafe-4a0b-8259-2ac4925129e7', 'Field title text'); } },
	ValueText: { Code: 'ValueText', Value: 'ValueText', get Description() { return captionService.getString('9fa7db3c-770f-4fec-8878-30951b33333d', 'Grid header, value text and non-default button text'); } },
	DefaultButton: { Code: 'DefaultButton', Value: 'DefaultButton', get Description() { return captionService.getString('6c27b7b2-00b8-4cd6-bf45-f4ee063f264d', 'Banner, title and default button background'); } },
	Button: { Code: 'Button', Value: 'Button', get Description() { return captionService.getString('cd91824c-fc07-4c37-8b58-81634a1a4a2c', 'Non-default button background'); } },
	DefaultButtonText: { Code: 'DefaultButtonText', Value: 'DefaultButtonText', get Description() { return captionService.getString('dac64389-2362-410f-aa92-734373766599', 'Header and default button text'); } },
	Hover: { Code: 'Hover', Value: 'Hover', get Description() { return captionService.getString('46987d18-e857-4997-ac68-0b1f5d16a3ef', 'Hover and grid select'); } },
	HoverText: { Code: 'HoverText', Value: 'HoverText', get Description() { return captionService.getString('52ced4a7-a757-4174-8282-aa1ff5aa0901', 'Default and non-default button hover text'); } }
};
/*! EndNoStringValidationRegion */
