import Promise from 'bluebird';
import RuleVertex from 'RuleVertex';
import validationEngine from 'ValidationEngine';

export default class ValidationRuleVertex extends RuleVertex {
	get isValidation() {
		return true;
	}

	reportChangedAsync() {
		return Promise.try(() => {
			this.wireDependencies();
		});
	}

	shouldCreateDependencyVertex(entity, propertyName) {
		return (
			super.shouldCreateDependencyVertex(entity, propertyName) ||
			!!entity.entityType.getNavigationProperty(propertyName)
		);
	}

	validateAsync() {
		return validationEngine.validateRuleAsync(this.entity, this.rule);
	}
}
