import captionService from "CaptionService";
import helpService from "HelpService";
import navigationService from "NavigationService";
import userSession from "UserSession";
import windowManager from "WindowManager";
import ko from "knockout";

class HelpPresenter {
  async showHelpInstructionsAsync(linkedScreenIdentifier: string, computedTitle: ko.Observable<string>): Promise<void> {
    if (!userSession.isLoggedOn()) {
      return Promise.resolve();
    }

    let setting!: boolean | null;
    let instructions: string | null | undefined;
    try {
      setting = await helpService.shouldShowHelpAsync();
      const showHelpBeforeScreens = setting && !userSession.hasShownHelpPage(linkedScreenIdentifier);

      if (showHelpBeforeScreens && !windowManager.isOpen("helpPageWindow")) {
        instructions = await helpService.getFormHelpAsync(linkedScreenIdentifier, true);
      }
    } catch {
      setting = false;
    } finally {
      navigationService.post("#/support/showHelpPage", {
        linkedScreenIdentifier,
        title: ko.computed(() =>
          captionService.getString("3E6AA0B3-A1DD-4558-B906-7BC2C6935D9C", "Help for {0}", computedTitle())
        ),
        isComputedTitle: true,
        setting,
        instructions,
        preventDialogShow: !instructions,
      });
    }
  }

  showConfigureHelp(): void {
    showHelp("showConfigureHelp", captionService.getString("6e0fe5fc-3416-4fd5-b57c-bbddf49dd4d3", "Configure Help"));
  }

  showHelpPage(): void {
    showHelp("showHelpPage", captionService.getString("52235196-d843-4757-9440-12bdbe8b54f8", "Help"));
  }
}

function showHelp(command: string, titlePrefix: string): void {
  const currentPage = userSession.currentPage;
  if (currentPage) {
    navigationService.post("#/support/" + command, {
      linkedScreenIdentifier: currentPage.id,
      title: captionService.getString(
        "05B43940-143E-4613-80DD-12622AD93387",
        "{0} for {1}",
        titlePrefix,
        currentPage.name
      ),
    });
  }
}

export default new HelpPresenter();
