import AsyncLock from 'AsyncLock';
import { FormExtenderError } from 'Errors';
import { newGuid } from 'GuidGenerator';
import KOComponentProxy from 'KOComponentProxy';
import log from 'Log';
import { getPageExtensions } from 'PageExtensions';
import ruleFunction from 'RuleFunction';
import VueComponentProxy from 'VueComponentProxy';
import { getGlowWrapperInstance } from 'VueComponentService';
import widgetUtils from 'WidgetUtils';
import Promise from 'bluebird';
import $ from 'jquery';
import ko from 'knockout';

const stateMarker = 'gwFormExtender-state-a8e155e2-696f-4cc2-9937-e0a812ec701a';

function getExtenderFnName(extender) {
	return typeof extender === 'function' ? extender.name : extender;
}

ko.bindingHandlers.gwFormExtender = {
	init(element, valueAccessor) {
		const { extender } = valueAccessor();
		const extenderFnName = getExtenderFnName(extender);
		const state = {
			asyncLock: new AsyncLock(),
			disposeAsync: undefined,
			disposed: false,
			version: 0,
			id: newGuid(),
		};
		$(element).data(stateMarker, state);

		ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
			state.disposed = true;
			state.asyncLock.doAsync(() => state.disposeAsync?.());
		});
		log.info(`[gwFormExtender] ${extenderFnName} - Initialized - #${state.id} / v0`);
	},
	update(element, valueAccessor, allBindings, viewModel, bindingContext) {
		ko.unwrap(bindingContext.$rawData);
		ko.ignoreDependencies(() => {
			const $element = $(element);
			const state = $element.data(stateMarker);
			const { extender, materialDesignForm } = valueAccessor();
			const extenderFnName = getExtenderFnName(extender);

			if (!state) {
				log.info(
					`[gwFormExtender] ${extenderFnName} - skipping update as element is disposed`
				);
				return;
			}

			const version = ++state.version;
			const id = state.id;
			log.info(`[gwFormExtender] ${extenderFnName} - update - #${id} / v${version}`);

			const extendComponents = getExtendComponents(
				$element,
				extenderFnName,
				materialDesignForm,
				state,
				version
			);
			const getRootDataItem = () => viewModel;
			const getMessageBus = () => getPageExtensions(bindingContext).messageBus;
			const extenderFn = ruleFunction(extender);
			extenderFn({ extendComponents, getRootDataItem, getMessageBus });
		});
	},
};

function getExtendComponents($element, extenderFnName, materialDesignForm, state, version) {
	return async (controlIds, callback, dispose) => {
		if (!dispose) {
			/*! SuppressStringValidation Not a caption */
			throw new FormExtenderError(
				'dispose method was not provided, you must provide a dispose method when using formExtenders because your code can run more than once and sometimes the DOM elements will get re-rendered and sometimes they will not'
			);
		}

		if (!isValidState(state, version)) {
			log.info(
				`[gwFormExtender] ${extenderFnName} - skipping extendComponents as element is disposed or version is not current - #${state.id} / v${version}`
			);
			return;
		}

		await state.asyncLock.doAsync(async () => {
			if (!isValidState(state, version)) {
				log.info(
					`[gwFormExtender] ${extenderFnName} - skipping extendComponents as element is disposed or version is not current - #${state.id} / v${version}`
				);
				return;
			}

			const components = await getComponentsAsync(
				$element,
				controlIds,
				extenderFnName,
				materialDesignForm,
				state,
				version
			);

			if (state.disposeAsync) {
				log.info(
					`[gwFormExtender] ${extenderFnName} - disposing before callback - #${state.id} / v${version}`
				);
				await state.disposeAsync();
			}

			if (!isValidState(state, version)) {
				log.info(
					`[gwFormExtender] ${extenderFnName} - skipping callback as element is disposed or version is not current - #${state.id} / v${version}`
				);
				return;
			}

			log.info(
				`[gwFormExtender] ${extenderFnName} - invoking callback - #${state.id} / v${version}`
			);
			attachDispose(components, state, dispose);
			await initExtenderAsync(callback, components, state, version);
		});
	};
}

async function getComponentsAsync(
	$element,
	controlIds,
	extenderFnName,
	materialDesignForm,
	state,
	version
) {
	const components = [];
	const extenderInfo = { name: extenderFnName, state, version };
	const initPromises = [];
	getControlItems(controlIds, $element.parent(), extenderInfo).forEach(([$control, id]) => {
		if ($control) {
			let proxy;
			const vm = getGlowWrapperInstance($control[0]);
			if (vm) {
				proxy = new VueComponentProxy(vm.$children[0], vm.extension, materialDesignForm);
				initPromises.push(vm.waitUntilLoadedAsync && vm.waitUntilLoadedAsync());
			} else {
				proxy = new KOComponentProxy($control);
				initPromises.push(widgetUtils.waitInitAllWidgetsAsync($control));
			}
			components.push(decorateComponentProxy(proxy, extenderInfo, $control, id));
		} else {
			components.push(null);
		}
	});

	try {
		await Promise.all(initPromises);
	} catch (error) {
		if (!isValidState(state, version)) {
			return [];
		} else {
			throw error;
		}
	}

	return components;
}

function attachDispose(components, state, dispose) {
	state.disposeAsync = async () => {
		try {
			await dispose(...components);
		} finally {
			state.disposeAsync = undefined;
		}
	};
}

async function initExtenderAsync(callback, components, state, version) {
	try {
		await callback(...components);
	} catch (error) {
		if (isValidState(state, version)) {
			throw error;
		}
	}
}

function getControlItems(controlIds, $scope, extenderInfo) {
	return controlIds.map((controlIdOrConfig) => {
		const { id: controlId = controlIdOrConfig, optional } = controlIdOrConfig;

		if (typeof controlId !== 'string') {
			/*! SuppressStringValidation Not a caption */
			throw new FormExtenderError(
				'Incorrect control id configuration. Expecting a string control id or an object of structure { id, optional }. configurationItemPK: ' +
					$scope.attr('data-configuration-item-pk')
			);
		}

		const $element = $scope.find('[data-design-control-id="' + controlId + '"]');

		if (!$element.length) {
			if (optional) {
				return [null];
			} else {
				/*! SuppressStringValidation Not a caption */
				throw new FormExtenderError(
					`Control id does not exist: ${controlId}, configurationItemPK: ${$scope.attr(
						'data-configuration-item-pk'
					)}, extenderFnName: ${extenderInfo.name}, disposed: ${extenderInfo.state.disposed}, version: ${extenderInfo.state.version}`
				);
			}
		}

		return [$element, controlId];
	});
}

function decorateComponentProxy(proxy, extenderInfo, $control, controlId) {
	const originalInvoke = proxy.invoke;
	proxy.invoke = function (methodName, ...args) {
		if (isElementDisposed($control)) {
			log.error(
				`[gwFormExtender] ${extenderInfo.name} - invoking proxy on disposed control / id: '${controlId}', method: '${methodName}', isBindingElementDisposed: ${extenderInfo.state.disposed} - v${extenderInfo.version}`
			);
		}

		return originalInvoke.call(this, methodName, ...args);
	};

	return proxy;
}

function isValidState(state, version) {
	return !state.disposed && state.version === version;
}

function isElementDisposed($element) {
	return !$.contains(document.body, $element[0]);
}
