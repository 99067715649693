import captionService from 'CaptionService';
import { loadWidgetAsync, tryGetWidget } from 'ModuleLoader';
import { format } from 'StringUtils';
import validationDecorator from 'ValidationDecorator';
import widgetFactory from 'WidgetFactory';
import widgetUtils from 'WidgetUtils';
import $ from 'jquery';

function WidgetService() {
}

WidgetService.prototype.utils = widgetUtils;

WidgetService.prototype.preloadWidgetsAsync = async ($element) => {
	const widgetNames = [];
	const $widgets = widgetFactory.select($element, true, true);
	$widgets.each((_, el) => {
		const $widget = $(el);
		const name = widgetFactory.getWidgetName($widget) || widgetFactory.getComponentName($widget);
		if (name && widgetNames.indexOf(name) === -1 && !$.fn[name] && !tryGetWidget(name)) {
			widgetNames.push(name);
		}
	});

	if (widgetNames.length > 0) {
		return await Promise.all(widgetNames.map((name) => {
			return loadWidgetAsync(name);
		}));
	}
};

WidgetService.prototype.preloadTemplateWidgetsAsync = async function (template) {
	const promises = [];
	$(template).filter('script').each((i, script) => {
		const scriptTemplate = $(script).text();
		promises.push(this.preloadWidgetsAsync(scriptTemplate));
	});
	return await Promise.all(promises);
};

WidgetService.prototype.createGlowWidgetsAsync = async function (element, all, includeSelf) {
	await this.preloadWidgetsAsync($(element));
	return this.createGlowWidgetsSync(element, all, includeSelf);
};

WidgetService.prototype.createGlowWidgetsSync = function (element, all, includeSelf) {
	createGlowWidgets(this, element, all, includeSelf);
};

WidgetService.prototype.register = (widgetName, widgetDefinition, baseWidget, templates, extensions) => {
	registerInternal(widgetName, widgetDefinition, baseWidget, extensions);
	if (templates) {
		appendTemplatesToBody(templates);
	}
};

function createGlowWidgets(service, element, all, includeSelf) {
	let elements = widgetFactory.select(element, includeSelf);
	if (!all) {
		elements = elements.filter((_, el) => { return $(el).parents('[data-bind*=gwWidgify]').length < 1; });
	}
	elements.each((index, widgetElement) => {
		const $widgetElement = $(widgetElement);
		const widgetName = widgetFactory.getWidgetName($widgetElement);
		if (widgetName) {
			if (!$.fn[widgetName]) {
				throw new Error('Widget "' + widgetName + '" has not been loaded yet.');
			}

			onBeforeWidgify($widgetElement);
			$widgetElement[widgetName]();
			onAfterWidgify(service, $widgetElement);
		}
	});
}

function onBeforeWidgify($widget) {
	translate($widget);
	decorateCollectionBinding($widget);
}

function onAfterWidgify(service, $widget) {
	decorateReadOnlyBinding(service, $widget);
	validationDecorator.decorate($widget);
}

function isReadOnlyDecorationsDisabled(service, $widget) {
	const decorations = service.constants.DisableReadOnlyDecorations;
	let selector = format('[data-disable-readonly-decorations="{0}"],[data-disable-readonly-decorations="{1}"]', decorations.SelfOnly, decorations.SelfAndChildren);
	if ($widget.is(selector)) {
		return true;
	}

	selector = format('[data-disable-readonly-decorations="{0}"],[data-disable-readonly-decorations="{1}"]', decorations.SelfAndChildren, decorations.ChildrenOnly);
	return $widget.parent().closest(selector).length > 0;
}

function addReadOnlyBinding($widget, value) {
	$widget.attr('data-bind', (i, attrValue) => {
		if (attrValue && attrValue.indexOf('gwReadOnly') > -1) {
			throw new Error('Do not use gwReadOnly directly, use data-readonly attr instead. Binding Strings: "' + attrValue + '"');
		}

		let result = 'gwReadOnly: ' + value;
		if (attrValue) {
			result = attrValue + ', ' + result;
		}
		return result;
	});
}

function decorateReadOnlyBinding(service, $widget) {
	if (!isReadOnlyDecorationsDisabled(service, $widget)) {
		const readOnlyAttr = $widget.attr('data-readonly');
		const readOnlyData = $widget.data('readonly');
		if (typeof readOnlyData === 'object') {
			addReadOnlyBinding($widget, readOnlyAttr);
		}
		else {
			const isReadOnly = readOnlyAttr === 'true';
			addReadOnlyBinding($widget, isReadOnly);
		}
	}
}

function decorateCollectionBinding($widget) {
	const bindingPath = $widget.data('property');
	if (bindingPath && bindingPath.indexOf('/') > -1) {
		const widgetId = $widget.attr('id');
		if (widgetId) {
			const revisedId = widgetId.replace(/\//g, '_');
			widgetUtils.getLabelForElement($widget).attr('for', revisedId);
			$widget.attr('id', revisedId);
		}
	}
}

function translate($widget) {
	for (const dataName in $widget.data()) {
		const dataValue = $widget.data(dataName);

		if (dataValue.ResKey) {
			$widget.data(dataName, captionService.getCaptionFromKey(dataValue.ResKey, dataValue.Fallback).caption);
		}
	}
}

function registerInternal(widgetName, widgetDefinition, baseWidget, extensions) {
	const baseProto = {
		markAsReadOnly(value) {
			widgetUtils.decorateReadOnly(this.element, value);
		}
	};
	/*! SuppressStringValidation String validation suppressed in initial refactor */
	$.widget('glow.' + widgetName, baseWidget || widgetFactory.defaultBaseWidget, $.extend(baseProto, widgetDefinition));
	$.extend($.glow[widgetName], extensions);
}

function appendTemplatesToBody(templates) {
	const $body = $('body');
	$(templates).filter('script[type="text/html"][data-append-to-body="true"]').each((_, el) => {
		if ($body.find('#' + $(el).attr('id')).length === 0) {
			$body.append(el);
		}
	});
}

/*! StartNoStringValidationRegion Strin validation suppressed in initial refactor */
WidgetService.prototype.constants = {
	DisableReadOnlyDecorations: {
		SelfOnly: 'selfonly',
		SelfAndChildren: 'selfandchildren',
		ChildrenOnly: 'childrenonly'
	}
};
/*! EndNoStringValidationRegion */

export default new WidgetService();
