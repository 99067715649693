import ko, { type Subscription } from "knockout";
import ruleFunction from "RuleFunction";
import { inject, onMounted, onUnmounted, provide } from "vue";

export type GetRootDataItem = () => unknown;

export type GetMessageBus = () => unknown;

export type ExtendComponents = (
  controlIds: ComponentExtenderControlIds,
  callback: ComponentExtenderCallback,
  dispose: ComponentExtenderDispose
) => void;

export type ComponentProxy = {
  invoke: (methodName: string, ...args: []) => unknown;
};

type ComponentExtenderControlIds = string[];
type ComponentExtenderCallback = (...args: []) => Promise<void>;
type ComponentExtenderDispose = (...args: []) => Promise<void>;

type ComponentPrepare = () => void;
type ComponentMethod = (...args: never[]) => void;
export type ComponentMethods = Record<string, ComponentMethod>;

type Component = {
  methods: ComponentMethods;
  prepare?: ComponentPrepare;
};

enum ComponentExtenderState {
  Active = "ACTIVE",
  Pending = "PENDING",
}

type ComponentExtender = {
  controlIds: ComponentExtenderControlIds;
  callback: ComponentExtenderCallback;
  dispose: ComponentExtenderDispose;
  state: ComponentExtenderState;
  controlProxies?: ComponentProxy[];
};

export const formExtenderInjectionKey = Symbol("formExtender");

export function setFormExtender(extender: string, viewModel: unknown, messageBus: unknown): void {
  const extenderFn = ruleFunction(extender);

  const context = new FormExtenderContext();
  provide(formExtenderInjectionKey, context);

  const extendComponents: ExtendComponents = (controlIds, callback, dispose) => {
    context.extendComponents(controlIds, callback, dispose);
  };

  const getRootDataItem: GetRootDataItem = () => {
    return ko.unwrap(viewModel);
  };

  const getMessageBus: GetMessageBus = () => {
    return messageBus;
  };

  const initExtendersAsync = async (): Promise<void> => {
    await extenderFn({
      extendComponents,
      getRootDataItem,
      getMessageBus,
    });
    context.initExtenders();
  };

  let subscription: Subscription | null = null;

  onMounted(initExtendersAsync);

  onUnmounted(() => {
    subscription?.dispose();
    context.disposeExtenders();
  });

  if (ko.isObservable(viewModel)) {
    subscription = viewModel.subscribe(() => {
      context.disposeExtenders();
      initExtendersAsync();
    });
  }
}

export function useFormExtender(id: string, component: Component): void {
  const context = inject<FormExtenderContext | undefined>(formExtenderInjectionKey, undefined);
  if (context) {
    onMounted(() => context.registerComponent(id, component));
    onUnmounted(() => context.unregisterComponent(id));
  }
}

class FormExtenderContext {
  private component = new Map<string, Component>();
  private componentExtenders: ComponentExtender[] = [];

  registerComponent(id: string, component: Component): void {
    this.component.set(id, component);
    this.initExtenders();
  }

  unregisterComponent(id: string): void {
    this.disposeExtenders(id);
    this.component.delete(id);
  }

  extendComponents(
    controlIds: ComponentExtenderControlIds,
    callback: ComponentExtenderCallback,
    dispose: ComponentExtenderDispose
  ): void {
    const extender = {
      controlIds,
      callback,
      dispose,
      state: ComponentExtenderState.Pending,
    };
    this.componentExtenders.push(extender);
    this.initExtenders();
  }

  initExtenders(): void {
    for (const extender of this.componentExtenders) {
      if (extender.state === ComponentExtenderState.Pending) {
        this.initExtenderAsync(extender);
      }
    }
  }

  private async initExtenderAsync(extender: ComponentExtender): Promise<void> {
    for (const id of extender.controlIds) {
      const component = this.component.get(id);
      if (!component) {
        // We'll wait
        return;
      }
    }

    extender.controlProxies = [];
    for (const id of extender.controlIds) {
      const component = this.component.get(id);
      const controlProxy = this.createProxy(component);
      extender.controlProxies.push(controlProxy);
    }

    await extender.callback(...(extender.controlProxies as []));
    extender.state = ComponentExtenderState.Active;
  }

  disposeExtenders(id?: string): void {
    for (const extender of this.componentExtenders) {
      if (extender.state === ComponentExtenderState.Active && (!id || extender.controlIds.includes(id))) {
        this.disposeExtenderAsync(extender);
      }
    }
  }

  private async disposeExtenderAsync(extender: ComponentExtender): Promise<void> {
    await extender.dispose(...(extender.controlProxies as []));
    extender.state = ComponentExtenderState.Pending;
  }

  private createProxy(component?: Component): ComponentProxy {
    let prepare = component?.prepare;

    function invoke(methodName: string, ...args: []): unknown {
      if (prepare) {
        prepare();
        prepare = undefined;
      }
      if (component?.methods[methodName]) {
        return component.methods[methodName](...args);
      }
      return undefined;
    }

    return { invoke };
  }
}
