import captionService from 'CaptionService';

export const schedule = {
	actionsHeader: () => captionService.getString('c4a1ffcf-c529-4947-aead-31b14b614f16', 'Actions'),
	activeHeader: () => captionService.getString('801e5b31-0830-41dd-ae03-72552b694c0d', 'Active'),
	addBreak: () => captionService.getString('c1535ed6-4f5f-4187-9435-97be9dd06c7c', 'Add Break'),
	breaksHeader: () => captionService.getString('c1f8d63a-dcf5-43b5-b725-4c2f90543700', 'Breaks'),
	breaksOverlap: () => captionService.getString('8c7ddff0-cfc9-4155-b914-1d0fd2a04784', 'Breaks Overlap'),
	copyDay: () => captionService.getString('0051a254-6106-4bf1-9767-5ef17f3ac0d7', 'Copy'),
	dayHeader: () => captionService.getString('747f0874-613b-4fdd-9d63-ce1a2ce32588', 'Day'),
	hours: () => captionService.getString('729df5a1-1a09-422a-8090-8b89fba18b62', 'Hours'),
	invalidEndTime: () => captionService.getString('1a64f548-8878-4a47-8bc7-169703cd6d32', 'Invalid End Time'),
	invalidStartTime: () => captionService.getString('12a907e5-b4e2-47dd-a409-1da42cbeceaf', 'Invalid Start Time'),
	minutes: () => captionService.getString('b8e15a73-bc87-4ad7-976f-5508373908a3', 'Minutes'),
	pasteDay: () => captionService.getString('045c05d7-b4d9-4e11-945e-a3cc793c2ccb', 'Paste'),
	removeBreak: () => captionService.getString('f37acf66-d3a2-4b3e-8298-0fb27a2b35be', 'Remove Break'),
	timeHeader: () => captionService.getString('0e26e6d3-86c1-4e2c-97f3-307749981fa1', 'Time'),
	totalHeader: () => captionService.getString('796c9be4-4461-407b-ba5e-939d6965cc84', 'Total')
};

export const weekDay = {
	monday: () => captionService.getString('0be2e83f-6a6e-4d94-929b-72df5b6209dd', 'Monday'),
	tuesday: () => captionService.getString('ea13fec5-19e4-4711-bb1e-9ba79566f3d8', 'Tuesday'),
	wednesday: () => captionService.getString('106b3fb9-04d9-46dd-a8e7-11736e274353', 'Wednesday'),
	thursday: () => captionService.getString('94dc7e40-edcc-4a4f-934e-921223c63ff4', 'Thursday'),
	friday: () => captionService.getString('b01bbc99-4130-4248-81f4-7e045a11dc47', 'Friday'),
	saturday: () => captionService.getString('65cd997d-33a2-4559-b4d3-f00f110e07c3', 'Saturday'),
	sunday: () => captionService.getString('44b5c0b6-acff-48cc-b6ab-87a42f58d276', 'Sunday'),
};
