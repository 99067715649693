import { type default as ValidationRegistrar } from "ValidationRegistrar";
import { provide, inject } from "vue";

export const validationRegistrarInjectionKey = Symbol("validationRegistrar");

export function useValidationRegistrar(): ValidationRegistrar | undefined {
  return inject<ValidationRegistrar | undefined>(validationRegistrarInjectionKey, undefined);
}

export function setValidationRegistrar(validationRegistrar: ValidationRegistrar | undefined): void {
  provide(validationRegistrarInjectionKey, validationRegistrar);
}
