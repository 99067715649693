import captionService from "CaptionService";
import global from "Global";

/*! StartNoStringValidationRegion constant for navigation */
export default Object.freeze({
  ImportPage: {
    get Name(): string {
      return "DataAutomation/ImportPage.ejs";
    },
    get Title(): string {
      return captionService.getString("1edd263b-031b-4a8e-82c6-696fcec567fc", "Data Import Wizard");
    },
    get FormFactorSpecific(): boolean {
      return true;
    },
  },
  ImportMappingPage: {
    get Name(): string {
      return "Desktop/DataAutomation/ImportMappingStandalonePage.ejs";
    },
    get Title(): string {
      return captionService.getString("1edd263b-031b-4a8e-82c6-696fcec567fc", "Data Import Wizard");
    },
  },
  ConfigurationMasterList: {
    get Name(): string {
      return "ConfigurationMasterList.html";
    },
    get Title(): string {
      return captionService.getString("b1c1b345-8425-4968-85bb-09c65c27be1c", "Configuration Master List");
    },
    get FormFactorSpecific(): boolean {
      return true;
    },
  },
  DocumentDelivery: {
    get Name(): string {
      return "DocumentDeliveryModal.ejs";
    },
    get Title(): string {
      return captionService.getString("3d86b1ba-25e0-4618-9d48-1ead62b327ef", "Document Delivery");
    },
  },
  HelpPageWindow: {
    get Name(): string {
      return "HelpPageWindow.ejs";
    },
  },
  Tracking: {
    get Name(): string {
      return "TrackingResult.ejs";
    },
    get Title(): string {
      return captionService.getString("a9e8b04f-b5ea-4ea9-9d0e-ae51ea2f0e66", "Tracking Results");
    },
  },
  PublicTracking: {
    get Name(): string {
      return global.materialDesign ? "PublicTrackingVue.html" : "TrackingResult.ejs";
    },
    get Title(): string {
      return captionService.getString("a9e8b04f-b5ea-4ea9-9d0e-ae51ea2f0e66", "Tracking Results");
    },
  },
  PowerBiReports: {
    get Name(): string {
      return "PowerBiReports.html";
    },
    get Title(): string {
      return captionService.getString("80824733-9405-487f-8b09-9cbfe5090bf2", "Analytics Reports");
    },
  },
  NoModuleAccess: {
    get Name(): string {
      return global.materialDesign ? "NoModuleAccessVue.html" : "NoModuleAccess.html";
    },
    get Title(): string {
      return captionService.getString("88ebc5a9-465b-4df7-b197-34934f6c1d00", "Access Denied");
    },
  },
  Unavailable: {
    get Name(): string {
      return global.materialDesign ? "UnavailableVue.html" : "Unavailable.html";
    },
    get Title(): string {
      return captionService.getString("36c10e7e-a4d8-401a-a4c2-4e8c6e7a9417", "Page Unavailable");
    },
  },
  Forbidden: {
    get Name(): string {
      return global.materialDesign ? "ForbiddenVue.html" : "Forbidden.html";
    },
    get Title(): string {
      return captionService.getString("7911af30-02f8-4dca-8853-3fe6b07db592", "Permission Denied");
    },
  },
  UserAgreement: {
    get Name(): string {
      return "UserAgreement.html";
    },
    get Title(): string {
      return captionService.getString("84791648-eb71-449a-9235-615317cac955", "User Agreement");
    },
  },
  toObject(name: string): { Name: string } {
    return { Name: name };
  },
});
/*! EndNoStringValidationRegion */
